import { Grid, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import ReactApexChart from 'react-apexcharts';

import { baseSwitchButtonStyle, themeDefault } from 'themeDefault';

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  maxWidth: '100%',
};

export const ChartContainer = styled(Grid)(
  ({ theme }) => ({
    color: themeDefault.palette.fieldLabelColor,
    height: 'auto',
    '& .apexcharts-canvas': {
      height: 'inherit !important',
      '& svg': {
        height: 'inherit !important',
        marginLeft: '5%',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '0',
        },
      },
      '& .apexcharts-slices': {
        cursor: 'pointer',
      },
      '& .apexcharts-legend': {
        padding: 0,
        top: '0 !important',
        '& .apexcharts-legend-text': {
          cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'initial',
          top: '0.5rem !important',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
    },
  }),
);

export const EviteContainer = styled(Grid)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50% !important',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${themeDefault.palette.lightGreyColor}`,
      flex: 1,
      height: '46vw',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

export const ChardSwitchContainer = styled(Grid)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    alignItems: 'center',
    display: 'flex',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'baseline',
      maxWidth: '90%',
    },
  }),
);

export const CardName = styled(Typography)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    alignItems: 'center',
    display: 'flex',
    float: 'left',
    justifyContent: 'start',
    marginTop: '5px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  }),
);

export const SwitchButton = styled(Switch)(
  () => ({
    ...baseSwitchButtonStyle as CSSProperties,
    marginRight: '1rem',
  }),
);

export const CardNameText = styled('span')(
  () => ({
    ...themeDefault.overflowText,
    maxWidth: '70%',
  }),
);

export const NoCardsMessage = styled(Typography)(
  () => ({
    margin: 'auto',
  }),
);

export const Chart = styled(ReactApexChart)(
  ({ theme }) => ({
    width: '100%',
    height: '20vh',
    '& foreignObject': {
      height: 'inherit',
    },
    '& svg': {
      marginTop: '6%',
    },
    '@media screen and (max-height: 850px)': {
      height: '29vh',
      '& svg': {
        marginTop: '5%',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
      height: '20vh',
      '& svg': {
        marginTop: '0 !important',
      },
    },
  }),
);
