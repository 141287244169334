import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilterContainer = styled(Box)(
  () => ({
    width: '30vw',
    display: 'flex',
    flexDirection: 'column'
  }),
);

export const SearchTextField = styled(TextField)(
  ({ theme }) => ({
    margin: '5% auto',
    width: '70%',
    '& input': {
      padding: '12px 0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '3rem',
    },
  }),
);
