import React, { ElementType, useState } from 'react';
import {
  Checkbox, TableCell,
  TableCellBaseProps,
  TableRow, Tooltip
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, ConfirmationModal, CreateGuestModal, CustomTooltip, RsvpStatusModal } from 'components';
import { RESPONSE_STATUS } from 'enums';
import { calculateMenuPlacement, checkIsAnyEviteSelected } from 'helpers';
import { REMOVE_GUEST, graphMutationMiddleware } from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { EviteResponseType, EviteType, GuestType, InvitationProps, ResponseType, SelectedGuests } from 'types';

import {
  BasicTableCell, CheckboxTableCell, FirstTableCellClosed, FirstTableCellHeaderSelected, LastTableCell,
  TableRowStyledPreview, ThemeMultipleLinesText, ThemeOverlappedText
} from './GuestsTableRow.styled';
import { Delete, Edit, Mail, Send, Visibility } from '@mui/icons-material';

type GuestTableProps = {
  sendToOneContact: (guestId: number) => void
  guest: GuestType,
  invitation: InvitationProps,
  forPreview: boolean,
  isTrackPage: boolean,
  enabledEvites: EviteType[],
  setGuestForPreview: (guest: GuestType) => void
};

export const GuestsTableRow = ({
  guest,
  invitation,
  sendToOneContact,
  forPreview = false,
  isTrackPage,
  enabledEvites,
  setGuestForPreview
}: GuestTableProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { selectedGuests, setSelectedGuests, changeExecuteRefetch: refetchGuests } = useGuestStore();
  const { changeExecuteRefetch: refetchResponses } = useTrackStore();

  const [removeGuest] = graphMutationMiddleware(REMOVE_GUEST);

  const [ isOpenEditModal, setIsOpenEditModal ] = useState(false);
  const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
  const [ isOpenRsvpStatusModal, setIsOpenRsvpStatusModal ] = useState(false);
  const [ isEditRSVP, setIsEditRSVP ] = useState(false);

  const isGuestSelected = (id: number) => {
    return selectedGuests.some((c: SelectedGuests) => c.guestId === id);
  };

  const deleteGuest = () => {
    removeGuest({variables: {
      guestId: Number(guest.id)
    }}).then(() => {
      refetchResponses();
      refetchGuests();
    });
  };

  const handleOpenRsvpModal = (isEdit: boolean) => {
    setIsOpenRsvpStatusModal(true);
    setIsEditRSVP(isEdit);
  };

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: Edit,
      functionHandler: () => setIsOpenEditModal(true)
    },
    {
      id: 1,
      title: t('send'),
      icon: Send,
      functionHandler: () => checkIsAnyEviteSelected(guest, sendToOneContact, t('noEvitesSelectedFor', { entity: t('guest')}))
    },
    {
      id: 2,
      title: t('preview'),
      icon: Visibility,
      functionHandler: () => navigate(`/invitations/edit/preview/${invitation.id}`, { state: { guest: guest } })
    },
    {
      id: 3,
      title: t('viewRSVP'),
      icon: Mail,
      functionHandler: () => handleOpenRsvpModal(false)
    },
    {
      id: 4,
      title: t('editRSVP'),
      icon: Edit,
      functionHandler: () => handleOpenRsvpModal(true)
    },
    {
      id: 5,
      title: t('remove'),
      icon: Delete,
      functionHandler: () => setIsOpenDeleteModal(true)
    }
  ];

  return (
    <>
      <TableRow component={forPreview ? TableRowStyledPreview : TableRow} selected={false} onClick={() => forPreview && setGuestForPreview(guest)}>
        { !forPreview &&
        <CheckboxTableCell>
          {isGuestSelected(guest.id) ?
            <FirstTableCellHeaderSelected checked={isGuestSelected(guest.id)} onClick={() => setSelectedGuests(guest)}/> :
            <Checkbox checked={isGuestSelected(guest.id)} onClick={() => setSelectedGuests(guest)}/>}
        </CheckboxTableCell>
        }
        <FirstTableCellClosed />
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.contact.lastName} />
        </BasicTableCell>
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.contact.firstName} />
        </BasicTableCell>
        <BasicTableCell>
          <CustomTooltip theme={ThemeOverlappedText}text={guest.contact.email} />
        </BasicTableCell>
        {!isTrackPage &&
        <BasicTableCell>
          <CustomTooltip theme={ThemeOverlappedText}text={guest.lastSent && format(parseISO(guest.lastSent), t('dateFormatUI'))} />
        </BasicTableCell>}
        <BasicTableCell>
          <CustomTooltip theme={ThemeOverlappedText}text={guest.contact.phoneNumber} />
        </BasicTableCell>
        {!isTrackPage &&
        <BasicTableCell>
          <Tooltip title={guest?.evites.filter((evite: EviteType) => evite.isSelected).map((evite: EviteType) => evite.name).join(', ')} placement='top' arrow>
            {invitation?.evites.filter((evite: EviteType) => evite.isSelected).length > 0 && invitation?.evites.filter((evite: EviteType) => evite.isSelected).length === guest?.evites.filter((evite: EviteType) => evite.isSelected).length ?
              <span>{t('all')}</span> :
              <span>{`${guest?.evites.filter((evite: EviteType) => evite.isSelected).length}/${invitation?.evites.filter((evite: EviteType) => evite.isSelected).length}`}</span>}
          </Tooltip>
        </BasicTableCell>}
        <TableCell component={(forPreview ? LastTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.sender && `${guest.sender.title ?? ''} ${guest.sender.lastName} ${guest.sender.firstName}`} />
        </TableCell>
        {isTrackPage &&
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.invitation.name} />
        </BasicTableCell>}
        {isTrackPage && enabledEvites &&
          enabledEvites.map((evite: EviteType) => (
            <BasicTableCell key={evite.id}>
              {(!guest.responses || guest.responses.length === 0) && <CustomTooltip theme={ThemeOverlappedText}text={t(RESPONSE_STATUS.NOT_SENT)} />}
              {guest.responses.length > 0 && guest.responses.map((response: ResponseType) => <CustomTooltip key={evite.id} theme={ThemeOverlappedText}text={t(response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ? response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id)?.status : t(RESPONSE_STATUS.NOT_SENT))} /> )}
            </BasicTableCell>
          ))
        }
        { !forPreview &&
        <LastTableCell>
          {
            actionMenuProps &&
              <ActionMenu propsData={actionMenuProps} isTrackPage={isTrackPage} menuPlacement={calculateMenuPlacement(isMobile)}/>
          }
        </LastTableCell>
        }
      </TableRow>
      <TableRow>
        <TableCell />
      </TableRow>
      {isOpenRsvpStatusModal &&
        <RsvpStatusModal guest={guest} isEdit={isEditRSVP} isOpen={true} handleClose={() => setIsOpenRsvpStatusModal(false)} />}
      {isOpenEditModal &&
        <CreateGuestModal guest={guest} handleClose={() => setIsOpenEditModal(false)} isOpen={isOpenEditModal} />
      }
      {isOpenDeleteModal &&
      <ConfirmationModal isOpen={isOpenDeleteModal} handleClose={() => setIsOpenDeleteModal(false)} confimMessage={t('confirmDeleteGuests')} handleConfirm={deleteGuest} />}
    </>
  );
};