import { Box, FormControl, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
      alignItems: 'center',
    },
  }),
);

export const EventName = styled(Typography)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    color: themeDefault.palette.mainColor,
    fontSize: '16px',
    fontWeight: 'bold',
    maxWidth: '30vh',
    margin: 'auto 1rem',
    textAlign: 'center',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      margin: '4px 0',
    },
  }),
);

export const RadioGroupFormControl = styled(FormControl)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      '& .MuiFormGroup-root': {
        width: '100%',
      },
      '& .MuiFormControlLabel-root': {
        width: '30%',
      }
    }
  }),
);
