import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PageContent = styled(Box)(
  ({ theme }) => ({
    maxHeight: '500px',
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export const ModalHeader = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%',
    }
  }),
);

export const ModalHeading = styled(Typography)(
  () => ({
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  ({ theme }) => ({
    cursor: 'pointer',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: '2rem',
      top: '2rem',
    },
  }),
);

export const ButtonContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '1rem',
    width: '100%',
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      padding: '10px 36px',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      }
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: '1rem',
      width: '100%',
    },
  }),
);

export const EmptyListText = styled(Typography)(
  () => ({
    ...themePublic.emptyListText as CSSProperties,
  }),
);
