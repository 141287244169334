import { CSSProperties } from 'react';
import { Box, Table, TableCell, TextField, Typography, Dialog, createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const TableCellWithoutBorderStyle = {
  border: 'none',
};

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  fontWeight: 'bold',
  [themeLocal.breakpoints.down('sm')]: {
    lineHeight: 1.8,
    maxWidth: '25vh',
  },
};

export const DialogStyled = styled(Dialog)(
  ( { theme }) => ({
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
    }
  }),
);

export const TableContainer = styled(Box)(
  () => ({
    width: '100%',
    '& tr': {
      border: 'none',
    }
  }),
);

export const GuestNameText = styled(Typography)(
  () => ({
    ...themeDefault.overflowHidden as CSSProperties,
    fontWeight: 'bold',
    color: themeDefault.palette.mainColor,
    margin: 'auto',
    width: 'fit-content',
    maxWidth: '90%',
  }),
);

export const TableStyled = styled(Table)(
  () => ({
    display: 'inline-table',
  }),
);

export const ContactNameText = styled(Typography)(
  ({ theme }) => ({
    ...themeDefault.overflowHidden as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
    display: 'block',
    fontWeight: 'bold',
    maxWidth: '185px',
    transform: 'translateY(-50%) translateX(100%)',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    }
  }),
);

export const TableCellStyled = styled(TableCell)(
  ({ theme }) => ({
    ...themeDefault.overflowHidden as CSSProperties,
    border: `1px solid ${themeDefault.palette.lightGreyColor}`,
    fontWeight: 'bold',
    maxWidth: '180px',
    width: '33%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70px',
    }
  }),
);

export const RowContainerWithoutBorder = styled(Box)(
  () => ({
    border: 'none',
  }),
);

export const RowContainerRound = styled(Box)(
  () => ({
    border: `2px solid ${themeDefault.palette.mainColor}`,
    margin: '15px 0',
    padding: '0px 10px 10px 10px',
  }),
);

export const TableCellText = styled(Typography)(
  () => ({
    ...themeDefault.overflowHidden as CSSProperties,
    fontWeight: 'bold',
  }),
);

export const PersonalMessage = styled(TextField)(
  ({ theme }) => ({
    width: '100%',
    '& .Mui-disabled': {
      margin: '2rem auto',
      width: '80%',
      '& textarea': {
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '100%',
    },
  }),
);

export const StatusIcon = styled('div')(
  () => ({
    backgroundColor: 'black',
    borderRadius: '50%',
    float: 'left',
    height: '16px',
    marginRight: '8px',
    marginTop: '2px',
    width: '16px',
  }),
);
