import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

export const EvitesContainer = styled(Box)(
  ({ theme, style }) => ({
    [theme.breakpoints.up('sm')]: {
      width: style.width,
    },
    [theme.breakpoints.down('sm')]: {
      overflowY: 'scroll'
    },
  }),
);

export const GuestName = styled(Typography)(
  ({ theme }) => ({
    ...themeDefault.overflowHidden as CSSProperties,
    color: themeDefault.palette.mainColor,
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${themeDefault.palette.disabledColor}`,
      padding: '18px 0',
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      marginLeft: '40px',
      width: '30%',
    },
  }),
);

export const SingleGuestContainer = styled(Box)(
  ({ theme }) => ({
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '0 auto',
    }
  }),
);

export const MultipleGuestsContainer = styled(SingleGuestContainer)(
  () => ({
    width: '80% !important',
  }),
);
