import React from 'react';

import { logo } from 'resources';

import { DividerStyled, HeaderContainer, HeaderLable } from './FormHeader.styled';

type FormHeaderProps = {
  label: string
}

export const FormHeader = (props: FormHeaderProps) => {
  return (
    <HeaderContainer>
      <img src={logo} alt='logo' />
      <DividerStyled variant='middle' />
      <HeaderLable>{props.label}</HeaderLable>
    </HeaderContainer>
  );
};