import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import React from 'react';

import { ComponentLoader, PageLoader } from './Loader.styled';

type LoaderProps = {
  loadingPage?: boolean,
  inProgress: boolean
}

export const Loader = ({ loadingPage = true, inProgress }: LoaderProps) => {
  return (
    inProgress && loadingPage ?
      <PageLoader>
        <CircularProgress />
      </PageLoader> :
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          },
        }}
        maxWidth='xl'
        open={inProgress}>
        <DialogContent>
          <ComponentLoader>
            <CircularProgress />
          </ComponentLoader>
        </DialogContent>
      </Dialog>
  );
};