import { Box, Checkbox, Table, TableBody } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const EmptyListTextStyle = {
  ...themePublic.emptyListText as CSSProperties,
  fontSize: '1rem !important',
  [themeLocal.breakpoints.down('sm')]: {
    margin: 0,
    marginTop: '1rem',
    textAlign: 'center'
  },
};

export const TableContainerPreview = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'flex-start',
    }
  }),
);

export const TableBulkOptionsCheckbox = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
  }),
);

export const TableBulkOptionsCheckboxSelected = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
    ...themePublic.tableBulkOptionsCheckboxSelected as CSSProperties,
  }),
);

export const TableStyled = styled(Table)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const TableBodyStyled = styled(TableBody)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const TableBodyMobile = styled(Box)(
  () => ({
    flex: 1,
    width: '100%',
  }),
);
