import React, { useCallback, useMemo } from 'react';
import { Box, CircularProgress, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { TagSelector } from 'components';
import { addDays, endOfDay, format, parse, parseISO } from 'date-fns';
import { ErrorMessage, Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { PublicContactEntryAuthorisationType, TagType } from 'types';

import {
  ButtonsContainer, CancelButton,
  PaperContent, PaperStyled, SelectFormControl, SubmitButton, TagsContainer
} from './MobilePublicContactEntryLinksTableEditRow.styled';

type Props = {
  item: Partial<PublicContactEntryAuthorisationType>;
  lookupTags?: TagType[];
  onSave?: (item: Partial<PublicContactEntryAuthorisationType>) => Promise<void>;
  onCancel?: () => void;
};

export const MobilePublicContactEntryLinksTableEditRow = ({
  item,
  lookupTags = [],
  onSave,
  onCancel,
}: Props) => {
  const [t] = useTranslation();

  const initialValues = useMemo(() => {
    const defaultExpirationDate = endOfDay(addDays(new Date(), 59));
    const {
      name: moniker,
      tags,
      expirationDate,
      activityStatus,
    } = item || {};

    return {
      name: moniker || '',
      tags: tags?.map((x) => x.name) || [],
      expirationDate: format(expirationDate ? parseISO(expirationDate) : defaultExpirationDate, 'yyyy-MM-dd'),
      activityStatus: activityStatus || 'ACTIVE',
    };
  }, [item]);

  const onSubmit = useCallback(async (values: typeof initialValues) => {
    const {
      name: moniker,
      tags,
      expirationDate,
      activityStatus,
    } = values;

    await onSave?.({
      name: moniker,
      tags: tags.map((x) => ({ id: undefined, name: x })),
      expirationDate: expirationDate && endOfDay(parse(expirationDate, 'yyyy-MM-dd', new Date())).toISOString(),
      activityStatus,
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, isSubmitting, submitForm: submitFormAsync }) => {
          const submitForm = () => submitFormAsync().catch(() => { /* swallow ... */ });
          return (
            <PaperStyled elevation={2}>
              <PaperContent>
                <Box>
                  <Field as={TextField}
                    name='name'
                    label={t('name')}
                    type='text'
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}/>
                  <ErrorMessage name='name' component='p' />
                </Box>
                <Box>
                  <Field as={TextField}
                    name='expirationDate'
                    label={t('expiry')}
                    type='date'
                    inputProps={{
                      min: format(new Date(), 'yyyy-MM-dd')
                    }}
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}/>
                  <ErrorMessage name='expirationDate' component='p' />
                </Box>
                <SelectFormControl>
                  <InputLabel>{t('status')}</InputLabel>
                  <Field as={Select}
                    name='activityStatus'
                    label={t('status')}>
                    <MenuItem value='ACTIVE'>{t('active')}</MenuItem>
                    <MenuItem value='INACTIVE'>{t('inactive')}</MenuItem>
                  </Field>
                </SelectFormControl>
                <TagsContainer>
                  <TagSelector
                    showText={false}
                    tagData={{ tags: lookupTags }}
                    selectedTags={values.tags}
                    setSelectedTags={(tags) => setFieldValue('tags', tags)} />
                </TagsContainer>
                <ButtonsContainer>
                  <SubmitButton
                    onClick={submitForm}
                    disableElevation
                    startIcon={isSubmitting && <CircularProgress size='1rem' color='inherit' />}
                    disabled={isSubmitting}>
                    {t('save')}
                  </SubmitButton>
                  <CancelButton
                    onClick={() => onCancel?.()}
                    disableElevation>
                    {t('cancel')}
                  </CancelButton>
                </ButtonsContainer>
              </PaperContent>
            </PaperStyled>
          );
        }}
      </Formik>
    </>
  );
};
