import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

export const ListItemStyled = styled(ListItem)(
  () => ({
    height: '8vh',
    '&& .Mui-selected, && .Mui-selected:hover': {
      backgroundColor: themeDefault.palette.mainBackground,
      color: themeDefault.palette.whiteColor,
      height: '8vh',
    },
  }),
);

export const DrawerIcon = styled(ListItemButton)(
  () => ({
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
      paddingLeft: '1.5rem',
    },
  }),
);

export const SubmenuIconWrapper = styled(Box)(
  () => ({
    maxHeight: '1.5rem',
    width: '1.5rem',
  }),
);

export const BasicOption = styled(ListItemText)(
  () => ({
    ...themeDefault.overflowText as CSSProperties,
  }),
);

export const ImpersonatedOption = styled(ListItemText)(
  () => ({
    ...themeDefault.overflowText as CSSProperties,
    color: themeDefault.palette.redColor,
    fontStyle: 'italic',
  }),
);
