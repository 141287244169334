import React, { ElementType, useEffect, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Checkbox, Dialog, DialogContent, TableCell, TableCellBaseProps, TableRow } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ActionMenu, ConfirmationModal, ContactsModal, CustomTooltip } from 'components';
import { FORM_TYPE } from 'enums';
import { calculateMenuPlacement } from 'helpers';
import { DELETE_CONTACTS, GET_TAGS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { useContactStore } from 'store';
import { ContactType } from 'types';

import {
  BasicTableCell, CheckboxTableCell, FirstTableCell, FirstTableCellClosed, GroupBadge,
  HeaderBasicTableCell, HeaderLastTableCell, HeaderTitleTableCell, ImportingOpenBasicTableCell,
  KeyboardArrowDownStyled, KeyboardArrowUpStyled, LastTableCell,
  OpenBasicTableCell, OpenFirstTableCell, OpenLastRowEndTableCell, OpenLastRowStartTableCell, OpenLastTableCell,
  TableRowStyled, ThemeMultipleLinesText, ThemeOverlappedText, TitleTableCell
} from './TableRowGroup.styled';

interface TableRowProps {
  row: ContactType | undefined,
  handleCheckboxSelect: (id: number, code: string) => void,
  isContactSelected: (id: number) => boolean,
  importingProcess?: boolean,
}

export const TableRowGroup = ({row, handleCheckboxSelect, isContactSelected, importingProcess = false, }: TableRowProps) => {
  const [t] = useTranslation();
  const { changeExecuteRefetch } = useContactStore();

  const [ openRow, setOpenRow ] = useState(false);
  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ editGroup, setEditGroup ] = useState<boolean>(false);

  const [ deleteContact, { loading: deleteLoading }] = graphMutationMiddleware(DELETE_CONTACTS);
  const [ getTags, { data: tagData }] = graphLazyQueryMiddleware(GET_TAGS);

  useEffect(() => {
    getTags({ variables: {
      filter: {
        type: 'CONTACT'
      }
    }});
  }, []);

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: Edit,
      functionHandler: () => setEditGroup(true)
    },
    {
      id: 1,
      title: t('delete'),
      icon: Delete,
      functionHandler: () => setDeleteModal(true)
    },
  ];

  const handleDelete = () => {
    deleteContact({variables: {
      contactId: row.id
    }}).then(() => {
      changeExecuteRefetch();
      setDeleteModal(false);
    });
  };

  return (
    <>
      <TableRowStyled key={row.firstName} selected={isContactSelected(row.id)}>
        <CheckboxTableCell>
          <Checkbox checked={isContactSelected(row.id)} onClick={() => handleCheckboxSelect(row.id, row.firstName)}/>
        </CheckboxTableCell>
        <TableCell component={(openRow ? FirstTableCell : FirstTableCellClosed) as ElementType<TableCellBaseProps>}>
          <>
            {openRow ?
              <KeyboardArrowUpStyled onClick={() => setOpenRow(!openRow)}/> :
              <KeyboardArrowDownStyled onClick={() => setOpenRow(!openRow)}/>}
            <GroupBadge> {row.contacts.length} </GroupBadge>
          </>
        </TableCell>
        <TableCell component={(openRow ? HeaderTitleTableCell : TitleTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={row.lastName} />
        </TableCell>
        <TableCell component={(openRow ? HeaderBasicTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={row.firstName} />
        </TableCell>
        <TableCell component={(openRow ? HeaderBasicTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={row.email} />
        </TableCell>
        <TableCell component={(importingProcess ? openRow ? HeaderLastTableCell : LastTableCell : openRow ? HeaderBasicTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={row.phoneNumber} />
        </TableCell>
        { !importingProcess &&
        <TableCell component={(openRow ? HeaderLastTableCell : LastTableCell) as ElementType<TableCellBaseProps>}>
          <ActionMenu propsData={actionMenuProps} menuPlacement={calculateMenuPlacement(isMobile)}/>
        </TableCell>
        }
      </TableRowStyled>
      { openRow &&
        row.contacts.map((item: ContactType) => (
          <TableRowStyled key={item.id}>
            <TableCell />
            <TableCell component={(item === row.contacts[row.contacts.length - 1] ? OpenLastRowStartTableCell : OpenFirstTableCell) as ElementType<TableCellBaseProps>}></TableCell>
            <TableCell component={(importingProcess ? ImportingOpenBasicTableCell : OpenBasicTableCell) as ElementType<TableCellBaseProps>}>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={item.lastName} />
            </TableCell>
            <TableCell component={(importingProcess ? ImportingOpenBasicTableCell : OpenBasicTableCell) as ElementType<TableCellBaseProps>}>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={item.firstName} />
            </TableCell>
            <TableCell component={(importingProcess ? ImportingOpenBasicTableCell : OpenBasicTableCell) as ElementType<TableCellBaseProps>}>
              <CustomTooltip theme={{...ThemeOverlappedText}} text={item.email} />
            </TableCell>
            <TableCell component={(importingProcess ? item === row.contacts[row.contacts.length - 1] ? OpenLastRowEndTableCell : OpenLastTableCell: OpenBasicTableCell) as ElementType<TableCellBaseProps>}>
              <CustomTooltip theme={{...ThemeOverlappedText}} text={item.phoneNumber} />
            </TableCell>
            { !importingProcess &&
            <TableCell component={(item === row.contacts[row.contacts.length - 1] ? OpenLastRowEndTableCell : OpenLastTableCell) as ElementType<TableCellBaseProps>}>
            </TableCell>
            }
          </TableRowStyled>
        ))
      }
      <TableRow>
        <TableCell></TableCell>
      </TableRow>
      {editGroup &&
      <Dialog maxWidth='xl' open={editGroup} onClose={() => setEditGroup(false)}>
        <DialogContent>
          <ContactsModal type={FORM_TYPE.EDIT} tagData={tagData} handleClose={() => setEditGroup(false)} contact={row} />
        </DialogContent>
      </Dialog>
      }
      <ConfirmationModal
        loading={deleteLoading}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleDelete}
        confimMessage={`${t('deletionConfirmMessage')} ${row.firstName || ''} ${row.lastName || ''}`}/>
    </>
  );
};