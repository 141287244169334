import React from 'react';
import { Checkbox, FormControl, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { CustomTooltip } from 'components';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { themeDefault } from 'themeDefault';

import { ListItemContainer, ListItemTtitle, MenuItemSelected, MenuItemStyled, SelectInput, ValueElement } from './SelectFilter.styled';

type SelectFilterProps = {
  handleSelectItem: (tags: string[] | string) => void;
  selectedItems: string[] | string;
  placeholder: string;
  multiple?: boolean;
  items: {
    id: number | string,
    title: string,
    hexValue?: string
  }[]
  isDisabledTranslation?: boolean
}

export const SelectFilter = ({handleSelectItem, selectedItems, placeholder, items, multiple = true, isDisabledTranslation = false}: SelectFilterProps) => {
  const [t] = useTranslation();

  const handleChange = (e: SelectChangeEvent<typeof selectedItems>) => {
    const { target: { value } } = e;
    handleSelectItem(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const inputWidth = isMobile ? '90%' : '70%';

  return (
    <FormControl sx={{ m: '0 auto', marginBottom: '5%', width: inputWidth }}>
      <SelectInput
        multiple={multiple}
        size='small'
        displayEmpty
        value={selectedItems}
        onChange={handleChange}
        renderValue={
          selectedItems.length === 0 ?
            () => <ValueElement>- {placeholder} -</ValueElement> :
            (selected: string | string[]) => typeof selected === 'string' ? t(selected) : selected.map(s => t(s)).join(', ')}>
        {items.map((item) => (
          <MenuItem key={item.id} value={item.title} component={selectedItems.indexOf(item.title) > -1 ? MenuItemSelected : MenuItemStyled}>
            <ListItemContainer>
              {item.hexValue && <div style={{ width: 13, height: 13, marginRight: 5, backgroundColor: item.hexValue }}></div>}
              <ListItemTtitle>
                <CustomTooltip theme={themeDefault.overflowText} text={isDisabledTranslation ? item.title : t(item.title)} />
              </ListItemTtitle>
              <Checkbox checked={selectedItems.includes(item.title)} />
            </ListItemContainer>
          </MenuItem>
        ))}
      </SelectInput>
    </FormControl>
  );
};