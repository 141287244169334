import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const ImageChangerContainer = styled(Box)(
  ({ theme }) => ({
    paddingTop: '20px',
    paddingBottom: '20px',
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      color: themeDefault.palette.whiteColor,
      display: 'block',
      margin: '20px auto',
      padding: '10px 36px',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 60px',
    }
  }),
);

export const CommentTextModal = styled(Typography)(
  () => ({
    ...themePublic.commentTextModal as CSSProperties,
    marginBottom: '30px',
  }),
);

export const UploaderContainer = styled(Box)(
  () => ({
    paddingBottom: '20px',
    maxWidth: '60vw',
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      padding: '10px 36px',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      },
    },
  }),
);

export const SupportedFormatsText = styled(Typography)(
  () => ({
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'center',
  }),
);
