import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
mutation UpdateCurrentUser($input: UpdateUserInput!) {
  updateCurrentUser(input: $input) {
        id,
        firstName,
        lastName,
        email,
        emailChanged
    }
  }
`;

export const CHANGE_EMAIL = gql`
mutation ChangeEmail($token: String!, $email: String!) {
  changeEmail(token: $token, email: $email)
  }
`;

export const REGISTER_USER_AS_ADMIN = gql`
mutation registerUserAsAdmin ($input: RegisterUserAsAdminInput) {
  registerUserAsAdmin (input: $input) {
      id
      firstName
      lastName
      email
      firebaseId
      invitationsNo
      status
      role
  }
}
`;
export const EDIT_USER_AS_ADMIN = gql`
mutation updateUser ($input: UpdateUserAsAdminInput) {
  updateUser (input: $input) {
      id
      firstName
      lastName
      email
      emailChanged
  }
}
`;
export const REGISTER_USER = gql`
mutation registerUser ($input: RegisterUserInput) {
  registerUser (input: $input) {
      id
      firstName
      lastName
      email
      firebaseId
      invitationsNo
      status
      role
  }
}
`;
export const SET_PASSWORD = gql`
mutation setPassword ($token: String, $password: String) {
  setPassword (token: $token, password: $password)
  }
`;

export const REQUEST_CHANGE_PASSWORD = gql`
mutation {
  changePassword
  }
`;

export const FORGOT_PASSWORD = gql`
mutation setPassword ($email: String) {
  forgotPassword (email: $email)
  }
`;

export const ACTIVATE_USER = gql`
mutation activateUser ($email: String) {
  activateUser (email: $email) {
    id
    firstName
    lastName
    email
    status
  }
}
`;

export const DEACTIVATE_USER = gql`
mutation deactivateUser ($email: String) {
  deactivateUser (email: $email) {
    id
    firstName
    lastName
    email
    status
  }
}
`;

export const BULK_CHANGE_STATUS_USERS = gql`
mutation BulkChangeStatusUsers ($userIds: [Long!], $isActive: Boolean!) {
  bulkChangeStatusUsers (userIds: $userIds, isActive: $isActive)
}
`;

export const UPDATE_USER_SETTINGS = gql`
mutation UpdateUserSettings ($input: UserSettingsInput!) {
  updateUserSettings (input: $input) {
    userId
    value
  }
}
`;
