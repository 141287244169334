import React from 'react';
import {
  Dialog,
  DialogContent
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ContactsPage, InvitationSendPage } from 'pages';
import { themeDefault, themePublic } from 'themeDefault';

import { CloseIconStyled, ContentContainer, Title } from './ImportGuestsFromContacts.styled';

type ImportGuestsFromContactsProps = {
  isOpen: boolean,
  handleClose: () => void,
  forPreview: boolean
};

export const ImportGuestsFromContacts = ({isOpen, handleClose, forPreview = false}: ImportGuestsFromContactsProps) => {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg' fullScreen={isMobile} sx={!isMobile && forPreview ? themePublic.importingContactsModalConatinerPreview : themePublic.importingContactsModalConatinerSend}>
      <DialogContent sx={isMobile ? { padding: 0, backgroundColor: themeDefault.palette.secondaryBackgrounColor } : { padding: '1rem 0', height: '60%' }}>
        <CloseIconStyled onClick={handleClose}/>
        {forPreview ?
          <InvitationSendPage forPreview={true}/> :
          <ContentContainer>
            <Title>{t('importExistingContacts')}</Title>
            <ContactsPage importingProcess={true} handleClose={handleClose} />
          </ContentContainer>
        }
      </DialogContent>
    </Dialog>
  );
};