import React from 'react';

import { MobilePublicContactEntryLinksTableEditRow, MobilePublicContactEntryLinksTableRow } from 'components';
import { PublicContactEntryAuthorisationType, TagType } from 'types';

import { PaperStyled } from './MobilePublicContactEntryLinksTable.styled';

type Props = {
  items: PublicContactEntryAuthorisationType[];
  lookupTags?: TagType[],
  inlineEdit?: {
    active?: boolean;
    id?: number;
    itemDefaults?: Partial<PublicContactEntryAuthorisationType>;
  };
  onSaveItem?: (item: Partial<PublicContactEntryAuthorisationType>) => Promise<void>;
  onDeleteItem?: (id?: number) => Promise<void>;
  onStartEdit?: (id?: number) => void;
  onCancelEdit?: () => void;
};

export const MobilePublicContactEntryLinksTable = ({
  items,
  lookupTags,
  inlineEdit,
  onSaveItem,
  onDeleteItem,
  onStartEdit,
  onCancelEdit,
}: Props) => {
  return (
    <PaperStyled>
      {inlineEdit?.active && !inlineEdit?.id &&
        <MobilePublicContactEntryLinksTableEditRow
          item={inlineEdit.itemDefaults}
          lookupTags={lookupTags}
          onSave={(props) => onSaveItem({ ...props, id: undefined })}
          onCancel={onCancelEdit} />
      }
      {items?.map((item, i) => (
        inlineEdit?.active && inlineEdit?.id && item.id === inlineEdit?.id ?
          <MobilePublicContactEntryLinksTableEditRow
            key={i} item={item}
            lookupTags={lookupTags}
            onSave={(props) => onSaveItem({ ...props, id: item.id })}
            onCancel={onCancelEdit} /> :
          <MobilePublicContactEntryLinksTableRow
            key={i} item={item}
            onStartEdit={() => onStartEdit?.(item.id)}
            onDelete={() => onDeleteItem?.(item.id)} />
      ))}
    </PaperStyled>
  );
};
