import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TooltipContainer = styled(Tooltip)(
  () => ({
    zIndex: 7000,
  }),
);

export const TypographyFont = styled(Typography)(
  () => ({
    fontSize: '16px',
  }),
);
