import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const CommentTextModal = styled(Typography)(
  () => ({
    ...themePublic.commentTextModal as CSSProperties,
  }),
);

export const InputGroup = styled(Box)(
  () => ({
    justifyContent: 'space-between',
    width: '100%',
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    float: 'right',
    marginTop: '1rem',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      borderRadius: 0,
    }
  }),
);
