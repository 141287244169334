import { gql } from '@apollo/client';

export const REPLACE_PLACEHOLDER_DEFAULT_VALUES = gql`
mutation ReplacePlaceholderDefaultValues ($contentInput: String){
  replacePlaceholderDefaultValues (contentInput: $contentInput)
}
`;

export const REPLACE_PLACEHOLDER_VALUES = gql`
mutation ReplacePlaceholderValues ($contentInput: String, $invitationId: Long!, $guestId: Long, $senderId: Long){
  replacePlaceholderValues (contentInput: $contentInput, invitationId: $invitationId, guestId: $guestId, senderId: $senderId)
}
`;

export const REPLACE_PLACEHOLDER_FOR_INVITATION_PREVIEW = gql`
mutation replacePlaceholdersForInvitationPreview ($invitationId: Long!, $guestId: Long, $senderId: Long) {
  replacePlaceholdersForInvitationPreview (invitationId: $invitationId, guestId: $guestId, senderId: $senderId) {
      envelope {
        contentJson
        music {
          url
        }
      }
      evites {
        id
        name
        contentJson
      }
  }
}
`;

export const REPLACE_PLACEHOLDER_FOR_INVITATION_TEMPLATE_PREVIEW = gql`
mutation ReplacePlaceholdersForInvitationTemplatePreview ($invitationTemplateId: Long!) {
  replacePlaceholdersForInvitationTemplatePreview (invitationTemplateId: $invitationTemplateId) {
      envelope {
        contentJson
        music {
          url
        }
      }
      evites {
        id
        name
        contentJson
      }
  }
}
`;
