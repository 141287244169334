import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardsContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  }),
);
