import React, { CSSProperties, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Loader, MobileFilters, MobilePagination, MobileSearch, TablePagination, UserListHeader, UserModal, UserlistTable } from 'components';
import { useAuth } from 'contexts';
import { FORM_TYPE, NUMBER_OF_ITEMS_PER_PAGE, ORDER_TYPE, USER_ROLES, USER_SETTINGS_CONFIG, USER_STATUS } from 'enums';
import { FIRST_PAGE, userColumns } from 'helpers';
import { useUsersStore } from 'store';
import { themePublic } from 'themeDefault';
import { FilterConfigProps, UseAuthProps } from 'types';

import { AddNewUserButtonMobile, UserListPageBody, UserListPageContent } from './Userlist.styled';

export const UserlistPage = () => {
  const [t] = useTranslation();
  const { saveUserSettings }: UseAuthProps = useAuth();
  const { params, setParams, pageInfo, isLoading, searchText, setSearchText, selectedItems: selectedUsers } = useUsersStore();

  const [ isOpenedFilterModal, setIsOpenFilterModal ] = useState(false);
  const [ isOpenedAddModal, setIsOpenedAddModal ] = useState(false);

  const setRowsPerPage = (rowsPerPage: number) => {
    setParams({
      ...params,
      rowsPerPage: rowsPerPage,
      page: FIRST_PAGE
    });
  };
  const setPage = (page: number) => {
    setParams({
      ...params,
      page: page
    });
  };

  const filterConfig: FilterConfigProps[] = [
    {
      id: 0,
      label: t('selectRoles'),
      body: (Object.keys(USER_ROLES) as (keyof typeof USER_ROLES)[]).map((item, index) => ({id: index, title: t(item.toLowerCase())})),
      multiple: true,
      selectedItems: params.selectedRoles,
      handleSelectItem: (item) => {
        setParams({...params, selectedRoles: item as string[]});
      },
      placeholder: t('selectRoles'),
      isDisabledTranslation: false
    },
    {
      id: 1,
      label: t('selectStatus'),
      body: (Object.keys(USER_STATUS) as (keyof typeof USER_STATUS)[]).map((item, index) => ({id: index, title: t(item.toLowerCase())})),
      multiple: true,
      selectedItems: params.selectedStatus,
      handleSelectItem: (item) => {
        setParams({...params, selectedStatus: item as string[]});
      },
      placeholder: t('selectStatus'),
      isDisabledTranslation: false
    },
  ];

  if (isMobile) {
    return (
      <UserListPageBody>
        <MobileSearch
          theme={themePublic.mobileSearchContainer as CSSProperties}
          setSearchText={setSearchText}
          searchText={searchText}
          handleOpenFilters={() => setIsOpenFilterModal(true)}
          showFilters={true}/>
        <UserListPageContent>
          {
            selectedUsers.length === 0 && !isLoading &&
              <AddNewUserButtonMobile elevation={3} onClick={() => setIsOpenedAddModal(true)}>
                <AddIcon fontSize='large' />
              </AddNewUserButtonMobile>
          }
          <UserModal type={FORM_TYPE.CREATE} isOpen={isOpenedAddModal} handleClose={() => setIsOpenedAddModal(false)}/>
          <Loader inProgress={isLoading} />
          <UserlistTable />
          {!isLoading &&
            <MobilePagination
              hasPreviousPage={pageInfo.hasPreviousPage}
              hasNextPage={pageInfo.hasNextPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              page={params.page}
              rowsLength={pageInfo.totalCount}
              rowsPerPage={params.rowsPerPage}/>}
        </UserListPageContent>
        <MobileFilters
          isOpen={isOpenedFilterModal}
          filterConfig={filterConfig}
          searchText={searchText}
          setSearchText={setSearchText}
          handleSortBy={(sortBy) => {
            setParams({
              ...params,
              order: ORDER_TYPE.ASC,
              orderBy: sortBy
            });
            saveUserSettings(USER_SETTINGS_CONFIG.USERS_TABLE, sortBy, ORDER_TYPE.ASC);
          }}
          selectedSort={params.orderBy}
          handleClose={(modalState) => setIsOpenFilterModal(modalState)}
          sortValues={userColumns.filter((item) => item.enableSorting)
            .map(item => ({ title: t(item.title), sortableName: item.sortableName }))}/>
      </UserListPageBody>
    );
  }

  return (
    <UserListPageBody>
      <UserListHeader/>
      <UserListPageContent>
        <Loader inProgress={isLoading} />
        <UserlistTable/>
        {!isLoading &&
          <TablePagination
            invitationToShow={NUMBER_OF_ITEMS_PER_PAGE}
            rowsLength={pageInfo.totalCount}
            rowsPerPage={params.rowsPerPage}
            page={params.page}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            tableType={USER_SETTINGS_CONFIG.USERS_TABLE} />}
      </UserListPageContent>
    </UserListPageBody>
  );
};