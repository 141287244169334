import { create } from 'zustand';

import { DefaultGuestParams, generateStoreObject } from 'helpers';
import { GuestStoreType, GuestType, InvitationProps, SelectedGuests } from 'types';

export const useGuestStore = create<GuestStoreType>((set, get) => ({
  ...generateStoreObject(set, get, DefaultGuestParams),
  contactIds: [],
  isFetching: false,
  invitationId: 0,
  updateGuests: (contactIds: number[], invitationId: number ) => set({ contactIds: contactIds, invitationId: invitationId, isFetching: true }),
  updateGuestsCompleted: () => set({ contactIds: [], invitationId: 0, isFetching: false }),
  selectedGuests: [],
  setSelectedGuests: (guest: GuestType = null) => {
    if (!guest) {
      set({selectedGuests: []});
      return;
    }
    if (get().selectedGuests.some((c: SelectedGuests) => c.guestId === guest.id)) {
      set({selectedGuests: get().selectedGuests.filter(item => item.guestId !== guest.id)});
    } else {
      set({selectedGuests: [ ...get().selectedGuests, { guestId: guest.id, contactId: guest.contact.id}]});
    }
  },
  setMultipleGuests: (guests: SelectedGuests[]) => set({selectedGuests: guests}),
  invitation: null,
  setInvitation: (invitation: InvitationProps) => set({invitation: invitation}),
  isOpenImportModal: false,
  setIsOpenImportModal: (state: boolean) => set({isOpenImportModal: state}),
  guestForPreview: null,
  setGuestForPreview: (guest: GuestType) => set({guestForPreview: guest, isOpenImportModal: false})
}));