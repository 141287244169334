import React, { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ActionMenu, ConfirmationModal, CustomTooltip } from 'components';
import { calculateMenuPlacement } from 'helpers';
import { DELETE_CONTACTS, graphMutationMiddleware } from 'services';
import { useContactStore } from 'store';
import { themePublic } from 'themeDefault';
import { ContactType } from 'types';

import {
  BoldInfoData, ContactMobilePaperContainer, ContactMobilePaperContainerSelected,
  DataContainerMobile, DataInfoContainerMobile, ImportingDataInfoContainerMobile,
  PersonIconStyled, ThemeMultipleLinesText, ThemeOverlappedText
} from './SingleContactItem.styled';

type ContactsTableProps = {
  handleSelectedContacts: (id: number) => void,
  selectedContacts: number[],
  contact: ContactType,
  importingProcess: boolean,
}

export const SingleContactItem = ({ contact, handleSelectedContacts, selectedContacts, importingProcess = false }: ContactsTableProps) => {
  const [t] = useTranslation();
  const { setContactForEditing, changeExecuteRefetch } = useContactStore();

  const [ deleteContact, { loading: deleteLoading }] = graphMutationMiddleware(DELETE_CONTACTS);

  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: Edit,
      functionHandler: () => setContactForEditing(contact)
    },
    {
      id: 1,
      title: t('delete'),
      icon: Delete,
      functionHandler: () => setDeleteModal(true)
    },
  ];

  const handleDelete = () => {
    deleteContact({variables: {
      contactId: contact.id
    }}).then(() => {
      changeExecuteRefetch();
      setDeleteModal(false);
    });
  };

  return (
    <>
      <Paper component={selectedContacts.includes(contact.id) ? ContactMobilePaperContainerSelected : ContactMobilePaperContainer} elevation={2}>
        <Box component={importingProcess ? ImportingDataInfoContainerMobile : DataInfoContainerMobile}>
          <PersonIconStyled/>
          <DataContainerMobile onClick={() => handleSelectedContacts(contact.id)}>
            <BoldInfoData>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={`${contact.lastName} ${contact.firstName}`} />
            </BoldInfoData>
            <Typography>
              <CustomTooltip theme={ThemeOverlappedText} text={contact.phoneNumber} />
            </Typography>
            <Typography>
              <CustomTooltip theme={ThemeOverlappedText} text={contact.email} />
            </Typography>
          </DataContainerMobile>
        </Box>
        {
          !importingProcess &&
            <ActionMenu iconTheme={themePublic.actionMenuIconStyle} propsData={actionMenuProps} disablePortal={isMobile} menuPlacement={calculateMenuPlacement(isMobile)} />
        }
      </Paper>
      <ConfirmationModal
        loading={deleteLoading}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleDelete}
        confimMessage={`${t('deletionConfirmMessage')} ${contact.firstName || ''} ${contact.lastName || ''}`}/>
    </>
  );
};