import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  AddBoxRounded as AddStampIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FormatAlignCenter as FormatAlignCenterIcon,
  FormatAlignJustify as FormatAlignJustifyIcon,
  FormatAlignLeft as FormatAlignLeftIcon,
  FormatAlignRight as FormatAlignRightIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Menu as MenuIcon,
  RefreshRounded as RedoIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent, Fade, FormControl, Grid,
  ListItemText,
  Menu, MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { fabric } from 'fabric';
import 'fabric-history';
import { HexColorPicker } from 'react-colorful';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AddFontModal, /* AudioPlayer ,*/ CanvasActionButton, CustomTooltip, Loader, SetBackgroundImageModal, ValueAndSliderInput, VirtualKeyboard } from 'components';
import {
  CANVAS_ELEMENTS_IDS,
  EDITOR_CONTAINER_ELEMENTS_IDS,
  ENVELOPE_SIZE,
  ENVELOPE_STAMPS_PADDING,
  FONT_PROPERTY,
  SLIDER_ELEMENTS_IDS,
  VALIDATION_RESTRICTION
} from 'enums';
import {
  Prompt,
  SCALING_COEFFICIENT_NON_RESIZED_IMAGES,
  checkIfElementContainsElement,
  checkIfElementsContainsElement,
  delay,
  findDefaultFontValue,
  fontSize,
  getRouteForNavigation,
  initAligningGuidelines,
  initCenteringGuidelines,
  lineSpacing,
  onImageErrorLoading,
  removeFocusFromTextboxMobile,
  resetTextSelection,
  selectAndRemoveFocus,
  showToast,
  switchToEditMode,
  textSpacing
} from 'helpers';
import { addBackgroundIcon, addBackgroundIconDisabled, addTextIcon, addTextIconDisabled } from 'resources';
import {
  GET_FONTS,
  GET_INVITATION_BY_ID,
  GET_INVITATION_TEMPLATE_BY_ID,
  /* GET_MUSIC, */
  GET_PLACEHOLDERS,
  GET_STAMPS,
  REPLACE_PLACEHOLDER_DEFAULT_VALUES,
  REPLACE_PLACEHOLDER_VALUES,
  UPDATE_INVITATION_ENVELOPE,
  UPDATE_INVITATION_TEMPLATE_ENVELOPE,
  graphLazyQueryMiddleware,
  graphMutationMiddleware
} from 'services';
import { themePublic } from 'themeDefault';
import { FontType, ImageType, PlaceholderType } from 'types';

import {
  ActionButtonContainer, AddFontLabelDesignPage, BottomCanvasButtons, BottomCanvasButtonsContainer,
  CanvasContainerDesignPageStyle, CanvasPreviewImgStyle, CanvasWrapper, ColorTextField, ContainerSubmenuEndButtons, ContainerSubmenuStartButtons,
  EditorContainer, EditorElementContainer, EditorElementDesignPage, EditorNameLabel,
  FontEditorNameDesignPage, FontFamilySelectItem, FontMenuItem, FormControlSelect, HiddenEditorContainer,
  IconButtonStyled, ImageEditorContainer, ImagePickerDesignPage, LessOptionsContainerMobile,
  MoreHorizIconStyled, MoreOptionIconButton, MoreOptionsContainerMobile, MoreOptionsText, NotDisplayed, NotDisplayedStyle, OpenedSubmenuContainer,
  PageButtonsContainer, PageContent, PageNextButton, PagePrevButton, PlaceholderMenuItem, PreviewSwitchButton, RoundColorElement,
  SelectCardButton, SelectCardContainerMobile, SelectCardValueTooltip, SelectPlaceholderButton, SetBackgroundImageDialogContent, StampContainer,
  StampItemImage, StampsContainer, SubmenuButton, SubmenuButtonImageIcon, SubmenuContainer, SubmenuContainerMobile, SubmenuExtendedContainerMobile,
  SubmenuPlaceholdersMenuItem, ToggleContainer
} from './InvitationEnvelopePage.styled';

type InvitationEnvelopeProps = {
  isTemplate?: boolean,
  invitationTemplateStatus?: string,
  setInvitationTemplateStatus?: (invitatioNTemplateStatus: string) => void,
  saveInvitationTemplate?: () => void,
}

type Font = {
  fontFamily: string
  fill: string
  fontSize: number
  fontWeight: string
  fontStyle: string
  underline: boolean
  textAlign: string
  lineHeight: number
  charSpacing: number
}

export const InvitationEnvelopePage = ({ isTemplate = false, invitationTemplateStatus, setInvitationTemplateStatus, saveInvitationTemplate }: InvitationEnvelopeProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const navLocation = useLocation();
  const params = useParams();
  const keyboardRef = useRef(null);
  const initialCanvasState = useRef('');
  const canvasRef = useRef(null);
  const fontsRef = useRef(null);
  const colorHexRef = useRef(null);
  const canvasElementRef = useRef(null);
  const canvasContainerRef = useRef(null);

  const [ getInvitation, { refetch: invitationRefetch, data: invitationData }] = graphLazyQueryMiddleware(isTemplate ? GET_INVITATION_TEMPLATE_BY_ID : GET_INVITATION_BY_ID);
  const [ getFonts, {refetch: fontsRefetch, data: fontsData}] = graphLazyQueryMiddleware(GET_FONTS);
  const [ getPlaceholders, {data: placeholderData}] = graphLazyQueryMiddleware(GET_PLACEHOLDERS);
  const [ getStamps, {data: stampsData}] = graphLazyQueryMiddleware(GET_STAMPS);
  //const [ getMusic, {data: musicData}] = graphLazyQueryMiddleware(GET_MUSIC);
  const [updateEnvelope] = graphMutationMiddleware(isTemplate ? UPDATE_INVITATION_TEMPLATE_ENVELOPE : UPDATE_INVITATION_ENVELOPE);
  const [ replacePlaceholderValues, { loading: replacePlaceholdersLoading }] = graphMutationMiddleware(REPLACE_PLACEHOLDER_VALUES);
  const [ replacePlaceholderDefaultValues, { loading: replaceDefaultPlaceholdersLoading }] = graphMutationMiddleware(REPLACE_PLACEHOLDER_DEFAULT_VALUES);

  const [ invitation, setInvitation ] = useState(null);
  const [invitationType] = useState(isTemplate ? 'invitationTemplate' : 'invitation');
  const [ canvas, setCanvas ] = useState(null);
  const [ canvasForResultImage, setCanvasForResultImage ] = useState(null);
  const [ showCanvasPreview, setShowCanvasPreview ] = useState(false);
  const [ canvasTextboxMap, setCanvasTextboxMap ] = useState({});
  const [ canvasPreviewImage, setCanvasPreviewImage ] = useState(null);
  const [ isSavingCanvas, setIsSavingCanvas ] = useState(false);
  const [ canvasIsChanged, setCanvasIsChanged ] = useState(false);
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ addFontModal, setAddFontModal ] = useState(false);
  const [ font, setFont ] = useState<Font>({
    fontFamily: null,
    fill: '#000000',
    fontSize: 24,
    fontWeight: 'normal',
    fontStyle: 'normal',
    underline: false,
    textAlign: 'center',
    lineHeight: 1,
    charSpacing: 50
  });
  const [ showColorPicker, setShowColorPicker ] = useState(false);
  const [ isOpenedStampMenu, setIsOpenedStampMenu ] = useState<boolean>(false);
  const [ isTextSelected, setIsTextSelected ] = useState(false);
  const [ isImageSelected, setIsImageSelected ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ imageLoading, setImageLoading ] = useState(false);
  const [ fontLoading, setFontLoading ] = useState(false);
  const [ moreOption, setMoreOption ] = useState(false);
  const [ placeholderEl, setPlaceholderEl ] = useState<null | HTMLElement>(null);
  const isOpenPlaceholderMenu = Boolean(placeholderEl);
  const [ musicId, setMusicId ] = useState<null | number>(0);
  const [ canvasSize, setCanvasSize ] = useState({width: ENVELOPE_SIZE.WIDTH, height: ENVELOPE_SIZE.HEIGHT});
  const [ isUndoDisabled, setIsUndoDisabled ] = useState(true);
  const [ isRedoDisabled, setIsRedoDisabled ] = useState(true);
  const [ canvasScaleFactor, setCanvasScaleFactor ]= useState(1);
  /* const [ isOpenMusicSelect, setIsOpenMusicSelect ] = useState(false); */
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ selectedObject, setSelectedObject ] = useState(null);
  const [ selectedKey, setSelectedKey ] = useState(null);
  const [ showStampUploadDialog, setShowStampUploadDialog ] = useState(false);
  const [ previewContent, setPreviewContent ] = useState<any>('');
  const [ loadingCanvas, setLoadingCanvas ] = useState(true);
  const [ canvasHistory, setCanvasHistory ] = useState({historyUndo: [], historyRedo: []});
  const [ isSaveDisabled, setIsSaveDisabled ] = useState(false);

  const handleNavigate = (route: string) => navHistory(route);

  const openPlaceholderMenu = (e: React.MouseEvent<HTMLElement>, element: string) => {
    switch (element) {
      case 'background':
        setAnchorEl(e.currentTarget);
        return;

      case 'placeholder':
        setPlaceholderEl(e.currentTarget);
        return;
    }
  };

  const closePlaceholderMenu = () => {
    setAnchorEl(null);
    setPlaceholderEl(null);
  };

  const checkForData = () => {
    if (!invitationData) {
      return;
    }

    if (!(invitationType in invitationData)) {
      showToast('error', t('invalidId'));
      handleNavigate('/invitationTemplates');
      return;
    }

    setInvitation(invitationData[invitationType]);
  };

  const getInv = () => {
    if (params.id) {
      getInvitation({
        variables: {
          id: Number(params.id)
        }
      }).then(res => res && setInvitation(isTemplate ? res.data.invitationTemplate : res.data.invitation))
        .catch(() => {
          setInvitation(null);
        });
    }
  };

  const setCanvasObjProperties = (obj: fabric.Object) => {
    if (obj instanceof fabric.Textbox) {
      obj.setControlsVisibility({
        mt: false,
        mb: false
      });
    }
    obj.selectable = true;
  };

  useEffect(() => {
    getInv();
  }, [params.id]);

  useEffect(() => {
    checkForData();
  }, [invitationData]);

  useEffect(() => {
    isHebrew && !isMobile && mapTextboxs();
  }, [isHebrew]);

  useEffect(() => {
    if (invitation) {
      getPlaceholders();
      if (invitation.envelope) {
        initialCanvasState.current = invitation.envelope.contentJson;
        setCanvasIsChanged(false);

        const contentJson = JSON.parse(invitation.envelope.contentJson);
        canvas.loadFromJSON(contentJson, () => {

          calculateCanvasScaleFactor();
          canvas.historyNextState = canvas._historyNext();
          canvas.clearHistory();
          canvas.historyUndo = canvasHistory.historyUndo ?? [];
          canvas.historyRedo = canvasHistory.historyRedo ?? [];
          checkHistory();
          setLoadingCanvas(false);
        });

        setMusicId(invitation.envelope.musicId);
      } else {
        setMusicId(null);
      }
    }
  }, [invitation]);

  useEffect(() => {
    if (invitationTemplateStatus) {
      saveCanvas();
      saveInvitationTemplate();
      setInvitationTemplateStatus(undefined);
    }
  }, [invitationTemplateStatus]);

  useEffect(() => {
    if (canvas) {
      canvas.extraProps.push('width');
      canvas.extraProps.push('height');
      canvas.extraProps.push('viewportTransform');

      canvas.on('selection:updated', handleElement);
      canvas.on('selection:created', handleElement);
      canvas.on('selection:cleared', () => {
        setIsTextSelected(false);
        setIsImageSelected(false);
        setShowKeyboard(false);
        setShowColorPicker(false);
      });
      canvas.on('history:append', historyAppend);
      canvas.on('history:undo', checkHistory);
      canvas.on('history:redo', checkHistory);

      canvas.backgroundColor = '#fff';
      canvasRef.current = canvas;

      initCenteringGuidelines(canvas, ENVELOPE_SIZE.WIDTH, ENVELOPE_SIZE.HEIGHT);
      initAligningGuidelines(canvas);
    }
  }, [canvas]);

  const mapTextboxs = () => {
    const textboxMap : any = {};
    for (let i = 0; i < canvas._objects.length; i++) {
      if (canvas._objects[i].type === 'textbox') {
        const a = i.toString();
        textboxMap[a] = canvas._objects[i];
      }
    }
    setCanvasTextboxMap(textboxMap);
  };

  const addTextboxToMap = (textbox: fabric.Textbox) => {
    const iterator = Object.keys(canvasTextboxMap).length+1;
    const textboxMap : any = {
      ...canvasTextboxMap
    };
    const a = iterator.toString();
    textboxMap[a] = textbox;
    setCanvasTextboxMap(textboxMap);
  };

  useEffect(() => {
    if (!isMobile && isHebrew) {
      for (const key of Object.keys(canvasTextboxMap)) {
        if ((canvasTextboxMap as any)[key]?.top === selectedObject?.selected[0]?.top &&
            (canvasTextboxMap as any)[key]?.left === selectedObject?.selected[0]?.left &&
            (canvasTextboxMap as any)[key]?.text === selectedObject?.selected[0]?.text) {
          setSelectedKey(key);
          break;
        }
      }
    }
  }, [ selectedObject, canvasTextboxMap ]);

  const deleteSelectedObjects = (deleteSelectedObject: boolean) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject.type === 'activeSelection') {
      activeObject.forEachObject((obj: any) => canvas.remove(obj));
    } else if (!deleteSelectedObject && activeObject.type === 'textbox' && activeObject?.isEditing) {
      return;
    } else {
      activeObject && canvas.remove(activeObject);
    }
    !isMobile && isHebrew && mapTextboxs();
    setIsImageSelected(false);
    setIsTextSelected(false);
  };

  const handleKeyPress = (e: any) => {
    !isMobile && isHebrew && mapTextboxs();
    if (e.keyCode === 90 && e.ctrlKey) {
      undo();
    } else if (e.keyCode === 89 && e.ctrlKey) {
      redo();
    } else if (e.keyCode === 46) {
      deleteSelectedObjects(false);
    }
  };

  const handleColorPickerClicked = (isOpened: boolean) => {
    if (isOpened) {
      document.addEventListener('click', handleOutsideColorPickerClick, false);
    } else {
      document.removeEventListener('click', handleOutsideColorPickerClick, false);
    }
  };

  const handleOutsideColorPickerClick = (e: any) => {
    if (document.getElementById(EDITOR_CONTAINER_ELEMENTS_IDS.FONT_COLOR_PICKER)?.contains(e.target)
      || document.getElementById(EDITOR_CONTAINER_ELEMENTS_IDS.COLOR_PICKER_BUTTON)?.contains(e.target)
      || document.getElementById(SLIDER_ELEMENTS_IDS.OUTLINED_BASIC)?.contains(e.target)
      || document.getElementById('fontColorPickerMobile')?.contains(e.target)
      || document.getElementById('colorPickerButtonMobile')?.contains(e.target)
      || document.getElementById('outlined-basic-mobile')?.contains(e.target)) {
      return;
    }

    setShowColorPicker(false);
    handleColorPickerClicked(false);
  };

  useEffect(() => {
    handleColorPickerClicked(showColorPicker);
  }, [showColorPicker]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [ handleKeyPress, document ]);

  const initCanvas = (id: string) => new fabric.Canvas(id, {});

  const registerFont = (fontName: string, url: string) => {
    const fontFace = new FontFace(fontName, `url(${url})`);
    (document as any).fonts.add(fontFace);
    fontFace.load();
  };

  const returnFocus = (toRef: boolean) => {
    if (isMobile) {
      return;
    }
    delay(100).then(() => {
      if (toRef) {
        canvasRef.current.getActiveObject()?.hiddenTextarea.focus();
      } else {
        canvas.getActiveObject()?.hiddenTextarea.focus();
        canvas.renderAll();
      }
    });
  };

  const handleClickOnEditorContainer = (target: HTMLElement) : boolean => {
    if (!document.getElementById('editorContainer')?.contains(target)) {
      return false;
    }

    if (checkIfElementsContainsElement(Object.values(EDITOR_CONTAINER_ELEMENTS_IDS), target)) {
      returnFocus(true);
      return true;
    }

    if (checkIfElementsContainsElement(Object.values(SLIDER_ELEMENTS_IDS), target)) {
      return true;
    }

    return false;
  };

  const ifClickedCanvasElement = (target: HTMLElement) : boolean => {
    return canvasRef.current.getActiveObject()?.type !== 'textbox' ||
           fontsRef.current.some((item: any) => checkIfElementContainsElement('fontItem' + item.name, target)) ||
           document.getElementsByClassName('canvas-container')[0].contains(target) ||
           checkIfElementsContainsElement(Object.values(CANVAS_ELEMENTS_IDS), target);
  };

  const returnFocusToSelectedTextbox = (e: any) => {
    if (ifClickedCanvasElement(e.target) || handleClickOnEditorContainer(e.target)) {
      return;
    }

    if (checkIfElementContainsElement('fontSelect', e.target)) {
      returnFocus(true);
      return;
    }

    canvasRef.current.getActiveObject().exitEditing();
    canvasRef.current.renderAll();
  };

  useEffect(() => {
    setCanvas(initCanvas('canvasMain'));
    setCanvasForResultImage(initCanvas('canvasImage'));
    getFonts().then(res => {
      res?.data?.fonts.filter((f: any) => f.ttfUrl).forEach((f: any) => {
        registerFont(f.name, f.ttfUrl);
      });
    });
    getStamps();
    //getMusic();
    return () => !isMobile && document.removeEventListener('mousedown', returnFocusToSelectedTextbox, false);
  }, []);

  useEffect(() => {
    setFont({
      ...font,
      fontFamily: fontsData?.fonts[0].name,
    });
    fontsRef.current = fontsData?.fonts;
  }, [fontsData]);

  useEffect(() => {
    if (!canvas) {
      return;
    }
    calculateCanvasScaleFactor();
  }, [ canvasSize, canvasElementRef.current ]);

  const calculateCanvasScaleFactor = () => {
    const canvasDiv = canvasElementRef.current;
    if (!canvasDiv) {
      return;
    }
    const availableWidth = canvasDiv.clientWidth;
    const availableHeight = canvasDiv.clientHeight;

    const widthScale = availableWidth / ENVELOPE_SIZE.WIDTH;
    const heightScale = availableHeight / ENVELOPE_SIZE.HEIGHT;
    const scale = Math.min(widthScale, heightScale);

    canvas.setWidth(ENVELOPE_SIZE.WIDTH * scale);
    canvas.setHeight(ENVELOPE_SIZE.HEIGHT * scale);
    canvas.zoomToPoint(new fabric.Point(0, 0), scale);

    setCanvasScaleFactor(scale);
    canvas.renderAll();
  };

  const renderTextFromKeyboard = () => {
    canvas?.renderAll();
  };

  useEffect(() => {
    setFont({
      ...font,
      fontSize: findDefaultFontValue(fontSize, canvasScaleFactor),
    });

    canvas?.zoomToPoint(new fabric.Point(0, 0), canvasScaleFactor);
    canvas?.calcOffset();
    canvas?.renderAll();
  }, [ canvasScaleFactor, canvasSize ]);

  const addText = () => {
    const textObjects = canvas.getObjects().filter((o: any) => o.get('type') === 'textbox');
    const maxTopValue = Math.max(...textObjects.map((o: any) => o.get('top')));

    const newTextBoxTop = (maxTopValue + font.fontSize * 2) > canvas.height / canvasScaleFactor ?
      Math.random() * canvas.height / canvasScaleFactor :
      textObjects.length * font.fontSize * 2 + font.fontSize;

    const textbox = new fabric.Textbox('', {
      width: (canvas.width / canvasScaleFactor) / 2,
      height: 100 / canvasScaleFactor,
      left: (canvas.width / canvasScaleFactor) / 4,
      top: newTextBoxTop,
      fill: font.fill,
      fontSize: font.fontSize,
      fontFamily: font.fontFamily,
      textAlign: font.textAlign,
      lineHeight: font.lineHeight,
      charSpacing: font.charSpacing,
    });

    setCanvasObjProperties(textbox);
    canvas.add(textbox);
    canvas.setActiveObject(textbox);
    textbox.enterEditing();
    textbox.hiddenTextarea.focus();
    !isMobile && isHebrew && addTextboxToMap(textbox);
  };

  const addFont = (fileUrl: string) => {
    setFontLoading(true);
    fontsRefetch().then(res => {
      const newFont = res?.data?.fonts.find((f: any) => f.ttfUrl === fileUrl);
      newFont && registerFont(newFont.name, newFont.ttfUrl);
      setFontLoading(false);
    });
  };

  const renderFont = (styleType: string, value: string | number | number[] | boolean) => {
    setFont({...font, [styleType]: value});
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject?.type === 'image') {
      return;
    }

    const style = {[styleType]: value};

    if (activeObject.isEditing &&
      activeObject.getSelectedText().length !== activeObject.text?.length &&
      activeObject.getSelectedText().length !== 0) {
      activeObject.setSelectionStyles(style);
    } else {
      activeObject.setSelectionStyles(style, 0, activeObject.text?.length);
      activeObject.set(styleType, value);
    }
    isMobile && resetTextSelection(canvas);
    canvas.renderAll();
  };

  const changeFont = (styleType: string, value: string | number | number[] | boolean) => {
    renderFont(styleType, value);
    canvas._historySaveAction();
  };

  const handleElement = (obj: any) => {
    if (obj.selected.length > 1) {
      setIsImageSelected(true);
      setIsTextSelected(true);
    }
    if (obj.selected[0]?.type === 'image') {
      setIsImageSelected(true);
    }
    !isMobile && document.addEventListener('mousedown', returnFocusToSelectedTextbox, false);
    if (obj.selected[0]?.type === 'textbox') {
      setIsTextSelected(true);
      if (localStorage.getItem('isHebrew') === 'true') {
        setIsHebrew(true);
        setShowKeyboard(true);
        setSelectedObject(obj);
      } else {
        setIsHebrew(false);
      }
      setFont({
        ...font,
        fontFamily: obj.selected[0].fontFamily,
        fill: obj.selected[0].fill,
        fontSize: obj.selected[0].fontSize,
        fontWeight: obj.selected[0].fontWeight,
        fontStyle: obj.selected[0].fontStyle,
        underline: obj.selected[0].underline,
        textAlign: obj.selected[0].textAlign,
        lineHeight: obj.selected[0].lineHeight,
        charSpacing: obj.selected[0].charSpacing
      });
    }
  };

  const checkHistory = () => {
    setIsUndoDisabled(!canvas?.historyUndo || canvas.historyUndo.length === 0);
    setIsRedoDisabled(!canvas?.historyRedo || canvas.historyRedo.length === 0);
    setIsSaveDisabled(false);

    const parsedInitialCanvasState = JSON.parse(initialCanvasState.current);
    const parsedCurrentCanvasState = JSON.parse(getCurrentCanvasState());

    delete parsedInitialCanvasState.width;
    delete parsedInitialCanvasState.height;
    delete parsedInitialCanvasState.viewportTransform;

    delete parsedCurrentCanvasState.width;
    delete parsedCurrentCanvasState.height;
    delete parsedCurrentCanvasState.viewportTransform;
    setCanvasIsChanged(JSON.stringify(parsedInitialCanvasState) !== JSON.stringify(parsedCurrentCanvasState));
    canvas.getObjects().forEach((obj: fabric.Object) => setCanvasObjProperties(obj));
  };

  const historyAppend = () => {
    if (canvas) {
      canvas.historyRedo = [];
    }
    checkHistory();
  };

  const setPlaceholder = (field: string) => {
    const textBox = canvas.getActiveObject();
    if (!textBox || textBox.get('type') !== 'textbox') {
      return;
    }

    const textToAppend = field;
    textBox.insertChars(textToAppend, null, textBox.selectionStart, textBox.selectionEnd);
    const textLength = textBox.selectionStart + textToAppend.length;
    canvas.renderAll();

    canvas.discardActiveObject();
    canvas.setActiveObject(textBox);
    returnFocus(false);
    textBox.setSelectionStart(textLength);
    textBox.setSelectionEnd(textLength);
  };

  const addImage = (fileUrl: string) => {
    setImageLoading(true);
    const image = new Image();

    image.crossOrigin = 'anonymous';
    image.src = fileUrl;
    image.onload = function() {
      const fabricImage = new fabric.Image(image);

      let position = { left: 0, top: 0 };
      if (fabricImage.width < VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY || fabricImage.width > VALIDATION_RESTRICTION.FOUR_HUNDRED) {
        fabricImage.scaleToWidth(VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY);
        position = calculateImagePosition(VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY);
      } else if (fabricImage.height < VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY || fabricImage.height > VALIDATION_RESTRICTION.FOUR_HUNDRED) {
        fabricImage.scaleToHeight(VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY);
        position = calculateImagePosition(VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY);
      } else {
        fabricImage.scale(SCALING_COEFFICIENT_NON_RESIZED_IMAGES);
        position = calculateImagePosition(fabricImage.width * SCALING_COEFFICIENT_NON_RESIZED_IMAGES);
      }

      fabricImage.set({ 'left': position.left });
      fabricImage.set({ 'top': position.top });
      canvas.discardActiveObject();
      canvas.add(fabricImage);
      canvas.setActiveObject(fabricImage);
      canvas.renderAll();
      setImageLoading(false);
    };
  };

  const calculateImagePosition = (imageWidth: number) => {
    let leftPosition = canvas.getWidth() / canvasScaleFactor - ENVELOPE_STAMPS_PADDING.LEFT;

    const images = canvas.getObjects().filter((obj: any) => obj.type === 'image');
    images?.map((image: HTMLImageElement) => leftPosition -= getCalculatedImageWidth(image));
    const topPosition = leftPosition - imageWidth > 0 ? ENVELOPE_STAMPS_PADDING.TOP : Math.random() * (500 - 0) + 0;
    leftPosition = leftPosition - imageWidth > 0 ? leftPosition - imageWidth : Math.random() * (500 - 0) + 0;

    return {left: leftPosition, top: topPosition};
  };

  const getCalculatedImageWidth = (image: HTMLImageElement) => {
    if (image.width < VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY || image.width > VALIDATION_RESTRICTION.FOUR_HUNDRED ||
      image.height < VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY || image.height > VALIDATION_RESTRICTION.FOUR_HUNDRED) {
      return VALIDATION_RESTRICTION.HUNDRED_AND_FIFTY + ENVELOPE_STAMPS_PADDING.LEFT;
    } else {
      return image.width * SCALING_COEFFICIENT_NON_RESIZED_IMAGES + ENVELOPE_STAMPS_PADDING.LEFT;
    }
  };

  const saveEnvelopeChanges = async (contentJson: string) => {
    setCanvasHistory({
      historyUndo: [...canvas.historyUndo],
      historyRedo: [...canvas.historyRedo],
    });

    await updateEnvelope({variables: {
      input: {
        invitationId: Number(params.id),
        contentJson: contentJson,
        musicId: musicId
      }
    }}).then(() => {
      invitationRefetch({id: Number(params.id)});
      setIsSavingCanvas(false);
      setCanvasIsChanged(false);
      initialCanvasState.current = contentJson;
    }).catch(() => {
      setIsSavingCanvas(false);
    });
  };

  const saveCanvas = async () => {
    if (!canvasIsChanged) {
      return;
    }

    setIsSavingCanvas(true);
    const jsonData = getCurrentCanvasState();
    await saveEnvelopeChanges(jsonData);
  };

  useEffect(() => {
    if (!previewContent) {
      setCanvasPreviewImage(null);
      return;
    }

    fabric.Image.fromURL(previewContent.backgroundImage?.src, () => {
      canvasForResultImage.loadFromJSON(previewContent, () => {
        setCanvasPreviewImage(canvasForResultImage.toDataURL());
      });
    });
  }, [previewContent]);

  const showCanvasPreviewImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const jsonData = getCurrentCanvasState();
    if (!(e.target.checked)) {
      setPreviewContent('');
      return;
    }

    if (isTemplate) {
      await replacePlaceholderDefaultValues({variables: {
        contentInput: jsonData,
      }}).then((res) => {
        setPreviewContent(res.data.replacePlaceholderDefaultValues.split('\n').join('\\n'));
      }).catch(() => {
        setIsSavingCanvas(false);
      });
    } else {
      await replacePlaceholderValues({variables: {
        contentInput: jsonData,
        invitationId: Number(params.id)
      }})
        .then((res) => {
          setPreviewContent(res.data.replacePlaceholderValues.split('\n').join('\\n'));
        }).catch(() => {
          setIsSavingCanvas(false);
        });
    }
  };

  const changeFontSize = (e: SelectChangeEvent<number>) => {
    changeFont(FONT_PROPERTY.FONT_SIZE, e.target.value);
  };

  const changeTab = async (tabName: string) => {
    await saveCanvas();
    handleNavigate(getRouteForNavigation(navLocation.pathname, tabName, params['*']));
  };

  const getCurrentCanvasState = () => {
    return canvas?._historyNext();
  };

  const disableButtons = () => {
    setIsUndoDisabled(true);
    setIsRedoDisabled(true);
    setIsSaveDisabled(true);
  };

  const undo = () => {
    disableButtons();
    canvas.undo();
    setCanvasSize({width: canvas.getWidth(), height: canvas.getHeight()});
  };

  const redo = () => {
    disableButtons();
    canvas.redo();
    setCanvasSize({width: canvas.getWidth(), height: canvas.getHeight()});
  };

  const getTransformPropertyForRoundColorElement = () => {
    return isIOS ? 'translateY(-8%)' : 'translateY(-50%)';
  };

  const getTransformPropertyForIconElement = () => {
    return isIOS ? 'translateY(-18%)' : 'translateY(-50%)';
  };

  return (
    <>
      <PageContent>
        <Box component={isOpenedStampMenu && !isMobile ? StampContainer : HiddenEditorContainer}>
          <SelectCardButton>
            <ListItemText primary={t('stamps')} />
            <MenuIcon onClick={() => setIsOpenedStampMenu(false)}/>
          </SelectCardButton>
          <StampsContainer container spacing={1} padding={1} maxHeight='690px' overflow='auto'>
            {stampsData && stampsData.stamps.map((item: ImageType) =>
              <Grid key={item.id} item xs={6} onClick={() => {
                setIsOpenedStampMenu(false);
                addImage(item.url);
              }}>
                <StampItemImage src={item.url} onError={onImageErrorLoading} />
              </Grid>
            )}
          </StampsContainer>
        </Box>
        <Box id='editorContainer' component={isMobile ? NotDisplayed : isOpenedStampMenu ? HiddenEditorContainer : EditorContainer}>
          <SelectCardButton>
            <ListItemText primary={t('stamps')} />
            <AddStampIcon onClick={() => setIsOpenedStampMenu(true)} />
          </SelectCardButton>
          <EditorElementDesignPage>
            <div>
              <FontEditorNameDesignPage>{t('font')}</FontEditorNameDesignPage>
              <AddFontLabelDesignPage onClick={() => setAddFontModal(true)}>+ {t('addFont')}</AddFontLabelDesignPage>
            </div>
            <FormControl sx={{ minWidth: 120, width: '100%' }}>
              {font && font.fontFamily &&
                <FormControlSelect
                  id='fontSelect'
                  value={font && font.fontFamily}
                  onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => {
                    changeFont(FONT_PROPERTY.FONT_FAMILY, e.target.value);
                    returnFocus(false);
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  renderValue={() => <SelectCardValueTooltip> {font?.fontFamily} </SelectCardValueTooltip>} >
                  {fontsData.fonts.map((item: FontType) =>
                    <FontMenuItem id={'fontItem' + item.name} key={item.name} value={item.name}>
                      <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={`${item.name}`} />
                    </FontMenuItem>)}
                </FormControlSelect>}
            </FormControl>
          </EditorElementDesignPage>
          <EditorElementDesignPage>
            <EditorNameLabel>{t('textColor')}</EditorNameLabel>
            <RoundColorElement
              id={EDITOR_CONTAINER_ELEMENTS_IDS.COLOR_PICKER_BUTTON}
              onClick={() => setShowColorPicker(!showColorPicker)}
              style={{ backgroundColor: font.fill, transform: getTransformPropertyForRoundColorElement() } as CSSProperties} />
            <ColorTextField
              id={SLIDER_ELEMENTS_IDS.OUTLINED_BASIC}
              variant='outlined'
              value={font.fill}
              style={{ transform: getTransformPropertyForIconElement()} as CSSProperties}
              onChange={(e) => changeFont(FONT_PROPERTY.FILL, e.target.value)} />
            {
              showColorPicker &&
              <ImagePickerDesignPage id={EDITOR_CONTAINER_ELEMENTS_IDS.FONT_COLOR_PICKER} >
                <HexColorPicker
                  color={font.fill}
                  onMouseUp={() => colorHexRef.current && changeFont(FONT_PROPERTY.FILL, colorHexRef.current)}
                  onChange={(newColor) => colorHexRef.current = newColor} />
              </ImagePickerDesignPage>
            }
          </EditorElementDesignPage>
          <EditorElementDesignPage>
            <ValueAndSliderInput
              sliderId={SLIDER_ELEMENTS_IDS.TEXT_SIZE_SLIDER}
              returnFocus={returnFocus}
              selectAndRemoveFocus={selectAndRemoveFocus}
              isMobile={false}
              font={font}
              callback={changeFont}
              title={t('textSize')}
              valueType='fontSize'
              maxValue={100}
              canvas={canvas} />
          </EditorElementDesignPage>
          <EditorElementDesignPage>
            <EditorNameLabel>{t('textStyle')}</EditorNameLabel>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.BOLD_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.fontWeight === 'bold' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont('fontWeight', font.fontWeight === 'normal' ? 'bold' : 'normal')}>
              <FormatBoldIcon />
            </IconButtonStyled>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.ITALIC_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.fontStyle === 'italic' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont(FONT_PROPERTY.FONT_STYLE, font.fontStyle === 'normal' ? 'italic' : 'normal')}>
              <FormatItalicIcon />
            </IconButtonStyled>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.UNDERLINE_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.underline && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont(FONT_PROPERTY.UNDERLINE, !font.underline)}>
              <FormatUnderlinedIcon />
            </IconButtonStyled>
          </EditorElementDesignPage>
          <EditorElementDesignPage>
            <EditorNameLabel>{t('textAlignment')}</EditorNameLabel>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.LEFT_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.textAlign === 'left' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'left' ? '' : 'left')}>
              <FormatAlignLeftIcon />
            </IconButtonStyled>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.RIGHT_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.textAlign === 'right' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'right' ? '' : 'right')}>
              <FormatAlignRightIcon />
            </IconButtonStyled>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.CENTER_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.textAlign === 'center' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'center' ? '' : 'center')}>
              <FormatAlignCenterIcon />
            </IconButtonStyled>
            <IconButtonStyled
              id={EDITOR_CONTAINER_ELEMENTS_IDS.JUSTIFY_BUTTON}
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ opacity: font.textAlign === 'justify' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
              onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'justify' ? '' : 'justify')}>
              <FormatAlignJustifyIcon />
            </IconButtonStyled>
          </EditorElementDesignPage>
          <EditorElementDesignPage>
            <ValueAndSliderInput
              sliderId={SLIDER_ELEMENTS_IDS.TEXT_SPACING_SLIDER}
              returnFocus={returnFocus}
              selectAndRemoveFocus={selectAndRemoveFocus}
              isMobile={false}
              font={font}
              callback={changeFont}
              title={t('textSpacing')}
              valueType={FONT_PROPERTY.CHAR_SPACING}
              maxValue={500}
              canvas={canvas} />
          </EditorElementDesignPage>
          <EditorElementDesignPage>
            <ValueAndSliderInput
              sliderId={SLIDER_ELEMENTS_IDS.LINE_SPACING_SLIDER}
              returnFocus={returnFocus}
              selectAndRemoveFocus={selectAndRemoveFocus}
              isMobile={false}
              font={font}
              callback={changeFont}
              title={t('lineSpacing')}
              valueType={FONT_PROPERTY.LINE_HEIGHT}
              maxValue={10}
              canvas={canvas} />
          </EditorElementDesignPage>
        </Box>
        <ImageEditorContainer id='imageEditor' onClick={(e) => removeFocusFromTextboxMobile(e, [ canvasElementRef.current, canvasContainerRef.current ], canvas, isMobile)}>
          <OpenedSubmenuContainer>
            {isMobile &&
              <>
                <SelectCardContainerMobile>
                  {isOpenedStampMenu ?
                    <>
                      <SelectCardButton onClick={() => setIsOpenedStampMenu(false)}>
                        <ListItemText primary={t('stamps')} sx={{marginLeft: isMobile && 0}} />
                        <MenuIcon />
                      </SelectCardButton>
                      <Grid container spacing={1} padding={1} maxHeight='690px' minHeight='30px' overflow='auto'>
                        {stampsData && stampsData.stamps.map((item: ImageType) =>
                          <Grid key={item.id} item xs={6} onClick={() => {
                            setIsOpenedStampMenu(false);
                            addImage(item.url);
                          }}>
                            <StampItemImage src={item.url} onError={onImageErrorLoading} />
                          </Grid>
                        )}
                      </Grid>
                    </> :
                    <SelectCardButton onClick={() => setIsOpenedStampMenu(true)}>
                      <ListItemText primary={t('stamps')} />
                      <AddStampIcon />
                    </SelectCardButton>
                  }
                </SelectCardContainerMobile>
                <Box component={isTextSelected ? moreOption ? LessOptionsContainerMobile : MoreOptionsContainerMobile : NotDisplayed}>
                  <MoreOptionsText> {t(moreOption ? 'lessOptions' : 'moreOptions')} </MoreOptionsText>
                  <MoreOptionIconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={() => setMoreOption(!moreOption)} >
                    <MoreHorizIconStyled/>
                  </MoreOptionIconButton>
                  {
                    moreOption &&
                          <>
                            {isTextSelected &&
                            <SelectPlaceholderButton
                              aria-controls={isOpenPlaceholderMenu ? CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU : undefined}
                              aria-haspopup='true'
                              aria-expanded={isOpenPlaceholderMenu ? 'true' : undefined}
                              onClick={(e: React.MouseEvent<HTMLElement>) => openPlaceholderMenu(e, 'placeholder')} >
                              - {t('selectPlaceholder')} -
                              <KeyboardArrowDownIcon />
                            </SelectPlaceholderButton>}
                            <Menu
                              id={CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU}
                              MenuListProps={{
                                'aria-labelledby': 'placeholder-button',
                              }}
                              anchorEl={placeholderEl}
                              open={isOpenPlaceholderMenu}
                              onClose={closePlaceholderMenu}
                              TransitionComponent={Fade} >
                              {
                                placeholderData?.placeholders.map((placeholder: PlaceholderType) => {
                                  return (
                                    <PlaceholderMenuItem
                                      key={placeholder.code}
                                      onClick={() => {
                                        closePlaceholderMenu();
                                        setPlaceholder(placeholder.code);
                                      }}>
                                      <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={'+ ' + t(placeholder.code.substring(1, placeholder.code.length - 1))} />
                                    </PlaceholderMenuItem>
                                  );
                                })
                              }
                            </Menu>

                            {/* <FormControl sx={{width: '100%', margin: '10px 20px'}}>
                              <InputLabel>{`${t('selectMusic')}`}</InputLabel>
                              <Select
                                value={musicId}
                                onChange={(e) => {
                                  setMusicId(e.target.value as number);
                                  setCanvasIsChanged(true);
                                }}
                                onOpen={() => setIsOpenMusicSelect(true)}
                                onClose={() => setIsOpenMusicSelect(false)}
                                defaultValue={null}
                                displayEmpty
                                fullWidth
                                renderValue={(id) => (
                                  <div>
                                    {id && musicData?.music.find((m: any) => m.id === id).name}
                                  </div>
                                )}>
                                <MenuItem value={null}>
                                  <em>None</em>
                                </MenuItem>
                                {
                                  musicData?.music.map((song: any) => {
                                    return (
                                      <MenuItem key={song.id} value={song.id}>
                                        <AudioPlayer url={song.url} isActive={isOpenMusicSelect} />
                                        {song.name}
                                      </MenuItem>
                                    );
                                  })
                                }
                              </Select>
                            </FormControl> */}
                          </>
                  }
                </Box>
              </>
            }
            {
              isMobile && isTextSelected ?
                <>
                  <EditorElementContainer>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('font')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FontFamilySelectItem
                          value={font?.fontFamily}
                          onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => changeFont(FONT_PROPERTY.FONT_FAMILY, e.target.value)}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {fontsData?.fonts.map((item: FontType) =>
                            <MenuItem key={item.name} value={item.name}>
                              <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={item.name} />
                            </MenuItem>)}
                        </FontFamilySelectItem>
                      </FormControl>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <AddFontLabelDesignPage onClick={() => setAddFontModal(true)}>+ {t('addFont')}</AddFontLabelDesignPage>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textColor')}</EditorNameLabel>
                      <RoundColorElement
                        id='colorPickerButtonMobile'
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        style={{ backgroundColor: font.fill, transform: getTransformPropertyForRoundColorElement() } as CSSProperties} />
                      <ColorTextField
                        id='outlined-basic-mobile'
                        variant='outlined'
                        value={font.fill}
                        style={{ transform: getTransformPropertyForIconElement()} as CSSProperties}
                        onChange={(e) => changeFont(FONT_PROPERTY.FILL, e.target.value)} />
                      {
                        showColorPicker &&
                        <ImagePickerDesignPage id='fontColorPickerMobile'>
                          <HexColorPicker
                            color={font.fill}
                            onMouseUp={() => colorHexRef.current && changeFont(FONT_PROPERTY.FILL, colorHexRef.current)}
                            onChange={(newColor) => colorHexRef.current = newColor} />
                        </ImagePickerDesignPage>
                      }
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textSize')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FormControlSelect
                          value={font?.fontSize}
                          onChange={changeFontSize}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {fontSize.map((item: number, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </FormControlSelect>
                      </FormControl>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textStyle')}</EditorNameLabel>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.fontWeight === 'bold' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont('fontWeight', font.fontWeight === 'normal' ? 'bold' : 'normal')}>
                        <FormatBoldIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.fontStyle === 'italic' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.FONT_STYLE, font.fontStyle === 'normal' ? 'italic' : 'normal')}>
                        <FormatItalicIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.underline && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.UNDERLINE, !font.underline)}>
                        <FormatUnderlinedIcon />
                      </IconButtonStyled>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textAlignment')}</EditorNameLabel>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'left' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'left' ? '' : 'left')}>
                        <FormatAlignLeftIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'right' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'right' ? '' : 'right')}>
                        <FormatAlignRightIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'center' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'center' ? '' : 'center')}>
                        <FormatAlignCenterIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'justify' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'justify' ? '' : 'justify')}>
                        <FormatAlignJustifyIcon />
                      </IconButtonStyled>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textSpacing')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FormControlSelect
                          value={font?.charSpacing}
                          onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => changeFont(FONT_PROPERTY.CHAR_SPACING, e.target.value)}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {textSpacing.map((item: number, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </FormControlSelect>
                      </FormControl>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('lineSpacing')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FormControlSelect
                          value={font?.lineHeight}
                          onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => changeFont(FONT_PROPERTY.LINE_HEIGHT, e.target.value)}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {lineSpacing.map((item: number, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </FormControlSelect>
                      </FormControl>
                    </EditorElementDesignPage>
                  </EditorElementContainer>
                </> :
                isMobile ?
                  <>
                    <Box component={isTextSelected ? SubmenuContainerMobile : SubmenuExtendedContainerMobile}>
                      <SubmenuButton onClick={addText}><SubmenuButtonImageIcon src={addTextIcon} />{t('addText')}</SubmenuButton>
                      <SubmenuButton onClick={() => setShowStampUploadDialog(true)}>
                        <SubmenuButtonImageIcon src={addBackgroundIcon} />
                        {t('setImageAsStamp')}
                      </SubmenuButton>
                      <ToggleContainer>
                        <PreviewSwitchButton
                          checked={showCanvasPreview}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setShowCanvasPreview(e.target.checked);
                            showCanvasPreviewImage(e);
                          }} />
                        {t('preview')}
                      </ToggleContainer>
                    </Box>
                  </> :
                  <>
                    <SubmenuContainer>
                      <ContainerSubmenuStartButtons>
                        <SubmenuButton onClick={addText} disabled={showCanvasPreview}>
                          <SubmenuButtonImageIcon src={showCanvasPreview ? addTextIconDisabled : addTextIcon} />
                          {t('addText')}
                        </SubmenuButton>
                        <SubmenuButton onClick={() => setShowStampUploadDialog(true)} disabled={showCanvasPreview}>
                          <SubmenuButtonImageIcon src={showCanvasPreview ? addBackgroundIconDisabled : addBackgroundIcon} />
                          {t('setImageAsStamp')}
                        </SubmenuButton>
                        <ToggleContainer>
                          <PreviewSwitchButton
                            checked={showCanvasPreview}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setShowCanvasPreview(e.target.checked);
                              showCanvasPreviewImage(e);
                            }} />
                          {t('preview')}
                        </ToggleContainer>
                      </ContainerSubmenuStartButtons>
                      <ContainerSubmenuEndButtons>
                        {isTextSelected &&
                        <SubmenuButton
                          id='placeholder-button'
                          aria-controls={isOpenPlaceholderMenu ? CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU : undefined}
                          aria-haspopup='true'
                          aria-expanded={isOpenPlaceholderMenu ? 'true' : undefined}
                          onClick={(e: React.MouseEvent<HTMLElement>) => openPlaceholderMenu(e, 'placeholder')} >
                          {t('selectPlaceholder')}
                        </SubmenuButton>}
                        <Menu
                          id={CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU}
                          MenuListProps={{
                            'aria-labelledby': 'placeholder-button',
                          }}
                          anchorEl={placeholderEl}
                          open={isOpenPlaceholderMenu}
                          onClose={closePlaceholderMenu}
                          TransitionComponent={Fade} >
                          {
                            placeholderData?.placeholders.map((placeholder: PlaceholderType) => {
                              return (
                                <SubmenuPlaceholdersMenuItem
                                  key={placeholder.code}
                                  onClick={() => {
                                    closePlaceholderMenu();
                                    setPlaceholder(placeholder.code);
                                  }}>
                                  <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={'+ ' + t(placeholder.code.substring(1, placeholder.code.length - 1))} />
                                </SubmenuPlaceholdersMenuItem>
                              );
                            })
                          }
                        </Menu>
                        {/* <FormControl sx={{ width: 200 }} >
                          <InputLabel shrink sx={{backgroundColor: '#FFF', padding: '0 10px 5px 10px', borderRadius: '10px'}}>{`${t('selectMusic')}`}</InputLabel>
                          <MusicListSelect
                            value={musicId}
                            onChange={(e) => {
                              setMusicId(e.target.value as number);
                              setCanvasIsChanged(true);
                            }}
                            onOpen={() => setIsOpenMusicSelect(true)}
                            onClose={() => setIsOpenMusicSelect(false)}
                            defaultValue={null}
                            displayEmpty
                            renderValue={(id) => (
                              <MusicListSelectOption>
                                {id && musicData?.music.find((m: any) => m.id === id).name}
                              </MusicListSelectOption>
                            )}>
                            <MenuItem value={null}>
                              <em>{t('none')}</em>
                            </MenuItem>
                            {
                              musicData?.music.map((song: any) => {
                                return (
                                  <MenuItem key={song.id} value={song.id}>
                                    <AudioPlayer url={song.url} isActive={isOpenMusicSelect} />
                                    {song.name}
                                  </MenuItem>
                                );
                              })
                            }
                          </MusicListSelect>
                        </FormControl> */}
                      </ContainerSubmenuEndButtons>
                    </SubmenuContainer>
                  </>
            }
          </OpenedSubmenuContainer>
          <CanvasWrapper ref={canvasContainerRef}>
            <Box id='canvasContainer' ref={canvasElementRef} sx={{...CanvasContainerDesignPageStyle, ... loadingCanvas && themePublic.hiddenElement, ... showCanvasPreview && themePublic.canvasContainerDesignPageHidden} as CSSProperties}>
              <Box sx={{... showCanvasPreview ? CanvasPreviewImgStyle : NotDisplayedStyle}}>
                {canvasPreviewImage &&
                  <img
                    src={canvasPreviewImage}
                    width={canvas?.width}
                    height={canvas?.height} />}
              </Box>
              <canvas id='canvasMain' />
              {showKeyboard &&
                  <VirtualKeyboard
                    keyboardComponentId={CANVAS_ELEMENTS_IDS.VIRTUAL_KEYBOARD}
                    selectedObject={selectedObject}
                    setShowKeyboard={setShowKeyboard}
                    setFieldValue={null}
                    keyboardRef={keyboardRef}
                    inputName={selectedKey}
                    isCanvas={true}
                    initialValues={canvasTextboxMap}
                    renderTextFromKeyboard={() => renderTextFromKeyboard()}/>
              }
            </Box>
            <ActionButtonContainer>
              <CanvasActionButton elementId={CANVAS_ELEMENTS_IDS.DELETE_BUTTON} showButton={isTextSelected || isImageSelected} handleAction={() => deleteSelectedObjects(true)}>
                <DeleteIcon />
              </CanvasActionButton>
              {isMobile &&
              <CanvasActionButton elementId={CANVAS_ELEMENTS_IDS.EDIT_TEXT_BUTTON} showButton={isTextSelected} handleAction={() => switchToEditMode(canvas, CANVAS_ELEMENTS_IDS.EDIT_TEXT_BUTTON)}>
                <EditIcon />
              </CanvasActionButton>
              }
            </ActionButtonContainer>
          </CanvasWrapper>
        </ImageEditorContainer>
        <Loader loadingPage={false} inProgress={imageLoading || isSavingCanvas || loadingCanvas || replacePlaceholdersLoading || replaceDefaultPlaceholdersLoading} />
        <Dialog maxWidth='xl' open={addFontModal} onClose={() => setAddFontModal(false)}>
          <DialogContent>
            <AddFontModal
              handleUpload={(fileUrl: string) => addFont(fileUrl)}
              handleClose={() => setAddFontModal(false)}
              fontLoading={fontLoading}/>
          </DialogContent>
        </Dialog>
      </PageContent>
      <PageButtonsContainer>
        <PagePrevButton
          variant='contained'
          onClick={() => changeTab('design')}>
          {t(isMobile ? 'back' : 'prevDesign')}
        </PagePrevButton>
        {!isMobile &&
          <BottomCanvasButtonsContainer>
            <BottomCanvasButtons
              style={{ marginLeft: isMobile ? 0 : '100px' }}
              onClick={() => undo()}
              disabled={isUndoDisabled}>
              <RedoIcon style={{transform: 'rotateY(180deg)', ...themePublic.submenuButtonIconDesignPage} as CSSProperties}/>
              {t('undo')}
            </BottomCanvasButtons>
            <BottomCanvasButtons
              onClick={() => redo()}
              disabled={isRedoDisabled}>
              <RedoIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
              {t('redo')}
            </BottomCanvasButtons>
            <BottomCanvasButtons
              disabled={!canvasIsChanged || isSavingCanvas || isSaveDisabled}
              onClick={() => saveCanvas()}>
              <SaveIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties} />{t('save')}
            </BottomCanvasButtons>
          </BottomCanvasButtonsContainer>
        }
        <Dialog maxWidth='lg' open={showStampUploadDialog} onClose={() => setShowStampUploadDialog(false)}>
          <SetBackgroundImageDialogContent>
            <SetBackgroundImageModal
              isTemplate={isTemplate}
              isBackgroundColor={false}
              handleUpload={(file: string) => addImage(file)}
              handleClose={() => setShowStampUploadDialog(false)}
              fileType='STAMP'
              imageLoading={imageLoading}/>
          </SetBackgroundImageDialogContent>
        </Dialog>
        <PageNextButton
          variant='outlined'
          onClick={() => changeTab('preview')}>
          {t(isMobile ? 'next' : 'nextPreview')}
        </PageNextButton>
      </PageButtonsContainer>
      <Prompt when={canvasIsChanged && !isSavingCanvas} options={{
        title: t('leavePage'),
        message: t('unsavedChanges'),
        buttons: [
          {
            label: 'Confirm',
            continue: true
          },
          {
            label: 'Cancel',
          }
        ],
      }} />
    </>
  );
};