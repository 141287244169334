import { Box, Button, Chip, Divider, Grid, ListItemText, Menu, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const SubheaderContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'right',
    margin: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
      margin: '1.5rem 0 0 0',
      padding: '0 0.35rem',
    },
  }),
);

export const SubheaderConatinerPreviewPage = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1.5rem 0',
    minWidth: '1000px',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      justifyContent: 'space-between',
      minWidth: 0,
      marginLeft: 0,
      padding: '0 10px',
    },
  }),
);

export const SubheaderContainerTrackPage = styled(Box)(
  ({ theme }) => ({
    margin: '1.5rem 0',
    justifyContent: 'center !important',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }),
);

export const SearchInputHeader = styled(TextField)(
  () => ({
    ...themePublic.searchInputHeader as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
  }),
);

export const FilterImage = styled('img')(
  () => ({
    ...themePublic.cursorPointer as CSSProperties,
  }),
);

export const FilterMenu = styled(Menu)(
  () => ({
    ...themePublic.filterContent as CSSProperties,
  }),
);

export const FilterHeading = styled(Box)(
  () => ({
    ...themePublic.filterHeading as CSSProperties,
  }),
);

export const FilterItemContainer = styled(Box)(
  () => ({
    ...themePublic.filterItemContainer as CSSProperties,
  }),
);

export const FilterSearchField = styled(TextField)(
  () => ({
    ...themePublic.filterSearch as CSSProperties,
  }),
);

export const SelectrSearchField = styled(Select)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    paddingTop: '2px',
    paddingBottom: '2px',
    zIndex: 99999,
    [theme.breakpoints.down('sm')]: {
      zIndex: 0,
    },
  }),
);

export const SelectLabel = styled('p')(
  () => ({
    color: themeDefault.palette.darkGreyColor,
    margin: 0,
    opacity: .8,
  }),
);

export const MenuItemContainer = styled('div')(
  () => ({
    display: 'contents',
    justifyContent: 'space-between',
    width: '100%',
  }),
);

export const MenuItemSelected = styled(MenuItem)(
  () => ({
    backgroundColor: `${themeDefault.palette.lightMainColor} !important`,
    color: themeDefault.palette.mainColor,
    '& svg': {
      fill: themeDefault.palette.mainColor,
    }
  }),
);

export const MenuItemTitle = styled(ListItemText)(
  () => ({
    '& span': {
      lineClamp: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto',
    }
  }),
);

export const SubmenuButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submenuButton as CSSProperties,
    padding: '0.4rem 1.5rem',
    marginRight: '1rem',
    '@media screen and (max-width: 1500px)': {
      padding: '0.4rem 0.7rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.3rem',
    },
  }),
);

export const MenuContent = styled(Menu)(
  () => ({
    ...themePublic.submenuContent as CSSProperties,
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactMenagementDividerMenu as CSSProperties,
  }),
);

export const ButtonSend = styled(Button)(
  () => ({
    ...themePublic.buttonWithIcon as CSSProperties,
  }),
);

export const ChipFilterContainer = styled(Grid)(
  ({ theme }) => ({
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  }),
);

export const ChipStyled = styled(Chip)(
  () => ({
    ...themePublic.chip as CSSProperties,
  }),
);
