import { create } from 'zustand';

import { DefaulContactParams, generateStoreObject } from 'helpers';
import { ContactStoreType } from 'types';

export const useContactStore = create<ContactStoreType>((set, get) => ({
  ...generateStoreObject(set, get, DefaulContactParams),
  isExecutedTagsRefetch: false,
  changeExecuteTagsRefetch: () => set({isExecutedTagsRefetch: !get().isExecutedTagsRefetch}),
  contactForEditing: null,
  setContactForEditing: (contact) => set({contactForEditing: contact}),
}));