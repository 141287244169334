import { Box, Divider, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PageContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    backgroundColor: themeDefault.palette.mainBackground,
    display: 'flex',
    height: '100vh',
    minHeight: '100vh',
    width: '100vw',
  }),
);

export const PaperStyled = styled(Paper)(
  () => ({
    boxSizing: 'border-box',
    maxWidth: '100%',
    margin: '2rem',
    padding: '2rem 1.5rem',
    textAlign: 'center',
  }),
);

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '90%',
    },
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.pageWithPaperDivider as CSSProperties,
  }),
);

export const Message = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: '3rem',
    textTransform: 'uppercase',
    width: '100%',
  }),
);
