import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { useAuth, InvitationProvider } from 'contexts';
import { Sidebar } from 'components';
import { PASSWORD_PAGE_TYPE, USER_PERMISSIONS } from 'enums';
import { checkPermission } from 'helpers';
import {
  AccountSettingsPage,
  LoginPage,
  RegisterPage,
  SetPasswordPage,
  ForgotPasswordPage,
  ContactsPage,
  UserlistPage,
  InvitationTemplatesPage,
  InvitationCreatePage,
  InvitationListPage,
  ConfirmEmailPage,
  InvitationContactPreviewPage,
  PublicContactEntryPage,
  VenuesPage,
  NotFoundPage
} from 'pages';
import { GET_USER_BY_TOKEN, graphLazyQueryMiddleware } from 'services';

import 'react-toastify/dist/ReactToastify.css';
import './react-toastify-overrides.css';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  const { currentUser, dbUser, logout } = useAuth();
  let routes = null;
  const urlLocation = useLocation();
  const navHistory = useNavigate();
  const fullPath = `${urlLocation.pathname}${urlLocation.search}`;

  const [ getUserByToken, { data: userData, loading: loadingUser }] = graphLazyQueryMiddleware(GET_USER_BY_TOKEN);

  useEffect(() => {
    const query = new URLSearchParams(urlLocation.search);
    const userToken = query.get('t');

    if (fullPath.startsWith('/change-user-password?t=') && currentUser) {
      logout();
      navHistory(fullPath);
      return;
    }

    getUserByToken({
      variables: {
        token: userToken
      }})
      .finally(async () => {
        if (fullPath.startsWith('/set-password?t=') && currentUser) {
          await logout();
          navHistory(fullPath);
        }
      });
  }, [fullPath]);

  if (currentUser) {
    if (dbUser) {
      routes = (
        <SentryRoutes>
          {checkPermission(dbUser, USER_PERMISSIONS.EDIT_VENUES) && <Route exact path='/venues' element={<VenuesPage />} />}
          {checkPermission(dbUser, USER_PERMISSIONS.VIEW_CONTACT) && <Route exact path='/contacts' element={<ContactsPage />} />}
          <Route exact path='/invitationTemplates' element={<InvitationTemplatesPage />} />
          {checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) && <Route exact path='/invitationTemplates/create/*' element={<InvitationCreatePage isPageForInvitationTemplates={true} />} />}
          {checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) && <Route exact path='/invitationTemplates/edit/*' element={<InvitationCreatePage isPageForInvitationTemplates={true} />} />}
          <Route exact path='/invitationlist' element={<InvitationListPage />} />
          <Route exact path='/invitations/create/*' element={
            <InvitationProvider>
              <InvitationCreatePage />
            </InvitationProvider>}/>
          <Route exact path='/invitations/edit/*' element={
            <InvitationProvider>
              <InvitationCreatePage />
            </InvitationProvider>}/>
          <Route exact path='/settings' element={<AccountSettingsPage />} />
          {checkPermission(dbUser, USER_PERMISSIONS.VIEW_USERS) && <Route exact path='/userlist' element={<UserlistPage />} />}
          <Route exact path='/confirm-email' element={<ConfirmEmailPage />} />
          <Route exact path='/change-password' element={<SetPasswordPage pageType={PASSWORD_PAGE_TYPE.CHANGE_PASSWORD} />} />
          <Route exact path='/invitationContactPreview' element={<InvitationContactPreviewPage />} />
          <Route exact path='/gather-contacts' element={<PublicContactEntryPage />} />
          <Route exact path='/notFound' element={<NotFoundPage />} />
          <Route path='*' element={<Navigate to='/invitationTemplates' replace />} />
        </SentryRoutes>
      );
    }
  } else {
    routes = (
      <SentryRoutes>
        <Route exact path='/login' element={<LoginPage />} />
        <Route exact path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route exact path='/register' element={<RegisterPage />} />
        <Route exact path='/set-password' element={<SetPasswordPage pageType={PASSWORD_PAGE_TYPE.SET_PASSWORD} user={userData?.userByToken} loadingUser={loadingUser} />} />
        <Route exact path='/change-password' element={<SetPasswordPage pageType={PASSWORD_PAGE_TYPE.CHANGE_PASSWORD} />} />
        <Route exact path='/change-user-password' element={<SetPasswordPage pageType={PASSWORD_PAGE_TYPE.CHANGE_PASSWORD} isForgotPassword={true} />} />
        <Route exact path='/invitationContactPreview' element={<InvitationContactPreviewPage />} />
        <Route exact path='/gather-contacts' element={<PublicContactEntryPage />} />
        <Route path='*' element={<Navigate to='/login' replace />} />
      </SentryRoutes>
    );
  }

  return (
    <>
      <Sidebar>
        {routes}
      </Sidebar>
      <ToastContainer hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss pauseOnHover position='bottom-right' />
    </>
  );
};
