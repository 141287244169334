import { CSSProperties } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault, themePublic } from 'themeDefault';

export const UserListPageBody = styled(Box)(
  () => ({
    ...themePublic.pageBody as CSSProperties,
  }),
);

export const UserListPageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '13.85vh',
      borderRadius: themeDefault.defaultBorderRadius
    }
  }),
);

export const AddNewUserButtonMobile = styled(Paper)(
  () => ({
    ...themePublic.addNewEntityButtonMobile as CSSProperties,
  }),
);

export const TypographyStyled = styled(Typography)(
  () => ({
    ...themePublic.emptyListText as CSSProperties,
  }),
);
