import { Box, Dialog, DialogContent } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  ConfirmationModal,
  CreateGuestModal,
  GuestsTable,
  ImportContactsCsvModal,
  ImportGuestsFromContacts,
  LinkedInvitationsResponses,
  Loader,
  MobileFilters,
  MobileImportContactsCsvModal,
  MobilePagination,
  MobileSearch,
  Subheader,
  TablePagination
} from 'components';
import {
  ACTIVITY_STATUS,
  NUMBER_OF_ITEMS_PER_PAGE,
  RESPONSE_STATUS,
  USER_SETTINGS_CONFIG
} from 'enums';
import {
  getRouteForNavigation,
  guestColumnsSendPage,
  guestColumnsTrackPage,
  lastTableColumForMenu, showToast
} from 'helpers';
import {
  GET_INVITATION_BY_ID,
  GET_TAGS,
  IMPORT_CONTACTS,
  SEND_INVITATIONS,
  graphLazyQueryMiddleware,
  graphMutationMiddleware
} from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { themePublic } from 'themeDefault';
import { EviteFilterType, EviteType, InvitationProps, SendersProps, TagType, TrackColumnType } from 'types';

import {
  CloseIconStyled, PageButtonsContainer, PageNextButton, PagePrevButton, PaginationContainer, PreviewPageContent,
  SendPageContent, TableContainer, TableContainerPreviewPage, TrackPageContent, TypographyStyled,
} from './InvitationSendPage.styled';

type InvitationSendPageProps = {
  isTrackPage?: boolean,
  linkedInvitations?: InvitationProps[],
  forPreview?: boolean,
  enabledEvites?: EviteType[],
  selectedEvitesResponses?: EviteFilterType[]
  linkedInvitationLoading?: boolean
  setSelectedEvitesResponses?: (state: EviteFilterType[]) => void
  evitesConfigsDict?: any
  setEvitesConfigsDict?: (state: any) => void

}

export const InvitationSendPage = ({
  isTrackPage = false,
  linkedInvitations = [],
  forPreview = false,
  enabledEvites = [],
  selectedEvitesResponses = null,
  linkedInvitationLoading,
  setSelectedEvitesResponses,
  evitesConfigsDict,
  setEvitesConfigsDict,
} : InvitationSendPageProps) => {
  const [t] = useTranslation();
  const navLocation = useLocation();
  const navHistory = useNavigate();
  const urlParams = useParams();

  const [ isOpenCreateModal, setIsOpenCreateModal ] = useState(false);
  const [ isOpenImportModal, setIsOpenImportModal ] = useState(false);
  const [ sendingProcess, setSendingProcess ] = useState(false);
  const [ isOpenBulkConfirmModal, setIsOpenBulkConfirmModal ] = useState(false);
  const [ showFilters, setShowFilter ] = useState(false);
  const [ isOpenCSVModal, setIsOpenCSVModal ] = useState(false);
  const [ senderIds, setSenderIds ] = useState([]);

  const [ getInvitation, { data: singleInvitationData }] = graphLazyQueryMiddleware(GET_INVITATION_BY_ID);
  const [ getTags, { data: tagData, refetch: refetchTags }] = graphLazyQueryMiddleware(GET_TAGS);
  const [importContactsToGuests] = graphMutationMiddleware(IMPORT_CONTACTS);
  const [sendInvitations] = graphMutationMiddleware(SEND_INVITATIONS);

  const {
    isFetching: isImportingInProgress,
    contactIds,
    invitationId,
    updateGuestsCompleted,
    pageInfo,
    params,
    setParams,
    searchText,
    setSearchText,
    changeExecuteRefetch,
    setInvitation,
    invitation,
    isLoading,
    setGuestForPreview,
    selectedGuests,
    setSelectedGuests
  } = useGuestStore();

  const { changeExecuteRefetch: refetchResponses } = useTrackStore();

  const [ guestColumnsWithCards, setGuestColumnsWithCards ] = useState<TrackColumnType[]>([]);

  let filterConfig = [
    {
      id: 0,
      label: t('selectTags'),
      body: tagData && tagData.tags.map((item: TagType) => ({ id: item.id, title: item.name})),
      multiple: true,
      selectedItems: params.selectedTags,
      handleSelectItem: (tag: string[]) => handleParams('selectedTags', tag),
      placeholder: t('selectTags'),
      isDisabledTranslation: true
    },
    {
      id: 1,
      label: t('selectSenders'),
      body: invitation && invitation.senders.map((item: SendersProps) => ({ id: item.id, title: item.firstName})),
      multiple: true,
      selectedItems: params.selectedSenders,
      handleSelectItem: (senders: number[]) => handleParams('selectedSenders', senders),
      placeholder: t('selectSenders'),
      isDisabledTranslation: true
    },
  ];

  const extraFilterConfigForTrackPage = [
    {
      id: 2,
      label: t('selectEvents'),
      body: linkedInvitations && linkedInvitations.map((linkedInvitation: InvitationProps) => ({id: linkedInvitation.id, title: linkedInvitation.name})),
      multiple: true,
      selectedItems: params.selectedEvents,
      handleSelectItem: (selectedEvents: string[]) => handleParams('selectedEvents', selectedEvents),
      placeholder: t('selectEvents'),
      isDisabledTranslation: true
    },
    {
      id: 3,
      label: t('selectResponses'),
      body: (Object.keys(RESPONSE_STATUS) as (keyof typeof RESPONSE_STATUS)[]).map(item => ({id: item, title: t(item)})),
      multiple: true,
      selectedItems: params.selectedStatus,
      handleSelectItem: (selectedStatus: string[]) => handleParams('selectedStatuses', selectedStatus),
      placeholder: t('selectResponses'),
      isDisabledTranslation: false
    },
    {
      id: 4,
      label: t('selectEvites'),
      body: singleInvitationData && singleInvitationData.invitation.senders.map((item: SendersProps) => ({ id: item.id, title: item.firstName})),
      multiple: true,
      selectedItems: params.selectedEvites,
      handleSelectItem: (card: string[]) => handleParams('selectedEvites', card),
      placeholder: t('selectEvites'),
      isDisabledTranslation: true
    },
  ];

  filterConfig = isTrackPage ? filterConfig.concat(extraFilterConfigForTrackPage) : filterConfig;

  useEffect(() => {
    if (urlParams.id) {
      getInvitation({
        variables: {
          id: Number(urlParams.id)
        }
      }).then(res => setInvitation(res.data.invitation));
    }
    getTags({ variables: {
      filter: {
        type: 'CONTACT'
      }
    }});
  }, []);

  useEffect(() => {
    if (!isTrackPage) {
      return;
    }

    const guestColumnsWithCardsTemp = [ ...guestColumnsTrackPage, ...enabledEvites.map((item: EviteType, index: number) => {
      return {
        id: guestColumnsTrackPage.length + index,
        title: item.name,
        enableSorting: true,
        sortableEviteId: item.id,
        sortableName: 'Status'
      };
    }) ];
    lastTableColumForMenu.id = guestColumnsWithCardsTemp.length;
    setGuestColumnsWithCards([ ...guestColumnsWithCardsTemp, lastTableColumForMenu ]);
  }, [enabledEvites]);

  useEffect(() => {
    if (selectedEvitesResponses) {
      handleParams('selectedEvitesResponses', selectedEvitesResponses);
    }
  }, [selectedEvitesResponses]);

  useEffect(() => {
    if (isImportingInProgress) {
      importContactsToGuests({
        variables: {
          contactIds: contactIds,
          invitationId: invitationId
        }
      }).then(() => {
        updateGuestsCompleted();
        guestsRefetch();
      }).catch(() => {
        updateGuestsCompleted();
      });
    }
  }, [ isImportingInProgress, contactIds, invitationId ]);

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const guestsRefetch = () => {
    changeExecuteRefetch();
    refetchTags();
    setSelectedGuests();
    isTrackPage && refetchResponses();
  };

  const handleOpenCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const handleParams = (property: string, value: any) => {
    if (isMobile) {
      if (property === 'selectedSenders') {
        const ids: number[] = [];
        singleInvitationData.invitation.senders.map((senderFromData: SendersProps) => {
          if (value.includes(senderFromData.firstName)) {
            ids.push(senderFromData.id);
          }
        });
        setSenderIds(ids);
      }
    }
    setParams({
      ...params,
      [property]: value
    });
  };

  const sendToOneContact = async (guestId: number) => {
    await replacePlaceholderAndSend([guestId]);
  };

  const replacePlaceholderAndSend = async (guestIds: number[]) => {
    if (singleInvitationData?.invitation.activityStatus === ACTIVITY_STATUS.INACTIVE.toUpperCase()) {
      showToast('error', t('sendInactiveInvitationError'));
      setSendingProcess(false);
      return;
    }
    sendInvitations({
      variables: {
        input: {
          invitationId: singleInvitationData?.invitation.id,
          guestIds: guestIds,
          musicId: singleInvitationData?.invitation.envelope?.musicId
        }
      }}).then((res) => {
      setSendingProcess(false);
      showToast('info', res.data.sendInvitations);
      refetchResponses();
    }).catch(() => {
      setSendingProcess(false);
    });
  };

  const sendToSelected = async () => {
    selectedGuests.length > 0 && setSendingProcess(true);
    await replacePlaceholderAndSend(selectedGuests.map(a => a.guestId)).then(() => setSelectedGuests());
  };

  const showBulkConfirmModal = () => {
    setIsOpenBulkConfirmModal(true);
  };

  const guestColumns = () => {
    return isTrackPage ? guestColumnsWithCards : guestColumnsSendPage;
  };

  return (
    <Box component={forPreview ? PreviewPageContent : isTrackPage ? TrackPageContent : SendPageContent}>
      {isMobile &&
      <MobileSearch
        theme={{...themePublic.mobileSearchContainer, position: forPreview ? 'unset' : 'fixed', marginTop: forPreview ? '10px' : 0} as CSSProperties}
        showFilters={true}
        searchText={searchText}
        handleOpenFilters={() => setShowFilter(true)}
        setSearchText={setSearchText}/>}
      <Subheader
        forPreview={forPreview}
        sendingProcess={sendingProcess}
        sendToSelected={() => showBulkConfirmModal()}
        openCSVModal={() => setIsOpenCSVModal(true)}
        openImportModal={() => setIsOpenImportModal(true)}
        filterParams={params}
        tags={tagData ? tagData.tags : []}
        openCreateModal={handleOpenCreateModal}
        isTrackPage={isTrackPage}
        linkedInvitations={linkedInvitations} />
      {
        isTrackPage && !linkedInvitationLoading &&
        <LinkedInvitationsResponses
          setSelectedEvitesResponses={setSelectedEvitesResponses}
          linkedInvitations={linkedInvitations}
          evitesConfigsDict={evitesConfigsDict}
          setEvitesConfigsDict={setEvitesConfigsDict} />
      }
      <Box component={forPreview && !isMobile ? TableContainerPreviewPage : TableContainer}>
        <Loader inProgress={isLoading} />
        <GuestsTable
          setGuestForPreview={setGuestForPreview}
          forPreview={forPreview}
          sendToOneContact={sendToOneContact}
          invitation={singleInvitationData && singleInvitationData.invitation}
          guestColumns={guestColumns()}
          isTrackPage={isTrackPage}
          enabledEvites={enabledEvites}
          sendersIds={senderIds}/>
        {!isLoading &&
          <PaginationContainer>
            {
              isMobile ?
                <MobilePagination
                  setPage={(page) => handleParams('page', page)}
                  hasNextPage={pageInfo.hasNextPage}
                  hasPreviousPage={pageInfo.hasPreviousPage}
                  rowsPerPage={params.rowsPerPage}
                  rowsLength={pageInfo.totalCount}
                  page={params.page}
                  setRowsPerPage={(rowsNo) => handleParams('rowsPerPage', rowsNo)}/> :
                <TablePagination
                  invitationToShow={NUMBER_OF_ITEMS_PER_PAGE}
                  setPage={(page) => handleParams('page', page)}
                  setRowsPerPage={(rowsPerPage) => handleParams('rowsPerPage', rowsPerPage)}
                  page={params.page}
                  rowsLength={pageInfo.totalCount}
                  rowsPerPage={params.rowsPerPage}
                  tableType={isTrackPage ? USER_SETTINGS_CONFIG.GUEST_TABLE_TRACK_PAGE : forPreview ? USER_SETTINGS_CONFIG.EDIT_GUEST_PREVIEW_PAGE : USER_SETTINGS_CONFIG.GUEST_TABLE_SEND_PAGE} />}
          </PaginationContainer>}
      </Box>
      {isOpenCreateModal &&
        <CreateGuestModal handleClose={() => setIsOpenCreateModal(!isOpenCreateModal)} isOpen={isOpenCreateModal} />
      }
      <ImportGuestsFromContacts isOpen={isOpenImportModal} handleClose={() => setIsOpenImportModal(false)} forPreview={false}/>
      { isOpenCSVModal &&
        <Dialog maxWidth='xl' open={isOpenCSVModal} onClose={() => setIsOpenCSVModal(false)} fullScreen={isMobile}>
          <DialogContent>
            {isMobile ?
              <>
                <CloseIconStyled onClick={() => setIsOpenCSVModal(false)}/>
                <MobileImportContactsCsvModal handleClose={() => setIsOpenCSVModal(false)}/>
              </> :
              <ImportContactsCsvModal handleClose={() => setIsOpenCSVModal(false)} />
            }
          </DialogContent>
        </Dialog>
      }
      <Loader loadingPage={false} inProgress={!isTrackPage && isImportingInProgress} />
      <ConfirmationModal
        loading={false}
        isOpen={isOpenBulkConfirmModal}
        handleClose={() => setIsOpenBulkConfirmModal(false)}
        handleConfirm={sendToSelected}
        confimMessage={selectedGuests.length > 1 ? 'Send to '+selectedGuests.length+' contacts' : 'Send to '+selectedGuests.length+' contact'}/>
      {!isTrackPage && !forPreview &&
        <PageButtonsContainer>
          <PagePrevButton
            onClick={() => handleNavigate(getRouteForNavigation(navLocation.pathname, 'preview', urlParams['*']))}
            variant='contained'>
            {t(isMobile ? 'back' : 'prevPreview')}
          </PagePrevButton>
          <PageNextButton
            onClick={() => handleNavigate(getRouteForNavigation(navLocation.pathname, 'track', urlParams['*']))}
            variant='outlined'>
            {t(isMobile ? 'next' : 'nextTrack')}
          </PageNextButton>
        </PageButtonsContainer>
      }
      <MobileFilters
        isOpen={showFilters}
        filterConfig={filterConfig}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSortBy={(sort) => handleParams('orderBy', sort)}
        selectedSort={params.orderBy}
        order={params.order}
        handleClose={() => setShowFilter(false)}
        sortValues={guestColumns().filter((item) => item.enableSorting)
          .map(item => ({title: t(item.title), sortableName: item.sortableName}))}/>
    </Box>
  );
};