import { Person } from '@mui/icons-material';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  maxWidth: '30vh',
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const ContactMobilePaperContainer = styled(Paper)(
  () => ({
    ...themePublic.tableGroupMobilePaperConatiner as CSSProperties,
    border: '2px solid transparent',
  }),
);

export const ContactMobilePaperContainerSelected = styled(Paper)(
  () => ({
    ...themePublic.tableGroupMobilePaperConatiner as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
  }),
);

export const DataContainerMobile = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    maxWidth: '100%',
    overflow: 'hidden',
  }),
);

export const DataInfoContainerMobile = styled(Box)(
  () => ({
    ...themeDefault.flexCenter as CSSProperties,
    width: '100%',
  }),
);

export const InfoContainerMobile = styled(Box)(
  () => ({
    maxWidth: '100%',
    marginLeft: '1rem',
    overflow: 'hidden',
    width: '-webkit-fill-available',
  }),
);

export const PersonIconStyled = styled(Person)(
  () => ({
    ...themePublic.personIcon as CSSProperties,
  }),
);

export const BoldInfoData = styled(Typography)(
  () => ({
    ...themePublic.tableMobileDataInfoBold as CSSProperties,
    ... themeDefault.overflowText as CSSProperties,
    width: '-webkit-fill-available',
  }),
);

export const TypographyCustomTooltip = styled(Typography)(
  () => ({
    ... themeDefault.overflowText as CSSProperties,
    width: '-webkit-fill-available',
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactRowDividerMobile as CSSProperties,
  }),
);

export const DataContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  }),
);

export const DataInfoContainer = styled(Box)(
  () => ({
    ...themePublic.singleContactDataInfoContainerMobile as CSSProperties,
  }),
);

export const LabelName = styled('span')(
  ({ theme }) => ({
    ...themePublic.contactLabelName as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
    },
  }),
);

export const EvitesContainer = styled(Box)(
  () => ({
    ...themePublic.contactEvitesContainer as CSSProperties,
  }),
);

export const DataEviteName = styled('span')(
  () => ({
    ...themePublic.contactDataEvitesName as CSSProperties,
  }),
);
