import React, { useCallback, useMemo } from 'react';
import { Box, CircularProgress, InputLabel, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { addDays, endOfDay, format, parse, parseISO } from 'date-fns';
import { ErrorMessage, Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { TagSelector } from 'components';
import { PublicContactEntryAuthorisationType, TagType } from 'types';

import {
  BasicTableCellStyle, ButtonsContainer, CancelButton,
  MultiCellBottomBorder, MultiCellLeftBorder, MultiCellRightBorder, MultiCellTopBorder,
  SelectFormControl, SubmitButton, TagsContainer
} from './PublicContactEntryLinksTableEditRow.styled';

type Props = {
  item: Partial<PublicContactEntryAuthorisationType>;
  lookupTags?: TagType[];
  onSave?: (item: Partial<PublicContactEntryAuthorisationType>) => Promise<void>;
  onCancel?: () => void;
  showKeyboard?: boolean;
  setShowKeyboard?: (value: boolean) => void;
};

export const PublicContactEntryLinksTableEditRow = ({
  item,
  lookupTags = [],
  onSave,
  onCancel,
  showKeyboard,
  setShowKeyboard,
}: Props) => {
  const [t] = useTranslation();

  const initialValues = useMemo(() => {
    const defaultExpirationDate = endOfDay(addDays(new Date(), 60));
    const {
      name: moniker,
      tags,
      expirationDate,
      activityStatus,
    } = item || {};

    return {
      name: moniker || '',
      tags: tags?.map((x) => x.name) || [],
      expirationDate: format(expirationDate ? parseISO(expirationDate) : defaultExpirationDate, 'yyyy-MM-dd'),
      activityStatus: activityStatus || 'ACTIVE',
    };
  }, [item]);

  const onSubmit = useCallback(async (values: typeof initialValues) => {
    const {
      name: moniker,
      tags,
      expirationDate,
      activityStatus,
    } = values;

    await onSave?.({
      name: moniker,
      tags: tags.map((x) => ({ id: undefined, name: x })),
      expirationDate: expirationDate && endOfDay(parse(expirationDate, 'yyyy-MM-dd', new Date())).toISOString(),
      activityStatus,
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, isSubmitting, submitForm: submitFormAsync }) => {
          const submitForm = () => submitFormAsync().catch(() => { /* swallow ... */ });
          return (
            <>
              <TableRow>
                <TableCell colSpan={3} sx={{...BasicTableCellStyle, ...MultiCellTopBorder, ...MultiCellLeftBorder}}>
                  <Box>
                    <Field as={TextField}
                      name='name'
                      label={t('name')}
                      type='text'
                      sx={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}/>
                    <ErrorMessage name='name' component='p' />
                  </Box>
                </TableCell>
                <TableCell colSpan={3} sx={{...BasicTableCellStyle, ...MultiCellTopBorder, ...MultiCellRightBorder}}>
                  <TagsContainer>
                    <TagSelector
                      showKeyboardParent={showKeyboard}
                      setShowKeyboardParent={setShowKeyboard}
                      showText={false}
                      tagData={{ tags: lookupTags }}
                      selectedTags={values.tags}
                      setSelectedTags={(tags) => setFieldValue('tags', tags)} />
                  </TagsContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{...BasicTableCellStyle, ...MultiCellBottomBorder, ...MultiCellLeftBorder}}>
                </TableCell>
                <TableCell sx={{...BasicTableCellStyle, ...MultiCellBottomBorder}}>
                  <Box>
                    <Field as={TextField}
                      name='expirationDate'
                      label={t('expiry')}
                      type='date'
                      inputProps={{
                        min: format(new Date(), 'yyyy-MM-dd')
                      }}
                      sx={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}/>
                    <ErrorMessage name='expirationDate' component='p' />
                  </Box>
                </TableCell>
                <TableCell sx={{...BasicTableCellStyle, ...MultiCellBottomBorder}}>
                  <SelectFormControl>
                    <InputLabel>{t('status')}</InputLabel>
                    <Field as={Select}
                      name='activityStatus'
                      label={t('status')}>
                      <MenuItem value='ACTIVE'>{t('active')}</MenuItem>
                      <MenuItem value='INACTIVE'>{t('inactive')}</MenuItem>
                    </Field>
                  </SelectFormControl>
                </TableCell>
                <TableCell colSpan={3} sx={{...BasicTableCellStyle, ...MultiCellBottomBorder, MultiCellRightBorder}}>
                  <ButtonsContainer>
                    <SubmitButton
                      onClick={submitForm}
                      disableElevation
                      startIcon={isSubmitting && <CircularProgress size='1rem' color='inherit' />}
                      disabled={isSubmitting}>
                      {t('save')}
                    </SubmitButton>
                    <CancelButton
                      onClick={() => onCancel?.()}
                      disableElevation>
                      {t('cancel')}
                    </CancelButton>
                  </ButtonsContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
              </TableRow>
            </>
          );
        }}
      </Formik>
    </>
  );
};
