import { Box, Checkbox, Table, TableBody, TableContainer } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const EmptyListTextStyle = {
  ...themePublic.emptyListText as CSSProperties,
  [themeLocal.breakpoints.down('sm')]: {
    margin: 0,
    marginTop: '5rem',
    textAlign: 'center'
  },
};

export const TableBulkOptionsHeader = styled(Box)(
  () => ({
    ...themePublic.tableBulkOptionsHeader as CSSProperties,
  }),
);

export const TableBulkOptionsCheckbox = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
  }),
);

export const TableBulkOptionsCheckboxSelected = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
    ...themePublic.tableBulkOptionsCheckboxSelected as CSSProperties,
  }),
);

export const ListContainer = styled(Box)(
  () => ({
    padding: 0,
  }),
);

export const TableContainerStyled = styled(TableContainer)(
  ({ theme }) => ({
    [theme.breakpoints.down(1700)]: {
      maxWidth: '77.127vw',
    },
    [theme.breakpoints.down(1300)]: {
      maxWidth: '74.525vw',
    },
    [theme.breakpoints.down(900)]: {
      maxWidth: '72.125vw',
    },
  }),
);

export const TableStyled = styled(Table)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const TableBodyStyled = styled(TableBody)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);
