import React, { memo } from 'react';
import { Box, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import { ErrorMessage, Field, FormikErrors } from 'formik';

import { themeDefault } from 'themeDefault';

import { AbsoluteError, ErrorSymbol, InputErrorStyle, InputFieldContainer, RegisterFieldStyle, VisibilityOffIconStyled } from './InputField.styled';

type InputFieldProps = {
  disabled?: boolean
  handleClick?: any,
  autoFocus?: boolean,
  inputId: string,
  inputName: string,
  inputProps?: boolean,
  isError?: string | string[] | FormikErrors<any> | FormikErrors<any>[],
  label?: string,
  maxRows?: number
  placeholder?: string,
  rows?: number
  showInputText?: boolean,
  value?: string,
  type: string,
  errorAbsolutePosition?: boolean
  boxStyles?: SxProps<Theme>
  children?: React.ReactNode,
  setInputName?: (fieldName: string) => void,
  setShowKeyboard?: (show: boolean) => void,
  setShowDropdown?: (show: boolean) => void,
};
const InputFieldCompoenent = ({
  disabled,
  handleClick,
  inputId,
  inputName,
  inputProps,
  isError,
  label,
  maxRows,
  placeholder,
  rows,
  showInputText,
  value = '',
  type,
  boxStyles,
  children,
  setInputName = null,
  setShowKeyboard = null,
  setShowDropdown = null,
  autoFocus = false,
  errorAbsolutePosition = false,
}: InputFieldProps) => {
  return (
    <Box
      component={errorAbsolutePosition ? AbsoluteError : InputFieldContainer}
      color={!showInputText && themeDefault.palette.mainColor}
      sx={boxStyles}>
      <Field
        id={inputId}
        as={TextField}
        type={showInputText ? 'text' : type}
        variant='outlined'
        onFocus={() => {
          if ( setInputName && setShowKeyboard ) {
            setInputName(inputName);
            setShowKeyboard(true);
          }
          if (setShowDropdown) {
            setShowDropdown(true);
          }
        }}
        autoFocus={autoFocus}
        disabled={disabled}
        name={inputName}
        label={label}
        multiline={rows > 1}
        InputLabelProps={ value ? { shrink: value } : {}}
        rows={4}
        maxRows={maxRows && maxRows}
        autoComplete='off'
        size='small'
        placeholder={placeholder}
        sx={isError ? InputErrorStyle : RegisterFieldStyle}
        InputProps={inputProps && {
          endAdornment: (
            <>
              <InputAdornment onClick={handleClick} position='end' sx={{ outline: 'none' }} style={{ cursor: 'pointer' }}>
                <VisibilityOffIconStyled />
              </InputAdornment>
              {isError && <ErrorSymbol><span>!</span></ErrorSymbol>}
            </>
          )
        }} />
      {children}
      <ErrorMessage name={inputName} component='p'/>
    </Box>
  );
};

export const InputField = memo(InputFieldCompoenent, (prevProps, nextProps) => {
  return prevProps.children === nextProps.children &&
    prevProps.inputName === nextProps.inputName &&
    prevProps.showInputText === nextProps.showInputText &&
    prevProps.value === nextProps.value;
});

InputField.displayName = 'InputField';
