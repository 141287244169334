import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
  query Contacts($order: [ContactPayloadSortInput!], $filter: ContactFilterInput, $skip: Int, $take: Int) {
    contacts(
      order: $order
      filter: $filter
      skip: $skip
      take: $take
      )
    {
      items {
        zipCode
        address
        city
        street
        state
        firstName
        lastName
        email
        title
        id
        phoneNumber
        tags {
          id
          name
        }
        contacts {
          firstName
          lastName
          email
          title
          id
          phoneNumber
          zipCode
          address
          city
          street
          state
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const GET_CONTACTS_IDS = gql`
  query Contacts($order: [ContactPayloadSortInput!], $filter: ContactFilterInput, $skip: Int, $take: Int) {
    contacts(
      order: $order
      filter: $filter
      skip: $skip
      take: $take
      )
    {
      items {
        id
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ALL_CONTACT_IDS_AND_TAGS = gql`
  query Contacts($order: [ContactPayloadSortInput!], $filter: ContactFilterInput, $skip: Int, $take: Int) {
    contacts(
      order: $order
      filter: $filter
      skip: $skip
      take: $take
      )
    {
      items {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

export const GET_TITLES = gql`
  query Titles{
    titles
    {
      id
      value
    }
  }
`;