import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const VenuesPageHeader = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
  }),
);

export const SearchInputHeader = styled(TextField)(
  () => ({
    ...themePublic.searchInputHeader as CSSProperties,
  }),
);

export const ChipContainer = styled(Box)(
  () => ({
    ...themePublic.chipContainer as CSSProperties,
  }),
);
