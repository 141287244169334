import React, { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ORDER_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { auth, getUserSettings, showToast } from 'helpers';
import {
  GET_CURRENT_USER, GET_USER_SETTINGS,
  graphLazyQueryMiddleware, graphMutationMiddleware,
  UPDATE_USER_SETTINGS
} from 'services';
import { ChildrenType as AuthProviderProps, UserProps } from 'types';

const AuthContext = React.createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [t] = useTranslation();
  const [ currentUser, setCurrentUser ] = useState(null);
  const [ dbUser, setDbUser ] = useState(null);
  const [ initialized, setInitialized ] = useState(false);
  const client = useApolloClient();

  const [updateUserSettings] = graphMutationMiddleware(UPDATE_USER_SETTINGS);
  const [getUserSettingsData] = graphLazyQueryMiddleware(GET_USER_SETTINGS);
  const [getUser] = graphLazyQueryMiddleware(GET_CURRENT_USER);

  useEffect(() => {
    auth.onIdTokenChanged({
      async next(user) {
        setCurrentUser(user);
        await user?.getIdToken();
        setInitialized(true);
      },
      error(error) {
        showToast('error', error.message);
        setInitialized(true);
      },
      // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
      complete() { }
    });
  }, []);

  const signup = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = async (email: string, password: string) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      const resGetUser = await getUser();
      await getUserSettingsConfig(resGetUser.data.currentUser.id);
    } catch (e) {
      showToast('error', t('loginCredentialError'), <Link to='/forgot-password'>{t('passwordResetLinkTxt')}</Link>);
    }
  };

  const getUserSettingsConfig = async (userId: number) => {
    if (!userId) {
      return;
    }

    await getUserSettingsData({
      fetchPolicy: 'cache-and-network'
    }).then(resUserSettings => {
      if (!resUserSettings.data.userSettings) {
        getUserSettings(null, userId);
        return;
      }
      const userSettingsJSON = {
        ...resUserSettings.data.userSettings,
        value: JSON.parse(resUserSettings.data.userSettings.value)
      };
      localStorage.setItem('userSettings', JSON.stringify(userSettingsJSON));
    });
  };

  const logout = async () => {
    setInitialized(false);
    await auth.signOut();
    localStorage.clear();
    setCurrentUser(null);
    setDbUser(null);
    await client.clearStore();
    setInitialized(true);
  };

  const setDbCurrentUser = (userPayload: UserProps) => {
    setDbUser(userPayload);
  };

  const saveUserSettings = (tableType: USER_SETTINGS_CONFIG, orderBy: string, order: ORDER_TYPE) => {
    const userSettingsJson = getUserSettings(null, dbUser.id);
    userSettingsJson[tableType].orderBy = orderBy;
    userSettingsJson[tableType].order = order;
    updateUserSettings({ variables: {
      input: {
        userId: dbUser.id,
        value: JSON.stringify(userSettingsJson)
      }
    }}).then(resUpdateUserSettings => {
      const userSettingsJSON = {
        ...resUpdateUserSettings.data.updateUserSettings,
        value: JSON.parse(resUpdateUserSettings.data.updateUserSettings.value)
      };
      localStorage.setItem('userSettings', JSON.stringify(userSettingsJSON));
    });
  };

  const values = {
    currentUser,
    login,
    logout,
    signup,
    setDbCurrentUser,
    dbUser,
    saveUserSettings,
    getUserSettingsConfig,
  };
  return (
    <AuthContext.Provider value={values}>
      {initialized && children}
    </AuthContext.Provider>
  );
};