import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const SingleCardContainer = styled(Box)(
  (props) => ({
    height: props.height as string,
    position: 'relative',
    width: '100%',
    '& img': {
      boxShadow: themeDefault.canvasBoxShadow,
      left: '50%',
      maxWidth: '80%',
      maxHeight: '90%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    [props.theme.breakpoints.down('sm')]: {
      height: props.height as string,
    },
  }),
);

export const TwoCardContainer = styled(Box)(
  ({ theme, style }) => ({
    height: style.height as string,
    position: 'relative',
    width: '100%',
    top: '0 !important',
    '& :nth-child(1)': {
      boxShadow: themeDefault.canvasBoxShadow,
      left: '10%',
      maxWidth: '70%',
      maxHeight: '80%',
      top: '10%',
      position: 'absolute',
    },
    '& :nth-child(2)': {
      boxShadow: themeDefault.canvasBoxShadow,
      left: '60%',
      maxWidth: '70%',
      maxHeight: '80%',
      position: 'absolute',
      top: `${style.top}`,
      transform: 'translate(-50%, -50%)',
    },
    [theme.breakpoints.down('sm')]: {
      height: style.height,
    },
  })
);

export const ThreeCardContainer = styled(Box)(
  (props) => ({
    position: 'relative',
    width: '100%',
    height: props.height as string,
    '& img': {
      display: 'none',
    },
    '& :nth-child(1)': {
      boxShadow: themeDefault.canvasBoxShadow,
      display: 'block',
      left: '50%',
      maxHeight: '80%',
      maxWidth: '70%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 3,
    },
    '& :nth-child(2)': {
      boxShadow: themeDefault.canvasBoxShadow,
      display: 'block',
      left: '5%',
      maxHeight: '70%',
      maxWidth: '50%',
      minWidth: '20%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      zIndex: 2,
    },
    '& :nth-child(3)': {
      boxShadow: themeDefault.canvasBoxShadow,
      display: 'block',
      maxHeight: '70%',
      maxWidth: '50%',
      minWidth: '20%',
      position: 'absolute',
      right: '5%',
      top: '50%',
      transform: 'translate(0, -50%)',
      zIndex: 1,
    },
    [props.theme.breakpoints.down('sm')]: {
      height: props.height as string,
    },
  }),
);

export const AdditionalCardsBadge = styled(Box)(
  (props) => ({
    backgroundColor: themeDefault.palette.lightMainColor,
    border: `1px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '50%',
    color: themeDefault.palette.mainColor,
    fontSize: props.fontSize as string,
    fontWeight: 600,
    padding: '4px',
    position: 'absolute',
    right: '5%',
    textAlign: 'center',
    top: props.top as string,
    zIndex: 10,
    [props.theme.breakpoints.down('sm')]: {
      boxSizing: 'border-box',
      height: '28px',
      width: '28px',
    },
  }),
);
