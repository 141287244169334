import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Loader } from 'components';
import { useInvitation } from 'contexts';
import { RESPONSE_STATUS } from 'enums';
import { RSVP_STATUS_TRANSLATE, getRouteForNavigation, handleCustomSortFilter, COLUMN_TITLES_TRANSLATE } from 'helpers';
import { InvitationSendPage } from 'pages';
import {
  CUMULATIVE_RESPONSE_UPDATED_SUBSCRIPTION,
  GET_CUMULATIVE_EVITES_RESPONSES,
  GET_LINKED_INVITATIONS,
  GET_GUEST_RESPONSES,
  graphLazyQueryMiddleware
} from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { CumulativeResponses, CustomOrderGuestResponse, EviteFilterType, EviteType, GuestCSVResponseFilters, InvitationContextType, InvitationProps } from 'types';

import { ExportResponsesButton, PageBody, PageButtonsContainer, PageContent, PagePrevButton } from './InvitationTrackPage.styled';

export const InvitationTrackPage = () => {
  const [t] = useTranslation();
  const navLocation = useLocation();
  const navHistory = useNavigate();
  const params = useParams();
  const {
    setEnabledInvitations,
    setExtendedInvitations,
    linkedInvitations,
    setLinkedInvitations,
    enabledInvitationsEvitesDict,
    setCumulativeEvitesResponses,
    isExecutedRefetch,
    changeExecuteRefetch,
    setEnabledInvitationsEvitesDict
  } = useTrackStore();

  const { setSelectedGuests, params: filterParams, searchText } = useGuestStore();
  const { invitationDetails }: InvitationContextType = useInvitation();
  const [ getLinkedInvitations, { data: linkedInvitationsData, loading: linkedInvitationLoading }] = graphLazyQueryMiddleware(GET_LINKED_INVITATIONS);
  const [ getCumulativeEvitesResponses, { data: cumulativeResponsesData, refetch: refetchCumulativeEviteResposes, subscribeToMore: subscribeToGetCumulativeResponses }] = graphLazyQueryMiddleware(GET_CUMULATIVE_EVITES_RESPONSES);
  const [getGuestResponses] = graphLazyQueryMiddleware(GET_GUEST_RESPONSES);

  const [ evitesConfigsDict, setEvitesConfigsDict ] = useState<any>({});
  const [ isFirstFetch, setIsFirstFetch ] = useState(false);
  const [ selectedEvitesResponses, setSelectedEvitesResponses ] = useState<EviteFilterType[]>(null);

  useEffect(() => {
    getLinkedInvitations({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: Number(params.id)
      }});
    return () => {
      setSelectedGuests();
      setEnabledInvitationsEvitesDict({});
    };
  }, []);

  useEffect(() => {
    if (isFirstFetch && cumulativeResponsesData) {
      let storedResponses = null;
      subscribeToGetCumulativeResponses({
        document: CUMULATIVE_RESPONSE_UPDATED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data.cumulativeResponseUpdated) {
            return prev;
          }
          const newData = {...subscriptionData.data.cumulativeResponseUpdated} as CumulativeResponses;
          const prevData = [...prev.cumulativeEvitesResponses] as CumulativeResponses[];

          const resIndex = prevData.findIndex((res: CumulativeResponses) => res.eviteId === newData.eviteId);
          storedResponses = prevData.map(res => res.eviteId === newData.eviteId ? newData : res);
          prevData[resIndex] = newData;
          setCumulativeEvitesResponses(storedResponses);
          return { cumulativeEvitesResponses: storedResponses };
        },
      });
      setIsFirstFetch(false);
    }
  }, [isFirstFetch]);

  useEffect(() => {
    if (!linkedInvitationsData || !invitationDetails) {
      return;
    }

    setExtendedInvitations([invitationDetails.id]);
    setEnabledInvitations([invitationDetails.id]);
    enabledInvitationsEvitesDict[invitationDetails.id] = invitationDetails.evites.filter((evite: EviteType) => evite.requestRsvp);

    const linkedInvitationsRSVPOnly = [...linkedInvitationsData.linkedInvitations.items];
    linkedInvitationsRSVPOnly.forEach((invitation: InvitationProps) => invitation.evites.map((evite: EviteType) => evite.requestRsvp));

    setLinkedInvitations(linkedInvitationsRSVPOnly);

    const evitesIds = [
      ...invitationDetails.evites.map((evite: EviteType) => evite.id),
      ...linkedInvitationsData.linkedInvitations.items
        .map((invitation: InvitationProps) => invitation.evites.map((evite: EviteType) => evite.id))
        .flat()
    ];

    getCumulativeEvitesResponses({
      fetchPolicy: 'cache-and-network',
      variables: {
        evitesIds: evitesIds
      },
    }).then(res => {
      setCumulativeEvitesResponses(res.data.cumulativeEvitesResponses);
      setIsFirstFetch(true);
    });
  }, [linkedInvitationsData]);

  useEffect(() => {
    if (cumulativeResponsesData) {
      setCumulativeEvitesResponses(cumulativeResponsesData.cumulativeEvitesResponses);
    }
  }, [cumulativeResponsesData]);

  useEffect(() => {
    if (isExecutedRefetch) {
      cumulativeResponsesData && refetchCumulativeEviteResposes();
      changeExecuteRefetch();
    }
  }, [isExecutedRefetch]);

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const generateCsvTranslate = (
    translateKeys: string[],
    translatePrefix: string
  ) => translateKeys.map(key => ({key: key, value: t(`${translatePrefix}${key}`)}));

  const handleExport = () => {

    const translatedColumns = generateCsvTranslate(COLUMN_TITLES_TRANSLATE.KEYS, COLUMN_TITLES_TRANSLATE.PREFIX);

    const translatedStatuses = generateCsvTranslate( RSVP_STATUS_TRANSLATE.KEYS, RSVP_STATUS_TRANSLATE.PREFIX);

    const enumValues = Object.values(RESPONSE_STATUS).map((enumValue: string) => enumValue.toLowerCase());
    const selectedStatusCopy = filterParams.selectedStatus.map((selStatus: string) => Object.keys(RESPONSE_STATUS)[enumValues.indexOf(selStatus.split(' ').join('').toLocaleLowerCase() as unknown as RESPONSE_STATUS)]);
    const eviteFiltersInput = filterParams.selectedEvitesResponses ?
      [ ...filterParams.selectedEvitesResponses, { responseStatuses: selectedStatusCopy }] :
      [{ responseStatuses: selectedStatusCopy }];
    const filters: GuestCSVResponseFilters = {
      invitationsIds: [Number(params.id)],
      customOrder: handleCustomSortFilter(filterParams) as CustomOrderGuestResponse,
      filters: {
        textFilter: searchText,
        senderIds: filterParams.selectedSenders,
        tags: filterParams.selectedTags,
        events: filterParams.selectedEvents,
        status: filterParams.selectedStatus,
        eviteNames: filterParams.selectedEvites,
        eviteFilters: eviteFiltersInput,
      },
      columnsTitles: translatedColumns,
      statusValues: translatedStatuses
    };

    getGuestResponses({
      variables: {
        input: filters
      },
      fetchPolicy: 'no-cache'
    }).then(res => {
      const result = res.data.guestsResponses;
      const csvFile = new File([new Uint8Array(result.data)], result.name, { type: result.type });
      const href = URL.createObjectURL(csvFile);
      const aElement = document.createElement('a');
      aElement.setAttribute('download', 'Responses.csv');
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  return (
    <PageBody>
      <Loader inProgress={linkedInvitationLoading} />
      {linkedInvitations && linkedInvitations.length > 0 &&
        <PageContent>
          <InvitationSendPage
            selectedEvitesResponses={selectedEvitesResponses}
            isTrackPage={true}
            linkedInvitations={linkedInvitations}
            linkedInvitationLoading={linkedInvitationLoading}
            evitesConfigsDict={evitesConfigsDict}
            setEvitesConfigsDict={setEvitesConfigsDict}
            setSelectedEvitesResponses={setSelectedEvitesResponses}
            enabledEvites={Array.prototype.concat.apply([], Object.values(enabledInvitationsEvitesDict))}/>
          <PageButtonsContainer>
            <PagePrevButton
              onClick={() => handleNavigate(getRouteForNavigation(navLocation.pathname, 'send', params['*']))}
              variant='contained'>
              {t(isMobile ? 'back' : 'prevSend')}
            </PagePrevButton>
            <ExportResponsesButton
              onClick={handleExport}
              variant='contained'>
              {t('exportResponses')}
            </ExportResponsesButton>
          </PageButtonsContainer>
        </PageContent>
      }
    </PageBody>
  );
};