import React, { useState } from 'react';
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

import { Loader } from 'components';
import { ImageType } from 'types';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  ActiveSlide, CenteredSlider, FirstSideSlide, ImageContainer, ImageSize,
  NextArrowContainer, PrevArrowContainer, SecondSideSlide, SingleImage, SquareImageContainer,
} from './ImageSlider.styled';

type ClickHandlerType = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

type ImageSliderProps = {
  images: ImageType[],
  loading: boolean,
  getUploadedImageUrl?: (url: string) => void
  getImageId?: (id: number) => void
  slidesToShow?: number
};

export const ImageSlider = ({images, loading, getUploadedImageUrl, slidesToShow=5, getImageId }: ImageSliderProps ) => {
  const [ imageId, setImageId ] = useState<number>(0);

  const NextArrow = ({onClick}: ClickHandlerType) => {
    return (
      <NextArrowContainer onClick={onClick}>
        <ArrowForwardIosIcon />
      </NextArrowContainer>
    );
  };

  const PrevArrow = ({onClick}: ClickHandlerType) => {
    return (
      <PrevArrowContainer onClick={onClick}>
        <ArrowBackIosNewIcon />
      </PrevArrowContainer>
    );
  };

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: images?.length > 1,
    dots: false,
    speed: 300,
    slidesToShow: images?.length <= slidesToShow ? images?.length : slidesToShow,
    centerPadding: '0',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: true,
    focusOnSelect: true,
    beforeChange: (current: number, next: number) => {
      setImageId(next);
      getImageId && getImageId(next);
    },
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getImageStyle = (index: number) => {
    return images?.length === 1 ? SingleImage :
      imageId === index ? ActiveSlide :
        imageId === index + 1 || imageId === index - 1 ? FirstSideSlide : SecondSideSlide;
  };

  return (
    <Box sx={{ minWidth: '40vw', maxWidth: '60vw', width: 'fit-content', overflow: 'hidden', marginBottom: '1rem' }}>
      <Loader loadingPage={false} inProgress={loading} />
      <CenteredSlider {...settings}>
        {images && images.map((img: ImageType, index: number) => {
          return (
            <Box key={img.name} component={images.length > 1 ? SquareImageContainer : ImageContainer}>
              <ImageSize
                key={img.name}
                src={img.thumbnailUrl ? img.thumbnailUrl : img.url}
                onClick={() => imageId === index && getUploadedImageUrl && getUploadedImageUrl(img.url)}
                sx={getImageStyle(index)}/>
            </Box>
          );
        })}
      </CenteredSlider>
    </Box>
  );
};
