import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import { GroupMobileGuest, GuestsGroupTableRow, GuestsTableHead, GuestsTableRow, SingleMobileGuest, TableWrapper } from 'components';
import { useAuth } from 'contexts';
import { RESPONSE_STATUS, TABLE_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { getUserSettings, handleCustomSortFilter, useFetching } from 'helpers';
import { GET_GUESTS_BY_INVITATIONS_IDS, SEND_GUEST_UPDATED_SUBSCRIPTION, graphLazyQueryMiddleware } from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { ContactColumnType, EviteType, GuestType, InvitationProps, UseAuthProps } from 'types';

import { EmptyListTextStyle, TableBodyMobile, TableBodyStyled, TableContainerPreview, TableStyled } from './GuestsTable.styled';

type GuestTableProps = {
  enabledEvites?: EviteType[],
  forPreview: boolean,
  guestColumns: ContactColumnType[],
  invitation: InvitationProps,
  isTrackPage?: boolean,
  sendToOneContact: (guestId: number) => void,
  setGuestForPreview?: (guest: GuestType) => void,
  sendersIds: number[]
};

const GuestsTableComponent = ({
  enabledEvites = [],
  forPreview = false,
  guestColumns,
  invitation,
  setGuestForPreview = null,
  isTrackPage = false,
  sendToOneContact,
  sendersIds
}: GuestTableProps) => {
  const urlParams = useParams();
  const { dbUser }: UseAuthProps = useAuth();

  const [ startFetching, setStartFetching ] = useState(false);
  const [ storedData, setStoredData ] = useState(null);

  const { params, setPageInfo, changeExecuteRefetch, pageInfo, searchText } = useGuestStore();
  const { cumulativeEvitesResponses, enabledInvitations, setEnabledInvitations, changeExecuteRefetch: refetchResponses } = useTrackStore();

  const [ getGuests, { data: guestsData, refetch: refetchGuests, loading: loadingGuests, subscribeToMore: subscribeToGetGuests }] = graphLazyQueryMiddleware(GET_GUESTS_BY_INVITATIONS_IDS);

  const userSettings = getUserSettings(
    isTrackPage ?
      USER_SETTINGS_CONFIG.GUEST_TABLE_TRACK_PAGE :
      forPreview ?
        USER_SETTINGS_CONFIG.EDIT_GUEST_PREVIEW_PAGE :
        USER_SETTINGS_CONFIG.GUEST_TABLE_SEND_PAGE
    , dbUser.id);

  useEffect(() => {
    setEnabledInvitations([Number(urlParams.id)]);
  }, []);

  const fetchGuests = (newSearchText = '') => {
    if (!startFetching) {
      return;
    }

    const enumValues = Object.values(RESPONSE_STATUS).map((enumValue: string) => enumValue.toLowerCase());
    const selectedStatusCopy = params.selectedStatus.map((selStatus: string) => Object.keys(RESPONSE_STATUS)[enumValues.indexOf(selStatus.split(' ').join('').toLocaleLowerCase() as unknown as RESPONSE_STATUS)]);
    const eviteFiltersInput = params.selectedEvitesResponses ?
      [ ...params.selectedEvitesResponses, { responseStatuses: selectedStatusCopy }] :
      [{ responseStatuses: selectedStatusCopy }];

    getGuests({
      fetchPolicy: 'cache-and-network',
      variables: {
        invitationsIds: isTrackPage ? enabledInvitations : [Number(urlParams.id)],
        skip: params.rowsPerPage * (params.page - 1),
        take: params.rowsPerPage,
        customOrder: handleCustomSortFilter(params),
        filters: {
          textFilter: newSearchText,
          senderIds: isMobile ? sendersIds : params.selectedSenders,
          tags: params.selectedTags,
          events: params.selectedEvents,
          status: selectedStatusCopy,
          eviteNames: params.selectedEvites,
          eviteFilters: eviteFiltersInput,
        }
      }
    }).then((res) => {
      setPageInfo({
        hasNextPage: res.data.guests.pageInfo.hasNextpage,
        hasPreviousPage: res.data.guests.pageInfo.hasPreviousPage,
        totalCount: res.data.guests.totalCount
      });
    });
  };

  useEffect(() => {
    if (startFetching) {
      fetchGuests(searchText);
    }
  }, [enabledInvitations]);

  useEffect(() => {
    if (guestsData) {
      setStoredData(guestsData.guests.items);
      setPageInfo({
        totalCount: guestsData.guests.totalCount,
        hasNextPage: guestsData.guests.pageInfo.hasNextPage,
        hasPreviousPage: guestsData.guests.pageInfo.hasPreviousPage,
      });
    }
  }, [guestsData]);

  useEffect(() => {
    if (!startFetching) {
      return;
    }
    changeExecuteRefetch();
  }, [cumulativeEvitesResponses]);

  useEffect(() => {
    if (!startFetching) {
      return;
    }
    subscribeToGetGuests({
      document: SEND_GUEST_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data.guestUpdated) {
          return prev;
        }
        const newPrev = {...prev};
        const guestIndex = prev.guests.items.findIndex((guest: GuestType) => guest.id === subscriptionData.data.guestUpdated.id);
        Object.assign(newPrev, { [guestIndex]: subscriptionData.data.guestUpdated });
        refetchResponses();
        return newPrev;
      }
    });
  }, [startFetching]);

  useFetching( fetchGuests, refetchGuests, loadingGuests, setStartFetching, startFetching, userSettings, useGuestStore, TABLE_TYPE.GUEST_TABLE );

  const guests: GuestType[] = guestsData?.guests?.items || storedData || [];

  return (
    <TableWrapper isLoading={loadingGuests} totalCount={pageInfo.totalCount} theme={EmptyListTextStyle}>
      <>
        {isMobile ?
          <Box component={forPreview ? TableContainerPreview : Box}>
            { !loadingGuests &&
                <GuestsTableHead
                  guestsLength={pageInfo.totalCount}
                  forPreview={forPreview}
                  guestColumns={guestColumns}
                  enabledEvites={enabledEvites}
                  isTrackPage={isTrackPage} />}
            <TableBodyMobile>
              {
                guests?.map((row) => (
                  row.contact.contacts.length > 0 ?
                    <GroupMobileGuest
                      key={row.id}
                      forPreview={forPreview}
                      isTrackPage={isTrackPage}
                      sendToOneContact={sendToOneContact}
                      invitation={invitation}
                      guest={row}
                      enabledEvites={enabledEvites}/> :
                    <SingleMobileGuest
                      key={row.id}
                      forPreview={forPreview}
                      isTrackPage={isTrackPage}
                      sendToOneContact={sendToOneContact}
                      invitation={invitation}
                      guest={row}
                      enabledEvites={enabledEvites} />
                ))
              }
            </TableBodyMobile>
          </Box> :
          <TableContainer component={Box}>
            <TableStyled sx={{ minWidth: '33.85vw', borderCollapse: 'separate', borderSpacing: '0' }}>
              { !loadingGuests &&
                <GuestsTableHead
                  guestsLength={pageInfo.totalCount}
                  forPreview={forPreview}
                  guestColumns={guestColumns}
                  enabledEvites={enabledEvites}
                  isTrackPage={isTrackPage} />}
              {
                guests?.length > 0 && !loadingGuests &&
                  <TableBodyStyled>
                    <TableRow>
                      <TableCell />
                    </TableRow>
                    {guests.map((row: GuestType) => (
                      row?.contact?.contacts?.length > 0 ?
                        <GuestsGroupTableRow
                          key={row.id}
                          enabledEvites={enabledEvites}
                          forPreview={forPreview}
                          guest={row}
                          invitation={invitation}
                          isTrackPage={isTrackPage}
                          sendToOneContact={sendToOneContact}/> :
                        <GuestsTableRow
                          key={row.id}
                          invitation={invitation}
                          guest={row}
                          setGuestForPreview={setGuestForPreview}
                          sendToOneContact={sendToOneContact}
                          forPreview={forPreview}
                          isTrackPage={isTrackPage}
                          enabledEvites={enabledEvites}/>
                    ))}
                  </TableBodyStyled>
              }
            </TableStyled>
          </TableContainer>}
      </>
    </TableWrapper>
  );
};

export const GuestsTable = memo(GuestsTableComponent);

GuestsTable.displayName = 'GuestsTable';
