import { gql } from '@apollo/client';

export const ADD_CONTACT = gql`
mutation addContact ($input: ContactInput) {
  addContact (input: $input) {
    firstName
    lastName
    title
    email
    phoneNumber
    city
    street
    state
    zipCode
  }
}
`;

export const EDIT_CONTACT = gql`
mutation updateContact ($input: UpdateContactInput) {
  updateContact (input: $input) {
    firstName
    lastName
    email
    phoneNumber
    city
    street
    state
    zipCode
    title
  }
}
`;
export const EDIT_CONTACTS_GROUP = gql`
mutation updateContactGroup ($input: UpdateContactGroupInput) {
  updateContactGroup (input: $input) {
    id
    firstName
    lastName
    title
    email
    phoneNumber
    address
    city
    street
    state
    zipCode
    contacts {
      id
      firstName
      lastName
      title
      email
      phoneNumber
      address
      city
      street
      state
      zipCode
    }
    tags {
      id
      name
    }
  }
}
`;

export const DELETE_CONTACTS = gql`
mutation removeContact ($contactId: Long!) {
  removeContact (contactId: $contactId)
}
`;

export const BULK_DELETE_CONTACTS = gql`
mutation bulkRemoveContact ($contactIds: [Long!]) {
  bulkRemoveContact (contactIds: $contactIds)
}
`;
export const BULK_EDIT_CONTACTS = gql`
mutation bulkEditContacts ($input: BulkEditContactsInput){
  bulkEditContacts (input: $input) {
    id
    firstName
    lastName
    tags {
      name
    }
  }
}
`;
export const CREATE_CONTACTS_GROUP = gql`
mutation addContactGroup ($input: ContactGroupInput) {
  addContactGroup (input: $input) {
    id
    firstName
    lastName
    title
    email
    phoneNumber
    address
    city
    street
    state
    zipCode
    contacts {
      id
      firstName
      lastName
      title
      email
      phoneNumber
      address
      city
      street
      state
      zipCode
    }
    tags {
      id
      name
    }
  }
}
`;

export const UPLOAD_CONTACTS_CSV = gql`
  mutation UploadCsv ($file: Upload!){
    uploadCsv(file: $file){
      successfullyAdded {
        firstName
        lastName
        email
      }
      skipped {
        firstName
        lastName
        email
      }
      failed {
        error
        row
      }
    }
  }
`;
