import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, DialogContent, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const DialogContentStyled = styled(DialogContent)(
  ({ theme }) => ({
    maxHeight: '500px',
    padding: 0,
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '15px',
    zIndex: 5000,
  }),
);

export const TabsContainer = styled(Box)(
  () => ({
    width: '100%',
  }),
);

export const TabsStyled = styled(Tabs)(
  () => ({
    '& .MuiTabs-indicator': {
      backgroundColor: themeDefault.palette.mainColor,
    }
  }),
);

export const TabStyled = styled(Tab)(
  () => ({
    color: `${themeDefault.palette.blackColor} !important`,
  }),
);

export const GroupNameDividerContainer = styled(Box)(
  () => ({
    ...themePublic.groupNameDivider as CSSProperties,
  }),
);

export const ButtonContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      margin: '1rem 1.5rem',
    }
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    float: 'right',
    margin: '1rem',
    padding: '8px 48px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      margin: 'auto',
      padding: '0.5rem',
      width: '100%',
    },
  }),
);
