import { Box, Button, Chip, Divider, Menu, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const SubmenuButton = styled(Button)(
  () => ({
    ...themePublic.submenuButton as CSSProperties,
    padding: '6px 0',
    width: '13rem',
  }),
);

export const MenuContent = styled(Menu)(
  () => ({
    ...themePublic.submenuContent as CSSProperties,
  }),
);

export const MenuItemStyled = styled(MenuItem)(
  () => ({
    width: '13rem',
    justifyContent: 'center',
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactMenagementDividerMenu as CSSProperties,
  }),
);

export const ContactPageHeader = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
  }),
);

export const CreateInvitationPageHeader = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: themeDefault.palette.whiteColor,
    padding: '1rem',
    borderRadius: '0.6rem',
  }),
);

export const SearchInputHeader = styled(TextField)(
  () => ({
    ...themePublic.searchInputHeader as CSSProperties,
  }),
);

export const SubmenuContainer = styled(Box)(
  () => ({
    ...themePublic.submenuContainer as CSSProperties,
  }),
);

export const FilterHeading = styled(Box)(
  () => ({
    ...themePublic.filterHeading as CSSProperties,
  }),
);

export const FilterImage = styled('img')(
  () => ({
    ...themePublic.cursorPointer as CSSProperties,
  }),
);

export const FilterMenu = styled(Menu)(
  () => ({
    ...themePublic.filterContent as CSSProperties,
  }),
);

export const ChipStyled = styled(Chip)(
  () => ({
    ...themePublic.chip as CSSProperties,
    maxWidth: '50vw',
  }),
);

export const FilterItemContainer = styled(Box)(
  () => ({
    ...themePublic.filterItemContainer as CSSProperties,
  }),
);

export const FilterSearchField = styled(TextField)(
  () => ({
    ...themePublic.filterSearch as CSSProperties,
  }),
);
