import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { InvitationTemplateType } from 'types';

import { ColumnContainer, DetailsDescription, DetailsTitle, ImageType, ItemContainer } from './InvitationTypeCard.styled';

type InvitationTypeCardProps = {
  type: InvitationTemplateType,
  handleSelectInvitationType: (type: InvitationTemplateType) => void
}

export const InvitationTypeCard = ({ type, handleSelectInvitationType } : InvitationTypeCardProps) => {
  const [t] = useTranslation();

  return (
    isMobile ?
      <ItemContainer onClick={() => handleSelectInvitationType(type)}>
        <ImageType src={type.imageSvg} alt={t('invitationTemplateImage')}/>
        <ColumnContainer>
          <DetailsTitle> {type.name} </DetailsTitle>
          <DetailsDescription> {type.description} </DetailsDescription>
        </ColumnContainer>
      </ItemContainer>
      :
      <ItemContainer onClick={() => handleSelectInvitationType(type)}>
        <ImageType src={type.imageSvg} alt={t('invitationTemplateImage')}/>
        <DetailsTitle> {type.name} </DetailsTitle>
        <DetailsDescription> {type.description} </DetailsDescription>
      </ItemContainer>
  );
};