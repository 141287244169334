import React, { CSSProperties } from 'react';
import { isMobile } from 'react-device-detect';

import {
  Loader,
  MobilePagination,
  MobileSearch,
  TablePagination,
  VenuesHeader,
  VenuesTable
} from 'components';
import { NUMBER_OF_ITEMS_PER_PAGE, USER_SETTINGS_CONFIG } from 'enums';
import { useVenuesStore } from 'store';
import { themePublic } from 'themeDefault';

import { VenuesPageBody, VenuesPageContent } from './VenuesPage.styled';

export const VenuesPage = () => {
  const { setParams, params, searchText, setSearchText, pageInfo, isLoading } = useVenuesStore();

  const changeRowsPerPage = (itemsPerPage: number) => {
    setParams({...params, rowsPerPage: itemsPerPage});
  };

  const changePage = (page: number) => {
    setParams({...params, page: page});
  };

  if (isMobile) {
    return (
      <VenuesPageBody>
        <MobileSearch
          theme={themePublic.mobileSearchContainer as CSSProperties}
          setSearchText={setSearchText}
          searchText={searchText}
          handleOpenFilters={null}
          showFilters={false}/>
        <VenuesPageContent>
          <Loader inProgress={isLoading} />
          <VenuesTable />
          {!isLoading &&
            <MobilePagination
              hasPreviousPage={pageInfo.hasPreviousPage}
              hasNextPage={pageInfo.hasNextPage}
              setPage={changePage}
              setRowsPerPage={changeRowsPerPage}
              page={params.page}
              rowsLength={pageInfo.totalCount}
              rowsPerPage={params.rowsPerPage}/>}
        </VenuesPageContent>
      </VenuesPageBody>
    );
  }

  return (
    <VenuesPageBody>
      <VenuesHeader/>
      <VenuesPageContent>
        <Loader inProgress={isLoading} />
        <VenuesTable />
        {!isLoading &&
          <TablePagination
            invitationToShow={NUMBER_OF_ITEMS_PER_PAGE}
            rowsLength={pageInfo.totalCount}
            rowsPerPage={params.rowsPerPage}
            page={params.page}
            setRowsPerPage={changeRowsPerPage}
            setPage={changePage}
            tableType={USER_SETTINGS_CONFIG.VENUES_TABLE} />}
      </VenuesPageContent>
    </VenuesPageBody>
  );
};