import { Box, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PageContainer = styled(Box)(
  () => ({
    ...themePublic.pageWithPaperContent as CSSProperties,
  }),
);

export const GridStyled = styled(Grid)(
  () => ({
    ...themePublic.pageWithPaperGridContent as CSSProperties,
  }),
);

export const PaperStyled = styled(Paper)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '80%',
    },
  }),
);

export const FormikContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '85%',
    },
  }),
);

export const FormContainer = styled(Box)(
  () => ({
    width: '100%',
  }),
);

export const PasswordContainer = styled(Grid)(
  () => ({
    marginTop: '0.5rem',
    '& a': {
      color: themeDefault.palette.mainColor,
    }
  }),
);

export const LoginButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    border: '2px solid',
    borderColor: themeDefault.palette.mainColor,
    marginBottom: '1rem',
    marginTop: '1.5rem',
    padding: '0.5rem',
    textTransform: 'capitalize',
    width: '100%',
  }),
);

export const SignUpButton = styled(Button)(
  () => ({
    ...themePublic.secondarySubmitButton as CSSProperties,
  }),
);
