import {
  Close as CloseIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon
} from '@mui/icons-material';
import {
  Box, Button, Dialog, DialogContent, IconButton, ListItemButton, Menu, MenuItem,
  Select, Switch, TextField, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form } from 'formik';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const CenterEviteName = {
  textAlign: 'center',
  zIndex: '5 !important',
};

export const NotDisplayedStyle = {
  display: 'none !important',
};

export const PageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    border: '1px solid rgba(128,128,128, 0.5)',
    borderRadius: '0 10px 10px 0',
    flexDirection: 'unset',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    }
  }),
);

export const SelectCardContainer = styled('div')(
  ({ theme }) => ({
    ...themePublic.canvasSidebarFixedSize as CSSProperties,
    backgroundColor: themeDefault.palette.lightGreyColor,
    border: `1px solid ${themeDefault.palette.mainBackground}`,
    boxSizing: 'border-box',
    color: `${themeDefault.palette.whiteColor} !important`,
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  }),
);

export const SelectCardContainerMobile = styled('div')(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    textAlign: 'center',
    width: '100%',
  }),
);

export const SelectCardButton = styled(ListItemButton)(
  () => ({
    ...themePublic.selectCardButtonDesignPage as CSSProperties,
  }),
);

export const SelectCardButtonActive = styled(SelectCardButton)(
  () => ({
    cursor: 'pointer',
  }),
);

export const SelectCardButtonInactive = styled(SelectCardButtonActive)(
  ({ theme }) => ({
    backgroundColor: `${themeDefault.palette.lightGreyColor} !important`,
    borderBottom: `1px solid ${themeDefault.palette.mainBackground} !important`,
    color: `${themeDefault.palette.mainBackground} !important`,
    boxSizing: 'border-box',
    fontWeight: '1000 !important',
    height: '60px',
    '& span': {
      ...themeDefault.overflowText
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: `${themeDefault.palette.lightGreyColor} !important`,
      borderBottom: `1px solid ${themeDefault.palette.mainBackground} !important`,
      color: `${themeDefault.palette.mainBackground} !important`,
      display: 'flex',
      height: '45px',
      justifyContent: 'space-between',
      textAlign: 'center',
    }
  }),
);

export const CardButtonContent = styled('div')(
  () => ({
    alignItems: 'center',
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center',
    width: '100%',
  }),
);

export const AddNewText = styled(ListItemButton)(
  () => ({
    color: themeDefault.palette.blackColor,
    display: 'flex',
    padding: '4px 16px',
  }),
);

export const EditorContainer = styled('div')(
  ({ theme }) => ({
    ...themePublic.canvasSidebarFixedSize as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
    borderRight: `1px solid ${themeDefault.palette.greyColor}`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
);

export const EditorElementContainer = styled('div')(
  () => ({
    ...themePublic.editorElementContainerDesignPage as CSSProperties,
  }),
);

export const EditorElementDesignPage = styled('div')(
  () => ({
    ...themePublic.editorElementDesignPage as CSSProperties
  }),
);

export const FontEditorNameDesignPage = styled(Typography)(
  () => ({
    ...themePublic.fontEditorNameDesignPage as CSSProperties,
  }),
);

export const AddFontLabelDesignPage = styled(Typography)(
  () => ({
    ...themePublic.addFontLabelDesignPage as CSSProperties,
  }),
);

export const FormControlSelect = styled(Select)(
  ({ theme }) => ({
    ...themePublic.formControlSelect as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      margin: '7% 0',
      width: '70px',
    }
  }),
);

export const SelectCardValueTooltip = styled('p')(
  () => ({
    ...themePublic.selectCardValueTooltipDesignPage as CSSProperties,
  }),
);

export const FontMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.fontMenuItemDesignPage as CSSProperties,
  }),
);

export const EditorNameLabel = styled(Typography)(
  () => ({
    ...themePublic.editorNameLabelDesignPage as CSSProperties,
    margin: '7% 0',
  }),
);

export const RoundColorElement = styled('div')(
  ({ theme, style }) => ({
    ...themePublic.roundColorElementDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      transform: style.transform,
    }
  }),
);

export const ImagePickerDesignPage = styled('div')(
  () => ({
    ...themePublic.imagePickerDesignPage as CSSProperties,
  }),
);

export const IconButtonStyled = styled(IconButton)(
  ({ theme, style }) => ({
    ...themePublic.iconButtonDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      transform: style.transform,
    }
  }),
);

export const PageNextButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: 'auto',
      width: '50%',
    },
  }),
);

export const PagePrevButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      borderRadius: 0,
    },
  }),
);

export const ColorTextField = styled(TextField)(
  ({ theme, style }) => ({
    ...themePublic.colorTextFieldDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      transform: style.transform,
      marginLeft: '6px',
      width: '60px',
    }
  }),
);

export const OpenedSubmenuContainer = styled('div')(
  () => ({
    ...themePublic.openedSubmenuContainerDesignPage as CSSProperties,
  }),
);

export const ImageEditorContainer = styled('div')(
  () => ({
    ...themePublic.imageEditorDesignPage as CSSProperties,
  }),
);

export const SelectCardButtonContainerMobile = styled('div')(
  () => ({
    marginRight: '1.5rem',
  }),
);

export const MoreOptionsContainerMobileStyle = styled(Box)(
  () => ({
    ...themePublic.moreOptionsContainerDesignPageMobile as CSSProperties,
    ...themeDefault.flexCenter,
    boxSizing: 'border-box',
    padding: '0 16px',
  }),
);

export const LessOptionsContainerMobileStyle = styled(Box)(
  () => ({
    ...themePublic.lessOptionsContainerDesignPageMobile as CSSProperties,
  }),
);

export const MoreOptionsText = styled(Typography)(
  () => ({
    ...themePublic.moreOptionsTextDesignPage as CSSProperties,
  }),
);

export const MoreOptionIconButton = styled(IconButton)(
  () => ({
    ...themePublic.moreOptionIconButtonDesignPage as CSSProperties,
  }),
);

export const MoreHorizIconStyled = styled(MoreHorizIcon)(
  () => ({
    ...themePublic.moreOptionIconDesignPage as CSSProperties,
  }),
);

export const SelectPlaceholderButton = styled(Button)(
  () => ({
    ...themePublic.selectPlaceholderButton as CSSProperties,
  }),
);

export const DropdownMenu = styled(Menu)(
  () => ({
    '& .MuiPaper-root': {
      width: '90%',
    },
  }),
);

export const PlaceholderMenuItem = styled(MenuItem)(
  ({ theme }) => ({
    ...themePublic.menuItemBorderDesignPage as CSSProperties,
    maxWidth: '50%',
    '& p': {
      ...themeDefault.overflowText,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    }
  }),
);

export const FontFamilySelectItem = styled(Select)(
  ({ theme }) => ({
    ...themePublic.fontFamilyFormControlDesignPage as CSSProperties,
    margin: '7% 0',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      '& .MuiSelect-select': {
        display: 'list-item',
      },
    },
  }),
);

export const SubmenuContainerMobile = styled(Box)(
  () => ({
    ...themePublic.submenuContainerDesignPageMobile as CSSProperties,
  }),
);

export const SubmenuExtendedContainerMobile = styled(Box)(
  () => ({
    ...themePublic.submenuContainerDesignPageMobile as CSSProperties,
    ...themePublic.submenuExtendedContainerDesignPageMobile as CSSProperties,
  }),
);

export const SubmenuButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submenuButtonDesignPage as CSSProperties,
    marginRight: '10px',
    [theme.breakpoints.down('xl')]: {
      marginRight: '5px',
      padding: '10px 15px',
    },
    [theme.breakpoints.down(1300)]: {
      marginRight: '5px',
      padding: '10px 7.5px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      height: 'auto',
      justifyItems: 'center',
      margin: '0 auto',
      padding: '2%',
      transform: 'translateY(0)',
    },
  }),
);

export const ToggleContainer = styled(Box)(
  () => ({
    ...themePublic.toggleContainerDesignPage as CSSProperties,
    marginRight: '1vw',
  }),
);

export const PreviewSwitchButton = styled(Switch)(
  () => ({
    ...themePublic.previewSwitchButtonDesignPage as CSSProperties,
  }),
);

export const SubmenuContainer = styled('div')(
  () => ({
    alignItems: 'center',
    columnWidth: 'auto',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    '& button': {
      height: '75%',
      whiteSpace: 'nowrap',
      width: 'auto',
    }
  }),
);

export const ContainerSubmenuStartButtons = styled('div')(
  () => ({
    ...themePublic.containerSubmenuStartButtonsDesignPage as CSSProperties,
  }),
);

export const ContainerSubmenuEndButtons = styled('div')(
  () => ({
    ...themePublic.containerSubmenuEndButtonsDesignPage as CSSProperties,
  }),
);

export const SubmenuPlaceholdersMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.submenuMenuItemDesignPage as CSSProperties,
    paddingLeft: '25px',
    paddingRight: '25px',
  }),
);

export const SubmenuCopyListMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.submenuMenuItemDesignPage as CSSProperties,
    paddingLeft: '53px',
    paddingRight: '53px',
  }),
);

export const BackgroundSizeText = styled(Typography)(
  ({ theme }) => ({
    bottom: 0,
    fontSize: '10px',
    left: '15px',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      left: 0,
      margin: 'auto 0 0 1rem',
      position: 'relative',
    },
  }),
);

export const EditSizeIcon = styled(EditIcon)(
  () => ({
    fontSize: '14px',
    top: '-1rem',
  }),
);

export const SetBackgroundImageDialogContent = styled(DialogContent)(
  () => ({
    ...themePublic.setBackgroundImageDialogContentDesignPage as CSSProperties,
  }),
);

export const DialogTitle = styled(Typography)(
  () => ({
    fontWeight: 'bold',
    marginBottom: '1rem',
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    ...themePublic.cursorPointer as CSSProperties,
  }),
);

export const FormStyledDialogContent = styled(Form)(
  () => ({
    textAlign: 'start',
    width: '100%',
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    marginTop: '1rem',
    padding: '8px 48px',
    width: 'auto',
  }),
);

export const BottomCanvasButtonsContainerMobile = styled(Box)(
  () => ({
    ...themePublic.bottomCanvasButtonsContainerMobile as CSSProperties,
    '& .Mui-disabled': {
      color: 'rgba(0,0,0,0.4)',
      fontWeight: 1000,
    },
  }),
);

export const BottomCanvasButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.bottomCanvasButtonsContainer as CSSProperties,
  }),
);

export const BottomCanvasButtonsMobile = styled(Button)(
  () => ({
    ...themePublic.bottomCanvasButtonsMobile as CSSProperties,
  }),
);

export const BottomCanvasButtons = styled(Button)(
  () => ({
    ...themePublic.bottomCanvasButtons as CSSProperties,
  }),
);

export const PageButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
    justifyContent: 'space-between',
  }),
);

export const NewEviteDialog = styled(Dialog)(
  ({ theme }) => ({
    ...themePublic.dialogStyle as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
      },
    },
  }),
);

export const ActionButtonContainer = styled(Box)(
  () => ({
    ...themePublic.actionButtonContainer as CSSProperties,
  }),
);
