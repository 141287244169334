import React, { CSSProperties } from 'react';
import { MenuItem } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { DefaultButton, TabPanel } from 'components';
import { themePublic } from 'themeDefault';
import { InvitationProps, SendersProps } from 'types';

import { ButtonContainer, CloseIconStyled, DialogContentStyled, DialogStyled, InputRowContainer, LabelName, SelecStyled } from './InvitationPreviewSenderModal.styled';

type InvitationPreviewSenderModalProps = {
    isOpen: boolean,
    handleClose: () => void
    setNewSenderId: (id: number) => void
    newSenderId: number
    sender: SendersProps
    handleSender: () => void
    invitation: InvitationProps
  };
export const InvitationPreviewSenderModal = ({isOpen, handleClose, invitation, setNewSenderId, handleSender, newSenderId, sender}: InvitationPreviewSenderModalProps) => {
  const [t] = useTranslation();

  return (
    <>
      <DialogStyled open={isOpen} onClose={() => handleClose()} maxWidth='md' fullScreen={isMobile} sx={{ overflowY: 'visible !important'}}>
        <DialogContentStyled sx={{overflowY: 'visible'}}>
          <CloseIconStyled onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            handleClose();
          }} />
          <TabPanel value={1} index={1}>
            <InputRowContainer>
              <LabelName> {t('selectSender')}: </LabelName>
              {sender &&
                    <SelecStyled
                      size='small'
                      value={newSenderId}
                      placeholder='Thomas Smith'
                      onChange={(e: any) => setNewSenderId(e.target.value)}>
                      {invitation && invitation.senders.length > 0 ?
                        invitation.senders.map((singleSender: SendersProps) => <MenuItem sx={{w: '100%'}} value={singleSender.id} key={singleSender.id}>{`${singleSender.firstName} ${singleSender.lastName}`}</MenuItem>):
                        <MenuItem sx={{w: '100%'}} disabled>{t('noSenders')}</MenuItem>
                      }
                    </SelecStyled>
              }
            </InputRowContainer>
          </TabPanel>
          <div>
            <ButtonContainer>
              <DefaultButton handleFunction={() => handleSender()} theme={themePublic.modalSubmitDefaultButton as CSSProperties} title={t('save')}/>
            </ButtonContainer>
          </div>
        </DialogContentStyled>
      </DialogStyled>
    </>
  );
};