import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, TagSelector } from 'components';
import { BULK_EDIT_CONTACTS, BULK_EDIT_INVITATIONS, GET_ALL_CONTACT_IDS_AND_TAGS, GET_INVITATIONS, GET_TAGS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';

import { InvitationProps, TagType } from 'types';
import { ButtonContainer, ContentContainer, DialogStyled, SubmitButton, Title } from './EditTagsModal.styled';

type EditTagsModalProps = {
  isOpen: boolean,
  closeEditTags: () => void
  selectedIds: number[]
  forInvitations: boolean
}

type ContactTagsType = {
  id: number,
  tags: TagType[]
}

export const EditTagsModal = ({isOpen, closeEditTags, selectedIds, forInvitations}: EditTagsModalProps) => {
  const [t] = useTranslation();

  const [ tags, setTags ] = useState<TagType[]>([]);
  const [ selectedTags, setSelectedTags ] = useState([]);
  const [ initialSelectedTags, setInitialSelectedTags ] = useState([]);
  const [ updateData, setUpdateData ] = useState([]);

  const [ getTags, { data, refetch: refetchTags }] = graphLazyQueryMiddleware(GET_TAGS);
  const [ getAllContactsIdsAndTags, { loading: getContactsLoading }] = graphLazyQueryMiddleware(GET_ALL_CONTACT_IDS_AND_TAGS);
  const [ updateContacts, { loading: editContactsLoading }] = graphMutationMiddleware(BULK_EDIT_CONTACTS);
  const [ getInvitations, { loading: getInvitationsLoading }] = graphLazyQueryMiddleware(GET_INVITATIONS);
  const [ updateInvitations, { loading: editInvitationsLoading }] = graphMutationMiddleware(BULK_EDIT_INVITATIONS);

  useEffect(() => {
    tags.length === 0 && getTags({
      variables: {
        filter: {
          type: forInvitations ? 'INVITATION' : 'CONTACT'
        }
      }
    });

    data?.tags && setTags(data.tags.map((item: TagType) => item.name));
  }, []);

  useEffect(() => {
    if (forInvitations) {
      getInvitations({ variables: {
        filter: {
          ids: selectedIds
        },
      }}).then(result => {
        const allTagsArray = result.data.invitations.items.map((item: any) => item.tags);
        const commonTags = allTagsArray.shift().reduce((res: any, v: any) => {
          if (res.indexOf(v.name) === -1 && allTagsArray.every((a: any) =>
            a.some((x: any) => x.name === v.name)
          )) {
            res.push(v.name);
          }
          return res;
        }, []);
        setSelectedTags(commonTags);
        setInitialSelectedTags(commonTags);

        setUpdateData(result.data.invitations.items.map((invitation: InvitationProps) => ({
          id: invitation.id,
          tags: invitation.tags.map((tag) => tag.name)
        })));
      });
    } else {
      findCommonTags();
    }
  }, [selectedIds]);

  const findCommonTagsForContacts = async () => {
    let iterator = 0;
    let allTagsArray = [];
    let allContactsArray: ContactTagsType[] = [];
    do {
      await getAllContactsIdsAndTags({ variables: {
        filter: {
          ids: selectedIds.slice(iterator * 100, (iterator + 1) * 100),
        },
        take: 100,
      }}).then(result => {
        const allContactsArrayData = result.data.contacts.items;
        allContactsArray = [ ...allContactsArray, ...allContactsArrayData ];
      });
      iterator = iterator + 1;
    }
    while (iterator * 100 < selectedIds.length);

    allTagsArray = allContactsArray.map((item: ContactTagsType) => item.tags);
    setUpdateData(allContactsArray.map((contact: ContactTagsType) => ({
      id: contact.id,
      tags: contact.tags.map((tag) => tag.name)
    })));

    return allTagsArray;
  };

  const findCommonTags = async () => {
    let allTagsArray: TagType[][] = [];
    allTagsArray = await findCommonTagsForContacts();
    const commonTags = allTagsArray.shift().reduce((res, v) => {
      if (res.indexOf(v.name) === -1 && allTagsArray.every((a) =>
        a.some((x) => x.name === v.name)
      )) {
        res.push(v.name);
      }
      return res;
    }, []);
    setSelectedTags(commonTags);
    setInitialSelectedTags(commonTags);
  };

  const updateSelectedTags = (val: string[], action: any, detail: any) => {
    setSelectedTags(val);
    if (action === 'removeOption') {
      updateData.forEach((item: any) => item.tags = item.tags.filter((x: string) => x !== detail.option));
    }
    if (action === 'createOption' || action === 'selectOption') {
      updateData.forEach((item: any) => {
        if (item.tags.indexOf(detail.option) === -1) {
          item.tags.push(detail.option);
        }
      });
    }
  };

  const handleUpdate = () => {
    if (forInvitations) {
      updateInvitations({variables: {
        input: {
          invitations: updateData
        }}}).then(() => {
        refetchTags();
        closeEditTags();
      });
    } else {
      updateContacts({variables: {
        input: {
          contacts: updateData
        }}}).then(() => {
        refetchTags();
        closeEditTags();
      });
    }
  };

  return (
    <>
      <Loader loadingPage={false} inProgress={editContactsLoading || getContactsLoading || editInvitationsLoading || getInvitationsLoading} />
      <DialogStyled maxWidth='xl' open={isOpen} onClose={closeEditTags}>
        <ContentContainer>
          <Title> {t('selectTags')} </Title>
          <TagSelector
            showText={false}
            tagData={data && data}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            updateSelectedTags={updateSelectedTags} />
          <ButtonContainer>
            <SubmitButton
              disabled={selectedTags.length === initialSelectedTags.length && initialSelectedTags.every((tag: any) => selectedTags.includes(tag))}
              onClick={handleUpdate}>
              {t('save')}
            </SubmitButton>
          </ButtonContainer>
        </ContentContainer>
      </DialogStyled>
    </>
  );
};