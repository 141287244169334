import React from 'react';
import { Grid } from '@mui/material';

import { logo } from 'resources';

import { ContentContainer, DividerStyled, Message, PageContainer, PaperStyled } from './PageWithMessage.styled';

type PageWithMessageProps = {
  message: string,
}

export const PageWithMessage = ({ message }: PageWithMessageProps) => {
  return (
    <PageContainer>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={3}>
          <PaperStyled variant='outlined' sx={{ p: { xs: 2, md: 5 }, borderRadius: 5 }}>
            <ContentContainer>
              <img src={logo} width={230} />
              <DividerStyled variant='middle' />
              <Message variant='h5'>
                {message}
              </Message>
            </ContentContainer>
          </PaperStyled>
        </Grid>
      </Grid>
    </PageContainer>
  );
};