import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const InvitationTemplatesPageBody = styled(Box)(
  () => ({
    ...themePublic.pageBody as CSSProperties,
  }),
);

export const InvitationTemplatesPageBodyOnCreating = styled(Box)(
  ({theme}) => ({
    ...themePublic.pageBody as CSSProperties,
    padding: '0',
    minHeight: 'unset',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
    }
  }),
);

export const InvitationTemplatesPageContent = styled(Box)(
  ({ theme, style }) => ({
    ...themePublic.pageContent as CSSProperties,
    padding: '2rem',
    '& p': {
      color: themeDefault.palette.fieldLabelColor,
      fontSize: '14px',
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: themeDefault.defaultBorderRadius,
      justifyContent: 'flex-start',
      marginTop: style?.marginTop,
      padding: '1rem',
    }
  }),
);

export const RowContainer = styled(Box)(
  () => ({
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginBottom: '1rem',
  }),
);

export const CenteredRowContainer = styled(RowContainer)(
  () => ({
    justifyContent: 'center',
  }),
);

export const TitleContainer = styled('p')(
  () => ({
    textAlign: 'center',
    width: '70%',
  }),
);

export const AddNewInvitationTemplateButtonMobile = styled(Paper)(
  () => ({
    ...themePublic.addNewEntityButtonMobile as CSSProperties,
    left: 0,
    marginLeft: '1rem',
    position: 'relative',
    transform: 'none',
    width: 'fit-content',
  }),
);

export const TypographyStyled = styled(Typography)(
  () => ({
    ...themePublic.emptyListText as CSSProperties,
    fontFamily: 'Open Sans !important',
    fontSize: '1rem !important',
    textAlign: 'start',
  }),
);
