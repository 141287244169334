import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

type TableWrapperProps = {
  totalCount: number,
  isLoading: boolean,
  children: JSX.Element,
  theme: CSSProperties
};

export const TableWrapper = ({ totalCount, isLoading, children, theme} :TableWrapperProps) => {
  const [t] = useTranslation();

  return (
    !isLoading && totalCount === 0 ? <Typography sx={theme}> {t('noResultsFound')}</Typography> : children
  );
};