import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Autocomplete, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CustomTooltip, VirtualKeyboard } from 'components';
import { ACTIVITY_STATUS, ORDER_PROPERTY, ORDER_TYPE } from 'enums';
import { DEBOUNCE_SEARCH_WAIT_TIME, MAX_NUMBER_OF_ITEMS_TO_GET, isEmptyStringOrNull } from 'helpers';
import { GET_INVITATIONS, graphLazyQueryMiddleware } from 'services';
import { EventLinkType, InvitationProps } from 'types';

import { ButtonContainer, SelectInvitationAutocompleteContainer, SubmitButton, ThemeOverlappedText } from './SelectInvitation.styled';

type SelectInvitationProps = {
    isOpen: boolean,
    handleClose: () => void,
    title: string,
    submitBtnText: string,
    handleUpdate: (invitationId: number) => void,
    invitation?: InvitationProps
    placeholder?: string
}
export const SelectInvitation = ({isOpen, handleClose, title, handleUpdate, invitation, submitBtnText, placeholder = ''}: SelectInvitationProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);
  const urlParams = useParams();
  const getInitialSelectedLink = () => {
    return {name: invitation?.eventLinkName ?? '', id: invitation?.eventLinkId};
  };
  const [ isOpenDropdown, setIsOpenDropdown ] = useState(false);
  const [ inputValue, setInputValue ] = useState(invitation?.eventLinkName ?? '');
  const [ selectedInvitation, setSelectedInvitation ] = useState<EventLinkType>(getInitialSelectedLink());
  const [ allInvitations, setAllInvitations ] = useState<InvitationProps[]>([]);
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ showKeyboardForEvent, setShowKeyboardForEvent ] = useState(false);

  const [ getInvitations, { data: invitationData, loading: loadingInvitationData }] = graphLazyQueryMiddleware(GET_INVITATIONS);

  useEffect(() => {
    fetchInvitations();
  }, []);

  useEffect(() => {
    if (invitationData) {
      if (invitation) {
        setAllInvitations(invitationData?.invitations?.items.filter((i: InvitationProps) => i.id !== invitation.id));
      } else {
        setAllInvitations(invitationData?.invitations?.items.filter((i: InvitationProps) => i.id !== Number(urlParams.id)));
      }
    }
  }, [invitationData]);

  useEffect((() => {
    if (showKeyboardForEvent) {
      localStorage.getItem('isHebrew') === 'true' ? setIsHebrew(true) : setIsHebrew(false);
    } else {
      setIsOpenDropdown(false);
    }
  }), [showKeyboardForEvent]);

  const debounceFetch = useCallback(debounce((newFilterValue) => fetchInvitations(newFilterValue), DEBOUNCE_SEARCH_WAIT_TIME), []);

  const setValueAndDebounce = (newValue : string) => {
    if (newValue !== inputValue) {
      setInputValue(newValue);
      debounceFetch(newValue);
    }
  };

  const fetchInvitations = (filterValue = '') => {
    getInvitations({
      fetchPolicy: 'cache-and-network',
      variables: {
        order: {[ORDER_PROPERTY.NAME]: ORDER_TYPE.ASC.toLocaleUpperCase()},
        filter: {
          textFilter: filterValue,
          activityStatus: ACTIVITY_STATUS.ACTIVE.toUpperCase(),
        },
        take: MAX_NUMBER_OF_ITEMS_TO_GET,
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
      <DialogContent sx={isMobile ? {width: '70vw'} : {width: '40vw'}}>
        <Typography>{title}</Typography>
        <SelectInvitationAutocompleteContainer>
          <Autocomplete
            sx={{margin: 0, width: '100%'}}
            inputValue={inputValue}
            loading={loadingInvitationData}
            loadingText={t('loading')}
            open={isOpenDropdown}
            onOpen={() => setIsOpenDropdown(true)}
            onClose={() => setIsOpenDropdown(false)}
            onInputChange={(e : React.ChangeEvent<HTMLInputElement>) => {
              e?.target && setValueAndDebounce(isEmptyStringOrNull(e.target.textContent) ? e.target.value : e.target.textContent);
              isHebrew && showKeyboardForEvent && setIsOpenDropdown(true);
            }}
            value={selectedInvitation}
            onFocus={() => {
              setIsOpenDropdown(true);
              setShowKeyboardForEvent(true);
            }}
            onChange={(e, val: EventLinkType) => {
              val = val ?? {id: null, name: ''};
              setSelectedInvitation(val);
              setInputValue(val.name);
              setIsOpenDropdown(false);
              setShowKeyboardForEvent(false);
            }}
            options={allInvitations?.map((item: InvitationProps) => ({name: item.name, id: item.id, eventLinkName: item.eventLinkName})) || []}
            getOptionLabel={(option: EventLinkType) => {
              return option.name ? `${option.name} ${option.eventLinkName ? ` > ${option.eventLinkName}` : ''}` : '';
            }}
            renderOption={(props, option: InvitationProps) => (
              <li {...props} key={option.id}>
                <CustomTooltip theme={ThemeOverlappedText} text={option.name ? `${option.name} ${option.eventLinkName ? ` > ${option.eventLinkName}` : ''}` : ''} />
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}/>
          { showKeyboardForEvent &&
            <VirtualKeyboard
              setShowKeyboard={setShowKeyboardForEvent}
              initialValues={{
                'eventLink': inputValue
              }}
              setStandaloneItem={setValueAndDebounce}
              keyboardRef={keyboardRef}
              inputName={'eventLink'}/>
          }
        </SelectInvitationAutocompleteContainer>
        <ButtonContainer>
          <SubmitButton
            variant='contained'
            disabled={selectedInvitation?.id === getInitialSelectedLink().id || inputValue !== selectedInvitation?.name}
            onClick={() => handleUpdate(selectedInvitation.id)}>
            {submitBtnText}
          </SubmitButton>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
};