import React from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { render, unmountComponentAtNode } from 'react-dom';

import { themeDefault } from 'themeDefault';

import { ButtonsContainer, PromptBody } from './PromptModal.styled';

export type PromptModalProps = {
  isOpen?: boolean
  title?: string
  message: string
  buttons: Array<{
    label: string
    continue?: boolean
    onClick?: () => void
  }>
  tx?: any
}

export const PromptModal = ({ message, buttons, title = '', isOpen = true, tx = null }: PromptModalProps) => {
  const handleClickButton = (button: any) => {
    if (button.onClick) {
      button.onClick();
    }
    closePrompt();

    if (button.continue) {
      tx?.retry();
    }
  };

  const closePrompt = () => {
    removeElementReconfirm();
  };

  return (
    <Dialog maxWidth='xl' open={isOpen} onClose={closePrompt}>
      <PromptBody>
        {
          title &&
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '25px',
              fontWeight: 700,
              marginBottom: '10px'
            }}>
            {title}
          </Typography>
        }
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '14px',
          }}>
          {message}
        </Typography>
        <ButtonsContainer>
          {buttons.map((button, i) => (
            <Button
              key={i}
              onClick={() => handleClickButton(button)}
              sx={{
                color: themeDefault.palette.whiteColor,
                backgroundColor: themeDefault.palette.mainColor,
                borderRadius: '8px',
                height: '2.5rem',
                marginTop: '0.5rem',
                marginRight: '10px',
                width: '48%',
                '&:hover': {
                  backgroundColor: themeDefault.palette.mainColorHover,
                }
              }}>
              {button.label}
            </Button>
          ))}
        </ButtonsContainer>
      </PromptBody>
    </Dialog>
  );
};

const createElementReconfirm = (properties: PromptModalProps) => {
  let divTarget = document.getElementById('prompt-dialog');
  if (!divTarget) {
    divTarget = document.createElement('div');
    divTarget.id = 'prompt-dialog';
    const root = document.getElementById('root');
    root.appendChild(divTarget);
  }

  render(<PromptModal {...properties} />, divTarget);
};

const removeElementReconfirm = () => {
  const target = document.getElementById('prompt-dialog');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

export const usePromptModal = (properties: PromptModalProps) => {
  createElementReconfirm(properties);
};