import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import Keyboard, { KeyboardInput } from 'react-simple-keyboard';
import layout from 'simple-keyboard-layouts/build/layouts/hebrew.js';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import { KeyboardPositionType } from 'types';

import { KeyboardContainer } from './VirtualKeyboard.styled';
import 'react-simple-keyboard/build/css/index.css';

type VirtualKeyboardType = {
    keyboardRef : React.MutableRefObject<any>,
    inputName : string,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    initialValues?: any,
    setShowKeyboard: (show: boolean) => void,
    closeWhenOutOfFocus?: boolean,
    isCanvas?: boolean
    selectedObject?: any
    renderTextFromKeyboard?: () => void
    setEvitesValue?: (evites: string) => void,
    setStandaloneItem?: (value: string) => void,
    setTagsValue?: (tags: any) => void,
    addTag?: (value: any) => void,
    handleParams?: any
    saveEventName?: () => void
    keyboardComponentId?: string
    tagInputValue?: string
}

export const VirtualKeyboard = ({
  keyboardRef: keyboard,
  setFieldValue = null,
  inputName,
  initialValues = null,
  setShowKeyboard,
  isCanvas = false,
  selectedObject = null,
  renderTextFromKeyboard = null,
  setStandaloneItem = null,
  handleParams = null,
  addTag = null,
  setEvitesValue = null,
  setTagsValue = null,
  closeWhenOutOfFocus = false,
  saveEventName = null,
  keyboardComponentId,
  tagInputValue = ''
} : VirtualKeyboardType) => {
  const [ layoutName, setLayoutName ] = useState('default');
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ position, setPosition ] = useState<KeyboardPositionType>({x: 0, y: 0});
  const focusedElementId = useRef(null);
  const [ tagsState, setTagsState ] = useState(null);

  useEffect(() => {
    if (!isMobile && isHebrew && initialValues && keyboard) {
      if (isCanvas) {
        keyboard.current.replaceInput({});
        for (const initialValueKey of Object.keys(initialValues)) {
          keyboard.current.setInput(initialValues[initialValueKey].text, initialValueKey);
        }
      } else {
        for (const initialValueKey of Object.keys(initialValues)) {
          if (Array.isArray(initialValues[initialValueKey]) && initialValueKey !== 'tags' && initialValueKey !== 'evites') {
            for (let i = 0; i < initialValues[initialValueKey].length; i++) {
              keyboard.current.setInput( initialValues[initialValueKey][i], initialValueKey + '[' + i + ']');
            }
          } else {
            keyboard.current.setInput(initialValues[initialValueKey], initialValueKey);
          }
        }
        if (inputName === 'newTags') {
          keyboard.current.setInput(tagInputValue, 'newTags');
          setTagsState(tagInputValue);
        }

      }
    }
  }, [ initialValues, isHebrew, tagInputValue ]);

  const handleShowKeyboard = () => {
    if (localStorage.getItem('isHebrew') === 'true') {
      setIsHebrew(true);
    } else {
      setIsHebrew(false);
      setShowKeyboard(false);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', () => handleShowKeyboard());
    const positionString = localStorage.getItem('keyboardPosition');
    if (positionString) {
      setPosition({x: Number(positionString.split(',')[0]), y: Number(positionString.split(',')[1])});
    }
    handleShowKeyboard();
  }, []);

  const onChangeAll = (allInputs: KeyboardInput) => {
    if (isCanvas) {
      selectedObject.selected[0]._textBeforeEdit = selectedObject.selected[0].text;
      const text = allInputs[inputName];
      selectedObject.selected[0].insertChars(text, null, 0, text.length-1);
      selectedObject.selected[0].exitEditing();
      selectedObject.selected[0].enterEditing();
      renderTextFromKeyboard();
    } else {
      if (handleParams) {
        handleParams('searchText', allInputs[inputName]);
      }
      if (setEvitesValue) {
        if (inputName === 'searchEvites') {
          setEvitesValue(allInputs[inputName]);
        }
      }
      if (setTagsValue) {
        if (inputName === 'newTags') {
          setTagsState(allInputs[inputName]);
          setTagsValue(allInputs[inputName]);
        }
      }
      if (setStandaloneItem) {
        setStandaloneItem(allInputs[inputName]);
      }
      if (setFieldValue) {
        inputName !== 'newTags' && inputName !== 'searchEvites' && setFieldValue(inputName, allInputs[inputName]);
      }
    }
  };

  const onKeyPress = (button: string) => {
    if (button === '{enter}' && keyboard) {
      if (inputName === 'newTags') {
        const array = initialValues['tags'] ? initialValues['tags'].slice() : [];
        array.push(tagsState);
        addTag(array);
        keyboard.current.setInput('', 'newTags');
      }
    }
  };

  const keyboardProps = {
    keyboardRef: (r:any) => (keyboard.current = r),
    onChangeAll,
    inputName,
    layoutName,
    onKeyPress
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (document.getElementById('keyboard')?.contains(e.target as HTMLElement)
    || document.getElementById(focusedElementId.current)?.contains(e.target as HTMLElement)) {
      return;
    }

    setShowKeyboard(false);
    saveEventName && saveEventName();
  };

  useEffect(() => {
    if (closeWhenOutOfFocus) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      focusedElementId.current = document.activeElement.id;
      return () => document.removeEventListener('mousedown', handleOutsideClick, false);
    }
  }, [closeWhenOutOfFocus]);

  const onDragStartHandler = (ev: DraggableEvent, data: DraggableData) => {
    const positionString = data.x.toString() + ',' + data.y.toString();
    localStorage.setItem('keyboardPosition', positionString);
  };

  return (
    <div id={keyboardComponentId}>
      {!isMobile && isHebrew &&
      <Draggable
        defaultPosition={position}
        onDrag={onDragStartHandler}>
        <KeyboardContainer id='keyboard'>
          <Keyboard layout={layout.layout} {...keyboardProps} />
          <button onClick={() => setShowKeyboard(false)}>
            <CloseIcon />
          </button>
        </KeyboardContainer>
      </Draggable>
      }
    </div>
  );
};
