import { gql } from '@apollo/client';

export const SEND_USER_UPDATED_SUBSCRIPTION = gql`
subscription OnUserUpdated {
  userUpdated {
    id
    firstName
    lastName
    email
    role
    invitationsNo
    status
    permissions
  }
}`;
