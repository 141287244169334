import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const HeaderTitle = styled(Typography)(
  ({ theme }) => ({
    fontSize: '22px',
    fontWeight: 'bold',
    margin: '2rem 0',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: themeDefault.defaultBorderRadius,
      fontSize: '14px',
      fontWeight: 'normal',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '30px',
    }
  }),
);

export const PageContent = styled(Grid)(
  () => ({
    display: 'contents',
    flexDirection: 'column',
  }),
);

export const AnotherInvitationTemplateContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      boxSizing: 'border-box',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      padding: '0 1em',
      textAlign: 'center',
      width: '100%',
    }
  }),
);

export const AnotherInvitationTemplateMessage = styled(Typography)(
  ({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '2em 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      fontWeight: 'normal',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }),
);

export const AnotherInvitationTemplateButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.mainColor,
    border: `1px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '0.6rem',
    color: themeDefault.palette.whiteColor,
    height: '3em',
    padding: '0 5em',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor,
      border: `1px solid ${themeDefault.palette.mainColor}`,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
      padding: '0 2em',
    },
  }),
);

export const PageTitle = styled(AnotherInvitationTemplateMessage)(
  () => ({
    textAlign: 'center',
  }),
);

export const SelectCardsWrapper = styled(Grid)(
  () => ({
    display: 'contents',
  }),
);

export const SelectCardsContainer = styled(Grid)(
  ({ theme }) => ({
    alignItems: 'center',
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '2rem',
    padding: '0 5em 1em 5em',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      flexDirection: 'column',
      margin: 0,
      padding: '0 1em',
      textAlign: 'center',
    }
  }),
);

export const CardsContainer = styled(Grid)(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  }),
);

export const MaxContainer = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  }),
);

export const CardContainer = styled(Box)(
  ({ theme }) => ({
    maxWidth: '18%',
    margin: '1rem',
    width: '17%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      width: '70%',
    }
  }),
);

export const PageButtonsContainer = styled(Grid)(
  () => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
  }),
);

export const PageNextButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: 0,
      marginLeft: 'auto',
      padding: '0',
      width: '100%',
    },
  }),
);
