import { PlayArrow as PlayArrowIcon, Replay as ReplayIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import {
  envelopeFront,
  envelopeInside,
  envelopeTop,
  envelopeTopInside,
  envelopeTopShadow,
} from 'resources';

import { themeDefault } from 'themeDefault';

const themeLocal = createTheme();

const MaxSizeStyle = {
  height: '100%',
  width: '100%',
};

const CenteredBackgroundStyle = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const IconStyle = {
  cursor: 'pointer',
  padding: '1rem',
  borderRadius: '16px',
  '& svg': {
    transform: 'scale(3)',
    fill: themeDefault.palette.whiteColor,
  },
  '& :hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '4px',
  }
};

const InvitationCoreStyle = {
  ...MaxSizeStyle,
  transformOrigin: 'center',
  opacity: 1,
  WebkitTransition: 'all 1s ease-out',
};

const InvitationCoreStyleMobile = {
  boxSizing: 'border-box',
  maxWidth: '100%',
  maxHeight: '100%',
};

const BaseEnvelopeStyles = (width = '100%') => ({
  width: width,
  position: 'absolute',
  aspectRatio: '16 / 9',
});

export const PlayIcon = styled(PlayArrowIcon)(
  () => ({
    fill: `${themeDefault.palette.whiteColor} !important`,
    transform: 'scale(1)',
  }),
);

export const ReplayIconStyled = styled(ReplayIcon)(
  () => ({
    fill: `${themeDefault.palette.whiteColor} !important`,
    transition: 'all 0.5s',
    transform: 'rotate(0deg)',
  }),
);

export const ReplayContainer = styled(Box)(
  ({theme}) => ({
    alignItems: 'center',
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '50%',
    bottom: '1rem',
    cursor: 'pointer',
    display: 'flex',
    padding: '0.5rem',
    position: 'absolute',
    right: '1rem',
    '&:hover': {
      '& svg': {
        transform: 'rotate(-180deg)'
      }
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '5rem',
    },
  }),
);
export const EnvelopeBody = styled(Box)(
  () => ({
    ...CenteredBackgroundStyle,
    ...BaseEnvelopeStyles() as CSSProperties,
    backgroundImage: `url(${envelopeFront})`,
    left: 0,
    top: 0,
    zIndex: 11,
  }),
);

export const BackIconContainer = styled(Box)(
  ({ theme }) => ({
    ...IconStyle,
    position: 'absolute',
    left: '1%',
    top: '50%',
    transform: 'translate(0%, -50%)',
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      '& svg': {
        fill: themeDefault.palette.whiteColor,
      },
      '& :hover': {
        backgroundColor: 'transparent !important',
      },
    },
  })
);

export const NextIconContainer = styled(Box)(
  ({ theme }) => ({
    ...IconStyle,
    position: 'absolute',
    right: '1%',
    top: '50%',
    transform: 'translate(0%, -50%)',
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      '& svg': {
        fill: themeDefault.palette.whiteColor,
      },
      '& :hover': {
        backgroundColor: 'transparent !important',
      },
    },
  })
);

export const EnvelopeContainer = styled(Box)(
  ({ theme }) => ({
    ...BaseEnvelopeStyles('70%') as CSSProperties,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

export const EnvelopeContainerMoveDown = {
  translate: '0 30%',
  WebkitTransition: 'all 1s ease-out',
  [themeLocal.breakpoints.down('sm')]: {
    translate: '0 20%',
  },
};

export const EnvelopeTopInside = styled(Box)(
  ({ theme }) => ({
    ...CenteredBackgroundStyle,
    ...MaxSizeStyle,
    backgroundImage: `url(${envelopeTopInside})`,
    backgroundPosition: 'top center',
    backfaceVisibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    top: '49.7%',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      left: '-2px',
      top: '49.7%',
    },
  })
);

export const EnvelopeTopContainer = styled(Box)(
  () => ({
    ...MaxSizeStyle,
    bottom: '49.9%',
    position: 'absolute',
    transform: 'rotateX(0deg)',
    zIndex: 12,
  })
);

export const EnvelopeTopContainerOpen = {
  transform: 'rotateX(180deg)',
  zIndex: 150,
  WebkitTransition: 'all 1s ease-out',
};

export const EnvelopeTopContainerBehind = {
  zIndex: '-10000 !important',
};

export const EnvelopeTop = styled(Box)(
  () => ({
    ...CenteredBackgroundStyle,
    ...MaxSizeStyle,
    backgroundImage: `url(${envelopeTop})`,
    backgroundPosition: 'top center',
    backfaceVisibility: 'hidden',
    opacity: 1,
    position: 'absolute',
    top: '49.7%',
    zIndex: 2,
  })
);

export const EnvelopeShadowContainer = styled(Box)(
  () => ({
    ...MaxSizeStyle,
    bottom: '49.9%',
    position: 'absolute',
    zIndex: 10,
    transform: 'rotateX(0deg)',
  })
);

export const EnvelopeShadowContainerHide = {
  opacity: 0,
  transform: 'rotateX(90deg)',
  WebkitTransition: 'all 1s ease-out',
};

export const EnvelopeTopShadow = styled(Box)(
  () => ({
    ...CenteredBackgroundStyle,
    ...MaxSizeStyle,
    backgroundImage: `url(${envelopeTopShadow})`,
    backgroundPosition: 'top center',
    backfaceVisibility: 'hidden',
    opacity: 1,
    position: 'absolute',
    top: '50%',
    zIndex: 15,
  })
);

export const EnvelopeFront = styled(Box)(
  () => ({
    ...BaseEnvelopeStyles() as CSSProperties,
    backfaceVisibility: 'hidden',
    backgroundSize: 'cover',
    zIndex: 6,
  })
);

export const EnvelopeInside = styled(Box)(
  ({ theme }) => ({
    ...CenteredBackgroundStyle,
    ...BaseEnvelopeStyles() as CSSProperties,
    backgroundImage: `url(${envelopeInside})`,
    top: 0,
    zIndex: 4,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  })
);

export const AnimationContentContainer = styled(Box)(
  ({ theme }) => ({
    aspectRatio: '3 / 2',
    height: '90%',
    maxWidth: '90%',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'scale(1) translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      aspectRatio: '2 / 3',
      height: 'unset',
      overflow: 'visible',
      transform: 'scale(1) translate(-50%, -50%)!important',
      width: '90%',
    },
  })
);

export const AnimationContentContainerrOverflowVisible = {
  overflow: 'visible !important',
};

export const AnimationContentContainerrRemoveButton = {
  bottom: 0,
};

export const AnimationContentContainerrShowAll = {
  visibility: 'visible',
};

export const AnimationContentContainerrVisibilityHidden = {
  visibility: 'hidden',
};

export const AnimationContent = {
  ...MaxSizeStyle,
  overflow: 'hidden',
  position: 'relative',
  transform: 'translateY(100%)',
  [themeLocal.breakpoints.down('sm')]: {
    overflow: 'visible',
  },
};

export const AnimationContentEnvelopeAnimation = {
  ...AnimationContentContainerrOverflowVisible,
  WebkitTransition: 'transform 2s ease-out',
  [themeLocal.breakpoints.down('sm')]: {
    transform: 'translateY(20%) !important',
  },
};

export const PlayButton = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '6px',
    display: 'none',
    justifyContent: 'space-between',
    left: '50%',
    maxWidth: '200px',
    minWidth: '150px',
    padding: '4px 12px',
    position: 'absolute',
    top: '110%',
    transform: 'translate(-50%, -50%)',
    zIndex: 75,
    '& span': {
      color: themeDefault.palette.whiteColor,
      fontSize: '18px',
    },
    '& :nth-child(2)': {
      lineHeight: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '120%',
      whiteSpace: 'nowrap',
    },
  })
);

export const PlayButtonShowStyle = {
  display: 'flex !important',
  WebkitTransition: 'all 1s ease-out',
};

export const DefaultCursorStyle = {
  cursor: 'default',
};

export const PointerCursorStyle = {
  cursor: 'pointer',
};

export const EnvelopeFrontContainer = styled(Box)(
  () => ({
    ...BaseEnvelopeStyles() as CSSProperties,
    opacity: 1,
    top: '0%',
    transform: 'rotateY(180deg)',
  })
);

export const EnvelopeFrontRotationStyle = {
  opacity: 1,
  transform: 'rotateY(0deg)',
  WebkitTransition: 'all 0.5s ease-out',
};

export const InvitationContent = styled(Box)(
  () => ({
    ...MaxSizeStyle,
    overflow: 'hidden',
    position: 'relative',
  })
);

export const InvitationContainer = styled(Box)(
  ({theme, style}) => ({
    ...MaxSizeStyle,
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    transform: 'translate(0%, 0%) rotate(0deg) scale(1)',
    [theme.breakpoints.down('sm')]: {
      display: style.display,
    },
  })
);

export const InvitationContainerRotate = {
  WebkitTransition: 'all 1s ease-out',
  zIndex: 500,
};

export const InvitationContainerAnimation = {
  translate: '0 -100%',
  WebkitTransition: 'all 1s ease-out',
};

const EnvelopeBackStyle = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backfaceVisibility: 'hidden',
  backgroundRepeat: 'no-repeat',
  opacity: 1,
  position: 'absolute',
  top: '0%',
  transform: 'rotateY(0deg)',
  WebkitBackfaceVisibility: 'hidden',
  zIndex: 3,
};

export const EnvelopeBack = styled(Box)(
  ({ theme }) => ({
    ...EnvelopeBackStyle as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'top',
    },
  })
);

export const EnvelopeBackImage = styled('img')(
  ({ theme }) => ({
    ...EnvelopeBackStyle as CSSProperties,
    ...BaseEnvelopeStyles() as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'top',
    },
  })
);

export const EnvelopeBackRotationStyle = {
  transform: 'rotateY(180deg)',
  WebkitTransition: 'all 0.5s ease-out',
};

export const EnvelopeBackFixBackSideStyle = {
  opacity: 0.3,
  WebkitTransition: 'all 0.3s ease-out',
};

export const InvitationCoreContainerStyle = {
  ...InvitationCoreStyle,
  position: 'absolute',
  transform: 'translate(0%, 0%) rotateY(0deg) rotate(90deg) scale(0.95)',
  '& .canvas-container': {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(0deg)',
  },
  [themeLocal.breakpoints.down('sm')]: {
    ...InvitationCoreStyleMobile,
  },
};

export const InvitationCoreContainerHorizontal = {
  ...InvitationCoreStyle,
  position: 'absolute',
  transform: 'translate(0%, 0%)  rotate(0deg) scale(0.95)',
  '& .canvas-container': {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(0deg)',
  },
  [themeLocal.breakpoints.down('sm')]: {
    transform: 'translate(0%, 0%)  rotate(0deg) scale(0.95)',
  },
};

export const CardRotation = {
  transform: 'rotate(0deg) translate(0%, 0%) scale(1)',
};

export const HideEvite = {
  zIndex: -15,
};

export const MoveRightEvite = {
  opacity: 1,
  transform: 'translate(70%, 10%) rotateY(0deg) scale(0.7) !important',
  WebkitTransition: 'all 0.6s ease-out',
  [themeLocal.breakpoints.down('sm')]: {
    opacity: 0,
    transform: 'rotateY(0deg) translate(0%, 200%) scale(0.8) !important',
    WebkitTransition: 'all 0.4s ease-out',
  },
};

export const MoveLeftEvite = {
  opacity: 1,
  transform: 'translate(-70%, 10%) rotateY(0deg) scale(0.7) !important',
  WebkitTransition: 'all 0.6s ease-out',
  [themeLocal.breakpoints.down('sm')]: {
    opacity: 0,
    transform: 'rotateY(0deg) translate(-0%, -100%) scale(0.8) !important',
    WebkitTransition: 'all 0.4s ease-out',
  },
};

export const HideSideEvite = {
  opacity: '0 !important',
  WebkitTransition: 'all 0.3s ease-out',
};

export const NotDisplayedSideEvite = {
  display: 'none',
};

export const SetToBack = {
  opacity: 0,
  transform: 'rotateY(0deg) translate(0%, 0%) scale(0.5) !important',
  WebkitTransition: 'all 1s ease-out',
  zIndex: -33,
  [themeLocal.breakpoints.down('sm')]: {
    transform: 'rotateY(0deg) translate(-30%, -25%) scale(0.5) !important',
  },
};

export const HorizontalSingleCardRotation = {
  opacity: 1,
  transform: 'rotateY(0deg) translate(0%, 75%) scale(1) !important',
  transition: 'all 1s ease-out',
  WebkitTransition: 'all 1s ease-out',
  zIndex: -33,
  [themeLocal.breakpoints.down('sm')]: {
    transform: 'rotateY(0deg) translate(0%, 30%) scale(1) !important',
  },
};

export const KeepSingleOnFrontBaseStyle = {
  transition: 'all 0.6s ease-out',
};

export const KeepSingleOnFrontHorizontal = (scale: number) => ({
  transform: `rotate(0deg) translate(0%, 70%) scale(${scale})`,
  transition: 'all 0.6s ease-out',
  [themeLocal.breakpoints.down('sm')]: {
    transform: `rotateY(0deg) translate(0%, 30%) rotate(0deg) scale(${scale})`,
  },
});

export const KeepSingleOnFrontBaseStylePreview = (scale: number) => ({
  transform: `rotateY(0deg) translate(0%, 70%) rotate(0deg) scale(${scale})`,
  [themeLocal.breakpoints.down('sm')]: {
    transform: `rotateY(0deg) translate(0%, 30%) rotate(0deg) scale(${scale})`,
  },
});

export const KeepSingleOnFrontBaseStyleEmail = (scale: number) => ({
  transform: `rotateY(0deg) translate(0%, 70%) rotate(0deg) scale(${scale})`,
  [themeLocal.breakpoints.down('sm')]: {
    transform: `rotateY(0deg) translate(0%, 30%) rotate(0deg) scale(${scale})`,
  },
});

export const SetToFront = (scale: number) => {
  return {
    opacity: 1,
    transform: `rotateY(0deg) translate(0%, 10%) scale(${scale || 1}) !important`,
    WebkitTransition: 'all 1s ease-out',
    zIndex: 150,
    [themeLocal.breakpoints.down('sm')]: {
      transform: `rotateY(0deg) translate(0%, 0%) scale(${scale || 1}) !important`,
    }
  };
};
