import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { showToast } from 'helpers/index.js';

import { ErrorCode, ErrorCodeDescription, ErrorMessage, PageContainer } from './NotFoundPage.styled';

export const NotFoundPage = () => {
  const [t] = useTranslation();
  const navLocation = useLocation();

  useEffect(() => {
    navLocation.search.split('?message=').length > 1 && showToast('error', decodeURI(navLocation.search.split('?message=')[1]));
  }, []);

  return (
    <PageContainer>
      <ErrorCode>404</ErrorCode>
      <ErrorCodeDescription>{t('notFound')}</ErrorCodeDescription>
      <ErrorMessage>{t('notFoundResource')}</ErrorMessage>
    </PageContainer>
  );
};