import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const DialogStyled = styled(Dialog)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
      },
    },
  }),
);

export const ModalHeaderContainer = styled(Box)(
  () => ({
    ...themePublic.modalHeaderContainer as CSSProperties,
    width: '30vw',
    maxWidth: '30vw',
  }),
);

export const ModalHeaderTitle = styled(Typography)(
  () => ({
    maxWidth: 'inherit',
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    ...themePublic.closeIcon as CSSProperties,
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
    justifyContent: 'space-around',
  }),
);

export const ConfirmButton = styled(Button)(
  () => ({
    ...themePublic.confirmButton as CSSProperties,
  }),
);

export const CancelButton = styled(Button)(
  () => ({
    ...themePublic.cancelButton as CSSProperties,
  }),
);
