import { gql } from '@apollo/client';

export const GET_FONTS = gql`
query GetFonts {
  fonts
  {
    name
    ttfUrl
  }
}
`;
