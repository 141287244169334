import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { InvitationRowElement, InvitationTableHead, Loader, TableWrapper } from 'components';
import { useAuth } from 'contexts';
import { INVITATION_STATUS, TABLE_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { MAX_NUMBER_OF_ITEMS_TO_GET, getUserSettings, handleCustomSortFilter, useFetching } from 'helpers';
import { useInvitationStore } from 'store';
import { InvitationProps, InvitationTemplateType, UseAuthProps } from 'types';

import { GET_INVITATIONS, GET_INVITATIONS_IDS, graphLazyQueryMiddleware } from 'services';
import {
  EmptyListTextStyle, ListContainer,
  TableBodyStyled, TableBulkOptionsCheckbox, TableBulkOptionsCheckboxSelected,
  TableBulkOptionsHeader,
  TableContainerStyled, TableStyled
} from './InvitationListTable.styled';

type InvitationTableProps = {
  allTypes: InvitationTemplateType[]
}

const InvitationListTableComponent = ({ allTypes } : InvitationTableProps) => {
  const { dbUser }: UseAuthProps = useAuth();
  const {
    params,
    searchText,
    selectedItems: selectedInvitations,
    setSelectedItems: setSelectedInvitations,
    setPageInfo,
    pageInfo
  } = useInvitationStore();

  const [ getInvitations, { loading: loadingInvitations, data, refetch }] = graphLazyQueryMiddleware(GET_INVITATIONS);
  const [getAllInvitations] = graphLazyQueryMiddleware(GET_INVITATIONS_IDS);

  const [ startFetching, setStartFetching ] = useState(false);
  const [ storedData, setStoredData ] = useState(null);
  const [ isSelectedAll, setIsSelectedAll ] = useState(false);
  const nestedPropertyForSorting = [ 'CreatedBy', 'LocationInfo.StartDate', 'EventLink.Name' ];
  const userSettings = getUserSettings(USER_SETTINGS_CONFIG.INVITATIONS_TABLE, dbUser.id);

  const fetchInvitations = (newSearchText = '') => {
    if (!startFetching) {
      return;
    }
    getInvitations({
      fetchPolicy: 'cache-and-network',
      variables: {
        order: nestedPropertyForSorting.includes(params.orderBy.toString()) ? null : { [params.orderBy]: params.order.toUpperCase(), 'id': params.order.toUpperCase() },
        customOrder: nestedPropertyForSorting.includes(params.orderBy.toString()) ? handleCustomSortFilter(params) : null,
        filter: {
          statuses: params.selectedStatus.map(selectedStatus => Object.keys(INVITATION_STATUS)[Object.values(INVITATION_STATUS).indexOf(selectedStatus as unknown as INVITATION_STATUS)]),
          textFilter: newSearchText,
          typeIds: getMappedTypes(),
          tags: params.selectedTags,
          activityStatus: params.selectedActivityStatuses.map((s: string) => s.toUpperCase())
        },
        skip: params.rowsPerPage * (params.page - 1),
        take: params.rowsPerPage
      }}).then(res => {
      setPageInfo({
        hasNextPage: res.data.invitations.pageInfo.hasNextPage,
        hasPreviousPage: res.data.invitations.pageInfo.hasPreviousPage,
        totalCount: res.data.invitations.totalCount
      });
    });
  };

  const handleSelectAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allRows: number[] = [];
      let hasNextPage = false;
      let iterator = 0;
      do {
        await getAllInvitations(
          { fetchPolicy: 'cache-and-network',
            variables: {
              order: nestedPropertyForSorting.includes(params.orderBy.toString()) ? null : { [params.orderBy]: params.order.toUpperCase() },
              customOrder: nestedPropertyForSorting.includes(params.orderBy.toString()) ? handleCustomSortFilter(params) : null,
              filter: {
                statuses: params.selectedStatus.map(selectedStatus => Object.keys(INVITATION_STATUS)[Object.values(INVITATION_STATUS).indexOf(selectedStatus as unknown as INVITATION_STATUS)]),
                textFilter: searchText,
                typeIds: getMappedTypes(),
                tags: params.selectedTags,
                activityStatus: params.selectedActivityStatuses.map((s: string) => s.toUpperCase())
              },
              skip: MAX_NUMBER_OF_ITEMS_TO_GET * iterator,
              take: MAX_NUMBER_OF_ITEMS_TO_GET
            }}).then((allInvitationsData) => {
          const allInvitations = allInvitationsData.data.invitations.items;
          hasNextPage = allInvitationsData.data.invitations.pageInfo.hasNextPage;
          iterator++;
          allInvitations.forEach((invitation: InvitationProps) => {
            allRows.push(invitation.id);
          });
        });
      } while (hasNextPage);
      setSelectedInvitations(allRows);
      setIsSelectedAll(true);
      return;
    }
    setSelectedInvitations([]);
    setIsSelectedAll(false);
  };

  useEffect(() => {
    data?.invitations?.items && setStoredData(data.invitations.items);
  }, [data]);

  const handleCheckboxSelect = (id: number) => {
    const newSelectedInvitation = [...selectedInvitations];
    if (isInvitationSelected(id)) {
      const customer = newSelectedInvitation.find(c => c === id);
      const indexOfSelected = newSelectedInvitation.indexOf(customer);
      newSelectedInvitation.splice(indexOfSelected, 1);
      setIsSelectedAll(false);
    } else {
      newSelectedInvitation.push(id);
      pageInfo.totalCount === selectedInvitations.length + 1 && setIsSelectedAll(true);
    }
    setSelectedInvitations(newSelectedInvitation);
  };

  const getMappedTypes = () => {
    const mappedTypes: number[] = [];
    params.selectedTypes.forEach(selectedType => {
      allTypes?.forEach((type) => {
        if (selectedType === type.name) {
          mappedTypes.push(type.id);
        }
      });
    });

    return mappedTypes;
  };

  const refetchInvitations = () => {
    refetch();
    setIsSelectedAll(false);
    setSelectedInvitations([]);
  };

  const isInvitationSelected = (id: number) => {
    return selectedInvitations.some((c: number) => c === id);
  };

  useFetching( fetchInvitations, refetchInvitations, loadingInvitations, setStartFetching, startFetching, userSettings, useInvitationStore, TABLE_TYPE.INVITATIONS_TABLE );

  const invitations: InvitationProps[] = (data?.invitations?.items) || storedData || [];

  return (
    <TableWrapper isLoading={loadingInvitations} totalCount={pageInfo.totalCount} theme={EmptyListTextStyle}>
      <>
        {isMobile ?
          <Box>
            {!loadingInvitations &&
            <TableBulkOptionsHeader>
              {isSelectedAll && selectedInvitations.length > 0 ?
                <TableBulkOptionsCheckboxSelected
                  checked={isSelectedAll && selectedInvitations.length > 0}
                  onChange={(e) => handleSelectAll(e)}/> :
                <TableBulkOptionsCheckbox
                  checked={isSelectedAll && selectedInvitations.length > 0}
                  onChange={(e) => handleSelectAll(e)}/> }
              <InvitationTableHead
                handleSelectAll={handleSelectAll}
                invitationsLength={invitations.length}
                isSelectedAll={isSelectedAll} />
            </TableBulkOptionsHeader>}
            <ListContainer>
              {invitations.map((invitation) => (
                <InvitationRowElement
                  key={invitation.id}
                  invitation={invitation}
                  invitations={invitations}
                  handleCheckboxSelect={handleCheckboxSelect}
                  isInvitationSelected={isInvitationSelected}/>
              ))}
            </ListContainer>
          </Box>
          :
          <TableContainerStyled>
            {!loadingInvitations &&
            <TableStyled sx={{ minWidth: '33.85vw', borderCollapse: 'separate', borderSpacing: '0 10px' }}>
              <InvitationTableHead
                handleSelectAll={handleSelectAll}
                invitationsLength={invitations.length}
                isSelectedAll={isSelectedAll} />
              <TableBodyStyled>
                {
                  invitations.map((invitation) => (
                    <InvitationRowElement
                      key={invitation.id}
                      invitation={invitation}
                      invitations={invitations}
                      handleCheckboxSelect={handleCheckboxSelect}
                      isInvitationSelected={isInvitationSelected} />
                  ))
                }
              </TableBodyStyled>
            </TableStyled>}
          </TableContainerStyled>}
        <Loader inProgress={loadingInvitations} />
      </>
    </TableWrapper>
  );
};

export const InvitationListTable = memo(InvitationListTableComponent);

InvitationListTable.displayName = 'InvitationListTable';
