import { Button, styled, Typography } from '@mui/material';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    width: '20%'
  }),
);

export const GuestNameText = styled(Typography)(
  ({theme}) => ({
    ...themeDefault.overflowHidden as CSSProperties,
    color: themeDefault.palette.mainColor,
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: '90%',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  }),
);