import { Box, Button, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

const BoxSpacngStyle = {
  marginBottom: '24px !important',
  marginTop: 0,
};

export const EndTimeLabel = {
  bottom: '-0.2rem',
  [themeLocal.breakpoints.down('sm')]: {
    margin: 'inherit',
    bottom: '-2.5rem',
  },
};

export const MarginBottomRemoveLabel = {
  bottom: '-3rem',
};

export const PageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    padding: '1rem',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: themeDefault.defaultBorderRadius,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '1rem',
    }
  }),
);

export const MobileTitle = styled(Typography)(
  () => ({
    color: themeDefault.palette.fieldLabelColor,
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '1.5rem 0',
    textAlign: 'center',
  }),
);

export const Title = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
  }),
);

export const DetailsContainer = styled(Box)(
  () => ({
    flexDirection: 'column',
    justifyContent: 'center',
  }),
);

export const RelativePositionContainer = styled(Box)(
  () => ({
    ...themePublic.relativePositionContainer as CSSProperties,
  }),
);

export const BoxSpacingContainer = styled(Box)(
  () => ({
    ...BoxSpacngStyle as CSSProperties,
  }),
);

export const DateTimeContainer = styled(Box)(
  () => ({
    ...themePublic.relativePositionContainer as CSSProperties,
    ...BoxSpacngStyle as CSSProperties,
  }),
);

export const DateContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    gap: '2rem',
    height: '5rem',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '100%',
  }),
);

export const MarginBottomContainer = styled(DateTimeContainer)(
  () => ({
    ...themePublic.relativePositionContainer as CSSProperties,
  }),
);

export const AddNewTextButton = styled('span')(
  () => ({
    ...themePublic.addNewTextButtonTypography as CSSProperties,
    bottom: '-20px',
    fontSize: '14px',
    margin: 0,
    position: 'absolute',
    right: '4px',
    textAlign: 'right',
    width: 'fit-content',
  }),
);

export const AddNewTextButtonFixedBottom = styled(AddNewTextButton)(
  () => ({
    bottom: '-4px',
  }),
);

export const ErrorMessage = styled(Typography)(
  () => ({
    ...themeDefault.errorMessage as CSSProperties,
  }),
);

export const InformationContainer = styled(Box)(
  ({ theme }) => ({
    width: '75%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }),
);

export const InputMessage = styled(Typography)(
  () => ({
    bottom: '-18px',
    color: themeDefault.palette.errorColor,
    fontSize: '12px',
    fontWeight: 600,
    position: 'absolute',
    right: '4px',
    textAlign: 'right',
  }),
);

export const DateInputContainer = styled(Box)(
  () => ({
    height: '4rem',
    width: '100%',
    '& fieldset': {
      borderColor: themeDefault.palette.greyColor,
      borderWidth: '1px !important'
    },
    '& input': {
      '& ::selection': {
        backgroundColor: themeDefault.palette.redColor,
      }
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.6) !important',
    },
    '& p': {
      color: themeDefault.palette.errorColor,
      fontSize: '12px',
      margin: 0,
    },
  }),
);

export const AutocompleteInputContainer = styled(Box)(
  () => ({
    maxWidth: '200vh',
    marginBottom: '24px',
    width: '100%',
    '& span': {
      ...themeDefault.overflowText,
    },
    '& fieldset': {
      borderColor: `${themeDefault.palette.greyColor} !important`,
      borderWidth: '1px !important',
    },
  }),
);

export const PageButtonsContainer = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      ...themeDefault.stickyBottom,
      backgroundColor: themeDefault.palette.whiteColor,
      marginTop: 0,
    },
  }),
);

export const PageNextButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: 'auto',
      width: '50%',
    },
  }),
);

export const PagePrevButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '50%',
    },
  }),
);
