import React from 'react';
import {
  DialogContent
} from '@mui/material';
import { isMobile } from 'react-device-detect';

import { CustomTooltip } from 'components';

import { CloseIconStyled, ContentContainer, ImageStyled, ModalContainer, ModalHeader, TitleStyle } from './DisplayImageModal.styled';

type DisplayImageModalProps = {
  imageUrl: string;
  title: string;
  isOpen: boolean;
  handleClose: () => void
};

export const DisplayImageModal = ({ imageUrl, title, isOpen, handleClose } : DisplayImageModalProps) => {
  return (
    <ModalContainer maxWidth={isMobile ? 'xs' : 'md'} open={isOpen} onClose={handleClose}>
      <DialogContent>
        <ModalHeader>
          <div></div>
          <CloseIconStyled onClick={handleClose}/>
        </ModalHeader>
        <ContentContainer>
          <CustomTooltip theme={TitleStyle} text={title} />
          <ImageStyled src={imageUrl} />
        </ContentContainer>
      </DialogContent>
    </ModalContainer>
  );
};