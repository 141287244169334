import { Box, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  maxWidth: '30vw',
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '100%',
    lineHeight: 1.8,
  },
};

export const InputRow = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    position: 'relative',
    width: '100%',
    '& .MuiSelect-select': {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })
);

export const InputRowVertical = styled(Box)(
  ({ theme }) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1rem',
    position: 'relative',
    width: '100%',
    '& div': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })
);

export const PersonalMessageLabelContainer = styled(Box)(
  () => ({
    ...themeDefault.flexCenter,
    justifyContent: 'flex-start',
    margin: '0 1rem 0 0',
  })
);

export const IconContainer = styled(Box)(
  () => ({
    height: '1.5rem',
    width: '2rem !important',
  })
);

export const EditIcon = styled('img')(
  () => ({
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.7)',
  })
);

export const ErrorMessage = styled(Typography)(
  () => ({
    ...themeDefault.errorMessage as CSSProperties,
  })
);

export const LabelSelected = styled(Typography)(
  () => ({
    whiteSpace: 'nowrap',
    width: '25%',
  })
);

export const LabelDisabled = styled(LabelSelected)(
  () => ({
    opacity: 0.6,
    pointerEvents: 'none',
  })
);
