import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const TitleStyle = {
  ...themeDefault.overflowText,
  fontWeight: 'bold',
  maxWidth: '40vh',
};

export const ModalContainer = styled(Dialog)(
  ({ theme }) => ({
    alignItems: 'center',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        width: '70%',
      },
    },
  }),
);

export const ModalHeader = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer',
  }),
);

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',
    minWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }),
);

export const ImageStyled = styled('img')(
  ({ theme }) => ({
    alignItems: 'center',
    boxShadow: `10px 10px 2em ${themeDefault.palette.darkGreyColor}`,
    display: 'flex',
    justifyContent: 'center',
    transform: 'scale(0.9)',
    transition: 'transform 300ms',
    width: '-webkit-fill-available',
    [theme.breakpoints.down('sm')]: {
      transform: 0,
      transition: 0,
    },
  }),
);
