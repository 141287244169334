import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const DialogStyled = styled(Dialog)(
  ({ theme }) => ({
    textAlign: 'center',
    '& .MuiPaper-root': {
      minWidth: '40vw',
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        minWidth: '80%',
      },
    },
  }),
);

export const ContentContainer = styled(Box)(
  () => ({
    padding: '2rem 1rem',
  }),
);

export const Title = styled(Typography)(
  () => ({
    marginBottom: '0.5rem',
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    padding: '8px 48px',
    marginTop: '0.5rem',
    width: '20%',
  }),
);
