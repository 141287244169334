import React, { ElementType, memo, useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  Menu,
  TableCell,
  TableCellBaseProps,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal, EditTagsModal, GroupContactItem, SingleContactItem, TableRowContact, TableRowGroup, TableWrapper } from 'components';
import { useAuth } from 'contexts';
import { ORDER_TYPE, TABLE_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { MAX_NUMBER_OF_ITEMS_TO_GET, contactsColumns, getUserSettings, handleOrder, useFetching } from 'helpers';
import { BULK_DELETE_CONTACTS, GET_CONTACTS, GET_CONTACTS_IDS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { useContactStore } from 'store';
import { themeDefault } from 'themeDefault';
import { ContactType, TableColumnProps, UseAuthProps } from 'types';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocalOffer as LocalOfferIcon,
  MoreHoriz as MoreHorizIcon
} from '@mui/icons-material';
import {
  BulkTableHead, BulkTableHeadRow, EmptyListTextStyle, FirstTableCellHeader, FirstTableCellHeaderSelected,
  HeaderBulkOptionTableCell, ImageTableCell, LastTableCell,
  ListContainer,
  RemovedBorderTableHead, TableBodyStyled, TableBulkOptionsCheckbox, TableBulkOptionsCheckboxSelected, TableBulkOptionsColoredText, TableBulkOptionsHeader, TableBulkOptionsMenu, TableBulkOptionsMenuItem, TableBulkOptionsText,
  TableContainerStyled, TableContentContainerMobile, TableStyled, TitleHeaderTableCell
} from './ContactsTable.styled';

type ContactsTableProps = {
  importingProcess: boolean,
}

const ContactsTableComponent = ({ importingProcess = false}: ContactsTableProps) => {
  const { dbUser, saveUserSettings }: UseAuthProps = useAuth();
  const [t] = useTranslation();

  const [ getContacts, { loading: loadingContacts, data, refetch: contactsRefetch }] = graphLazyQueryMiddleware(GET_CONTACTS);
  const [ bulkDeleteContacts, { loading: deleteInProgress }] = graphMutationMiddleware(BULK_DELETE_CONTACTS);
  const [getAllContacts] = graphLazyQueryMiddleware(GET_CONTACTS_IDS);
  const userSettings = getUserSettings(importingProcess ? USER_SETTINGS_CONFIG.IMPORT_EXISTING_CONTACTS_SEND_PAGE : USER_SETTINGS_CONFIG.CONTACTS_TABLE, dbUser.id);

  const {
    params,
    setParams,
    selectedItems: selectedContacts,
    setSelectedItems: setSelectedContacts,
    changeExecuteRefetch,
    searchText,
    setPageInfo,
    pageInfo,
    changeExecuteTagsRefetch,
  } = useContactStore();

  const [ isEditTagsModal, setIsEditTagsModal ] = useState(false);
  const [ startFetching, setStartFetching ] = useState(false);
  const [ isSelectedAll, setIsSelectedAll ] = useState(false);
  const [ storedData, setStoredData ] = useState(null);
  const [ isDeleteModal, setIsDeleteModal ] = useState(false);
  const [ bulkMenuElement, setBulkMenuElement ] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(bulkMenuElement);

  useEffect(() => {
    if (!data) {
      return;
    }
    data.contacts?.items && setStoredData(data.contacts.items);
    setPageInfo({
      totalCount: data.contacts.totalCount,
      hasNextPage: data.contacts.pageInfo.hasNextPage,
      hasPreviousPage: data.contacts.pageInfo.hasPreviousPage,
    });
  }, [data]);

  const fetchContacts = (newSearchText = '') => {
    if (!startFetching) {
      return;
    }

    getContacts({
      fetchPolicy: 'cache-and-network',
      variables: {
        order: {
          [params.orderBy]: params.order.toUpperCase(),
          'id': params.order.toUpperCase()
        },
        filter: {
          textFilter: newSearchText,
          tags: params.selectedTags
        },
        skip: params.rowsPerPage * (params.page - 1),
        take: params.rowsPerPage
      }});
    changeExecuteTagsRefetch();
  };

  const handleSelectAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allRows: number[] = [];
      let hasNextPage = false;
      let iterator = 0;
      do {
        await getAllContacts(
          { fetchPolicy: 'cache-and-network',
            variables: {
              order: {
                [params.orderBy]: params.order.toUpperCase(),
                'id': params.order.toUpperCase()
              },
              filter: {
                textFilter: searchText,
                tags: params.selectedTags
              },
              skip: MAX_NUMBER_OF_ITEMS_TO_GET * iterator,
              take: MAX_NUMBER_OF_ITEMS_TO_GET,
            }}).then(allContactsData => {
          const allContacts = allContactsData.data.contacts.items;
          hasNextPage = allContactsData.data.contacts.pageInfo.hasNextPage;
          iterator++;
          allContacts.forEach((contact: ContactType) => {
            allRows.push(contact.id);
          });
        });
      } while (hasNextPage);
      setSelectedContacts(allRows);
      setIsSelectedAll(true);
      return;
    }
    setSelectedContacts([]);
    setIsSelectedAll(false);
  };

  const handleSelectedContacts = (id: number) => {
    if (selectedContacts.includes(id)) {
      setSelectedContacts(selectedContacts.filter(item => item !== id));
      setIsSelectedAll(false);
    } else {
      setSelectedContacts([ ...selectedContacts, id ]);
      pageInfo.totalCount === selectedContacts.length && setIsSelectedAll(true);
    }
  };

  const bulkDelete = () => {
    bulkDeleteContacts({variables: {
      contactIds: selectedContacts
    }}).then(() => {
      setSelectedContacts([]);
      changeExecuteRefetch();
    });
    setIsDeleteModal(false);
    setBulkMenuElement(null);
  };

  const refetchContacts = () => {
    contactsRefetch();
    changeExecuteTagsRefetch();
    setSelectedContacts([]);
    setIsSelectedAll(false);
  };

  const isContactSelected = (id: number) => {
    return selectedContacts.some(c => c === id);
  };

  const handleSorting = (orderBy: string, order: ORDER_TYPE) => {
    setParams({
      ...params,
      order: order,
      orderBy: orderBy
    });
    const tableType = importingProcess ? USER_SETTINGS_CONFIG.IMPORT_EXISTING_CONTACTS_SEND_PAGE : USER_SETTINGS_CONFIG.CONTACTS_TABLE;
    saveUserSettings(tableType, orderBy, order);
  };

  useFetching( fetchContacts, refetchContacts, loadingContacts, setStartFetching, startFetching, userSettings, useContactStore, TABLE_TYPE.CONTACTS_TABLE );

  const contacts: ContactType[] = data?.contacts?.items || storedData || [];

  return (
    <TableWrapper isLoading={loadingContacts} totalCount={pageInfo.totalCount} theme={EmptyListTextStyle}>
      <>
        {
          isMobile ?
            <TableContentContainerMobile>
              {!loadingContacts &&
              <>
                <TableBulkOptionsHeader>
                  {isSelectedAll && selectedContacts.length > 0 ?
                    <TableBulkOptionsCheckboxSelected
                      checked={isSelectedAll && selectedContacts.length > 0 }
                      onChange={handleSelectAll}/> :
                    <TableBulkOptionsCheckbox
                      checked={isSelectedAll && selectedContacts.length > 0 }
                      onChange={handleSelectAll}/>}
                  {
                    selectedContacts.length > 0 ?
                      <>
                        <TableBulkOptionsText> {`${selectedContacts.length} ${t('selected')}`}</TableBulkOptionsText>
                        <TableBulkOptionsMenu onClick={(e) => setBulkMenuElement(e.currentTarget)}>
                          <MoreHorizIcon />
                        </TableBulkOptionsMenu>
                        <Menu anchorEl={bulkMenuElement} open={openMenu} onClose={() => setBulkMenuElement(null)}>
                          <TableBulkOptionsMenuItem onClick={() => setIsEditTagsModal(true)}><LocalOfferIcon/> {t('editTags')}</TableBulkOptionsMenuItem>
                          <Divider />
                          <TableBulkOptionsMenuItem onClick={() => setIsDeleteModal(true)}><DeleteIcon/> {t('delete')}</TableBulkOptionsMenuItem>
                        </Menu>
                      </>:
                      <Typography >{t('selectAll')}</Typography>
                  }
                </TableBulkOptionsHeader>
                <ListContainer>
                  {
                    contacts.map((contact) => (
                      contact.contacts.length === 0 ?
                        <SingleContactItem
                          key={contact.id}
                          importingProcess={importingProcess}
                          contact={contact}
                          handleSelectedContacts={handleSelectedContacts}
                          selectedContacts={selectedContacts}/> :
                        <GroupContactItem
                          key={contact.id}
                          handleSelectedContacts={handleSelectedContacts}
                          importingProcess={importingProcess}
                          contact={contact}
                          selectedContacts={selectedContacts}/>
                    ))}
                </ListContainer>
              </>}
            </TableContentContainerMobile>
            :
            <>
              <TableContainerStyled>
                {!loadingContacts &&
                  <TableStyled sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0', paddingRight: importingProcess ? '0.5rem' : '0' }}>
                    {
                      !importingProcess && selectedContacts.length > 0 ?
                        <BulkTableHead>
                          <BulkTableHeadRow>
                            <TableCell component={(isSelectedAll && selectedContacts.length > 0 ? FirstTableCellHeaderSelected : FirstTableCellHeader) as ElementType<TableCellBaseProps>}>
                              <Checkbox onChange={handleSelectAll} checked={isSelectedAll && selectedContacts.length > 0} />
                            </TableCell>
                            <ImageTableCell>
                              <TableBulkOptionsText> {`${selectedContacts.length} ${t('selected')}`} </TableBulkOptionsText>
                            </ImageTableCell>
                            <HeaderBulkOptionTableCell>
                              <TableBulkOptionsText onClick={() => setIsEditTagsModal(true)}><EditIcon/>{t('editTags')}</TableBulkOptionsText>
                            </HeaderBulkOptionTableCell>
                            <HeaderBulkOptionTableCell>
                              <TableBulkOptionsText onClick={() => setIsDeleteModal(true)}><DeleteIcon/>{t('delete')}</TableBulkOptionsText>
                            </HeaderBulkOptionTableCell>
                            <HeaderBulkOptionTableCell/>
                            { !importingProcess && <HeaderBulkOptionTableCell/> }
                            <LastTableCell />
                          </BulkTableHeadRow>
                        </BulkTableHead> :
                        <RemovedBorderTableHead>
                          <BulkTableHeadRow>
                            <TableCell component={(isSelectedAll && selectedContacts.length > 0 ? FirstTableCellHeaderSelected : FirstTableCellHeader) as ElementType<TableCellBaseProps>}>
                              <Checkbox onChange={handleSelectAll} checked={isSelectedAll && selectedContacts.length > 0} />
                            </TableCell>
                            {contactsColumns.map((column: TableColumnProps) => (
                              importingProcess && column.id === 0 && selectedContacts.length > 0 ?
                                <ImageTableCell key={column.id}>
                                  <TableBulkOptionsColoredText color={themeDefault.palette.blackColor}> {`${selectedContacts.length} ${t('selected')}`} </TableBulkOptionsColoredText>
                                </ImageTableCell>
                                :
                                <TableCell key={column.id} component={(column.id === 0 ? ImageTableCell : TitleHeaderTableCell) as ElementType<TableCellBaseProps>}>
                                  {t(column.title)}
                                  {column.enableSorting &&
                              <TableSortLabel
                                active={params.orderBy === column.sortableName}
                                direction={params.order}
                                onClick={() => handleSorting(column.sortableName, handleOrder(params.order))}>
                              </TableSortLabel>
                                  }
                                </TableCell>
                            ))}
                          </BulkTableHeadRow>
                        </RemovedBorderTableHead>
                    }
                    <TableBodyStyled>
                      <TableRow>
                        <TableCell></TableCell>
                      </TableRow>
                      {contacts.map((row) => row.contacts && row.contacts.length > 0 ?
                        <TableRowGroup
                          key={row.id}
                          importingProcess={importingProcess}
                          row={row}
                          handleCheckboxSelect={handleSelectedContacts}
                          isContactSelected={isContactSelected} /> :
                        <TableRowContact
                          key={row.id}
                          importingProcess={importingProcess}
                          row={row}
                          handleCheckboxSelect={handleSelectedContacts}
                          isContactSelected={isContactSelected} />
                      )}
                    </TableBodyStyled>
                  </TableStyled>}
              </TableContainerStyled>
            </>
        }
        <ConfirmationModal
          loading={deleteInProgress}
          isOpen={isDeleteModal}
          handleClose={() => setIsDeleteModal(false)}
          handleConfirm={bulkDelete}
          confimMessage={t('deletionsConfirmMessage')}/>
        {isEditTagsModal &&
          <EditTagsModal
            isOpen={isEditTagsModal}
            closeEditTags={() => setIsEditTagsModal(false)}
            selectedIds={selectedContacts}
            forInvitations={false}/>}
      </>
    </TableWrapper>

  );
};

export const ContactsTable = memo(ContactsTableComponent);

ContactsTable.displayName = 'ContactsTable';
