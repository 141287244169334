import React from 'react';
import { Box } from '@mui/material';

import { DisabledListItem } from './ListItemHeaderComponent.styled';

interface CreateInvitationHeaderProps {
  icon: any,
  text: string,
  handleOnClick: any
  isDisabled?: boolean
}

export const ListItemHeaderComponent = ({ icon, text, handleOnClick, isDisabled = false } : CreateInvitationHeaderProps) => {
  return (
    <Box component={isDisabled && DisabledListItem} onClick={() => !isDisabled && handleOnClick()}>
      {icon}
      <p>{text}</p>
    </Box>
  );
};
