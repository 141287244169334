import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Loader, MobilePublicContactEntryLinksTable, PublicContactEntryLinksTable } from 'components';
import {
  CREATE_PUBLIC_CONTACT_ENTRY_AUTHORISATION,
  GET_PUBLIC_CONTACT_ENTRY_AUTHORISATIONS,
  GET_TAGS,
  REMOVE_PUBLIC_CONTACT_ENTRY_AUTHORISATION,
  UPDATE_PUBLIC_CONTACT_ENTRY_AUTHORISATION,
  graphMutationMiddleware,
  graphQueryMiddleware,
} from 'services';
import { PublicContactEntryAuthorisationType } from 'types';

import { ButtonContainer, CloseIconStyled, EmptyListText, ModalHeader, ModalHeading, PageContent } from './PublicContactEntryLinksModal.styled';

type PublicContactEntryLinksModalProps = {
  handleClose?: () => void;
  filter?: {
    scope: 'ALL_CONTACTS';
  };
};

export const PublicContactEntryLinksModal = ({
  handleClose,
  filter = { scope: 'ALL_CONTACTS' },
}: PublicContactEntryLinksModalProps) => {
  const [t] = useTranslation();

  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ inlineEdit, setInlineEdit ] = useState<Parameters<typeof PublicContactEntryLinksTable>[0]['inlineEdit']>({
    active: false,
    id: undefined,
  });

  const onStartInlineEdit = (id?: number) => setInlineEdit((s) => ({ ...s, active: true, id }));
  const onCanceInlineEdit = () => setInlineEdit((s) => ({ ...s, active: false }));

  const {
    data: tagData,
    loading: isTagsLookupLoading,
    refetch: refetchTagsLookup,
  } = graphQueryMiddleware(GET_TAGS, { filter: { type: 'CONTACT' } });

  const {
    data,
    loading: isListLoading,
    refetch: refetchList,
  } = graphQueryMiddleware(GET_PUBLIC_CONTACT_ENTRY_AUTHORISATIONS, { filter });
  const authorisations = data?.publicContactEntryAuthorisations;

  const [createRecord] = graphMutationMiddleware(CREATE_PUBLIC_CONTACT_ENTRY_AUTHORISATION);
  const [updateRecord] = graphMutationMiddleware(UPDATE_PUBLIC_CONTACT_ENTRY_AUTHORISATION);
  const [removeRecord] = graphMutationMiddleware(REMOVE_PUBLIC_CONTACT_ENTRY_AUTHORISATION);

  const onSaveItem = async (item: Partial<PublicContactEntryAuthorisationType>) => {
    const { id, activityStatus, name: moniker, expirationDate, tags } = item;
    await (id ? updateRecord : createRecord)({
      variables: {
        input: {
          id,
          scope: id ? undefined : filter?.scope,
          activityStatus,
          name: moniker,
          expirationDate,
          tags: tags?.map((x) => x.name),
        }
      },
    });

    onCanceInlineEdit();

    // Note: no 'await' because we want Save operation to finish, while item list
    // is re-fetched independently
    refetchList();
    refetchTagsLookup();
  };

  const onDeleteItem = async (id: number) => {
    await removeRecord({ variables: { id } });
    refetchList();
  };

  return (
    <PageContent>
      <Loader loadingPage={false} inProgress={isListLoading || isTagsLookupLoading} />
      <ModalHeader>
        {isMobile ?
          <ModalHeading>{t('sharedLinksModalHeader')}</ModalHeading>:
          <Typography variant='h5' sx={{marginTop: '1rem'}}>{t('sharedLinksModalHeader')}</Typography>}
        <CloseIconStyled onClick={() => handleClose?.()}/>
      </ModalHeader>
      <Box sx={{width: '100%'}}>
        <ButtonContainer>
          <Button variant='contained' disabled={inlineEdit.active} onClick={() => onStartInlineEdit()}>
            {t('sharedLinksCreateNew')}
          </Button>
        </ButtonContainer>
        {!isListLoading && !authorisations?.length && !inlineEdit.active &&
          <EmptyListText>{t('sharedLinksNoneExist')}</EmptyListText>
        }
        { (authorisations?.length > 0 || inlineEdit.active) &&
          (isMobile ?
            <MobilePublicContactEntryLinksTable
              items={authorisations}
              lookupTags={tagData?.tags}
              inlineEdit={inlineEdit}
              onStartEdit={onStartInlineEdit}
              onCancelEdit={onCanceInlineEdit}
              onSaveItem={onSaveItem}
              onDeleteItem={onDeleteItem} /> :
            <PublicContactEntryLinksTable
              items={authorisations}
              lookupTags={tagData?.tags}
              inlineEdit={inlineEdit}
              onStartEdit={onStartInlineEdit}
              onCancelEdit={onCanceInlineEdit}
              onSaveItem={onSaveItem}
              onDeleteItem={onDeleteItem}
              showKeyboard={showKeyboard}
              setShowKeyboard={setShowKeyboard} />
          )
        }
      </Box>
    </PageContent>
  );
};
