import { Box, IconButton, Slider, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const EditorNameLabel = styled(Typography)(
  () => ({
    ...themePublic.editorNameLabelDesignPage as CSSProperties,
  }),
);

export const InputContainer = styled(Box)(
  () => ({
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root': {
      paddingRight: '8px',
    }
  }),
);

export const TextFieldStyled = styled(TextField)(
  () => ({
    display: 'inline-block',
    fontSize: '12px',
    height: '25px',
    marginBottom: '5px',
    marginTop: '5px',
    padding: 0,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '70px',
    '& .MuiInputBase-root': {
      height: '25px',
      fontSize: '12px',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '10px'
    },
    '& .MuiTypography-root': {
      lineHeight: 0,
    },
  }),
);

export const ArrowButtonsContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    margin: 0,
    marginBottom: '5px',
    marginTop: '5px',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'fit-content',
    '& :hover': {
      backgroundColor: 'transparent !important',
    },
  }),
);

export const UpArrowButton = styled(IconButton)(
  () => ({
    float: 'left',
    margin: 0,
    padding: '4px',
  }),
);

export const DownArrowButton = styled(UpArrowButton)(
  () => ({
    clear: 'left',
  }),
);

export const SliderInput = styled(Slider)(
  () => ({
    display: 'inline-block',
    fontSize: '12px',
    height: '25px',
    margin: '5px 0 5px 15px',
    padding: 0,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100px',
    '& .MuiSlider-rail': {
      backgroundColor: themeDefault.palette.mainColor,
      height: '3px',
      opacity: '1'
    },
    '& .MuiSlider-track': {
      backgroundColor: themeDefault.palette.mainColor,
      height: '3px',
    },
    '& .MuiSlider-thumb': {
      backgroundColor: themeDefault.palette.whiteColor,
      border: `solid 1px ${themeDefault.palette.mainColor}`
    },
    '& .MuiSlider-valueLabelOpen': {
      height: '10px',
      width: '10px',
    }
  }),
);
