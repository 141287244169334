import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';

import { InvitationCreatePageHeader, VirtualKeyboard } from 'components';
import { InvitationProvider, useAuth, useInvitation } from 'contexts';
import { FORM_TYPE, VALIDATION_RESTRICTION } from 'enums';
import { getIdFromRoute, isCurrentUsersInvitation, showToast } from 'helpers';
import {
  InvitationDesignPage,
  InvitationDetailsPage,
  InvitationEnvelopePage,
  InvitationPage,
  InvitationPreviewPage,
  InvitationSendPage,
  InvitationTemplateDetailsPage,
  InvitationTrackPage
} from 'pages';
import {
  GET_INVITATION_TEMPLATE_BY_ID,
  UPDATE_INVITATION_NAME,
  UPDATE_INVITATION_TEMPLATE,
  UPDATE_INVITATION_TEMPLATE_NAME,
  graphLazyQueryMiddleware,
  graphMutationMiddleware
} from 'services';
import { InvitationContextType, TagType, UseAuthProps } from 'types';

import { DoneIcon, EditIcon, EditIconContainer, EventNameConatiner, EventNameTextField, IconButtonStyled, PageBody } from './InvitationCreatePage.styled';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

type InvitationCreatePageProps = {
  isPageForInvitationTemplates?: boolean
}

export const InvitationCreatePage = ({ isPageForInvitationTemplates = false } : InvitationCreatePageProps) => {
  const [t] = useTranslation();
  const params = useParams();
  const { invitationDetails, setInvitationDetails }: InvitationContextType = useInvitation();
  const { dbUser }: UseAuthProps = useAuth();

  const isCreatedByCurrentUser = isCurrentUsersInvitation(dbUser?.id, invitationDetails?.createdBy?.id);

  const [ getInvitationTemplate, { data: invitationTemplateData, refetch: refetchInvitationTemplate, loading: loadingInvitationTemplateData }] = graphLazyQueryMiddleware(GET_INVITATION_TEMPLATE_BY_ID);
  const [updateInvitationName] = graphMutationMiddleware(isPageForInvitationTemplates ? UPDATE_INVITATION_TEMPLATE_NAME : UPDATE_INVITATION_NAME);
  const [updateInvitationTemplate] = graphMutationMiddleware(UPDATE_INVITATION_TEMPLATE);

  const [ invitationTemplate, setInvitationTemplate ] = useState(null);
  const [ showFilteredinvitationTemplates, setShowFilteredinvitationTemplates ] = useState(false);
  const [ isEventNameEditable, setIsEventNameEditable ] = useState(false);
  const [ eventName, setEventName ] = useState<string>(t('defaultTemplateName'));
  const [ invitationTemplateStatus, setInvitationTemplateStatus ] = useState<string>(isPageForInvitationTemplates && invitationDetails?.status);
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  const keyboardRef = useRef(null);
  const eventNameRef = useRef(null);
  const editEventNameRef = useRef(null);
  const invitationId = getIdFromRoute(params['*']) || null;

  const changeEventName = () => {
    setIsEventNameEditable(false);
    updateInvitationName({variables: {
      input: {
        id: Number(invitationId),
        name: eventNameRef.current,
      }}
    }).then(() => {
      if (isPageForInvitationTemplates) {
        refetchInvitationTemplate();
      } else {
        setInvitationDetails({
          ...invitationDetails,
          name: eventNameRef.current,
        });
      }
    });

  };

  useEffect(() => {
    if (invitationDetails) {
      setEventName(invitationDetails.name);
    }
  }, [invitationDetails]);

  useEffect(() => {
    if (eventName) {
      eventNameRef.current = eventName;
    }
  }, [eventName]);

  useEffect((() => {
    if (!invitationTemplateData?.invitationTemplate) {
      return;
    }
    setInvitationTemplate(invitationTemplateData.invitationTemplate);
    setEventName(invitationTemplateData.invitationTemplate.name);
  }), [invitationTemplateData]);

  useEffect((() => {
    if (params && invitationId && isPageForInvitationTemplates) {
      getInvitationTemplate({
        variables: {
          id: Number(invitationId)
        }
      }).then(res => res && setInvitationTemplate(res.data.invitationTemplate)
      ).catch(() => {
        setInvitationTemplate(null);
      });
    }
    if (localStorage.getItem('activeId') && localStorage.getItem('activeId') !== getIdFromRoute(params['*'])) {
      localStorage.setItem('activeId', getIdFromRoute(params['*']));
    }
  }), [ params, invitationId, isPageForInvitationTemplates ]);

  const returnNameToDefaultValue = () => {
    const nameValue = isPageForInvitationTemplates ? invitationTemplate.name : invitationDetails.name;
    eventNameRef.current = nameValue;
    setEventName(nameValue);
    setIsEventNameEditable(false);
  };

  const handleIconClick = () => {
    if (eventName.length < VALIDATION_RESTRICTION.ONE) {
      returnNameToDefaultValue();
      showToast('error', t('cannotBeEmpty', { propertyName: t(isPageForInvitationTemplates ? 'invitationTemplateName' : 'eventName') }));
      return;
    }
    if (eventName.length > VALIDATION_RESTRICTION.HUNDRED) {
      returnNameToDefaultValue();
      showToast('error', t('stringPropertyMaxValidation', { propertyName: t(isPageForInvitationTemplates ? 'invitationTemplateName' : 'eventName'), length: VALIDATION_RESTRICTION.HUNDRED }));
      return;
    }
    setIsEventNameEditable(!isEventNameEditable);
    isEventNameEditable && setShowKeyboard(false);
    if (isEventNameEditable && editEventNameRef.current) {
      editEventNameRef.current.focus();
    }
    if (isEventNameEditable && eventNameRef.current !== invitationDetails?.name && eventNameRef.current !== invitationTemplate?.name ) {
      changeEventName();
    }
  };

  const saveInvitationTemplate = () => {
    updateInvitationTemplate({variables: {
      input: {
        id: invitationTemplate.id,
        name: invitationTemplate.name,
        description: invitationTemplate.description,
        colors: invitationTemplate.colors,
        typeId: invitationTemplate.typeId,
        tags: invitationTemplate.tags.map((tag: TagType) => tag.name),
        status: invitationTemplateStatus ?? invitationTemplate.status
      }}
    }).then(res => {
      refetchInvitationTemplate({id: res.data.updateInvitationTemplate.id});
    });
  };

  const createInvitationRoutes =
    <SentryRoutes>
      <Route path='/invitation' element={<InvitationPage
        showFilteredinvitationTemplates={showFilteredinvitationTemplates}
        setShowFilteredinvitationTemplates={setShowFilteredinvitationTemplates} />} />
      <Route path='/invitation/:id' element={<InvitationPage
        showFilteredinvitationTemplates={showFilteredinvitationTemplates}
        setShowFilteredinvitationTemplates={setShowFilteredinvitationTemplates} />} />
      <Route path='/details' element={<InvitationDetailsPage />} />
      <Route path='/details/:id' element={<InvitationDetailsPage />} />
      <Route path='/design' element={<InvitationDesignPage />} />
      <Route path='/design/:id' element={<InvitationDesignPage />} />
      <Route path='/envelope' element={<InvitationEnvelopePage />} />
      <Route path='/envelope/:id' element={<InvitationEnvelopePage/>} />
      <Route path='/preview' element={<InvitationPreviewPage />} />
      <Route path='/preview/:id' element={<InvitationPreviewPage/>} />
      { isCreatedByCurrentUser && <Route path='/send/:id' element={<InvitationSendPage />} />}
      <Route path='/track' element={<InvitationTrackPage />} />
      { isCreatedByCurrentUser && <Route path='/track/:id' element={<InvitationTrackPage/>} />}
    </SentryRoutes>;

  const createInvitationTemplateRoutes =
    <SentryRoutes>
      <Route path='/details'
        element={
          <InvitationTemplateDetailsPage
            loadingInvitationTemplateData={loadingInvitationTemplateData}
            invitationTemplate={invitationTemplate}
            invitationTemplateStatus={invitationTemplateStatus}
            setInvitationTemplateStatus={setInvitationTemplateStatus}
            refetch={refetchInvitationTemplate}
            type={FORM_TYPE.CREATE} />}/>
      <Route path='/details/:id'
        element={
          <InvitationTemplateDetailsPage
            loadingInvitationTemplateData={loadingInvitationTemplateData}
            invitationTemplate={invitationTemplate}
            invitationTemplateStatus={invitationTemplateStatus}
            setInvitationTemplateStatus={setInvitationTemplateStatus}
            refetch={refetchInvitationTemplate}
            type={FORM_TYPE.EDIT} />} />
      <Route path='/design' element={<InvitationDesignPage invitationTemplateStatus={invitationTemplateStatus} setInvitationTemplateStatus={setInvitationTemplateStatus} saveInvitationTemplate={saveInvitationTemplate}/>} />
      <Route path='/design/:id' element={<InvitationDesignPage isTemplate={true} invitationTemplateStatus={invitationTemplateStatus} setInvitationTemplateStatus={setInvitationTemplateStatus} saveInvitationTemplate={saveInvitationTemplate}/>} />
      <Route path='/envelope' element={<InvitationEnvelopePage isTemplate={true} invitationTemplateStatus={invitationTemplateStatus} setInvitationTemplateStatus={setInvitationTemplateStatus} saveInvitationTemplate={saveInvitationTemplate} />} />
      <Route path='/envelope/:id' element={<InvitationEnvelopePage isTemplate={true} invitationTemplateStatus={invitationTemplateStatus} setInvitationTemplateStatus={setInvitationTemplateStatus} saveInvitationTemplate={saveInvitationTemplate} />} />
      <Route path='/preview' element={<InvitationPreviewPage isTemplate={true} invitationTemplateStatus={invitationTemplateStatus} setInvitationTemplateStatus={setInvitationTemplateStatus} saveInvitationTemplate={saveInvitationTemplate} />} />
      <Route path='/preview/:id' element={<InvitationPreviewPage isTemplate={true} invitationTemplateStatus={invitationTemplateStatus} setInvitationTemplateStatus={setInvitationTemplateStatus} saveInvitationTemplate={saveInvitationTemplate} />} />
      <Route path='/track' element={<InvitationTrackPage />} />
      <Route path='/track/:id' element={<InvitationTrackPage />} />
    </SentryRoutes>;

  return (
    <InvitationProvider>
      <PageBody>
        {
          ((!isPageForInvitationTemplates && invitationDetails) || (isPageForInvitationTemplates && invitationTemplate)) &&
            <EventNameConatiner>
              <EditIconContainer>
                <IconButton
                  component={eventName.length < VALIDATION_RESTRICTION.ONE || eventName.length > VALIDATION_RESTRICTION.HUNDRED ? IconButtonStyled : IconButton}
                  disabled={eventName.length < VALIDATION_RESTRICTION.ONE || eventName.length > VALIDATION_RESTRICTION.HUNDRED}
                  onClick={handleIconClick}
                  color='inherit'>
                  {isEventNameEditable ? <DoneIcon />: <EditIcon/>}
                </IconButton>
              </EditIconContainer>
              <EventNameTextField
                variant='outlined'
                onFocus={() => setShowKeyboard(true)}
                value={eventName}
                disabled={!isEventNameEditable}
                inputRef={input => isEventNameEditable && input?.focus()}
                size='small'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventName(e.target.value)}
                onBlur={(localStorage.getItem('isHebrew') === 'true') ? null : handleIconClick}/>
              { showKeyboard &&
              <VirtualKeyboard
                setShowKeyboard={setShowKeyboard}
                closeWhenOutOfFocus={showKeyboard}
                saveEventName={handleIconClick}
                initialValues={{
                  'eventName': eventName
                }}
                setFieldValue={null}
                setStandaloneItem={setEventName}
                keyboardRef={keyboardRef}
                inputName={'eventName'}/>
              }
            </EventNameConatiner>
        }
        <InvitationCreatePageHeader
          setShowFilteredinvitationTemplates={setShowFilteredinvitationTemplates}
          isCreatedByCurrentUser={isCreatedByCurrentUser}
          isPageForInvitationTemplates={isPageForInvitationTemplates}
          invitationTemplate={invitationTemplate}
          setInvitationTemplateStatus={setInvitationTemplateStatus}>
          {isPageForInvitationTemplates ? createInvitationTemplateRoutes : createInvitationRoutes}
        </InvitationCreatePageHeader>
      </PageBody>
    </InvitationProvider>
  );
};