import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

const getPaginationArrowStyle = (component: typeof KeyboardDoubleArrowRightIcon | typeof KeyboardArrowRightIcon | typeof KeyboardDoubleArrowLeftIcon | typeof KeyboardArrowLeftIcon) => {
  return styled(component)(
    (props) => ({
      ...themePublic.paginationNextPageArrow as CSSProperties,
      opacity: props.opacity,
    }),
  );
};

export const PaginationSection = styled(Box)(
  () => ({
    ...themePublic.paginationSection as CSSProperties,
    padding: '0 1rem',
    boxSizing: 'border-box',
    marginTop: '1rem',
  }),
);

export const PaginationFirstPageArrow = getPaginationArrowStyle(KeyboardDoubleArrowLeftIcon);

export const PaginationNextPageArrow = getPaginationArrowStyle(KeyboardArrowLeftIcon);

export const PaginationPreviousPageArrow = getPaginationArrowStyle(KeyboardArrowRightIcon);

export const PaginationLastPageArrow = getPaginationArrowStyle(KeyboardDoubleArrowRightIcon);

export const SelectContainer = styled(Box)(
  () => ({
    display: 'flex',
    alignItems: 'center',
  }),
);
