import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ListItemHeaderComponent } from 'components';
import { useInvitation } from 'contexts';
import { ACTION_MENU_INVIATTION_TEMPLATES, INVITATION_TEMPLATE_STATUS } from 'enums';
import { disableInvitationTab, getIdFromRoute, getRouteForNavigation } from 'helpers';
import { InvitationContextType, InvitationTemplateProps } from 'types';

import {
  Assignment as AssignmentIcon,
  CheckCircle as CheckCircleIcon,
  RestorePage as DraftIcon,
  Drafts as DraftsIcon,
  Email as EmailIcon,
  Palette as PaletteIcon,
  CheckCircleOutline as PublishedIcon,
  Send as SendIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  BasicHeaderItem, ButtonPublishContainer,
  ButtonPublishContainerMobile,
  PageHeaderContainer, PageHeaderContainerInvitationActive, PageHeaderContainerMobile, PageHeaderContainerTemplate,
  PageHeaderDisabledIconStyle, PageHeaderIconStyleMobile, PageHeaderPublishButtonMobileStyle, PageHeaderSelectedIconStyle, PageHeaderSelectedIconStyledMobile, PublishButton
} from './InvitationCreatePageHeader.styled';

type InvitationCreatePageHeaderProps = {
  setShowFilteredinvitationTemplates: (value: boolean) => void;
  children: React.ReactNode;
  isCreatedByCurrentUser: boolean;
  isPageForInvitationTemplates?: boolean;
  invitationTemplate: InvitationTemplateProps;
  setInvitationTemplateStatus: (invitatioNTemplateStatus: string) => void;
}

export const InvitationCreatePageHeader = ({
  setShowFilteredinvitationTemplates,
  children, isCreatedByCurrentUser,
  isPageForInvitationTemplates = false,
  invitationTemplate,
  setInvitationTemplateStatus
}: InvitationCreatePageHeaderProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const navLocation = useLocation();
  const params = useParams();
  const { invitationDetails }: InvitationContextType = useInvitation();

  useEffect(() => {
    setActiveTab(navLocation.pathname);
  }, [navLocation]);

  const [ selectedIndex, setSelectedIndex ] = useState(isPageForInvitationTemplates ? 1 : 0);

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const setActiveTab = (pathname: string) => {
    switch (pathname) {
      case '/invitations/create/invitation':
      case `/invitations/create/invitation/${localStorage.getItem('activeId')}`:
        setSelectedIndex(0);
        break;
      case '/invitations/create/details':
      case `/invitations/create/details/${localStorage.getItem('activeId')}`:
      case `/invitations/edit/details/${localStorage.getItem('activeId')}`:
      case `/invitationTemplates/create/details/${getIdFromRoute(params['*'])}`:
      case `/invitationTemplates/edit/details/${getIdFromRoute(params['*'])}`:
        setSelectedIndex(1);
        break;
      case '/invitations/create/design':
      case `/invitations/create/design/${localStorage.getItem('activeId')}`:
      case `/invitations/edit/design/${localStorage.getItem('activeId')}`:
      case `/invitationTemplates/create/design/${getIdFromRoute(params['*'])}`:
      case `/invitationTemplates/edit/design/${getIdFromRoute(params['*'])}`:
        setSelectedIndex(2);
        break;
      case '/invitations/create/envelope':
      case `/invitations/create/envelope/${localStorage.getItem('activeId')}`:
      case `/invitations/edit/envelope/${localStorage.getItem('activeId')}`:
      case `/invitationTemplates/create/envelope/${getIdFromRoute(params['*'])}`:
      case `/invitationTemplates/edit/envelope/${getIdFromRoute(params['*'])}`:
        setSelectedIndex(3);
        break;
      case '/invitations/edit/preview':
      case `/invitations/edit/preview/${localStorage.getItem('activeId')}`:
      case '/invitations/create/preview':
      case `/invitations/create/preview/${localStorage.getItem('activeId')}`:
      case `/invitationTemplates/create/preview/${getIdFromRoute(params['*'])}`:
      case `/invitationTemplates/edit/preview/${getIdFromRoute(params['*'])}`:
        setSelectedIndex(4);
        break;
      case '/invitations/edit/send':
      case `/invitations/edit/send/${localStorage.getItem('activeId')}`:
      case '/invitations/create/send':
      case `/invitations/create/send/${localStorage.getItem('activeId')}`:
        setSelectedIndex(5);
        break;
      case '/invitations/create/track':
      case `/invitations/create/track/${localStorage.getItem('activeId')}`:
      case `/invitations/edit/track/${localStorage.getItem('activeId')}`:
        setSelectedIndex(6);
        break;
      default:
        setSelectedIndex(isPageForInvitationTemplates ? 1 : 0);
        break;
    }
  };

  const handleUpdateInvitationTemplateStatus = () => {
    const newStatus = invitationTemplate.status === INVITATION_TEMPLATE_STATUS.DRAFT.toUpperCase() ?
      INVITATION_TEMPLATE_STATUS.PUBLISHED : INVITATION_TEMPLATE_STATUS.DRAFT;

    setInvitationTemplateStatus(newStatus.toUpperCase());
  };

  const setStyle = (index: number) => {
    return selectedIndex === index ? PageHeaderSelectedIconStyledMobile : PageHeaderIconStyleMobile;
  };

  return (
    isMobile ?
      <>
        <PageHeaderContainerMobile>
          {!isPageForInvitationTemplates &&
          <ListItemHeaderComponent
            text={t('invitation')} icon={<DraftsIcon style={{... setStyle(0)}} />}
            handleOnClick={() => {
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'invitation', params['*']));
              setShowFilteredinvitationTemplates(false);
            }} />
          }
          <ListItemHeaderComponent
            isDisabled={!isPageForInvitationTemplates && !localStorage.getItem('activeId')}
            text={t('details')} icon={<AssignmentIcon style={setStyle(1)} />}
            handleOnClick={() => {
              (!isPageForInvitationTemplates || getIdFromRoute(params['*'])) &&
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'details', params['*']));
            }} />
          <ListItemHeaderComponent
            isDisabled={
              !isPageForInvitationTemplates &&
              (!localStorage.getItem('activeId') ||
              navLocation.pathname === '/invitationTemplates/create/details' ||
              disableInvitationTab(invitationDetails))}
            text={t('design')} icon={<PaletteIcon style={setStyle(2)} />}
            handleOnClick={() => {
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'design', params['*']));
            }} />
          <ListItemHeaderComponent
            isDisabled={
              !isPageForInvitationTemplates &&
              (!localStorage.getItem('activeId') ||
              navLocation.pathname === '/invitationTemplates/create/details' ||
              disableInvitationTab(invitationDetails))}
            text={t('envelope')} icon={<EmailIcon style={setStyle(3)} />}
            handleOnClick={() => {
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'envelope', params['*']));
            }} />
          <ListItemHeaderComponent
            isDisabled={
              !isPageForInvitationTemplates &&
              (!localStorage.getItem('activeId') ||
              navLocation.pathname === '/invitationTemplates/create/details' ||
              disableInvitationTab(invitationDetails))}
            text={t('preview')} icon={<VisibilityIcon style={setStyle(4)} />}
            handleOnClick={() => {
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'preview', params['*']));
            }} />
          {!isPageForInvitationTemplates && isCreatedByCurrentUser &&
          <ListItemHeaderComponent
            isDisabled={!isPageForInvitationTemplates && (!localStorage.getItem('activeId') || disableInvitationTab(invitationDetails))}
            text={t('send')} icon={<SendIcon style={setStyle(5)} />}
            handleOnClick={() => {
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'send', params['*']));
            }} />
          }
          {!isPageForInvitationTemplates && isCreatedByCurrentUser &&
          <ListItemHeaderComponent
            isDisabled={!isPageForInvitationTemplates && (!localStorage.getItem('activeId') || disableInvitationTab(invitationDetails))}
            text={t('track')} icon={<CheckCircleIcon style={setStyle(6)} />}
            handleOnClick={() => {
              handleNavigate(getRouteForNavigation(navLocation.pathname, 'track', params['*']));
            }} />
          }
          {isPageForInvitationTemplates &&
          <ButtonPublishContainerMobile>
            <ListItemHeaderComponent
              text={invitationTemplate?.status === INVITATION_TEMPLATE_STATUS.PUBLISHED.toUpperCase() ? t(ACTION_MENU_INVIATTION_TEMPLATES.UNPUBLISH) : t(ACTION_MENU_INVIATTION_TEMPLATES.PUBLISH)}
              icon={invitationTemplate?.status === INVITATION_TEMPLATE_STATUS.PUBLISHED.toUpperCase() ? <DraftIcon style={PageHeaderPublishButtonMobileStyle}/> : <PublishedIcon style={PageHeaderPublishButtonMobileStyle}/>}
              handleOnClick={() => handleUpdateInvitationTemplateStatus()}
              isDisabled={!invitationTemplate?.status} />
          </ButtonPublishContainerMobile>}
        </PageHeaderContainerMobile>
        {children}
      </>
      :
      <>
        <PageHeaderContainer>
          <Box component={isPageForInvitationTemplates || !isCreatedByCurrentUser ? PageHeaderContainerTemplate : PageHeaderContainerInvitationActive}>
            {!isPageForInvitationTemplates &&
            <Typography component={selectedIndex === 0 ? PageHeaderSelectedIconStyle : BasicHeaderItem}
              onClick={() => {
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'invitation', params['*']));
                setShowFilteredinvitationTemplates(false);
              }}>
              {t('invitation')}
            </Typography>
            }
            <Typography
              component={selectedIndex === 1 ? PageHeaderSelectedIconStyle :
                !isPageForInvitationTemplates && !localStorage.getItem('activeId') ? PageHeaderDisabledIconStyle : BasicHeaderItem}
              onClick={() => {
                (!isPageForInvitationTemplates || getIdFromRoute(params['*'])) &&
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'details', params['*']));
              }}>
              {t('details')}
            </Typography>
            <Typography
              component={selectedIndex === 2 ? PageHeaderSelectedIconStyle :
                !isPageForInvitationTemplates && (!localStorage.getItem('activeId') || disableInvitationTab(invitationDetails)) || navLocation.pathname === '/invitationTemplates/create/details' ? PageHeaderDisabledIconStyle : BasicHeaderItem}
              onClick={() => {
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'design', params['*']));
              }}>
              {t('design')}
            </Typography>
            <Typography
              component={selectedIndex === 3 ? PageHeaderSelectedIconStyle :
                !isPageForInvitationTemplates && (!localStorage.getItem('activeId') || disableInvitationTab(invitationDetails)) || navLocation.pathname === '/invitationTemplates/create/details' ? PageHeaderDisabledIconStyle : BasicHeaderItem}
              onClick={() => {
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'envelope', params['*']));
              }}>
              {t('envelope')}
            </Typography>
            <Typography
              component={selectedIndex === 4 ? PageHeaderSelectedIconStyle :
                !isPageForInvitationTemplates && (!localStorage.getItem('activeId') || disableInvitationTab(invitationDetails)) || navLocation.pathname === '/invitationTemplates/create/details' ? PageHeaderDisabledIconStyle : BasicHeaderItem}
              onClick={() => {
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'preview', params['*']));
              }}>
              {t('preview')}
            </Typography>
            {!isPageForInvitationTemplates && isCreatedByCurrentUser &&
            <Typography
              component={selectedIndex === 5 ? PageHeaderSelectedIconStyle :
                !isPageForInvitationTemplates && !localStorage.getItem('activeId') || disableInvitationTab(invitationDetails) ? PageHeaderDisabledIconStyle : BasicHeaderItem}
              onClick={() => {
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'send', params['*']));
              }}>
              {t('send')}
            </Typography>
            }
            {!isPageForInvitationTemplates && isCreatedByCurrentUser &&
            <Typography
              component={selectedIndex === 6 ? PageHeaderSelectedIconStyle :
                !isPageForInvitationTemplates && !localStorage.getItem('activeId') || disableInvitationTab(invitationDetails) ? PageHeaderDisabledIconStyle : BasicHeaderItem}
              onClick={() => {
                handleNavigate(getRouteForNavigation(navLocation.pathname, 'track', params['*']));
              }}>
              {t('track')}
            </Typography>
            }
          </Box>
          {isPageForInvitationTemplates &&
          <ButtonPublishContainer>
            <PublishButton
              variant='contained'
              onClick={() => handleUpdateInvitationTemplateStatus()}
              disabled={!invitationTemplate?.status}>
              {invitationTemplate?.status === INVITATION_TEMPLATE_STATUS.PUBLISHED.toUpperCase() ?
                <>
                  <DraftIcon/>
                  <Typography> {t(ACTION_MENU_INVIATTION_TEMPLATES.UNPUBLISH)} </Typography>
                </> :
                <>
                  <PublishedIcon/>
                  <Typography> {t(ACTION_MENU_INVIATTION_TEMPLATES.PUBLISH)} </Typography>
                </>}
            </PublishButton>
          </ButtonPublishContainer>}
        </PageHeaderContainer>
        {children}
      </>
  );
};
