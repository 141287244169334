import React from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHeader, InputField } from 'components';
import { VALIDATION_RESTRICTION } from 'enums';
import { showToast } from 'helpers';
import { REGISTER_USER } from 'services';

import { ButtonsContainer, FormContainer, FormikContainer, InputContainer, InputGroup, LoginButton, RegisterContainer, SignUpButton } from './RegisterPage.styled';

export const RegisterPage = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [ sendConfirmationEmail, { data, loading, error: mutationError }] = useMutation(REGISTER_USER);

  const intialValues = {
    firstName: '',
    lastName: '',
    email: ''
  };

  const registerSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    lastName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    email: Yup.string()
      .email(t('invalidEmailFormat'))
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))
  });

  const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    sendConfirmationEmail({variables: { input: { firstName: values.firstName, lastName: values.lastName, email: values.email}}})
      .then(() => {
        showToast('info', `${t('sendTo')} ${values.email}`);
        resetForm();
      });
    setSubmitting(false);
  };
  return (
    <RegisterContainer>
      <FormikContainer>
        <FormHeader label={t('signUp')}/>
        <Formik
          initialValues={intialValues}
          validationSchema={registerSchema}
          onSubmit={handleSubmit}>
          {({ isSubmitting, errors, values }) => {
            return (
              <FormContainer>
                <Form autoComplete='off'>
                  <Box component={isMobile ? Box : InputGroup}>
                    <InputContainer>
                      <InputField
                        inputId='firstName'
                        inputName='firstName'
                        isError={errors.firstName}
                        label={t('firstName')}
                        type='text' />
                    </InputContainer>
                    <InputContainer>
                      <InputField
                        inputId='lastName'
                        inputName='lastName'
                        isError={errors.lastName}
                        label={t('lastName')}
                        type='text' />
                    </InputContainer>
                  </Box>
                  <InputField
                    inputId='email'
                    inputName='email'
                    isError={errors.email}
                    label={t('email')}
                    type='email' />
                  <ButtonsContainer>
                    <SignUpButton
                      type='submit'
                      disableElevation disabled={Object.keys(errors).length > 0 || !values.firstName || !values.lastName || !values.email || isSubmitting} >
                      {t('signUp')}
                    </SignUpButton>
                    <LoginButton
                      onClick={() => navigate(-1)}
                      disableElevation>
                      {t('login')}
                    </LoginButton>
                  </ButtonsContainer>
                </Form>
              </FormContainer>
            );
          }}
        </Formik>
      </FormikContainer>
    </RegisterContainer>
  );
};