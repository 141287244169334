import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { InputField, Loader, VirtualKeyboard } from 'components';
import { VALIDATION_RESTRICTION } from 'enums';
import { Prompt, showToast } from 'helpers';
import { GET_CURRENT_USER, graphMutationMiddleware, graphQueryMiddleware, REQUEST_CHANGE_PASSWORD, UPDATE_USER } from 'services';

import {
  AccountSettingsFormHeading,
  AccountSettingsPageBody,
  AccountSettingsPageContent,
  AccountSettingsPageHeader,
  NewInvitationButton,
  ImpersonateText,
  LeaveImpersonatedButton,
  FormStyled,
  SaveButton,
  ButtonsContainer,
  CancelButton,
} from './AccountSettings.styled';

export const AccountSettingsPage = () => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const keyboardRef = useRef(null);

  const [ updateUser, { loading }] = graphMutationMiddleware(UPDATE_USER);
  const [requestChangePassword] = graphMutationMiddleware(REQUEST_CHANGE_PASSWORD);
  const { data: currentUserData } = graphQueryMiddleware(GET_CURRENT_USER);
  const [ currentUser, setCurrentUser ] = useState(null);
  const [ user, setUser ] = useState(null);
  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  const handleNavigate = (route: string) => {
    localStorage.removeItem('activeId');
    navHistory(route);
  };

  useEffect(() => {
    if (!currentUserData) {
      return;
    }
    setCurrentUser(currentUserData.currentUser);
    setUser(currentUserData.currentUser.originalUser ? currentUserData.currentUser.originalUser : currentUserData.currentUser);
  }, [currentUserData]);

  const intialValues = {
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    email: user && user.email
  };

  const SettingsSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    lastName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    email: Yup.string()
      .email(t('invalidEmailFormat'))
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    updateUser({variables: {input: {id: user.id, firstName: values.firstName, lastName: values.lastName, email: values.email}}}).then((res: any) => {
      if (res.data.updateCurrentUser.emailChanged) {
        showToast('info', `${t('changeEmailRequested')} ${values.email}.`);
      }
    }).then(res => setSubmitting(false));
  };

  const handleChangePassword = () => {
    requestChangePassword()
      .then(res => {
        showToast('info', `${t('changePasswordEmailSent')} ${res.data.changePassword}`);
      });
  };

  const leaveImpersonation = () => {
    localStorage.removeItem('userId');
    window.location.reload();
  };

  return (
    user &&
    <AccountSettingsPageBody as={Box}>
      <Loader loadingPage={false} inProgress={loading} />
      {!isMobile &&
      <AccountSettingsPageHeader>
        <Typography>{t('myProfile')}</Typography>
        <NewInvitationButton as={Button} variant='contained' onClick={() => handleNavigate('/invitations/create/invitation')}>
          {t('createNewInvitationBtn')}
        </NewInvitationButton>
      </AccountSettingsPageHeader>}
      <AccountSettingsPageContent>
        <AccountSettingsFormHeading>{t('accountSettings')}</AccountSettingsFormHeading>
        <Formik
          initialValues={intialValues}
          enableReinitialize
          validationSchema={SettingsSchema}
          onSubmit={handleSubmit}>
          {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }) => {
            return (
              <FormStyled autoComplete='off'>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      setShowKeyboard={setShowKeyboard}
                      setInputName={setInputName}
                      inputId='firstName'
                      inputName='firstName'
                      isError={errors.firstName}
                      label={t('firstName')}
                      placeholder='John'
                      type='firstName'
                      disabled={!!currentUser?.originalUser} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      setShowKeyboard={setShowKeyboard}
                      setInputName={setInputName}
                      inputId='lastName'
                      inputName='lastName'
                      isError={errors.lastName}
                      label={t('lastName')}
                      placeholder='Doe'
                      type='lastName'
                      disabled={!!currentUser?.originalUser} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      setShowKeyboard={setShowKeyboard}
                      setInputName={setInputName}
                      inputId='email'
                      inputName='email'
                      isError={errors.email}
                      label={t('email')}
                      placeholder='johndoe@mail.com'
                      type='email'
                      disabled={!!currentUser?.originalUser} />
                  </Grid>
                  { showKeyboard &&
                  <VirtualKeyboard
                    setShowKeyboard={setShowKeyboard}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    keyboardRef={keyboardRef}
                    inputName={inputName}/>
                  }
                </Grid>
                {currentUser?.originalUser &&
                  <>
                    <ImpersonateText>
                      {t('impersonatedAs')} {currentUser.firstName + ' ' + currentUser.lastName}
                    </ImpersonateText>
                    <LeaveImpersonatedButton variant='contained' onClick={leaveImpersonation}>
                      {t('leaveImpersonation')}
                    </LeaveImpersonatedButton>
                  </>
                }
                <ButtonsContainer>
                  <SaveButton
                    type='submit'
                    variant='contained'
                    disabled={Object.keys(errors).length > 0 || isSubmitting || !(isValid && dirty)}>
                    {t('saveSettings')}
                  </SaveButton>
                  <CancelButton
                    type='button'
                    variant='outlined'
                    onClick={handleChangePassword}>
                    {t('changePassword')}
                  </CancelButton>
                </ButtonsContainer>
                <Prompt when={dirty} options={{
                  title: t('leavePage'),
                  message: t('unsavedChanges'),
                  buttons: [
                    {
                      label: 'Confirm',
                      continue: true
                    },
                    {
                      label: 'Cancel',
                    }
                  ],
                }} />
              </FormStyled>
            );
          }}
        </Formik>
      </AccountSettingsPageContent>
    </AccountSettingsPageBody>
  );
};