import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const BodyContainer = styled(Box)(
  () => ({
    '& svg': {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: '50%',
      boxSizing: 'border-box',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 2,
    },
    '& path': {
      fill: themeDefault.palette.gloomyLightMainBackground,
    },
  }),
);

export const TextContainer = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '4px',
    boxShadow: '0px 0px 6px rgba(0,0,0,0.6)',
    padding: '0.5rem',
    position: 'absolute',
    width: '200px',
    zIndex: 1,
  }),
);

export const TextContent = styled(Typography)(
  () => ({
    fontSize: '0.8rem',
  }),
);
