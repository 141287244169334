import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomTooltip } from 'components';
import { useTrackStore } from 'store';
import { EviteType, InvitationProps } from 'types';

import {
  CardName, CardNameText, ChardSwitchContainer, Chart, ChartContainer, EviteContainer,
  NoCardsMessage, SwitchButton, ThemeOverlappedText
} from './EviteResponseChart.styled';

type EviteResponseChartProps = {
  evite: EviteType,
  linkedInvitation: InvitationProps,
  enabledInvitations: number[],
  enabledInvitationsEvitesDict: any,
  evitesConfigsDict: any,
  handleEnableEvite: (invitationId: number, evite: EviteType) => void,
}

export const EviteResponseChart = ({
  evite,
  linkedInvitation,
  enabledInvitations,
  enabledInvitationsEvitesDict,
  evitesConfigsDict,
  handleEnableEvite,
}: EviteResponseChartProps) => {
  const [t] = useTranslation();
  const { cumulativeEvitesResponses } = useTrackStore();

  return (
    <>
      <EviteContainer item xs={12} sm={12} md={12} lg={6} xl={6} key={`${linkedInvitation.id}_${evite.id}`}>
        <ChartContainer container>
          <ChardSwitchContainer item xs={12} sm={12} md={3} lg={4} xl={3}>
            <CardName>
              <SwitchButton
                key={`${linkedInvitation.id}_${evite.id}_switch`}
                checked={enabledInvitationsEvitesDict[linkedInvitation.id]?.some((e: EviteType) => e.id === evite.id)}
                disabled={!enabledInvitations.includes(linkedInvitation.id)}
                onChange={() => handleEnableEvite(linkedInvitation.id, evite)} />
              <CardNameText>
                <CustomTooltip theme={ThemeOverlappedText} text={evite.name} />
              </CardNameText>
            </CardName>
          </ChardSwitchContainer>
          {cumulativeEvitesResponses.length > 0 && evitesConfigsDict[evite.id]?.series.some((serie: number) => serie > 0) ?
            <ChartContainer item xs={12} sm={12} md={5} lg={8} xl={5} >
              <Chart width={360} options={evitesConfigsDict[evite.id].options} series={evitesConfigsDict[evite.id].series} type='pie' />
            </ChartContainer> :
            <NoCardsMessage> {t('noGuestsForEvite')} </NoCardsMessage>}
        </ChartContainer>
      </EviteContainer>
    </>
  );
};