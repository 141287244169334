import { gql } from '@apollo/client';

export const ADD_INVITATION = gql`
mutation addInvitation ($input: InvitationInput){
  addInvitation (input: $input) {
    id
    name
    createdBy {
      id
      firstName
      lastName
    }
  }
}
`;

export const BULK_UPDATE_INVITATION_ACTIVITY_STATUS = gql`
mutation BulkUpdateActivityStatus ($invitationIds: [Long!], $activityStatus: ActivityStatus!) {
  bulkUpdateActivityStatus (invitationIds: $invitationIds, activityStatus: $activityStatus)
}
`;

export const BULK_DELETE_INVITATIONS = gql`
mutation bulkRemoveInvitation ($invitationIds: [Long!]) {
  bulkRemoveInvitation (invitationIds: $invitationIds)
}
`;

export const BULK_EDIT_INVITATIONS = gql`
mutation bulkEditInvitations ($input: BulkEditInvitationsInput){
  bulkEditInvitations (input: $input) {
    id
    tags {
      name
    }
  }
}
`;

export const CHANGE_TEMPLATE = gql`
mutation ChangeTemplate ($id: Long!, $templateId: Long!) {
  changeTemplate (id: $id, templateId: $templateId) {
    id
    name
    description
    evites {
      id
      name
      contentJson
      isDefault
      position
      isSelected
      requestRsvp
      image {
        id
        name
        url
        thumbnailUrl
        key
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
    typeId
    colors
    status
    response
    link
    eventDate
    isOpened
    tags {
      id
      name
    }
    senders {
      title
      firstName
      lastName
      isDefault
      createdAt
      modifiedAt
      id
    }
    locationInfo {
      id
      startDate
      endDate
      venue {
        name
        address1
        address2
        city
        state
        zipCode
        displayedAddress
        status
        createdById
      }
    }
    additionalInfo
    eventLinkId
    gifts {
      url
      invitationId
      createdAt
      modifiedAt
      id
    }
    giftRegistryNote
  }
}
`;

export const REMOVE_INVITATION = gql`
mutation RemoveInvitation ($invitationId: Long!) {
  removeInvitation (invitationId: $invitationId)
}
`;

export const SET_TO_NOT_ANSWERED = gql`
mutation SetToNotAnswered ($token: String ) {
  setToNotAnswered (token: $token )
}
`;

export const UPDATE_EVITE = gql`
mutation updateEvite ($input: UpdateEviteInput) {
  updateEvite (input: $input) {
    id
    name
    contentJson
    isSelected
    requestRsvp
    image {
      id
      name
      url
      thumbnailUrl
      key
    }
  }
}
`;

export const UPDATE_INVITATION = gql`
mutation updateInvitation ($input: UpdateInvitationInput) {
  updateInvitation (input: $input) {
    id
    name
    description
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
    typeId
    colors
    status
    response
    link
    eventDate
    isOpened
    tags {
      id
      name
    }
    senders {
      title
      firstName
      lastName
      isDefault
      createdAt
      modifiedAt
      id
    }
    locationInfo {
      id
      createdAt
      modifiedAt
      startDate
      endDate
      venue {
        id
        name
        address1
        address2
        city
        state
        zipCode
        displayedAddress
        status
        createdById
      }
    }
    additionalInfo
    eventLinkId
    eventLinkName
    gifts {
      url
      invitationId
      createdAt
      modifiedAt
      id
    }
    giftRegistryNote
  }
}
`;

export const UPDATE_INVITATION_ACTIVITY_STATUS = gql`
mutation UpdateInvitationActivityStatus ($invitationId: Long!, $activityStatus: ActivityStatus!) {
  updateInvitationActivityStatus (invitationId: $invitationId, activityStatus: $activityStatus) {
    id
    name
    description
    createdAt
    typeId
    colors
    status
    response
    link
    eventDate
    isOpened
    tags {
      id
      name
    }
    senders {
      title
      firstName
      lastName
      isDefault
      createdAt
      modifiedAt
      id
    }
    locationInfo {
      id
      createdAt
      modifiedAt
      startDate
      endDate
      venue {        
        name
        address1
        address2
        city
        state
        zipCode
        displayedAddress
        status
        createdById
      }
    }
    additionalInfo
    eventLinkId
    eventLinkName
    gifts {
      url
      invitationId
      createdAt
      modifiedAt
      id
    }
    giftRegistryNote
    activityStatus
  }
}
`;

export const UPDATE_INVITATION_ENVELOPE = gql`
mutation UpdateInvitationEnvelope ($input : EnvelopeInput) {
  updateInvitationEnvelope (input: $input ) {
   id
   contentJson
  }
}
`;

export const UPDATE_INVITATION_NAME = gql`
mutation UpdateInvitationName($input: UpdateInvitationNameInput) {
  updateInvitationName(input: $input) {
    id
    name
  }
}

`;

export const UPDATE_SELECTED_EVITES = gql`
mutation updateSelectedEvites ($input: UpdateSelectedEvitesInput) {
  updateSelectedEvites (input: $input) {
    id
    isDefault
    position
    isSelected
    requestRsvp
  }
}
`;

export const COPY_GUESTS_FROM_INVITATION = gql`
mutation copyGuestFromInvitation($targetInvitationId: Long!, $cloneInvitationId: Long!) {
  copyGuestFromInvitation(targetInvitationId: $targetInvitationId, cloneInvitationId: $cloneInvitationId)
}
`;
