import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ConfirmationModal, Loader, SelectInvitationCard } from 'components';
import { useInvitation } from 'contexts';
import { getRouteForNavigation, showToast } from 'helpers';
import { EviteType, InvitationContextType } from 'types';

import {
  GET_INVITATION_BY_ID,
  UPDATE_SELECTED_EVITES,
  graphLazyQueryMiddleware,
  graphMutationMiddleware,
} from 'services';

import {
  AnotherInvitationTemplateButton, AnotherInvitationTemplateContainer, AnotherInvitationTemplateMessage,
  CardContainer, CardsContainer, HeaderTitle, MaxContainer,
  PageButtonsContainer, PageContent, PageNextButton, PageTitle, SelectCardsContainer, SelectCardsWrapper
} from './SelectInvitationCards.styled';

type SelectInvitationCardsProps = {
  handleShowCards: () => void
};

export const SelectInvitationCards = ({handleShowCards}: SelectInvitationCardsProps) => {
  const [t] = useTranslation();
  const navLocation = useLocation();
  const navHistory = useNavigate();
  const params = useParams();

  const [ getInvitation, { loading: loadingInvitationData }] = graphLazyQueryMiddleware(GET_INVITATION_BY_ID);
  const [ updateSelectedEvites, { loading: evitesUpdating }] = graphMutationMiddleware(UPDATE_SELECTED_EVITES);

  const { invitationDetails, setInvitationDetails }: InvitationContextType = useInvitation();
  const [ invitationEvites, setInvitationEvites ] = useState<EviteType[]>([]);
  const [ selectedEvites, setSelectedEvites ] = useState<number[]>([]);
  const [ requestRsvpEvites, setRequestRsvpEvites ] = useState<number[]>([]);
  const [ changedEvites, setChangedEvites ] = useState<number[]>([]);
  const [ draggedEvite, setDraggedEvite ] = useState<EviteType | null>(null);
  const [ isOpenSelectAnotherTemplateModal, setIsOpenSelectAnotherTemplateModal ] = useState(false);

  const [ isLoadingInvitationDetails, setIsLoadingInvitationDetails ] = useState<boolean>(true);

  useEffect(() => {
    if (invitationDetails) {
      setInvitationEvites(invitationDetails.evites);
      setSelectedInvitationEvites();
      setIsLoadingInvitationDetails(false);
    }
  }, [invitationDetails]);

  useEffect(() => {
    if (invitationEvites.length > 0) {
      updateInvitationEvites();
    }
  }, [ selectedEvites.length, requestRsvpEvites.length ]);

  const updateInvitationEvites = () => {
    const invitationEvitesCopy = invitationEvites?.map((evite: EviteType) => {
      return {
        id: evite.id,
        isDefault: evite.isDefault,
        position: invitationEvites.findIndex((e) => e.id === evite.id),
        isSelected: selectedEvites.includes(evite.id),
        requestRsvp: requestRsvpEvites.includes(evite.id)
      };
    });

    updateSelectedEvites({variables: {
      input: {
        evites: invitationEvitesCopy
      }
    }}).then(() => {
      invitationDetails.id &&
      getInvitation({
        fetchPolicy: 'cache-and-network',
        variables: {
          id: invitationDetails.id
        }
      }).then(res => {
        setInvitationDetails(res.data.invitation);
      }).catch(() => setInvitationDetails(null));
    });
  };

  const setSelectedInvitationEvites = () => {
    setChangedEvites([]);
    const selectedEvitesInit: number[] = [];
    const requestRsvpEvitesInit: number[] = [];
    invitationDetails?.evites?.forEach((item: EviteType) => {
      if (item.isSelected) {
        selectedEvitesInit.push(item.id);
      }
      if (item.requestRsvp) {
        requestRsvpEvitesInit.push(item.id);
      }
    });
    setSelectedEvites(selectedEvitesInit);
    setRequestRsvpEvites(requestRsvpEvitesInit);
  };

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const handleSelectedCard = (id: number) => {
    setChangedEvites([ ...changedEvites, id ]);
    selectedEvites.includes(id) ?
      selectedEvites.length < 2 ? showToast('error', t('oneEviteMustBeSelected')) : setSelectedEvites(selectedEvites.filter(item => item !== id)) :
      setSelectedEvites([ ...selectedEvites, id ]);
  };

  const handleSelectRequestedRSVP = (id: number) => {
    setChangedEvites([ ...changedEvites, id ]);
    requestRsvpEvites.includes(id) ?
      setRequestRsvpEvites(requestRsvpEvites.filter(item => item !== id)):
      setRequestRsvpEvites([ ...requestRsvpEvites, id ]);
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, evite: EviteType) => {
    setDraggedEvite(evite);
  };

  const onDragOver = (evite: EviteType) => {
    if (draggedEvite.id === evite.id) {
      return;
    }
    const items = invitationEvites.filter((e: EviteType) => e.id !== draggedEvite.id);
    items.splice(invitationEvites.findIndex((e: EviteType) => e.id === evite.id), 0, draggedEvite);

    setInvitationEvites(items);
  };

  const onDragEnd = () => {
    setDraggedEvite(null);
    updateInvitationEvites();
  };

  const goToSelectAnotherTemplate = () => {
    handleNavigate(getRouteForNavigation(navLocation.pathname, 'invitation', params['*']));
    handleShowCards();
  };

  return (
    <>
      <Loader loadingPage={false} inProgress={evitesUpdating || loadingInvitationData || isLoadingInvitationDetails} />
      <HeaderTitle>{t('selectInvitationType')}</HeaderTitle>
      <PageContent>
        <AnotherInvitationTemplateContainer>
          <Box>
            <AnotherInvitationTemplateMessage>{t('useAnotherInvitationTemplateMessage')}</AnotherInvitationTemplateMessage>
          </Box>
          <Box>
            <AnotherInvitationTemplateButton
              variant='outlined'
              onClick={() => setIsOpenSelectAnotherTemplateModal(true)}>
              {t('selectAnotherTemplate')}
            </AnotherInvitationTemplateButton>
          </Box>
        </AnotherInvitationTemplateContainer>
        <SelectCardsWrapper>
          <SelectCardsContainer>
            <Grid>
              <PageTitle>{t('selectEvites')}</PageTitle>
            </Grid>
            <CardsContainer>
              <MaxContainer sx={{ flexGrow: 1 }}>
                <CardsContainer container sx={{ flexWrap: 'wrap' }}>
                  {invitationEvites?.map((item: EviteType) => (
                    <CardContainer key={item.id} onDragOver={() => onDragOver(item)}>
                      <div
                        key={item.id}
                        draggable
                        onDragStart={(e) => onDragStart(e, item)} onDragEnd={onDragEnd}>
                        <SelectInvitationCard
                          item={item}
                          selectedEvites={selectedEvites}
                          requestRsvpEvites={requestRsvpEvites}
                          handleSelectedCard={handleSelectedCard}
                          handleSelectRequestedRSVP={handleSelectRequestedRSVP} />
                      </div>
                    </CardContainer>
                  ))}
                </CardsContainer>
              </MaxContainer>
            </CardsContainer>
          </SelectCardsContainer>
        </SelectCardsWrapper>
      </PageContent>
      <PageButtonsContainer>
        <PageNextButton
          variant='outlined'
          onClick={() => handleNavigate(getRouteForNavigation(navLocation.pathname, 'details', params['*']))}>
          {t(isMobile ? 'next' : 'nextDetails')}
        </PageNextButton>
      </PageButtonsContainer>
      <ConfirmationModal
        isOpen={isOpenSelectAnotherTemplateModal}
        confimMessage={t('confirmSelectAnotherTemplateMessage')}
        handleClose={() => setIsOpenSelectAnotherTemplateModal(false)}
        handleConfirm={goToSelectAnotherTemplate} />
    </>
  );
};
