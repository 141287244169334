import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PageContainer = styled(Box)(
  () => ({
    ...themePublic.pageWithPaperContent as CSSProperties,
  }),
);

export const GridStyled = styled(Grid)(
  () => ({
    ...themePublic.pageWithPaperGridContent as CSSProperties,
  }),
);

export const PaperStyled = styled(Paper)(
  ({ theme }) => ({
    boxShadow: 'none',
    '& .MuiPaper-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      boxSizing: 'border-box',
      margin: '0 auto',
      maxWidth: '80%',
      padding: '2rem 1.5rem',
    },
  }),
);

export const ContentContainer = styled(Paper)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.pageWithPaperDivider as CSSProperties,
  }),
);

export const PageTitle = styled(Typography)(
  () => ({
    ...themePublic.pageWithPaperTitle as CSSProperties,
  }),
);

export const SetPasswordImageContainer = styled(Box)(
  () => ({
    ...themePublic.pageWithPaperImageContainer as CSSProperties,
  }),
);

export const MaxContrainer = styled(Box)(
  () => ({
    ...themePublic.maxWidth as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '0.6rem',
    marginTop: '2rem',
    padding: '0.5rem',
    textTransform: 'capitalize',
    width: '100%',
  }),
);
