import React, { MouseEvent as MouseEventType, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { EditResponseModal, Loader, ViewResponseModal } from 'components';
import { isRsvpResponseNotEmpty } from 'helpers';
import { GET_GUESTS_RSVP_STATUS, graphLazyQueryMiddleware } from 'services';
import { GuestRSVPType, GuestType } from 'types';

import { CloseIconStyled, ContentContainer, DialogContentStyled, EditIconStyled, FlexBoxStyled, ModalHeaderTitle, ViewIconStyled } from './RsvpStatusModal.styled';

type RsvpStatusModalProps = {
  isOpen: boolean,
  handleClose: () => void,
  guest: GuestType,
  isEdit?: boolean,
};

export const RsvpStatusModal = ({isOpen, handleClose, guest, isEdit = false}: RsvpStatusModalProps) => {
  const [t] = useTranslation();
  const [ isEditMode, setIsEditMode ] = useState(isEdit);

  const [ getGuestsRsvpStatus, { data: rsvpStatusData, loading }] = graphLazyQueryMiddleware(GET_GUESTS_RSVP_STATUS);

  useEffect(() => {
    getGuestsRsvpStatus({
      fetchPolicy: 'no-cache',
      variables: {
        guestId: guest?.id,
        invitationId: guest?.invitation.id
      }
    });
  }, []);

  const guestRsvp: GuestRSVPType = rsvpStatusData?.guestsRsvpStatus;
  const isResponseValid = isRsvpResponseNotEmpty(guestRsvp);

  return (
    loading ?
      <Loader loadingPage={false} inProgress={loading} /> :
      <Dialog open={isOpen} onClose={handleClose} maxWidth='xl' fullScreen={isMobile && isResponseValid} sx={{overflowY: 'visible !important'}}>
        <DialogContentStyled
          sx={{
            position: 'relative',
            overflowY: 'auto',
            overflowX: 'hidden !important',
            width: `${isMobile ? '100%' : '50vw'}`,
            minWidth: '50vw',
            boxSizing: 'border-box',
            paddingBottom: '20px'
          }}>
          {
            isResponseValid &&
              <FlexBoxStyled>
                {
                  isEditMode ?
                    <ViewIconStyled onClick={() => setIsEditMode(!isEditMode)} /> :
                    <EditIconStyled onClick={() => setIsEditMode(!isEditMode)} />
                }
                <ModalHeaderTitle>{t('rsvpStatus')}</ModalHeaderTitle>
              </FlexBoxStyled>
          }
          <CloseIconStyled
            sx={{marginRight: '20px', position: 'absolute'}}
            onClick={(e: MouseEventType<SVGSVGElement, MouseEvent>) => {
              e.stopPropagation();
              handleClose();
            }} />
          {guestRsvp &&
          <ContentContainer>
            {
              isEditMode ?
                <EditResponseModal isResponseValid={isResponseValid} guest={guest} guestRsvp={guestRsvp} handleClose={handleClose}/> :
                <ViewResponseModal isResponseValid={isResponseValid} guest={guest} guestRsvp={guestRsvp}/>
            }
          </ContentContainer>}
        </DialogContentStyled>
      </Dialog>
  );
};
