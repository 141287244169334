import { CSSProperties } from '@emotion/serialize';
import { ContentCopy } from '@mui/icons-material';
import { Button, Chip, TableCell, Tooltip, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    fontWeight: 'bold',
    lineHeight: 1.8,
    maxWidth: '25vh',
  },
};

export const TooltipStyle = {
  zIndex: 7000,
};

export const HeaderFirstTableCellClosed = styled(TableCell)(
  () => ({
    ...themeDefault.overflowText as CSSProperties,
    ...themePublic.firstTableCell as CSSProperties,
    maxWidth: '25vh',
    minWidth: '4rem',
  }),
);

export const BasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.basicTableCell as CSSProperties,
    maxWidth: '30vh',
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themeDefault.overflowText,
    ...themePublic.optionTableCell as CSSProperties,
    maxWidth: '30vh',
  }),
);

export const ActiveText = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    whiteSpace: 'nowrap',
  }),
);

export const InactiveText = styled(Typography)(
  () => ({
    color: themeDefault.palette.greyColor,
    whiteSpace: 'nowrap',
  }),
);

export const ChipStyled = styled(Chip)(
  () => ({
    ...themePublic.chip as CSSProperties,
  }),
);

export const CopyButton = styled(Button)(
  () => ({
    ...themePublic.publicContactEntryLinksTableRow_CopyButton as CSSProperties,
  }),
);

export const CopyButtonIcon = styled(ContentCopy)(
  () => ({
    ...themePublic.publicContactEntryLinksTableRow_CopyButtonIcon as CSSProperties,
  }),
);

export const TooltipMessageStyled = styled(Tooltip)(
  () => ({
    ...TooltipStyle,
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  }),
);
