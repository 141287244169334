import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { flushSync } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { StoreApi, UseBoundStore } from 'zustand';

import { TABLE_TYPE } from 'enums';
import { DEBOUNCE_SEARCH_WAIT_TIME, FIRST_PAGE, getDefaultTableParams, getIntialTableParams } from 'helpers';
import { GuestStoreType, UserSettingsCofing } from 'types';

export const useFetching = (
  fetchItems: (text: string) => void,
  refetchItems: () => void,
  isLoading: boolean,
  setStartFetching: (fetchState: boolean) => void,
  startFetching: boolean,
  userSettings: UserSettingsCofing,
  store: UseBoundStore<StoreApi<any>>,
  tableType: TABLE_TYPE,
) => {
  const locationPath = useLocation();
  const { searchText, params, isExecutedRefetch, changeExecuteRefetch, setIsLoading, setParams, setParamsPage, setSearchText, setSelectedItems, setSelectedGuests }: GuestStoreType = store();

  useEffect(() => {
    tableType === TABLE_TYPE.GUEST_TABLE ?
      setSelectedGuests() :
      setSelectedItems([]);

    const newParams = getIntialTableParams(getDefaultTableParams(tableType), tableType, userSettings);
    setParams(newParams);
    setSearchText('');
  }, [locationPath]);

  useEffect(() => {
    fetchItems(searchText);
  }, [params]);

  useEffect(() => {
    debounceFetch(searchText);
    return () => debounceFetch.cancel();
  }, [searchText]);

  useEffect(() => {
    if (isExecutedRefetch && startFetching) {
      refetchItems();
      changeExecuteRefetch();
    }
  }, [isExecutedRefetch]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const newParams = getIntialTableParams(params, tableType, userSettings);
    setParams(newParams);
    flushSync(() => {
      setStartFetching(true);
    });
  }, []);

  const debounceFetch = useCallback(debounce((newSearchText) => {
    setParamsPage({
      ...params,
      page: FIRST_PAGE,
    });
    fetchItems(newSearchText);
  }, DEBOUNCE_SEARCH_WAIT_TIME), [searchText]);

};
