import React, { ElementType, useState } from 'react';
import {
  Checkbox, TableCell,
  TableCellBaseProps,
  TableRow, Tooltip
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, ConfirmationModal, CreateGuestModal, CustomTooltip, RsvpStatusModal } from 'components';
import { RESPONSE_STATUS } from 'enums';
import { calculateMenuPlacement, checkIsAnyEviteSelected } from 'helpers';
import { REMOVE_GUEST, graphMutationMiddleware } from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { ContactType, EviteResponseType, EviteType, GuestType, InvitationProps, ResponseType, SelectedGuests } from 'types';

import {
  CheckboxTableCell, FirstTableCell, FirstTableCellClosed, FirstTableCellHeaderSelected, GroupBadge,
  HeaderFirsTableCellContainer, HeaderLastTableCell, HeaderTitleTableCell, LastTableCell,
  OpenedBasicTableCell, OpenedFirstTableCell, OpenLastRowEndTableCell, OpenLastRowStartTableCell, OpenedLastRowTableCellEnd, OpenedLastTableCell,
  TableRowStyled, TableRowStyledPreview, ThemeMultipleLinesText, ThemeOverlappedText, TitleTableCell
} from './GuestsGroupTableRow.styled';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Mail, Send, Visibility } from '@mui/icons-material';

type GuestTableProps = {
  sendToOneContact: (guestId: number) => void
  guest: GuestType,
  invitation: InvitationProps,
  forPreview: boolean,
  isTrackPage: boolean,
  enabledEvites: EviteType[],
};

export const GuestsGroupTableRow = ({
  guest,
  invitation,
  sendToOneContact,
  forPreview,
  isTrackPage,
  enabledEvites,
}: GuestTableProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { selectedGuests, setSelectedGuests } = useGuestStore();
  const { changeExecuteRefetch: refetchResponses } = useTrackStore();

  const [removeGuest] = graphMutationMiddleware(REMOVE_GUEST);

  const [ isOpenEditModal, setIsOpenEditModal ] = useState(false);
  const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
  const [ openRow, setOpenRow ] = useState(false);
  const [ isOpenRsvpStatusModal, setIsOpenRsvpStatusModal ] = useState(false);
  const [ isEditRSVP, setIsEditRSVP ] = useState(false);

  const handleOpenRsvpModal = (isEdit: boolean) => {
    setIsOpenRsvpStatusModal(true);
    setIsEditRSVP(isEdit);
  };

  const isGuestSelected = (id: number) => {
    return selectedGuests.some((c: SelectedGuests) => c.guestId === id);
  };

  const deleteGuest = () => {
    removeGuest({variables: {
      guestId: Number(guest.id)
    }}).then(() => {
      refetchResponses();
    });
  };

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: Edit,
      functionHandler: () => setIsOpenEditModal(true)
    },
    {
      id: 1,
      title: t('send'),
      icon: Send,
      functionHandler: () => checkIsAnyEviteSelected(guest, sendToOneContact, t('noEvitesSelectedFor', { entity: t('group')}))
    },
    {
      id: 2,
      title: t('preview'),
      icon: Visibility,
      functionHandler: () => navigate(`/invitations/edit/preview/${invitation.id}`, { state: { guest: guest } })
    },
    {
      id: 3,
      title: t('viewRSVP'),
      icon: Mail,
      functionHandler: () => handleOpenRsvpModal(false)
    },
    {
      id: 4,
      title: t('editRSVP'),
      icon: Edit,
      functionHandler: () => handleOpenRsvpModal(true)
    },
    {
      id: 5,
      title: t('remove'),
      icon: Delete,
      functionHandler: () => setIsOpenDeleteModal(true)
    },
  ];

  const getTitleTableCell = () => {
    return (openRow ? HeaderTitleTableCell : TitleTableCell) as ElementType<TableCellBaseProps>;
  };

  return (
    <>
      <TableRowStyled as={forPreview ? TableRowStyledPreview : TableRowStyled}
        selected={false}
        onClick={() => forPreview && setSelectedGuests(guest)}>
        {!forPreview &&
        <CheckboxTableCell>
          {isGuestSelected(guest.id) ?
            <FirstTableCellHeaderSelected checked={isGuestSelected(guest.id)} onClick={() => setSelectedGuests(guest)}/> :
            <Checkbox checked={isGuestSelected(guest.id)} onClick={() => setSelectedGuests(guest)}/>}
        </CheckboxTableCell>}
        <TableCell component={(openRow ? FirstTableCell : FirstTableCellClosed) as ElementType<TableCellBaseProps>}>
          <HeaderFirsTableCellContainer>
            {openRow ?
              <KeyboardArrowUp
                onClick={(e) => {
                  e.preventDefault();
                  setOpenRow(!openRow);
                }}/> :
              <KeyboardArrowDown
                onClick={(e) => {
                  e.preventDefault();
                  setOpenRow(!openRow);
                }}/>}
            <GroupBadge>
              { guest.contact.contacts.length }
            </GroupBadge>
          </HeaderFirsTableCellContainer>
        </TableCell>
        <TableCell component={getTitleTableCell()}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.contact.lastName} />
        </TableCell>
        <TableCell component={getTitleTableCell()}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.contact.firstName} />
        </TableCell>
        <TableCell component={getTitleTableCell()}>
          <CustomTooltip theme={ThemeOverlappedText} text={guest.contact.email} />
        </TableCell>
        {!isTrackPage &&
        <TableCell component={getTitleTableCell()}>
          <CustomTooltip theme={ThemeOverlappedText} text={guest.lastSent && format(parseISO(guest.lastSent), t('dateFormatUI'))} />
        </TableCell>}
        <TableCell component={getTitleTableCell()}>
          <CustomTooltip theme={ThemeOverlappedText} text={guest.contact.phoneNumber} />
        </TableCell>
        {!isTrackPage &&
        <TableCell component={getTitleTableCell()}>
          <Tooltip title={guest?.evites.filter((evite: EviteType) => evite.isSelected).map((evite: EviteType) => evite.name).join(', ')} placement='top' arrow>
            {invitation?.evites.filter((evite: EviteType) => evite.isSelected).length > 0 && invitation?.evites.filter((evite: EviteType) => evite.isSelected).length === guest?.evites.filter((evite: EviteType) => evite.isSelected).length ?
              <span>{t('all')}</span> :
              <span>{`${guest?.evites.filter((evite: EviteType) => evite.isSelected).length}/${invitation?.evites.filter((evite: EviteType) => evite.isSelected).length}`}</span>}
          </Tooltip>
        </TableCell>}
        <TableCell component={(forPreview ? openRow ? HeaderLastTableCell : LastTableCell : getTitleTableCell()) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.sender && `${guest.sender.title ?? ''} ${guest.sender.lastName} ${guest.sender.firstName}`} />
        </TableCell>
        {isTrackPage &&
        <TableCell component={getTitleTableCell()}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.invitation.name} />
        </TableCell>}
        {isTrackPage && enabledEvites &&
          enabledEvites.map((evite: EviteType) => (
            <TableCell key={evite.id} component={getTitleTableCell()}>
              {(!guest.responses || guest.responses.length === 0) && <CustomTooltip theme={ThemeOverlappedText} text={t(RESPONSE_STATUS.NOT_SENT)} />}
              {guest.responses.length === 1 &&
                guest.responses.map((response: ResponseType) =>
                  <CustomTooltip
                    key={evite.id}
                    theme={ThemeOverlappedText}
                    text={t(response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ? response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id).status : t(RESPONSE_STATUS.NOT_SENT))} /> )}
              {guest.responses.length > 1 &&
                guest.responses.map((response: ResponseType) =>
                  response.contact.contacts.length > 0 &&
                    <CustomTooltip
                      key={evite.id}
                      theme={ThemeOverlappedText}
                      text={t(response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ? response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id).status : t(RESPONSE_STATUS.NOT_SENT))} />)}
            </TableCell>
          ))
        }
        {!forPreview &&
        <TableCell component={(openRow ? HeaderLastTableCell : LastTableCell) as ElementType<TableCellBaseProps>}>
          <ActionMenu propsData={actionMenuProps} isTrackPage={isTrackPage} menuPlacement={calculateMenuPlacement(isMobile)}/>
        </TableCell>}
      </TableRowStyled>
      {openRow &&
        guest.contact.contacts.map((item: ContactType) => (
          <TableRowStyled key={item.id}>
            {!forPreview && <TableCell></TableCell>}
            <TableCell component={(item === guest.contact.contacts[guest.contact.contacts.length - 1] ? OpenLastRowStartTableCell : OpenedFirstTableCell) as ElementType<TableCellBaseProps>} />
            <OpenedBasicTableCell>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={item.lastName} />
            </OpenedBasicTableCell>
            <OpenedBasicTableCell>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={item.firstName} />
            </OpenedBasicTableCell>
            <OpenedBasicTableCell>
              <CustomTooltip theme={ThemeOverlappedText} text={item.email} />
            </OpenedBasicTableCell>
            {!isTrackPage && <OpenedBasicTableCell />}
            <OpenedBasicTableCell>
              <CustomTooltip theme={ThemeOverlappedText} text={item.phoneNumber} />
            </OpenedBasicTableCell>
            {!isTrackPage &&
            <OpenedBasicTableCell>
              <Tooltip title={guest?.evites.filter((evite: EviteType) => evite.isSelected).map((evite: EviteType) => evite.name).join(', ')} placement='top' arrow>
                {invitation?.evites.filter((evite: EviteType) => evite.isSelected).length > 0 && invitation?.evites.filter((evite: EviteType) => evite.isSelected).length === guest?.evites.filter((evite: EviteType) => evite.isSelected).length ?
                  <span>{t('all')}</span> :
                  <span>{`${guest?.evites.filter((evite: EviteType) => evite.isSelected).length}/${invitation?.evites.filter((evite: EviteType) => evite.isSelected).length}`}</span>}
              </Tooltip>
            </OpenedBasicTableCell>}
            <TableCell component={(forPreview ? item === guest.contact.contacts[guest.contact.contacts.length - 1] ? OpenedLastRowTableCellEnd : OpenedLastTableCell : item === guest.contact.contacts[guest.contact.contacts.length - 1] ? OpenLastRowEndTableCell : OpenedBasicTableCell) as ElementType<TableCellBaseProps>}>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.sender && `${guest.sender.title ?? ''} ${guest.sender.lastName} ${guest.sender.firstName}`} />
            </TableCell>
            {isTrackPage &&
            <OpenedBasicTableCell>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.invitation.name} />
            </OpenedBasicTableCell>}
            {isTrackPage && enabledEvites &&
              enabledEvites.map((evite: EviteType) => (
                <OpenedBasicTableCell key={evite.id}>
                  {(!guest.responses || guest.responses.length === 0) && <CustomTooltip theme={ThemeOverlappedText} text={t(RESPONSE_STATUS.NOT_SENT)} />}
                  {guest.responses.length > 0 &&
                  guest.responses.map((response: ResponseType) =>
                    response.contact.id === item.id ?
                      <CustomTooltip key={response.id} theme={ThemeOverlappedText} text={t(response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ? response.eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id).status : RESPONSE_STATUS.NOT_SENT)} /> :
                      '' )}
                  {guest.responses.length > 0 && !guest.responses.find((response: ResponseType) => response.contact.id === item.id) && <CustomTooltip theme={ThemeOverlappedText} text={t(RESPONSE_STATUS.NOT_SENT)} />}
                </OpenedBasicTableCell>
              ))
            }
            {!forPreview &&
              <TableCell component={(item === guest.contact.contacts[guest.contact.contacts.length - 1] ? OpenedLastRowTableCellEnd : OpenedLastTableCell) as ElementType<TableCellBaseProps>} />}
          </TableRowStyled>
        ))
      }
      <TableRow>
        <TableCell></TableCell>
      </TableRow>
      {isOpenRsvpStatusModal && <RsvpStatusModal guest={guest} isEdit={isEditRSVP} isOpen={true} handleClose={() => setIsOpenRsvpStatusModal(false)} />}
      {isOpenEditModal &&
      <CreateGuestModal guest={guest} handleClose={() => setIsOpenEditModal(false)} isOpen={isOpenEditModal} />
      }
      <ConfirmationModal isOpen={isOpenDeleteModal} handleClose={() => setIsOpenDeleteModal(false)} confimMessage={t('confirmDeleteGuests')} handleConfirm={deleteGuest} />
    </>
  );
};