import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  InputAdornment,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SelectFilter, UserModal, VirtualKeyboard } from 'components';
import { FORM_TYPE, USER_ROLES, USER_STATUS } from 'enums';
import { defaultPositionForMenu } from 'helpers';
import { searchFilter } from 'resources';
import { useUsersStore } from 'store';

import {
  ChipContainer, ChipStyled, FilterImage, FilterHeading, FilterItemContainer, FilterMenu, FilterSearchField,
  SearchInputHeader, UserListPageHeader
} from './UserListHeader.styled';

export const UserListHeader = () => {
  const [t] = useTranslation();
  const { params, setParams, searchText, setSearchText } = useUsersStore();

  const [ filterEl, setFilterEl ] = useState<null | HTMLElement>(null);
  const [ openModal, setOpenModal ] = useState(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  const filterRef = useRef();
  const keyboardRef = useRef(null);
  const isOpenFilter = Boolean(filterEl);

  const handleSelectedRoles = (e: string[]) => {
    setParams({...params, selectedRoles: e});
  };
  const handleSelectedStatus = (e: string[]) => {
    setParams({...params, selectedStatus: e});
  };

  const openFilters = () => {
    setFilterEl(filterRef.current);
  };
  const closeFilters = () => {
    setFilterEl(null);
  };

  const closeCreateUserModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <UserListPageHeader>
        <Typography>{t('userListHeader')}</Typography>
        <div ref={filterRef}>
          <SearchInputHeader
            autoComplete='off'
            onFocus={() => setShowKeyboard(true)}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={t('searchPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start' onClick={() => openFilters()}>
                  <FilterImage src={searchFilter} />
                </InputAdornment>
              ),
            }}
            variant='outlined'/>
        </div>
        <FilterMenu
          anchorEl={filterEl}
          open={isOpenFilter}
          onClose={closeFilters}
          anchorOrigin={{
            vertical: 0,
            horizontal: 0,
          }}
          transformOrigin={defaultPositionForMenu}>
          <FilterHeading onClick={closeFilters}>
            <FilterImage src={searchFilter}/>
          </FilterHeading>
          <FilterItemContainer>
            <FilterSearchField
              onFocus={() => setShowKeyboard(true)}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              placeholder={t('searchPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant='outlined'/>
            <SelectFilter
              placeholder={t('selectRoles')}
              selectedItems={params.selectedRoles}
              handleSelectItem={handleSelectedRoles}
              items={(Object.keys(USER_ROLES) as (keyof typeof USER_ROLES)[]).map((item, index) => ({id: index, title: t(item.toLowerCase())}))}/>
            <SelectFilter
              placeholder={t('selectStatus')}
              selectedItems={params.selectedStatus}
              handleSelectItem={handleSelectedStatus}
              items={(Object.keys(USER_STATUS) as (keyof typeof USER_STATUS)[]).map((item, index) => ({id: index, title: t(item.toLowerCase())}))}/>
          </FilterItemContainer>
        </FilterMenu>
        <Button variant='contained' onClick={() => setOpenModal(true)}>{t('createNewUser')}</Button>
      </UserListPageHeader>
      <ChipContainer>
        {params.selectedRoles.map((chipName) => (
          <ChipStyled label={chipName} key={chipName} onDelete={() => setParams({...params, selectedRoles: params.selectedRoles.filter(item => item !== chipName)})} />
        ))}
        {params.selectedStatus.map((chipName) => (
          <ChipStyled label={chipName} key={chipName} onDelete={() => setParams({...params, selectedStatus: params.selectedStatus.filter(item => item !== chipName)})} />
        ))}
      </ChipContainer>
      { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          closeWhenOutOfFocus={showKeyboard}
          initialValues={{
            'searchText': searchText
          }}
          setFieldValue={null}
          setStandaloneItem={setSearchText}
          keyboardRef={keyboardRef}
          inputName={'searchText'}/>
      }
      <UserModal isOpen={openModal} handleClose={closeCreateUserModal} type={FORM_TYPE.CREATE} />
    </>
  );
};