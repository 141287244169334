import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  ColorLens as ColorLensIcon, Delete as DeleteIcon, Edit as EditIcon,
  FormatAlignCenter as FormatAlignCenterIcon,
  FormatAlignJustify as FormatAlignJustifyIcon, FormatAlignLeft as FormatAlignLeftIcon,
  FormatAlignRight as FormatAlignRightIcon, FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Menu as MenuIcon,
  RefreshRounded as RedoIcon, Save as SaveIcon, Wallpaper as WallpaperIcon
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent, Fade, FormControl, IconButton,
  ListItemButton,
  ListItemText,
  Menu, MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { fabric } from 'fabric';
import { Formik } from 'formik';
import { HexColorPicker } from 'react-colorful';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  AddFontModal,
  CanvasActionButton,
  CanvasResizeModal, ConfirmationModal, CustomTooltip,
  InputField, Loader,
  SetBackgroundImageModal, ValueAndSliderInput, VirtualKeyboard
} from 'components';
import { useInvitation } from 'contexts';
import {
  CANVAS_ELEMENTS_IDS,
  DEFAULT_EVITE_SIZE,
  EDITOR_CONTAINER_ELEMENTS_IDS,
  FONT_PROPERTY,
  SLIDER_ELEMENTS_IDS,
  VALIDATION_RESTRICTION
} from 'enums';
import {
  FONT_LINE_SPACING_MIN_VALUE,
  FONT_MIN_VALUE,
  Prompt,
  checkIfElementContainsElement,
  checkIfElementsContainsElement,
  delay,
  findDefaultFontValue,
  fontSize,
  getRouteForNavigation,
  initAligningGuidelines,
  initCenteringGuidelines,
  lineSpacing,
  removeFocusFromTextboxMobile,
  resetTextSelection,
  selectAndRemoveFocus,
  showToast,
  switchToEditMode,
  textSpacing
} from 'helpers';
import { addBackgroundIcon, addBackgroundIconDisabled, addTextIcon, addTextIconDisabled } from 'resources';
import {
  ADD_EVITE_TEMPLATE, GET_FONTS, GET_INVITATION_BY_ID, GET_INVITATION_TEMPLATE_BY_ID, GET_PLACEHOLDERS,
  REMOVE_EVITE_TEMPLATE, REPLACE_PLACEHOLDER_DEFAULT_VALUES, REPLACE_PLACEHOLDER_VALUES, UPDATE_EVITE,
  UPDATE_EVITE_TEMPLATE, UPDATE_INVITATION_TEMPLATE, UPDATE_SELECTED_EVITE_TEMPLATES,
  graphLazyQueryMiddleware, graphMutationMiddleware
} from 'services';
import { themePublic } from 'themeDefault';
import { EviteType, FontType, InvitationContextType, PlaceholderType } from 'types';

import 'fabric-history';

import {
  AddFontLabelDesignPage, AddNewText,
  BackgroundSizeText, BottomCanvasButtons, BottomCanvasButtonsContainer, BottomCanvasButtonsContainerMobile, BottomCanvasButtonsMobile, ButtonContainer,
  CardButtonContent, CenterEviteName, CloseIconStyled, ColorTextField, ContainerSubmenuEndButtons, ContainerSubmenuStartButtons,
  DialogTitle,
  DropdownMenu,
  EditSizeIcon, EditorContainer, EditorElementContainer, EditorElementDesignPage, EditorNameLabel,
  FontEditorNameDesignPage, FontFamilySelectItem, FontMenuItem, FormControlSelect, FormStyledDialogContent,
  IconButtonStyled, ImageEditorContainer, ImagePickerDesignPage, LessOptionsContainerMobileStyle,
  MoreHorizIconStyled, MoreOptionIconButton, MoreOptionsContainerMobileStyle, MoreOptionsText,
  NewEviteDialog, NotDisplayedStyle, OpenedSubmenuContainer,
  PageButtonsContainer, PageContent, PageNextButton, PagePrevButton,
  PlaceholderMenuItem, PreviewSwitchButton, RoundColorElement,
  SelectCardButton, SelectCardButtonActive, SelectCardButtonContainerMobile, SelectCardButtonInactive, SelectCardContainer, SelectCardContainerMobile,
  SelectCardValueTooltip, SelectPlaceholderButton, SetBackgroundImageDialogContent, SubmenuButton, SubmenuContainer, SubmenuContainerMobile, SubmenuCopyListMenuItem,
  SubmenuExtendedContainerMobile, SubmenuPlaceholdersMenuItem, SubmitButton, ToggleContainer, ActionButtonContainer
} from './InvitationDesignPage.styled';

type InvitationDesignProps = {
  isTemplate?: boolean,
  invitationTemplateStatus?: string,
  setInvitationTemplateStatus?: (invitatioNTemplateStatus: string) => void,
  saveInvitationTemplate?: () => void,
}

type Font = {
  fontFamily: string
  fill: string
  fontSize: number
  fontWeight: string
  fontStyle: string
  underline: boolean
  textAlign: string
  lineHeight: number
  charSpacing: number
}

export const InvitationDesignPage = ({ isTemplate = false, invitationTemplateStatus, setInvitationTemplateStatus, saveInvitationTemplate }: InvitationDesignProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const navLocation = useLocation();
  const params = useParams();
  const createEviteFormikRef = useRef(null);

  const { invitationDetails, setInvitationDetails }: InvitationContextType = useInvitation();
  const [ getInvitation, { refetch: invitationRefetch, data: invitationData }] = graphLazyQueryMiddleware(isTemplate ? GET_INVITATION_TEMPLATE_BY_ID : GET_INVITATION_BY_ID);
  const [ getFonts, {refetch: fontsRefetch, data: fontsData}] = graphLazyQueryMiddleware(GET_FONTS);
  const [ getPlaceholders, {data: placeholderData}] = graphLazyQueryMiddleware(GET_PLACEHOLDERS);
  const [addEvite] = graphMutationMiddleware(ADD_EVITE_TEMPLATE);
  const [removeEvite] = graphMutationMiddleware(REMOVE_EVITE_TEMPLATE);
  const [ updateEvite, { loading: updateEviteLoading }] = graphMutationMiddleware(isTemplate ? UPDATE_EVITE_TEMPLATE : UPDATE_EVITE);
  const [updateSelectedEviteTemplates] = graphMutationMiddleware(UPDATE_SELECTED_EVITE_TEMPLATES);
  const [ replacePlaceholderValues, { loading: replacePlaceholdersLoading }] = graphMutationMiddleware(REPLACE_PLACEHOLDER_VALUES);
  const [ replacePlaceholderDefaultValues, { loading: replaceDefaultPlaceholdersLoading }] = graphMutationMiddleware(REPLACE_PLACEHOLDER_DEFAULT_VALUES);

  const [ activeEviteId, setActiveEviteId ] = useState(null);
  const [ previousEvite, setPreviousEvite ] = useState<EviteType>(null);
  const [ invitation, setInvitation ] = useState(null);
  const [invitationType] = useState(isTemplate ? 'invitationTemplate' : 'invitation');
  const [addEviteMutationName] = useState(isTemplate ? 'addEviteTemplate' : 'addEvite');
  const [ isImage, setIsImage ] = useState(false);

  const [ canvas, setCanvas ] = useState(null);
  const [ canvasForResultImage, setCanvasForResultImage ] = useState(null);
  const [ showCanvasPreview, setShowCanvasPreview ] = useState(false);
  const [ canvasPreviewImage, setCanvasPreviewImage ] = useState(null);
  const [ isSavingCanvas, setIsSavingCanvas ] = useState(false);
  const [ canvasIsChanged, setCanvasIsChanged ] = useState(false);
  const [ addBackgroundImageModal, setAddBackgroundImageModal ] = useState<boolean>(false);
  const [ addFontModal, setAddFontModal ] = useState(false);
  const [ canvasScaleFactor, setCanvasScaleFactor ]= useState(1);
  const [ font, setFont ] = useState<Font>({
    fontFamily: '',
    fill: '#000000',
    fontSize: fontSize[5],
    fontWeight: 'normal',
    fontStyle: 'normal',
    underline: false,
    textAlign: 'center',
    lineHeight: FONT_MIN_VALUE,
    charSpacing: 50
  });
  const [ showColorPicker, setShowColorPicker ] = useState(false);
  const [ isOpenedCardMenu, setIsOpenedCardMenu ] = useState<boolean>(false);
  const [ isEviteInInvitaion, setIsEviteInInvitaion ] = useState(false);
  const [ cardInputName, setCardInputName ] = useState('');
  const [ cardModal, setCardModal ] = useState(false);
  const [ removeCardModal, setRemoveCardModal ] = useState(false);
  const [ removeCardId, setRemoveCardId ] = useState<number|null>(null);
  const [ isBackgroundColor, setIsBackgroundColor ] = useState(false);
  const [ isTextSelected, setIsTextSelected ] = useState(false);
  const [ isImageSelected, setIsImageSelected ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ imageLoading, setImageLoading ] = useState(false);
  const [ fontLoading, setFontLoading ] = useState(false);
  const openBackgroundMenu = Boolean(anchorEl);
  const [historyDict] = useState<any>({});
  const [ placeholderEl, setPlaceholderEl ] = useState<null | HTMLElement>(null);
  const isOpenPlaceholderMenu = Boolean(placeholderEl);
  const [ copyDesignEl, setCopyDesignEl ] = useState<null | HTMLElement>(null);
  const isOpenCopyDesignMenu = Boolean(copyDesignEl);
  const [ selectedEviteForCopyDesign, setSelectedEviteForCopyDesign ] = useState(null);
  const [ isUndoDisabled, setIsUndoDisabled ] = useState(true);
  const [ isRedoDisabled, setIsRedoDisabled ] = useState(true);
  const [ draggedEvite, setDraggedEvite ] = useState<EviteType | null>(null);
  const [ moreOption, setMoreOption ] = useState(false);

  const [ canvasSize, setCanvasSize ] = useState({width: 0, height: 0});
  const [ canvasSizeChanged, setCanvasSizeChanged ] = useState(false);
  const [ isOpenCanvasResizeModal, setIsOpenCanvasResizeModal ] = useState(false);
  const [ confirmCopyDesignModal, setConfirmCopyDesignModal ] = useState(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ showKeyboardForCardName, setShowKeyboardForCardName ] = useState(false);
  const [ selectedObject, setSelectedObject ] = useState(null);
  const [ selectedKey, setSelectedKey ] = useState(null);
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ canvasTextboxMap, setCanvasTextboxMap ] = useState({});
  const [ loadingCanvas, setLoadingCanvas ] = useState(true);
  const [ isInitLoadHistory, setIsInitLoadHistory ] = useState(true);
  const [ isSubmittingCardName, setIsSubmittingCardName ] = useState(false);
  const [ isSaveDisabled, setIsSaveDisabled ] = useState(false);

  const initialCanvasState = useRef('');
  const keyboardRef = useRef(null);
  const canvasRef = useRef(null);
  const fontsRef = useRef(null);
  const colorHexRef = useRef(null);
  const canvasContainerRef = useRef(null);

  const createEviteSchema = Yup.object().shape({
    cardName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('eviteName'), length: VALIDATION_RESTRICTION.HUNDRED })),
  });

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, element: string) => {
    switch (element) {
      case 'background':
        setAnchorEl(e.currentTarget);
        return;

      case 'placeholder':
        setPlaceholderEl(e.currentTarget);
        return;

      case 'design':
        setCopyDesignEl(e.currentTarget);
        return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPlaceholderEl(null);
    setCopyDesignEl(null);
  };

  const checkForData = () => {
    if (!invitationData) {
      return;
    }

    if (!(invitationType in invitationData)) {
      showToast('error', t('invalidId'));
      handleNavigate('/invitationTemplates');
      return;
    }

    setInvitation(invitationData[invitationType]);
    if (invitationType === 'invitation') {
      setInvitationDetails({...invitationData.invitation});
    } else {
      setInvitationDetails(invitationData[invitationType]);
    }
  };

  const getInv = () => {
    if (params.id) {
      getInvitation({
        fetchPolicy: 'network-only',
        variables: {
          id: Number(params.id)
        }
      }).then(res => {
        if (res.data[invitationType].evites.length > 0 && res.data[invitationType].evites[0].name !== 'Evite') {
          setIsEviteInInvitaion(true);
          const activeEviteIdTemp = isTemplate ? res.data[invitationType].evites[0].id : res.data[invitationType].evites.find((e: EviteType) => e.isSelected === true)?.id;
          setActiveEviteId(activeEviteIdTemp);
        }
      }).catch(() => {
        setInvitation(null);
      });
    }
  };

  useEffect(() => {
    getInv();
  }, [params.id]);

  useEffect(() => {
    showKeyboardForCardName && setShowKeyboard(false);
  }, [showKeyboardForCardName]);

  useEffect(() => {
    isHebrew && !isMobile && mapTextboxs();
  }, [isHebrew]);

  const renameCard = () => {
    const inputData = {
      id: invitationData[invitationType].evites[0].id,
      name: createEviteFormikRef.current.values.cardName,
      contentJson: invitationData[invitationType].evites[0].contentJson,
      isDefault: true,
      position: 0,
      templateId: Number(params.id),
    };
    updateEvite({variables: {
      input: inputData
    }}).then(() => {
      setIsEviteInInvitaion(true);
      setCardModal(false);
      createEviteFormikRef.current = '';
      invitationRefetch({
        variables: {
          id: Number(params.id)
        }
      }).then((result) => {
        setActiveEviteId(result.data[invitationType].evites[result.data[invitationType].evites.length-1].id);
      });
    });
  };

  const createCard = () => {
    setIsSubmittingCardName(true);
    addEvite({variables: {
      input: {
        name: createEviteFormikRef.current.values.cardName,
        contentJson: '{}',
        isDefault: true,
        position: 0,
        templateId: Number(params.id)
      }
    }}).then((res) => {
      setActiveEviteId(res.data[addEviteMutationName].id);
      setIsEviteInInvitaion(true);
      setCardModal(false);
      createEviteFormikRef.current = '';
      invitationRefetch({
        variables: {
          id: Number(params.id)
        }
      }).then((result) => {
        setActiveEviteId(result.data[invitationType].evites[result.data[invitationType].evites.length-1].id);
        setIsSubmittingCardName(false);
      }).catch(() => setIsSubmittingCardName(false));
    }).catch(() => setIsSubmittingCardName(false));
  };

  const removeCard = () => {
    setRemoveCardModal(false);
    removeEvite({variables: {
      id: removeCardId
    }}).then(() => {
      delete historyDict[removeCardId];
      invitationRefetch({id: Number(params.id)})
        .then(result => {
          const activeEviteIdTemp = isTemplate ? result.data.invitationTemplate.evites[0].id : result.data.invitationTemplate.evites.find((e: EviteType) => e.isSelected === true)?.id;
          setActiveEviteId(activeEviteIdTemp);
        });
    });
  };

  useEffect(() => {
    checkForData();
  }, [invitationData]);

  useEffect(() => {
    invitation && getPlaceholders();
  }, [invitation]);

  useEffect(() => {
    if (canvas) {
      canvas.extraProps.push('width');
      canvas.extraProps.push('height');
      canvas.extraProps.push('viewportTransform');

      canvas.on('selection:updated', handleElement);
      canvas.on('selection:created', handleElement);
      canvas.on('selection:cleared', () => {
        setIsTextSelected(false);
        setIsImageSelected(false);
        setShowKeyboard(false);
        setShowColorPicker(false);
      });
      canvas.on('history:append', historyAppend);
      canvas.on('history:undo', checkHistory);
      canvas.on('history:redo', checkHistory);

      canvasRef.current = canvas;

      initCenteringGuidelines(canvas, canvasSize.width, canvasSize.height);
      initAligningGuidelines(canvas);
    }
  }, [canvas]);

  useEffect(() => {
    if (!isMobile && isHebrew) {
      for (const key of Object.keys(canvasTextboxMap)) {
        if ( (canvasTextboxMap as any)[key]?.top === selectedObject?.selected[0]?.top && (canvasTextboxMap as any)[key]?.left === selectedObject?.selected[0]?.left && (canvasTextboxMap as any)[key]?.text === selectedObject?.selected[0]?.text) {
          setSelectedKey(key);
          break;
        }
      }
    }
  }, [ selectedObject, canvasTextboxMap ]);

  const deleteSelectedObjects = (deleteSelectedObject: boolean) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject.type === 'activeSelection') {
      activeObject.forEachObject((obj: any) => canvas.remove(obj));
    } else if (!deleteSelectedObject && activeObject.type === 'textbox' && activeObject?.isEditing) {
      return;
    } else {
      activeObject && canvas.remove(activeObject);
    }
    !isMobile && isHebrew && mapTextboxs();
    setIsImageSelected(false);
    setIsTextSelected(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    !isMobile && isHebrew && mapTextboxs();
    if (e.keyCode === 90 && e.ctrlKey) {
      undo();
    } else if (e.keyCode === 89 && e.ctrlKey) {
      redo();
    } else if (e.keyCode === 46) {
      deleteSelectedObjects(false);
    } else if (e.keyCode === 83 && e.ctrlKey) {
      e.preventDefault();
      saveCanvas(true);
    }
  };

  const renderTextFromKeyboard = () => {
    canvas?.renderAll();
  };

  const mapTextboxs = () => {
    const textboxMap : any = {};
    for (let i = 0; i < canvas._objects.length; i++) {
      if (canvas._objects[i].type === 'textbox') {
        const a = i.toString();
        textboxMap[a] = canvas._objects[i];
      }
    }
    setCanvasTextboxMap(textboxMap);
  };

  const addTextboxToMap = (textbox: any) => {
    const iterator = Object.keys(canvasTextboxMap).length+1;
    const textboxMap : any = {
      ...canvasTextboxMap
    };
    const a = iterator.toString();
    textboxMap[a] = textbox;
    setCanvasTextboxMap(textboxMap);
  };

  const handleColorPickerClicked = (isOpened: boolean) => {
    if (isOpened) {
      document.addEventListener('click', handleOutsideColorPickerClick, false);
    } else {
      document.removeEventListener('click', handleOutsideColorPickerClick, false);
    }
  };

  const handleOutsideColorPickerClick = (e: any) => {
    if (document.getElementById(EDITOR_CONTAINER_ELEMENTS_IDS.FONT_COLOR_PICKER)?.contains(e.target)
      || document.getElementById(EDITOR_CONTAINER_ELEMENTS_IDS.COLOR_PICKER_BUTTON)?.contains(e.target)
      || document.getElementById(SLIDER_ELEMENTS_IDS.OUTLINED_BASIC)?.contains(e.target)) {
      return;
    }

    !isMobile && setShowColorPicker(false);
    handleColorPickerClicked(false);
  };

  useEffect(() => {
    handleColorPickerClicked(showColorPicker);
  }, [showColorPicker]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [ handleKeyPress, document ]);

  const initCanvas = (id: string) => new fabric.Canvas(id, {});

  const registerFont = (fontName: string, url: string) => {
    const fontFace = new FontFace(fontName, `url(${url})`);
    (document as any).fonts.add(fontFace);
    fontFace.load();
  };

  useEffect(() => {
    setCanvas(initCanvas('canvasMain'));
    setCanvasForResultImage(initCanvas('canvasImage'));
    getFonts().then(res => {
      res?.data?.fonts.filter((f: FontType) => f.ttfUrl).forEach((f: FontType) => {
        registerFont(f.name, f.ttfUrl);
      });
    });
    return () => !isMobile && document.removeEventListener('mousedown', returnFocusToSelectedTextbox, false);
  }, []);

  useEffect(() => {
    setFont({
      ...font,
      fontFamily: fontsData?.fonts[0].name,
    });
    fontsRef.current = fontsData?.fonts;
  }, [fontsData]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (cardModal && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
        e.preventDefault();
        if (!updateEviteLoading && !isSubmittingCardName) {
          isEviteInInvitaion ? createCard() : renameCard();
        }
      }
    };
    if (isSubmittingCardName) {
      createEviteFormikRef.current = '';
      document.removeEventListener('keydown', listener);
    } else {
      createEviteFormikRef.current = '';
      document.addEventListener('keydown', listener);
    }
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isSubmittingCardName]);

  const setCanvasObjProperties = (obj: fabric.Object) => {
    if (obj instanceof fabric.Textbox) {
      obj.setControlsVisibility({
        mt: false,
        mb: false
      });
    }
    obj.selectable = true;
  };

  const addImage = (fileUrl: string) => {
    setIsImage(false);
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = fileUrl;
    image.onload = function() {
      const fabricImage = new fabric.Image(image);
      const ls = Math.random() * (500 - 0) + 0;
      const ts = Math.random() * (500 - 0) + 0;
      fabricImage.scale(0.2);
      fabricImage.set({ 'left': ls });
      fabricImage.set({ 'top': ts });
      canvas.discardActiveObject();
      canvas.add(fabricImage);
      canvas.setActiveObject(fabricImage);
      setImageLoading(false);
    };
  };

  const handleSetBackground = (fileUrl: string) => {
    setImageLoading(true);
    if (isImage) {
      addImage(fileUrl);
      return;
    }

    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = fileUrl;
    image.onload = function() {
      const fabricImage = new fabric.Image(image);
      setInitCanvasSize(fabricImage.width, fabricImage.height);

      canvas.setBackgroundImage(fabricImage, () => {
        canvas.renderAll();
        setImageLoading(false);
        canvas._historySaveAction();
      });
    };
  };

  const handleSetBackgroundColor = (color: string) => {
    canvas.setBackgroundImage(0);
    canvas.backgroundColor = color;
    canvas.renderAll();
    canvas._historySaveAction();
  };

  const addText = () => {
    setIsOpenedCardMenu(false);
    const textObjects = canvas.getObjects().filter((o: any) => o.get('type') === 'textbox');
    const maxTopValue = Math.max(...textObjects.map((o: any) => o.get('top')));

    const newTextBoxTop = (maxTopValue + font.fontSize * 2) > canvas.height / canvasScaleFactor ?
      Math.random() * canvas.height / canvasScaleFactor :
      textObjects.length * font.fontSize * 2 + font.fontSize;

    const textbox = new fabric.Textbox('', {
      width: (canvas.width / canvasScaleFactor) / 2,
      height: 100 / canvasScaleFactor,
      left: (canvas.width / canvasScaleFactor) / 4,
      top: newTextBoxTop,
      fill: font.fill,
      fontSize: font.fontSize,
      fontFamily: font.fontFamily,
      textAlign: font.textAlign,
      lineHeight: font.lineHeight,
      charSpacing: font.charSpacing,
    });

    setCanvasObjProperties(textbox);
    canvas.add(textbox);
    canvas.setActiveObject(textbox);
    textbox.enterEditing();
    textbox.hiddenTextarea.focus();
    !isMobile && isHebrew && addTextboxToMap(textbox);
  };

  const addFont = (fileUrl: string) => {
    setFontLoading(true);
    fontsRefetch().then(res => {
      const newFont = res?.data?.fonts.find((f: FontType) => f.ttfUrl === fileUrl);
      newFont && registerFont(newFont.name, newFont.ttfUrl);
      setFontLoading(false);
    });
  };

  const renderFont = (styleType: string, value: string | number | number[] | boolean) => {
    setFont({...font, [styleType]: value});
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject?.type === 'image') {
      return;
    }

    const style = {[styleType]: value};

    if (activeObject.isEditing &&
      activeObject.getSelectedText().length !== activeObject.text?.length &&
      activeObject.getSelectedText().length !== 0) {
      activeObject.setSelectionStyles(style);
    } else {
      activeObject.setSelectionStyles(style, 0, activeObject.text?.length);
      activeObject.set(styleType, value);
    }
    isMobile && resetTextSelection(canvas);
    canvas.renderAll();
  };

  const changeFont = (styleType: string, value: string | number | number[] | boolean) => {
    if (isMobile) {
      delay(1).then(() => {
        renderFont(styleType, value);
      });
    } else {
      renderFont(styleType, value);
    }
    canvas._historySaveAction();
  };

  const closeAddBackgroundImageModal = () => {
    setAddBackgroundImageModal(false);
  };

  const handleElement = (obj: any) => {
    if (obj.selected.length > 1) {
      setIsImageSelected(true);
      setIsTextSelected(true);
    }
    !isMobile && document.addEventListener('mousedown', returnFocusToSelectedTextbox, false);
    if (obj.selected[0]?.type === 'image') {
      setIsImageSelected(true);
    }
    if (obj.selected[0]?.type === 'textbox') {
      setIsTextSelected(obj.selected.length === 1);
      if (localStorage.getItem('isHebrew') === 'true') {
        setIsHebrew(true);
        setShowKeyboard(true);
        setSelectedObject(obj);
      } else {
        setIsHebrew(false);
      }
      setIsOpenedCardMenu(false);
      setFont({
        ...font,
        fontFamily: obj.selected[0].fontFamily,
        fill: obj.selected[0].fill,
        fontSize: obj.selected[0].fontSize,
        fontWeight: obj.selected[0].fontWeight,
        fontStyle: obj.selected[0].fontStyle,
        underline: obj.selected[0].underline,
        textAlign: obj.selected[0].textAlign,
        lineHeight: obj.selected[0].lineHeight,
        charSpacing: obj.selected[0].charSpacing
      });
    }
  };

  const setInitCanvasSize = (width: number | null, height: number | null) => {
    if (width && height) {
      setCanvasSize({width: width, height: height});
    } else {
      setCanvasSize({width: DEFAULT_EVITE_SIZE.WIDTH, height: DEFAULT_EVITE_SIZE.HEIGHT});
    }
  };

  const returnFocus = (toRef: boolean) => {
    if (isMobile) {
      return;
    }
    if (toRef) {
      delay(100).then(() => {
        canvasRef.current.getActiveObject()?.hiddenTextarea.focus();
      });
    } else {
      delay(100).then(() => {
        canvas.getActiveObject()?.hiddenTextarea.focus();
        canvas.renderAll();
      });
    }
  };

  const handleClickOnEditorContainer = (target: HTMLElement) : boolean => {
    if (!document.getElementById('editorContainer')?.contains(target)) {
      return false;
    }

    if (checkIfElementsContainsElement(Object.values(EDITOR_CONTAINER_ELEMENTS_IDS), target)) {
      returnFocus(true);
      return true;
    }

    if (checkIfElementsContainsElement(Object.values(SLIDER_ELEMENTS_IDS), target)) {
      return true;
    }

    return false;
  };

  const ifClickedCanvasElement = (target: HTMLElement) : boolean => {
    return canvasRef.current.getActiveObject()?.type !== 'textbox' ||
           fontsRef.current.some((item: any) => checkIfElementContainsElement('fontItem' + item.name, target)) ||
           document.getElementsByClassName('canvas-container')[0].contains(target) ||
           checkIfElementsContainsElement(Object.values(CANVAS_ELEMENTS_IDS), target);
  };

  const returnFocusToSelectedTextbox = (e: any) => {
    if (ifClickedCanvasElement(e.target) || handleClickOnEditorContainer(e.target)) {
      return;
    }

    if (checkIfElementContainsElement('fontSelect', e.target)) {
      returnFocus(true);
      return;
    }
    canvasRef.current.getActiveObject().exitEditing();
    canvasRef.current.renderAll();
  };

  const changeFontSize = (e: SelectChangeEvent<number>) => {
    changeFont(FONT_PROPERTY.FONT_SIZE, e.target.value);
  };

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const canvasDiv = document.getElementById('canvasContainer');

    const availableWidth = canvasDiv.clientWidth;
    const availableHeight = canvasDiv.clientHeight;

    const widthScale = availableWidth / canvasSize.width;
    const heightScale = availableHeight / canvasSize.height;
    const scale = Math.min(widthScale, heightScale);

    canvas.setWidth(canvasSize.width * scale);
    canvas.setHeight(canvasSize.height * scale);
    setCanvasScaleFactor(scale);
  }, [canvasSize]);

  useEffect(() => {
    setFont({
      ...font,
      fontSize: findDefaultFontValue(fontSize, canvasScaleFactor),
    });

    canvas?.zoomToPoint(new fabric.Point(0, 0), canvasScaleFactor);
    canvas?.calcOffset();
    canvas?.renderAll();
    if (canvas && isInitLoadHistory) {
      setIsInitLoadHistory(false);
      canvas.historyNextState = canvas?._historyNext();
    }
  }, [canvasScaleFactor]);

  useEffect(() => {
    if (!activeEviteId) {
      return;
    }

    if (invitation?.evites?.find((ev: EviteType) => ev.id === activeEviteId)) {
      loadCanvasFromJson(invitation?.evites?.find((ev: EviteType) => ev.id === activeEviteId));
    } else {
      invitationRefetch({id: Number(params.id)})
        .then(result => {
          loadCanvasFromJson(result.data[invitationType].evites.find((ev: EviteType) => ev.id === activeEviteId));
        });
    }
  }, [activeEviteId]);

  useEffect(() => {
    if (invitationTemplateStatus) {
      saveCanvas();
      saveInvitationTemplate();
      setInvitationTemplateStatus(undefined);
    }
  }, [invitationTemplateStatus]);

  const loadCanvasFromJson = (targetEvite: EviteType) => {
    if (canvas && previousEvite) {
      saveCanvas();
    }

    setPreviousEvite(targetEvite);
    canvas?.clear();
    const targetHistory = historyDict[targetEvite.id];
    const contentString = targetHistory?.lastState ?? targetEvite?.contentJson;
    initialCanvasState.current = contentString;
    setCanvasIsChanged(false);

    if (contentString && contentString !== '{}') {
      const contentJson = JSON.parse(contentString);

      fabric.Image.fromURL(contentJson.backgroundImage?.src, img => {
        canvas.loadFromJSON(contentJson, () => {
          if (contentJson.width && contentJson.height && contentJson.viewportTransform) {
            setInitCanvasSize(contentJson.width / contentJson.viewportTransform[0], contentJson.height / contentJson.viewportTransform[3]);
          } else {
            setInitCanvasSize(img.width, img.height);
          }

          canvas.clearHistory();
          if (targetHistory) {
            canvas.historyUndo = targetHistory.historyUndo ?? [];
            canvas.historyRedo = targetHistory.historyRedo ?? [];
          }

          checkHistory();
          canvas.historyNextState = canvas?._historyNext();
          canvas.fire('history:append', { json: canvas.historyNextState});
          setLoadingCanvas(false);
        });
      });

      if (showCanvasPreview && !loadingCanvas) {
        setShowCanvasPreview(false);
        setCanvasPreviewImage(null);
      }
    }
  };

  const checkHistory = () => {
    setIsUndoDisabled(!canvas?.historyUndo || canvas.historyUndo.length === 0);
    setIsRedoDisabled(!canvas?.historyRedo || canvas.historyRedo.length === 0);
    setIsSaveDisabled(false);

    const parsedInitialCanvasState = JSON.parse(initialCanvasState.current);
    const parsedCurrentCanvasState = JSON.parse(getCurrentCanvasState());

    delete parsedInitialCanvasState.width;
    delete parsedInitialCanvasState.height;
    delete parsedInitialCanvasState.viewportTransform;

    delete parsedCurrentCanvasState.width;
    delete parsedCurrentCanvasState.height;
    delete parsedCurrentCanvasState.viewportTransform;
    setCanvasIsChanged(JSON.stringify(parsedInitialCanvasState) !== JSON.stringify(parsedCurrentCanvasState));
    canvas.getObjects().forEach((obj: fabric.Object) => setCanvasObjProperties(obj));
  };

  const historyAppend = () => {
    if (canvas) {
      canvas.historyRedo = [];
    }
    checkHistory();
  };

  const getCurrentCanvasState = () => {
    return canvas?._historyNext();
  };

  const setPlaceholder = (field: string) => {
    const textBox = canvas.getActiveObject();
    if (!textBox || textBox.get('type') !== 'textbox') {
      return;
    }

    const textToAppend = field;
    textBox.insertChars(textToAppend, null, textBox.selectionStart, textBox.selectionEnd);
    const textLength = textBox.selectionStart + textToAppend.length;
    canvas.renderAll();

    canvas.discardActiveObject();
    canvas.setActiveObject(textBox);
    returnFocus(false);
    textBox.setSelectionStart(textLength);
    textBox.setSelectionEnd(textLength);
  };

  const getPreviousEviteInputParams = (jsonData: string) => {
    return isTemplate ? {
      id: previousEvite.id,
      name: previousEvite.name,
      contentJson: jsonData,
      isDefault: previousEvite.isDefault,
      position: previousEvite.position,
      templateId: Number(params.id),
    } : {
      id: previousEvite.id,
      name: previousEvite.name,
      contentJson: jsonData,
      isDefault: previousEvite.isDefault,
      position: previousEvite.position,
      templateId: Number(params.id),
      isSelected: previousEvite.isSelected,
      requestRsvp: previousEvite.requestRsvp,
    };
  };

  const saveEviteChanges = (contentJson: string) => {
    const inputData = getPreviousEviteInputParams(contentJson);
    updateEvite({variables: {
      input: inputData
    }}).then(() => {
      invitationRefetch({id: Number(params.id)});
      setIsSavingCanvas(false);
    }).catch(() => {
      setIsSavingCanvas(false);
    });
  };

  const saveCanvas = async (waitToSave = false) => {
    if (!canvasIsChanged && !canvasSizeChanged) {
      return;
    }

    setIsSavingCanvas(waitToSave && true);
    const jsonData = getCurrentCanvasState();
    historyDict[previousEvite.id] = {
      'historyUndo': [...canvas.historyUndo],
      'historyRedo': [...canvas.historyRedo],
      'lastState': jsonData
    };

    initialCanvasState.current = jsonData;
    setCanvasIsChanged(false);
    setCanvasSizeChanged(false);
    saveEviteChanges(jsonData);
  };

  const updateEviteTemplates = () => {
    const invitationEviteTemplatesCopy = invitation.evites?.map((evite: EviteType) => {
      return {
        id: evite.id,
        isDefault: evite.isDefault,
        position: invitation.evites.findIndex((e: EviteType) => e.id === evite.id),
      };
    });

    updateSelectedEviteTemplates({variables: {
      input: {
        eviteTemplates: invitationEviteTemplatesCopy
      }
    }}).then(() => {
      invitationRefetch({id: Number(params.id)});
    });
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, evite: EviteType) => {
    setDraggedEvite(evite);
  };

  const onDragOver = (evite: EviteType) => {
    if (draggedEvite.id === evite.id) {
      return;
    }
    const invitationCopy = {...invitation};
    invitationCopy.evites = invitation.evites.filter((e: EviteType) => e.id !== draggedEvite.id);
    invitationCopy.evites.splice(invitation.evites.findIndex((e: EviteType) => e.id === evite.id), 0, draggedEvite);

    setInvitation(invitationCopy);
  };

  const onDragEnd = () => {
    setDraggedEvite(null);
    updateEviteTemplates();
  };

  const handleCopyDesignFrom = (defaultEvite: EviteType) => {
    canvas.historyUndo.push(canvas.historyNextState);
    const contentJson = JSON.parse(defaultEvite.contentJson);

    canvas.loadFromJSON(contentJson, () => {
      if (contentJson.width && contentJson.height && contentJson.viewportTransform) {
        setInitCanvasSize(contentJson.width / contentJson.viewportTransform[0], contentJson.height / contentJson.viewportTransform[3]);
      }

      canvas.renderAll();
      canvas.historyNextState = getCurrentCanvasState();
      canvas.fire('history:append', { json: canvas.historyNextState});
      setShowCanvasPreview(false);
    });
  };

  const isCanvasEmpty = () => {
    const blankCanvas = initCanvas('canvasEmpty');

    blankCanvas.width = canvas.width;
    blankCanvas.height = canvas.height;

    return JSON.stringify(canvas.toJSON()) === JSON.stringify(blankCanvas.toJSON());
  };

  const showCanvasPreviewImage = async (showPreview: boolean) => {
    const jsonData = getCurrentCanvasState();
    if (showPreview) {
      if (isTemplate) {
        await replacePlaceholderDefaultValues({variables: {
          contentInput: jsonData
        }}).then((resReplacePlaceholders: any) => {
          const contentJson = resReplacePlaceholders.data.replacePlaceholderDefaultValues.split('\n').join('\\n');
          fabric.Image.fromURL(contentJson.backgroundImage?.src, () => {
            canvasForResultImage.loadFromJSON(contentJson, () => {
              setCanvasPreviewImage(canvasForResultImage.toDataURL());
            });
          });
        }).catch(() => {
          setIsSavingCanvas(false);
        });
      } else {
        await replacePlaceholderValues({variables: {
          contentInput: jsonData,
          invitationId: Number(params.id)
        }})
          .then((resReplacePlaceholders: any) => {
            const contentJson = resReplacePlaceholders.data.replacePlaceholderValues.split('\n').join('\\n');
            fabric.Image.fromURL(contentJson.backgroundImage?.src, () => {
              canvasForResultImage.loadFromJSON(contentJson, () => {
                setCanvasPreviewImage(canvasForResultImage.toDataURL());
              });
            });
          })
          .catch(() => {
            setIsSavingCanvas(false);
          });
      }
    } else {
      setCanvasPreviewImage(null);
    }
  };

  const changeTab = async (tabName: string) => {
    await saveCanvas();
    handleNavigate(getRouteForNavigation(navLocation.pathname, tabName, params['*']));
  };

  const setHistoryCanvasSize = () => {
    const contentJson = JSON.parse(canvas.historyNextState);
    if (!contentJson.width || !contentJson.height || !contentJson.viewportTransform) {
      return;
    }
    setInitCanvasSize(contentJson.width / contentJson.viewportTransform[0], contentJson.height / contentJson.viewportTransform[3]);
  };

  const disableButtons = () => {
    setIsUndoDisabled(true);
    setIsRedoDisabled(true);
    setIsSaveDisabled(true);
  };

  const undo = () => {
    disableButtons();
    canvas.undo();
    setHistoryCanvasSize();
  };

  const redo = () => {
    disableButtons();
    canvas.redo();
    setHistoryCanvasSize();
  };

  const getTransformPropertyForRoundColorElement = () => {
    return isIOS ? 'translateY(-8%)' : 'translateY(-50%)';
  };

  const getTransformPropertyForIconElement = () => {
    return isIOS ? 'translateY(-18%)' : 'translateY(-50%)';
  };

  return (
    <>
      <PageContent>
        {isOpenedCardMenu ?
          <SelectCardContainer>
            {invitation?.evites && invitation.evites.map((evite: EviteType) => (
              (isTemplate || !isTemplate && evite.isSelected) &&
                <ListItemButton
                  key={evite.id}
                  component={evite.id === activeEviteId ? SelectCardButtonActive : SelectCardButtonInactive}
                  onClick={() => {
                    setIsOpenedCardMenu(false);
                    setActiveEviteId(evite.id);
                  }}
                  onDragOver={() => isTemplate && onDragOver(evite)}>
                  <CardButtonContent
                    key={evite.id}
                    draggable
                    onDragStart={(e) => isTemplate && onDragStart(e, evite)} onDragEnd={() => isTemplate && onDragEnd()}>
                    <ListItemText>
                      <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={evite.name} />
                    </ListItemText>
                    {isTemplate && invitation?.evites.length > 1 && <DeleteIcon onClick={(e) => {
                      e.stopPropagation();
                      setRemoveCardModal(true);
                      setRemoveCardId(evite.id);
                    }} />}
                  </CardButtonContent>
                </ListItemButton>
            ))}
            {
              isTemplate &&
              <AddNewText disabled={isSubmittingCardName} onClick={(e) => {
                if (e.type === 'keydown') {
                  return;
                }
                setCardModal(true);
                setShowKeyboard(false);
              }}>
                <ListItemText primary={t('addNewEvite')} />
              </AddNewText>
            }
          </SelectCardContainer>:
          <EditorContainer id='editorContainer'>
            <SelectCardButton>
              <ListItemText>
                <CustomTooltip
                  theme={themePublic.selectCardButtonTooltip}
                  text={invitation && invitation.evites.map((item: EviteType) => item.id === activeEviteId && item.name)} />
              </ListItemText>
              <MenuIcon onClick={() => setIsOpenedCardMenu(true)}/>
            </SelectCardButton>
            <EditorElementDesignPage>
              <div>
                <FontEditorNameDesignPage>{t('font')}</FontEditorNameDesignPage>
                <AddFontLabelDesignPage onClick={() => setAddFontModal(true)} >+ {t('addFont')}</AddFontLabelDesignPage>
              </div>
              <FormControl sx={{ minWidth: 120, width: '100%' }}>
                <FormControlSelect
                  id='fontSelect'
                  value={font && font.fontFamily}
                  onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => {
                    changeFont(FONT_PROPERTY.FONT_FAMILY, e.target.value);
                    returnFocus(false);
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  renderValue={() => <SelectCardValueTooltip> {font?.fontFamily} </SelectCardValueTooltip>} >
                  {fontsData?.fonts.map((item: FontType) =>
                    <FontMenuItem id={'fontItem' + item.name} key={item.name} value={item.name}>
                      <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={`${item.name}`} />
                    </FontMenuItem>)}
                </FormControlSelect>
              </FormControl>
            </EditorElementDesignPage>
            <EditorElementDesignPage>
              <EditorNameLabel>{t('textColor')}</EditorNameLabel>
              <RoundColorElement
                id={EDITOR_CONTAINER_ELEMENTS_IDS.COLOR_PICKER_BUTTON}
                onClick={() => setShowColorPicker(!showColorPicker)}
                style={{ backgroundColor: font.fill, transform: getTransformPropertyForRoundColorElement() } as CSSProperties} />
              <ColorTextField
                id={SLIDER_ELEMENTS_IDS.OUTLINED_BASIC}
                variant='outlined'
                value={font.fill}
                style={{ transform: getTransformPropertyForIconElement()} as CSSProperties}
                onChange={(e) => changeFont(FONT_PROPERTY.FILL, e.target.value)} />
              {
                showColorPicker &&
                <ImagePickerDesignPage id={EDITOR_CONTAINER_ELEMENTS_IDS.FONT_COLOR_PICKER}>
                  <HexColorPicker
                    color={font.fill}
                    onMouseUp={() => colorHexRef.current && changeFont(FONT_PROPERTY.FILL, colorHexRef.current)}
                    onChange={(newColor) => colorHexRef.current = newColor } />
                </ImagePickerDesignPage>
              }
            </EditorElementDesignPage>
            <EditorElementDesignPage>
              <ValueAndSliderInput
                sliderId={SLIDER_ELEMENTS_IDS.TEXT_SIZE_SLIDER}
                returnFocus={returnFocus}
                selectAndRemoveFocus={selectAndRemoveFocus}
                isMobile={false}
                font={font} callback={changeFont}
                title={t('textSize')}
                valueType={FONT_PROPERTY.FONT_SIZE}
                maxValue={100 / canvasScaleFactor}
                canvas={canvas} />
            </EditorElementDesignPage>
            <EditorElementDesignPage>
              <EditorNameLabel>{t('textStyle')}</EditorNameLabel>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.BOLD_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.fontWeight === 'bold' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.FONT_WEIGHT, font.fontWeight === 'normal' ? 'bold' : 'normal')}>
                <FormatBoldIcon />
              </IconButtonStyled>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.ITALIC_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.fontStyle === 'italic' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.FONT_STYLE, font.fontStyle === 'normal' ? 'italic' : 'normal')}>
                <FormatItalicIcon />
              </IconButtonStyled>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.UNDERLINE_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.underline && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.UNDERLINE, !font.underline)} >
                <FormatUnderlinedIcon />
              </IconButtonStyled>
            </EditorElementDesignPage>
            <EditorElementDesignPage>
              <EditorNameLabel>{t('textAlignment')}</EditorNameLabel>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.LEFT_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.textAlign === 'left' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'left' ? '' : 'left')} >
                <FormatAlignLeftIcon />
              </IconButtonStyled>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.RIGHT_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.textAlign === 'right' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'right' ? '' : 'right')} >
                <FormatAlignRightIcon />
              </IconButtonStyled>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.CENTER_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.textAlign === 'center' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'center' ? '' : 'center')} >
                <FormatAlignCenterIcon />
              </IconButtonStyled>
              <IconButtonStyled
                id={EDITOR_CONTAINER_ELEMENTS_IDS.JUSTIFY_BUTTON}
                color='inherit'
                aria-label='open drawer'
                edge='start'
                style={{ opacity: font.textAlign === 'justify' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'justify' ? '' : 'justify')}>
                <FormatAlignJustifyIcon />
              </IconButtonStyled>
            </EditorElementDesignPage>
            <EditorElementDesignPage>
              <ValueAndSliderInput
                sliderId={SLIDER_ELEMENTS_IDS.TEXT_SPACING_SLIDER}
                returnFocus={returnFocus}
                selectAndRemoveFocus={selectAndRemoveFocus}
                isMobile={false}
                font={font}
                callback={changeFont}
                title={t('textSpacing')}
                valueType={FONT_PROPERTY.CHAR_SPACING}
                maxValue={500}
                canvas={canvas} />
            </EditorElementDesignPage>
            <EditorElementDesignPage>
              <ValueAndSliderInput
                sliderId={SLIDER_ELEMENTS_IDS.LINE_SPACING_SLIDER}
                returnFocus={returnFocus}
                selectAndRemoveFocus={selectAndRemoveFocus}
                isMobile={false}
                font={font}
                callback={changeFont}
                title={t('lineSpacing')}
                valueType={FONT_PROPERTY.LINE_HEIGHT}
                maxValue={FONT_LINE_SPACING_MIN_VALUE}
                canvas={canvas} />
            </EditorElementDesignPage>
          </EditorContainer>
        }
        <ImageEditorContainer id='imageEditor' onClick={(e) => removeFocusFromTextboxMobile(e, [canvasContainerRef.current], canvas, isMobile)}>
          <OpenedSubmenuContainer>
            {isMobile &&
              <>
                <SelectCardContainerMobile>
                  {isOpenedCardMenu ?
                    <>
                      {invitation?.evites && invitation.evites.map((evite: EviteType, index: number) => (
                        <ListItemButton key={evite.id}
                          component={evite.id === activeEviteId ? SelectCardButtonActive : SelectCardButtonInactive}
                          onClick={() => {
                            setIsOpenedCardMenu(false);
                            setActiveEviteId(evite.id);
                          }}>
                          <CustomTooltip theme={{...themePublic.selectCardButtonTooltip, ... (isMobile && CenterEviteName)} as CSSProperties} text={evite.name} />
                          <Box component={isTemplate && invitation?.evites.length > 1 && index !== 0 && SelectCardButtonContainerMobile}>
                            {isTemplate && invitation?.evites.length > 1 &&
                            <DeleteIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setRemoveCardModal(true);
                                setRemoveCardId(evite.id);
                              }} />}
                            {index === 0 && <KeyboardArrowUpIcon />}
                          </Box>
                        </ListItemButton>
                      ))}
                      {
                        isTemplate &&
                        <AddNewText onClick={() => {
                          setCardModal(true);
                          setShowKeyboard(false);
                        }}>
                          <ListItemText primary={t('addNewEvite')} />
                        </AddNewText>
                      }
                    </>
                    :
                    <SelectCardButton onClick={() => invitation?.evites.length > 1 && setIsOpenedCardMenu(true)}>
                      <CustomTooltip
                        theme={{...themePublic.selectCardButtonTooltip, ... (isMobile && CenterEviteName)} as CSSProperties}
                        text={invitation && invitation.evites.map((item: EviteType) => item.id === activeEviteId && item.name)} />
                      {invitation?.evites.length > 1 && <KeyboardArrowDownIcon />}
                    </SelectCardButton>}
                </SelectCardContainerMobile>
                {(isTextSelected || invitation?.evites.length > 1) &&
                <MoreOptionsContainerMobileStyle>
                  <MoreOptionsText> {t(moreOption ? 'lessOptions' : 'moreOptions')} </MoreOptionsText>
                  <MoreOptionIconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={() => setMoreOption(!moreOption)}>
                    <MoreHorizIconStyled/>
                  </MoreOptionIconButton>
                </MoreOptionsContainerMobileStyle>}
                {
                  moreOption &&
                      <LessOptionsContainerMobileStyle>
                        {isTextSelected &&
                        <SelectPlaceholderButton
                          aria-controls={isOpenPlaceholderMenu ? CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU : undefined}
                          aria-haspopup='true'
                          aria-expanded={isOpenPlaceholderMenu ? 'true' : undefined}
                          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, 'placeholder')} >
                          - {t('selectPlaceholder')} -
                          <KeyboardArrowDownIcon />
                        </SelectPlaceholderButton>}
                        <DropdownMenu
                          id={CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU}
                          MenuListProps={{
                            'aria-labelledby': 'placeholder-button',
                          }}
                          anchorEl={placeholderEl}
                          open={isOpenPlaceholderMenu}
                          onClose={handleClose}
                          TransitionComponent={Fade} >
                          {
                            placeholderData?.placeholders.map((placeholder: PlaceholderType) => {
                              return (
                                <PlaceholderMenuItem
                                  key={placeholder.code}
                                  onClick={() => {
                                    handleClose();
                                    setPlaceholder(placeholder.code);
                                  }}>
                                  <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={'+ ' + t(placeholder.code.substring(1, placeholder.code.length - 1))} />
                                </PlaceholderMenuItem>
                              );
                            })
                          }
                        </DropdownMenu>
                        {invitation?.evites.length > 1 &&
                        <SelectPlaceholderButton
                          id='copy-design-button'
                          aria-controls={isOpenCopyDesignMenu ? 'copy-design-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={isOpenCopyDesignMenu ? 'true' : undefined}
                          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, 'design')} >
                          - {t('copyDesignFrom')} -
                          <KeyboardArrowDownIcon />
                        </SelectPlaceholderButton>}
                        <DropdownMenu
                          id='copy-design-menu'
                          MenuListProps={{
                            'aria-labelledby': 'copy-design-button',
                          }}
                          anchorEl={copyDesignEl}
                          open={isOpenCopyDesignMenu}
                          onClose={handleClose}
                          TransitionComponent={Fade} >
                          {
                            invitation?.evites?.map((evite: EviteType) => {
                              return (
                                evite.id !== activeEviteId && (isTemplate || !isTemplate && evite.isSelected) &&
                                <PlaceholderMenuItem
                                  key={evite.id}
                                  onClick={() => {
                                    if (isCanvasEmpty()) {
                                      handleClose();
                                      handleCopyDesignFrom(evite);
                                    } else {
                                      setConfirmCopyDesignModal(true);
                                      setSelectedEviteForCopyDesign(evite);
                                    }
                                  }}>
                                  <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={t(evite.name)} />
                                </PlaceholderMenuItem>
                              );
                            })
                          }
                        </DropdownMenu>
                        <ConfirmationModal
                          loading={false}
                          isOpen={confirmCopyDesignModal && !isCanvasEmpty()}
                          handleClose={() => {
                            setConfirmCopyDesignModal(false);
                            setSelectedEviteForCopyDesign(null);
                          }}
                          handleConfirm={() => {
                            handleClose();
                            handleCopyDesignFrom(selectedEviteForCopyDesign);
                            setConfirmCopyDesignModal(false);
                          }}
                          confimMessage={t('confirmCopyDesignMessage')} />
                      </LessOptionsContainerMobileStyle>
                }
              </>}
            {
              isMobile && isTextSelected ?
                <>
                  <EditorElementContainer>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('font')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FontFamilySelectItem
                          value={font?.fontFamily}
                          onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => changeFont(FONT_PROPERTY.FONT_FAMILY, e.target.value)}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {fontsData?.fonts.map((item: FontType) =>
                            <MenuItem key={item.name} value={item.name}>
                              <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={item.name} />
                            </MenuItem>)}
                        </FontFamilySelectItem>
                      </FormControl>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <AddFontLabelDesignPage onClick={() => setAddFontModal(true)}>+ {t('addFont')}</AddFontLabelDesignPage>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textColor')}</EditorNameLabel>
                      <RoundColorElement
                        id={EDITOR_CONTAINER_ELEMENTS_IDS.COLOR_PICKER_BUTTON}
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        style={{ backgroundColor: font.fill, transform: getTransformPropertyForRoundColorElement()} as CSSProperties} />
                      <ColorTextField
                        id={SLIDER_ELEMENTS_IDS.OUTLINED_BASIC}
                        variant='outlined' value={font.fill}
                        style={{ transform: getTransformPropertyForIconElement()} as CSSProperties}
                        onChange={(e) => changeFont(FONT_PROPERTY.FILL, e.target.value)} />
                      {
                        showColorPicker &&
                        <ImagePickerDesignPage id={EDITOR_CONTAINER_ELEMENTS_IDS.FONT_COLOR_PICKER}>
                          <HexColorPicker
                            color={font.fill}
                            onMouseUp={() => colorHexRef.current && changeFont(FONT_PROPERTY.FILL, colorHexRef.current)}
                            onChange={(newColor) => colorHexRef.current = newColor } />
                        </ImagePickerDesignPage>
                      }
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textSize')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FormControlSelect
                          value={font?.fontSize}
                          onChange={changeFontSize}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {fontSize.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </FormControlSelect>
                      </FormControl>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textStyle')}</EditorNameLabel>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.fontWeight === 'bold' && 1, transform: getTransformPropertyForIconElement()} as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.FONT_WEIGHT, font.fontWeight === 'normal' ? 'bold' : 'normal')}>
                        <FormatBoldIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.fontStyle === 'italic' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.FONT_STYLE, font.fontStyle === 'normal' ? 'italic' : 'normal')} >
                        <FormatItalicIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.underline && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.UNDERLINE, !font.underline)}>
                        <FormatUnderlinedIcon />
                      </IconButtonStyled>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textAlignment')}</EditorNameLabel>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'left' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'left' ? '' : 'left')} >
                        <FormatAlignLeftIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'right' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'right' ? '' : 'right')}>
                        <FormatAlignRightIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'center' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'center' ? '' : 'center')} >
                        <FormatAlignCenterIcon />
                      </IconButtonStyled>
                      <IconButtonStyled
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        style={{ opacity: font.textAlign === 'justify' && 1, transform: getTransformPropertyForIconElement() } as CSSProperties}
                        onClick={() => changeFont(FONT_PROPERTY.TEXT_ALIGN, font.textAlign === 'justify' ? '' : 'justify')}>
                        <FormatAlignJustifyIcon />
                      </IconButtonStyled>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('textSpacing')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FormControlSelect
                          value={font?.charSpacing}
                          onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => changeFont(FONT_PROPERTY.CHAR_SPACING, e.target.value)}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {textSpacing.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </FormControlSelect>
                      </FormControl>
                    </EditorElementDesignPage>
                    <EditorElementDesignPage>
                      <EditorNameLabel>{t('lineSpacing')}</EditorNameLabel>
                      <FormControl sx={{ minWidth: 100, width: '100%' }}>
                        <FormControlSelect
                          value={font?.lineHeight}
                          onChange={(e: SelectChangeEvent<string | number | boolean | number[]>) => changeFont(FONT_PROPERTY.LINE_HEIGHT, e.target.value)}
                          inputProps={{ 'aria-label': 'Without label' }} >
                          {lineSpacing.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </FormControlSelect>
                      </FormControl>
                    </EditorElementDesignPage>
                  </EditorElementContainer>
                </> :
                isMobile ?
                  <>
                    <Box component={!isTextSelected && invitation?.evites.length <= 1 ? SubmenuExtendedContainerMobile : SubmenuContainerMobile}>
                      <SubmenuButton onClick={addText} disabled={showCanvasPreview}>
                        <img src={showCanvasPreview ? addTextIconDisabled : addTextIcon} style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
                        {t('addText')}
                      </SubmenuButton>
                      <SubmenuButton
                        id='fade-button'
                        aria-controls={openBackgroundMenu ? 'fade-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={openBackgroundMenu ? 'true' : undefined}
                        onClick={e => {
                          handleClick(e, 'background');
                          setIsImage(false);
                        }}
                        disabled={showCanvasPreview}>
                        <WallpaperIcon style={{...themePublic.submenuButtonIconDesignPage, ... showCanvasPreview && themePublic.disabledIcon} as CSSProperties} />
                        {t('setBackground')}
                      </SubmenuButton>
                      <Menu
                        id='fade-menu'
                        MenuListProps={{
                          'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={openBackgroundMenu}
                        onClose={handleClose}
                        TransitionComponent={Fade} >
                        <MenuItem onClick={() => {
                          handleClose();
                          setIsBackgroundColor(false);
                          setAddBackgroundImageModal(true);
                        }}>
                          <img src={addBackgroundIcon} style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
                          {t('image')}
                        </MenuItem>
                        <MenuItem onClick={() => {
                          handleClose();
                          setIsBackgroundColor(true);
                          setAddBackgroundImageModal(true);
                        }}>
                          <ColorLensIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
                          {t('color')}
                        </MenuItem>
                      </Menu>
                      <SubmenuButton
                        disabled={showCanvasPreview}
                        onClick={() => {
                          setIsBackgroundColor(false);
                          setIsImage(true);
                          setAddBackgroundImageModal(true);
                        }}>
                        <img src={showCanvasPreview ? addBackgroundIconDisabled : addBackgroundIcon} style={themePublic.submenuButtonIconDesignPage as CSSProperties} />{t('addImage')}
                      </SubmenuButton>
                      <ToggleContainer>
                        <PreviewSwitchButton
                          checked={showCanvasPreview}
                          onChange={(e) => {
                            setShowCanvasPreview(e.target.checked);
                            showCanvasPreviewImage(e.target.checked);
                          }} />
                        {t('preview')}
                      </ToggleContainer>
                    </Box>
                  </> :
                  <>
                    <SubmenuContainer>
                      <ContainerSubmenuStartButtons>
                        <SubmenuButton onClick={addText} disabled={showCanvasPreview}>
                          <img src={showCanvasPreview ? addTextIconDisabled : addTextIcon} style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
                          {t('addText')}
                        </SubmenuButton>
                        <SubmenuButton
                          id='fade-button'
                          aria-controls={openBackgroundMenu ? 'fade-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={openBackgroundMenu ? 'true' : undefined}
                          onClick={e => {
                            setIsImage(false);
                            handleClick(e, 'background');
                          }}
                          disabled={showCanvasPreview}>
                          <WallpaperIcon style={{...themePublic.submenuButtonIconDesignPage, ... showCanvasPreview && themePublic.disabledIcon} as CSSProperties} />
                          {t('setBackground')}
                        </SubmenuButton>
                        <Menu
                          id='fade-menu'
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={anchorEl}
                          open={openBackgroundMenu}
                          onClose={handleClose}
                          TransitionComponent={Fade} >
                          <MenuItem onClick={() => {
                            handleClose();
                            setIsBackgroundColor(false);
                            setAddBackgroundImageModal(true);
                          }}>
                            <img src={addBackgroundIcon} style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
                            {t('image')}
                          </MenuItem>
                          <MenuItem onClick={() => {
                            handleClose();
                            setIsBackgroundColor(true);
                            setAddBackgroundImageModal(true);
                          }}>
                            <ColorLensIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
                            {t('color')}
                          </MenuItem>
                        </Menu>
                        <SubmenuButton
                          disabled={showCanvasPreview}
                          onClick={() => {
                            setIsBackgroundColor(false);
                            setIsImage(true);
                            setAddBackgroundImageModal(true);
                          }}>
                          <img src={showCanvasPreview ? addBackgroundIconDisabled : addBackgroundIcon} style={themePublic.submenuButtonIconDesignPage as CSSProperties} />{t('addImage')}
                        </SubmenuButton>
                        <ToggleContainer>
                          <PreviewSwitchButton
                            checked={showCanvasPreview}
                            onChange={(e) => {
                              setShowCanvasPreview(e.target.checked);
                              showCanvasPreviewImage(e.target.checked);
                            }} />
                          {t('preview')}
                        </ToggleContainer>
                      </ContainerSubmenuStartButtons>
                      <ContainerSubmenuEndButtons>
                        {isTextSelected &&
                        <SubmenuButton
                          id='placeholder-button'
                          aria-controls={isOpenPlaceholderMenu ? CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU : undefined}
                          aria-haspopup='true'
                          aria-expanded={isOpenPlaceholderMenu ? 'true' : undefined}
                          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, 'placeholder')} >
                          {t('selectPlaceholder')}
                        </SubmenuButton>}
                        <Menu
                          id={CANVAS_ELEMENTS_IDS.PLACEHOLDER_MENU}
                          MenuListProps={{
                            'aria-labelledby': 'placeholder-button',
                          }}
                          anchorEl={placeholderEl}
                          open={isOpenPlaceholderMenu}
                          onClose={handleClose}
                          TransitionComponent={Fade} >
                          {
                            placeholderData?.placeholders.map((placeholder: PlaceholderType) => {
                              return (
                                <SubmenuPlaceholdersMenuItem
                                  key={placeholder.code}
                                  onClick={() => {
                                    handleClose();
                                    setPlaceholder(placeholder.code);
                                  }}>
                                  <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={'+ ' + t(placeholder.code.substring(1, placeholder.code.length - 1))} />
                                </SubmenuPlaceholdersMenuItem>
                              );
                            })
                          }
                        </Menu>
                        {invitation?.evites.length > 1 &&
                        <SubmenuButton
                          id='copy-design-button'
                          aria-controls={isOpenCopyDesignMenu ? 'copy-design-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={isOpenCopyDesignMenu ? 'true' : undefined}
                          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, 'design')} >
                          {t('copyDesignFrom')}
                        </SubmenuButton>}
                        <Menu
                          id='copy-design-menu'
                          MenuListProps={{
                            'aria-labelledby': 'copy-design-button',
                          }}
                          anchorEl={copyDesignEl}
                          open={isOpenCopyDesignMenu}
                          onClose={handleClose}
                          TransitionComponent={Fade} >
                          {
                            invitation?.evites?.map((evite: EviteType) => {
                              return (
                                evite.id !== activeEviteId && (isTemplate || !isTemplate && evite.isSelected) &&
                                <SubmenuCopyListMenuItem
                                  key={evite.id}
                                  onClick={() => {
                                    if (isCanvasEmpty()) {
                                      handleClose();
                                      handleCopyDesignFrom(evite);
                                    } else {
                                      setConfirmCopyDesignModal(true);
                                      setSelectedEviteForCopyDesign(evite);
                                    }
                                  }}>
                                  <CustomTooltip theme={themePublic.selectCardButtonTooltip} text={t(evite.name)} />
                                </SubmenuCopyListMenuItem>
                              );
                            })
                          }
                        </Menu>
                      </ContainerSubmenuEndButtons>
                    </SubmenuContainer>
                    <ConfirmationModal
                      loading={false}
                      isOpen={confirmCopyDesignModal && !isCanvasEmpty()}
                      handleClose={() => {
                        setConfirmCopyDesignModal(false);
                        setSelectedEviteForCopyDesign(null);
                      }}
                      handleConfirm={() => {
                        handleClose();
                        handleCopyDesignFrom(selectedEviteForCopyDesign);
                        setConfirmCopyDesignModal(false);
                      }}
                      confimMessage={t('confirmCopyDesignMessage')} />
                  </>
            }
          </OpenedSubmenuContainer>
          <Box id='canvasContainer' ref={canvasContainerRef} sx={{... loadingCanvas && themePublic.hiddenElement, ... showCanvasPreview ? themePublic.canvasContainerDesignPageHidden : themePublic.canvasContainerDesignPage}}>
            <Box sx={{... showCanvasPreview ? themePublic.canvasPreviewImg : NotDisplayedStyle}}>
              {canvasPreviewImage &&
                <img
                  src={canvasPreviewImage}
                  width={canvas?.width}
                  height={canvas?.height} />}
            </Box>
            <canvas id='canvasMain' />
          </Box>
          {canvas &&
              <BackgroundSizeText>
                {t('backgroundSize', { width: Number(canvasSize.width).toFixed(), height: Number(canvasSize.height).toFixed() })}
                <IconButton
                  onClick={() => setIsOpenCanvasResizeModal(true)}
                  color='inherit'
                  component='label'>
                  <EditSizeIcon />
                </IconButton>
              </BackgroundSizeText>
          }
          <ActionButtonContainer>
            <CanvasActionButton elementId={CANVAS_ELEMENTS_IDS.DELETE_BUTTON} showButton={isTextSelected || isImageSelected} handleAction={() => deleteSelectedObjects(true)}>
              <DeleteIcon />
            </CanvasActionButton>
            {isMobile &&
              <CanvasActionButton elementId={CANVAS_ELEMENTS_IDS.EDIT_TEXT_BUTTON} showButton={isTextSelected} handleAction={() => switchToEditMode(canvas, CANVAS_ELEMENTS_IDS.EDIT_TEXT_BUTTON)}>
                <EditIcon />
              </CanvasActionButton>
            }
          </ActionButtonContainer>
        </ImageEditorContainer>
        <Loader loadingPage={false} inProgress={imageLoading || isSavingCanvas || updateEviteLoading || loadingCanvas || replacePlaceholdersLoading || replaceDefaultPlaceholdersLoading} />
        <Dialog maxWidth='xl' open={addFontModal} onClose={() => setAddFontModal(false)}>
          <DialogContent>
            <AddFontModal
              handleUpload={(fileUrl: string) => addFont(fileUrl)}
              handleClose={() => setAddFontModal(false)}
              fontLoading={fontLoading}
              isTemplate={isTemplate}/>
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth={isBackgroundColor ? 'xl' : 'lg'}
          open={addBackgroundImageModal}
          onClose={closeAddBackgroundImageModal}
          sx={!isBackgroundColor && themePublic.dialogStyle as CSSProperties}>
          <SetBackgroundImageDialogContent>
            <SetBackgroundImageModal
              isBackgroundColor={isBackgroundColor}
              handleSetBackgroundColor={(color: string) => isBackgroundColor && handleSetBackgroundColor(color)}
              handleUpload={(file: string) => !isBackgroundColor && handleSetBackground(file)}
              handleClose={closeAddBackgroundImageModal}
              imageLoading={imageLoading}
              isTemplate={isTemplate}/>
          </SetBackgroundImageDialogContent>
        </Dialog>
        <NewEviteDialog
          open={isTemplate && invitation && (!isEviteInInvitaion || cardModal)}
          maxWidth='lg'>
          <DialogContent>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <DialogTitle>{t('setEviteName')}</DialogTitle>
              <CloseIconStyled onClick={() => {
                cardModal ?
                  setCardModal(false) :
                  handleNavigate('/invitationTemplates');
              }} />
            </Box>
            <Formik
              innerRef={createEviteFormikRef}
              initialValues={{cardName: ''}}
              validationSchema={createEviteSchema}
              onSubmit={isEviteInInvitaion ? createCard : renameCard}>
              {({ errors, isSubmitting, isValid, values, dirty, setFieldValue }) => {
                return (
                  <FormStyledDialogContent autoComplete='off'>
                    <InputField
                      setShowKeyboard={setShowKeyboardForCardName}
                      setInputName={setCardInputName}
                      inputId='cardName'
                      inputName='cardName'
                      isError={errors.cardName}
                      label={t('eviteName')}
                      type='text' />
                    { showKeyboardForCardName &&
                      <VirtualKeyboard
                        setShowKeyboard={setShowKeyboardForCardName}
                        initialValues={values}
                        setFieldValue={setFieldValue}
                        keyboardRef={keyboardRef}
                        inputName={cardInputName}/>
                    }
                    <ButtonContainer>
                      <SubmitButton
                        type='submit'
                        variant='contained'
                        disabled={Object.keys(errors).length > 0 || isSubmitting || !(isValid && dirty) || isSubmittingCardName}>
                        {t('save')}
                      </SubmitButton>
                    </ButtonContainer>
                  </FormStyledDialogContent>
                );
              }}
            </Formik>
          </DialogContent>
        </NewEviteDialog>
        <Dialog open={isOpenCanvasResizeModal} onClose={() => setIsOpenCanvasResizeModal(false)}>
          <DialogContent>
            <CanvasResizeModal
              initSize={canvasSize}
              setSize={(size) => {
                setCanvasSize(size);
                setCanvasSizeChanged(true);
                canvas._historySaveAction();
              }}
              handleClose={() => setIsOpenCanvasResizeModal(false)} />
          </DialogContent>
        </Dialog>
        <ConfirmationModal
          isOpen={removeCardModal}
          handleClose={() => setRemoveCardModal(false)}
          confimMessage={t('confirmDeleteMessage')}
          handleConfirm={() => removeCard()}
          loading={false} />
      </PageContent>
      {isMobile &&
        <BottomCanvasButtonsContainerMobile>
          <BottomCanvasButtonsMobile
            variant='contained'
            onClick={() => undo()}
            disabled={isUndoDisabled}>
            <RedoIcon style={{transform: 'rotateY(180deg)', ...themePublic.submenuButtonIconDesignPage} as CSSProperties}/>
            {t('undo')}
          </BottomCanvasButtonsMobile>
          <BottomCanvasButtonsMobile
            variant='contained'
            onClick={() => redo()}
            disabled={isRedoDisabled}>
            <RedoIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties} />
            {t('redo')}
          </BottomCanvasButtonsMobile>
          <BottomCanvasButtonsMobile
            disabled={(!canvasIsChanged && !canvasSizeChanged) || isSavingCanvas || isSaveDisabled}
            onClick={() => saveCanvas(true)}>
            <SaveIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties}/>{t('save')}
          </BottomCanvasButtonsMobile>
        </BottomCanvasButtonsContainerMobile>}
      <PageButtonsContainer>
        <PagePrevButton
          variant='contained'
          onClick={() => changeTab('details')}>
          {t(isMobile ? 'back' : 'prevDetails')}
        </PagePrevButton>
        {!isMobile &&
          <BottomCanvasButtonsContainer>
            <BottomCanvasButtons
              style={{ marginLeft: isMobile ? 0 : '100px' }}
              onClick={() => undo()}
              disabled={isUndoDisabled}>
              <RedoIcon style={{transform: 'rotateY(180deg)', ...themePublic.submenuButtonIconDesignPage} as CSSProperties} />
              {t('undo')}
            </BottomCanvasButtons>
            <BottomCanvasButtons
              onClick={() => redo()}
              disabled={isRedoDisabled}>
              <RedoIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties}/>
              {t('redo')}
            </BottomCanvasButtons>
            <BottomCanvasButtons
              disabled={(!canvasIsChanged && !canvasSizeChanged) || isSavingCanvas || isSaveDisabled}
              onClick={() => saveCanvas(true)}>
              <SaveIcon style={themePublic.submenuButtonIconDesignPage as CSSProperties} />{t('save')}
            </BottomCanvasButtons>
          </BottomCanvasButtonsContainer>
        }
        <PageNextButton
          variant='outlined'
          onClick={() => changeTab('envelope')}>
          {t(isMobile ? 'next' : 'nextEnvelope')}
        </PageNextButton>
        {showKeyboard &&
          <VirtualKeyboard
            keyboardComponentId={CANVAS_ELEMENTS_IDS.VIRTUAL_KEYBOARD}
            selectedObject={selectedObject}
            setShowKeyboard={setShowKeyboard}
            setFieldValue={null}
            keyboardRef={keyboardRef}
            inputName={selectedKey}
            isCanvas={true}
            initialValues={canvasTextboxMap}
            renderTextFromKeyboard={() => renderTextFromKeyboard()}/>
        }
      </PageButtonsContainer>
      <Prompt when={canvasIsChanged && !isSavingCanvas || canvasSizeChanged} options={{
        title: t('leavePage'),
        message: t('unsavedChanges'),
        buttons: [
          {
            label: 'Confirm',
            continue: true
          },
          {
            label: 'Cancel',
          }
        ],
      }} />
    </>
  );
};