import React, { CSSProperties, memo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { onImageErrorLoading } from 'helpers';
import { InvitationProps, InvitationTemplateProps } from 'types';

import { AdditionalCardsBadge, SingleCardContainer, ThreeCardContainer, TwoCardContainer } from './ImagePreview.styled';

type ImagePreviewProps = {
  invitation: InvitationTemplateProps | InvitationProps,
  forTablePreview: boolean,
}

export const ImagePreviewComponent = ({ invitation, forTablePreview }: ImagePreviewProps) => {
  const [t] = useTranslation();

  const renderCardContainerContent = () => {
    const stylePropsAdditionalCardsBadge = {
      top: forTablePreview ? '60%' : '90%',
      fontSize: forTablePreview ? '10px' : '12px',
    };

    return (
      <>
        {invitation?.evites.map(evite =>
          <img key={evite.id} src={evite?.image?.thumbnailUrl} alt={t('loading')} onError={onImageErrorLoading}/>
        )}
        {invitation?.evites.length > 3 &&
        <AdditionalCardsBadge style={stylePropsAdditionalCardsBadge as CSSProperties}>
          +{invitation?.evites.length - 3}
        </AdditionalCardsBadge>}
      </>
    );
  };

  const renderCardContainer = (cardNumber: number) => {
    const styleProps = {
      marginTop: forTablePreview ? 0 : '2rem',
      height: forTablePreview ? '80px' : isMobile ? '200px' : '300px',
    };

    switch (cardNumber) {
      case 0:
      case 1:
        return <SingleCardContainer style={styleProps as CSSProperties}> {renderCardContainerContent()} </SingleCardContainer>;
      case 2:
        return <TwoCardContainer style={{...styleProps, top: forTablePreview ? '60%' : '55%'}}> {renderCardContainerContent()} </TwoCardContainer>;
      default:
        return <ThreeCardContainer style={styleProps as CSSProperties}> {renderCardContainerContent()} </ThreeCardContainer>;
    }
  };

  return (
    renderCardContainer(invitation?.evites.length)
  );
};

export const ImagePreview = memo(ImagePreviewComponent, (prevProps, nextProps) => {
  return prevProps.invitation.evites !== nextProps.invitation.evites;
});

ImagePreview.displayName = 'ImagePreview';
