import { Box, Grid, IconButton, Switch, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { baseSwitchButtonStyle, themeDefault } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
    lineHeight: 1.8,
  },
};

export const KeyboardArrowStyle = {
  fill: themeDefault.palette.disabledButtonColor,
};

export const CardContainer = styled(Box)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    marginBottom: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5vh',
    },
  }),
);

export const InvitationDetails = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    height: 'auto',
    width: '100%',
  }),
);

export const CardTitleContainer = styled(Box)(
  () => ({
    borderRadius: '10px',
    boxShadow: `1px 2px ${themeDefault.palette.greyColor}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
);

export const CardTitle = styled(Typography)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    alignItems: 'center',
    float: 'left',
    display: 'flex',
    justifyContent: 'start',
    maxWidth: '50%',
    marginLeft: '20px',
    marginTop: '5px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  }),
);

export const SwitchButton = styled(Switch)(
  () => ({
    ...baseSwitchButtonStyle as CSSProperties,
    marginRight: '1rem',
  }),
);

export const CardTitleText = styled('span')(
  () => ({
    ...themeDefault.overflowText,
    maxWidth: '70%',
  }),
);

export const MoreOptionIconButton = styled(IconButton)(
  () => ({
    float: 'right',
  }),
);

export const EvitesContainer = styled(Grid)(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const NoCardsMessage = styled(Typography)(
  () => ({
    margin: '1rem',
  }),
);
