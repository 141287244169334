import React from 'react';
import { Box, Table, TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PublicContactEntryLinksTableEditRow, PublicContactEntryLinksTableRow } from 'components';
import { PublicContactEntryAuthorisationType, TagType } from 'types';

import { BulkTableHeadRow, ButtonColumnIndent, LabelNameTableCell, LastTableCell, RemovedBorderTableHead, TableStyled } from './PublicContactEntryLinksTable.styled';

type Props = {
  items: PublicContactEntryAuthorisationType[];
  lookupTags?: TagType[],
  inlineEdit?: {
    active?: boolean;
    id?: number;
    itemDefaults?: Partial<PublicContactEntryAuthorisationType>;
  };
  onSaveItem?: (item: Partial<PublicContactEntryAuthorisationType>) => Promise<void>;
  onDeleteItem?: (id?: number) => Promise<void>;
  onStartEdit?: (id?: number) => void;
  onCancelEdit?: () => void;
  showKeyboard?: boolean;
  setShowKeyboard?: (value: boolean) => void;
};

export const PublicContactEntryLinksTable = ({
  items,
  lookupTags,
  inlineEdit,
  onSaveItem,
  onDeleteItem,
  onStartEdit,
  onCancelEdit,
  showKeyboard,
  setShowKeyboard,
}: Props) => {
  const [t] = useTranslation();

  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0' }}>
        <RemovedBorderTableHead>
          <BulkTableHeadRow>
            <LabelNameTableCell>
              {t('name')}
            </LabelNameTableCell>
            <LabelNameTableCell>
              {t('expiry')}
            </LabelNameTableCell>
            <LabelNameTableCell>
              {t('status')}
            </LabelNameTableCell>
            <LabelNameTableCell>
              {t('tags')}
            </LabelNameTableCell>
            <LabelNameTableCell>
              <ButtonColumnIndent>{t('link')}</ButtonColumnIndent>
            </LabelNameTableCell>
            <LastTableCell />
          </BulkTableHeadRow>
        </RemovedBorderTableHead>
        <TableStyled>
          {inlineEdit?.active && !inlineEdit?.id &&
            <PublicContactEntryLinksTableEditRow
              item={inlineEdit.itemDefaults}
              lookupTags={lookupTags}
              onSave={(props) => onSaveItem({ ...props, id: undefined })}
              onCancel={onCancelEdit}
              showKeyboard={showKeyboard}
              setShowKeyboard={setShowKeyboard} />
          }
          {items?.map((item, i) => (
            inlineEdit?.active && inlineEdit?.id && item.id === inlineEdit?.id ?
              <PublicContactEntryLinksTableEditRow
                key={i} item={item}
                lookupTags={lookupTags}
                onSave={(props) => onSaveItem({ ...props, id: item.id })}
                onCancel={onCancelEdit}
                showKeyboard={showKeyboard}
                setShowKeyboard={setShowKeyboard} /> :
              <PublicContactEntryLinksTableRow
                key={i} item={item}
                onStartEdit={() => onStartEdit?.(item.id)}
                onDelete={() => onDeleteItem?.(item.id)} />
          ))}
        </TableStyled>
      </Table>
    </TableContainer>
  );
};
