import { Box, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PaginationSection = styled(Box)(
  () => ({
    ...themePublic.paginationSection as CSSProperties,
  }),
);

export const SelectContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
  }),
);

export const PaginationStyled = styled(Pagination)(
  () => ({
    '& button': {
      '&:hover': {
        backgroundColor: themeDefault.palette.lightMainColor,
      }
    },
    '& .Mui-selected': {
      backgroundColor: `${themeDefault.palette.mainColor} !important`,
      color: themeDefault.palette.whiteColor,
      '&:hover': {
        backgroundColor: `${themeDefault.palette.mainColor} !important`,
      }
    }
  }),
);
