import { CSSProperties } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form } from 'formik';

import { themeDefault, themePublic } from 'themeDefault';

export const AccountSettingsPageBody = styled(Box)(
  () => ({
    ...themePublic.pageBody as CSSProperties,
  }),
);

export const AccountSettingsPageHeader = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
  }),
);

export const AccountSettingsPageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    justifyContent: 'start',
    marginTop: '24px',
    padding: '4rem 8rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '13.85vh',
      padding: '2rem 3rem',
      borderRadius: themeDefault.defaultBorderRadius,
    },
  }),
);

export const AccountSettingsFormHeading = styled(Typography)(
  ({ theme }) => ({
    color: themeDefault.palette.fieldLabelColor,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  }),
);

export const NewInvitationButton = styled(Button)(
  () => ({
    borderRadius: '0.6rem'
  }),
);

export const FormStyled = styled(Form)(
  () => ({
    position: 'relative'
  })
);

export const ImpersonateText = styled(Typography)(
  ({ theme }) => ({
    color: themeDefault.palette.mainColor,
    fontWeight: 600,
    margin: '80px 0 0.5rem 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  }),
);

export const LeaveImpersonatedButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '0.6rem',
    width: '20%',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColorHover,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  }),
);

export const SaveButton = styled(Button)(
  ({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '0.6rem',
    marginTop: '80px',
    width: '20%',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export const ButtonsContainer = styled(Box)(
  (props) => ({
    display: 'flex',
    marginTop: '40px',
    [props.theme.breakpoints.down('sm')]: {
      display: 'inline',
      marginTop: 0,
    },
  }),
);

export const CancelButton = styled(Button)(
  ({ theme }) => ({
    background: 'transparent',
    borderRadius: '0.6rem',
    marginTop: '80px',
    borderColor: 'black',
    color: 'black',
    marginLeft: '40px',
    width: '20%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: themeDefault.palette.mainColor,
      borderColor: themeDefault.palette.mainColor,
      color: themeDefault.palette.whiteColor,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      marginTop: '20px',
    },
  }),
);

export const TypographyStyled = styled(Typography)(
  ({ theme }) => ({
    margin: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginTop: '5rem',
      textAlign: 'center'
    },
  }),
);
