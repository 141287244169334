import React, { CSSProperties, useState } from 'react';
import { Help as HelpIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

import { BodyContainer, TextContainer, TextContent } from './GuideIcon.styled';

type GuideIconProps = {
    themes: {
      containerStyle: CSSProperties,
      textBoxStyle: CSSProperties,
    },
    text: string,
};

export const GuideIcon = ({ themes, text }: GuideIconProps) => {
  const [ isBodyActive, setIsBodyActive ] = useState<boolean>(false);

  return (
    <Box sx={themes.containerStyle}>
      <BodyContainer onClick={() => setIsBodyActive(!isBodyActive)}>
        <HelpIcon />
        {isBodyActive &&
        <TextContainer sx={themes.textBoxStyle}>
          <TextContent>{text}</TextContent>
        </TextContainer>}
      </BodyContainer>
    </Box>
  );
};