import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ContactsForm, InputField, Loader, TagSelector, VirtualKeyboard } from 'components';
import { FORM_TYPE, VALIDATION_RESTRICTION } from 'enums';
import { contactGroupInitialValues, contactInitialValues, memberOfContactsAreChanged, phoneNumberRegExp, tagsAreChanged } from 'helpers';
import { ADD_CONTACT, CREATE_CONTACTS_GROUP, EDIT_CONTACT, EDIT_CONTACTS_GROUP, graphMutationMiddleware } from 'services';
import { useContactStore } from 'store';
import { ContactFormValuesType, ContactType, FormikMethodsTypes, TagType } from 'types';

import {
  AddNewTagContainer, AddNewTagText, ButtonsContainer, CloseIconStyled, GroupNameContainer,
  ModaHeader, ModaHeading, ModalBody, PersonIconStyled, SubmitButton, TagsContainer,
} from './ContactModal.styled';

type CreateUserModalProps = {
  handleClose?: () => void
  type: FORM_TYPE.CREATE | FORM_TYPE.EDIT
  contact?: ContactType;
  tagData: {
    tags: {
      id: number,
      name: string
    }[]
  }
};

export const ContactsModal = ({ type, contact = null, handleClose, tagData }: CreateUserModalProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);
  const formRef = useRef(null);
  const { changeExecuteTagsRefetch, changeExecuteRefetch } = useContactStore();

  const [ initialValues, setInitialValues ] = useState(
    type === FORM_TYPE.EDIT ? {
      groupName: contact?.contacts.length > 0 ? contact.lastName : '',
      firstName: contact?.contacts.length > 0 ? contact.contacts.map(item => item.firstName) : [contact.firstName || ''],
      lastName: contact?.contacts.length > 0 ? contact.contacts.map(item => item.lastName) : [contact.lastName || ''],
      email: contact?.contacts.length > 0 ? contact.contacts.map(item => item.email) : [contact.email || ''],
      phoneNumber: contact?.contacts.length > 0 ? contact.contacts.map(item => item.phoneNumber) : [contact.phoneNumber || ''],
      street: contact?.contacts.length > 0 ? contact.contacts.map(item => item.street) : [contact.street || ''],
      city: contact?.contacts.length > 0 ? contact.contacts.map(item => item.city) : [contact.city || ''],
      zipCode: contact?.contacts.length > 0 ? contact.contacts.map(item => item.zipCode) : [contact.zipCode || ''],
      state: contact?.contacts.length > 0 ? contact.contacts.map(item => item.state) : [contact.state || ''],
      title: contact?.contacts.length > 0 ? contact.contacts.map(item => item.title) : [contact.title || ''],
      id: contact?.contacts.length > 0 ? contact.contacts.map(item => item.id) : [contact.id || null],
    } :
      contactGroupInitialValues
  );
  const [ showTags, setShowTags ] = useState(true);
  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');
  const [ contactsCopy, setContactsCopy ] = useState(contact ? contact.contacts.length > 0 ? [...contact.contacts] : [contact] : [contactInitialValues]);
  const [ membersForDelete, setMembersForDelete ] = useState<number[]>([]);

  const [ addContact, { loading: createLoading }] = graphMutationMiddleware(ADD_CONTACT);
  const [ editContact, { loading: editSingleLoading }] = graphMutationMiddleware(EDIT_CONTACT);
  const [ createContactsGroup, { loading: createGroupLoading }] = graphMutationMiddleware(CREATE_CONTACTS_GROUP);
  const [ editContactsGroup, { loading: editGroupLoading }] = graphMutationMiddleware(EDIT_CONTACTS_GROUP);

  const initSelectedTags = () => {
    return type === FORM_TYPE.EDIT ? contact.tags.map((tag: TagType) => tag.name) : [];
  };

  const [ selectedTags, setSelectedTags ] = useState(initSelectedTags());
  const submitRef = useRef();

  const submitForm = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    submitRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const renderNewMember = (values: ContactFormValuesType) => {
    const newContactsCopy = [...contactsCopy];
    newContactsCopy.push(contactInitialValues);
    setContactsCopy(newContactsCopy);
    setInitialValues({
      ...initialValues,
      groupName: formRef?.current ? formRef.current.values.groupName : '',
      firstName: [ ...values.firstName, '' ],
      lastName: [ ...values.lastName, '' ],
      email: [ ...values.email, '' ],
      phoneNumber: [ ...values.phoneNumber, '' ],
      street: [ ...values.street, '' ],
      city: [ ...values.city, '' ],
      zipCode: [ ...values.zipCode, '' ],
      state: [ ...values.state, '' ],
      title: [ ...values.title, '' ],
    });
  };

  const contactsSchema = Yup.object().shape({
    groupName: contactsCopy.length > 1 ? Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('groupName'), length: VALIDATION_RESTRICTION.HUNDRED })) :
      Yup.string()
        .nullable(),
    firstName: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    lastName: Yup.array().of(Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    email: Yup.array().of(Yup.string()
      .email(t('invalidEmailFormat'))
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))),
    phoneNumber: Yup.array().of(Yup.string()
      .matches(phoneNumberRegExp, t('invalidPhoneNumberFormat'))
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('phoneNumber'), length: VALIDATION_RESTRICTION.FIFTY }))),
    street: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('street'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    city: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('city'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    zipCode: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('zipCode'), length: VALIDATION_RESTRICTION.FIFTY }))),
    state: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('state'), length: VALIDATION_RESTRICTION.HUNDRED }))),
  });

  const refetchData = () => {
    changeExecuteTagsRefetch();
    changeExecuteRefetch();
  };

  const handleEditSingle = (values: ContactFormValuesType, { setSubmitting }: FormikMethodsTypes) => {
    editContact({variables: {
      input: {
        tags: selectedTags,
        firstName: values.firstName[0],
        lastName: values.lastName[0],
        email: values.email[0],
        titleValue: values.title[0],
        phoneNumber: values.phoneNumber[0],
        state: values.state[0],
        street: values.street[0],
        zipCode: values.zipCode[0],
        city: values.city[0],
        id: values.id[0]
      },
    }}).then(() => {
      setSubmitting(false);
      handleClose();
      refetchData();
      initSelectedTags();
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const handleEditGroup = (values: ContactFormValuesType, { setSubmitting }: FormikMethodsTypes) => {

    const contactValues = values.firstName.map((item: string, index: number) => {
      return {
        firstName: item,
        lastName: values.lastName[index] ?? '',
        email: values.email[index] ?? '',
        phoneNumber: values.phoneNumber[index] ?? '',
        state: values.state[index] ?? '',
        street: values.street[index] ?? '',
        zipCode: values.zipCode[index] ?? '',
        city: values.city[index] ?? '',
        titleValue: values.title[index] ?? '',
        id: values.id[index] ?? null,
      };
    });

    editContactsGroup({variables: {
      id: Number(contact.id),
      input: {
        id: Number(contact.id),
        groupName: values.groupName,
        tags: selectedTags,
        contacts: contactValues.filter((value: ContactType) => value.id),
        membersForDelete: membersForDelete,
        newContactMembers: contactValues.filter((value: ContactType) => !value.id).map((value: ContactType) => {
          return {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phoneNumber: value.phoneNumber,
            state: value.state,
            street: value.street,
            zipCode: value.zipCode,
            city: value.city,
            titleValue: value.titleValue
          };
        })
      },
    }}).then(() => {
      setSubmitting(false);
      handleClose();
      changeExecuteRefetch();
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const createSingle = (val: ContactFormValuesType, { setSubmitting }: FormikMethodsTypes) => {
    addContact({variables: { input: {
      tags: selectedTags,
      firstName: val.firstName[0],
      lastName: val.lastName[0],
      email: val.email[0],
      phoneNumber: val.phoneNumber[0],
      titleValue: val.title[0],
      city: val.city[0],
      street: val.street[0],
      state: val.state[0],
      zipCode: val.zipCode[0],
    }}}).then(() => {
      setSubmitting(false);
      handleClose();
      refetchData();
      initSelectedTags();
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const createGroup = (val: ContactFormValuesType, { setSubmitting }: FormikMethodsTypes) => {
    const items = val.firstName.map((firstName: string, index: number) => {
      return {
        titleValue: val.title[index],
        firstName: firstName,
        lastName: val.lastName[index],
        email: val.email[index],
        phoneNumber: val.phoneNumber[index],
        street: val.street[index],
        city: val.street[index],
        zipCode: val.zipCode[index],
        state: val.state[index],
      };
    });

    createContactsGroup({variables: { input: {
      groupName: val.groupName,
      tags: selectedTags,
      contacts: items
    }}}).then(() => {
      setSubmitting(false);
      handleClose();
      refetchData();
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const removeMember = (index: number, values: ContactFormValuesType) => {
    const newContactsCopy = [...contactsCopy];
    newContactsCopy[index].id && setMembersForDelete([ ...membersForDelete, newContactsCopy[index].id ]);
    newContactsCopy.splice(index, 1);
    setContactsCopy(newContactsCopy);

    setInitialValues({
      ...initialValues,
      groupName: formRef?.current ? formRef.current.values.groupName : '',
      firstName: values.firstName.filter((value, i) => i !== index),
      lastName: values.lastName.filter((value, i) => i !== index),
      email: values.email.filter((value, i) => i !== index),
      phoneNumber: values.phoneNumber.filter((value, i) => i !== index),
      street: values.street.filter((value, i) => i !== index),
      city: values.city.filter((value, i) => i !== index),
      zipCode: values.zipCode.filter((value, i) => i !== index),
      state: values.state.filter((value, i) => i !== index),
      title: values.title.filter((value, i) => i !== index),
      id: values.id.filter((value, i) => i !== index)
    });
  };

  return (
    <ModalBody>
      <Loader loadingPage={false} inProgress={createLoading || createGroupLoading || editSingleLoading || editGroupLoading} />
      <ModaHeader>
        {isMobile ?
          <ModaHeading>{type === FORM_TYPE.CREATE ? t('newContact') : t('editContact')}</ModaHeading>:
          <Typography variant='h5'>{t(type === FORM_TYPE.CREATE ? 'createNewContact' : 'editContact')}</Typography>}
        <CloseIconStyled onClick={() => handleClose()} />
      </ModaHeader>
      {isMobile && <PersonIconStyled/>}
      <Box sx={isMobile ? {width: '90%'} : {width: '100%'}}>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={contactsSchema}
          onSubmit={
            type === FORM_TYPE.CREATE ?
              contactsCopy.length > 1 ?
                createGroup :
                createSingle
              : contactsCopy.length > 1 ?
                handleEditGroup :
                handleEditSingle
          }>
          {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }) => {
            return (
              <Box>
                <Form ref={submitRef} autoComplete='off'>
                  {contactsCopy.length > 1 &&
                    <GroupNameContainer>
                      <Typography>{t('groupName')}</Typography>
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='groupName'
                        inputName='groupName'
                        isError={null}
                        label=''
                        type='text' />
                    </GroupNameContainer>
                  }
                  {contactsCopy.map((item, index) =>
                    <ContactsForm
                      contact={item}
                      showAddNewText={contactsCopy.length - 1 === index}
                      key={index}
                      type={type}
                      removeMember={() => removeMember(index, values)}
                      handleAddNew={() => renderNewMember(values)}
                      index={index}
                      setInputName={setInputName}
                      setShowKeyboard={setShowKeyboard}/>)
                  }
                  <AddNewTagContainer>
                    {showTags && selectedTags.length === 0 ?
                      <AddNewTagText onClick={() => setShowTags(false)}>+ {t('addTags')}</AddNewTagText>:
                      <TagsContainer>
                        <TagSelector
                          showKeyboardParent={showKeyboard}
                          setShowKeyboardParent={setShowKeyboard}
                          showText={false}
                          tagData={tagData && tagData}
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags} />
                      </TagsContainer>
                    }
                  </AddNewTagContainer>
                  { showKeyboard &&
                  <VirtualKeyboard
                    setShowKeyboard={setShowKeyboard}
                    initialValues={{
                      ...values,
                      'tags': selectedTags
                    }}
                    setTagsValue={setInputValue}
                    addTag={setSelectedTags}
                    setFieldValue={setFieldValue}
                    keyboardRef={keyboardRef}
                    inputName={inputName}/>
                  }
                  <ButtonsContainer>
                    <SubmitButton
                      onClick={submitForm}
                      disableElevation
                      disabled={(Object.keys(errors).length > 0 || isSubmitting || !(isValid && dirty) &&
                        (type === FORM_TYPE.EDIT ? !tagsAreChanged(contact?.tags, selectedTags) && !memberOfContactsAreChanged(contact, values) : true))}>
                      {t(type === FORM_TYPE.CREATE ? 'add' : 'save')}
                    </SubmitButton>
                  </ButtonsContainer>
                </Form>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </ModalBody>
  );
};
