import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { SelectFilter } from 'components';
import { ACTIVITY_STATUS, INVITATION_STATUS } from 'enums';
import { useInvitationStore } from 'store';
import { InvitationTags, InvitationTemplateType } from 'types';

import { FilterContainer, SearchTextField } from './SearchFilterInvitationsContainer.styled';

type ComponentProps = {
  tags: InvitationTags[],
  types: InvitationTemplateType[]
}

export const SearchFilterInvitationsContainer = ({ tags, types }: ComponentProps) => {
  const [t] = useTranslation();
  const { searchText, setSearchText, params, setParams } = useInvitationStore();

  return (
    <Box component={isMobile ? Box : FilterContainer}>
      <SearchTextField
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        placeholder={t('searchPlaceholder')}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant='outlined'/>
      <SelectFilter
        isDisabledTranslation={true}
        placeholder={t('selectTags')}
        selectedItems={params.selectedTags}
        handleSelectItem={(invTags: string[]) => setParams({...params, selectedTags: invTags})}
        items={tags}/>
      <SelectFilter
        isDisabledTranslation={true}
        placeholder={t('selectTypes')}
        selectedItems={params.selectedTypes}
        handleSelectItem={(invTypes: string[]) => setParams({...params, selectedTypes: invTypes})}
        items={types.map((type: any) => ({id: type.id, title: type.name}))}/>
      <SelectFilter
        placeholder={t('selectStatus')}
        selectedItems={params.selectedStatus}
        handleSelectItem={(invStatus: string[]) => setParams({...params, selectedStatus: invStatus})}
        items={(Object.keys(INVITATION_STATUS) as (keyof typeof INVITATION_STATUS)[]).map((item, index) => ({id: index, title: t(item)}))}/>
      <SelectFilter
        placeholder={t('selectActivityStatus')}
        selectedItems={params.selectedActivityStatuses}
        handleSelectItem={(invActivityStatus: string[]) => setParams({...params, selectedActivityStatuses: invActivityStatus})}
        items={(Object.keys(ACTIVITY_STATUS) as (keyof typeof ACTIVITY_STATUS)[]).map((item, index) => ({id: index, title: t(item)}))}/>
    </Box>
  );
};