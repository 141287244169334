import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const basicIconStyleMobile = {
  borderRadius: '50px',
  fontSize: '30px',
  margin: '0.5rem',
  padding: '0.75rem',
};

export const PageHeaderSelectedIconStyledMobile = {
  ...basicIconStyleMobile,
  background: themeDefault.palette.mainBackground,
  color: themeDefault.palette.whiteColor,
};

export const PageHeaderIconStyleMobile = {
  ...basicIconStyleMobile,
  background: themeDefault.palette.whiteColor,
  color: themeDefault.palette.mainBackground,
};

export const PageHeaderPublishButtonMobileStyle = {
  ...basicIconStyleMobile,
  background: themeDefault.palette.mainColor,
  color: themeDefault.palette.whiteColor,
};

export const PageHeaderContainerMobile = styled(Box)(
  () => ({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    marginTop: '65px',
    overflow: 'auto',
    padding: '20px 10px',
    whiteSpace: 'nowrap',
    '& p': {
      color: themeDefault.palette.mainBackground,
      fontSize: '10px',
      margin: 0,
      textAlign: 'center'
    }
  }),
);

export const PageHeaderContainerTemplate = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
    '& p': {
      color: themeDefault.palette.mainColor,
      fontSize: ' 18px',
      fontWeight: 'bold',
      marginRight: '3rem',
      padding: '0.5rem 3rem',
      '&:hover': {
        backgroundColor: themeDefault.palette.lightMainColor,
        borderRadius: '10px',
        cursor: 'pointer',
        padding: '0.5rem 3rem',
      }
    },
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      padding: '10px auto',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      }
    },
    '@media screen and (max-width:1500px)': {
      padding: '1rem',
      '& p': {
        '&:hover': {
          padding: '0.5rem 6rem',
        },
      },
    }
  }),
);

export const PageHeaderContainer = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
    padding: 0,
  }),
);

export const PageHeaderContainerInvitationActive = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageHeader as CSSProperties,
    height: 'auto',
    width: '100%',
    '& p': {
      padding: '0.5rem 3rem',
      color: themeDefault.palette.mainColor,
      fontSize: ' 18px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: themeDefault.palette.lightMainColor,
        borderRadius: '10px',
        cursor: 'pointer',
        padding: '0.5rem 3rem',
      }
    },
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      padding: '10px auto',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      }
    },
    [theme.breakpoints.down(1500)]: {
      padding: '1rem',
      '& p': {
        fontSize: '1rem',
      }
    },
    [theme.breakpoints.down(1340)]: {
      padding: '0.5rem',
      '& p': {
        padding: '0.5rem 2rem !important',
        '&:hover': {
          padding: '0.5rem 2rem !important',
        },
      },
    },
    [theme.breakpoints.down(1060)]: {
      '& p': {
        padding: '0.5rem 1rem !important',
        '&:hover': {
          padding: '0.5rem 1rem !important',
        },
      },
    },
  }),
);

export const BasicHeaderItem = styled(Typography)(
  ({ theme }) => ({
    borderRadius: '10px',
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    fontWeight: 'bold !important',
    padding: '0.5rem 3rem',
    [theme.breakpoints.down(1340)]: {
      padding: '0.5rem 2rem',
    },
  }),
);

export const PageHeaderSelectedIconStyle = styled(Typography)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.lightMainColor,
    borderRadius: '10px',
    cursor: 'pointer',
    padding: '0.5rem 3rem',
    [theme.breakpoints.down(1340)]: {
      padding: '0.5rem 2rem',
    },
  }),
);

export const PageHeaderDisabledIconStyle = styled(Typography)(
  () => ({
    borderRadius: '10px',
    opacity: 0.5,
    padding: '0.5rem 3rem',
    pointerEvents: 'none',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: `${themeDefault.palette.whiteColor} !important`,
    }
  }),
);

export const ButtonPublishContainer = styled(Grid)(
  ({ theme }) => ({
    marginRight: '40px',
    width: '10%',
    '& button': {
      borderRadius: '10px',
      backgroundColor: themeDefault.palette.mainColor,
      display: 'flex',
      justifyContent: 'space-around',
      padding: '10px auto',
      width: '100%',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColorHover,
        boxShadow: 'none',
      },
    },
    '& p': {
      color: themeDefault.palette.whiteColor,
      fontSize: '1rem',
      fontWeight: 'normal',
      marginLeft: '0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'start',
      display: 'flex',
      height: '100%',
      margin: '1rem 0',
      width: '100%',
      '& button': {
        height: '50%',
        width: 'auto',
      },
    },
  }),
);

export const ButtonPublishContainerMobile = styled(Box)(
  () => ({
    '& p': {
      color: themeDefault.palette.mainColor,
    },
  }),
);

export const PublishButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
  }),
);
