import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GET_INVITATION_BY_ID, graphLazyQueryMiddleware } from 'services';

const InvitationContext = React.createContext({});

export const useInvitation = () => {
  return useContext(InvitationContext);
};
type InvitatioProviderProps = {
  children: ReactNode
}
export const InvitationProvider = ({children}: InvitatioProviderProps) => {
  const [ getInvitation, { data: invitationData }] = graphLazyQueryMiddleware(GET_INVITATION_BY_ID);
  const [ invitationDetails, setInvitationDetails ] = useState(null);
  const navLocation = useLocation();

  useEffect(() => {
    localStorage.getItem('activeId') && !navLocation.pathname.includes('invitationTemplates') &&
    getInvitation({
      variables: {
        id: Number(localStorage.getItem('activeId'))
      }
    }).then(res => {
      setInvitationDetails({
        ...res.data.invitation,
      });
    }).catch(() => setInvitationDetails(null));

  }, [localStorage.getItem('activeId')]);

  useEffect(() => {
    if (invitationData) {
      setInvitationDetails({
        ...invitationData.invitation,
      });
    }
  }, [invitationData]);

  const values = {
    invitationDetails,
    setInvitationDetails
  };
  return (
    <InvitationContext.Provider value={values}>
      {children}
    </InvitationContext.Provider>
  );
};