import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

export const InputRow = styled(Box)(
  ({ theme }) => ({
    ...themeDefault.flexCenter as CSSProperties,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);

export const InputContainerSecond = styled(Box)(
  ({ theme }) => ({
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: 0,
    },
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
  }),
);

export const TitleContainer = styled(Box)(
  ({ theme }) => ({
    marginRight: '5%',
    width: '10%',
    '& div': {
      margin: 0
    },
    '& input': {
      width: '120px'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);

export const InputGroup = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%'
    },
  }),
);

export const InputContainer = styled(Box)(
  ({ theme }) => ({
    width: '48%',
    '& div': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export const AddNewContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const AddNewLabel = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    marginTop: '20px',
    textDecoration: 'underline',
  }),
);

export const DividerFromBottom = styled(Divider)(
  () => ({
    marginTop: '0.5rem',
    marginBottom: '1rem',
  }),
);

export const StreetInputContainer = styled(Box)(
  () => ({
    width: '100%',
    '& div': {
      margin: 0,
    },
  }),
);

export const CityInputContainer = styled(Box)(
  ({ theme }) => ({
    width: '32%',
    '& div': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);
