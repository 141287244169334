import React, { useEffect, useRef, useState } from 'react';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Toolbar
} from '@mui/material';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Menu as MenuIcon } from '@mui/icons-material';

import { ListItemComponent, Loader, VirtualKeyboard } from 'components';
import { useAuth } from 'contexts';
import { USER_PERMISSIONS } from 'enums';
import { checkPermission, getRouteName } from 'helpers';
import { contacts, contactsWhite, invitations, invitationsWhite, logo, logoutIcon, loupe, loupeWhite, user, userWhite, venue, venueWhite } from 'resources';
import { GET_CURRENT_USER, graphQueryMiddleware } from 'services';
import { UseAuthProps } from 'types';

import {
  AppBarStyled, DividerStyled, DrawerStyled, HeaderTitle, IconButtonStyled,
  LanguageSwitch, Layout, LogoContainer, LogoutListItemButton, LogoutWrapper,
  SubmenuSelected, SwitchListItem, VerticalMarginDivider
} from './Sidebar.styled';

interface SidebarProps {
  children: React.ReactNode
}

export const Sidebar = ({ children }: SidebarProps) => {
  const { currentUser, getUserSettingsConfig, logout, setDbCurrentUser } : UseAuthProps = useAuth();
  const { t } = useTranslation();
  const navHistory = useNavigate();
  const navLocation = useLocation();

  const [ invitationsExpanded, setInvitationsExpanded ] = React.useState(false);
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const keyboardRef = useRef(null);
  const [ mobileOpen, setMobileOpen ] = React.useState(false);

  const { loading: userLoading, data: userData } = graphQueryMiddleware(GET_CURRENT_USER, null, !currentUser);

  useEffect(() => {
    if (isHebrew) {
      localStorage.setItem('isHebrew', 'true');
      setShowKeyboard(true);
    } else {
      localStorage.setItem('isHebrew', 'false');
      setShowKeyboard(false);
    }
    window.dispatchEvent(new Event('storage'));
  }, [isHebrew]);

  useEffect(() => {
    if (userData && userData.currentUser) {
      setDbCurrentUser(userData.currentUser);
      getUserSettingsConfig(userData.currentUser.id);
    }
  }, [userData]);

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    setDbCurrentUser(null);
    logout();
  };

  const isSelected = (path: string, alternatePath?: string) => {
    return alternatePath ? navLocation.pathname.includes(alternatePath) || navLocation.pathname === path : navLocation.pathname === path;
  };

  const list = () => (
    <List>
      <LogoContainer>
        <img src={logo} width={'70%'} />
      </LogoContainer>
      <DividerStyled variant='middle' />
      <ListItemComponent impersonated={userData && userData.currentUser?.originalUser} text={userData && userData.currentUser ? `${userData.currentUser.firstName} ${userData.currentUser.lastName}` : ''} icon={isSelected('/settings') ? userWhite : user} isSelected={isSelected('/settings')}
        handleOnClick={() => {
          handleNavigate('/settings');
          handleDrawerToggle();
        }} />
      {userData && checkPermission(userData.currentUser, USER_PERMISSIONS.VIEW_USERS) && <ListItemComponent text={t('users')} icon={isSelected('/userlist') ? contactsWhite : contacts} isSelected={isSelected('/userlist')}
        handleOnClick={() => {
          handleNavigate('/userlist');
          handleDrawerToggle();
        }} />}
      {userData && checkPermission(userData.currentUser, USER_PERMISSIONS.VIEW_CONTACT) && <ListItemComponent text={t('contacts')} icon={isSelected('/contacts') ? contactsWhite : contacts} isSelected={isSelected('/contacts')}
        handleOnClick={() => {
          handleNavigate('/contacts');
          handleDrawerToggle();
        }} />}
      {userData && checkPermission(userData.currentUser, USER_PERMISSIONS.EDIT_VENUES) && <ListItemComponent text={t('venues')} icon={isSelected('/venues') ? venueWhite : venue} isSelected={isSelected('/venues')}
        handleOnClick={() => {
          handleNavigate('/venues');
          handleDrawerToggle();
        }} />}
      <ListItemComponent
        text={t('invitationTemplates')}
        icon={isSelected('/invitationTemplates', '/invitationTemplates') ? loupeWhite : loupe}
        isSelected={isSelected('/invitationTemplates', '/invitationTemplates')}
        handleOnClick={() => {
          handleNavigate('/invitationTemplates');
          handleDrawerToggle();
        }} />
      <ListItemComponent text={t('invitations')} icon={isSelected('/invitationlist', '/invitations') ? invitationsWhite : invitations} isSelected={isSelected('/invitationlist', '/invitations')}
        handleOnClick={() => {
          handleNavigate('/invitationlist');
          handleDrawerToggle();
        }}
        expandInvitations={() => setInvitationsExpanded(!invitationsExpanded)}
        isSubmenuExpanded={invitationsExpanded}
        showSubmenu={true} />

      <Collapse in={invitationsExpanded} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItemButton component={isSelected('/invitations/create/invitation') ? SubmenuSelected : ListItemButton} sx={{ pl: 4 }}>
            <ListItemText primary={t('createNewInvitation')} onClick={() => {
              handleNavigate('/invitations/create/invitation');
              localStorage.removeItem('activeId');
              handleDrawerToggle();
            }}/>
          </ListItemButton>
          <ListItemButton component={isSelected('/invitationlist') ? SubmenuSelected : ListItemButton} sx={{ pl: 4 }} onClick={ () => {
            handleNavigate('/invitationlist');
            handleDrawerToggle();
          }} >
            <ListItemText primary={t('invitationList')} />
          </ListItemButton>
        </List>
      </Collapse>
    </List >
  );

  return (
    <Layout>
      { showKeyboard &&
        <VirtualKeyboard
          inputName='empty'
          setShowKeyboard={setShowKeyboard}
          closeWhenOutOfFocus={showKeyboard}
          keyboardRef={keyboardRef}/>
      }
      <Loader loadingPage={false} inProgress={userLoading} />
      {
        currentUser &&
        navLocation.pathname !== '/confirm-email' &&
        navLocation.pathname !== '/change-password' &&
        navLocation.pathname !== '/invitationContactPreview' &&
        navLocation.pathname !== '/set-password' &&
        navLocation.pathname !== '/gather-contacts' &&
        <>
          {isMobile ?
            <>
              <AppBarStyled position='fixed'>
                <Toolbar>
                  <IconButtonStyled
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={handleDrawerToggle} >
                    <MenuIcon />
                  </IconButtonStyled>
                  <HeaderTitle variant='h6' noWrap>
                    {t(getRouteName(navLocation.pathname))}
                  </HeaderTitle>
                </Toolbar>
              </AppBarStyled>
              <DrawerStyled
                variant='temporary'
                anchor='left'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}>
                {userData && list()}
                <List style={{ marginTop: 'auto' }} >
                  {!isMobile &&
                  <SwitchListItem>
                    <LanguageSwitch
                      value='end'
                      control={<Switch value={isHebrew} onChange={() => setIsHebrew(!isHebrew)} />}
                      label={t('hebrewKeyboard')}
                      labelPlacement='end' />
                  </SwitchListItem>
                  }
                  <VerticalMarginDivider variant='middle' />
                  <LogoutListItemButton onClick={() => handleLogout()}>
                    <LogoutWrapper>
                      <ListItemIcon >
                        <img src={logoutIcon} height={20} width={20} />
                      </ListItemIcon>
                      <ListItemText primary={t('logOut')} />
                    </LogoutWrapper>
                  </LogoutListItemButton>
                </List>
              </DrawerStyled>
            </>
            :
            <DrawerStyled
              variant='permanent'
              open >
              {list()}
              <List style={{ marginTop: 'auto' }} >
                {!isMobile &&
                <SwitchListItem>
                  <LanguageSwitch
                    value='end'
                    control={<Switch value={isHebrew} onChange={() => setIsHebrew(!isHebrew)} />}
                    label={t('hebrewKeyboard')}
                    labelPlacement='end' />
                </SwitchListItem>
                }
                <VerticalMarginDivider variant='middle' />
                <LogoutListItemButton onClick={() => handleLogout()}>
                  <LogoutWrapper>
                    <ListItemIcon>
                      <img src={logoutIcon} height={20} width={20} />
                    </ListItemIcon>
                    <ListItemText primary={t('logOut')} />
                  </LogoutWrapper>
                </LogoutListItemButton>
              </List>
            </DrawerStyled>
          }
        </>
      }
      {children}
    </Layout>

  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.object,
};