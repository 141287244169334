import React, { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  SelectChangeEvent
} from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components';
import { GET_TITLES, graphQueryMiddleware } from 'services';
import { InvitationDetailsFormValuesType, TitleType } from 'types';

import {
  CheckedRadioButton, CloseIconStyled, ContentContainer, FormControlStyled,
  InputContainer, InputContainerHalfWidth, InputGroup, InputLabelActive, InputLabelStyled,
  Label, RadioButton, RadioContainer, RadioStyled, RowContainer, SenderTitle, SubtitleContainer,
  TitleContainer, TitleSelect, UncheckedRadioButton
} from './SenderForm.styled';

type SenderFormFormProps = {
  handleAddNew?: () => void
  index: number
  defaultSender: boolean[]
  values?: InvitationDetailsFormValuesType
  errors?: FormikErrors<InvitationDetailsFormValuesType>,
  setShowKeyboard?: (show: boolean) => void
  setInputName?: (namee: string) => void
  selectDefaultSender: (element: number) => void
  removeSender: (senderId: number, values?: InvitationDetailsFormValuesType, errors?: FormikErrors<InvitationDetailsFormValuesType>) => void
}

export const SenderForm = ({ index, defaultSender, selectDefaultSender, values = null, errors, setShowKeyboard = null, setInputName = null, removeSender } : SenderFormFormProps) => {
  const [t] = useTranslation();
  const { data: titlesData } = graphQueryMiddleware(GET_TITLES);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(`title[${index}]`, values.title[index]);
  }, []);

  return (
    <>
      {isMobile ?
        <ContentContainer>
          <RowContainer>
            <RadioButton
              key={`defaultSender${index}`}
              checked={defaultSender[index]}
              onChange={() => selectDefaultSender(index)}
              control={
                <Radio
                  checkedIcon={<CheckedRadioButton />}
                  icon={<UncheckedRadioButton/>}/>
              }
              label={t('defaultSender')} />
            <SenderTitle> {t('sender')} {index + 1}</SenderTitle>
          </RowContainer>
          <RowContainer>
            {titlesData &&
              <FormControlStyled>
                {values.title[index] ?
                  <InputLabelActive id={`title[${index}]`}>{t('title')}</InputLabelActive> :
                  <InputLabelStyled id={`title[${index}]`}>{t('title')}</InputLabelStyled>
                }
                <TitleSelect
                  value={values.title[index] || ''}
                  onChange={(e: SelectChangeEvent<string>) => setFieldValue(`title[${index}]`, e.target.value)}
                  name={`title[${index}]`} id={`title[${index}]`} >
                  <MenuItem value=''>
                    <em>{t('none')}</em>
                  </MenuItem>
                  {titlesData.titles.map((item: TitleType) =>
                    <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
                  )}
                </TitleSelect>
              </FormControlStyled>}
            <InputContainer>
              <InputField
                errorAbsolutePosition={true}
                inputId={`firstName[${index}]`}
                inputName={`firstName[${index}]`}
                isError={null}
                label={t('firstName')}
                type='text'/>
            </InputContainer>
          </RowContainer>
          <RowContainer>
            <InputContainerHalfWidth />
            <InputContainer>
              <InputField
                errorAbsolutePosition={true}
                inputId={`lastName[${index}]`}
                inputName={`lastName[${index}]`}
                isError={null}
                label={t('lastName')}
                type='text' />
            </InputContainer>
          </RowContainer>
          {values.firstName.length > 1 && <CloseIconStyled onClick={() => removeSender(index, values, errors)}/>}
        </ContentContainer>
        :
        <ContentContainer>
          <SubtitleContainer>
            <Label> {t('sender')} {index + 1}</Label>
          </SubtitleContainer>
          <InputGroup>
            <TitleContainer>
              {titlesData &&
            <FormControl>
              {values.title[index] ?
                <InputLabelActive id={`title[${index}]`}>{t('title')}</InputLabelActive> :
                <InputLabelStyled id={`title[${index}]`}>{t('title')}</InputLabelStyled>
              }
              <TitleSelect
                value={values.title[index] || ''}
                onChange={(e: SelectChangeEvent<string>) => setFieldValue(`title[${index}]`, e.target.value)}
                name={`title[${index}]`} id={`title[${index}]`} >
                <MenuItem value=''>
                  <em>{t('none')}</em>
                </MenuItem>
                {titlesData.titles.map((item: TitleType) =>
                  <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
                )}
              </TitleSelect>
            </FormControl>}
            </TitleContainer>
            <InputContainer>
              <InputField
                setShowKeyboard={setShowKeyboard}
                setInputName={setInputName}
                inputId={`firstName[${index}]`}
                inputName={`firstName[${index}]`}
                isError={null}
                label={t('firstName')}
                type='text'
                errorAbsolutePosition={true}
                value={values['firstName'][index]} />
            </InputContainer>
            <InputContainer>
              <InputField
                setShowKeyboard={setShowKeyboard}
                setInputName={setInputName}
                inputId={`lastName[${index}]`}
                inputName={`lastName[${index}]`}
                isError={null}
                label={t('lastName')}
                type='text'
                errorAbsolutePosition={true}
                value={values['lastName'][index]} />
            </InputContainer>
          </InputGroup>
          <RadioContainer>
            <FormControlLabel
              checked={defaultSender[index]}
              control={
                <RadioStyled
                  onChange={() => selectDefaultSender(index)}
                  name={`defaultSender[${index}]`}
                  checkedIcon={<CheckedRadioButton />}
                  icon={<UncheckedRadioButton />} />
              }
              label={<Label>{t('defaultSender')}</Label>} />
          </RadioContainer>
          {values.firstName.length > 1 && <CloseIconStyled onClick={() => removeSender(index, values, errors)}/>}
        </ContentContainer>
      }
    </>
  );
};