import React, { CSSProperties } from 'react';
import { atcb_action } from 'add-to-calendar-button';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { SUPPORTED_CALENDARS } from 'enums';
import { DATE_FORMAT_INPUT, DEFAULT_EVENT_DURATION_HOURS, TIME_FORMAT_INPUT, getUTCDate } from 'helpers';
import { InvitationProps } from 'types';

import { AddToCalendarButtonStyled, CalendarMonthIconStyled, ExportButtonsContainer } from './AddToCalendar.styled';

type AddToCalendarProps = {
    invitation: InvitationProps,
    buttonId: string,
    styles?: CSSProperties
}

export const AddToCalendar = ({invitation, styles, buttonId}: AddToCalendarProps) => {
  const [t] = useTranslation();
  const { startDate, endDate } = invitation.locationInfo;
  const { name: venueName, displayedAddress } = invitation.locationInfo.venue;

  const invitationStartDate = getUTCDate(startDate);
  const startDateTime = new Date(invitationStartDate);
  const invitationEndDate = endDate ? getUTCDate(endDate) : startDateTime.setHours(startDateTime.getHours() + DEFAULT_EVENT_DURATION_HOURS);

  const addToCalendarButtonConfig = {
    name: invitation.name,
    label: t('addToCalendar'),
    options: Object.values(SUPPORTED_CALENDARS),
    location: `${venueName}, ${displayedAddress}`,
    startDate: format(invitationStartDate, DATE_FORMAT_INPUT),
    endDate: format(invitationEndDate, DATE_FORMAT_INPUT),
    startTime: format(invitationStartDate, TIME_FORMAT_INPUT),
    endTime: format(invitationEndDate, TIME_FORMAT_INPUT),
    timeZone: 'currentBrowser',
    hideBackground: true,
    listStyle: 'overlay' as const,
  };

  const addToCalendarButton = document.getElementById(buttonId);
  if (addToCalendarButton) {
    addToCalendarButton.addEventListener('click', () => atcb_action(addToCalendarButtonConfig, addToCalendarButton));
  }

  return (
    <ExportButtonsContainer style={styles}>
      <AddToCalendarButtonStyled id={buttonId}>
        <CalendarMonthIconStyled />
        {t('addToCalendar')}
      </AddToCalendarButtonStyled>
    </ExportButtonsContainer>
  );
};
