import { Person } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
    lineHeight: 1.8,
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const ContactMobilePaperContainer = styled(Box)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    border: '2px solid transparent',
  }),
);

export const ContactMobilePaperContainerSelected = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    ...themePublic.tableMobilePaperConatinerSelected as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
  }),
);

export const DataInfoContainerMobile = styled(Box)(
  () => ({
    ...themePublic.tableMobileDataInfoConatiner as CSSProperties,
  }),
);

export const ImportingDataInfoContainerMobile = styled(DataInfoContainerMobile)(
  () => ({
    width: '100%',
  }),
);

export const PersonIconStyled = styled(Person)(
  () => ({
    ...themePublic.personIcon as CSSProperties,
  }),
);

export const DataContainerMobile = styled(Box)(
  () => ({
    ...themePublic.dataContainer as CSSProperties,
  }),
);

export const BoldInfoData = styled(Typography)(
  () => ({
    ...themePublic.tableMobileDataInfoBold as CSSProperties,
  }),
);
