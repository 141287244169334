import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
  display: 'unset !important',
  [themeLocal.breakpoints.down('sm')]: {
    display: '-webkit-box !important',
  },
};

export const PointerText = {
  ...themePublic.cursorPointer as CSSProperties,
  paddingTop: 0,
};

export const InvitationPaper = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    padding: '0.5rem 0.5rem 0.5rem 0.1rem',
  }),
);

export const InvitationPaperSelected = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    ...themePublic.tableMobilePaperConatinerSelected as CSSProperties,
    border: '2px solid transparent',
    padding: '0.5rem 0.5rem 0.5rem 0.1rem',
  }),
);

export const CheckCircleWrapper = styled(Box)(
  () => ({
    alignSelf: 'baseline',
    width: '5%',
  }),
);

export const CheckCircleStyled = styled(CheckCircle)(
  () => ({
    color: themeDefault.palette.mainBackground,
  }),
);

export const ExpandedInvitationContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '95%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  }),
);

export const MainInvitationDataConatainer = styled(Box)(
  () => ({
    ...themeDefault.flexCenter as CSSProperties,
    flexDirection: 'row',
    width: '100%',
  }),
);

export const ImagePreviewContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '0.5rem',
    top: '50%',
    width: '30%',
  }),
);

export const InfoContainer = styled(Box)(
  () => ({
    marginLeft: 0,
    width: '70%',
  }),
);

export const InactiveTableRow = styled(TableRow)(
  () => ({
    opacity: 0.5,
  }),
);

export const ActionMenuContainer = styled(Box)(
  () => ({
    ...themePublic.tableActionMenuContainer as CSSProperties,
  }),
);

export const DataContainer = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 0,
  }),
);

export const DataLabel = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    fontWeight: 'bold',
    lineHeight: 1.8,
  }),
);

export const FirstTableCell = styled(TableCell)(
  ({ theme }) => ({
    ...themePublic.firstTableCell as CSSProperties,
    maxWidth: '5vw',
    [theme.breakpoints.between('sm', 950)]: {
      minWidth: '8vw',
    },
    [theme.breakpoints.between(950, 1250)]: {
      minWidth: '7vw',
    },
    [theme.breakpoints.between(1250, 1360)]: {
      minWidth: '6.75vw',
    },
    [theme.breakpoints.between(1360, 1455)]: {
      minWidth: '6.5vw',
    },
  }),
);

export const BasicTableCell = styled(TableCell)(
  ({ theme, style }) => ({
    ...themePublic.basicTableCell as CSSProperties,
    maxWidth: style.width,
    width: style.width,
    whiteSpace: 'nowrap',
    [theme.breakpoints.between('sm', 950)]: {
      maxWidth: '13vw',
    },
    [theme.breakpoints.between(950, 1250)]: {
      maxWidth: '10.5vw',
    },
    [theme.breakpoints.between(1250, 1360)]: {
      maxWidth: '8.5vw',
    },
    [theme.breakpoints.between(1360, 1455)]: {
      maxWidth: '7.525vw',
    },
  }),
);

export const ActiveBasicTableCell = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
  }),
);

export const InactiveBasicTableCell = styled(Typography)(
  () => ({
    color: 'rgba(0, 0, 0, .4)',
  }),
);

export const HiddenElement = styled(TableCell)(
  () => ({
    display: 'none'
  }),
);

export const CheckBoxTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxTableCell as CSSProperties,
  }),
);

export const OptionTableCell = styled(TableCell)(
  () => ({
    ...themePublic.optionTableCell as CSSProperties,
    maxWidth: '3vw',
    width: '3vw',
  }),
);

export const LinkInvitationAutocompleteContainer = styled(Box)(
  () => ({
    marginTop: '16px',
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    marginTop: '10px',
    padding: '8px 30px',
    width: '20%',
  }),
);
