import { isMobile } from 'react-device-detect';
import { PopoverOrigin } from '@mui/material';

import {
  USER_PERMISSIONS, ACTIVITY_STATUS, NUMBER_OF_ITEMS_PER_PAGE,
  NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES, ORDER_PROPERTY, ORDER_TYPE, RESPONSE_STATUS
} from 'enums';
import { ExtendedParams } from 'types';

export const userColumns = [
  {
    id: 0,
    title: 'selectAll',
    enableSorting: false
  },
  {
    id: 1,
    title: 'lastName',
    enableSorting: true,
    sortableName: 'lastName'
  },
  {
    id: 2,
    title: 'firstName',
    enableSorting: true,
    sortableName: 'firstName'
  },
  {
    id: 3,
    title: 'email',
    enableSorting: true,
    sortableName: 'email'
  },
  {
    id: 4,
    title: 'role',
    enableSorting: true,
    sortableName: 'role'
  },
  {
    id: 5,
    title: 'invitations',
    enableSorting: true,
    sortableName: 'invitationsNo'
  },
  {
    id: 6,
    title: 'status',
    enableSorting: true,
    sortableName: 'status'
  },
];

export const venueColumns = [
  {
    id: 0,
    title: 'selectAll',
    enableSorting: false
  },
  {
    id: 1,
    title: 'name',
    enableSorting: true,
    sortableName: 'name'
  },
  {
    id: 2,
    title: 'address1',
    enableSorting: true,
    sortableName: 'address1'
  },
  {
    id: 3,
    title: 'address2',
    enableSorting: true,
    sortableName: 'address2'
  },
  {
    id: 4,
    title: 'city',
    enableSorting: true,
    sortableName: 'city'
  },
  {
    id: 5,
    title: 'state',
    enableSorting: true,
    sortableName: 'state'
  },
  {
    id: 6,
    title: 'zipCode',
    enableSorting: true,
    sortableName: 'zipCode'
  },
  {
    id: 7,
    title: 'status',
    enableSorting: false,
  },
];

export const contactsColumns = [
  {
    id: 0,
    title: 'selectAll',
    enableSorting: false
  },
  {
    id: 1,
    title: 'lastName',
    enableSorting: true,
    sortableName: 'lastName'
  },
  {
    id: 2,
    title: 'firstName',
    enableSorting: true,
    sortableName: 'firstName'
  },
  {
    id: 3,
    title: 'email',
    enableSorting: true,
    sortableName: 'email'
  },
  {
    id: 4,
    title: 'phone',
    enableSorting: true,
    sortableName: 'phoneNumber'
  },
];

export const invitationColumns = [
  {
    id: 0,
    title: 'selectAll',
    enableSorting: false
  },
  {
    id: 1,
    title: 'eventName',
    enableSorting: true,
    sortableName: 'name'
  },
  {
    id: 2,
    title: isMobile ? 'newest' : 'created',
    enableSorting: true,
    sortableName: 'createdAt'
  },
  {
    id: 3,
    title: 'eventDate',
    enableSorting: true,
    sortableName: 'LocationInfo.StartDate'
  },
  {
    id: 4,
    title: 'status',
    enableSorting: true,
    sortableName: 'status'
  },
  {
    id: 5,
    title: 'response',
    enableSorting: true,
    sortableName: 'response'
  },
  {
    id: 6,
    title: 'tags',
    enableSorting: false
  },
  {
    id: 7,
    title: 'link',
    enableSorting: true,
    sortableName: 'EventLink.Name'
  },
  {
    id: 8,
    title: 'createdBy',
    enableSorting: true,
    sortableName: 'CreatedBy',
    permission: USER_PERMISSIONS.VIEW_ALL_INVITATIONS
  },
  {
    id: 9,
    title: '',
    enableSorting: false
  },
];

export const invitationTemplateSortableFields = [
  {
    id: 0,
    title: 'name',
    enableSorting: true,
    sortableName: 'name'
  },
  {
    id: 1,
    title: 'mostPopular',
    enableSorting: true,
    sortableName: 'timesUsed'
  },
  {
    id: 2,
    title: 'newest',
    enableSorting: true,
    sortableName: 'createdAt'
  },
];

export const guestColumnsSendPage = [
  {
    id: 0,
    title: 'selectAll',
    enableSorting: false,
    hideOnPreview: true,
  },
  {
    id: 1,
    title: 'lastName',
    enableSorting: true,
    sortableName: 'Contact.LastName'
  },
  {
    id: 2,
    title: 'firstName',
    enableSorting: true,
    sortableName: 'Contact.FirstName'
  },
  {
    id: 3,
    title: 'email',
    enableSorting: true,
    sortableName: 'Contact.Email'
  },
  {
    id: 4,
    title: 'lastSent',
    enableSorting: true,
    sortableName: 'LastSent'
  },
  {
    id: 5,
    title: 'phone',
    enableSorting: true,
    sortableName: 'Contact.PhoneNumber'
  },
  {
    id: 6,
    title: 'evites',
    enableSorting: false,
  },
  {
    id: 7,
    title: 'sender',
    enableSorting: true,
    sortableName: 'Sender'
  },
  {
    id: 8,
    title: '',
    enableSorting: false,
    hideOnPreview: true,
  },
];

export const guestColumnsTrackPage = [
  {
    id: 0,
    title: 'selectAll',
    enableSorting: false
  },
  {
    id: 1,
    title: 'lastName',
    enableSorting: true,
    sortableName: 'Contact.LastName'
  },
  {
    id: 2,
    title: 'firstName',
    enableSorting: true,
    sortableName: 'Contact.FirstName'
  },
  {
    id: 3,
    title: 'email',
    enableSorting: true,
    sortableName: 'Contact.Email'
  },
  {
    id: 4,
    title: 'phone',
    enableSorting: true,
    sortableName: 'Contact.PhoneNumber'
  },
  {
    id: 5,
    title: 'sender',
    enableSorting: true,
    sortableName: 'Sender'
  },
  {
    id: 6,
    title: 'event',
    enableSorting: true,
    sortableName: 'Invitation.Name'
  }
];

export const lastTableColumForMenu = {
  id: 7,
  title: '',
  enableSorting: false,
};

export const fontSize = [
  10,
  12,
  14,
  18,
  24,
  36,
  48,
  72,
  96,
  120,
  150,
  180,
  220,
  270,
  330,
  400,
];

export const textSpacing = [
  5,
  10,
  20,
  50,
  80,
  100,
  150,
  200,
  350,
  500
];

export const lineSpacing = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10
];

export const contactGroupInitialValues = {
  groupName: '',
  firstName: [''],
  lastName: [''],
  email: [''],
  phoneNumber: [''],
  street: [''],
  city: [''],
  zipCode: [''],
  state: [''],
  title: [''],
  id: [null as number]
};

export const contactInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  street: '',
  city: '',
  zipCode: '',
  state: '',
  title: '',
  id: null as number
};

export const ALIGN_GUIDLINES_LINE_CONST = 0.5;

export const DATE_FORMAT_INPUT = 'yyyy-MM-dd';

export const DEBOUNCE_SEARCH_WAIT_TIME = 500;

export const DEFAULT_EVENT_DURATION_HOURS = 6;

export const DEFAULT_EVENT_DURATION_MINUTES = 0;

export const DEFAULT_FONT_SIZE = 24;

export const FIRST_PAGE = 1;

export const FONT_LINE_SPACING_MIN_VALUE = 5;

export const FONT_MIN_VALUE = 1;

export const FONT_STEP_INCREASE = 0.2;

export const FONT_VALUE_INCREASE = 1;

export const LIMIT_OF_POPULAR_INVITATION_TEMPLATE = 50;

export const LOCAL_DATE_ARGUMENT = 'en-US';

export const MAX_IMAGE_FILE_IN_BYTES = 5000000;

export const MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP = 15;

export const MAX_NUMBER_OF_GIFTS = 5;

export const MAX_NUMBER_OF_ITEMS_TO_GET = 100;

export const MAX_NUMBER_OF_SENDERS = 10;

export const MAX_YEAR = 9999;

export const MUTATION = 'mutation';

export const OPERATION_DEFINITION = 'OperationDefinition';

export const SCALING_COEFFICIENT_NON_RESIZED_IMAGES = 0.4;

export const SUBSCRIPTION = 'subscription';

export const TIME_FORMAT_INPUT = 'HH:mm';

export const decimalNumberRegExp = /^\d+(?:\.*\d{0,1})$/;

export const numberRegExp = /^[0-9\b]+$/;

export const phoneNumberRegExp = /^[\\+]?(\d*)?([ ])?((\d{3})|\(\d{3}\))([- ])?(\d{3})([- ])?(\d{4})$/mg;

export const DefaulContactParams: ExtendedParams = {
  rowsPerPage: NUMBER_OF_ITEMS_PER_PAGE.TEN,
  page: FIRST_PAGE,
  order: ORDER_TYPE.ASC,
  orderBy: ORDER_PROPERTY.LAST_NAME,
  selectedTags: [],
};

export const DefaultGuestParams: ExtendedParams = {
  selectedSenders: [],
  selectedTags: [],
  selectedEvents: [],
  selectedStatus: [],
  selectedEvites: [],
  selectedEvitesResponses: null,
  rowsPerPage: NUMBER_OF_ITEMS_PER_PAGE.TEN,
  page: FIRST_PAGE,
  orderBy: ORDER_PROPERTY.CONTACT_LAST_NAME,
  order: ORDER_TYPE.ASC,
  customOrder: {
    eviteId: -1,
    order: ORDER_TYPE.ASC,
    orderBy: ORDER_PROPERTY.CONTACT_LAST_NAME,
  },
};

export const DefaultInvitationParams: ExtendedParams = {
  selectedTags: [],
  selectedTypes: [],
  selectedStatus: [],
  selectedActivityStatuses: [ACTIVITY_STATUS.ACTIVE],
  rowsPerPage: NUMBER_OF_ITEMS_PER_PAGE.TEN,
  page: FIRST_PAGE,
  order: ORDER_TYPE.DESC,
  orderBy: ORDER_PROPERTY.CREATED_AT,
  customOrder: {
    eviteId: -1,
    order: ORDER_TYPE.DESC,
  },
};

export const DEFAULT_ID = -1;

export const defaultPositionForMenu: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const DefaultTemplateParams: ExtendedParams = {
  rowsPerPage: NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES.TWO,
  page: FIRST_PAGE,
  order: ORDER_TYPE.ASC,
  orderBy: ORDER_PROPERTY.TIMES_USED,
  selectedTags: [],
  selectedTypes: [],
  selectedStatus: [],
  selectedColors: [],
};

export const DefaultUserParams: ExtendedParams = {
  rowsPerPage: NUMBER_OF_ITEMS_PER_PAGE.TEN,
  page: FIRST_PAGE,
  order: ORDER_TYPE.ASC,
  orderBy: ORDER_PROPERTY.LAST_NAME,
  selectedRoles: [],
  selectedStatus: [],
};

export const DefaultVenueParams: ExtendedParams = {
  rowsPerPage: NUMBER_OF_ITEMS_PER_PAGE.TEN,
  page: FIRST_PAGE,
  order: ORDER_TYPE.ASC,
  orderBy: ORDER_PROPERTY.NAME,
};

export const TRACK_CHART_LABELS_CONFIG = [
  RESPONSE_STATUS.NOT_SENT,
  RESPONSE_STATUS.PENDING,
  RESPONSE_STATUS.NOT_ANSWERED,
  RESPONSE_STATUS.ATTENDING,
  RESPONSE_STATUS.NOT_ATTENDING,
  RESPONSE_STATUS.MAZEL_TOW_ONLY,
];

export const COLUMN_TITLES_TRANSLATE = {
  PREFIX: 'csvColumn.',
  KEYS: [ 'groupName', 'firstName', 'lastName', 'email', 'phoneNumber', 'sender' ]
};

export const RSVP_STATUS_TRANSLATE = {
  PREFIX: 'csvStatuses.',
  KEYS: [ 'Attending', 'NotAttending', 'MazelTowOnly', 'NotAnswered', 'Pending', 'NotSent' ]
};
