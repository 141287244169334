import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer',
    position: 'absolute',
    right: '1rem',
    top: '2rem',
    zIndex: 500,
  }),
);

export const ContentContainer = styled(Box)(
  () => ({
    height: '100%',
    '& .pageContent': {
      height: '-webkit-fill-availabl',
    }
  }),
);

export const Title = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 'bold',
    margin: '0 40px',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 40px',
      textAlign: 'center',
    }
  }),
);
