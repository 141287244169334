import React, { CSSProperties } from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { AddToCalendar, CustomTooltip } from 'components';
import { ADD_TO_CALENDAR_IDS } from 'enums';
import { getUTCDate } from 'helpers';

import {
  CloseIconStyled, ContentContainer, CopyIcon, CopyIconWrapper, KeyName, KeyWrapper,
  LabelName, LinkText, LinkWrapper, PersonalGreetingMessage, ShowContentContainerStyle,
  TabWrapper, ThemeOverlappedText, ValueTypographyContainer, ValueWrapper
} from './GiftRegistry.styled';

type InvitationCreatePageHeaderProps = {
  setClose: () => void;
  invitation: any
  showDrawer: boolean
  personalGreetingMessage?: string
}

export const GiftRegistry = ({ setClose, invitation, showDrawer, personalGreetingMessage = null }: InvitationCreatePageHeaderProps) => {
  const [t] = useTranslation();

  const invitationStartDate = getUTCDate(invitation.locationInfo.startDate);
  const invitationEndDate = getUTCDate(invitation.locationInfo.endDate);
  const startDate = format(invitationStartDate, t('dateFormatUI'));
  const startTime = format(invitationStartDate, t('time12HoursFormatUI'));
  const endDate = invitation.locationInfo.endDate ? format(invitationEndDate, t('dateFormatUI')) : '';
  const endTime = invitation.locationInfo.endDate ? format(invitationEndDate, t('time12HoursFormatUI')) : '';

  return (
    <ContentContainer sx={showDrawer && ShowContentContainerStyle}>
      <CloseIconStyled onClick={setClose} />

      {personalGreetingMessage &&
      <>
        <LabelName>- {t('personalGreetingMessage')} -</LabelName>
        <TabWrapper>
          <KeyWrapper>
            <KeyName>{t('message')}</KeyName>
          </KeyWrapper>
          <ValueWrapper>
            <PersonalGreetingMessage>{personalGreetingMessage}</PersonalGreetingMessage>
          </ValueWrapper>
        </TabWrapper>
      </>
      }

      <LabelName>- {t('eventInformation')} -</LabelName>

      <TabWrapper>
        <KeyWrapper>
          <KeyName>{t('eventName')}:</KeyName>
        </KeyWrapper>
        <ValueWrapper>
          <ValueTypographyContainer>
            <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={invitation.name} />
          </ValueTypographyContainer>
        </ValueWrapper>
      </TabWrapper>
      <TabWrapper>
        <KeyWrapper>
          <KeyName>{t('venue')}:</KeyName>
        </KeyWrapper>
        <ValueWrapper>
          <ValueTypographyContainer>
            <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={invitation.locationInfo.venue.name} />
          </ValueTypographyContainer>
        </ValueWrapper>
      </TabWrapper>
      <TabWrapper>
        <KeyWrapper>
          <KeyName>{t('address')}:</KeyName>
        </KeyWrapper>
        <ValueWrapper>
          <ValueTypographyContainer>
            <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={invitation.locationInfo.venue.displayedAddress} />
          </ValueTypographyContainer>
        </ValueWrapper>
      </TabWrapper>
      <TabWrapper>
        <KeyWrapper>
          <KeyName>{startDate === endDate || !endDate ? `${t('date')}:` : `${t('startsAt')}:`}</KeyName>
        </KeyWrapper>
        <ValueWrapper>
          <ValueTypographyContainer>
            <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={startDate === endDate || !endDate ? startDate : `${startDate} ${startTime}`} />
          </ValueTypographyContainer>
        </ValueWrapper>
      </TabWrapper>
      <TabWrapper>
        <KeyWrapper>
          <KeyName>{startDate === endDate || !endDate ? `${t('time')}:` : `${t('endsAt')}:`}</KeyName>
        </KeyWrapper>
        <ValueWrapper>
          <ValueTypographyContainer>
            <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={startDate === endDate || !endDate ? endTime ? `${startTime}  - ${endTime}` : startTime : `${endDate} ${endTime}`} />
          </ValueTypographyContainer>
        </ValueWrapper>
      </TabWrapper>
      {invitation.additionalInfo &&
      <TabWrapper>
        <KeyWrapper>
          <KeyName>{t('additionalInfo')}:</KeyName>
        </KeyWrapper>
        <ValueWrapper>
          <ValueTypographyContainer>
            <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={invitation.additionalInfo} />
          </ValueTypographyContainer>
        </ValueWrapper>
      </TabWrapper>}

      {invitation.gifts.length > 0 &&
        <Box>
          <LabelName>- {t('giftRegistry')} -</LabelName>

          {invitation.gifts.map((gift: { url: string; }, index: number) =>
            <TabWrapper key={index}>
              <LinkWrapper>
                <LinkText onClick={() => window.open(gift.url, '_blank', 'noopener,noreferrer')}>{gift.url}</LinkText>
              </LinkWrapper>
              {!isMobile &&
                <CopyIconWrapper>
                  <CopyIcon onClick={() => navigator.clipboard.writeText(gift.url)} />
                </CopyIconWrapper>}
            </TabWrapper>)}
          <TabWrapper>
            <KeyWrapper>
              <KeyName>{t('giftInfo')}:</KeyName>
            </KeyWrapper>
            <ValueWrapper>
              <ValueTypographyContainer>
                <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={invitation.giftRegistryNote} />
              </ValueTypographyContainer>
            </ValueWrapper>
          </TabWrapper>
        </Box>}

      <TabWrapper>
        <KeyWrapper>
        </KeyWrapper>
        <ValueWrapper>
          <AddToCalendar
            buttonId={ADD_TO_CALENDAR_IDS.GIFT_REGISTRY_CALENDAR_BUTTON}
            styles={{ marginTop: '2rem', padding: '0 1rem', boxSizing: 'border-box' } as CSSProperties }
            invitation={invitation} />
        </ValueWrapper>
      </TabWrapper>
    </ContentContainer>
  );
};
