import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const CanvasContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '100% !important',
};

const FlexContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '100% !important',
};

export const CanvasContainerMobileStyle = {
  height: '40% !important',
};

export const PageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    backgroundColor: 'transparent',
    border: '1px solid rgba(128,128,128, 0.5)',
    borderTop: 0,
    height: '100%',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: themeDefault.defaultBorderRadius,
      flex: 1,
      marginTop: 0,
    }
  }),
);

export const PageButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
    justifyContent: 'space-between',
  }),
);

export const PageNextButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: 'auto',
      width: '50%',
    },
  }),
);

export const PagePrevButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '50%',
    },
  }),
);

export const LastPagePrevButton = styled(PagePrevButton)(
  () => ({
    ...themePublic.maxWidth as CSSProperties,
  }),
);

export const AnimationBox = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.mainBackground,
    border: `2px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '20px',
    boxSizing: 'border-box',
    height: '94vh',
    left: '1vw',
    position: 'fixed',
    top: '1vh',
    width: '98vw',
    zIndex: 1800,
  }),
);

export const AnimationBoxSmall = styled(AnimationBox)(
  () => ({
    height: '82vh !important',
  }),
);

export const AnimationBoxMedium = styled(AnimationBox)(
  () => ({
    height: '86vh !important',
  }),
);

export const CloseAnimationButton = styled(AnimationBox)(
  () => ({
    backgroundColor: themeDefault.palette.mainColor,
    left: '50%',
    padding: '4px 24px',
    transform: 'translate(-50%, -50%)',
    top: 0,
    zIndex: 999999,
  }),
);

export const AnimationWrapper = styled(Box)(
  () => ({
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  }),
);

export const ContentContainer = styled(Box)(
  () => ({
    height: '100%',
  }),
);

export const DataInformation = styled('span')(
  () => ({
    '& p': {
      ...themeDefault.overflowText,
    }
  }),
);

export const TypographyStyled = styled(Typography)(
  ({ theme }) => ({
    color: themeDefault.palette.mainBackground,
    ...themeDefault.overflowText,
    fontSize: '24px',
    maxWidth: '90%',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'center',
    '& p': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  }),
);

export const BigTypographyStyled = styled(TypographyStyled)(
  () => ({
    maxWidth: '95%',
  }),
);

export const SubheaderContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100vw',
    '& p': {
      fontSize: '24px !important',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      '& p': {
        fontSize: '20px !important',
      },
    },
  }),
);

export const NavigationButtonWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '17vh',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '7.5%',
    },
  }),
);

export const NavigationButtonWrapperTemplates = styled(NavigationButtonWrapper)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      height: '10%',
    },
  }),
);

export const NavigationButtonContainer = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '@media screen and (max-height: 820px)': {
      marginBottom: '7vh',
    },
    '@media screen and (max-height: 720px)': {
      marginBottom: '9vh',
    },
    '@media screen and (max-height: 620px)': {
      marginBottom: '11vh',
    },
  }),
);

export const NavigationNextButton = styled(Button)(
  () => ({
    margin: '10px',
    background: themeDefault.palette.mainBackground,
    '&:hover': {
      backgroundColor: themeDefault.palette.mainBackground,
    },
  }),
);

export const OpenButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '0.6rem',
    color: themeDefault.palette.whiteColor,
    padding: '15px',
    width: '25%',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px',
      width: '50%',
    },
  }),
);

export const MainCanvasContainer = styled(Box)(
  ({ theme }) => ({
    height: '55% !important',
    '& #canvasContainer': {
      height: '100%',
    },
    '& .canvas-container': {
      margin: '0px auto',
      transformOrigin: '0 0',
      '& #canvasMain': {
        boxShadow: themeDefault.canvasBoxShadow,
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: '40% !important',
    },
  }),
);

export const ContactPageHeader = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageHeader as CSSProperties,
    height: 'auto',
    justifyContent: 'start',
    '& p': {
      color: themeDefault.palette.blackColor,
      fontSize: ' 16px',
      fontWeight: 'normal',
    },
    '& span': {
      fontSize: ' 16px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: themeDefault.defaultBorderRadius,
      display: 'block',
      flexDirection: 'column',
      gap: '1rem',
      padding: '20px 10px',
      overflow: 'auto',
      whiteSpace: 'nowrap',
      '& p': {
        color: themeDefault.palette.mainBackground,
        fontSize: '16px',
        margin: 0,
        textAlign: 'center',
        width: '100%',
      },
      '& button': {
        backgroundColor: themeDefault.palette.whiteColor,
        borderWidth: '0',
        color: themeDefault.palette.mainColor,
        textDecoration: 'underline',
      },
    }
  }),
);

export const OverflowTypographyContainer = styled(Box)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    display: 'flex',
    justifyContent: 'start',
    '& span': {
      ...themeDefault.overflowText,
      display: 'flex',
      justifyContent: 'start',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      marginTop: '0.5rem',
      maxWidth: '100%',
      paddingLeft: '20px',
      '& span': {
        ...themeDefault.overflowText,
        maxWidth: '85%',
      },
    },
  }),
);

export const LabelName = styled('span')(
  ({ theme }) => ({
    ...FlexContainerStyle as CSSProperties,
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      ...themePublic.boldText as CSSProperties,
      minWidth: '10vw',
    },
  }),
);

export const ClickableLabelName = styled('span')(
  ({ theme }) => ({
    ...FlexContainerStyle as CSSProperties,
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    margin: '0 0.5rem',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      margin: '0 1rem',
      minWidth: '10vw',
    },
  }),
);
