import { Box, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const MenuItemStyle = {
  backgroundColor: themeDefault.palette.whiteColor,
  cursor: 'pointer',
  padding: '5px 10px',
  minHeight: '30px',
  '&:hover': {
    backgroundColor: `${themeDefault.palette.mainColor} !important`,
    color: themeDefault.palette.whiteColor,
  },
};

export const MenuItemTextStyle = {
  ...themeDefault.overflowText,
  margin: 0,
  maxWidth: '100rem',
};

export const RelativePositionContainer = styled(Box)(
  () => ({
    ...themePublic.relativePositionContainer as CSSProperties,
  }),
);

export const ResultContainer = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    boxSizing: 'border-box',
    border: `1px solid ${themeDefault.palette.greyColor}`,
    borderRadius: '10px',
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 200,
  }),
);

export const ListContainer = styled('ul')(
  () => ({
    borderRadius: '10px',
    listStyleType: 'none',
    margin: 0,
    maxHeight: '200px',
    overflowY: 'auto',
    padding: 0,
  }),
);

export const LiFirst = styled('li')(
  () => ({
    ...MenuItemStyle as CSSProperties,
  }),
);

export const ListItem = styled('li')(
  () => ({
    ...MenuItemStyle as CSSProperties,
    borderTop: `1px solid ${themeDefault.palette.greyColor}`,
  }),
);

export const AddNewVenueMenuItem = styled(MenuItem)(
  () => ({
    backgroundColor: themeDefault.palette.lightGreyColor,
    borderBottom: `1px solid ${themeDefault.palette.greyColor}`,
    minHeight: '25px',
  }),
);

export const MenuItemContentContainer = styled(Box)(
  () => ({
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    maxWidth: '-webkit-fill-available',
  }),
);
