import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PaperStyled = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '5px 1rem',
  }),
);
