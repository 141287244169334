import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const errorColor = '#F264A6';

export const themeDefault = {
  defaultBorderRadius: '40px 40px 0 0',
  canvasBoxShadow: 'rgba(0, 0, 0, 0.24) 3px 4px 6px',
  typography: {
    'fontFamily': 'Open Sans',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    blackColor: '#000000',
    borderColor: '#ECEBEB',
    chipGreyColor: '#C1C5CE',
    darkBlue: '#011442',
    darkGreyColor: '#9F9F9F',
    disabledButtonColor: '#9E9E9E',
    disabledColor: '#D7D7D7',
    disabledMainColor: '#E8D8E1',
    errorColor: errorColor,
    fieldLabelColor: '#3F3F3F',
    gloomyLightMainBackground: '#7691C8',
    greyColor: '#B8B8B8',
    lightGreyColor: '#EAEBEE',
    lightGreyColorGroup: '#F8F8F8',
    lightMainBackground: '#8D95A5',
    lightMainColor: '#F6EBF0',
    linkColor: '#3366CC',
    mainBackground: '#33415E',
    mainColor: '#A5416F',
    mainColorHover: '#82385A',
    redColor: '#FF0000',
    secondaryBackgrounColor: '#F0F0F0',
    whiteColor: '#FFFFFF',
  },

  errorMessage: {
    color: errorColor,
    margin: 0,
    fontSize: '12px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  overflowHidden: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  overflowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2,
    width: 'auto',
  },
  multipleLinesTextDisplay: {
    '-webkit-box-orient': 'vertical !important',
    '-webkit-line-clamp': '2 !important',
    display: '-webkit-box !important',
    wordWrap: 'break-word !important'
  },
  stickyBottom: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1000,
  },
  paddingTableCell: {
    p: 0,
    py: '15px'
  },
};

export const baseSwitchButtonStyle = {
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: themeDefault.palette.mainColor,
    [theme.breakpoints.down('sm')]: {
      color: themeDefault.palette.mainBackground,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: themeDefault.palette.mainColor,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.mainBackground
    },
  },
};

export const themePublic = {
  actionButtonContainer: {
    alignItems: 'center',
    bottom: '2px',
    display: 'flex',
    flexDirection: 'row-reverse',
    fontSize: '12px',
    justifyContent: 'space-between',
    position: 'absolute',
    right: '8px',
    width: '3rem',
    '& svg': {
      fill: 'rgba(0,0,0,0.6)',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '-4px',
      right: 0,
    }
  },
  basicFlex: {
    alignItems: 'center',
    display: 'flex',
  },
  pageBody: {
    backgroundColor: themeDefault.palette.secondaryBackgrounColor,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
    padding: '1rem 1.5rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  pageContent: {
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
  },
  pageHeader: {
    alignItems: 'center',
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    height: '90px',
    justifyContent: 'space-between',
    padding: '20px 40px',
    '& p': {
      color: themeDefault.palette.mainColor,
      fontSize: ' 18px',
      fontWeight: 'bold',
    },
    '& button': {
      padding: '10px 36px',
      borderRadius: '4px',
      backgroundColor: themeDefault.palette.mainColor,
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      },
    },
  },
  mobileSearchContainer: {
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '5px 5px 10px 10px',
    marginLeft: '15%',
    position: 'fixed',
    top: '2.5rem',
    width: '70%',
    zIndex: 1100,
    '& input': {
      padding: '10px 12px',
    },
  },
  mobileSearchInput: {
    padding: '10px 12px',
  },
  mobileFiltersSearchInput: {
    width: '100%',
    backgroundColor: themeDefault.palette.whiteColor,
    '& input': {
      borderRadius: '40px',
      padding: '12px',
    },
  },
  searchInputCreateInvitationPage: {
    backgroundColor: themeDefault.palette.whiteColor,
    marginLeft: '15%',
    position: 'fixed',
    top: '2.5rem',
    width: '70%',
    zIndex: 1100,
    '& input': {
      borderRadius: '40px',
      padding: '12px 0',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-formControl': {
      backgroundColor: themeDefault.palette.whiteColor,
      display: 'flex',
      alignItems: 'center',
    },
  },
  addNewEntityButtonMobile: {
    position: 'absolute',
    transform: 'translate(-50%, 50%)',
    display: 'flex',
    justifyContent: 'center',
    left: '50%',
    padding: '4px',
    borderRadius: '50%',
    '& svg': {
      fill: themeDefault.palette.mainColor,
      margin: '0 auto',
    },
  },
  emptyListText: {
    margin: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginTop: '5rem',
      textAlign: 'center'
    },
  },
  loadingStateModalAllowEvents: {
    pointerEvents: 'auto',
  },
  loadingStateModalPreventEvents: {
    pointerEvents: 'none',
  },
  modalHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      justifyContent: 'center',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',
    },
  },
  inputGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  submitButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    backgroundColor: themeDefault.palette.mainColor,
    color: themeDefault.palette.whiteColor,
    width: '40%',
    margin: '0 auto',
    borderRadius: '8px',
    height: '3rem',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor,
    },
    '&:disabled': {
      backgroundColor: themeDefault.palette.mainColor,
      color: themeDefault.palette.whiteColor,
      opacity: 0.4,
    }
  },
  secondarySubmitButton: {
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '0.6rem',
    border: '2px solid',
    borderColor: themeDefault.palette.mainBackground,
    color: themeDefault.palette.mainBackground,
    padding: '0.5rem',
    textTransform: 'capitalize',
    width: '100%',
    '&:hover': {
      backgroundColor: themeDefault.palette.whiteColor,
    },
  },
  paginationSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paginationNextPageArrow: {
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '50%',
    padding: '2px',
    color: themeDefault.palette.mainColor,
  },
  customTooltipText: {
    ...themeDefault.overflowText,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
    },
  },
  confirmButton: {
    width: '35%',
    backgroundColor: themeDefault.palette.mainColor,
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColorHover,
    },
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
  },
  cancelButton: {
    backgroundColor: themeDefault.palette.whiteColor,
    border: `1px solid ${themeDefault.palette.mainColor}`,
    color: themeDefault.palette.mainColor,
    width: '35%',
    '&:hover': {
      border: `1px solid ${themeDefault.palette.mainColor}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
  },
  fullFilterPageContainer: {
    height: 'auto',
    width: '100vw',
    backgroundColor: themeDefault.palette.lightGreyColor,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1250,
  },

  tableBulkOptionsHeader: {
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& p': {
      marginRight: '18px',
      fontSize: '14px',
    },
  },
  tableBulkOptionsCheckbox: {
    marginLeft: '1rem',
  },
  tableBulkOptionsCheckboxSelected: {
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    },
  },
  tableBulkOptionsText: {
    alignItems: 'center',
    cursor: 'pointer',
    color: themeDefault.palette.mainBackground,
    display: 'flex',
    fontSize: '14px',
    paddingTop: 0,
    paddingBottom: 0,
    '& svg': {
      marginRight: '8px',
    },
  },
  tableBulkOptionsMenu: {
    border: `1px solid ${themeDefault.palette.mainBackground}`,
    minWidth: '24px',
    marginleft: '24px',
    padding: 0,
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    },
  },
  tableBulkOptionsMenuItem: {
    color: themeDefault.palette.mainBackground,
    padding: '0 2rem',
    '& svg': {
      fill: themeDefault.palette.mainBackground,
      marginRight: '1rem',
    },
  },
  tableGroupMobilePaperConatiner: {
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  tableMobilePaperConatiner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  tableMobilePaperConatinerSelected: {
    overflow: 'hidden',
    '& p': {
      overflow: 'hidden',
    },
  },
  tableMobileDataInfoConatiner: {
    display: 'flex',
    maxWidth: '90%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      maxWidth: '100%',
    },
  },
  tableMobileDataInfoBold: {
    '& p': {
      fontWeight: 'bold'
    },
  },
  tableStatusCell: {
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
    [theme.breakpoints.down('sm')]: {
      borderTop: 0,
      borderBottom: '0 !important',
    },
  },
  tableActionMenuContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '30px',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
    '& .Mui-selected': {
      borderRadius: '10px',
      border: `1px solid ${themeDefault.palette.borderColor}`,
      backgroundColor: `${themeDefault.palette.whiteColor} !important`,
      '&:hover': {
        backgroundColor: themeDefault.palette.whiteColor,
      },
      '& svg': {
        fill: themeDefault.palette.mainBackground,
      }
    },
    '& td': {
      padding: '5px 8px 5px 0',
    },
    '& tr': {
      maxHeight: '60px',
    },
    '& th': {
      padding: 0,
      paddingBottom: '15px',
      paddingTop: '15px',
    },
  },
  bulkTableHeader: {
    width: '100%',
    backgroundColor: 'whitesmoke',
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
    '& tr': {
      borderRadius: '8px',
      backgroundColor: 'whitesmoke',
    },
  },
  firstTableCellHeader: {
    borderRadius: '8px 0 0 8px',
    width: '50px',
  },
  firstTableCellHeaderSelected: {
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    },
  },
  firstTableCell: {
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderLeft: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
    borderRadius: '10px 0 0 10px',
  },
  firstTableCellClosed: {
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderLeft: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
    borderRadius: '10px 0 0 10px',
  },
  firstTableCellGroup: {
    alignItems: 'center',
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderTop: `1px solid ${themeDefault.palette.darkGreyColor}`,
    borderLeft: `1px solid ${themeDefault.palette.darkGreyColor}`,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
    borderRadius: '10px 0 0 0',
    cursor: 'pointer',
    height: 'inherit',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  openFirstTableCell: {
    ...themeDefault.overflowText,
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
    borderLeft: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
  },
  titleTableCell: {
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
    position: 'relative',
  },
  headerTitleTableCellGroup: {
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderTop: `1px solid ${themeDefault.palette.darkGreyColor}`,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
    position: 'relative',
  },
  basicTableCell: {
    ...themeDefault.overflowText,
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
  },
  headerBasicTableCellGroup: {
    ...themeDefault.overflowText,
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderTop: `1px solid ${themeDefault.palette.darkGreyColor}`,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
  },
  openBasicTableCell: {
    ...themeDefault.overflowText,
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
  },
  lastTableCell: {
    borderRadius: '0 8px 8px 0',
    maxWidth: '3vw',
  },
  lastTableCellGroup: {
    ...themeDefault.overflowText,
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
    borderRight: `1px solid ${themeDefault.palette.borderColor}`,
    borderRadius: '0 10px 10px 0',
  },
  headerLastTableCellGroup: {
    ...themeDefault.overflowText,
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderTop: `1px solid ${themeDefault.palette.darkGreyColor}`,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
    borderRight: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
    borderRadius: '0 10px 0 0',
  },
  openLastTableCell: {
    ...themeDefault.overflowText,
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    borderBottom: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
    borderRight: `1px solid ${themeDefault.palette.darkGreyColor} !important`,
  },
  optionTableCell: {
    borderTop: `1px solid ${themeDefault.palette.borderColor}`,
    borderRight: `1px solid ${themeDefault.palette.borderColor}`,
    borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
    borderRadius: '0 10px 10px 0',
  },
  removedBorderTableHead: {
    '& tr': {
      '& :nth-child(2)': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& first-child': {
        backgroundColor: themeDefault.palette.whiteColor,
      },
    },
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
  },
  rsvpModalIcon: {
    cursor: 'pointer',
    marginRight: '1rem',
    zIndex: 5000,
    fill: 'rgba(0, 0, 0, 0.65)'
  },
  checkBoxHeaderTableCell: {
    width: '50px',
  },
  checkBoxHeaderTableCellSelected: {
    '& svg': {
      '& path': {
        fill: themeDefault.palette.mainBackground,
      }
    }
  },
  checkBoxTableCell: {
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    }
  },
  checkBoxTableCellInRow: {
    borderBottom: 0,
    borderRadius: '10px 0 0 10px',
  },
  importSelectedContactsButton: {
    margin: '10%',
    padding: '12px',
    width: '80%',
  },
  fullPageMobile: {
    alignItems: 'center',
    backgroundColor: themeDefault.palette.whiteColor,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    left: 0,
    minHeight: '100vh',
    padding: '2rem 1rem',
    position: 'absolute',
    top: 0,
    width: '100vw',
    zIndex: 1250,
  },
  importingProcessSearchInput: {
    position: 'relative',
    top: '1rem',
  },
  fixedPosition: {
    position: 'fixed',
  },
  addNewTextButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addNewTextButtonTypography: {
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: '10px 0'
  },
  contactImportModalGoBackButton: {
    borderRadius: '0.6rem',
    marginTop: 'auto',
    width: '100%',
  },
  personIcon: {
    fill: themeDefault.palette.mainColor,
    fontSize: '48px',
  },
  dataContainer: {
    marginLeft: '1rem',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '-webkit-fill-available',
  },
  actionMenuIconStyle: {
    cursor: 'pointer',
    margin: '0 0 0 auto !important',
  },
  extendedItemContainerMobile: {
    alignItems: 'center',
    border: '2px solid transparent',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem',
    padding: '0.5rem',
  },
  extendedArrowContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableBulkHeadRow: {
    '& th': {
      paddingBottom: '15px'
    },
  },
  groupBadge: {
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '50% !important',
    color: themeDefault.palette.whiteColor,
    display: 'inline-block',
    fontSize: '16px',
    height: '24px',
    textAlign: 'center',
    width: '24px',
  },
  searchInputHeader: {
    width: '30vw',
    '& input': {
      padding: '12px'
    },
  },
  chipContainer: {
    height: '2rem',
    padding: '1rem 0',
    width: '100%',
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  filterHeading: {
    backgroundColor: themeDefault.palette.lightGreyColorGroup,
    border: `1px solid ${themeDefault.palette.greyColor}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '13px 21px',
    '& ul': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    '& div': {
      boxShadow: 'none !important',
    },
  },
  filterItemContainer: {
    width: '30vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  filterContent: {
    '& ul': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  filterSearch: {
    margin: '5% auto',
    width: '70%',
    '& input': {
      padding: '12px 0'
    },
  },
  chip: {
    margin: '0.1em',
    width: 'fit-content',
    backgroundColor: themeDefault.palette.chipGreyColor,
    marginRight: '1rem',
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    },
  },
  contactMenagementDividerMenu: {
    marginTop: 0,
    marginBottom: 0,
  },
  contactRowDividerMobile: {
    marginBottom: '10px',
    marginTop: '10px'
  },
  submenuButton: {
    color: themeDefault.palette.mainBackground,
    backgroundColor: themeDefault.palette.whiteColor,
    border: `1px solid ${themeDefault.palette.mainBackground}`,
    fontSize: '1rem',
    marginRight: '40px',
    '& img': {
      marginLeft: '6px',
    },
  },
  submenuContent: {
    '& ul': {
      padding: 0,
      border: `1px solid ${themeDefault.palette.mainBackground}`,
      borderRadius: '4px',
      '& li': {
        lineHeight: 1.75,
      }
    },
    '& img': {
      marginLeft: '6px',
    },
    '& hr': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    }
  },
  submenuContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0',
    position: 'relative',
  },
  invitationTemplateTitle: {
    ...themeDefault.overflowText,
    ...themeDefault.multipleLinesTextDisplay,
    fontSize: '20px',
    marginTop: '2rem',
    maxWidth: '30vh',
    zIndex: '0 !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem !important',
      maxWidth: '15vh',
    },
  },
  pageButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      ...themeDefault.stickyBottom,
      marginTop: 0,
    },
  },
  pageNextButton: {
    backgroundColor: themeDefault.palette.mainColor,
    border: `1px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '0.6rem',
    bottom: '0',
    color: themeDefault.palette.whiteColor,
    height: '2.5em',
    position: 'relative',
    right: '0',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColorHover,
      border: `1px solid ${themeDefault.palette.mainColor}`,
    },
    '&:disabled': {
      color: themeDefault.palette.whiteColor,
      opacity: 0.4,
    },
  },
  pagePrevButton: {
    backgroundColor: themeDefault.palette.whiteColor,
    border: `1px solid ${themeDefault.palette.mainBackground}`,
    borderRadius: '0.6rem',
    color: themeDefault.palette.mainBackground,
    '&:hover': {
      backgroundColor: themeDefault.palette.whiteColor,
    },
    '&:disabled': {
      border: `1px solid ${themeDefault.palette.disabledButtonColor}`,
      opacity: 0.4,
    },
  },
  selectCardButtonDesignPage: {
    alignItems: 'center',
    backgroundColor: `${themeDefault.palette.mainBackground} !important`,
    color: themeDefault.palette.whiteColor,
    cursor: 'default',
    display: 'flex',
    height: '60px',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
    '& span': {
      ...themeDefault.overflowText,
      '& p': {
        ...themeDefault.overflowText,
      },
    },
    '& svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      color: themeDefault.palette.whiteColor,
      display: 'flex',
      flexDirection: 'row',
      height: '45px',
      justifyContent: 'space-between',
      textAlign: 'center',
      '& span': {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  selectCardButtonTooltip: {
    ...themeDefault.overflowText,
    maxHeight: '3rem',
    maxWidth: '20rem',
  },
  editorContainerDesignPage: {
    backgroundColor: themeDefault.palette.whiteColor,
    borderRight: `1px solid ${themeDefault.palette.greyColor}`,
    boxSizing: 'border-box',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  editorElementContainerDesignPage: {
    display: 'flex',
    gap: '1rem',
    height: '60px',
    justifyContent: 'space-between',
    overflow: 'auto',
    padding: '0.5rem',
    whiteSpace: 'nowrap',
    '& p': {
      color: themeDefault.palette.mainBackground,
      fontSize: '10px',
      margin: 0,
      textAlign: 'left',
    }
  },
  editorElementDesignPage: {
    borderBottom: '0.5px solid rgba(0,0,0,0.2)',
    margin: '0 5%',
    [theme.breakpoints.down('sm')]: {
      borderBottomWidth: 0,
      margin: '0 1%',
    },
    [theme.breakpoints.down('up')]: {
      position: 'relative'
    }
  },
  fontEditorNameDesignPage: {
    display: 'inline-block',
    fontSize: '16px',
    margin: '10px 0',
    width: '50%',
  },
  addFontLabelDesignPage: {
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: '2px 0',
    display: 'inline-block',
    width: '50%',
    textAlign: 'end',
    verticalAlign: 'bottom',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '50px',
    }
  },
  formControlSelect: {
    fontSize: '12px',
    height: '25px',
    marginBottom: '10px',
    '& .MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0
    },
  },
  selectCardValueTooltipDesignPage: {
    ...themeDefault.overflowText,
    maxWidth: '10rem',
  },
  fontMenuItemDesignPage: {
    '& ul': {
      maxWidth: '300px',
    },
  },
  editorNameLabelDesignPage: {
    fontSize: '16px',
    margin: '10px 0',
  },
  roundColorElementDesignPage: {
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    marginBottom: '5px',
    marginTop: '5px',
  },
  imagePickerDesignPage: {
    display: 'block',
    position: 'absolute',
    zIndex: '999',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '200px',
      right: 0,
    }
  },
  iconButtonDesignPage: {
    display: 'inline-block',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '25px',
    padding: 0,
    fontSize: '12px',
    margin: '5px 7px 5px 0',
    opacity: 0.5,
    border: 'solid',
    borderWidth: '1px',
    borderRadius: '5px',
  },
  colorTextFieldDesignPage: {
    display: 'inline-flex',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '25px',
    width: '70%',
    fontSize: '12px',
    marginLeft: '15px',
    '& .MuiInputBase-root': {
      height: '25px',
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 4px'
    },
    marginBottom: '5px',
    marginTop: '5px',
  },
  openedSubmenuContainerDesignPage: {
    alignItems: 'center',
    backgroundColor: themeDefault.palette.disabledColor,
    borderTopRightRadius: '10px',
    boxSizing: 'border-box',
    minWidth: '100%',
    paddingLeft: '10px',
    position: 'relative',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderTopRightRadius: 0,
      marginBottom: '10px',
      paddingLeft: '0',
      textAlign: 'center',
      width: '100%',
      '& button': {
        backgroundColor: themeDefault.palette.whiteColor,
      },
    },
    [theme.breakpoints.up('sm')]: {
      height: '60px',
    }
  },
  imageEditorDesignPage: {
    backgroundColor: themeDefault.palette.secondaryBackgrounColor,
    borderBottomRightRadius: '10px',
    borderTopRightRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    overflowX: 'auto',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  moreOptionsContainerDesignPageMobile: {
    backgroundColor: themeDefault.palette.lightMainBackground,
    height: '32px',
    overflow: 'scroll',
    width: '100%',
  },
  lessOptionsContainerDesignPageMobile: {
    backgroundColor: themeDefault.palette.lightMainBackground,
    height: '100px',
    width: '100%',
    overflow: 'scroll',
  },
  moreOptionsTextDesignPage: {
    color: themeDefault.palette.whiteColor,
    float: 'left',
  },
  moreOptionIconButtonDesignPage: {
    backgroundColor: `${themeDefault.palette.lightMainBackground} !important`,
    height: '32px',
    padding: 0,
  },
  moreOptionIconDesignPage: {
    fill: themeDefault.palette.whiteColor,
  },
  selectPlaceholderButton: {
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px !important',
    color: `${themeDefault.palette.blackColor} !important`,
    display: 'flex',
    justifyContent: 'space-between !important',
    margin: 'auto',
    marginBottom: '1rem',
    padding: '10px 20px !important',
    position: 'relative',
    top: '25%',
    transform: 'translateY(-50%)',
    width: '90%',
  },
  menuItemBorderDesignPage: {
    border: 'solid',
    borderColor: themeDefault.palette.disabledButtonColor,
    borderWidth: '0.5px',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    color: themeDefault.palette.mainBackground,
  },
  fontFamilyFormControlDesignPage: {
    fontSize: '12px',
    height: '25px',
    marginBottom: '10px',
    '& .MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    }
  },
  submenuContainerDesignPageMobile: {
    alignItems: 'center',
    display: 'flex',
    height: '60px',
    justifyContent: 'center',
    padding: '0.5rem',
  },
  submenuExtendedContainerDesignPageMobile: {
    height: '100px',
    padding: '0rem 0.5rem 0.5rem 0.5rem',
  },
  submenuButtonDesignPage: {
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    color: themeDefault.palette.blackColor,
    height: '42px',
    padding: '10px 25px',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  submenuButtonIconDesignPage: {
    marginRight: '13px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  disabledIcon: {
    fill: themeDefault.palette.disabledColor,
  },
  toggleContainerDesignPage: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    justifyContent: 'center',
    marginTop: '0.2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      justifyContent: 'space-evenly',
      marginTop: '-0.9rem',
    }
  },
  previewSwitchButtonDesignPage: {
    ...baseSwitchButtonStyle,
    marginRight: '0.5rem',
  },
  containerSubmenuStartButtonsDesignPage: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  containerSubmenuEndButtonsDesignPage: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  submenuMenuItemDesignPage: {
    color: themeDefault.palette.mainBackground,
    border: 'solid',
    borderColor: themeDefault.palette.disabledButtonColor,
    borderWidth: '0.5px',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    transform: 'translateY(-17%)',
    fontSize: '14px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  setBackgroundImageDialogContentDesignPage: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogStyle: {
    textAlign: 'center',
    '& .MuiPaper-root': {
      minWidth: '40vw',
      padding: '1rem',
    }
  },
  bottomCanvasButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  bottomCanvasButtons: {
    borderRadius: '10px',
    color: themeDefault.palette.blackColor,
    cursor: 'pointer',
    marginRight: '20px',
    padding: '5px 5px',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    '&:hover': {
      backgroundColor: themeDefault.palette.whiteColor,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline-grid',
      justifyItems: 'center',
      margin: '0 auto',
      padding: '2%',
      top: '30%',
      transform: 'translateY(0)',
    },
  },
  bottomCanvasButtonsContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomCanvasButtonsMobile: {
    width: '30%',
    backgroundColor: themeDefault.palette.mainColor,
    border: `1px solid ${themeDefault.palette.mainColor}`,
    color: themeDefault.palette.whiteColor,
    margin: '20px auto',
    borderRadius: '0.6rem',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor,
      border: `1px solid ${themeDefault.palette.mainColor}`,
    }
  },
  hiddenElement: {
    opacity: 0,
  },
  canvasContainerDesignPageHidden: {
    '& .canvas-container': {
      display: 'none !important',
    },
  },
  canvasContainerDesignPage: {
    position: 'relative',
    height: '85%',
    '& .canvas-container': {
      transformOrigin: '0 0',
      margin: '1rem auto',
      '& #canvasMain': {
        boxShadow: themeDefault.canvasBoxShadow,
      },
    },
  },
  canvasPreviewImg: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem auto',
    '& img': {
      boxShadow: themeDefault.canvasBoxShadow,
    },
  },
  commentTextModal: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
  maxWidth: {
    width: '100% !important',
  },
  closeAnimationButton: {
    backgroundColor: themeDefault.palette.mainColor,
    left: '50%',
    padding: '4px 24px',
    transform: 'translate(-50%, -50%)',
    top: 0,
    zIndex: 999999,
  },
  overplappedTextPreviewPage: {
    ...themeDefault.overflowText,
    zIndex: '0 !important',
    '& p': {
      ...themeDefault.overflowText,
      zIndex: '0 !important',
    }
  },
  invitationTemplateCardTitle: {
    ...themeDefault.overflowText,
    maxWidth: '30vh',
    fontSize: '20px',
    marginTop: '2rem',
    zIndex: '0 !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem !important',
      maxWidth: '15vh',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  importingContactsModalConatinerPreview: {
    '& .MuiPaper-root': {
      height: '80%',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
  },
  importingContactsModalConatinerSend: {
    '& .MuiPaper-root': {
      width: '80%',
      height: '80%',
      [theme.breakpoints.down('sm')]: {
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
      },
    },
  },
  modalSubmitDefaultButton: {
    padding: '8px 48px',
    float: 'right',
    marginRight: '24px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      margin: '0 9px',
    },
  },
  groupMobilePaperContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  singleContactDataInfoContainerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  contactLabelName: {
    color: themeDefault.palette.mainColor,
    fontWeight: 'bold',
    paddingLeft: '64px',
    paddingRight: '0.5rem',
  },
  contactEvitesContainer: {
    marginRight: 'auto',
    width: '100%',
  },
  contactDataEvitesName: {
    ...themeDefault.overflowText,
    maxWidth: '30vh',
    color: themeDefault.palette.mainColor,
    paddingLeft: '64px',
    paddingRight: '0.5rem',
    '& p': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
  },
  tableRowPreview: {
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: themeDefault.palette.chipGreyColor,
    },
  },
  groupTableRow: {
    height: 48,
    maxHeight: '60px',
  },
  relativePositionContainer: {
    position: 'relative',
  },
  radioButtonSenderDetailsPage: {
    color: themeDefault.palette.mainColor,
    height: '1.5rem',
    paddingLeft: 0,
    width: '1.5rem',
  },
  halfWidth: {
    width: '50% !important',
  },
  pageWithPaperContent: {
    backgroundColor: themeDefault.palette.mainBackground,
    width: '100vw',
    height: '100vh',
  },
  pageWithPaperGridContent: {
    minHeight: '100vh',
    '& .MuiPaper-root': {
      padding: '3rem',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        padding: '2rem 1rem',
      },
    },
  },
  pageWithPaperDivider: {
    margin: '5%',
    width: '50%',
  },
  pageWithPaperTitle: {
    color: themeDefault.palette.mainColor,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  pageWithPaperImageContainer: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '100%'
      }
    },
  },
  importModalStepContainer: {
    borderTop: '5px',
    height: '4px',
    flex: 'inherit',
    margin: '15px 0',
    position: 'relative',
    width: '100%',
  },
  dragAndDropLabelFileUpload: {
    alignItems: 'center',
    borderRadius: '1rem',
    borderWidth: '2px',
    borderColor: themeDefault.palette.disabledColor,
    backgroundColor: themeDefault.palette.secondaryBackgrounColor,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '100%',
    },
  },
  guideIconContainerGuestBulkEditEventDetails: {
    position: 'absolute',
    right: '5rem',
    [theme.breakpoints.down('sm')]: {
      top: '2.2rem',
      left: '2.5rem',
    },
  },
  guideTextContainerGuestBulkEditEventDetails: {
    right: '-10px',
    top: '10px',
    zIndex: 10000,
    [theme.breakpoints.down('sm')]: {
      left: '15px',
      right: 0,
      top: '15px',
    },
  },
  groupNameDivider: {
    marginBottom: '2rem',
    marginTop: '1rem',
  },
  publicContactEntryLinksTableEditRow_SelectFormControl: {
    width: '100%',
    '& div': {
      height: '100%',
    },
  },
  publicContactEntryLinksTableEditRow_ButtonsContainer: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: '1rem',
      marginTop: '1rem',
      width: '100%',
    },
  },
  publicContactEntryLinksTableRow_CopyButton: {
    backgroundColor: themeDefault.palette.whiteColor,
    border: `2px solid ${themeDefault.palette.borderColor}`,
    borderRadius: '10px',
    color: themeDefault.palette.mainBackground,
    padding: '10px',
    '&:hover': {
      backgroundColor: themeDefault.palette.lightMainColor,
      borderColor: themeDefault.palette.mainColor,
    },
  },
  publicContactEntryLinksTableRow_CopyButtonIcon: {
    height: '0.75em',
    marginRight: '0.25em',
    width: '0.75em',
  },
  canvasSidebarFixedSize: {
    width: '15%',
    maxWidth: '15%',
    minWidth: '15%',
    height: '100%',
  },
  buttonWithIcon: {
    backgroundColor: themeDefault.palette.mainColor,
    float: 'right',
    marginLeft: '0.5vw',
    padding: '8px 32px',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColorHover,
      border: `1px solid ${themeDefault.palette.mainColor}`,
      boxShadow: 'none',
    },
    '& img': {
      marginRight: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.25rem',
      marginLeft: 0,
    },
  },
};
