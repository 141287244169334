import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Dialog, DialogContent, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogStyled = styled(Dialog)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      height: '20%',
      left: '5%',
      top: '40%',
      width: '90%',
    }
  }),
);

export const DialogContentStyled = styled(DialogContent)(
  ({ theme }) => ({
    width: '450px',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    top: '15px',
    zIndex: 5000,
  }),
);

export const InputRowContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    marginTop: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  }),
);

export const LabelName = styled('span')(
  () => ({
    whiteSpace: 'nowrap',
    width: '25%',
  }),
);

export const SelecStyled = styled(Select)(
  ({ theme }) => ({
    width: '73%',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }),
);
