import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { Evite } from 'components';
import { MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP } from 'helpers';
import { EviteRSVPType, GuestRSVPModalType, GuestResponseType } from 'types';

import { EvitesContainer, GuestName, MultipleGuestsContainer, SingleGuestContainer } from './Guest.styled';

type GuestProps = {
  guest?: GuestRSVPModalType,
  guestRsvp?: GuestResponseType,
  changeValue: (id: number, value: string, guestId: number) => void
  multipleGuests: boolean
  isEdit?: boolean
};

export const Guest = ({ guest, guestRsvp, changeValue, multipleGuests, isEdit = false }: GuestProps) => {
  return (
    <Box component={multipleGuests ? MultipleGuestsContainer : SingleGuestContainer}>
      {multipleGuests &&
        <Tooltip enterTouchDelay={0} title={guest.name?.length > MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP ? guest.name : ''}>
          <GuestName style={ { borderWidth: guest.name === '' && 0 } }>{isMobile && guest.name ? `- ${guest.name} -` : guest.name}</GuestName>
        </Tooltip>}
      <EvitesContainer style={{width: isEdit ? '100%' : '70%'}}>
        {
          isEdit ?
            guestRsvp.evites[guestRsvp.id].map((evite: EviteRSVPType) =>
              <Evite changeValue={changeValue} guestId={guestRsvp.id} key={`${evite.id}_${guestRsvp.id}`} evite={evite} /> ):
            guest.evites.map((evite: EviteRSVPType) =>
              <Evite changeValue={changeValue} guestId={guest.id} key={`${evite.id}_${guest.id}`} evite={evite} /> )
        }
      </EvitesContainer>
    </Box>
  );
};
