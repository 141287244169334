import { gql } from '@apollo/client';

export const GET_USERS = gql`
query Users($order: [UserPayloadSortInput!], $filter: UsersFilterInput, $skip: Int, $take: Int) {
  users (
    order: $order
    filter: $filter
    skip: $skip
    take: $take
    )
  {
    items {
      id
      firstName
      lastName
      email
      role
      invitationsNo
      status
      permissions
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`;

export const GET_USERS_IDS = gql`
query Users($order: [UserPayloadSortInput!], $filter: UsersFilterInput, $skip: Int, $take: Int) {
  users (
    order: $order
    filter: $filter
    skip: $skip
    take: $take
    )
  {
    items {
      id
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

export const GET_CURRENT_USER = gql`
  query CurrentUser{
    currentUser {
      id
      firstName
      lastName
      email
      firebaseId
      invitationsNo
      status
      role
      permissions
      originalUser {
        firstName
        lastName
        email
        id
      }
    }
  }
`;

export const GET_USER_SETTINGS = gql`
query UserSettings {
  userSettings {
    userId
    value
  }
}
`;

export const GET_USER_BY_TOKEN = gql`
query UserByToken($token: String) {
  userByToken(token: $token) {
    id
    firstName
    lastName
    email
    status
    token
    activationDate
    tokenExpirationDate
  }
}
`;
