import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const OptionPaper = styled(Paper)(
  () => ({
    '& li[aria-selected=true]': {
      backgroundColor: `${themeDefault.palette.lightMainColor} !important`,
      color: themeDefault.palette.mainColor,
      '& svg': {
        fill: themeDefault.palette.mainColor,
      },
    },
  }),
);

export const SelectorContainer = styled(Paper)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const EvitesContainer = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    '& .MuiChip-label': {
      color: themeDefault.palette.mainColor,
    },
    '& .MuiChip-sizeMedium': {
      backgroundColor: themeDefault.palette.lightMainColor,
      '& svg': {
        fill: themeDefault.palette.mainColor,
      }
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: '20vw',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      },
    },
    '& .MuiAutocomplete-input': {
      maxWidth: '40vw',
      minWidth: 'fit-content !important',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      top: '0.9rem',
    },
  }),
);
