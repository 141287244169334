import { CSSProperties } from '@emotion/serialize';
import { Box, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault, themePublic } from 'themeDefault';

export const PublicContactEntryPageContainer = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.mainBackground,
    height: '100vh',
    width: '100vw',
  }),
);

export const MinHeight = styled(Grid)(
  () => ({
    minHeight: '100vh',
  }),
);

export const PublicContactEntryContainer = styled(Paper)(
  ({ theme }) => ({
    maxWidth: '30em',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '2rem 1rem',
      width: '90%',
    },
  }),
);

export const BoxContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '85%',
    },
  }),
);

export const FormContainer = styled(Box)(
  () => ({
    paddingBottom: '1em',
    paddingTop: '2em',
    width: '100%',
  }),
);

export const GroupNameDivider = styled(Box)(
  () => ({
    ...themePublic.groupNameDivider as CSSProperties,
  }),
);

export const SubmissionErrorContainer = styled(Box)(
  () => ({
    marginBottom: '1rem',
  }),
);

export const ButtonContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: '1rem',
      marginBottom: '1rem',
      width: '100%',
    }
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    borderRadius: '8px',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
      marginTop: '1rem',
      width: '100%',
    },
  }),
);
