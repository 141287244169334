import {
  MoreHoriz as MoreHorizIcon
} from '@mui/icons-material';
import {
  Box, Button, DialogContent, Grid, IconButton, ListItemButton, MenuItem,
  Select, Switch, TextField, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const NotDisplayedStyle = {
  display: 'none !important',
};

export const CanvasContainerDesignPageStyle = {
  ...themePublic.canvasContainerDesignPage as CSSProperties,
  '& .canvas-container': {
    transformOrigin: '0 0',
    margin: '0 auto',
    '& #canvasMain': {
      boxShadow: themeDefault.canvasBoxShadow,
    },
  },
};

export const CanvasPreviewImgStyle = {
  ...themePublic.canvasPreviewImg as CSSProperties,
  margin: '0 auto',
};

export const PageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    border: '1px solid rgba(128,128,128, 0.5)',
    borderRadius: '0 10px 10px 0',
    flexDirection: 'unset',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    }
  }),
);

export const StampContainer = styled(Box)(
  () => ({
    ...themePublic.canvasSidebarFixedSize as CSSProperties,
    boxSizing: 'border-box',
    backgroundColor: themeDefault.palette.lightGreyColor,
    borderRight: `1px solid ${themeDefault.palette.greyColor}`,
    float: 'left',
  }),
);

export const HiddenEditorContainer = styled(Box)(
  () => ({
    ...themePublic.canvasSidebarFixedSize as CSSProperties,
    display: 'none',
    float: 'left',
  }),
);

export const EditorContainer = styled(Box)(
  () => ({
    ...themePublic.canvasSidebarFixedSize as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
    borderRight: `1px solid ${themeDefault.palette.greyColor}`,
    boxSizing: 'border-box',
    float: 'left',
  }),
);

export const MoreOptionsContainerMobile = styled(Box)(
  () => ({
    ...themePublic.moreOptionsContainerDesignPageMobile as CSSProperties,
  }),
);

export const LessOptionsContainerMobile = styled(Box)(
  () => ({
    ...themePublic.lessOptionsContainerDesignPageMobile as CSSProperties,
  }),
);

export const SelectCardContainerMobile = styled('div')(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    textAlign: 'center',
    width: '15rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
);

export const SelectCardButton = styled(ListItemButton)(
  () => ({
    ...themePublic.selectCardButtonDesignPage as CSSProperties,
  }),
);

export const EditorElementContainer = styled('div')(
  () => ({
    ...themePublic.editorElementContainerDesignPage as CSSProperties,
  }),
);

export const EditorElementDesignPage = styled('div')(
  () => ({
    ...themePublic.editorElementDesignPage as CSSProperties,
  }),
);

export const FontEditorNameDesignPage = styled(Typography)(
  () => ({
    ...themePublic.fontEditorNameDesignPage as CSSProperties,
  }),
);

export const AddFontLabelDesignPage = styled(Typography)(
  () => ({
    ...themePublic.addFontLabelDesignPage as CSSProperties,
  }),
);

export const FormControlSelect = styled(Select)(
  ({ theme }) => ({
    ...themePublic.formControlSelect as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      margin: '7% 0',
    }
  }),
);

export const SelectCardValueTooltip = styled('p')(
  ({ theme }) => ({
    ...themePublic.selectCardValueTooltipDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '5rem',
    },
  }),
);

export const FontMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.fontMenuItemDesignPage as CSSProperties,
  }),
);

export const EditorNameLabel = styled(Typography)(
  () => ({
    ...themePublic.editorNameLabelDesignPage as CSSProperties,
    margin: '7% 0',
  }),
);

export const RoundColorElement = styled(Box)(
  ({ theme, style }) => ({
    ...themePublic.roundColorElementDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      transform: style.transform,
    }
  }),
);

export const ImagePickerDesignPage = styled('div')(
  () => ({
    ...themePublic.imagePickerDesignPage as CSSProperties,
  }),
);

export const IconButtonStyled = styled(IconButton)(
  ({ theme, style }) => ({
    ...themePublic.iconButtonDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      transform: style.transform,
    }
  }),
);

export const PageNextButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: 'auto',
      width: '50%',
    },
  }),
);

export const PagePrevButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      borderRadius: 0,
    },
  }),
);

export const ColorTextField = styled(TextField)(
  ({ theme, style }) => ({
    ...themePublic.colorTextFieldDesignPage as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      top: '12%',
      transform: style.transform,
      marginLeft: '6px',
      width: '60px',
    }
  }),
);

export const OpenedSubmenuContainer = styled('div')(
  () => ({
    ...themePublic.openedSubmenuContainerDesignPage as CSSProperties,
    width: '100% - 30px',
  }),
);

export const ImageEditorContainer = styled('div')(
  () => ({
    ...themePublic.imageEditorDesignPage as CSSProperties,
    '& .image-editor': {
      width: 'calc(100% - 32px) !important',
      height: 'calc(90% - 32px) !important',
      margin: '16px'
    },
  }),
);

export const MoreOptionsText = styled(Typography)(
  () => ({
    ...themePublic.moreOptionsTextDesignPage as CSSProperties,
    marginLeft: '20px',
    marginTop: '5px',
  }),
);

export const MoreOptionIconButton = styled(IconButton)(
  () => ({
    ...themePublic.moreOptionIconButtonDesignPage as CSSProperties,
    float: 'right',
    marginRight: '20px',
  }),
);

export const MoreHorizIconStyled = styled(MoreHorizIcon)(
  () => ({
    ...themePublic.moreOptionIconDesignPage as CSSProperties,
  }),
);

export const SelectPlaceholderButton = styled(Button)(
  () => ({
    ...themePublic.selectPlaceholderButton as CSSProperties,
  }),
);

export const NotDisplayed = styled(Box)(
  () => ({
    ...NotDisplayedStyle as CSSProperties,
  }),
);

export const PlaceholderMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.menuItemBorderDesignPage as CSSProperties,
  }),
);

export const FontFamilySelectItem = styled(Select)(
  ({ theme }) => ({
    ...themePublic.fontFamilyFormControlDesignPage as CSSProperties,
    margin: '7% 0',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      '& .MuiSelect-select': {
        display: 'list-item',
      },
    },
  }),
);

export const SubmenuContainerMobile = styled(Box)(
  () => ({
    ...themePublic.submenuContainerDesignPageMobile as CSSProperties,
  }),
);

export const SubmenuExtendedContainerMobile = styled(Box)(
  () => ({
    ...themePublic.submenuExtendedContainerDesignPageMobile as CSSProperties,
    alignItems: 'center',
    display: 'flex',
    height: '94px',
    justifyContent: 'space-between',
  }),
);

export const SubmenuButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submenuButtonDesignPage as CSSProperties,
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'inline-flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: 'auto',
      justifyItems: 'center',
      margin: '0',
      padding: '2%',
      transform: 'translateY(0)',
      with: 'auto'
    }
  }),
);

export const SubmenuButtonImageIcon = styled('img')(
  () => ({
    ...themePublic.submenuButtonIconDesignPage as CSSProperties,
  }),
);

export const ToggleContainer = styled(Box)(
  () => ({
    ...themePublic.toggleContainerDesignPage as CSSProperties,
  }),
);

export const PreviewSwitchButton = styled(Switch)(
  () => ({
    ...themePublic.previewSwitchButtonDesignPage as CSSProperties,
  }),
);

export const SubmenuContainer = styled('div')(
  () => ({
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    width: '100%',
  }),
);

export const ContainerSubmenuStartButtons = styled('div')(
  () => ({
    ...themePublic.containerSubmenuStartButtonsDesignPage as CSSProperties,
  }),
);

export const ContainerSubmenuEndButtons = styled('div')(
  () => ({
    ...themePublic.containerSubmenuEndButtonsDesignPage as CSSProperties,
  }),
);

export const SubmenuPlaceholdersMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.submenuMenuItemDesignPage as CSSProperties,
    paddingLeft: '25px',
    paddingRight: '25px',
  }),
);

export const SetBackgroundImageDialogContent = styled(DialogContent)(
  () => ({
    ...themePublic.setBackgroundImageDialogContentDesignPage as CSSProperties,
  }),
);

export const BottomCanvasButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.bottomCanvasButtonsContainer as CSSProperties,
  }),
);

export const BottomCanvasButtons = styled(Button)(
  () => ({
    ...themePublic.bottomCanvasButtons as CSSProperties,
  }),
);

export const PageButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
    justifyContent: 'space-between',
  }),
);

export const StampsContainer = styled(Grid)(
  () => ({
    maxHeight: '690px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: themeDefault.palette.lightGreyColor,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeDefault.palette.darkGreyColor,
    },
  }),
);

export const StampItemImage = styled('img')(
  ({ theme }) => ({
    cursor: 'pointer',
    maxHeight: '100px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    }
  }),
);

export const CanvasWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      boxSizing: 'border-box',
      paddingTop: '15%',
    },
  }),
);

export const ActionButtonContainer = styled(Box)(
  () => ({
    ...themePublic.actionButtonContainer as CSSProperties,
  }),
);
/* // Music Select Button
export const MusicListSelect = styled(Select)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    height: '45px',
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-grid',
      justifyItems: 'center',
      margin: '0 auto',
      padding: '2%',
      transform: 'translateY(0)',
    }
  }),
);

export const MusicListSelectOption = styled(Box)(
  () => ({
    overflow: 'hidden',
  }),
);
 */