import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const ButonStyled = styled(Button)(
  () => ({
    backgroundColor: themeDefault.palette.mainColor,
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColorHover
    }
  }),
);
