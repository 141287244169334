import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Button,
  Dialog,
  FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const DialogStyledAllowedEvents = styled(Dialog)(
  () => ({
    ...themePublic.loadingStateModalAllowEvents as CSSProperties,
    width: '35vw',
  }),
);

export const DialogStyledPreventEvents = styled(Dialog)(
  () => ({
    ...themePublic.loadingStateModalPreventEvents as CSSProperties,
    width: '35vw',
  }),
);

export const ModalHeaderContainer = styled(Box)(
  ({ theme }) => ({
    ...themePublic.modalHeaderContainer as CSSProperties,
    width: '35vw',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer'
  }),
);

export const InputGroupContainer = styled(Box)(
  () => ({
    ...themePublic.inputGroup as CSSProperties,
  }),
);

export const InputSingleMember = styled(Box)(
  ({ theme }) => ({
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  }),
);

export const InputGroupMember = styled(Box)(
  ({ theme }) => ({
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    }
  }),
);

export const SelectGroupContainer = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
  }),
);

export const FormControlStyled = styled(FormControl)(
  () => ({
    marginTop: '1rem',
    width: '100%',
    '& div': {
      height: '100%',
    },
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
    marginTop: '1rem',
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
  }),
);
