import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconButtonStyled = styled(IconButton)(
  ({ theme }) => ({
    border: 'solid',
    borderWidth: '1px',
    borderRadius: '5px',
    display: 'inline-block',
    fontSize: '12px',
    height: '25px',
    margin: '5px 7px 5px 0',
    opacity: 0.5,
    padding: 0,
    position: 'relative',
    top: '50%',
    [theme.breakpoints.down('sm')]: {
      top: '12%',
    },
  }),
);
