import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { InvitationTypeCard, SelectInvitationCards } from 'components';
import { InvitationTemplatesPage } from 'pages';
import { GET_TYPES, graphQueryMiddleware } from 'services';
import { useTemplateStore } from 'store';
import { InvitationTemplateType } from 'types';

import { CardsContainer, PageContent, TypographyStyled, PageContentOnCreating } from './InvitationPage.styled';

type InvitationPageProps = {
  showFilteredinvitationTemplates: boolean
  setShowFilteredinvitationTemplates: (state: boolean) => void
}

export const InvitationPage = ({showFilteredinvitationTemplates, setShowFilteredinvitationTemplates} : InvitationPageProps) => {
  const [t] = useTranslation();

  const { setParams, params } = useTemplateStore();

  const [ showCards, setShowCards ] = useState(Boolean(localStorage.getItem('activeId')));
  const { data: typesData } = graphQueryMiddleware(GET_TYPES);

  const handleSelectInvitationType = (type: InvitationTemplateType) => {
    setShowFilteredinvitationTemplates(!showFilteredinvitationTemplates);
    setParams({...params, selectedTypes: [type.name]});
  };

  useEffect(() => {
    setShowCards(Boolean(localStorage.getItem('activeId')));
  }, [localStorage.getItem('activeId')]);

  const handleShowCards = () => {
    setShowFilteredinvitationTemplates(false);
    setShowCards(false);
  };

  return (
    <>
      {showCards ?
        <SelectInvitationCards handleShowCards={handleShowCards} /> :
        !showFilteredinvitationTemplates &&
          <PageContent>
            <Box>
              <TypographyStyled>{t('selectInvitationType')}</TypographyStyled>
              <CardsContainer container rowSpacing={isMobile ? 2 : 3} columnSpacing={isMobile ? 0 : 4} >
                {typesData?.types.map((type: InvitationTemplateType) => (
                  <Grid item key={type.id} xs={isMobile ? 12 : 3} width={'100%'}>
                    <InvitationTypeCard type={type} handleSelectInvitationType={handleSelectInvitationType} />
                  </Grid>
                ))}
              </CardsContainer>
            </Box>
          </PageContent>
      }
      {
        showFilteredinvitationTemplates && !showCards &&
          <PageContentOnCreating>
            <InvitationTemplatesPage setShowCards={setShowCards} isCreateInvitationProcess={true} />
          </PageContentOnCreating>
      }

    </>
  );
};