import { Person } from '@mui/icons-material';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
    lineHeight: 1.8,
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const ContactGroupMobilePaperContainer = styled(Paper)(
  () => ({
    ...themePublic.tableGroupMobilePaperConatiner as CSSProperties,
    border: '2px solid transparent',
  }),
);

export const ContactGroupMobilePaperContainerSelected = styled(Paper)(
  () => ({
    ...themePublic.tableGroupMobilePaperConatiner as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
  }),
);

export const GroupMobilePaperContent = styled(Paper)(
  () => ({
    ...themePublic.groupMobilePaperContent as CSSProperties,
    boxShadow: 'none',
  }),
);

export const PersonIconStyled = styled(Person)(
  () => ({
    ...themePublic.personIcon as CSSProperties,
  }),
);

export const DataContainerMobile = styled(Box)(
  () => ({
    ...themePublic.dataContainer as CSSProperties,
  }),
);

export const BoldInfoData = styled(Typography)(
  () => ({
    ...themePublic.tableMobileDataInfoBold as CSSProperties,
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactRowDividerMobile as CSSProperties,
  }),
);

export const ExtendedItemContainer = styled(Box)(
  () => ({
    ...themePublic.extendedItemContainerMobile as CSSProperties,
  }),
);

export const ExtendedArrowContainer = styled(Box)(
  () => ({
    ...themePublic.extendedArrowContainerMobile as CSSProperties,
  }),
);

export const FullEditPageContainer = styled(Box)(
  () => ({
    ...themePublic.fullFilterPageContainer as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    padding: '2rem 1rem',
  }),
);
