import React, { useEffect, useState } from 'react';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Dialog, DialogContent, Paper, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ActionMenu, ConfirmationModal, ContactsModal, CustomTooltip } from 'components';
import { FORM_TYPE } from 'enums';
import { calculateMenuPlacement } from 'helpers';
import { DELETE_CONTACTS, GET_TAGS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { themePublic } from 'themeDefault';
import { useContactStore } from 'store';
import { ContactType } from 'types';

import {
  BoldInfoData, ContactGroupMobilePaperContainer, ContactGroupMobilePaperContainerSelected, DataContainerMobile, DividerStyled,
  ExtendedArrowContainer, ExtendedItemContainer, FullEditPageContainer, GroupMobilePaperContent,
  PersonIconStyled, ThemeMultipleLinesText, ThemeOverlappedText
} from './GroupContactItem.styled';

type ContactsTableProps = {
  handleSelectedContacts: (id: number) => void
  selectedContacts: number[],
  contact: ContactType,
  importingProcess: boolean,
}

export const GroupContactItem = ({ contact, handleSelectedContacts, selectedContacts, importingProcess }: ContactsTableProps) => {
  const [t] = useTranslation();
  const { changeExecuteRefetch } = useContactStore();

  const [ deleteContact, { loading: deleteLoading }] = graphMutationMiddleware(DELETE_CONTACTS);
  const [ getTags, { data: tagData }] = graphLazyQueryMiddleware(GET_TAGS);

  const [ groupModalOpen, setGroupModalOpen ] = useState(false);
  const [ deleteModal, setDeleteModal ] = useState(false);
  const [ extendGroup, setExtendGroup ] = useState(false);

  useEffect(() => {
    getTags({ variables: {
      filter: {
        type: 'CONTACT'
      }
    }});
  }, []);

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: Edit,
      functionHandler: () => setGroupModalOpen(true)
    },
    {
      id: 1,
      title: t('delete'),
      icon: Delete,
      functionHandler: () => setDeleteModal(true)
    },
  ];

  const handleDelete = () => {
    deleteContact({variables: {
      contactId: contact.id
    }}).then(() => {
      changeExecuteRefetch();
      setDeleteModal(false);
    });
  };

  return (
    <>
      <Paper component={selectedContacts.includes(contact.id) ? ContactGroupMobilePaperContainerSelected : ContactGroupMobilePaperContainer} elevation={2}>
        <GroupMobilePaperContent>
          <PersonIconStyled/>
          <DataContainerMobile onClick={() => handleSelectedContacts(contact.id)}>
            <BoldInfoData>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={contact.lastName} />
            </BoldInfoData>
            <Typography>
              <CustomTooltip theme={ThemeOverlappedText} text={contact.phoneNumber} />
            </Typography>
            <Typography>
              <CustomTooltip theme={ThemeOverlappedText} text={contact.email} />
            </Typography>
          </DataContainerMobile>
          {!importingProcess &&
            <ActionMenu iconTheme={themePublic.actionMenuIconStyle} propsData={actionMenuProps} menuPlacement={calculateMenuPlacement(isMobile)} />
          }
        </GroupMobilePaperContent>
        {extendGroup &&
        <>
          <DividerStyled/>
          {contact.contacts.map(item => (
            <ExtendedItemContainer key={item.id}>
              <PersonIconStyled/>
              <DataContainerMobile onClick={() => handleSelectedContacts(contact.id)}>
                <BoldInfoData>
                  <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={`${item.lastName} ${item.firstName}`} />
                </BoldInfoData>
                <Typography>
                  <CustomTooltip theme={ThemeOverlappedText} text={item.phoneNumber} />
                </Typography>
                <Typography>
                  <CustomTooltip theme={ThemeOverlappedText} text={item.email} />
                </Typography>
              </DataContainerMobile>
            </ExtendedItemContainer>
          ))}
        </>}
        <ExtendedArrowContainer onClick={() => setExtendGroup(!extendGroup)}>
          {extendGroup ? <KeyboardArrowUp/> : <KeyboardArrowDown /> }
        </ExtendedArrowContainer>
      </Paper>
      <ConfirmationModal
        loading={deleteLoading}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleDelete}
        confimMessage={`${t('deletionConfirmMessage')} ${contact.firstName || ''} ${contact.lastName || ''}`}/>
      {groupModalOpen &&
      <Dialog maxWidth='xl' fullScreen={isMobile} open={groupModalOpen} onClose={() => setGroupModalOpen(false)}>
        <DialogContent>
          <FullEditPageContainer>
            <ContactsModal type={FORM_TYPE.EDIT} tagData={tagData} handleClose={() => setGroupModalOpen(false)} contact={contact} />
          </FullEditPageContainer>
        </DialogContent>
      </Dialog>
      }
    </>
  );
};