import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const FullPageContainer = styled(Box)(
  () => ({
    ...themePublic.fullFilterPageContainer as CSSProperties,
    minHeight: '100vh',
  }),
);

export const ShiftedFullPageContainer = styled(Button)(
  () => ({
    ...themePublic.fullFilterPageContainer as CSSProperties,
    marginTop: '-218px',
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    ...themePublic.closeIcon as CSSProperties,
  }),
);

export const TitleTypography = styled(Typography)(
  () => ({
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 700,
    marginTop: '10%',
  }),
);

export const SearchContainer = styled(Box)(
  () => ({
    width: '70%',
    margin: '5% auto',
  }),
);

export const SelectContainer = styled(Box)(
  () => ({
    marginTop: '40%',
    zIndex: 1500,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '78%',
    alignItems: 'center',
  }),
);

export const LabelTypography = styled(Typography)(
  () => ({
    width: '80%',
    margin: '0 auto',
  }),
);

export const SortContainer = styled(Box)(
  () => ({
    zIndex: 1500,
    margin: '0 auto',
    display: 'flex',
    color: themeDefault.palette.blackColor,
    justifyContent: 'center',
    width: '80%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: `${themeDefault.palette.whiteColor} !important`,
      margin: '0 1rem 1rem 1rem',
      '& p': {
        color: themeDefault.palette.blackColor,
      }
    },
    '& .MuiSelect-outlined': {
      color: themeDefault.palette.blackColor,
    },
  }),
);

export const ApplyButton = styled(Button)(
  () => ({
    backgroundColor: `${themeDefault.palette.mainColor} !important`,
    position: 'absolute',
    width: '70%',
    margin: '0 15%',
    bottom: '2rem',
  }),
);
