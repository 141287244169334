import { gql } from '@apollo/client';

export const UPLOAD_FILE = gql`
mutation UploadFile ($file: Upload, $type: DocumentType!){
  uploadFile (file: $file, type: $type) {
    id
    name
    url
    thumbnailUrl
    key
  }
}
`;

export const UPLOAD_GLOBAL_FILE = gql`
mutation UploadGlobalFile ($file: Upload, $type: DocumentType!){
  uploadGlobalFile (file: $file, type: $type) {
    id
    name
    url
    thumbnailUrl
    key
  }
}
`;
