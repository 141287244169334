import { CSSProperties } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault, themePublic } from 'themeDefault';

export const PageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    marginTop: '2rem',
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: themeDefault.defaultBorderRadius,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '1rem 0',
    }
  }),
);

export const PageContentOnCreating = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    marginTop: '2rem',
    padding: '0',
    backgroundColor: 'unset',
    [theme.breakpoints.down('sm')]: {
      borderRadius: themeDefault.defaultBorderRadius,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      paddingTop: '1rem',
    }
  }),
);

export const TypographyStyled = styled(Typography)(
  ({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
    }
  }),
);

export const CardsContainer = styled(Grid)(
  ({ theme }) => ({
    margin: '3rem auto',
    paddingRight: '2rem',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 0,
      padding: '1rem',
      width: '100%',
    }
  }),
);
