import React, { CSSProperties, useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { FormikErrors } from 'formik';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, InputField, VenueModal } from 'components';
import { FORM_TYPE, VENUE_STATUS } from 'enums';
import { VenueFormValuesType, VenueProps } from 'types';

import {
  AddNewVenueMenuItem, ListContainer, ListItem, MenuItemContentContainer, MenuItemTextStyle,
  RelativePositionContainer, ResultContainer
} from './AutocompleteCustom.styled';

type AutocompleteProps = {
    values?: any,
    list?: any,
    disabled?: boolean,
    handleClick?: any,
    inputId: string,
    inputName: string,
    inputProps?: boolean,
    isError?: string | string[] | FormikErrors<any> | FormikErrors<any>[],
    isOpenVenueModal: boolean,
    label?: string,
    maxRows?: number,
    placeholder?: string,
    rows?: number,
    showInputText?: boolean,
    type: string,
    selectedItem: VenueProps,
    setSelectedItem: (selectedItem: VenueProps) => void,
    venueModalType: FORM_TYPE,
    setInputName?: (fieldName: string) => void,
    handleChange?: (value: any) => void,
    setShowKeyboard?: (show: boolean) => void,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => void,
    setErrors?: (errors: any) => void,
    setIsOpenVenueModal: (isOpenVenueModal: boolean) => void,
    setVenueModalType: (venueModalType: FORM_TYPE) => void,
    errors?: any,
  }

export const AutocompleteCustom = (
  {
    values = null,
    disabled,
    handleClick,
    inputId,
    inputName,
    inputProps,
    isError,
    isOpenVenueModal,
    label,
    maxRows,
    placeholder,
    rows,
    showInputText,
    type,
    selectedItem,
    setSelectedItem,
    venueModalType,
    setInputName = null,
    setShowKeyboard = null,
    list = null,
    setFieldValue = null,
    setErrors = null,
    setIsOpenVenueModal,
    errors = null,
    handleChange = null,
    setVenueModalType
  } : AutocompleteProps ) => {
  const [t] = useTranslation();

  const [ showDropdown, setShowDropdown ] = useState(false);

  useEffect(() => {
    handleDropdownClicked(showDropdown);
  }, [showDropdown]);

  const resetLocation = () => {
    setFieldValue('location', '');
    setSelectedItem(null);
  };

  useEffect(() => {
    if (values[inputName] === '') {
      resetLocation();
    }
    handleChange(values[inputName]);
  }, [values[inputName]]);

  const setInputFieldValue = (val: VenueFormValuesType) => {
    setSelectedItem(val);
    setShowDropdown(false);
    handleDropdownClicked(false);
    const newErrors = {
      ...errors
    };
    newErrors.venueName && delete newErrors.venueName;
    newErrors.location && delete newErrors.location;
    flushSync(() => {
      setFieldValue('venueName', val.name);
    });
    flushSync(() => {
      setFieldValue('location', val.displayedAddress);
    });
    setErrors(newErrors);
  };

  const handleOutsideDropdownClick = (e: any) => {
    if (document.getElementById(inputName)?.contains(e.target) ||
    document.getElementById(inputName + 'keyboard')?.contains(e.target)) {
      return;
    }
    setShowDropdown(false);
    handleDropdownClicked(false);
  };

  const handleDropdownClicked = (isOpened: boolean) => {
    if (isOpened) {
      document.addEventListener('mousedown', handleOutsideDropdownClick, false);
    } else {
      document.removeEventListener('mousedown', handleOutsideDropdownClick, false);
    }
  };

  const getInitialVenue = () => {
    switch (venueModalType) {
      case FORM_TYPE.EDIT_PRIVATE:
        return selectedItem;
      case FORM_TYPE.CREATE_PRIVATE:
      default:
        return {
          name: values['venueName'],
          address1: values['location'],
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          status: VENUE_STATUS.PRIVATE.toUpperCase(),
        };
    }
  };

  return (
    <RelativePositionContainer id={inputName}>
      <InputField
        errorAbsolutePosition={true}
        boxStyles={{marginTop: '0 !important'}}
        disabled={disabled}
        handleClick={handleClick}
        inputId={inputId}
        inputName={inputName}
        inputProps={inputProps}
        isError={isError}
        label={label}
        maxRows={maxRows}
        placeholder={placeholder}
        rows={rows}
        showInputText={showInputText}
        type={type}
        setInputName={setInputName}
        setShowKeyboard={setShowKeyboard}
        setShowDropdown={setShowDropdown} />
      {showDropdown &&
        <ResultContainer>
          <ListContainer>
            <AddNewVenueMenuItem
              key='addVenueLabelButton'
              as={ListItem}
              onClick={() => {
                setIsOpenVenueModal(true);
                setVenueModalType(FORM_TYPE.CREATE_PRIVATE);
              }}>
              {t('addVenueLabelButton')}
            </AddNewVenueMenuItem>
            {list.map((value: VenueProps, index: number) => {
              return (
                <MenuItem
                  key={index}
                  component={ListItem}
                  onClick={() => setInputFieldValue(value)}>
                  <MenuItemContentContainer>
                    <CustomTooltip
                      text={`${t('nameAutocomplete')} ${value.name}`}
                      theme={MenuItemTextStyle as CSSProperties} />
                    <CustomTooltip
                      text={`${t('locationAutocomplete')} ${value.displayedAddress}`}
                      theme={MenuItemTextStyle as CSSProperties} />
                  </MenuItemContentContainer>
                </MenuItem>
              );
            })}
          </ListContainer>
        </ResultContainer>}
      <VenueModal
        isOpen={isOpenVenueModal}
        type={venueModalType}
        venue={getInitialVenue()}
        handleClose={(newVenue: VenueProps) => {
          setIsOpenVenueModal(false);
          if (newVenue?.id) {
            setInputFieldValue(newVenue);
            handleChange(values[inputName]);
          }
        }}/>
    </RelativePositionContainer>
  );
};