import React, { ElementType, useState } from 'react';
import {
  Checkbox,
  Divider,
  Menu,
  TableCell,
  TableCellBaseProps,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'contexts';
import { USER_SETTINGS_CONFIG } from 'enums';
import { handleOrder, userColumns } from 'helpers';
import { useUsersStore } from 'store';
import { TableColumnProps, UseAuthProps } from 'types';

import {
  MoreHoriz as MoreHorizIcon,
  PersonAdd as PersonAddIcon,
  PersonRemove as PersonRemoveIcon
} from '@mui/icons-material';
import {
  BulkTableHead, CheckBoxHeaderTableCell, CheckBoxHeaderTableCellSelected,
  FirstTableCellHeader, FirstTableCellHeaderSelected, HeaderBulkOptionTableCell,
  ImageTableCell, LastTableCell, RemovedBorderTableHead, TableBulkOptionsCheckbox,
  TableBulkOptionsCheckboxSelected, TableBulkOptionsHeader, TableBulkOptionsMenu,
  TableBulkOptionsMenuItem, TableBulkOptionsText, TitleHeaderTableCell
} from './UserTableHead.styled';

type UserTableHeadProps = {
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
  selectedUsersLength: number,
  isSelectedAll: boolean,
  handleBulkStatus: (isActivate: boolean) => void
}

export const UserTableHead = ({handleSelectAll, selectedUsersLength, isSelectedAll, handleBulkStatus}: UserTableHeadProps) => {
  const [t] = useTranslation();
  const { saveUserSettings }: UseAuthProps = useAuth();
  const { params, setParams } = useUsersStore();

  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  return (
    isMobile ?
      <TableBulkOptionsHeader>
        {selectedUsersLength > 0 && isSelectedAll ?
          <TableBulkOptionsCheckboxSelected
            checked={selectedUsersLength > 0 && isSelectedAll}
            onChange={(e) => handleSelectAll(e)}/> :
          <TableBulkOptionsCheckbox
            checked={selectedUsersLength > 0 && isSelectedAll}
            onChange={(e) => handleSelectAll(e)}/>}
        {
          selectedUsersLength > 0 ?
            <>
              <TableBulkOptionsText> {`${selectedUsersLength} ${t('selected')}`} </TableBulkOptionsText>
              <TableBulkOptionsMenu onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreHorizIcon />
              </TableBulkOptionsMenu>
              <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
                <TableBulkOptionsMenuItem onClick={() => {
                  handleBulkStatus(true);
                  setAnchorEl(null);
                }}>
                  <PersonAddIcon/>{t('activate')}
                </TableBulkOptionsMenuItem>
                <Divider />
                <TableBulkOptionsMenuItem onClick={() => {
                  handleBulkStatus(false);
                  setAnchorEl(null);
                }}>
                  <PersonRemoveIcon/>{t('deactivate')}
                </TableBulkOptionsMenuItem>
              </Menu>
            </>
            :
            <Typography >{t('selectAll')}</Typography>
        }
      </TableBulkOptionsHeader>
      :
      selectedUsersLength > 0 ?
        <BulkTableHead>
          <TableRow>
            <TableCell component={(isSelectedAll ? FirstTableCellHeaderSelected : FirstTableCellHeader) as ElementType<TableCellBaseProps>}>
              <Checkbox onChange={handleSelectAll} checked={isSelectedAll && selectedUsersLength > 0} />
            </TableCell>
            <ImageTableCell>
              <TableBulkOptionsText> {`${selectedUsersLength} ${t('selected')}`} </TableBulkOptionsText>
            </ImageTableCell>
            <HeaderBulkOptionTableCell>
              <TableBulkOptionsText onClick={() => handleBulkStatus(true)}>
                <PersonAddIcon/>{t('activate')}
              </TableBulkOptionsText>
            </HeaderBulkOptionTableCell>
            <HeaderBulkOptionTableCell>
              <TableBulkOptionsText onClick={() => handleBulkStatus(false)}>
                <PersonRemoveIcon/>{t('deactivate')}
              </TableBulkOptionsText>
            </HeaderBulkOptionTableCell>
            <HeaderBulkOptionTableCell/>
            <HeaderBulkOptionTableCell/>
            <HeaderBulkOptionTableCell/>
            <HeaderBulkOptionTableCell/>
            <LastTableCell/>
          </TableRow>
        </BulkTableHead> :
        <RemovedBorderTableHead>
          <TableRow>
            <TableCell component={(isSelectedAll && selectedUsersLength > 0 ? CheckBoxHeaderTableCellSelected : CheckBoxHeaderTableCell) as ElementType<TableCellBaseProps>}>
              <Checkbox onChange={(e) => handleSelectAll(e)} checked={isSelectedAll && selectedUsersLength > 0 } />
            </TableCell>
            {userColumns.map((column: TableColumnProps) => (
              <TableCell key={column.id}component={(column.id === 0 ? ImageTableCell : TitleHeaderTableCell) as ElementType<TableCellBaseProps>} >
                {t(column.title)}
                {
                  column.enableSorting &&
                  <TableSortLabel
                    active={params.orderBy === column.sortableName}
                    direction={params.order}
                    onClick={() => {
                      setParams({
                        ...params,
                        order: handleOrder(params.order),
                        orderBy: column.sortableName
                      });
                      saveUserSettings(USER_SETTINGS_CONFIG.USERS_TABLE, column.sortableName, handleOrder(params.order));
                    }}>
                  </TableSortLabel>
                }
              </TableCell>
            ))}
            <LastTableCell />
          </TableRow>
        </RemovedBorderTableHead>
  );
};

