import { Close as CloseIcon } from '@mui/icons-material';
import { Button, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const EditEventDetailsContainer = styled(DialogContent)(
  ({ theme }) => ({
    boxSizing: 'border-box',
    padding: '1rem 2.5rem',
    width: '40vw',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '7vh',
      width: '100%',
    }
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  ({ theme }) => ({
    cursor: 'pointer',
    position: 'absolute',
    right: '1rem',
    [theme.breakpoints.down('sm')]: {
      right: '2rem',
      top: '2rem',
      transform: 'scale(1.3)'
    }
  }),
);

export const EventInfoLabel = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
    }
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    float: 'right',
    marginTop: '1rem',
    padding: '8px 48px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      marginTop: '2rem',
      width: '100%',
    },
  }),
);
