import { AddCircle, Cancel, Delete, Edit, Link, Send } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, Dialog, DialogContent, Paper, TableCell, TableCellBaseProps, TableRow, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { CSSProperties, ElementType, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, ConfirmationModal, CustomTooltip, ImagePreview, SelectInvitation, VirtualKeyboard } from 'components';
import { useAuth } from 'contexts';
import { ACTIVITY_STATUS, INVITATION_STATUS, ORDER_PROPERTY, ORDER_TYPE, USER_PERMISSIONS } from 'enums';
import {
  DEBOUNCE_SEARCH_WAIT_TIME,
  LOCAL_DATE_ARGUMENT,
  MAX_NUMBER_OF_ITEMS_TO_GET,
  calculateMenuPlacement,
  checkPermission,
  disableInvitationTab,
  formatDate,
  isCurrentUsersInvitation,
  isEmptyStringOrNull
} from 'helpers';
import {
  GET_INVITATIONS,
  REMOVE_INVITATION,
  UPDATE_INVITATION,
  UPDATE_INVITATION_ACTIVITY_STATUS,
  graphLazyQueryMiddleware,
  graphMutationMiddleware
} from 'services';
import { themePublic } from 'themeDefault';
import { EventLinkType, InvitationProps, TagType, UseAuthProps } from 'types';
import { useInvitationStore } from 'store';

import {
  ActionMenuContainer, ActiveBasicTableCell, BasicTableCell, ButtonContainer,
  CheckBoxTableCell, CheckCircleStyled, CheckCircleWrapper, DataContainer, DataLabel,
  ExpandedInvitationContainer, FirstTableCell, HiddenElement,
  ImagePreviewContainer, InactiveBasicTableCell, InactiveTableRow, InfoContainer, InvitationPaper,
  InvitationPaperSelected, LinkInvitationAutocompleteContainer, MainInvitationDataConatainer,
  OptionTableCell, PointerText, SubmitButton, ThemeMultipleLinesText, ThemeOverlappedText
} from './InvitationRowElement.styled';

type InvitationRowProps = {
    invitation: InvitationProps,
    invitations: InvitationProps[],
    isInvitationSelected: (id: number) => boolean,
    handleCheckboxSelect: (id: number) => void,
};

export const InvitationRowElement = ({invitation, invitations, isInvitationSelected, handleCheckboxSelect } : InvitationRowProps) => {
  const { dbUser }: UseAuthProps = useAuth();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const keyboardRef = useRef(null);
  const { changeExecuteRefetch } = useInvitationStore();

  const [ isOpenLinkModal, setIsOpenLinkModal ] = useState(false);
  const [ inputValue, setInputValue ] = useState(invitation?.eventLinkName ?? '');
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ isOpenDeactivateModal, setIsOpenDeactivateModal ] = useState(false);
  const [ isOpenActivateModal, setIsOpenActivateModal ] = useState(false);
  const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ showKeyboardForEvent, setShowKeyboardForEvent ] = useState(false);

  const [ deactivateInvitation, { loading: deactivateLoading }] = graphMutationMiddleware(REMOVE_INVITATION);
  const [updateStatus] = graphMutationMiddleware(UPDATE_INVITATION);
  const [ getInvitations, { data: invitationData, loading: loadingInvitationData }] = graphLazyQueryMiddleware(GET_INVITATIONS);
  const [ updateInvitationActivityStatus, { loading: activityStatusLoading }] = graphMutationMiddleware(UPDATE_INVITATION_ACTIVITY_STATUS);

  useEffect((() => {
    if (showKeyboardForEvent) {
      localStorage.getItem('isHebrew') === 'true' ? setIsHebrew(true) : setIsHebrew(false);
      setShowKeyboard(false);
    }
  }), [showKeyboardForEvent]);

  const isActiveInvitation = invitation.activityStatus === ACTIVITY_STATUS.ACTIVE.toUpperCase();

  const actionMenuProps =
    [
      {
        id: 0,
        title: t('edit'),
        icon: Edit,
        functionHandler: () => navigateToEditInvitation('invitation')
      },
      {
        id: 1,
        title: t('send'),
        icon: Send,
        functionHandler: () => navigateToEditInvitation('send')
      },
      {
        id: 2,
        title: t('linkEvents'),
        icon: Link,
        functionHandler: () => setIsOpenLinkModal(true)
      },
      {
        id: 3,
        title: t('deactivate'),
        icon: Cancel,
        functionHandler: () => setIsOpenDeactivateModal(true)
      },
      {
        id: 4,
        title: t('activate'),
        icon: AddCircle,
        functionHandler: () => setIsOpenActivateModal(true)
      },
      {
        id: 5,
        title: t('delete'),
        icon: Delete,
        functionHandler: () => setIsOpenDeleteModal(true)
      }
    ];

  if (isActiveInvitation) {
    actionMenuProps.splice(4, 1);
    invitationData?.invitations?.items?.filter((inv: InvitationProps) => inv.id !== invitation?.id).length === 0 && actionMenuProps.splice(2, 1);
    (disableInvitationTab(invitation) || !isCurrentUsersInvitation(dbUser?.id, invitation?.createdBy?.id)) && actionMenuProps.splice(1, 1);
  } else {
    actionMenuProps.splice(1, 3);
  }

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const navigateToEditInvitation = (tab: string) => {
    localStorage.setItem('activeId', invitation.id.toString());
    navigate(`/invitations/edit/${tab}/${invitation.id}`);
  };

  const handleUpdateInvitationActivityStatus = (activityStatus: string) => {
    updateInvitationActivityStatus({
      variables: {
        invitationId: invitation.id,
        activityStatus: activityStatus
      }
    }).then(() => {
      changeExecuteRefetch();
    });
  };

  const handleUpdate = (linkedId: number) => {
    updateStatus({
      variables: {
        input: {
          id: invitation.id,
          name: invitation.name,
          eventLinkId: linkedId,
          tags: invitation.tags?.length > 0 ? invitation.tags.map((tag: TagType) => tag.name) : [],
          locationInfo: {
            startDate: invitation.locationInfo.startDate,
            venueId: invitation.locationInfo.venue.id,
          }
        }
      }
    }).then(() => {
      changeExecuteRefetch();
      setIsOpenLinkModal(false);
    });
  };

  const deactivateInv = () => {
    deactivateInvitation({variables: {
      invitationId: invitation.id
    }}).then(() => changeExecuteRefetch() );
    setIsOpenDeactivateModal(false);
  };

  const getStatusStyle = (invitationStatus: string) : ElementType<TableCellBaseProps> => {
    return (invitationStatus.toLowerCase() === INVITATION_STATUS.SENT.toLowerCase() ? ActiveBasicTableCell : InactiveBasicTableCell) as ElementType<TableCellBaseProps>;
  };

  const handleCloseModal = () => {
    setIsOpenLinkModal(false);
  };

  const isInactiveRow = () => {
    return invitation.activityStatus === ACTIVITY_STATUS.INACTIVE.toUpperCase();
  };

  const getBasicTableCellWidth = () => {
    return checkPermission(dbUser, USER_PERMISSIONS.VIEW_ALL_INVITATIONS) ? '7vw' : '8vw';
  };

  return (
    <>
      {isMobile ?
        <Paper
          key={invitation.id}
          elevation={2}
          style={{ opacity: isInactiveRow() ? 0.5 : 1 } as CSSProperties}
          component={isInvitationSelected(invitation.id) ? InvitationPaperSelected : InvitationPaper}>
          <CheckCircleWrapper>
            {isInvitationSelected(invitation.id) && <CheckCircleStyled />}
          </CheckCircleWrapper>
          <ExpandedInvitationContainer>
            <MainInvitationDataConatainer>
              <MainInvitationDataConatainer onClick={() => handleCheckboxSelect(invitation.id)}>
                <ImagePreviewContainer>
                  <ImagePreview invitation={invitation} forTablePreview={true} />
                </ImagePreviewContainer>
                <InfoContainer>
                  <CustomTooltip text={invitation.name} theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} />
                  <Typography>{invitation.locationInfo?.startDate ? formatDate(invitation.locationInfo.startDate).toLocaleDateString(LOCAL_DATE_ARGUMENT) : ''}</Typography>
                  <Typography component={getStatusStyle(invitation.status)}>
                  &#9679; {t(invitation.status.toLowerCase())}
                  </Typography>
                </InfoContainer>
              </MainInvitationDataConatainer>
              <ActionMenuContainer>
                <ActionMenu
                  propsData={actionMenuProps}
                  iconTheme={themePublic.actionMenuIconStyle}
                  disablePortal={true}
                  menuPlacement={calculateMenuPlacement(true)}/>
              </ActionMenuContainer>
            </MainInvitationDataConatainer>
            {isInvitationSelected(invitation.id) &&
            <>
              <Box>
                <hr/>
              </Box>
              <DataContainer>
                <DataLabel> {`${t('created')}`}: </DataLabel>
                <CustomTooltip theme={ThemeOverlappedText} text={formatDate(invitation.createdAt).toLocaleDateString(LOCAL_DATE_ARGUMENT)} />
              </DataContainer>
              <DataContainer>
                <DataLabel> {`${t('eventDate')}`}: </DataLabel>
                <CustomTooltip
                  theme={ThemeOverlappedText}
                  text={invitation.locationInfo?.startDate ? formatDate(invitation.locationInfo.startDate).toLocaleDateString(LOCAL_DATE_ARGUMENT) : ''} />
              </DataContainer>
              <DataContainer>
                <DataLabel> {`${t('response')}`}: </DataLabel>
                <CustomTooltip theme={ThemeOverlappedText} text={`${invitation.response}%`} />
              </DataContainer>
              <DataContainer>
                <DataLabel> {`${t('tags')}`}: </DataLabel>
                <CustomTooltip
                  theme={ThemeOverlappedText}
                  text={
                    invitation.tags.length === 0 ?
                      `${t('noTags')}` :
                      invitation.tags.map((tag) => tag.name).join(', ')
                  } />
              </DataContainer>
              <DataContainer>
                <DataLabel>  {`${t('link')}`}: </DataLabel>
                <CustomTooltip
                  theme={ThemeOverlappedText}
                  text={invitation.eventLinkName ?? ''}
                  onClick={(e: React.MouseEvent<Element>) => {
                    localStorage.setItem('activeId', invitation.eventLinkId.toString());
                    e.preventDefault();
                    handleNavigate(`/invitations/edit/invitation/${invitation.eventLinkId}`);
                  }}/>
              </DataContainer>
            </>
            }
          </ExpandedInvitationContainer>
        </Paper> :
        <TableRow component={isInactiveRow() ? InactiveTableRow : TableRow} key={invitation.id}>
          <TableCell component={(isInvitationSelected(invitation.id) ? CheckBoxTableCell : TableCell) as ElementType<TableCellBaseProps>}>
            <Checkbox checked={isInvitationSelected(invitation.id)} onClick={() => handleCheckboxSelect(invitation.id)}/>
          </TableCell>
          <FirstTableCell sx={{width: '8%'}}>
            <ImagePreview invitation={invitation} forTablePreview={true} />
          </FirstTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <CustomTooltip text={invitation.name} theme={ThemeOverlappedText}/>
          </BasicTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <Typography>{formatDate(invitation.createdAt).toLocaleDateString(LOCAL_DATE_ARGUMENT)}</Typography>
          </BasicTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <Typography>{invitation.locationInfo?.startDate ? formatDate(invitation.locationInfo.startDate).toLocaleDateString(LOCAL_DATE_ARGUMENT) : ''}</Typography>
          </BasicTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <Typography component={getStatusStyle(invitation.status)}>&#9679; {t(invitation.status.toLowerCase())}</Typography>
          </BasicTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <Typography>{invitation.response}%</Typography>
          </BasicTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <CustomTooltip text={invitation.tags.map((tag: TagType) => tag.name).join(', ')} theme={ThemeOverlappedText}/>
          </BasicTableCell>
          <BasicTableCell style={{width: getBasicTableCellWidth()}}>
            <CustomTooltip
              theme={{...ThemeOverlappedText, ...PointerText}}
              text={invitation.eventLinkName ?? ''}
              onClick={(e: React.MouseEvent<Element>) => {
                localStorage.setItem('activeId', invitation.eventLinkId.toString());
                e.preventDefault();
                handleNavigate(`/invitations/edit/invitation/${invitation.eventLinkId}`);
              }}/>
          </BasicTableCell>
          <BasicTableCell
            style={{width: getBasicTableCellWidth()}}
            component={(checkPermission(dbUser, USER_PERMISSIONS.VIEW_ALL_INVITATIONS) && HiddenElement) as ElementType<TableCellBaseProps>}>
            <CustomTooltip text={`${invitation.createdBy.firstName} ${invitation.createdBy.lastName}`} theme={ThemeOverlappedText}/>
          </BasicTableCell>
          <OptionTableCell>
            <ActionMenu
              propsData={actionMenuProps}
              disablePortal={false}
              menuPlacement={calculateMenuPlacement(isMobile)}/>
          </OptionTableCell>
        </TableRow>}
      <ConfirmationModal
        isOpen={isOpenDeactivateModal}
        confimMessage={invitation.status.toLowerCase() === INVITATION_STATUS.SENT.toLowerCase() ?
          t('deactivateSentInvitationMessage', { invitationName: invitation.name }) : t('deactivateMessage', { invitationName: invitation.name })}
        handleClose={() => setIsOpenDeactivateModal(false)}
        handleConfirm={() => handleUpdateInvitationActivityStatus(ACTIVITY_STATUS.INACTIVE.toUpperCase())}
        loading={activityStatusLoading} />
      <ConfirmationModal
        isOpen={isOpenActivateModal}
        confimMessage={t('activateSentInvitationMessage', { invitationName: invitation.name })}
        handleClose={() => setIsOpenActivateModal(false)}
        handleConfirm={() => handleUpdateInvitationActivityStatus(ACTIVITY_STATUS.ACTIVE.toUpperCase())}
        loading={activityStatusLoading} />
      <ConfirmationModal
        isOpen={isOpenDeleteModal}
        confimMessage={invitation.status.toLowerCase() === INVITATION_STATUS.SENT.toLowerCase() ?
          t('deleteSentInvitationMessage', { invitationName: invitation.name }) : t('deleteMessage', { invitationName: invitation.name })}
        handleClose={() => setIsOpenDeleteModal(false)}
        handleConfirm={() => deactivateInv()}
        loading={deactivateLoading} />
      {isOpenLinkModal &&
      <SelectInvitation
        isOpen={true}
        handleClose={handleCloseModal}
        handleUpdate={handleUpdate}
        title={t('selectEvent')}
        submitBtnText={t('save')}
        placeholder={t('eventLink')}
        invitation={invitation}/>}
    </>
  );
};