import React from 'react';
import { toast } from 'react-toastify';

import { Link, ToastHeading, ToastParagraph } from './toast.styled';

interface NotificationBodyProps {
  msg: string,
  type: string,
  link?: React.ReactElement
}

const NotificationBody = ({ msg, type, link }: NotificationBodyProps) => {
  return (
    <>
      <ToastHeading>{type}</ToastHeading>
      <ToastParagraph>{msg} {<Link>{link}</Link>}</ToastParagraph>
    </>
  );
};

export const showToast = ( type: string, msg: string, link?: React.ReactElement ) => {
  if (type === 'success') {
    toast.success(<NotificationBody msg={msg} type={type}/>);
  } else if (type === 'error') {
    toast.error(<NotificationBody msg={msg} type={type} link={link}/>);
  } else if (type === 'info') {
    toast.info(<NotificationBody msg={msg} type={type}/>);
  }
};