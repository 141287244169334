import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PageWithMessage } from 'components';
import { CHANGE_EMAIL, graphMutationMiddleware } from 'services';

export const ConfirmEmailPage = () => {
  const [t] = useTranslation();

  const [changeEmail] = graphMutationMiddleware(CHANGE_EMAIL);

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    const emailEncoded = encodeURIComponent(query.get('email'));
    let emailDecoded = emailEncoded.replace(/%20/g, '+');
    emailDecoded = emailDecoded.replace(/%40/g, '@');
    changeEmail({variables: { token: query.get('t'), email: emailDecoded }});
  }, []);

  return (
    <PageWithMessage message={t('emailSuccessfullyChanged')} />
  );
};