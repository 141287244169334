import { Box } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

const contactsPageContentStyle = {
  ...themePublic.pageContent as CSSProperties,
  padding: '1rem',
  [themeLocal.breakpoints.down('sm')]: {
    marginTop: '20vh',
    borderRadius: themeDefault.defaultBorderRadius,
    padding: '1rem 0',
    justifyContent: 'start',
  },
};

const importingContactsPageContentStyle = {
  height: '85%',
  [themeLocal.breakpoints.down('sm')]: {
    height: '100%,'
  },
};

export const ContactsPageBody = styled(Box)(
  () => ({
    ...themePublic.pageBody as CSSProperties,
  }),
);

export const ImportingContactsPageBody = styled(Box)(
  ({ theme }) => ({
    height: '95%',
    [theme.breakpoints.down('sm')]: {
      height: '100%,'
    },
  }),
);

export const ContactsPageContent = styled(Box)(
  () => ({
    ...contactsPageContentStyle,
  }),
);

export const ImportingAndContactsPageContent = styled(ContactsPageContent)(
  () => ({
    ...importingContactsPageContentStyle,
  }),
);

export const ImportingContactsPageContentMobile = styled(ContactsPageContent)(
  () => ({
    marginTop: '1vh !important',
  }),
);

export const TableContainerMobile = styled(Box)(
  () => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  }),
);

export const ImportingTableContainerMobile = styled(TableContainerMobile)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: themeDefault.defaultBorderRadius,
    marginTop: '1rem',
  }),
);

export const FullPageMobile = styled(Box)(
  () => ({
    ...themePublic.fullPageMobile as CSSProperties,
  }),
);
