import { CSSProperties } from 'react';
import { Box, Button, Typography, Grid, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault, themePublic } from 'themeDefault';

export const PageContainer = styled(Box)(
  () => ({
    ...themePublic.pageWithPaperContent as CSSProperties,
  }),
);

export const GridStyled = styled(Grid)(
  () => ({
    ...themePublic.pageWithPaperGridContent as CSSProperties,
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.pageWithPaperDivider as CSSProperties,
  }),
);

export const SetPasswordImageContainer = styled(Box)(
  () => ({
    ...themePublic.pageWithPaperImageContainer as CSSProperties,
  }),
);

export const PageTitle = styled(Typography)(
  () => ({
    ...themePublic.pageWithPaperTitle as CSSProperties,
  }),
);

export const MaxContrainer = styled(Box)(
  () => ({
    ...themePublic.maxWidth as CSSProperties,
  }),
);

export const ErrorMessage = styled(Typography)(
  () => ({
    color: themeDefault.palette.errorColor,
    fontSize: '12px',
    maxWidth: '325px',
  }),
);

export const SignUpButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '0.6rem',
    marginTop: '2rem',
    padding: '0.5rem',
    textTransform: 'capitalize',
    width: '100%',
  }),
);

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '90%',
    },
  }),
);

export const ContentContainerDisabled = styled(Box)(
  () => ({
    opacity: 0.8,
    pointerEvents: 'none',
  }),
);
