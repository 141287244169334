import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  FormControl,
  MenuItem,
  PaginationItem,
  Select,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'contexts';
import { USER_SETTINGS_CONFIG } from 'enums';
import { FIRST_PAGE, getUserSettings, templatesPerRowCalc } from 'helpers';
import {
  UPDATE_USER_SETTINGS,
  graphMutationMiddleware
} from 'services';
import { UseAuthProps } from 'types';

import { PaginationSection, PaginationStyled, SelectContainer } from './TablePagination.styled';

type TablePaginationProps = {
  rowsLength: number | null
  rowsPerPage: number
  page: number
  setRowsPerPage: (e: number) => void
  setPage: (e: number) => void
  invitationToShow: any,
  tableType: USER_SETTINGS_CONFIG,
}

export const TablePagination = ({
  rowsLength,
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  invitationToShow,
  tableType,
}: TablePaginationProps) => {
  const [t] = useTranslation();

  const { dbUser }: UseAuthProps = useAuth();
  const [updateUserSettings] = graphMutationMiddleware(UPDATE_USER_SETTINGS);

  let count = rowsLength / rowsPerPage;
  count = rowsLength > 0 ? count = rowsLength / rowsPerPage : count = 0;

  const goToPage = [];
  for (let i = 0; i < count; i++) {
    goToPage.push(i + 1);
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(FIRST_PAGE);
    const pageSize = parseInt(e.target.value, 10);
    setRowsPerPage(pageSize);

    const userSettingsJson = getUserSettings(null, dbUser.id);
    userSettingsJson[tableType].pageSize = pageSize;
    updateUserSettings({ variables: {
      input: {
        userId: dbUser.id,
        value: JSON.stringify(userSettingsJson)
      }
    }}).then(resUpdateUserSettings => {
      const userSettingsJSON = {
        ...resUpdateUserSettings.data.updateUserSettings,
        value: JSON.parse(resUpdateUserSettings.data.updateUserSettings.value)
      };
      localStorage.setItem('userSettings', JSON.stringify(userSettingsJSON));
    });
  };

  const handleGoToPage = (e: any) => {
    setPage(e.target.value);
  };

  const showPerPageArray = Object.keys(invitationToShow).slice(0, 4);

  return (
    rowsLength > 0 &&
    <PaginationSection>
      <SelectContainer>
        <Typography component='span'>{t('showRowsPerPage')}</Typography>
        <FormControl sx={{ m: 1, display: 'flex' }}>
          <Select
            size='small'
            value={tableType === USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE ? rowsPerPage / templatesPerRowCalc() : rowsPerPage}
            onChange={handleChangeRowsPerPage}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
            }}
            displayEmpty>
            {showPerPageArray.map(item => (
              <MenuItem
                key={item}
                disabled={tableType === USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE ? rowsPerPage === parseInt(item) * templatesPerRowCalc() : rowsPerPage === parseInt(item)}
                value={item}>
                {tableType === USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE ? parseInt(item) * templatesPerRowCalc() : item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectContainer>
      <PaginationStyled
        page={page}
        showFirstButton
        showLastButton
        onChange={(e, pageNumber) => setPage(pageNumber)}
        count={Math.ceil(count)}
        renderItem={(item) => (
          <PaginationItem
            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}/>
        )}/>
      <SelectContainer>
        <Typography component='span' >{t('goToPage')}</Typography>
        <FormControl sx={{ m: 1, display: 'flex' }}>
          <Select
            size='small'
            value={page}
            onChange={(e) => handleGoToPage(e)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              }
            }}
            displayEmpty>
            {goToPage.map((item) => (
              <MenuItem disabled={page === item} key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectContainer>
    </PaginationSection>
  );
};