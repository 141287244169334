import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const SendPageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    backgroundColor: 'transparent',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: themeDefault.defaultBorderRadius,
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '10px',
    },
  }),
);

export const TrackPageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: themeDefault.defaultBorderRadius,
      marginTop: '10px',
      maxWidth: '100%',
    },
  }),
);

export const PreviewPageContent = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    width: 'initial',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      borderRadius: themeDefault.defaultBorderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '10px',
    },
  }),
);

export const TableContainer = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    maxWidth: 'inherit',
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      border: 0,
      borderRadius: themeDefault.defaultBorderRadius,
      marginTop: 0,
      minWidth: '100%',
    },
  }),
);

export const TableContainerPreviewPage = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    border: '1px solid rgba(128,128,128, 0.5)',
    bottom: '1rem',
    margin: '1rem',
    maxHeight: '85%',
    padding: '1rem',
    width: 'inherit',
    [theme.breakpoints.down('sm')]: {
      border: 0,
      marginTop: 0,
      padding: 0,
    }
  }),
);

export const TypographyStyled = styled(Typography)(
  ({ theme }) => ({
    ...themePublic.emptyListText as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginTop: '2rem',
      marginBottom: '2rem',
      textAlign: 'center',
    },
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer',
    float: 'right',
    margin: '15px 10px',
  }),
);

export const PageButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
    justifyContent: 'space-between',
  }),
);

export const PageNextButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: 'auto',
      width: '50%',
    },
  }),
);

export const PagePrevButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '50%',
    },
  }),
);

export const PaginationContainer = styled(Box)(
  () => ({
    boxSizing: 'border-box',
    padding: '0 1rem',
    width: '100%',
  }),
);
