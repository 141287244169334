import { Autocomplete, Box, Paper, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

const themeLocal = createTheme();

const AutocompleteStyle = {
  width: '100%',
  '& .MuiChip-label': {
    color: themeDefault.palette.mainColor,
  },
  '& .MuiChip-sizeMedium': {
    backgroundColor: themeDefault.palette.lightMainColor,
    ' & svg': {
      fill: themeDefault.palette.mainColor,
    }
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: '20vw',
    [themeLocal.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
  },
  '& .MuiAutocomplete-input': {
    maxWidth: '40vw',
    minWidth: 'fit-content !important',
    [themeLocal.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  '& .MuiAutocomplete-endAdornment': {
    top: '0.9rem',
  },
};

export const OptionContainer = styled(Paper)(
  () => ({
    '& li[aria-selected=true]': {
      backgroundColor: `${themeDefault.palette.lightMainColor} !important`,
      color: themeDefault.palette.mainColor,
      '& svg': {
        fill: themeDefault.palette.mainColor,
      },
    },
  }),
);

export const AddNewContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const Label = styled(Box)(
  () => ({
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    margin: '20px 0',
    textDecoration: 'underline',
  }),
);

export const ErrorMessage = styled(Typography)(
  () => ({
    ...themeDefault.errorMessage as CSSProperties,
  }),
);

export const AutocompleteContainer = styled(Box)(
  () => ({
    ...AutocompleteStyle as CSSProperties,
  }),
);

export const AutocompleteStyled = styled(Autocomplete)(
  () => ({
    ...AutocompleteStyle as CSSProperties,
  }),
);
