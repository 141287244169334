import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Dialog,
  DialogContent, InputLabel, MenuItem, Select, SelectChangeEvent, Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputField, Loader, VirtualKeyboard } from 'components';
import { FORM_TYPE, USER_STATUS, VALIDATION_RESTRICTION } from 'enums';
import { showToast } from 'helpers';
import { EDIT_USER_AS_ADMIN, REGISTER_USER_AS_ADMIN, graphMutationMiddleware } from 'services';
import { useUsersStore } from 'store';
import { DialogComponentsType, FormikMethodsTypes, UserProps } from 'types';

import {
  ButtonContainer, CloseIconStyled,
  DialogStyledAllowedEvents, DialogStyledPreventEvents,
  FormControlStyled,
  InputGroupContainer, InputGroupMember, InputSingleMember,
  ModalHeaderContainer, SelectGroupContainer, SubmitButton,
} from './UserModal.styled';

type CreateUserModalProps = {
  isOpen: boolean
  handleClose: () => void
  type: FORM_TYPE.CREATE | FORM_TYPE.EDIT
  user?: UserProps
};

type UserFormType = {
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  status: string,
}

export const UserModal = ({isOpen, handleClose, type, user}: CreateUserModalProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);

  const { changeExecuteRefetch } = useUsersStore();

  const [ createUser, { loading: createLoading }] = graphMutationMiddleware(REGISTER_USER_AS_ADMIN);
  const [ editUser, { loading: editLoading }] = graphMutationMiddleware(EDIT_USER_AS_ADMIN);

  const [ userType, setUserType ] = useState(type === FORM_TYPE.EDIT ? user.role : 'User');
  const [ userStatus, setUserStatus ] = useState<string>(user ? user.status : 'INACTIVE');
  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const initialValues = {
    firstName: type === FORM_TYPE.EDIT ? user.firstName : '',
    lastName: type === FORM_TYPE.EDIT ? user.lastName : '',
    email: type === FORM_TYPE.EDIT ? user.email : '',
    role: type === FORM_TYPE.EDIT ? user.role : '',
    status: type === FORM_TYPE.EDIT ? user.status : '',
  };

  const registerSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    lastName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    email: Yup.string()
      .email(t('invalidEmailFormat'))
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))
  });

  useEffect(() => {
    user && setUserStatus(user.status);
  }, [user]);

  const handleChangeRole = (e: SelectChangeEvent) => {
    setUserType(e.target.value);
  };
  const handleChangeStatus = (e: SelectChangeEvent) => {
    setUserStatus(e.target.value.toUpperCase());
  };

  const handleCreate = (values: UserFormType, { setSubmitting, resetForm }: FormikMethodsTypes) => {
    setSubmitting(false);
    const finalValues = {
      ...values,
      role: userType,
      status: userStatus
    };
    createUser({variables:
      {input: {
        firstName: finalValues.firstName,
        lastName: finalValues.lastName,
        email: finalValues.email,
        role: finalValues.role}}
    }).then(() => {
      resetForm();
      handleClose();
      changeExecuteRefetch();
    });
  };

  const handleEdit = (values: UserFormType, { setSubmitting, resetForm }: FormikMethodsTypes) => {
    setSubmitting(false);
    const finalValues = {
      ...values,
      role: userType,
      status: userStatus
    };
    editUser({variables:
      {input: {
        id: user.id,
        firstName: finalValues.firstName,
        lastName: finalValues.lastName,
        email: finalValues.email,
        role: finalValues.role,
        status: finalValues.status}}})
      .then((res) => {
        if (res.data.updateUser.emailChanged) {
          showToast('info', `${t('changeEmailRequested')} ${finalValues.email}.`);
        }
        resetForm();
        handleClose();
        changeExecuteRefetch();
      });
  };

  return (
    <Dialog
      components={(createLoading || editLoading ? DialogStyledPreventEvents : DialogStyledAllowedEvents) as DialogComponentsType}
      fullWidth={isMobile && true}
      open={isOpen}
      maxWidth='md'
      onClose={handleClose}>
      <Loader loadingPage={false} inProgress={createLoading || editLoading} />
      <DialogContent>
        <ModalHeaderContainer>
          <Typography variant={isMobile ? 'h6' : 'h5'}>{t(type === FORM_TYPE.EDIT ? 'editUser' : 'createNewUser')}</Typography>
          <CloseIconStyled onClick={handleClose} />
        </ModalHeaderContainer>
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={registerSchema}
            onSubmit={type === FORM_TYPE.CREATE ? handleCreate : handleEdit}>
            {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }) => {
              return (
                <Box>
                  <Form autoComplete='off'>
                    <InputGroupContainer>
                      <InputSingleMember>
                        <InputField
                          setShowKeyboard={setShowKeyboard}
                          setInputName={setInputName}
                          inputId='firstName'
                          inputName='firstName'
                          isError={errors.firstName}
                          label={t('firstName')}
                          type='text' />
                      </InputSingleMember>
                      <InputSingleMember>
                        <InputField
                          setShowKeyboard={setShowKeyboard}
                          setInputName={setInputName}
                          inputId='lastName'
                          inputName='lastName'
                          isError={errors.lastName}
                          label={t('lastName')}
                          type='text' />
                      </InputSingleMember>
                    </InputGroupContainer>
                    <InputField
                      setShowKeyboard={setShowKeyboard}
                      setInputName={setInputName}
                      inputId='email'
                      inputName='email'
                      isError={errors.email}
                      label={t('email')}
                      type='email' />
                    <SelectGroupContainer>
                      <InputGroupMember>
                        <FormControlStyled size='small'>
                          <InputLabel>{t('userRole')}</InputLabel>
                          <Select
                            value={userType}
                            label={t('userRole')}
                            onChange={handleChangeRole}>
                            <MenuItem value={'Admin'}>{t('admin')}</MenuItem>
                            <MenuItem value={'User'}>{t('user')}</MenuItem>
                          </Select>
                        </FormControlStyled>
                      </InputGroupMember>
                      <InputGroupMember>
                        {type === FORM_TYPE.EDIT &&
                        <FormControlStyled size='small'>
                          <InputLabel>{t('status')}</InputLabel>
                          <Select
                            value={userStatus}
                            label={t('userRole')}
                            onChange={(e) => handleChangeStatus(e)}>
                            <MenuItem value={'ACTIVE'}>{USER_STATUS.ACTIVE}</MenuItem>
                            <MenuItem value={'INACTIVE'}>{USER_STATUS.INACTIVE}</MenuItem>
                          </Select>
                        </FormControlStyled>}
                      </InputGroupMember>
                    </SelectGroupContainer>
                    <ButtonContainer>
                      <SubmitButton
                        type='submit'
                        disableElevation
                        disabled={(Object.keys(errors).length > 0 || isSubmitting || !(isValid && dirty)) && (type === FORM_TYPE.EDIT ? values.role === userType && values.status === userStatus : true)}>
                        {t(type === FORM_TYPE.CREATE ? FORM_TYPE.CREATE : 'save')}
                      </SubmitButton>
                    </ButtonContainer>
                    { showKeyboard &&
                    <VirtualKeyboard
                      setShowKeyboard={setShowKeyboard}
                      initialValues={values}
                      setFieldValue={setFieldValue}
                      keyboardRef={keyboardRef}
                      inputName={inputName}/>
                    }
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};