import {
  AppBar, Box, Divider, Drawer, FormControlLabel, IconButton, ListItem, ListItemButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { baseSwitchButtonStyle, themeDefault } from 'themeDefault';

export const LogoContainer = styled(Box)(
  () => ({
    marginTop: '1rem',
    marginBottom: '0.7rem',
    textAlign: 'center',
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    marginBottom: '2rem',
    width: '90%',
  }),
);

export const SubmenuSelected = styled(ListItemButton)(
  () => ({
    background: themeDefault.palette.disabledColor,
  }),
);

export const Layout = styled(Box)(
  () => ({
    display: 'flex',
  }),
);

export const AppBarStyled = styled(AppBar)(
  () => ({
    height: '60px',
    backgroundColor: themeDefault.palette.darkBlue,
  }),
);

export const IconButtonStyled = styled(IconButton)(
  () => ({
    position: 'absolute',
  }),
);

export const HeaderTitle = styled(Typography)(
  () => ({
    margin: 'auto',
  }),
);

export const DrawerStyled = styled(Drawer)(
  ({ theme }) => ({
    width: '80%',
    '& .MuiDrawer-paper': {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '15.7vw',
      width: '20%',
      '& .MuiDrawer-paper': {
        width: '20%',
        maxWidth: '15.7vw',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      maxWidth: '15.7vw',
      '& .MuiDrawer-paper': {
        width: '50%',
        maxWidth: '15.7vw',
      },
    }
  }),
);

export const LanguageSwitch = styled(FormControlLabel)(
  () => ({
    ...baseSwitchButtonStyle as CSSProperties,
  }),
);

export const SwitchListItem = styled(ListItem)(
  () => ({
    justifyContent: 'center',
  }),
);

export const VerticalMarginDivider = styled(Divider)(
  () => ({
    marginTop: '15px 0',
  }),
);

export const LogoutListItemButton = styled(ListItemButton)(
  () => ({
    justifyContent: 'center',
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  }),
);

export const LogoutWrapper = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }),
);
