import { TableCell } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    fontWeight: 'bold',
    lineHeight: 1.8,
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const CheckboxTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxTableCellInRow as CSSProperties,
  }),
);

export const FirstTableCell = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCell as CSSProperties,
    ...themeDefault.overflowText as CSSProperties,
    maxWidth: '30vh',
    minWidth: '4rem',
  }),
);

export const TitleTableCell = styled(TableCell)(
  () => ({
    ...themePublic.titleTableCell as CSSProperties,
    ...themeDefault.overflowText as CSSProperties,
    maxWidth: '27vh',
  }),
);

export const BasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.basicTableCell as CSSProperties,
    maxWidth: '27vh',
  }),
);

export const ImportingBasicTableCell = styled(BasicTableCell)(
  () => ({
    maxWidth: '20vh',
  }),
);

export const OptionTableCell = styled(TableCell)(
  () => ({
    ...themePublic.optionTableCell as CSSProperties,
    maxWidth: '3vw',
  }),
);
