import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const InvitationListPageBody = styled(Box)(
  () => ({
    ...themePublic.pageBody as CSSProperties,
  }),
);

export const InvitationListPageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: themeDefault.defaultBorderRadius,
      marginTop: '100px',
    },
  }),
);

export const AddNewInvitationButtonMobile = styled(Paper)(
  () => ({
    ...themePublic.addNewEntityButtonMobile as CSSProperties,
  }),
);

export const TypographyStyled = styled(Typography)(
  () => ({
    ...themePublic.emptyListText as CSSProperties,
  }),
);
