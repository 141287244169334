import { CSSProperties } from '@emotion/serialize';
import { Box, Button, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault, themePublic } from 'themeDefault';

export const BasicTableCellStyle = {
  ...themeDefault.overflowText as CSSProperties,
  borderRadius: '10px 0 0 10px',
  maxWidth: '30vh',
  paddingTop: '0.5rem !important',
  verticalAlign: 'top',
};

export const MultiCellTopBorder = {
  borderTop: `1px solid ${themeDefault.palette.borderColor}`,
};

export const MultiCellLeftBorder = {
  borderLeft: `1px solid ${themeDefault.palette.borderColor}`,
};

export const MultiCellBottomBorder = {
  borderBottom: `1px solid ${themeDefault.palette.borderColor} !important`,
};

export const MultiCellRightBorder = {
  borderRight: `1px solid ${themeDefault.palette.borderColor}`,
};

export const TagsContainer = styled(Box)(
  () => ({
    ...themeDefault.overflowText as CSSProperties,
    marginBottom: '0.5rem',
    maxWidth: '200vh',
    width: '100%',
  }),
);

export const SelectFormControl = styled(FormControl)(
  () => ({
    ...themePublic.publicContactEntryLinksTableEditRow_SelectFormControl as CSSProperties,
  }),
);

export const ButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.publicContactEntryLinksTableEditRow_ButtonsContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    margin: 'auto',
    width: 'auto',
  }),
);

export const CancelButton = styled(Button)(
  () => ({
    ...themePublic.cancelButton as CSSProperties,
    height: '3rem',
    margin: 'auto',
    width: 'auto',
  }),
);
