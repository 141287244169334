import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PageBody = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.secondaryBackgrounColor,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 131px)',
    position: 'relative',
    width: '100%',
  }),
);

export const PageContent = styled(Box)(
  () => ({
    display: 'contents',
  }),
);

export const PageButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.pageButtonsContainer as CSSProperties,
    justifyContent: 'space-between',
  }),
);

export const PagePrevButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '100%',
    },
  }),
);
export const ExportResponsesButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pagePrevButton as CSSProperties,
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '100%',
    },
  }),
);
