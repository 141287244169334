import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const InvitationItemContainer = styled(Box)(
  () => ({
    position: 'relative',
  }),
);

export const ItemContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    borderRadius: '8px',
    border: `2px solid ${themeDefault.palette.disabledColor}`,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '400px !important',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    '&:hover': {
      border: `2px solid ${themeDefault.palette.mainColor}`,
    },
    [theme.breakpoints.down('sm')]: {
      height: '310px !important',
      justifyContent: 'start',
      minHeight: 0,
    },
  }),
);

export const StarBadge = styled('img')(
  () => ({
    left: '10px',
    position: 'absolute',
    top: '10px',
    width: '28px',
    zIndex: 100,
  }),
);

export const DraftLabel = styled(Typography)(
  () => ({
    backgroundColor: themeDefault.palette.chipGreyColor,
    borderRadius: '15px',
    color: `${themeDefault.palette.whiteColor} !important`,
    fontSize: '14px',
    margin: 0,
    position: 'absolute',
    padding: '0.25rem 1rem',
    right: '10px',
    top: '10px',
    width: 'auto',
  }),
);

export const ActionMenuContainer = styled('div')(
  ({ theme }) => ({
    alignItems: 'center',
    bottom: '5%',
    display: 'flex',
    justifyContent: 'end',
    position: 'absolute',
    right: '5%',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      bottom: '1%',
    },
  }),
);

export const TemplateTitle = styled('div')(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    maxWidth: '30vh',
    fontSize: '20px',
    marginTop: '2rem',
    zIndex: '0 !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem !important',
      maxWidth: '15vh',
    },
  }),
);

export const DateTypography = styled(Typography)(
  ({ theme }) => ({
    color: 'rgba(0,0,0,0.5)',
    fontSize: '16px',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
    },
  }),
);

export const ActionMenuConatiner = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    bottom: '5%',
    display: 'flex',
    justifyContent: 'end',
    position: 'absolute',
    right: '5%',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      bottom: '1%',
    },
  }),
);
