import React, { CSSProperties, useState } from 'react';
import { Box, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, DisplayImageModal } from 'components';
import { onImageErrorLoading } from 'helpers';
import { EviteType } from 'types';

import {
  CardContainer, CardContainerBody, CardContainerBodyImage,
  CardContainerHeader, CardContainerHeaderSelected, CardContainerRsvp,
  CardContainerRsvpDisabled, CardImage, ImageContainer,
  SelectedCardContainer, ThemeOverlappedText
} from './SelectInvitationCard.styled';

type SelectInvitationCardProps = {
  item: EviteType;
  selectedEvites: number[];
  requestRsvpEvites: number[];
  handleSelectedCard: (id: number) => void;
  handleSelectRequestedRSVP: (id: number) => void;
};

export const SelectInvitationCard = ({
  item,
  selectedEvites,
  requestRsvpEvites,
  handleSelectedCard,
  handleSelectRequestedRSVP
}: SelectInvitationCardProps) => {
  const [t] = useTranslation();

  const [ isOpenCardPreviewModal, setIsOpenCardPreviewModal ] = useState(false);

  return (
    <Box component={selectedEvites.includes(item.id) ? SelectedCardContainer : CardContainer}>
      <Box component={selectedEvites.includes(item.id) ? CardContainerHeaderSelected : CardContainerHeader}>
        <Checkbox checked={selectedEvites.includes(item.id)} onClick={(e) => handleSelectedCard(item.id)}/>
        {item.isSelected ? t('eviteSelected') : t('selectEvite') }
      </Box>
      <Box component={item.image ? CardContainerBodyImage : CardContainerBody} onClick={() => setIsOpenCardPreviewModal(true)}>
        <ImageContainer>
          <CardImage src={item.image?.thumbnailUrl} alt={t('loading')} onError={onImageErrorLoading} />
        </ImageContainer>
        <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={item.name} />
        <Box component={selectedEvites.includes(item.id) ? CardContainerRsvp : CardContainerRsvpDisabled}>
          <Checkbox
            checked={requestRsvpEvites.includes(item.id)}
            disabled={!selectedEvites.includes(item.id)}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectRequestedRSVP(item.id);
            }}/>
          {t('requestRsvp')}
        </Box>
      </Box>
      {item.image &&
      <DisplayImageModal
        imageUrl={item.image.url}
        title={item.name}
        isOpen={isOpenCardPreviewModal}
        handleClose={() => {
          setIsOpenCardPreviewModal(false);
        }}/>
      }
    </Box>
  );
};