import React, { ChangeEvent, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { CheckCircleOutline as CheckCircleOutlineIcon, } from '@mui/icons-material';
import { MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP } from 'helpers';

import { ContentContainer, EventName, RadioGroupFormControl } from './Evite.styled';
import { EviteRSVPType } from 'types';

type EviteProps = {
  evite: EviteRSVPType;
  changeValue: (id: number, value: string, guestId: number) => void;
  guestId: number;
};

export const Evite = ({ evite, changeValue, guestId }: EviteProps) => {
  const [t] = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeValue(evite.id, e.target.value, guestId);
  };

  return (
    <ContentContainer>
      <Tooltip enterTouchDelay={0} title={evite.name?.length > MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP ? evite.name : ''}>
        <EventName>{isMobile && evite.name ? `${evite.name}:` : evite.name}</EventName>
      </Tooltip>
      <RadioGroupFormControl>
        <RadioGroup
          onChange={handleChange}
          value={evite.status}
          row
          aria-labelledby='demo-radio-buttons-group-label'
          name='radio-buttons-group'>
          <FormControlLabel
            labelPlacement='end'
            value='ATTENDING'
            control={<Radio checkedIcon={<CheckCircleOutlineIcon color='action' />} />}
            label={t(evite.id === -1 ? isMobile ? 'selectAllAttending' : 'selectAll' : 'attending')} />
          <FormControlLabel
            labelPlacement='end'
            value='NOT_ATTENDING'
            control={<Radio checkedIcon={<CheckCircleOutlineIcon color='action' />} />}
            label={t(evite.id === -1 ? isMobile ? 'selectAllNotAttending' : 'selectAll' : 'notAttending')} />
          <FormControlLabel
            labelPlacement='end'
            value='MAZEL_TOW_ONLY'
            control={<Radio checkedIcon={<CheckCircleOutlineIcon color='action' />} />}
            label={t(evite.id === -1 ? isMobile ? 'selectAllMazelTovOnly' : 'selectAll' : 'mazalTovOnly')} />
        </RadioGroup>
      </RadioGroupFormControl>
    </ContentContainer>
  );
};
