import { Box, Divider, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const menuButtonStyel = {
  alignItems: 'center',
  backgroundColor: themeDefault.palette.whiteColor,
  border: `1px solid ${themeDefault.palette.mainBackground}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: themeDefault.palette.mainBackground,
  display: 'flex',
  fontSize: '1rem',
  justifyContent: 'space-between',
  lineHeight: 1.75,
  marginTop: '-9.2vh',
  marginBottom: '1rem',
  padding: '6px 14px',
  '& img': {
    marginLeft: '6px',
  },
};

export const HiddenButton = styled(Box)(
  () => ({
    ...menuButtonStyel as CSSProperties,
    marginTop: '-12.58vh',
    marginLeft: '20%',
    opacity: 0,
    padding: '6px 14px',
    width: '50vw',
  }),
);

export const MenuButton = styled(Box)(
  () => ({
    ...menuButtonStyel as CSSProperties,
    marginLeft: '15vw',
    whiteSpace: 'nowrap',
    width: '70vw',
  }),
);

export const MenuContent = styled(Menu)(
  () => ({
    '& .MuiPaper-root': {
      left: '15vw !important',
      top: '12.75vh !important',
    },
    '& ul': {
      border: `1px solid ${themeDefault.palette.mainBackground}`,
      borderRadius: '4px',
      padding: 0,
      '& li': {
        fontSize: '14px',
        justifyContent: 'space-between',
        minHeight: '32px',
        lineHeight: 1.75,
        padding: '8px 14px',
        width: '70vw',
      }
    },
    '& img': {
      marginLeft: '28px',
    },
    '& hr': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    }
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactMenagementDividerMenu as CSSProperties,
  }),
);
