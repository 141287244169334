import { Box, Button, Container, Paper, Stepper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const StepperStyled = styled(Stepper)(
  () => ({
    margin: '10px',
    '& .MuiStep-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiButtonBase-root': {
      width: '100%',
    },
    '& .MuiStepLabel-root': {
      width: '100%',
    },
    '& .MuiStepLabel-iconContainer': {
      width: '100%',
    },
  }),
);

export const UploaderContainer = styled(Box)(
  () => ({
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      padding: '10px 36px',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      }
    },
  }),
);

export const CommentText = styled(Typography)(
  () => ({
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '5px',
  }),
);

export const TemplateButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
    borderRadius: '10px',
    color: themeDefault.palette.mainBackground,
    float: 'left',
    marginTop: '60px',
    marginRight: '250px',
    padding: '10px',
    width: '250px',
    '&:hover': {
      backgroundColor: themeDefault.palette.lightMainColor,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      marginRight: 0,
      width: '100%',
    },
  }),
);

export const ButtonImageContainer = styled(Box)(
  () => ({
    display: 'flex',
  }),
);

export const DownloadIcon = styled('img')(
  () => ({
    marginRight: '8px',
  }),
);

export const NavigationButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '10px',
    border: '2px solid',
    color: 'white',
    float: 'right',
    padding: '10px 36px',
    marginTop: '60px',
    width: '250px',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor,
    },
    '&:disabled': {
      backgroundColor: themeDefault.palette.disabledColor,
    },
    [theme.breakpoints.down('sm')]: {
      float: 'left',
      marginTop: '20px',
      width: '100%',
    },
  }),
);

export const ReportContainer = styled(Box)(
  () => ({
    maxWidth: '600px',
    minWidth: '500px',
    minHeight: '200px',
    maxHeight: '400px',
    overflow: 'auto',
    paddingTop: '20px',
    paddingBottom: '20px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  }),
);

export const PaperCircle = styled(Paper)(
  () => ({
    alignItems: 'center',
    backgroundColor: 'inherit',
    border: 0,
    borderRadius: '50%',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    left: 'calc(50% - 24px)',
    position: 'absolute',
    top: '-24px',
    verticalAlign: 'middle',
    width: '48px',
  }),
);

export const StepText = styled(Paper)(
  () => ({
    color: themeDefault.palette.whiteColor,
    display: 'inline-block',
    fontSize: 25,
    textAlign: 'center',
    verticalAlign: 'middle',
  }),
);

export const StepContainerStyled = styled(Container)(
  () => ({
    ...themePublic.importModalStepContainer as CSSProperties,
    backgroundColor: themeDefault.palette.disabledColor,
    '& .MuiPaper-root': {
      backgroundColor: themeDefault.palette.disabledColor,
      boxShadow: 'none',
    },
  }),
);

export const ActiveStepContainerStyled = styled(Container)(
  () => ({
    ...themePublic.importModalStepContainer as CSSProperties,
    backgroundColor: themeDefault.palette.mainColor,
    '& .MuiPaper-root': {
      backgroundColor: themeDefault.palette.mainColor,
      boxShadow: 'none',
    },
  }),
);
