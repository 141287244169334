import React, { useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { InputField, Loader, PageWithMessage } from 'components';
import { useAuth } from 'contexts';
import { PASSWORD_PAGE_TYPE, USER_STATUS, VALIDATION_RESTRICTION } from 'enums';
import { isPageForSettingPassword, showToast } from 'helpers';
import { logo, setPasswordImage } from 'resources';
import { SET_PASSWORD, graphMutationMiddleware } from 'services';
import { FormikMethodsTypes, SetPasswordFormValues, UseAuthProps, UserProps } from 'types';

import {
  ContentContainer, ContentContainerDisabled, DividerStyled, ErrorMessage, GridStyled,
  MaxContrainer, PageContainer, PageTitle, SetPasswordImageContainer, SignUpButton
} from './SetPasswordPage.styled';

type SetPasswordPageProps = {
  pageType: PASSWORD_PAGE_TYPE,
  user?: UserProps,
  loadingUser?: boolean,
  isForgotPassword?: boolean,
}

export const SetPasswordPage = ({ pageType, user, loadingUser = false, isForgotPassword = false }: SetPasswordPageProps) => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();
  const navHistory = useNavigate();

  const { currentUser, login }: UseAuthProps = useAuth();
  const intialValues = { password: '', repeatPassword: '' };
  const [ showPassword, setShowPassword ] = useState(false);
  const [ showRepeatPassword, setShowRepeatPassword ] = useState(false);

  const [ setPassword, { loading }] = graphMutationMiddleware(SET_PASSWORD);

  const setPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('required'))
      .min(VALIDATION_RESTRICTION.EIGHT, t('shortPasswordError', { length: VALIDATION_RESTRICTION.EIGHT }))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('password'), length: VALIDATION_RESTRICTION.HUNDRED }))
      .matches(/[0-9]/, t('passwordNumberError'))
      .matches(/[a-z]/, t('passwordLowerError'))
      .matches(/[A-Z]/, t('passwordUpperError'))
      .matches(/[^\w]/, t('passwordSymbolError')),
    repeatPassword: Yup.string()
      .required(t('required'))
      .oneOf([ Yup.ref('password'), null ], t('confirmPasswordError'))
  });

  const handleSubmit = (values: SetPasswordFormValues, { setSubmitting, resetForm }: FormikMethodsTypes) => {
    setPassword({variables: { token: searchParams.get('t'), password: values.password}})
      .then(async (response: { data: { setPassword: string; }; }) => {
        setSubmitting(false);
        await login(response.data.setPassword, values.password);
        !isForgotPassword && navHistory('/invitationTemplates');
        showToast('info', t(isPageForSettingPassword(pageType) ? 'successfullyRegistered' : 'passwordChangedSuccessfully'));
        resetForm();
      }).catch(() => {
        setSubmitting(true);
      });
  };

  if (pageType === PASSWORD_PAGE_TYPE.SET_PASSWORD) {
    if (loadingUser || currentUser) {
      return <Loader inProgress={loadingUser || !!currentUser} />;
    }

    if (!user) {
      return <PageWithMessage message={t('userDoesNotExist')} />;
    }

    if (!user?.activationDate && new Date(user?.tokenExpirationDate) < new Date()) {
      return <PageWithMessage message={t('tokenExpired')} />;
    }

    if (user?.activationDate && user?.status === USER_STATUS.INACTIVE.toUpperCase()) {
      return <PageWithMessage message={t('userHasBeenTemporarilyLocked')} />;
    }

    if (user?.activationDate && user?.status === USER_STATUS.ACTIVE.toUpperCase()) {
      return <PageWithMessage message={t('userAlreadyActivated')} />;
    }
  }

  return (
    <PageContainer>
      <GridStyled
        container
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={3}>
          <Paper variant='outlined' sx={{ p: { xs: 2, md: 5 }, borderRadius: 5 }}>
            <Loader inProgress={loading} />
            <ContentContainer component={loading && ContentContainerDisabled}>
              <img src={logo} width={230} />
              <DividerStyled variant='middle' />
              <PageTitle variant='h5'>
                {pageType === PASSWORD_PAGE_TYPE.CHANGE_PASSWORD ? t('changePassword') : t('setPasswordLabel')}
              </PageTitle>
              <SetPasswordImageContainer>
                <img src={setPasswordImage} width={330} />
              </SetPasswordImageContainer>
              <Formik
                initialValues={intialValues}
                validationSchema={setPasswordSchema}
                onSubmit={handleSubmit}>
                {({ values, errors, isSubmitting, isValid }) => {
                  return (
                    <MaxContrainer>
                      <Form>
                        <Grid item>
                          <InputField
                            inputId='password'
                            inputName='password'
                            inputProps={true}
                            isError={errors.password}
                            label={t('password')}
                            handleClick={() => setShowPassword(!showPassword)}
                            showInputText={showPassword}
                            type='password' />
                          {errors.password && <ErrorMessage>{t('passwordRequirements')}</ErrorMessage>}
                          <InputField
                            inputId='repeatPassword'
                            inputName='repeatPassword'
                            inputProps={true}
                            isError={errors.repeatPassword}
                            label={t('repeatPassword')}
                            handleClick={() => setShowRepeatPassword(!showRepeatPassword)}
                            showInputText={showRepeatPassword}
                            type='password' />
                        </Grid>
                        <SignUpButton
                          disabled={isSubmitting || !isValid || values.password === ''}
                          type='submit'
                          disableElevation>
                          {pageType === PASSWORD_PAGE_TYPE.CHANGE_PASSWORD ? t('confirm') : t('signUp')}
                        </SignUpButton>
                      </Form>
                    </MaxContrainer>
                  );
                }}
              </Formik>
            </ContentContainer>
          </Paper>
        </Grid>
      </GridStyled>
    </PageContainer>
  );
};
