import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SelectFilter, VirtualKeyboard } from 'components';
import { useAuth } from 'contexts';
import { INVITATION_TEMPLATE_PARAMS, INVITATION_TEMPLATE_STATUS, USER_PERMISSIONS, USER_SETTINGS_CONFIG } from 'enums';
import { checkPermission, defaultPositionForMenu, handleOrder } from 'helpers';
import { menuClosed, menuOpen, searchFilter } from 'resources';
import {
  GET_COLORS,
  GET_TAGS,
  GET_TYPES,
  graphQueryMiddleware
} from 'services';
import { useTemplateStore } from 'store';
import { themeDefault } from 'themeDefault';
import { ColorType, TagType, TypesOfInvitationTemplate, UseAuthProps } from 'types';

import SearchIcon from '@mui/icons-material/Search';
import {
  ChipStyled, ContactPageHeader, CreateInvitationPageHeader,
  DividerStyled, FilterHeading, FilterImage, FilterItemContainer, FilterMenu, FilterSearchField,
  MenuContent, MenuItemStyled,
  SearchInputHeader, SubmenuButton, SubmenuContainer
} from './InvitationTemplatesHeader.styled';

type ContactsHeadingProps = {
  handleSetParams: (property: string, value: string | string[] | number) => void
  isCreateInvitationProcess: boolean
}

export const InvitationTemplatesHeader = ({ handleSetParams, isCreateInvitationProcess }: ContactsHeadingProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const { dbUser, saveUserSettings }: UseAuthProps = useAuth();
  const { params, searchText, setSearchText } = useTemplateStore();

  const [ filterEl, setFilterEl ] = useState<null | HTMLElement>(null);
  const [ submenuEl, setSubmenuEl ] = useState<null | HTMLElement>(null);
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  const filterRef = useRef();
  const keyboardRef = useRef(null);
  const isOpenFilter = Boolean(filterEl);

  const { data: tagsData } = graphQueryMiddleware(GET_TAGS, { filter: { type: 'INVITATION_TEMPLATE' }});
  const { data: typesData } = graphQueryMiddleware(GET_TYPES);
  const { data: colorsData } = graphQueryMiddleware(GET_COLORS);

  const handleNavigate = (route: string) => {
    localStorage.removeItem('activeId');
    navHistory(route);
  };

  const openFilters = () => {
    setFilterEl(filterRef.current);
  };
  const closeFilters = () => {
    setFilterEl(null);
  };
  const isOpenMenu = Boolean(submenuEl);

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setSubmenuEl(e.currentTarget);
  };
  const closeMenu = () => {
    setSubmenuEl(null);
  };
  const handleSelectSort = (sortTitle: string) => {
    handleSetParams(INVITATION_TEMPLATE_PARAMS.ORDER_BY, sortTitle);
    saveUserSettings(USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE, sortTitle, handleOrder(params.order));
    closeMenu();
  };

  const sortByMenu =
  <div>
    <SubmenuButton
      aria-controls={isOpenMenu ? 'demo-positioned-menu' : undefined}
      aria-haspopup='true'
      aria-expanded={isOpenMenu ? 'true' : undefined}
      onClick={openMenu}>
      {t('sortBy')}: {t(params.orderBy === 'createdAt' ? 'newest' : params.orderBy)} <img src={menuClosed} />
    </SubmenuButton>
    <MenuContent
      anchorEl={submenuEl}
      open={isOpenMenu}
      onClose={closeMenu}
      anchorOrigin={defaultPositionForMenu}
      transformOrigin={defaultPositionForMenu}>
      <MenuItemStyled onClick={() => handleSelectSort('name')}>
        {t('name')} <img src={menuOpen} />
      </MenuItemStyled>
      <DividerStyled color={themeDefault.palette.mainBackground} />
      <MenuItemStyled onClick={() => handleSelectSort('timesUsed')}>
        {t('timesUsed')}
      </MenuItemStyled>
      <DividerStyled color={themeDefault.palette.mainBackground} />
      <MenuItemStyled onClick={() => handleSelectSort('createdAt')}>
        {t('newest')}
      </MenuItemStyled>
    </MenuContent>
  </div>;

  return (
    <>
      <Box component={isCreateInvitationProcess ? CreateInvitationPageHeader : ContactPageHeader}>
        {!isCreateInvitationProcess && <Typography>{t('invitationTemplates')}</Typography>}
        {isCreateInvitationProcess && <Box></Box>}
        <div ref={filterRef}>
          <SearchInputHeader
            onFocus={() => setShowKeyboard(true)}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={t('searchPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start' onClick={() => openFilters()}>
                  <FilterImage src={searchFilter} />
                </InputAdornment>
              ),
            }}
            variant='outlined'/>
        </div>
        <FilterMenu
          anchorEl={filterEl}
          open={isOpenFilter}
          onClose={closeFilters}
          anchorOrigin={{
            vertical: 0,
            horizontal: 0,
          }}
          transformOrigin={defaultPositionForMenu}>
          <FilterHeading onClick={closeFilters}>
            <FilterImage src={searchFilter} />
          </FilterHeading>
          <FilterItemContainer>
            <FilterSearchField
              onFocus={() => setShowKeyboard(true)}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder={t('searchPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant='outlined'/>
            <SelectFilter
              isDisabledTranslation={true}
              placeholder={t('selectTags')}
              selectedItems={params.selectedTags}
              handleSelectItem={(tags) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_TAGS, tags)}
              items={tagsData?.tags.map((tag: TagType) => ({id: tag.id, title: tag.name}))}/>
            <SelectFilter
              isDisabledTranslation={true}
              placeholder={t('selectTypes')}
              selectedItems={params.selectedTypes}
              handleSelectItem={(types) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_TYPES, types)}
              items={typesData?.types.map((type: TypesOfInvitationTemplate) => ({id: type.id, title: type.name}))}/>
            <SelectFilter
              isDisabledTranslation={true}
              placeholder={t('selectStatus')}
              selectedItems={params.selectedStatus}
              handleSelectItem={(statuses) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_STATUS, statuses)}
              items={(Object.keys(INVITATION_TEMPLATE_STATUS) as (keyof typeof INVITATION_TEMPLATE_STATUS)[]).map((item, index) => ({id: index, title: t(item)}))}/>
            <SelectFilter
              placeholder={t('selectColors')}
              selectedItems={params.selectedColors}
              handleSelectItem={(colors) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_COLORS, colors)}
              items={colorsData?.colors.map((color: ColorType) => ({id: color.id, title: color.value, hexValue: color.hexValue}))}/>
          </FilterItemContainer>
        </FilterMenu>
        {isCreateInvitationProcess && sortByMenu}
        {!isCreateInvitationProcess &&
          <Button
            variant='contained'
            onClick={() => checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) ? handleNavigate('/invitationTemplates/create/details') : handleNavigate('/invitations/create/invitation')}>
            {checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) ? t('createNewInvitationTemplateBtn') : t('createNewInvitationBtn')}
          </Button>
        }
      </Box>
      <SubmenuContainer>
        <Box>
          {params.selectedTags.map((tagName) => (
            <ChipStyled label={t(tagName)} key={tagName} onDelete={() => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_TAGS, params.selectedTags.filter(item => item !== tagName))} />
          ))}
          {params.selectedTypes.map((typeName) => (
            <ChipStyled label={t(typeName)} key={typeName} onDelete={() => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_TYPES, params.selectedTypes.filter(item => item !== typeName))} />
          ))}
          {params.selectedStatus.map((statusName) => (
            <ChipStyled label={t(statusName)} key={statusName} onDelete={() => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_STATUS, params.selectedStatus.filter(item => item !== statusName))} />
          ))}
          {params.selectedColors.map((colorName) => (
            <ChipStyled label={t(colorName)} key={colorName} onDelete={() => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_COLORS, params.selectedColors.filter(item => item !== colorName))} />
          ))}
        </Box>
        { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          initialValues={{
            'searchText': searchText
          }}
          setFieldValue={null}
          setStandaloneItem={setSearchText}
          keyboardRef={keyboardRef}
          inputName={'searchText'}/>
        }
        {!isCreateInvitationProcess && sortByMenu}
      </SubmenuContainer>
    </>
  );
};