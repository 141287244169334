import React, { CSSProperties, useEffect, useState } from 'react';
import { ArrowForwardIos as ArrowForward } from '@mui/icons-material';
import { Box } from '@mui/material';
import { fabric } from 'fabric';
import { GraphQLError } from 'graphql';
import { isChrome, isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  AnimationContainer,
  CustomTooltip,
  DefaultButton,
  ImportGuestsFromContacts,
  InvitationPreviewSenderModal,
  Loader
} from 'components';
import { useAuth, useInvitation } from 'contexts';
import { addFontsToDocument, addShadowToCanvas, checkForLoadedFonts, getRouteForNavigation, isCurrentUsersInvitation, showToast } from 'helpers';
import {
  GET_FONTS,
  GET_GUESTS_BY_INVITATIONS_IDS,
  GET_INVITATION_BY_ID,
  GET_INVITATION_TEMPLATE_BY_ID,
  REPLACE_PLACEHOLDER_FOR_INVITATION_PREVIEW,
  REPLACE_PLACEHOLDER_FOR_INVITATION_TEMPLATE_PREVIEW,
  graphLazyQueryMiddleware,
  graphMutationMiddleware
} from 'services';
import { useGuestStore } from 'store';
import { themePublic } from 'themeDefault';
import { EviteType, FontType, ImageType, InvitationContextType, InvitationPreviewPlaceholderTypes, UseAuthProps } from 'types';

import {
  AnimationBox, AnimationBoxMedium, AnimationBoxSmall, AnimationWrapper, BigTypographyStyled,
  CanvasContainerMobileStyle,
  CanvasContainerStyle,
  ClickableLabelName, ContactPageHeader, ContentContainer, DataInformation,
  LabelName, LastPagePrevButton, MainCanvasContainer,
  NavigationButtonContainer, NavigationButtonWrapper, NavigationButtonWrapperTemplates, NavigationNextButton,
  OpenButton, OverflowTypographyContainer, PageButtonsContainer, PageContent, PageNextButton, PagePrevButton,
  SubheaderContainer, TypographyStyled
} from './InvitationPreviewPage.styled';

type InvitationPreviewPageProps = {
  isTemplate?: boolean,
  invitationTemplateStatus?: string,
  setInvitationTemplateStatus?: (invitatioNTemplateStatus: string) => void,
  saveInvitationTemplate?: () => void,
}

type NavigationState = {
  guest?: any
}

export const InvitationPreviewPage = ({ isTemplate = false, invitationTemplateStatus, setInvitationTemplateStatus, saveInvitationTemplate }:InvitationPreviewPageProps) => {
  const navHistory = useNavigate();
  const navLocation = useLocation();
  const [t] = useTranslation();
  const params = useParams();
  const { invitationDetails }: InvitationContextType = useInvitation();
  const { dbUser }: UseAuthProps = useAuth();
  const { guestForPreview, setGuestForPreview, isOpenImportModal, setIsOpenImportModal } = useGuestStore();

  const isCreatedByCurrentUser = isCurrentUsersInvitation(dbUser?.id, invitationDetails?.createdBy?.id);

  const [ getInvitation, { loading: isLoadingInvitation }] = graphLazyQueryMiddleware(isTemplate ? GET_INVITATION_TEMPLATE_BY_ID : GET_INVITATION_BY_ID);
  const [ getGuests, { data: guestsByIdData }] = graphLazyQueryMiddleware(GET_GUESTS_BY_INVITATIONS_IDS);
  const [replacePlaceholdersForInvitationPreview] = graphMutationMiddleware(REPLACE_PLACEHOLDER_FOR_INVITATION_PREVIEW);
  const [replacePlaceholdersForInvitationTemplatePreview] = graphMutationMiddleware(REPLACE_PLACEHOLDER_FOR_INVITATION_TEMPLATE_PREVIEW);
  const [getFonts] = graphLazyQueryMiddleware(GET_FONTS);

  const [ invitation, setInvitation ] = useState(null);
  const [ sender, setSender ] = useState(null);
  const [ newSenderId, setNewSenderId ] = useState(null);
  const [ canvasSize, setCanvasSize ] = useState({width: 0, height: 0});
  const [ canvas, setCanvas ] = useState(null);
  const [ allGuestsIterator, setAllGuestIterator ] = useState(0);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isLoadingEnvelopeData, setIsLoadingEnvelopeData ] = useState(true);
  const [ previewAnimation, setPreviewAnimation ] = useState(false);
  const [ evitesForPreview, setEvitesForPreview ] = useState(null);
  const [ envelopeForPreview, setEnvelopeForPreview ] = useState(null);
  const [ audio, setAudio ] = useState(null);
  const [ playAudio, setPlayAudio ] = useState(false);
  const [ isPreviewDisabled, setIsPreviewDisabled ] = useState(false);
  const [ isFontStyleReady, setIsFontStyleReady ] = useState(false);
  const [ isHiddenSideEvite, setIsHiddenSideEvite ] = useState(false);
  const state = navLocation?.state as NavigationState;

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const handleClose = () => {
    setIsOpen(false);
    setNewSenderId(sender.id);
  };

  const iterate = (num:number) => {
    setIsLoadingEnvelopeData(true);
    getGuests({ variables: {
      invitationsIds: [invitation.id],
      take: 1,
      skip: num
    }}).then(res => {
      if ( res && res.data.guests?.items?.length > 0 ) {
        setGuestForPreview(res.data.guests.items[0]);
        setAllGuestIterator(num);
        setIsLoadingEnvelopeData(false);
      }
    }).catch(() => {
      setIsLoadingEnvelopeData(false);
    });
  };

  const next = () => {
    iterate(allGuestsIterator + 1);
  };

  const undo = () => {
    iterate(allGuestsIterator - 1);
  };

  const setInitCanvasSize = (width: number | null, height: number | null) => {
    if (width && height) {
      setCanvasSize({width: width, height: height});
    } else {
      setCanvasSize({width: 1000, height: 600});
    }
  };

  const getInv = () => {
    if (params.id) {
      getInvitation({
        fetchPolicy: 'no-cache',
        variables: {
          id: Number(params.id)
        }
      }).then(res => {
        res && setInvitation(isTemplate ? res.data.invitationTemplate : res.data.invitation);
      }
      ).catch(() => {
        setInvitation(null);
      });
    }
  };

  const getGuest = () => {
    setIsLoadingEnvelopeData(true);
    getGuests({
      fetchPolicy: 'no-cache',
      variables: {
        invitationsIds: [invitation.id],
        take: 1,
        skip: 0
      }}).then(res => {
      res && res.data.guests?.items?.length > 0 && setGuestForPreview(res.data.guests.items[0]);
    }).catch(() => {
      setGuestForPreview(null);
    });
  };

  const initCanvas = (id: string) => new fabric.Canvas(id, {});

  const handleSender = () => {
    if (newSenderId) {
      for (const senderr of invitation.senders) {
        if (senderr.id === newSenderId) {
          setSender(senderr);
        }
      }
    }
    setIsOpen(false);
  };

  useEffect(() => {
    replacePlaceholder();
  }, [ sender, guestForPreview ]);

  useEffect(() => {
    setCanvas(initCanvas('canvasMain'));
  }, []);

  useEffect(() => {
    if (!canvas) {
      return;
    }
    const canvasDiv = document.getElementById('canvasContainer');

    let availableWidth = canvasDiv.clientWidth;
    let availableHeight = canvasDiv.clientHeight;
    if (isMobile) {
      availableWidth = availableWidth - 50;
      availableHeight = availableHeight - 50;
    }

    const widthScale = availableWidth / canvasSize.width;
    const heightScale = availableHeight / canvasSize.height;
    const scale = Math.min(widthScale, heightScale);

    canvas.setWidth(canvasSize.width * scale);
    canvas.setHeight(canvasSize.height * scale);

    canvas?.renderAll();

    resizeCanvas(scale);
  }, [canvasSize]);

  const resizeCanvas = (scale: number) => {
    canvas?.zoomToPoint(new fabric.Point(0, 0), scale);
    canvas?.calcOffset();
    canvas?.renderAll();
  };

  useEffect(() => {
    getInv();
    getFonts()
      .then(res => {
        if (res.data.fonts.filter((font: FontType) => !!font.ttfUrl).length > 0) {
          addFontsToDocument(res.data.fonts)
            .then(() => {
              checkForLoadedFonts(setIsFontStyleReady);
            })
            .catch(() => {
              setIsFontStyleReady(true);
            });
        } else {
          setIsFontStyleReady(true);
        }
      });
  }, []);

  useEffect(() => {
    if (invitation !== null && !isTemplate) {
      if (!sender) {
        setSender(invitation.senders.find((s: any) => s.isDefault));
        setNewSenderId(invitation.senders.find((s: any) => s.isDefault)?.id);
      }
      state?.guest && setGuestForPreview(state.guest);
      !state && getGuest();
    }
    invitation && replacePlaceholder();
  }, [invitation]);

  useEffect(() => {
    if (invitationTemplateStatus) {
      saveInvitationTemplate();
      setInvitationTemplateStatus(undefined);
    }
  }, [invitationTemplateStatus]);

  const loadCanvas = (resContentJson: any) => {
    setIsLoadingEnvelopeData(true);
    canvas.loadFromJSON(resContentJson, () => {
      for (const obj of canvas._objects) {
        obj.evented = false;
      }
      addShadowToCanvas(canvas);
      canvas.selection = false;
      const contentJson = JSON.parse(resContentJson);
      setInitCanvasSize(contentJson.width / contentJson.viewportTransform[0], contentJson.height / contentJson.viewportTransform[3]);
    });
    setIsLoadingEnvelopeData(false);
  };

  const replacePlaceholder = () => {
    if (!invitation) {
      return;
    }
    if (!invitation.envelope) {
      showToast('info', t('envelopeIsNotCreated'));
      return;
    }

    if (isTemplate) {
      replacePlaceholdersForInvitationTemplatePreview({variables: {
        invitationTemplateId: Number(params.id)
      }}).then((resReplacePlaceholders: any) => {
        setDataForTheAnimation(resReplacePlaceholders.data.replacePlaceholdersForInvitationTemplatePreview);
      });
    } else {
      if (canvas) {
        replacePlaceholdersForInvitationPreview({variables: {
          invitationId: Number(params.id),
          senderId: sender ? sender.id : null,
          guestId: guestForPreview ? guestForPreview.id: null,
        }}).then((resReplacePlaceholders: any) => {
          setDataForTheAnimation(resReplacePlaceholders.data.replacePlaceholdersForInvitationPreview);
          setIsPreviewDisabled(false);
        }).catch(error => {
          if (error.graphQLErrors.filter((err: GraphQLError) => err.extensions.code === 'NULL_REFERENCE')) {
            setIsPreviewDisabled(true);
          }
        });
      }
    }
  };

  const setDataForTheAnimation = (resContentJson: InvitationPreviewPlaceholderTypes) => {
    let allEvites: EviteType[] = resContentJson.evites;
    if (allEvites.length === 2) {
      allEvites = allEvites.concat(allEvites);
      setIsHiddenSideEvite(true);
    }
    setEvitesForPreview(allEvites.map((img: ImageType, index: number) => {
      return {
        id: index,
        name: img.name,
        contentJson: img.contentJson,
      };
    }));
    setEnvelopeForPreview(resContentJson.envelope.contentJson);
    setAudio(new Audio(resContentJson.envelope.music?.url));
    loadCanvas(resContentJson.envelope.contentJson);
  };

  useEffect(() => {
    if (!audio) {
      return;
    }
    if (playAudio) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [playAudio]);

  useEffect(() => {
    if (state?.guest) {
      setGuestForPreview(state.guest);
      setSender(state.guest.sender);
      setNewSenderId(state.guest.sender.id);
    }
  }, [navLocation.state]);

  return (
    <>
      <Loader loadingPage={false} inProgress={isLoadingEnvelopeData || isLoadingInvitation} />
      <PageContent>
        {
          previewAnimation &&
          isFontStyleReady &&
          evitesForPreview &&
          envelopeForPreview &&
            <AnimationBox as={isMobile && isIOS && (isChrome ? AnimationBoxSmall : AnimationBoxMedium)}>
              <AnimationWrapper>
                <AnimationContainer isHiddenSideEvite={isHiddenSideEvite} setPlayAudio={() => setPlayAudio(true)} images={evitesForPreview} envelopeImage={envelopeForPreview} isPreview={true}/>
              </AnimationWrapper>
              <DefaultButton title={t('closeAnimation')} handleFunction={() => {
                setPreviewAnimation(false);
                setPlayAudio(false);
              }} theme={themePublic.closeAnimationButton as CSSProperties} />
            </AnimationBox>
        }
        <ContentContainer>
          <ContactPageHeader>
            <OverflowTypographyContainer>
              <span>
                <LabelName> {t('from')} </LabelName>
                <DataInformation>
                  <CustomTooltip theme={themePublic.overplappedTextPreviewPage as CSSProperties} text={isTemplate ? t('EthanCohen') : sender ? sender.firstName + ' ' + sender.lastName : t('senderName')} />
                </DataInformation>
              </span>
              {!isTemplate && <ClickableLabelName onClick={() => setIsOpen(true)}>{t('edit')}</ClickableLabelName>}
            </OverflowTypographyContainer>
            <OverflowTypographyContainer>
              <span>
                <LabelName style={{marginLeft: isMobile ? '0' : '3rem'}}> {t('to')} </LabelName>
                <DataInformation>
                  <CustomTooltip theme={themePublic.overplappedTextPreviewPage as CSSProperties} text={isTemplate || !guestForPreview ? t('NoahAdams') : guestForPreview.contact.firstName ?? '' + ' ' + guestForPreview.contact.lastName} />
                </DataInformation>
              </span>
              {!isTemplate && <ClickableLabelName onClick={() => setIsOpenImportModal(true)}>{t('edit')}</ClickableLabelName>}
            </OverflowTypographyContainer>
          </ContactPageHeader>
          <SubheaderContainer>
            {invitation && !isTemplate &&
            <>
              <TypographyStyled>
                <CustomTooltip theme={themePublic.overplappedTextPreviewPage as CSSProperties} text={`${sender ? sender.firstName + ' ' + sender.lastName : t('senderName')} ${t('hasSentYouInvitation')}`} />
              </TypographyStyled>
              <BigTypographyStyled>
                <CustomTooltip theme={themePublic.overplappedTextPreviewPage as CSSProperties} text={invitation.name} />
              </BigTypographyStyled>
            </>
            }
            {isTemplate &&
            <>
              <TypographyStyled>{t('EthanCohen')} {t('hasSentYouInvitation')}</TypographyStyled>
              <TypographyStyled>{t('CalebAndElizaWedding')} </TypographyStyled>
            </>
            }
          </SubheaderContainer>
          <MainCanvasContainer>
            <div id='canvasContainer' style={isMobile ? CanvasContainerMobileStyle : CanvasContainerStyle}>
              <canvas id='canvasMain'/>
            </div>
          </MainCanvasContainer>
          <Box component={isTemplate ? NavigationButtonWrapperTemplates : NavigationButtonWrapper}>
            <NavigationButtonContainer>
              {!isTemplate &&
              <NavigationNextButton disabled={allGuestsIterator === 0} onClick={() => undo()} variant='contained'>
                <ArrowForward style={{transform: 'rotateY(180deg)'} as CSSProperties}/>
              </NavigationNextButton>}
              <OpenButton
                onClick={() => !isPreviewDisabled && setPreviewAnimation(true)}
                variant='contained'
                disabled={isPreviewDisabled}>
                {t('openInvitations')}
              </OpenButton>
              {!isTemplate &&
              <NavigationNextButton
                disabled={
                  guestsByIdData?.guests?.totalCount === allGuestsIterator + 1 ||
                  guestsByIdData?.guests?.totalCount === 0 ||
                  invitation?.guests?.length < 2}
                onClick={() => next()}
                variant='contained' >
                <ArrowForward />
              </NavigationNextButton>}
            </NavigationButtonContainer>
          </Box>
        </ContentContainer>
      </PageContent>
      <PageButtonsContainer>
        <PagePrevButton
          as={isMobile && (isTemplate || !isCreatedByCurrentUser) ? LastPagePrevButton : PagePrevButton}
          variant='contained'
          onClick={() => handleNavigate(getRouteForNavigation(navLocation.pathname, 'envelope', params['*']))}>
          {t(isMobile ? 'back' : 'prevEnvelope')}
        </PagePrevButton>
        {!isTemplate && isCreatedByCurrentUser &&
        <PageNextButton
          variant='outlined'
          onClick={() => handleNavigate(getRouteForNavigation(navLocation.pathname, 'send', params['*']))}>
          {t(isMobile ? 'next' : 'nextSend')}
        </PageNextButton>
        }
      </PageButtonsContainer>
      <InvitationPreviewSenderModal isOpen={isOpen} handleClose={() => handleClose()} invitation={invitation} setNewSenderId={setNewSenderId} handleSender={() => handleSender()} newSenderId={newSenderId} sender={sender} />
      <ImportGuestsFromContacts isOpen={isOpenImportModal} handleClose={() => setIsOpenImportModal(false)} forPreview={true} />
    </>
  );
};