import { gql } from '@apollo/client';

export const GET_INVITATION_TEMPLATES = gql`
query InvitationTemplates($order: [InvitationTemplatePayloadSortInput!], $filter: InvitationTemplateFilterInput, $skip: Int, $take: Int) {
  invitationTemplates(
    order: $order
    filter: $filter
    skip: $skip
    take: $take
    )
    {
      items {
        id
        name
        createdAt
        timesUsed
        typeId
        status
        colors
        createdBy {
          id
          firstName
          lastName
        }
        description
        tags {
          name
        }
        evites {
          id
          name
          isDefault
          position
          contentJson
          image {
            id
            name
            url
            thumbnailUrl
            key
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const GET_INVITATION_TEMPLATE_BY_ID = gql`
  query InvitationTemplate($id: Long!) {
    invitationTemplate(id: $id) {
      id
      name
      description
      typeId
      status
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      colors
      tags {
        name
      }
      evites {
        id
        name
        isDefault
        position
        contentJson
        image {
          id
          name
          url
          thumbnailUrl
          key
        }
      }
      envelope {
        id
        contentJson
        musicId
      }
    }
  }
`;

export const GET_COLORS = gql`
  query{
    colors {
      id
      value
      hexValue
    }
  }
`;

export const GET_TYPES = gql`
  query{
    types {
      id
      description
      name
      imageSvg
    }
  }
`;

export const GET_IMAGES = gql`
query {
  images {
    id
    name
    url
    thumbnailUrl
  }
}
`;