import React, { ElementType, useState } from 'react';
import {
  Checkbox,
  Divider,
  Menu,
  TableCell,
  TableCellBaseProps,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal, EditTagsModal, } from 'components';
import { useAuth } from 'contexts';
import { ACTIVITY_STATUS, USER_PERMISSIONS } from 'enums';
import { checkPermission, handleOrder, invitationColumns } from 'helpers';
import { BULK_DELETE_INVITATIONS, BULK_UPDATE_INVITATION_ACTIVITY_STATUS, graphMutationMiddleware } from 'services';
import { useInvitationStore } from 'store';
import { TableColumnProps, UseAuthProps } from 'types';

import { AddCircle, Cancel, Delete, Edit, LocalOffer, MoreHoriz } from '@mui/icons-material';
import {
  BulkTableHead, CheckBoxHeaderTableCell, CheckBoxHeaderTableCellSelected,
  FirstTableCellHeader, FirstTableCellHeaderSelected, HiddenElement, LastTableCell,
  RemovedBorderTableHead, TableBulkOptionsMenu, TableBulkOptionsMenuItem, TextTableCell,
  TitleHeaderTableCell, TitleHeaderTableCellWider, TypographyStyled
} from './InvitationTableHead.styled';

type InvitationTableHeadProps = {
  invitationsLength: number;
  isSelectedAll: boolean;
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InvitationTableHead = ({ invitationsLength, isSelectedAll, handleSelectAll } : InvitationTableHeadProps) => {
  const { dbUser }: UseAuthProps = useAuth();
  const [t] = useTranslation();
  const { params, setParams, selectedItems: selectedInvitations, changeExecuteRefetch } = useInvitationStore();
  const [ bulkDeleteInvitations, { loading: deleteInProgress }] = graphMutationMiddleware(BULK_DELETE_INVITATIONS);
  const [ bulkUpdateActivityStatus, { loading: updateInProgress }] = graphMutationMiddleware(BULK_UPDATE_INVITATION_ACTIVITY_STATUS);

  const [ editTags, setEditTags ] = useState<boolean>(false);
  const [ isDeleteModal, setIsDeleteModal ] = useState(false);
  const [ isUpdateModal, setIsUpdateModal ] = useState(false);
  const [ invitationActivityStatus, setInvitationActivityStatus ] = useState('active');
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const bulkDelete = () => {
    bulkDeleteInvitations({variables: {
      invitationIds: selectedInvitations
    }}).then(() => {
      changeExecuteRefetch();
    });
    setIsDeleteModal(false);
  };

  const bulkUpdateStatusActivity = () => {
    bulkUpdateActivityStatus({variables: {
      invitationIds: selectedInvitations,
      activityStatus: invitationActivityStatus
    }}).then(() => {
      changeExecuteRefetch();
    });
  };

  const changeActivityStatus = (activityStatus: string) => {
    setIsUpdateModal(true);
    setInvitationActivityStatus(activityStatus);
  };

  const getTitleHeaderTableCell = () => {
    return (checkPermission(dbUser, USER_PERMISSIONS.VIEW_ALL_INVITATIONS) ? TitleHeaderTableCell : TitleHeaderTableCellWider) as ElementType<TableCellBaseProps>;
  };

  const boldColumnTitle = (id: number, permission: USER_PERMISSIONS) => {
    return permission && !(checkPermission(dbUser, permission)) ? HiddenElement : getTitleHeaderTableCell();
  };

  return (
    <>
      {
        isMobile ?
          selectedInvitations.length > 0 ?
            <>
              <TypographyStyled>{`${selectedInvitations.length} ${t('selected')}`}</TypographyStyled>
              <TableBulkOptionsMenu
                id='basic-button'
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreHoriz />
              </TableBulkOptionsMenu>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <TableBulkOptionsMenuItem onClick={() => {
                  setEditTags(true);
                  setAnchorEl(null);
                  return;
                }}>
                  <LocalOffer/>{t('editTags')}
                </TableBulkOptionsMenuItem>
                <Divider />
                <TableBulkOptionsMenuItem onClick={() => {
                  setAnchorEl(null);
                  changeActivityStatus(ACTIVITY_STATUS.ACTIVE.toUpperCase());
                }}>
                  <AddCircle/>{t('activate')}
                </TableBulkOptionsMenuItem>
                <Divider />
                <TableBulkOptionsMenuItem onClick={() => {
                  setAnchorEl(null);
                  changeActivityStatus(ACTIVITY_STATUS.INACTIVE.toUpperCase());
                }}>
                  <Cancel/>{t('deactivate')}
                </TableBulkOptionsMenuItem>
                <Divider />
                <TableBulkOptionsMenuItem onClick={() => {
                  setAnchorEl(null);
                  setIsDeleteModal(true);
                }}><Delete/>{t('delete')}
                </TableBulkOptionsMenuItem>
              </Menu>
            </> :
            <Typography >{t('selectAll')}</Typography> :
          selectedInvitations.length > 0 ?
            <BulkTableHead>
              <TableRow>
                <TableCell component={(isSelectedAll && selectedInvitations.length > 0 ? FirstTableCellHeaderSelected : FirstTableCellHeader) as ElementType<TableCellBaseProps>}>
                  <Checkbox onChange={(e) => handleSelectAll(e)} checked={isSelectedAll && selectedInvitations.length > 0} />
                </TableCell>
                <TextTableCell>
                  <TypographyStyled>
                    {`${selectedInvitations.length} ${t('selected')}`}
                  </TypographyStyled>
                </TextTableCell>
                <TableCell component={getTitleHeaderTableCell()}>
                  <TypographyStyled onClick={() => setEditTags(true)}>
                    <Edit />{t('editTags')}
                  </TypographyStyled>
                </TableCell>
                <TableCell component={getTitleHeaderTableCell()}>
                  <TypographyStyled onClick={() => changeActivityStatus(ACTIVITY_STATUS.ACTIVE.toUpperCase())}>
                    <AddCircle />{t('activate')}
                  </TypographyStyled>
                </TableCell>
                <TableCell component={getTitleHeaderTableCell()}>
                  <TypographyStyled onClick={() => changeActivityStatus(ACTIVITY_STATUS.INACTIVE.toUpperCase())}>
                    <Cancel />{t('deactivate')}
                  </TypographyStyled>
                </TableCell>
                <TableCell component={getTitleHeaderTableCell()}>
                  <TypographyStyled onClick={() => setIsDeleteModal(true)}>
                    <Delete />{t('delete')}
                  </TypographyStyled>
                </TableCell>
                <TableCell component={getTitleHeaderTableCell()} />
                <TableCell component={getTitleHeaderTableCell()} />
                <TableCell component={getTitleHeaderTableCell()} />
                <TableCell component={checkPermission(dbUser, USER_PERMISSIONS.VIEW_ALL_INVITATIONS) ? getTitleHeaderTableCell() : HiddenElement as ElementType<TableCellBaseProps>} />
                <LastTableCell />
              </TableRow>
            </BulkTableHead> :
            <RemovedBorderTableHead>
              <TableRow>
                <TableCell component={(invitationsLength === selectedInvitations.length && selectedInvitations.length > 0 ? CheckBoxHeaderTableCellSelected : CheckBoxHeaderTableCell) as ElementType<TableCellBaseProps>}>
                  <Checkbox onChange={(e) => handleSelectAll(e)} checked={invitationsLength === selectedInvitations.length && selectedInvitations.length > 0 } />
                </TableCell>
                {(invitationColumns as TableColumnProps[]).map( (column: TableColumnProps, index: number) => (
                  <TableCell key={column.id} component={(index === 0 ? TextTableCell : index === (invitationColumns.length - 1) ? LastTableCell : boldColumnTitle(column.id, column.permission)) as ElementType<TableCellBaseProps>}>
                    {t(column.title)}
                    {column.enableSorting &&
                      <TableSortLabel
                        active={params.orderBy === column.sortableName}
                        direction={params.order}
                        onClick={() => {
                          setParams({
                            ...params,
                            order: handleOrder(params.order),
                            orderBy: column.sortableName
                          });
                        }}>
                      </TableSortLabel>
                    }
                  </TableCell>
                ))}
              </TableRow>
            </RemovedBorderTableHead>
      }
      <ConfirmationModal
        loading={deleteInProgress}
        isOpen={isDeleteModal}
        handleClose={() => setIsDeleteModal(false)}
        handleConfirm={bulkDelete}
        confimMessage={t('invitationsDeletionsConfirmMessage')}/>
      <ConfirmationModal
        loading={updateInProgress}
        isOpen={isUpdateModal}
        handleClose={() => setIsUpdateModal(false)}
        handleConfirm={bulkUpdateStatusActivity}
        confimMessage={t('invitationsStatusMessage', { status: invitationActivityStatus === ACTIVITY_STATUS.ACTIVE.toUpperCase() ? 'activation' : 'deactivation'})}/>
      {editTags &&
        <EditTagsModal isOpen={editTags} closeEditTags={() => setEditTags(false)} selectedIds={selectedInvitations} forInvitations={true} />
      }
    </>
  );
};
