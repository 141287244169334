import { Box, Button, TextField, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    border: `1px solid ${themeDefault.palette.mainBackground}`,
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    height: 'min-content',
    justifyContent: 'center',
    margin: '0 15%',
    position: 'fixed',
    top: '100%',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '20px 20px 0 0',
      margin: 0,
      maxHeight: '85vh',
      width: '100% !important',
    },
  }),
);

export const ShowModalContentContainerStyle = {
  transform: 'translate(0%, -100%)',
  transition: 'all 1s ease-out',
};

export const HideModalContentContainer = {
  transform: 'translate(0%, -5%)',
  transition: 'all 1s ease-out',
  [themeLocal.breakpoints.down('sm')]: {
    transform: 'translate(0%, -10%)',
  },
};

export const GuestContainer = styled(Box)(
  () => ({
    height: 'min-content',
    maxHeight: '55vh',
    overflowY: 'auto',
  }),
);

export const PersonalMessageContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '30px auto',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      margin: '10px auto',
    }
  }),
);

export const PersonalMessageContainerMultipleGuests = styled(PersonalMessageContainer)(
  () => ({
    margin: '30px auto',
    width: '80%',
  }),
);

export const PersonalMessageInput = styled(TextField)(
  ({ theme }) => ({
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '100%',
    },
  }),
);

export const PersonalMessageText = styled(Typography)(
  () => ({
    fontWeight: 'bold',
    opacity: '0.5',
    width: '20%',
  }),
);

export const ButtonsContainer = styled(Box)(
  () => ({
    margin: '30px auto',
    width: '80%',
  }),
);

export const CloseButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    marginLeft: '40px',
    transform: 'translateY(-50%)',
    width: '10%',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      left: '50%',
      marginLeft: 0,
      padding: '1rem',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    height: 'fit-content',
    margin: 'initial',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
      width: '100%',
    },
  }),
);

export const ButtonSubcontainer = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      float: 'right',
      justifyContent: 'space-between',
      width: '80%',
    }
  }),
);

export const ButtonSubcontainerMultipleGuests = styled(ButtonSubcontainer)(
  ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      float: 'inherit',
      margin: 'auto',
    }
  }),
);
