import { Done, Edit } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const editDoneIconStyle = {
  fill: themeDefault.palette.darkGreyColor,
  fontSize: '16px',
  top: '-1rem',
};

export const PageBody = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageBody as CSSProperties,
    overflowX: 'hidden',
    padding: '0.5rem 1.5rem',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      padding: '1rem 1.5rem !important',
    }
  }),
);

export const EventNameConatiner = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    width: '95%',
    '& fieldset': {
      display: 'none',
    },
    '& input': {
      padding: '0',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
);

export const EditIconContainer = styled(Box)(
  () => ({
    display: 'inline-block',
  }),
);

export const IconButtonStyled = styled(IconButton)(
  () => ({
    cursor: 'default',
    opacity: 0.6,
    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    }
  }),
);

export const EditIcon = styled(Edit)(
  () => ({
    ...editDoneIconStyle,
  }),
);

export const DoneIcon = styled(Done)(
  () => ({
    ...editDoneIconStyle,
  }),
);

export const EventNameTextField = styled(TextField)(
  () => ({
    color: themeDefault.palette.darkGreyColor,
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineClamp: 2,
    overflow: 'hidden',
    paddingLeft: '4px',
    width: '100%',
    '& input': {
      ...themeDefault.overflowText,
      width: '100%'
    }
  }),
);
