import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const UrlContainer = styled(Box)(
  () => ({
    position: 'relative',
    marginBottom: '24px',
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  ({ theme }) => ({
    cursor: 'pointer',
    fill: themeDefault.palette.darkGreyColor,
    position: 'absolute',
    right: '0%',
    top: '25px',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: themeDefault.palette.whiteColor,
      right: '-4px',
    }
  }),
);
