import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageContainer = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  }),
);

export const ErrorCode = styled(Typography)(
  () => ({
    fontSize: '80px',
    fontWeight: 'bold',
  }),
);

export const ErrorCodeDescription = styled(Typography)(
  () => ({
    fontSize: '40px',
    fontWeight: 'bold',
  }),
);

export const ErrorMessage = styled(Typography)(
  () => ({
    fontSize: '20px',
    marginBottom: '200px',
  }),
);
