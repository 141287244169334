import React from 'react';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FORGOT_PASSWORD } from 'services';
import * as Yup from 'yup';

import { InputField } from 'components';
import { VALIDATION_RESTRICTION } from 'enums';
import { showToast } from 'helpers';
import { logo, setPasswordImage } from 'resources';
import { FormikMethodsTypes } from 'types';

import { ContentContainer, DividerStyled, GridStyled, MaxContrainer, PageContainer, PageTitle, PaperStyled, SetPasswordImageContainer, SubmitButton } from './ForgotPasswordPage.style';

type FrgotPasswordValuesType = {
  email: string
}

export const ForgotPasswordPage = () => {
  const [t] = useTranslation();

  const intialValues = { email: '' };
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('invalidEmailFormat'))
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))
  });

  const handleSubmit = (values: FrgotPasswordValuesType, { setSubmitting }: FormikMethodsTypes) => {
    forgotPassword({variables: { email: values.email}})
      .then(() => {
        setSubmitting(false);
        showToast('info', t('successfullyMailSent'));
      }).catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <PageContainer>
      <GridStyled
        container
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={3}>
          <PaperStyled variant='outlined' sx={{ p: { xs: 2, md: 5 }, borderRadius: 5 }}>
            <ContentContainer>
              <img src={logo} width={250} />
              <DividerStyled variant='middle' />
              <PageTitle variant='h5'>
                {t('forgotPasswordLabel')}
              </PageTitle>
              <SetPasswordImageContainer>
                <img src={setPasswordImage} width={350}/>
              </SetPasswordImageContainer>
              <Formik
                initialValues={intialValues}
                validationSchema={forgotPasswordSchema}
                onSubmit={handleSubmit}>
                {({ values, errors, isValid, isSubmitting }) => {
                  return (
                    <MaxContrainer>
                      <Form>
                        <Grid item>
                          <InputField
                            inputId='email'
                            inputName='email'
                            isError={errors.email}
                            label={t('email')}
                            type='email' />
                        </Grid>
                        <SubmitButton
                          disabled={Object.keys(errors).length > 0 || isSubmitting || !(isValid && values.email)}
                          type='submit'
                          disableElevation>
                          {t('send')}
                        </SubmitButton>
                      </Form>
                    </MaxContrainer>
                  );
                }}
              </Formik>
            </ContentContainer>
          </PaperStyled>
        </Grid>
      </GridStyled>
    </PageContainer>
  );
};