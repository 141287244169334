import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHeader, InputField } from 'components';
import { useAuth } from 'contexts';
import { VALIDATION_RESTRICTION } from 'enums';
import { UseAuthProps } from 'types';

import { FormContainer, FormikContainer, GridStyled, LoginButton, PageContainer, PaperStyled, PasswordContainer, SignUpButton } from './LoginPage.styled';

export const LoginPage = () => {
  const [ showPassword, setShowPassword ] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const intialValues = { email: '', password: '' };
  const { login }: UseAuthProps = useAuth();

  const handleLogin = async (email: string, password: string) => {
    login(email, password);
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('invalidEmailFormat'))
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY })),
    password: Yup.string()
      .required(t('required'))
      .min(VALIDATION_RESTRICTION.EIGHT, t('shortPasswordError', { length: VALIDATION_RESTRICTION.EIGHT }))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('password'), length: VALIDATION_RESTRICTION.HUNDRED }))
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setSubmitting(false);
    handleLogin(values.email, values.password);
  };

  return (
    <PageContainer>
      <GridStyled
        container
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={3}>
          <PaperStyled variant='outlined' sx={{ p: { xs: 2, md: 6 }, borderRadius: 5 }}>
            <FormikContainer>
              <FormHeader label={t('login')}/>
              <Formik
                initialValues={intialValues}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}>
                {({ values, errors, isSubmitting }) => {
                  return (
                    <FormContainer>
                      <Form>
                        <Grid item>
                          <InputField
                            inputId='email'
                            inputName='email'
                            isError={errors.email}
                            label={t('email')}
                            type='email' />
                        </Grid>
                        <Grid item>
                          <InputField
                            inputId='password'
                            inputName='password'
                            inputProps={true}
                            isError={errors.password}
                            label={t('password')}
                            handleClick={() => setShowPassword(!showPassword)}
                            showInputText={showPassword}
                            type='password' />
                        </Grid>
                        <Grid container
                          direction='row'
                          justifyContent='flex-end'
                          alignItems='flex-start'>
                          <PasswordContainer>
                            <Link to='/forgot-password'>{t('forgotPassword')}</Link>
                          </PasswordContainer>
                        </Grid>
                        <LoginButton
                          type='submit'
                          disableElevation
                          disabled={Object.keys(errors).length > 0 || !values.email || !values.password || isSubmitting}>
                          {t('login')}
                        </LoginButton>
                        <SignUpButton
                          onClick={() => navigate('/register')} >
                          {t('signUp')}
                        </SignUpButton>
                      </Form>
                    </FormContainer>
                  );
                }}
              </Formik>
            </FormikContainer>
          </PaperStyled>
        </Grid>
      </GridStyled>
    </PageContainer>
  );
};