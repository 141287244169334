import React, { useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent, Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputField, Loader, VirtualKeyboard } from 'components';
import { FORM_TYPE, VALIDATION_RESTRICTION, VENUE_STATUS } from 'enums';
import { ADD_VENUE, UPDATE_VENUE, graphMutationMiddleware } from 'services';
import { useVenuesStore } from 'store';
import { DialogComponentsType, FormikMethodsTypes, VenueFormValuesType, VenueProps } from 'types';

import {
  ButtonContainer, CloseIconStyled, DialogStyledAllowedEvents, DialogStyledPreventEvents, InputGroupContainer,
  ModalHeaderContainer, SubmitButton
} from './VenueModal.styled';

type CreateVenueModalProps = {
  isOpen: boolean,
  handleClose: (venue?: VenueProps) => void,
  type: FORM_TYPE,
  venue?: VenueProps,
};

export const VenueModal = ({isOpen, handleClose, type, venue}: CreateVenueModalProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);
  const { changeExecuteRefetch } = useVenuesStore();

  const [ createVenue, { loading: createLoading }] = graphMutationMiddleware(ADD_VENUE);
  const [ editVenue, { loading: editLoading }] = graphMutationMiddleware(UPDATE_VENUE);

  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  const intialValues = {
    name: venue?.name ?? '',
    address1: venue?.address1 ?? '',
    address2: venue?.address2 ?? '',
    city: venue?.city ?? '',
    state: venue?.state ?? '',
    zipCode: venue?.zipCode ?? '',
  };

  const addSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('name'), length: VALIDATION_RESTRICTION.HUNDRED })),
    address1: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.THREE_HUNDRED_AND_FIFTY, t('stringPropertyMaxValidation', { propertyName: t('address1'), length: VALIDATION_RESTRICTION.THREE_HUNDRED_AND_FIFTY })),
  });

  const handleCreate = (values: VenueFormValuesType, { setSubmitting, resetForm }: FormikMethodsTypes) => {
    const finalValues = {
      ...values,
    };
    createVenue({variables: {
      input: {
        name: finalValues.name,
        address1: finalValues.address1,
        address2: finalValues.address2,
        city: finalValues.city,
        state: finalValues.state,
        zipCode: finalValues.zipCode,
        status: type === FORM_TYPE.CREATE_PRIVATE || type === FORM_TYPE.EDIT_PRIVATE && venue.status === VENUE_STATUS.PUBLIC.toUpperCase() ?
          VENUE_STATUS.PRIVATE.toUpperCase() :
          VENUE_STATUS.PUBLIC.toUpperCase(),
      }}
    }).then((res) => {
      setSubmitting(false);
      handleClose(res.data.addVenue);
      resetForm();
      changeExecuteRefetch();
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const handleEdit = (values: VenueFormValuesType, { setSubmitting, resetForm }: FormikMethodsTypes) => {
    const finalValues = {
      ...values,
    };
    editVenue({variables: {
      input: {
        id: venue.id,
        name: finalValues.name,
        address1: finalValues.address1,
        address2: finalValues.address2,
        city: finalValues.city,
        state: finalValues.state,
        zipCode: finalValues.zipCode,
        status: type === FORM_TYPE.EDIT_PRIVATE ? VENUE_STATUS.PRIVATE.toUpperCase() : VENUE_STATUS.PUBLIC.toUpperCase(),
      }}}).then((res) => {
      setSubmitting(false);
      handleClose(res.data.updateVenue);
      resetForm();
      changeExecuteRefetch();
    }).catch(() => {
      setSubmitting(false);
    });
  };

  return (
    <Dialog
      components={(createLoading || editLoading ? DialogStyledPreventEvents : DialogStyledAllowedEvents) as DialogComponentsType}
      fullWidth={isMobile && true}
      open={isOpen} maxWidth='md'
      onClose={handleClose}>
      <Loader loadingPage={false} inProgress={createLoading || editLoading} />
      <DialogContent>
        <ModalHeaderContainer>
          <Typography variant={isMobile ? 'h6' : 'h5'}>{t(type === FORM_TYPE.EDIT ? 'editVenue' : 'createNewVenue')}</Typography>
          <CloseIconStyled onClick={() => handleClose()}/>
        </ModalHeaderContainer>
        <Box>
          <Formik
            initialValues={intialValues}
            validationSchema={addSchema}
            onSubmit={
              type === FORM_TYPE.CREATE || type === FORM_TYPE.CREATE_PRIVATE || type === FORM_TYPE.EDIT_PRIVATE && venue.status === VENUE_STATUS.PUBLIC.toUpperCase() ?
                handleCreate : handleEdit}>
            {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }) => {
              return (
                <Box>
                  <Form autoComplete='off'>
                    <InputGroupContainer>
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='name'
                        inputName='name'
                        isError={errors.name}
                        label={t('name')}
                        type='text' />
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='address1'
                        inputName='address1'
                        isError={errors.address1}
                        label={t('address1')}
                        type='text' />
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='address2'
                        inputName='address2'
                        isError={errors.address2}
                        label={t('address2')}
                        type='text' />
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='city'
                        inputName='city'
                        isError={errors.city}
                        label={t('city')}
                        type='text' />
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='state'
                        inputName='state'
                        isError={errors.state}
                        label={t('state')}
                        type='text' />
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='zipCode'
                        inputName='zipCode'
                        isError={errors.zipCode}
                        label={t('zipCode')}
                        type='text' />
                    </InputGroupContainer>
                    <ButtonContainer>
                      <SubmitButton
                        type='submit'
                        disableElevation
                        disabled={(Object.keys(errors).length > 0 || isSubmitting || !(isValid && dirty)) && (type === FORM_TYPE.CREATE || type === FORM_TYPE.EDIT) }>
                        {t(type === FORM_TYPE.CREATE ? FORM_TYPE.CREATE : 'save')}
                      </SubmitButton>
                    </ButtonContainer>
                    { showKeyboard &&
                    <VirtualKeyboard
                      setShowKeyboard={setShowKeyboard}
                      initialValues={values}
                      setFieldValue={setFieldValue}
                      keyboardRef={keyboardRef}
                      inputName={inputName}/>
                    }
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};