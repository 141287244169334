import { Box, Dialog, DialogContent, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MobileSearch, SelectFilter } from 'components';
import { ORDER_TYPE } from 'enums';
import { themePublic } from 'themeDefault';
import { FilterConfigProps } from 'types';

import {
  ApplyButton, CloseIconStyled, LabelTypography, FullPageContainer, ShiftedFullPageContainer,
  SearchContainer, SelectContainer, SortContainer, TitleTypography
} from './MobileFilters.styled';

type MobileFiltersProps = {
  setSearchText: (searchText: string) => void,
  handleSortBy: (sortBy: string) => void,
  handleClose: (state: boolean) => void,
  selectedSort: string | number,
  order?: ORDER_TYPE,
  changeParams?: (orderBy: string | number, order: ORDER_TYPE) => void,
  searchText: string,
  isCreateInvitationProcess?: boolean,
  sortValues: {
    sortableName: string
    title: string
  }[],
  filterConfig: FilterConfigProps[],
  isOpen: boolean,
}

export const MobileFilters = ({
  handleClose,
  selectedSort,
  order,
  changeParams,
  handleSortBy,
  setSearchText,
  searchText,
  isCreateInvitationProcess,
  sortValues,
  filterConfig,
  isOpen
}: MobileFiltersProps) => {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleClose} disablePortal fullScreen={true}>
      <DialogContent>
        <Box component={isCreateInvitationProcess ? ShiftedFullPageContainer : FullPageContainer}>
          <CloseIconStyled onClick={() => handleClose(false)}/>
          <TitleTypography>{t('filters')}</TitleTypography>
          <SearchContainer>
            <MobileSearch theme={themePublic.mobileFiltersSearchInput} setSearchText={setSearchText} searchText={searchText} showFilters={false}/>
          </SearchContainer>
          {filterConfig.map(item => {
            const { handleSelectItem } = item;
            return (
              <SelectContainer key={item.id}>
                <LabelTypography>{item.label}</LabelTypography>
                <SelectFilter
                  isDisabledTranslation={item.isDisabledTranslation}
                  multiple={item.multiple}
                  handleSelectItem={handleSelectItem}
                  selectedItems={item.selectedItems}
                  items={item.body}
                  placeholder={item.placeholder}/>
              </SelectContainer>);
          })}
          <SortContainer>
            <FormControl fullWidth>
              <LabelTypography>{t('sortBy')}</LabelTypography>
              <Select
                value={selectedSort}
                label={''}
                onChange={(e: SelectChangeEvent<string>) => {
                  handleSortBy(e.target.value);
                  changeParams && changeParams(e.target.value, order);
                }}>
                {sortValues.map(item => (
                  <MenuItem key={item.sortableName} value={item.sortableName}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </SortContainer>
          <ApplyButton variant='contained' onClick={() => handleClose(false)}>
            {t('applyFilters')}
          </ApplyButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};