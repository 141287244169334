import { CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ADD_TO_CALENDAR_IDS } from 'enums';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

const RSVPHostContainerId = `& #atcb-customTrigger-${ADD_TO_CALENDAR_IDS.RSVP_CALENDAR_BUTTON}-host`;
const GiftRegistryHostContainerId = `& #atcb-customTrigger-${ADD_TO_CALENDAR_IDS.GIFT_REGISTRY_CALENDAR_BUTTON}-host`;

const CalendaButtonHostStyle = {
  [RSVPHostContainerId]: {
    left: '0% !important',
    top: '-13rem !important',
  },
  [GiftRegistryHostContainerId]: {
    left: '0% !important',
    top: '13rem !important',
  },
};

const CalendaButtonHostStyleMobile = {
  [RSVPHostContainerId]: {
    left: '0 !important',
    top: '-12rem !important',
  },
  [GiftRegistryHostContainerId]: {
    left: '0 !important',
    top: '11rem !important',
  },
};

export const CalendarMonthIconStyled = styled(CalendarMonthIcon)(
  () => ({
    marginRight: '0.5rem',
  }),
);

const ExportButtonStyle = {
  backgroundColor: themeDefault.palette.whiteColor,
  border: `1px solid ${themeDefault.palette.mainBackground}`,
  borderRadius: '0.6rem',
  color: themeDefault.palette.mainBackground,
  height: 'auto',
  marginRight: '1rem',
  padding: '0.4rem 0.5rem',
  width: '15rem',
  '&:hover': {
    backgroundColor: themeDefault.palette.whiteColor,
    border: `1px solid ${themeDefault.palette.mainBackground}`,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
};

export const AddToCalendarButtonStyled = styled(Button)(
  ({ theme }) => ({
    ...ExportButtonStyle as CSSProperties,
    ...CalendaButtonHostStyle as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      ...CalendaButtonHostStyleMobile as CSSProperties,
      marginBottom: '0.5rem',
      marginRight: 0,
      width: '100%',
    },
  }),
);

export const ExportButtonsContainer = styled(Box)(
  ({ theme }) => ({
    ...CalendaButtonHostStyle as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      ...CalendaButtonHostStyleMobile as CSSProperties,
    },
  }),
);