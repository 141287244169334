import React, { CSSProperties, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  InvitationTemplatesHeader,
  InvitationTemplatesList,
  Loader,
  MobileFilters,
  MobilePagination,
  MobileSearch,
  TablePagination
} from 'components';
import { useAuth } from 'contexts';
import {
  INVITATION_TEMPLATE_PARAMS,
  INVITATION_TEMPLATE_STATUS,
  NUMBER_OF_INVITATION_TEMPLATES_PER_ROW,
  NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES,
  ORDER_PROPERTY,
  USER_PERMISSIONS,
  USER_SETTINGS_CONFIG
} from 'enums';
import {
  FIRST_PAGE,
  checkPermission,
  getUserSettings,
  invitationTemplateSortableFields,
  templatesPerRowCalc
} from 'helpers';
import {
  GET_COLORS,
  GET_TAGS,
  GET_TYPES,
  UPDATE_USER_SETTINGS,
  graphMutationMiddleware,
  graphQueryMiddleware
} from 'services';
import { useTemplateStore } from 'store';
import { themePublic } from 'themeDefault';
import { ColorType, FilterConfigProps, TagType, UseAuthProps } from 'types';

import { Add as AddIcon } from '@mui/icons-material';
import {
  AddNewInvitationTemplateButtonMobile, CenteredRowContainer, InvitationTemplatesPageBody,
  InvitationTemplatesPageBodyOnCreating,
  InvitationTemplatesPageContent, RowContainer, TitleContainer
} from './InvitationTemplatesPage.styled';

type InvitationTemplatesProps = {
  isCreateInvitationProcess?: boolean,
  setShowCards?: (state: boolean) => void
}

export const InvitationTemplatesPage = ({ isCreateInvitationProcess, setShowCards } : InvitationTemplatesProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const { dbUser, getUserSettingsConfig }: UseAuthProps = useAuth();

  const { params, setParams, isLoading, pageInfo, setSearchText, searchText, setSelectedTypeId } = useTemplateStore();

  const { data: tagData } = graphQueryMiddleware(GET_TAGS);
  const { data: typesData } = graphQueryMiddleware(GET_TYPES);
  const { data: colorsData } = graphQueryMiddleware(GET_COLORS);
  const [updateUserSettings] = graphMutationMiddleware(UPDATE_USER_SETTINGS);

  const [ openFilters, setOpenFilters ] = useState(false);

  let userSettings = getUserSettings(USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE, dbUser.id);

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  useEffect(() => {
    getUserSettingsConfig(dbUser.id)
      .then(() => {
        userSettings = getUserSettings(USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE, dbUser.id);
        setParams({
          ...params,
          orderBy: userSettings?.orderBy ?? ORDER_PROPERTY.TIMES_USED,
          rowsPerPage: userSettings?.pageSize ?? NUMBER_OF_INVITATION_TEMPLATES_PER_ROW.TWO,
        });
      });

    return () => {
      setSelectedTypeId(undefined);
    };
  }, []);

  const handleSetParams = (property: string, value: string | string[] | number) => {
    setParams({
      ...params,
      page: FIRST_PAGE,
      [property]: value,
    });

    if (property === INVITATION_TEMPLATE_PARAMS.SORT_BY) {
      const userSettingsJson = getUserSettings(null, dbUser.id);
      userSettingsJson[USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE].orderBy = value;
      updateUserSettings({ variables: {
        input: {
          userId: dbUser.id,
          value: JSON.stringify(userSettingsJson)
        }
      }}).then(resUpdateUserSettings => {
        const userSettingsJSON = {
          ...resUpdateUserSettings.data.updateUserSettings,
          value: JSON.parse(resUpdateUserSettings.data.updateUserSettings.value)
        };
        localStorage.setItem('userSettings', JSON.stringify(userSettingsJSON));
      });
    }
  };

  const filterConfig: FilterConfigProps[] = [
    {
      id: 0,
      label: t('selectTags'),
      body: tagData && tagData.tags.map((item: TagType) => ({ id: item.id, title: item.name})),
      multiple: true,
      selectedItems: params.selectedTags,
      handleSelectItem: (val: string[]) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_TAGS, val),
      placeholder: t('selectTags'),
      isDisabledTranslation: true
    },
    {
      id: 1,
      label: t('selectTypes'),
      body: typesData?.types.map((item: TagType) => ({ id: item.id, title: item.name})),
      multiple: true,
      selectedItems: params.selectedTypes,
      handleSelectItem: (val: string[]) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_TYPES, val),
      placeholder: t('selectTypes'),
      isDisabledTranslation: true
    },
    {
      id: 2,
      label: t('selectStatus'),
      body: (Object.keys(INVITATION_TEMPLATE_STATUS) as (keyof typeof INVITATION_TEMPLATE_STATUS)[]).map((item, index) => ({id: index, title: t(item)})),
      multiple: true,
      selectedItems: params.selectedStatus,
      handleSelectItem: (val: string[]) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_STATUS, val),
      placeholder: t('selectStatus'),
      isDisabledTranslation: false
    },
    {
      id: 3,
      label: t('selectColors'),
      body: colorsData?.colors.map((color: ColorType) => ({id: color.id, title: color.value, hexValue: color.hexValue})),
      multiple: true,
      selectedItems: params.selectedColors,
      handleSelectItem: (val: string[]) => handleSetParams(INVITATION_TEMPLATE_PARAMS.SELECTED_COLORS, val),
      placeholder: t('selectColors'),
      isDisabledTranslation: false
    },
  ];

  return (
    isMobile ?
      <>
        <Box component={isCreateInvitationProcess ? InvitationTemplatesPageBodyOnCreating : InvitationTemplatesPageBody}>
          <MobileSearch
            setSearchText={setSearchText}
            theme={(isCreateInvitationProcess ? themePublic.searchInputCreateInvitationPage : themePublic.mobileSearchContainer) as CSSProperties}
            searchText={searchText}
            handleOpenFilters={setOpenFilters}
            showFilters={true}/>
          <InvitationTemplatesPageContent style={{ marginTop: isCreateInvitationProcess && isMobile ? '-20px' : '100px' }}>
            <Box component={checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) ? RowContainer : CenteredRowContainer}>
              {!isLoading && <TitleContainer>{t('browseDesignTemplates')}</TitleContainer>}
              {checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) && !isLoading &&
            <AddNewInvitationTemplateButtonMobile elevation={3} onClick={() => handleNavigate('/invitationTemplates/create/details')}>
              <AddIcon fontSize='large' />
            </AddNewInvitationTemplateButtonMobile>}
            </Box>
            <Loader inProgress={isLoading} />
            <InvitationTemplatesList
              setShowCards={setShowCards}
              isCreateInvitationProcess={isCreateInvitationProcess}
              typesData={typesData?.types}/>
            {!isLoading &&
              <MobilePagination
                hasPreviousPage={pageInfo.hasPreviousPage}
                hasNextPage={pageInfo.hasNextPage}
                setPage={(page) => handleSetParams(INVITATION_TEMPLATE_PARAMS.PAGE, page)}
                setRowsPerPage={(rowsPerPage) => handleSetParams(INVITATION_TEMPLATE_PARAMS.ROWS_PER_PAGE, rowsPerPage)}
                page={params.page}
                rowsLength={pageInfo.totalCount}
                rowsPerPage={params.rowsPerPage * templatesPerRowCalc()}/>}
          </InvitationTemplatesPageContent>
        </Box>
        <MobileFilters
          isOpen={openFilters}
          filterConfig={filterConfig}
          searchText={searchText}
          selectedSort={params.orderBy}
          handleClose={setOpenFilters}
          sortValues={invitationTemplateSortableFields.filter((item) => item.enableSorting).map(item => ({title: t(item.title), sortableName: item.sortableName}))}
          isCreateInvitationProcess={isCreateInvitationProcess}
          handleSortBy={(sort) => handleSetParams(INVITATION_TEMPLATE_PARAMS.ORDER_BY, sort)}
          setSearchText={setSearchText}/>
      </>
      :
      <Box component={isCreateInvitationProcess ? InvitationTemplatesPageBodyOnCreating : InvitationTemplatesPageBody}>
        <InvitationTemplatesHeader handleSetParams={handleSetParams} isCreateInvitationProcess={isCreateInvitationProcess} />
        <InvitationTemplatesPageContent>
          <Loader inProgress={isLoading} />
          <InvitationTemplatesList
            setShowCards={setShowCards}
            isCreateInvitationProcess={isCreateInvitationProcess}
            typesData={typesData?.types}/>
          {!isLoading &&
            <TablePagination
              invitationToShow={NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES}
              setPage={(page) => handleSetParams(INVITATION_TEMPLATE_PARAMS.PAGE, page)}
              setRowsPerPage={(rowsPerPage) => handleSetParams(INVITATION_TEMPLATE_PARAMS.ROWS_PER_PAGE, rowsPerPage)}
              page={params.page}
              rowsLength={pageInfo.totalCount}
              rowsPerPage={params.rowsPerPage * templatesPerRowCalc()}
              tableType={USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE} />}
        </InvitationTemplatesPageContent>
      </Box>
  );
};