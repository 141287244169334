import { CSSProperties } from '@emotion/serialize';
import { Box, Button, FormControl, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themePublic } from 'themeDefault';

export const PaperStyled = styled(Paper)(
  () => ({
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem',
  }),
);

export const PaperContent = styled(Box)(
  () => ({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '0.75rem',
  }),
);

export const SelectFormControl = styled(FormControl)(
  () => ({
    ...themePublic.publicContactEntryLinksTableEditRow_SelectFormControl as CSSProperties,
  }),
);

export const TagsContainer = styled(Box)(
  () => ({
    marginBottom: '0.5rem',
    width: '100%',
  }),
);

export const ButtonsContainer = styled(Box)(
  () => ({
    ...themePublic.publicContactEntryLinksTableEditRow_ButtonsContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    margin: 'auto',
    width: 'auto',
  }),
);

export const CancelButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.cancelButton as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      height: '3rem',
      margin: 'auto',
      width: 'auto',
    },
  }),
);
