import { Box, ListItemText, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const SelectInput = styled(Select)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    paddingTop: '2px',
    paddingBottom: '2px',
    zIndex: 99999,
    [theme.breakpoints.down('sm')]: {
      zIndex: 0,
    },
  }),
);

export const ValueElement = styled('p')(
  () => ({
    color: themeDefault.palette.darkGreyColor,
    margin: 0,
    opacity: .8
  }),
);

export const ListItemContainer = styled(Box)(
  () => ({
    display: 'contents',
    justifyContent: 'space-between',
    width: '100%',
  }),
);

export const ListItemTtitle = styled(ListItemText)(
  () => ({
    '& span': {
      lineClamp: 1,
      maxWidth: '100vh',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto',
    }
  }),
);

export const MenuItemStyled = styled(MenuItem)(
  () => ({
    ...themeDefault.overflowText,
    maxWidth: '100%',
    margin: '0.2vh',
  }),
);

export const MenuItemSelected = styled(MenuItemStyled)(
  () => ({
    color: themeDefault.palette.mainColor,
    backgroundColor: `${themeDefault.palette.lightMainColor} !important`,
    '& svg': {
      fill: themeDefault.palette.mainColor,
    },
  }),
);
