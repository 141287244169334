import { gql } from '@apollo/client';

export const CHECK_PUBLIC_CONTACT_ENTRY_AUTHORISATION = gql`
mutation checkPublicContactEntryAuthorisation ($credentials: TokenCredentialsInput!) {
  checkPublicContactEntryAuthorisation(credentials: $credentials)
}
`;

export const PUBLIC_ADD_CONTACT = gql`
mutation publicAddContact (
  $credentials: TokenCredentialsInput!,
  $input: ContactInput!
) {
  publicAddContact (credentials: $credentials, input: $input) {
    id
  }
}
`;

export const PUBLIC_ADD_CONTACT_GROUP = gql`
mutation publicAddContactGroup (
  $credentials: TokenCredentialsInput!,
  $input: ContactGroupInput!
) {
  publicAddContactGroup (credentials: $credentials, input: $input) {
    id
  }
}
`;

export const CREATE_PUBLIC_CONTACT_ENTRY_AUTHORISATION = gql`
mutation createPublicContactEntryAuthorisation ($input: CreatePublicContactEntryAuthorisationInput!) {
  createPublicContactEntryAuthorisation (input: $input) {
    id
    activityStatus
    name
    token
    expirationDate
    scope
    tags {
      id
      name
    }
  }
}
`;

export const UPDATE_PUBLIC_CONTACT_ENTRY_AUTHORISATION = gql`
mutation updatePublicContactEntryAuthorisation ($input: UpdatePublicContactEntryAuthorisationInput!) {
  updatePublicContactEntryAuthorisation (input: $input) {
    id
    activityStatus
    name
    token
    expirationDate
    scope
    tags {
      id
      name
    }
  }
}
`;

export const REMOVE_PUBLIC_CONTACT_ENTRY_AUTHORISATION = gql`
mutation removePublicContactEntryAuthorisation ($id: Long!) {
  removePublicContactEntryAuthorisation (authorisationId: $id)
}
`;
