import { Box, Chip, Menu, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const UserListPageHeader = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
  }),
);

export const SearchInputHeader = styled(TextField)(
  () => ({
    ...themePublic.searchInputHeader as CSSProperties,
  }),
);

export const ChipContainer = styled(Box)(
  () => ({
    ...themePublic.chipContainer as CSSProperties,
  }),
);

export const FilterHeading = styled(Box)(
  () => ({
    ...themePublic.filterHeading as CSSProperties,
  }),
);

export const FilterItemContainer = styled(Box)(
  () => ({
    ...themePublic.filterItemContainer as CSSProperties,
  }),
);

export const FilterImage = styled('img')(
  () => ({
    ...themePublic.cursorPointer as CSSProperties,
  }),
);

export const FilterMenu = styled(Menu)(
  () => ({
    ...themePublic.filterContent as CSSProperties,
  }),
);

export const FilterSearchField = styled(TextField)(
  () => ({
    ...themePublic.filterSearch as CSSProperties,
  }),
);

export const ChipStyled = styled(Chip)(
  () => ({
    ...themePublic.chip as CSSProperties,
  }),
);
