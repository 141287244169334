import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const ItemContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    border: '2px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '16rem',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    '&:hover': {
      border: `2px solid ${themeDefault.palette.mainColor}`,
    },
    [theme.breakpoints.down('sm')]: {
      border: '1px solid rgba(0, 0, 0, 0.15)',
      flexDirection: 'row',
      height: '6rem',
      justifyContent: 'start',
      maxWidth: '100%',
      padding: '5% 5%',
      width: '100%',
    }
  }),
);

export const ImageType = styled('img')(
  ({ theme }) => ({
    height: '5rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      height: '4rem',
      marginTop: 0,
    }
  }),
);

export const ColumnContainer = styled(Box)(
  () => ({
    flexDirection: 'column',
    marginLeft: '1rem',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
);

export const DetailsTitle = styled(Typography)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    display: '-webkit-box',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '1rem',
    maxWidth: '30vh',
    textAlign: 'center',
    wordBreak: 'break-all',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
    }
  }),
);

export const DetailsDescription = styled(Typography)(
  ({ theme }) => ({
    color: themeDefault.palette.fieldLabelColor,
    display: '-webkit-box',
    fontSize: '10px',
    lineClamp: 3,
    marginBottom: '1rem',
    maxWidth: '85%',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    width: '80%',
    wordBreak: 'break-all',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('sm')]: {
      lineClamp: 2,
      maxWidth: '100%',
      textAlign: 'start',
      width: '100%',
      '-webkit-line-clamp': 2,
    }
  }),
);
