import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: window.REACT_APP_FIREBASE_API_KEY,
  authDomain: window.REACT_APP_FIREBASE_API_KEY_AUTH_DOMAIN,
  projectId: window.REACT_APP_FIREBASE_API_KEY_PROJECT_ID,
  storageBucket: window.REACT_APP_FIREBASE_API_KEY_STORAGE_BUCKET,
  messagingSenderId: window.REACT_APP_FIREBASE_API_KEY_MESSAGING_SENDER_ID,
  appId: window.REACT_APP_FIREBASE_API_KEY_APP_ID
};

// eslint-disable-next-line no-negated-condition
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const auth = firebase.auth();