import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

const checkboxContainer = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
};

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  margin: '0.5rem',
  fontSize: '20px',
  textAlign: 'center',
  width: '90%',
  whiteSpace: 'nowrap',
  zIndex: '0 !important',
};

export const CardContainer = styled(Box)(
  () => ({
    border: `1px solid ${themeDefault.palette.disabledColor}`,
    borderRadius: '10px',
  }),
);

export const SelectedCardContainer = styled(CardContainer)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      border: `1px solid ${themeDefault.palette.mainBackground}`,
    }
  }),
);

export const CardContainerHeader = styled(Box)(
  () => ({
    ...checkboxContainer as CSSProperties,
    borderRadius: '10px 10px 0 0',
    backgroundColor: themeDefault.palette.disabledColor,
    color: themeDefault.palette.blackColor,
    '& svg': {
      fill: themeDefault.palette.whiteColor
    }
  }),
);

export const CardContainerHeaderSelected = styled(CardContainerHeader)(
  () => ({
    backgroundColor: themeDefault.palette.mainBackground,
    color: themeDefault.palette.whiteColor,
  }),
);

export const CardContainerRsvp = styled(Box)(
  () => ({
    ...checkboxContainer as CSSProperties,
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    }
  }),
);

export const CardContainerRsvpDisabled = styled(Box)(
  () => ({
    ...checkboxContainer as CSSProperties,
    color: themeDefault.palette.disabledColor,
  }),
);

export const CardContainerBody = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0',
    width: '100%',
  }),
);

export const CardContainerBodyImage = styled(CardContainerBody)(
  () => ({
    cursor: 'pointer',
  }),
);

export const ImageContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    aspectRatio: '1/1',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    minWidth: '100%',
    '& img': {
      boxShadow: themeDefault.canvasBoxShadow,
    }
  }),
);

export const CardImage = styled('img')(
  () => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '22vh',
    minHeight: '100px',
    maxWidth: '90%',
    minWidth: '50%',
  }),
);
