import React from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, EviteResponseChart } from 'components';
import { useTrackStore } from 'store';
import { EviteType, InvitationProps } from 'types';

import {
  CardContainer, CardTitle, CardTitleContainer, CardTitleText, EvitesContainer, InvitationDetails,
  KeyboardArrowStyle, MoreOptionIconButton, NoCardsMessage, SwitchButton, ThemeOverlappedText,
} from './LinkedInvitationResponse.styled';

type LinkedInvitationsResponseProps = {
  linkedInvitation: InvitationProps,
  evitesConfigsDict: any,
}

export const LinkedInvitationResponse = ({
  linkedInvitation,
  evitesConfigsDict,
}: LinkedInvitationsResponseProps) => {
  const [t] = useTranslation();
  const {
    enabledInvitations,
    setEnabledInvitations,
    extendedInvitations,
    setExtendedInvitations,
    enabledInvitationsEvitesDict,
    setEnabledInvitationsEvitesDict,
  } = useTrackStore();

  const handleEnableInvitation = (invitation: InvitationProps) => {
    const enabledEvitesCopy = {...enabledInvitationsEvitesDict};
    if (enabledInvitations.includes(invitation.id)) {
      setEnabledInvitations(enabledInvitations.filter(item => item !== invitation.id));
      if (extendedInvitations.includes(invitation.id)) {
        setExtendedInvitations(extendedInvitations.filter(item => item !== invitation.id));
      }
      delete enabledEvitesCopy[invitation.id];
    } else {
      setEnabledInvitations([ ...enabledInvitations, invitation.id ]);
      if (!extendedInvitations.includes(invitation.id)) {
        setExtendedInvitations([ ...extendedInvitations, invitation.id ]);
      }
      enabledEvitesCopy[invitation.id] = invitation.evites.filter((evite: EviteType) => evite.requestRsvp);
    }
    setEnabledInvitationsEvitesDict(enabledEvitesCopy);
  };

  const handleExtendInvitation = (id: number) => {
    if (extendedInvitations.includes(id)) {
      setExtendedInvitations(extendedInvitations.filter(item => item !== id));
    } else {
      setExtendedInvitations([ ...extendedInvitations, id ]);
    }
  };

  const handleEnableEvite = (invitationId: number, evite: EviteType) => {
    const enabledEvitesCopy = {...enabledInvitationsEvitesDict};
    if (enabledInvitationsEvitesDict[invitationId] && enabledInvitationsEvitesDict[invitationId].find((e: EviteType) => e.id === evite.id)) {
      enabledEvitesCopy[invitationId] =
      enabledEvitesCopy[invitationId].filter((e: EviteType) => e.id !== evite.id);
    } else {
      enabledInvitationsEvitesDict[invitationId] ?
        enabledEvitesCopy[invitationId].push(evite) :
        enabledEvitesCopy[invitationId] = [evite];
    }
    setEnabledInvitationsEvitesDict(enabledEvitesCopy);
  };

  return (
    <>
      <CardContainer key={linkedInvitation.id}>
        <InvitationDetails>
          <CardTitleContainer>
            <CardTitleContainer>
              <CardTitle>
                <SwitchButton
                  key={linkedInvitation.id}
                  checked={enabledInvitations.includes(linkedInvitation.id)}
                  onChange={() => handleEnableInvitation(linkedInvitation)} />
                <CardTitleText>
                  <CustomTooltip theme={ThemeOverlappedText} text={linkedInvitation.name} />
                </CardTitleText>
              </CardTitle>
              <MoreOptionIconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={() => handleExtendInvitation(linkedInvitation.id)} >
                {extendedInvitations.includes(linkedInvitation.id) && <KeyboardArrowUp style={KeyboardArrowStyle}/>}
                {!extendedInvitations.includes(linkedInvitation.id) && <KeyboardArrowDown style={KeyboardArrowStyle}/>}
              </MoreOptionIconButton>
            </CardTitleContainer>
          </CardTitleContainer>
          {extendedInvitations.includes(linkedInvitation.id) &&
            <EvitesContainer>
              {!linkedInvitation.evites.find((evite: EviteType) => evite.requestRsvp) &&
                <NoCardsMessage> {t('noEvitesWithSelectedRSVP')} </NoCardsMessage>
              }
              {linkedInvitation.evites.map((evite: EviteType) => {
                return (
                  evite.requestRsvp &&
                    <EviteResponseChart
                      key={evite.id}
                      evite={evite}
                      linkedInvitation={linkedInvitation}
                      enabledInvitations={enabledInvitations}
                      enabledInvitationsEvitesDict={enabledInvitationsEvitesDict}
                      evitesConfigsDict={evitesConfigsDict}
                      handleEnableEvite={handleEnableEvite} />
                );
              })}
            </EvitesContainer>
          }
        </InvitationDetails>
      </CardContainer>
    </>
  );
};
