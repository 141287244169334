import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, TableCell, TableRow } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

const KeyboardArrowSryled = {
  cursor: 'pointer',
  fill: `${themeDefault.palette.blackColor} !important`,
  '& svg': {
    fill: `${themeDefault.palette.blackColor} !important`,
  },
};

export const TableRowStyled = styled(TableRow)(
  () => ({
    ...themePublic.groupTableRow as CSSProperties,
  }),
);

export const FirstTableCellHeader = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
  }),
);

export const FirstTableCellHeaderSelected = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const CheckboxTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxTableCellInRow as CSSProperties,
  }),
);

export const FirstTableCell = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellGroup as CSSProperties,
    display: 'flex !important',
    justifyContent: 'space-evenly',
    minWidth: '4rem',
    '& svg': {
      verticalAlign: 'middle',
    },
  }),
);

export const FirstTableCellClosed = styled(FirstTableCell)(
  () => ({
    ...themePublic.firstTableCell as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
  }),
);

export const KeyboardArrowUpStyled = styled(KeyboardArrowUp)(
  () => ({
    ...KeyboardArrowSryled,
  }),
);

export const KeyboardArrowDownStyled = styled(KeyboardArrowDown)(
  () => ({
    ...KeyboardArrowSryled,
  }),
);

export const GroupBadge = styled(Box)(
  () => ({
    ...themePublic.groupBadge as CSSProperties,
  }),
);

export const HeaderTitleTableCell = styled(TableCell)(
  () => ({
    ...themePublic.headerTitleTableCellGroup as CSSProperties,
    ...themeDefault.overflowText,
    maxWidth: '30vh',
  }),
);

export const TitleTableCell = styled(TableCell)(
  () => ({
    ...themePublic.titleTableCell as CSSProperties,
    ...themeDefault.overflowText,
    maxWidth: '30vh',
  }),
);

export const HeaderBasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.headerBasicTableCellGroup as CSSProperties,
    maxWidth: '25vh',
  }),
);

export const BasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.basicTableCell as CSSProperties,
    maxWidth: '30vh',
  }),
);

export const HeaderLastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.headerLastTableCellGroup as CSSProperties,
    maxWidth: '25vh',
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCellGroup as CSSProperties,
    maxWidth: '25vh',
  }),
);

export const OpenLastRowStartTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openFirstTableCell as CSSProperties,
    borderRadius: '0 0 0 10px',
  }),
);

export const OpenFirstTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openFirstTableCell as CSSProperties,
    minHeight: '46px',
    maxWidth: '25vh',
  }),
);

export const OpenBasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openBasicTableCell as CSSProperties,
    maxWidth: '25vh',
  }),
);

export const ImportingOpenBasicTableCell = styled(OpenBasicTableCell)(
  () => ({
    maxWidth: '20vh',
  }),
);

export const OpenLastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openLastTableCell as CSSProperties,
    maxWidth: '20vh',
  }),
);

export const OpenLastRowEndTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openLastTableCell as CSSProperties,
    borderRadius: '0 0 10px 0',
  }),
);
