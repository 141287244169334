import {
  Box, Button, Checkbox, MenuItem,
  Paper, Table, TableCell, TableContainer, TableHead, Typography
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
    lineHeight: 1.8,
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const UserPaperContainer = styled(Paper)(
  () => ({
    ...themePublic.tableBulkOptionsMenuItem as CSSProperties,
  }),
);

export const UserPaperConatinerMobile = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
  }),
);

export const UserPaperConatinerMobileSelected = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    ...themePublic.tableMobilePaperConatinerSelected as CSSProperties,
    border: '2px solid transparent',
  }),
);

export const DataInfoContainerMobile = styled(Box)(
  () => ({
    ...themePublic.tableMobileDataInfoConatiner as CSSProperties,
  }),
);

export const DataContainerMobile = styled(Box)(
  ({ theme }) => ({
    marginLeft: '1rem',
    width: '35vh',
    '& p': {
      ...themeDefault.overflowText,
      maxWidth: '30vh',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '95%'
      },
    }
  }),
);

export const BoldInfoData = styled(Typography)(
  () => ({
    ...themePublic.tableMobileDataInfoBold as CSSProperties,
  }),
);

export const StatusTableCell = styled(TableCell)(
  () => ({
    ...themePublic.tableStatusCell as CSSProperties,
  }),
);

export const ActiveText = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    lineHeight: '1.8rem !important',
    minWidth: '6vw',
  }),
);

export const InactiveText = styled(Typography)(
  () => ({
    color: themeDefault.palette.greyColor,
    lineHeight: '1.8rem !important',
    minWidth: '6vw',
  }),
);

export const BadgeNumber = styled('span')(
  () => ({
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '50%',
    color: themeDefault.palette.whiteColor,
    marginLeft: '8px',
    padding: '3px 8px 3px 5px',
    width: 'fit-content',
  }),
);

export const ActionMenuContainer = styled(Box)(
  () => ({
    ...themePublic.tableActionMenuContainer as CSSProperties,
  }),
);

export const CheckBoxTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxTableCell as CSSProperties,
  }),
);

export const FirstTableCell = styled(TableCell)(
  ({ theme }) => ({
    ...themePublic.firstTableCell as CSSProperties,
    minWidth: '6vw',
    [theme.breakpoints.between('sm', 860)]: {
      minWidth: '12vw',
    },
    [theme.breakpoints.between(860, 1050)]: {
      minWidth: '11vw',
    },
    [theme.breakpoints.between(1050, 1350)]: {
      minWidth: '9vw',
    },
    [theme.breakpoints.between(1350, 1455)]: {
      minWidth: '7.125vw',
    },
    [theme.breakpoints.between(1455, 1700)]: {
      minWidth: '5vw',
    },
  }),
);

export const BasicTableCell = styled(TableCell)(
  ({ theme }) => ({
    ...themePublic.basicTableCell as CSSProperties,
    minWidth: '6vw',
    maxWidth: '11vw',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%'
    },
    [theme.breakpoints.between('sm', 860)]: {
      maxWidth: '12vw',
    },
    [theme.breakpoints.between(860, 1050)]: {
      maxWidth: '11vw',
    },
    [theme.breakpoints.between(1050, 1700)]: {
      maxWidth: '9vw',
    },
  }),
);

export const OptionTableCell = styled(TableCell)(
  () => ({
    ...themePublic.optionTableCell as CSSProperties,
    maxWidth: '3vw',
  }),
);
