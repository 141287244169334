import { gql } from '@apollo/client';

export const SEND_GUEST_UPDATED_SUBSCRIPTION = gql`
subscription OnGuestUpdated {
  guestUpdated {
    id
    lastSent
    contact {
        id
        firstName
        lastName
        title
        email
        phoneNumber
        address
        city
        street
        state
        zipCode
        contacts {
            id
            firstName
            lastName
            title
            email
            phoneNumber
            address
            city
            street
            state
            zipCode
        }
        tags {
            id
            name
        }
    }
    sender {
        id
        title
        firstName
        lastName
    }
    invitation {
        id
        name      
    }
    evites {
        id
        name
        isSelected
    }
    responses {
        id
        contact {
          firstName
          lastName
          contacts {
              id
          }
          id
        }
        eviteResponse {
          status
          isGroup
          evite {
            name
            id
          }
        }
    }
    personalMessage
    personalGreetingMessage
    isInvitationSent
  }
}`;

export const CUMULATIVE_RESPONSE_UPDATED_SUBSCRIPTION = gql`
subscription onCumulativeResponseUpdated {
  cumulativeResponseUpdated {
    eviteId
    numberOfNotSent
    numberOfAttending
    numberOfNotAttending
    numberOfPending
    numberOfNotAnswered
    numberOfMazelTowOnly
  }
}`;

