import { gql } from '@apollo/client';

export const ADD_GUESTS = gql`
mutation addGuest ($input: AddGuestInput) {
  addGuest (input: $input) {
    contact {
        id
        firstName
        lastName
        title
        email
        phoneNumber
        address
        city
        street
        state
        zipCode
        contacts {
            id
            firstName
            lastName
            title
            email
            phoneNumber
            tags {
                id
                name
                type
            }
        }
        tags {
            id
            name
            type
        }
    }
    sender {
        title
        firstName
        lastName
    }
    evites {
        id
    }
    personalMessage
    personalGreetingMessage
    isInvitationSent
  }
}
`;

export const IMPORT_CONTACTS = gql`
mutation importGuests ($contactIds: [Long!], $invitationId: Long!) {
    importGuests (contactIds: $contactIds, invitationId: $invitationId) {
        contact {
            id
            firstName
            lastName
            title
            email
            phoneNumber
            address
            city
            street
            state
            zipCode
            contacts {
                id
                firstName
                lastName
                title
                email
                phoneNumber
                tags {
                    id
                    name
                    type
                }
            }
            tags {
                id
                name
                type
            }
        }
        sender {
            title
            firstName
            lastName
        }
        evites {
            id
        }
        personalMessage
        personalGreetingMessage
        isInvitationSent
    }
}
`;
export const UPDATE_GUEST = gql`
mutation updateGuest ($input: UpdateGuestInput) {
    updateGuest (input: $input) {
        contact {
            id
            firstName
            lastName
            title
            email
            phoneNumber
            address
            city
            street
            state
            zipCode
            contacts {
                id
                firstName
                lastName
                title
                email
                phoneNumber
                tags {
                    id
                    name
                    type
                }
            }
            tags {
                id
                name
                type
            }
        }
        sender {
            title
            firstName
            lastName
        }
        evites {
            id
        }
        personalMessage
        personalGreetingMessage
        isInvitationSent
    }
}
`;

export const REMOVE_GUEST = gql`
mutation removeGuest ($guestId: Long!) {
    removeGuest (guestId: $guestId)
}
`;

export const BULK_REMOVE_GUEST = gql`
mutation bulkRemoveGuests ($guestsIds: [Long!]) {
    bulkRemoveGuests (guestsIds: $guestsIds)
}
`;

export const SEND_INVITATIONS = gql`
mutation SendInvitations ($input: InvitationSendInput){
    sendInvitations(input: $input)
}`;

export const IMPORT_CSV_GUESTS = gql`
  mutation ImportCsvGuests ($file: Upload!, $invitationId: Long!){
    importCsvGuests(file: $file, invitationId: $invitationId){
      successfullyAdded {
        firstName
        lastName
        email
      }
      skipped {
        firstName
        lastName
        email
      }
      failed {
        error
        row
      }
    }
  }
`;

export const UPDATE_GUESTS = gql`
mutation updateGuests ($input: UpdateGuestsInput) {
    updateGuests (input: $input) {
        id
        contact {
            id
            firstName
            lastName
            phoneNumber
        }
        evites {
            id
            name
            position
        }
        personalMessage
        personalGreetingMessage
        sender {
            title
            firstName
            lastName
        }
    }
}
`;

export const SEND_RSVP_RESPONSE = gql`
mutation sendResponse ($token: String, $input: [GuestResponseInput], $personalMessage: String) {
    sendResponse (token: $token, input: $input, personalMessage: $personalMessage)
}
`;

export const ADD_GUESTS_GROUP = gql`
mutation addGuestGroup ($input: AddGuestGroupInput) {
  addGuestGroup (input: $input) {
    id
  }
}
`;

export const UPDATE_GUESTS_GROUP = gql`
mutation updateGuestGroup ($input: UpdateGuestGroupInput) {
  updateGuestGroup (input: $input) {
    id
  }
}
`;

export const CHANGE_GUESTS_RESPONSE = gql`
mutation changeUserResponse ($guestId: Long!, $input: [GuestResponseInput]) {
    changeGuestsResponse (guestId: $guestId, input: $input)
}
`;
