import React, { useRef, useState } from 'react';
import { Box, Input, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DefaultButton, ImportCsvContactsReport, Loader } from 'components';
import { useInvitation } from 'contexts';
import { showToast } from 'helpers';
import { downloadTemplateIcon } from 'resources';
import { IMPORT_CSV_GUESTS, UPLOAD_CONTACTS_CSV, graphMutationMiddleware } from 'services';
import { useContactStore, useGuestStore } from 'store';
import { themePublic } from 'themeDefault';
import { InvitationContextType } from 'types';

import {
  BrowseButton, ButtonsContainer, DownloadButton, DownloadIcon,
  ImportingOnlyContactsPageBody, ImportingOnlyContactsPageContent,
  PageBody, PageContent, ResponseContainer, UploadFilersContainer
} from './MobileImportContactsCsvModal.styled';

type ImportContactsCsv = {
  handleClose: () => void,
  importingOnlyContacts?: boolean,
}

export const MobileImportContactsCsvModal = ({handleClose, importingOnlyContacts=false}: ImportContactsCsv) => {
  const [t] = useTranslation();
  const [ files, setFiles ] = useState<File[]>([]);
  const [ uploadCsv, { data: uploadedData, loading: uploading }] = graphMutationMiddleware(importingOnlyContacts ? UPLOAD_CONTACTS_CSV : IMPORT_CSV_GUESTS);
  const inputRef = useRef(null);
  const { invitationDetails }: InvitationContextType = useInvitation();
  const { changeExecuteRefetch: contactRefetch } = useContactStore();
  const { changeExecuteRefetch: guestRefetch } = useGuestStore();

  const downloadCsvTemplate = () => {
    const link = document.createElement('a');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    link.href = `${window.UREVITED_APP_CORE_URL}/api/v1/file/contactcsv`;
    link.click();
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const variables = importingOnlyContacts ?
        { file: e.target.files[0] } :
        {
          file: e.target.files[0],
          invitationId: invitationDetails.id
        };
      setFiles(e.target.files);
      uploadCsv({variables: variables })
        .then(() => {
          contactRefetch();
          guestRefetch();
          showToast('info', t('importCsvFinished'));
        });
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box component={importingOnlyContacts ? PageBody : ImportingOnlyContactsPageBody}>
      <Loader loadingPage={false} inProgress={uploading} />
      <Box component={importingOnlyContacts ? PageContent : ImportingOnlyContactsPageContent}>
        <Input
          inputRef={inputRef}
          type='file'
          inputProps={{ accept: '.csv', multiple: false}}
          onChange={handleChange}
          sx={{display: 'none'}}/>
        {
          files &&
          <UploadFilersContainer> {
            Array.from(files).map((file: File, index: number) =>
              <Typography key={index}>{file.name}</Typography>
            )}
          </UploadFilersContainer>
        }
        <ButtonsContainer>
          <BrowseButton type='button' variant='contained' onClick={onButtonClick}>{t('importFile')}</BrowseButton>
          <DownloadButton type='button' variant='outlined' onClick={downloadCsvTemplate}>
            <DownloadIcon src={downloadTemplateIcon}/>
            {t('downloadCsvTemplate')}
          </DownloadButton>
          {uploadedData &&
            <ResponseContainer>
              <ImportCsvContactsReport uploadedData={importingOnlyContacts ? uploadedData?.uploadCsv : uploadedData?.importCsvGuests} />
            </ResponseContainer>}
          <DefaultButton theme={themePublic.contactImportModalGoBackButton} title={t('goBack')} handleFunction={handleClose} />
        </ButtonsContainer>
      </Box>
    </Box>
  );
};
