import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components';
import { InvitationDetailsFormValuesType } from 'types';

import { CloseIconStyled, UrlContainer } from './GiftUrl.styled';

type GiftUrlProps = {
    setInputName?: (fieldName: string) => void,
    setShowKeyboard?: (show: boolean) => void,
    showKeyboard: boolean,
    values : InvitationDetailsFormValuesType,
    arrayGift: number[],
    handleGiftUrlChange : (values: InvitationDetailsFormValuesType) => void,
    removeGiftUrl: (giftIndex: number, values: InvitationDetailsFormValuesType) => void
  };

export const GiftUrl = ({
  removeGiftUrl,
  arrayGift,
  setInputName = null,
  setShowKeyboard = null,
  values,
  handleGiftUrlChange
} : GiftUrlProps) => {
  const [t] = useTranslation();

  useEffect(() => {
    handleGiftUrlChange(values);
  }, [values['giftUrl']]);

  return (
    <>
      {arrayGift.map((el: number, index : number) =>
        <UrlContainer key={el}>
          <InputField
            errorAbsolutePosition={true}
            boxStyles={{marginTop: '0 !important'}}
            setShowKeyboard={setShowKeyboard}
            setInputName={setInputName}
            inputId={`giftUrl[${index}]`}
            inputName={`giftUrl[${index}]`}
            label={t('giftRegistry')}
            type='text'
            value={values['giftUrl'][index]}/>
          <CloseIconStyled onClick={() => removeGiftUrl(el, values)}/>
        </UrlContainer>
      )}
    </>
  );
};