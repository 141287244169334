import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, EviteSelector, VirtualKeyboard } from 'components';
import { padlockLocked, padlockUnlocked } from 'resources';
import { useGuestStore } from 'store';
import { ActiveItemsType, EviteType, SendersProps } from 'types';

import {
  EditIcon, ErrorMessage, IconContainer, InputRow, InputRowVertical, LabelDisabled, LabelSelected,
  PersonalMessageLabelContainer, ThemeOverlappedText
} from './EventDetailsForm.styled';

type EventDetailsFormProps = {
  isCreating?: boolean
  personalGreetingMessage?: string
  selectedEvites: EviteType[]
  selectedSenders: number,
  setPersonalGreetingMessage?: (text: string) => void
  setSelectedSenders: (id: number) => void
  setSelectedEvites: (input: EviteType[]) => void
  showKeyboardParent?: boolean
  setShowKeyboardParent?: (show: boolean) => void
  activeItems?: ActiveItemsType,
  setActiveItems?: (item: ActiveItemsType) => void
  personalGreetingMessageError?: boolean
  eviteData: {
    evites: EviteType[]
  }
};

export const EventDetailsForm = ({
  activeItems,
  eviteData,
  isCreating=true,
  personalGreetingMessage,
  selectedEvites,
  selectedSenders,
  setActiveItems,
  setPersonalGreetingMessage,
  setSelectedEvites,
  setSelectedSenders,
  setShowKeyboardParent = null,
  showKeyboardParent = false,
  personalGreetingMessageError = false,
}: EventDetailsFormProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);
  const { invitation } = useGuestStore();
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  useEffect((() => {
    showKeyboardParent && setShowKeyboard(false);
  }), [showKeyboardParent]);

  return (
    <>
      <InputRow>
        <PersonalMessageLabelContainer>
          {!isCreating &&
          <IconContainer>
            <Tooltip arrow placement='top' title={t(activeItems.sender ? 'clickToLock' : 'clickToUnlock')}>
              <EditIcon src={activeItems.sender ? padlockUnlocked : padlockLocked}
                onClick={() => setActiveItems({...activeItems, sender: !activeItems.sender})}/>
            </Tooltip>
          </IconContainer>
          }
          <Typography component={!isCreating && !activeItems.sender? LabelDisabled : LabelSelected}> {t('selectSender')}: </Typography>
        </PersonalMessageLabelContainer>
        <FormControl sx={{ width: '100%' }}>
          <Select
            sx={{ marginTop: isMobile ? '0.5rem' : 0 }}
            disabled={!isCreating && !activeItems.sender }
            size='small'
            value={selectedSenders}
            placeholder='Thomas Smith'
            onChange={(e: SelectChangeEvent<number>) => setSelectedSenders(Number(e.target.value))}>
            {invitation && invitation.senders.length > 0 ?
              invitation.senders.map((sender: SendersProps) =>
                <MenuItem sx={{w: '100%'}} value={sender.id} key={sender.id}>
                  <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={`${sender.firstName} ${sender.lastName}`} />
                </MenuItem>):
              <MenuItem sx={{w: '100%'}} disabled>{t('noSenders')}</MenuItem>
            }
          </Select>
        </FormControl>
      </InputRow>
      <InputRowVertical>
        <PersonalMessageLabelContainer>
          {!isCreating &&
            <IconContainer>
              <Tooltip arrow placement='top' title={t(activeItems.personalGreetingMessage ? 'clickToLock' : 'clickToUnlock')}>
                <EditIcon src={activeItems.personalGreetingMessage ? padlockUnlocked : padlockLocked}
                  onClick={() => setActiveItems({...activeItems, personalGreetingMessage: !activeItems.personalGreetingMessage})}/>
              </Tooltip>
            </IconContainer>
          }
          <Typography component={!isCreating && !activeItems.personalGreetingMessage ? LabelDisabled : Typography}>
            {t('personalGreetingMessage')}
          </Typography>
        </PersonalMessageLabelContainer>
        <TextField
          style={{marginTop: '0.5rem'}}
          onFocus={() => {
            setShowKeyboard(true);
            setShowKeyboardParent && setShowKeyboardParent(false);
          }}
          type='text'
          disabled={!isCreating && !activeItems.personalGreetingMessage}
          fullWidth
          multiline
          value={personalGreetingMessage}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPersonalGreetingMessage(e.target.value)}
          rows={4}/>
        {personalGreetingMessageError &&
          <ErrorMessage>{t('personalGreetingMessageError')}</ErrorMessage>
        }
      </InputRowVertical>
      <InputRow>
        <PersonalMessageLabelContainer>
          {!isCreating &&
          <IconContainer>
            <Tooltip arrow placement='top' title={t(activeItems.evites ? 'clickToLock' : 'clickToUnlock')}>
              <EditIcon src={activeItems.evites ? padlockUnlocked : padlockLocked}
                onClick={() => setActiveItems({...activeItems, evites: !activeItems.evites})}/>
            </Tooltip>
          </IconContainer>
          }
          <Typography component={!isCreating && !activeItems.evites ? LabelDisabled : LabelSelected}>{t('selectEvitesText')}: </Typography>
        </PersonalMessageLabelContainer>
        <EviteSelector
          activeItems={activeItems}
          defaultShowEvites={false}
          isCreating={isCreating}
          eviteData={eviteData ?? { evites: [{ id: 0, name: '' } as EviteType] }}
          setSelectedEvites={setSelectedEvites}
          selectedEvites={selectedEvites}
          showKeyboardParent={showKeyboard}
          setShowKeyboardParent={setShowKeyboard} />
        { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          initialValues={{
            'message': personalGreetingMessage
          }}
          setFieldValue={null}
          setStandaloneItem={setPersonalGreetingMessage}
          keyboardRef={keyboardRef}
          inputName={'message'}/>
        }
      </InputRow>
    </>
  );
};