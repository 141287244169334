import {
  Close as CloseIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  maxWidth: '100%',
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderLeft: `1px solid ${themeDefault.palette.mainBackground}`,
    height: '100vh',
    left: '100%',
    margin: 0,
    position: 'absolute',
    top: 0,
    transition: 'all 0.7s ease-out',
    width: '35%',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  }),
);

export const ShowContentContainerStyle = {
  left: '65%',
  overflowY: 'auto',
  transition: 'all 0.7s ease-out',
  zIndex: 200,
  [themeLocal.breakpoints.down('sm')]: {
    left: '25%',
  },
};

export const CloseIconStyled = styled(CloseIcon)(
  ({ theme }) => ({
    cursor: 'pointer',
    margin: '2% 0 0 2%',
    opacity: 0.7,
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px',
    },
  }),
);

export const LabelName = styled(Box)(
  ({ theme }) => ({
    color: themeDefault.palette.mainColor,
    fontWeight: 'bold',
    marginBottom: '40px',
    marginTop: '40px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      marginBottom: '20px'
    }
  }),
);

export const TabWrapper = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '10px',
    },
  }),
);

export const KeyWrapper = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  }),
);

export const KeyName = styled(Box)(
  ({ theme }) => ({
    ...themeDefault.overflowText as CSSProperties,
    fontWeight: 'bold',
    marginLeft: '40px',
    marginTop: '10px',
    maxWidth: '80%',
    opacity: 0.5,
    [theme.breakpoints.up('sm')]: {
      fontWeight: 'bold',
      marginLeft: '0',
      marginTop: '0',
      opacity: 0.5,
      textAlign: 'right',
    },
  }),
);

export const ValueWrapper = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  }),
);

export const PersonalGreetingMessage = styled(Typography)(
  () => ({
    wordWrap: 'break-word',
    paddingRight: '20px',
    marginLeft: '40px',
    textAlign: 'left',
  }),
);

export const ValueTypographyContainer = styled(Typography)(
  () => ({
    ...themeDefault.overflowText,
    maxWidth: '80%',
    marginLeft: '40px',
    textAlign: 'left',
  }),
);

export const LinkWrapper = styled(Box)(
  () => ({
    width: '80%',
  }),
);

export const LinkText = styled(Box)(
  () => ({
    color: 'blue',
    cursor: 'pointer',
    marginLeft: '40px',
    textDecoration: 'underline',
  }),
);

export const CopyIconWrapper = styled(Box)(
  () => ({
    width: '20%',
  }),
);

export const CopyIcon = styled(ContentCopyIcon)(
  () => ({
    cursor: 'pointer',
  }),
);
