import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, CircularProgress, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ContactsForm, FormHeader, InputField, Loader, VirtualKeyboard } from 'components';
import { FORM_TYPE, VALIDATION_RESTRICTION } from 'enums';
import { contactGroupInitialValues, contactInitialValues, phoneNumberRegExp } from 'helpers';
import {
  CHECK_PUBLIC_CONTACT_ENTRY_AUTHORISATION,
  PUBLIC_ADD_CONTACT,
  PUBLIC_ADD_CONTACT_GROUP,
  graphMutationMiddleware,
} from 'services';
import { ContactFormValuesType, ContactType } from 'types';

import { BoxContainer, ButtonContainer, FormContainer, GroupNameDivider, MinHeight, PublicContactEntryContainer, PublicContactEntryPageContainer, SubmissionErrorContainer, SubmitButton } from './PublicContactEntryPage.styled';

export const PublicContactEntryPage = () => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();

  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const keyboardRef = useRef(null);

  const authorisationToken = searchParams.get('token');
  const [ validateToken, tokenValidationResult ] = graphMutationMiddleware(CHECK_PUBLIC_CONTACT_ENTRY_AUTHORISATION);
  const isTokenValid = !!tokenValidationResult.data?.checkPublicContactEntryAuthorisation;

  useEffect(() => {
    if (authorisationToken) {
      validateToken({
        variables: {
          credentials: {
            token: authorisationToken,
          },
        },
      });
    }
  }, [authorisationToken]);

  const [ publicAddContact, publicAddContactResult ] = graphMutationMiddleware(PUBLIC_ADD_CONTACT);
  const [ publicAddContactGroup, publicAddContactGroupResult ] = graphMutationMiddleware(PUBLIC_ADD_CONTACT_GROUP);

  const submissionResultAlternatives = [ publicAddContactResult, publicAddContactGroupResult ];
  const submissionResult = {
    called: submissionResultAlternatives.some((x) => x.called),
    error: submissionResultAlternatives.find((x) => x.error)?.error,
    loading: submissionResultAlternatives.some((x) => x.loading),
    reset: () => submissionResultAlternatives.forEach((x) => x.reset()),
  };

  const contactsSchema = useMemo(() => Yup.object().shape({
    groupName: Yup.string()
      .when('firstName', {
        is: (arr: string[]) => arr.length > 1,
        then: (schema) => schema
          .required(t('required'))
          .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('groupName'), length: VALIDATION_RESTRICTION.HUNDRED })),
        otherwise: (schema) => schema.nullable(),
      }),
    firstName: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    lastName: Yup.array().of(Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    email: Yup.array().of(Yup.string()
      .email(t('invalidEmailFormat'))
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))),
    phoneNumber: Yup.array().of(Yup.string()
      .matches(phoneNumberRegExp, t('invalidPhoneNumberFormat'))
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('phoneNumber'), length: VALIDATION_RESTRICTION.FIFTY }))),
    street: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('street'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    city: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('city'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    zipCode: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('zipCode'), length: VALIDATION_RESTRICTION.FIFTY }))),
    state: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('state'), length: VALIDATION_RESTRICTION.HUNDRED }))),
  }), []);

  const contactPropNames = useMemo(() => Object.keys(contactInitialValues) as (keyof ContactFormValuesType)[], []);

  const getContactAtIndex = useCallback((values: ContactFormValuesType, index: number): ContactType => {
    const contact: Record<string, unknown> = {};
    contactPropNames.forEach((prop) => {
      contact[prop as keyof ContactType] = values[prop][index];
    });
    return contact as ContactType;
  }, [contactPropNames]);

  const spliceContacts = useCallback((
    values: ContactFormValuesType,
    start: number,
    deleteCount: number,
    ...newContacts: ContactType[]
  ): ContactFormValuesType => {
    const newValues: Record<string, unknown> = { ...values };
    contactPropNames.forEach((prop) => {
      newValues[prop] = [...(newValues[prop] as unknown[])];
      (newValues[prop] as unknown[]).splice(start, deleteCount, ...newContacts.map((x) => x[prop as keyof ContactType]));
    });
    return newValues as ContactFormValuesType;
  }, [contactPropNames]);

  const onSubmit = useCallback((values: ContactFormValuesType, helpers: FormikHelpers<ContactFormValuesType>) => {
    const { setSubmitting } = helpers;
    submissionResult.reset();

    const convertToInput = (contact: ContactType) => ({
      titleValue: contact.titleValue,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phoneNumber: contact.phoneNumber,
      street: contact.street,
      city: contact.street,
      zipCode: contact.zipCode,
      state: contact.state,
    });

    const count = values.id.length;
    let promise = Promise.resolve() as Promise<unknown>;

    if (count > 1) {
      promise = publicAddContactGroup({
        variables: {
          credentials: { token: authorisationToken },
          input: {
            groupName: values.groupName,
            contacts: Array.from({ length: count }, (_, index) => convertToInput(getContactAtIndex(values, index))),
          },
        },
      });
    } else {
      promise = publicAddContact({
        variables: {
          credentials: { token: authorisationToken },
          input: convertToInput(getContactAtIndex(values, 0)),
        },
      });
    }

    promise.finally(() => setSubmitting(false));
  }, [ publicAddContact, publicAddContactGroup, getContactAtIndex ]);

  const Wrapper = useCallback(({ children }) => (
    <PublicContactEntryPageContainer>
      <MinHeight
        container
        direction='column'
        alignItems='center'
        justifyContent='center'>
        <Grid item xs={3}>
          <PublicContactEntryContainer variant='outlined' sx={{ p: { xs: 2, md: 6 }, borderRadius: 5 }}>
            <BoxContainer>
              { children }
            </BoxContainer>
          </PublicContactEntryContainer>
        </Grid>
      </MinHeight>
    </PublicContactEntryPageContainer>
  ), []);

  if (authorisationToken && (tokenValidationResult.loading || !tokenValidationResult.called)) {
    return (
      <Wrapper>
        <Loader inProgress={true} />
      </Wrapper>
    );
  }

  if (tokenValidationResult.error || !authorisationToken || !isTokenValid) {
    return (
      <Wrapper>
        <FormHeader label={null} />
        <FormContainer>
          <Alert severity='error'>{ t('gatheringContactDetailsUnauthorized') }</Alert>
        </FormContainer>
      </Wrapper>
    );
  }

  if (submissionResult.called && !submissionResult.loading && !submissionResult.error) {
    return (
      <Wrapper>
        <FormHeader label={null} />
        <FormContainer>
          <Alert severity='success'>{ t('gatheringContactDetailsSuccess') }</Alert>
        </FormContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormHeader label={t('gatheringContactDetails')} />
      <Formik
        initialValues={contactGroupInitialValues as ContactFormValuesType}
        validationSchema={contactsSchema}
        onSubmit={onSubmit}>
        {({ values, dirty, isSubmitting, setFieldValue, setValues, submitForm: submitFormAsync }) => {
          const count = values.id.length;

          const removeMember = (index: number) => {
            setValues((prev) => spliceContacts(prev, index, 1));
          };

          const addNewMember = () => {
            setValues((prev) => spliceContacts(prev, prev.id.length, 0, contactInitialValues));
          };

          const submitForm = () => submitFormAsync().catch(() => { /* swallow ... */ });

          return (
            <FormContainer>
              <Form>
                {count > 1 &&
                  <GroupNameDivider>
                    <Typography>{t('groupName')}</Typography>
                    <InputField
                      setShowKeyboard={setShowKeyboard}
                      setInputName={setInputName}
                      inputId='groupName'
                      inputName='groupName'
                      isError={null}
                      label=''
                      type='text' />
                  </GroupNameDivider>
                }
                {Array.from({ length: count }, (_, index) =>
                  <ContactsForm
                    key={index}
                    contact={getContactAtIndex(values, index)}
                    showAddNewText={count - 1 === index}
                    type={FORM_TYPE.CREATE}
                    removeMember={() => removeMember(index)}
                    handleAddNew={() => addNewMember()}
                    index={index}
                    setInputName={setInputName}
                    setShowKeyboard={setShowKeyboard}/>)
                }
                { showKeyboard &&
                  <VirtualKeyboard
                    setShowKeyboard={setShowKeyboard}
                    initialValues={{ ...values }}
                    setFieldValue={setFieldValue}
                    keyboardRef={keyboardRef}
                    inputName={inputName}/>
                }
                { submissionResult.error &&
                  <SubmissionErrorContainer>
                    <Alert severity='error'>{ t('gatheringContactDetailsFailure') }</Alert>
                  </SubmissionErrorContainer>
                }
                <ButtonContainer>
                  <SubmitButton
                    onClick={submitForm}
                    disableElevation
                    startIcon={isSubmitting && <CircularProgress size='1rem' color='inherit' />}
                    disabled={isSubmitting || !dirty}>
                    {t('send')}
                  </SubmitButton>
                </ButtonContainer>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
