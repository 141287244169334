import { MenuList, Paper, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const OptionsIcon = styled('img')(
  ({ theme }) => ({
    cursor: 'pointer',
    height: '100%',
    margin: '0 auto',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      width: '28px',
    },
  }),
);

export const OptionsMenuPopper = styled(Popper)(
  ({ theme }) => ({
    zIndex: 2000,
    '& .MuiPaper-root': {
      margin: '0 !important',
      marginTop: '-28px !important',
      boxShadow: 'none',
      background: 'transparent',
      display: 'flex',
    },
    '& ul': {
      alignItems: 'center',
      backgroundColor: themeDefault.palette.whiteColor,
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-end',
      },
      '& li': {
        backgroundColor: `${themeDefault.palette.whiteColor} !important`,
        gap: '4px',
        padding: '4px 8px',
        width: '100%',
        '& svg': {
          marginRight: '8px',
        },
        '&:hover': {
          color: themeDefault.palette.mainColor,
          backgroundColor: `${themeDefault.palette.lightMainColor} !important`,
        }
      },
    }
  }),
);

export const OptionsMenuPaper = styled(Paper)(
  () => ({
    flexDirection: 'column',
    marginTop: '-28px',
    '& ul': {
      flexDirection: 'column',
      padding: '16px 0 0 0',
      transform: 'translate(0px, -14px)',
    }
  }),
);

export const MenuListStyled = styled(MenuList)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    border: `1px solid ${themeDefault.palette.borderColor}`,
    borderRadius: '8px',
    color: themeDefault.palette.darkGreyColor,
    zIndex: 0,
  }),
);

export const OptionsActiveIcon = styled('img')(
  ({ theme }) => ({
    cursor: 'pointer',
    height: '100%',
    margin: '0 auto',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      ...themePublic.actionMenuIconStyle as CSSProperties,
    },
  }),
);
