import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const ModalBody = styled(Box)(
  ({ theme }) => ({
    maxHeight: '500px',
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      display: 'contents',
      width: '100%',
    }
  }),
);

export const ModaHeader = styled(Box)(
  () => ({
    ...themePublic.modalHeaderContainer as CSSProperties,
  }),
);

export const ModaHeading = styled(Typography)(
  () => ({
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  ({ theme }) => ({
    ...themePublic.closeIcon as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      position: 'absolute',
      top: '2rem',
      right: '2rem',
    },
  }),
);

export const PersonIconStyled = styled(PersonIcon)(
  () => ({
    fill: themeDefault.palette.mainColor,
    fontSize: '100px',
    margin: '0 auto',
    textAlign: 'center',
  }),
);

export const GroupNameContainer = styled(Box)(
  () => ({
    marginTop: '1rem',
    marginBottom: '2rem',
  }),
);

export const AddNewTagContainer = styled(Box)(
  () => ({
    ...themePublic.addNewTextButtonContainer as CSSProperties,
  }),
);

export const AddNewTagText = styled(Typography)(
  () => ({
    ...themePublic.addNewTextButtonTypography as CSSProperties,
  }),
);

export const TagsContainer = styled(Box)(
  () => ({
    ...themeDefault.overflowText,
    marginBottom: '0.5rem',
    maxWidth: '200vh',
    width: '100%',
  }),
);

export const ButtonsContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '1rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: '1rem',
      width: '100%',
    }
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.submitButton as CSSProperties,
    margin: 0,
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      marginBottom: '1rem',
      width: '100%',
    }
  }),
);
