import React, { useState } from 'react';
import { Button } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';

import { DragDropFile, ImageSlider, Loader } from 'components';
import { GET_IMAGES, GET_STAMPS, UPLOAD_FILE, UPLOAD_GLOBAL_FILE, graphMutationMiddleware, graphQueryMiddleware } from 'services';
import { themeDefault } from 'themeDefault';

import { CommentTextModal, ImageChangerContainer, SupportedFormatsText, UploaderContainer } from './SetBackgroundImageModal.styled';

type SetBackgroundImageModalCsv = {
  handleClose: () => void
  handleUpload: (fileUrl: string) => void
  isBackgroundColor: boolean
  handleSetBackgroundColor?: (color: string) => any,
  imageLoading: boolean
  fileType?: 'IMAGE' | 'STAMP',
  isTemplate?: boolean
}

export const SetBackgroundImageModal = ({handleClose, handleUpload, isBackgroundColor, handleSetBackgroundColor, imageLoading, fileType='IMAGE', isTemplate=false}: SetBackgroundImageModalCsv) => {
  const [t] = useTranslation();

  const [ color, setColor ] = useState(themeDefault.palette.whiteColor);
  const { data, loading, refetch } = graphQueryMiddleware(fileType === 'IMAGE' ? GET_IMAGES : GET_STAMPS);
  const [ uploadFile, {loading: uploadLoading}] = graphMutationMiddleware(isTemplate ? UPLOAD_GLOBAL_FILE : UPLOAD_FILE);

  const uploadBackground = (uploadedFiles: File[]) => {
    uploadFile({variables: {
      file: uploadedFiles[0],
      type: fileType
    }}).then(res => {
      handleClose();
      handleUpload(res.data[isTemplate ? 'uploadGlobalFile': 'uploadFile'].url);
      refetch();
    });
  };

  const uploadBackgroundColor = () => {
    handleClose();
    handleSetBackgroundColor && handleSetBackgroundColor(color);
  };

  const uploadAndClose = (url: string) => {
    handleUpload(url);
    handleClose();
  };

  return (
    <>
      <Loader loadingPage={false} inProgress={uploadLoading || imageLoading} />
      {isBackgroundColor ?
        <ImageChangerContainer>
          <CommentTextModal>{t('setBackgroundColor')}</CommentTextModal>
          <HexColorPicker color={color} onChange={(newColor) => setColor(newColor)} />
          <Button onClick={uploadBackgroundColor}>{t('confirm')}</Button>
        </ImageChangerContainer> :
        <UploaderContainer>
          <CommentTextModal>{t('uploadImage')}</CommentTextModal>
          {data && <ImageSlider loading={loading} images={fileType === 'IMAGE' ? data.images : data.stamps} getUploadedImageUrl={uploadAndClose}/>}
          <DragDropFile
            files={null}
            setFiles={uploadBackground}
            buttonText={t('browseFile')}
            title={t('dragAndDropHere')}
            multiple={false}
            accept='image/png, image/jpeg, image/gif'/>
          <SupportedFormatsText>{t('uploadImageSupportedFormats')}</SupportedFormatsText>
        </UploaderContainer>}
    </>
  );
};
