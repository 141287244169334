import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { InvitationItem, TableWrapper } from 'components';
import { useAuth } from 'contexts';
import { INVITATION_TEMPLATE_STATUS, ORDER_TYPE, TABLE_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { getUserSettings, templatesPerRowCalc, useFetching } from 'helpers';
import { GET_INVITATION_TEMPLATES, graphLazyQueryMiddleware } from 'services';
import { useTemplateStore } from 'store';
import { InvitationTemplateProps, InvitationTemplateType, UseAuthProps } from 'types';

import { EmptyListTextStyle } from './InvitationTemplates.styled';

type InvitationTemplatesListProps = {
  setShowCards?: (state: boolean) => void
  isCreateInvitationProcess?: boolean,
  typesData: InvitationTemplateType[],
};

export const InvitationTemplatesList = ({ setShowCards, isCreateInvitationProcess, typesData }: InvitationTemplatesListProps) => {
  const { dbUser }: UseAuthProps = useAuth();
  const [ getInvitationTemplates, { loading: loadingInvitationTemplates, data: invitationTemplatesData, refetch: refetchInvitationTemplates }] = graphLazyQueryMiddleware(GET_INVITATION_TEMPLATES);
  const { params, selectedTypeId, isLoading, setPageInfo, pageInfo } = useTemplateStore();

  const [ storedData, setStoredData ] = useState(null);
  const [ startFetching, setStartFetching ] = useState(false);
  const userSettings = getUserSettings(USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE, dbUser.id);

  useEffect(() => {
    return () => {
      setPageInfo({
        ...pageInfo,
        totalCount: 0
      });
    };
  }, []);

  const fetchInvitationTemplates = (newSearchText = '') => {
    if (!startFetching) {
      return;
    }

    const mappedTypes: number[] = [];
    if (selectedTypeId) {
      mappedTypes.push(selectedTypeId);
    } else {
      params.selectedTypes.forEach(selectedType => {
        typesData?.forEach((invitationTemplateType) => {
          if (selectedType === invitationTemplateType.name) {
            mappedTypes.push(invitationTemplateType.id);
          }
        });
      });
    }
    const mappedStatuses = isCreateInvitationProcess ?
      [INVITATION_TEMPLATE_STATUS.PUBLISHED.toUpperCase()] :
      params.selectedStatus.map(selectedStatus => Object.keys(INVITATION_TEMPLATE_STATUS)[Object.values(INVITATION_TEMPLATE_STATUS).indexOf(selectedStatus as unknown as INVITATION_TEMPLATE_STATUS)]);
    const order = params.orderBy === 'name' ? ORDER_TYPE.ASC : ORDER_TYPE.DESC;
    const numberOfTemplatesPerRow = templatesPerRowCalc();

    getInvitationTemplates({
      fetchPolicy: 'cache-and-network',
      variables: {
        order: {
          [params.orderBy]: order.toUpperCase(),
          'id': order.toUpperCase()
        },
        filter: {
          colors: params.selectedColors.map((color: string) => color.toUpperCase()),
          textFilter: newSearchText,
          typeIds: mappedTypes,
          statuses: mappedStatuses,
          tags: params.selectedTags
        },
        skip: params.rowsPerPage * numberOfTemplatesPerRow * (params.page - 1),
        take: params.rowsPerPage * numberOfTemplatesPerRow
      }}).then((res) => {
      setPageInfo({
        hasNextPage: res.data.invitationTemplates.pageInfo.hasNextPage,
        hasPreviousPage: res.data.invitationTemplates.pageInfo.hasPreviousPage,
        totalCount: res.data.invitationTemplates.totalCount
      });
    });
  };

  useEffect(() => {
    if (invitationTemplatesData) {
      setStoredData(invitationTemplatesData);
    }
  }, [invitationTemplatesData]);

  useFetching( fetchInvitationTemplates, refetchInvitationTemplates, loadingInvitationTemplates, setStartFetching, startFetching, userSettings, useTemplateStore, TABLE_TYPE.INVITATION_TEMPLATES_TABLE );

  const invitationTemplates = (invitationTemplatesData?.invitationTemplates.items) || (storedData?.invitationTemplates.items) || [];

  return (
    <TableWrapper isLoading={isLoading} totalCount={pageInfo.totalCount} theme={EmptyListTextStyle}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4} sx={{ flexWrap: 'wrap' }}>
          {!loadingInvitationTemplates &&
            invitationTemplates.map((item: InvitationTemplateProps) => (
              <Grid item xs={6} sm={6} md={6} lg={4} xl={3} key={item.id}>
                <InvitationItem
                  isCreateInvitationProcess={isCreateInvitationProcess}
                  setShowCards={setShowCards}
                  templateData={item}/>
              </Grid>
            ))}
        </Grid>
      </Box>
    </TableWrapper>
  );
};