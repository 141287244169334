import React, { CSSProperties } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';

import { CustomTooltip } from 'components';
import { themeDefault } from 'themeDefault';

import { BasicOption, DrawerIcon, ImpersonatedOption, ListItemStyled, SubmenuIconWrapper } from './ListItemComponent.styled';

interface SidebarProps {
  icon: any,
  text: string,
  isSelected: boolean,
  handleOnClick: any,
  expandInvitations?: any,
  isSubmenuExpanded?: boolean,
  showSubmenu?: boolean
  impersonated?: boolean
  isIconSVG?: boolean
}

export const ListItemComponent = ({ icon, text, isSelected, handleOnClick, expandInvitations, isSubmenuExpanded, showSubmenu, impersonated, isIconSVG = false }: SidebarProps) => {
  return (
    <ListItemStyled disablePadding>
      <DrawerIcon
        selected={isSelected}
        onClick={() => handleOnClick()}>
        <ListItemIcon>
          {isIconSVG ?
            icon
            :
            <img src={icon.toString()} height={20} width={20} />
          }
        </ListItemIcon>
        <BasicOption as={impersonated ? ImpersonatedOption : BasicOption} >
          <CustomTooltip theme={themeDefault.overflowText as CSSProperties} text={`${text}`} />
        </BasicOption>
        {showSubmenu &&
          <SubmenuIconWrapper onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (showSubmenu) {
              e.stopPropagation();
              expandInvitations();
            }
          }}>
            {isSubmenuExpanded ? <ExpandLess /> : <ExpandMore />}
          </SubmenuIconWrapper>}
      </DrawerIcon>
    </ListItemStyled>
  );
};