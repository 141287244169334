import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, ConfirmationModal, CreateGuestModal, CustomTooltip, RsvpStatusModal } from 'components';
import { RESPONSE_STATUS } from 'enums';
import { calculateMenuPlacement, checkIsAnyEviteSelected } from 'helpers';
import { REMOVE_GUEST, graphMutationMiddleware } from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { themePublic } from 'themeDefault';
import { ContactType, EviteResponseType, EviteType, GuestType, InvitationProps, ResponseType } from 'types';

import {
  BoldInfoData, ContactGroupMobilePaperContainer, ContactGroupMobilePaperContainerSelected,
  Data,
  DataContainer,
  DataContainerMobile, DataEviteName,
  DataInfoContainer,
  DividerStyled,
  EvitesContainer, ExpandArrowContainer, ExpandedDataContainer, GroupMobilePaperContent, LabelName,
  PeopleIconStyled, ThemeMultipleLinesText, ThemeOverlappedText, TypographyCustomTooltip
} from './GroupMobileGuest.styled';
import {
  Delete as DeleteIcon,
  Edit as EditIcon, KeyboardArrowDown, KeyboardArrowUp, Mail as MailIcon,
  Send as SendIcon, Visibility as VisibilityIcon
} from '@mui/icons-material';

type GroupMobileGuestProps = {
  invitation: InvitationProps
  guest: GuestType
  forPreview: boolean
  isTrackPage?: boolean
  sendToOneContact: (guestId: number) => void
  enabledEvites?: EviteType[],
}

export const GroupMobileGuest = ({
  guest,
  invitation,
  sendToOneContact,
  forPreview,
  isTrackPage = false,
  enabledEvites = [],
}: GroupMobileGuestProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { selectedGuests, setSelectedGuests } = useGuestStore();
  const { changeExecuteRefetch: refetchResponses } = useTrackStore();

  const [ removeGuest, { loading: removeLoading }] = graphMutationMiddleware(REMOVE_GUEST);

  const [ groupModalOpen, setGroupModalOpen ] = useState(false);
  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ extendGroup, setExtendGroup ] = useState(false);
  const [ isOpenRsvpStatusModal, setIsOpenRsvpStatusModal ] = useState(false);
  const [ isEditRSVP, setIsEditRSVP ] = useState(false);

  const handleOpenRsvpModal = (isEdit: boolean) => {
    setIsOpenRsvpStatusModal(true);
    setIsEditRSVP(isEdit);
  };

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: EditIcon,
      functionHandler: () => setGroupModalOpen(true)
    },
    {
      id: 1,
      title: t('send'),
      icon: SendIcon,
      functionHandler: () => checkIsAnyEviteSelected(guest, sendToOneContact, t('noEvitesSelectedFor', { entity: t('group')}))
    },
    {
      id: 2,
      title: t('preview'),
      icon: VisibilityIcon,
      functionHandler: () => navigate(`/invitations/edit/preview/${invitation.id}`, { state: { guest: guest } })
    },
    {
      id: 3,
      title: t('viewRSVP'),
      icon: MailIcon,
      functionHandler: () => handleOpenRsvpModal(false)
    },
    {
      id: 4,
      title: t('editRSVP'),
      icon: EditIcon,
      functionHandler: () => handleOpenRsvpModal(true)
    },
    {
      id: 5,
      title: t('remove'),
      icon: DeleteIcon,
      functionHandler: () => setDeleteModal(true)
    }
  ];

  const deleteGuest = () => {
    removeGuest({variables: {
      guestId: Number(guest.id)
    }}).then(() => {
      refetchResponses();
    });
  };

  return (
    <>
      <Paper elevation={2} component={selectedGuests.map(selectedGuest => selectedGuest.guestId).includes(guest.id) ? ContactGroupMobilePaperContainerSelected : ContactGroupMobilePaperContainer}>
        <GroupMobilePaperContent>
          <PeopleIconStyled/>
          <DataContainerMobile onClick={() => setSelectedGuests(guest)}>
            <BoldInfoData>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={guest.contact.lastName} />
            </BoldInfoData>
          </DataContainerMobile>
          {!forPreview &&
          <ActionMenu iconTheme={themePublic.actionMenuIconStyle} propsData={actionMenuProps} disablePortal isTrackPage={isTrackPage} menuPlacement={calculateMenuPlacement(true)} />
          }
        </GroupMobilePaperContent>
        {extendGroup && !forPreview &&
        <>
          {isTrackPage ?
            <>
              <DividerStyled/>
              <DataContainer>
                <DataInfoContainer>
                  <LabelName>{t('sender')}: </LabelName>
                  <span>
                    <CustomTooltip theme={ThemeOverlappedText} text={`${guest?.sender?.firstName ?? ''} ${guest?.sender?.lastName}`} />
                  </span>
                </DataInfoContainer>
                <DataInfoContainer>
                  <LabelName>{t('event')}: </LabelName>
                  <Data>
                    <CustomTooltip theme={ThemeOverlappedText} text={guest.invitation.name} />
                  </Data>
                </DataInfoContainer>
                <EvitesContainer>
                  {guest?.evites.map((evite: EviteType) => {
                    return enabledEvites.find(eev => eev.id === evite.id) &&
                    <DataInfoContainer key={evite.id}>
                      <DataEviteName>
                        <CustomTooltip theme={ThemeOverlappedText} text={`${evite.name}: `} />
                      </DataEviteName>
                      <Data>
                        <CustomTooltip theme={ThemeOverlappedText} text={guest?.responses.length > 0 && guest?.responses.find((r: ResponseType) => r.contact.id === guest.contact.id).eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ?
                          t(guest?.responses.find((r: ResponseType) => r.contact.id === guest.contact.id).eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id).status) :
                          t(RESPONSE_STATUS.NOT_SENT)} />
                      </Data>
                    </DataInfoContainer>;
                  })}
                </EvitesContainer>
              </DataContainer>
              <DividerStyled/>
            </> :
            <>
              <DividerStyled/>
              <DataContainer>
                <DataInfoContainer>
                  <LabelName>{t('lastSent')}: </LabelName>
                  <Data>{guest.lastSent && format(parseISO(guest.lastSent), t('dateFormatUI'))}</Data>
                </DataInfoContainer>
                <DataInfoContainer>
                  <LabelName>{t('evites')}: </LabelName>
                  <span>
                    <CustomTooltip
                      theme={ThemeOverlappedText}
                      text={guest?.evites.filter((evite: EviteType) => evite.isSelected).length > 0 && guest.evites.filter((evite: EviteType) => evite.isSelected).map((item: EviteType) => item.name).join(', ')} />
                  </span>
                </DataInfoContainer>
                <DataInfoContainer>
                  <LabelName>{t('sender')}: </LabelName>
                  <Data>
                    <CustomTooltip
                      theme={ThemeOverlappedText}
                      text={`${guest?.sender?.firstName ?? ''} ${guest?.sender?.lastName}`} />
                  </Data>
                </DataInfoContainer>
              </DataContainer>
              <DividerStyled/>
            </>
          }
        </>
        }
        {extendGroup && guest.contact.contacts.map((contact: ContactType) => (
          <>
            <ExpandedDataContainer key={contact.id}>
              <PeopleIconStyled/>
              <DataContainerMobile onClick={() => setSelectedGuests(guest)}>
                <BoldInfoData>
                  <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={`${contact.lastName} ${contact.firstName}`} />
                </BoldInfoData>
                <TypographyCustomTooltip>
                  <CustomTooltip theme={ThemeOverlappedText} text={contact.phoneNumber} />
                </TypographyCustomTooltip>
                <TypographyCustomTooltip>
                  <CustomTooltip theme={ThemeOverlappedText} text={contact.email} />
                </TypographyCustomTooltip>
              </DataContainerMobile>
            </ExpandedDataContainer>
            {isTrackPage && enabledEvites.length > 0 &&
            <>
              <DividerStyled/>
              <DataContainer>
                <EvitesContainer>
                  {guest?.evites.map((evite: EviteType) => {
                    return enabledEvites.find(eev => eev.id === evite.id) &&
                    <DataInfoContainer key={evite.id}>
                      <DataEviteName>
                        <CustomTooltip theme={ThemeOverlappedText} text={`${evite.name}: `} />
                      </DataEviteName>
                      <span>
                        <CustomTooltip theme={ThemeOverlappedText} text={guest?.responses.length > 0 && guest?.responses.find((r: ResponseType) => r.contact.id === contact.id).eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ?
                          t(guest?.responses.find((r: ResponseType) => r.contact.id === contact.id).eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id).status) :
                          t(RESPONSE_STATUS.NOT_SENT)} />
                      </span>
                    </DataInfoContainer>;
                  })}
                </EvitesContainer>
              </DataContainer>
              <DividerStyled/>
            </>
            }
          </>
        ))}
        <ExpandArrowContainer onClick={() => setExtendGroup(!extendGroup)}>
          {extendGroup ? <KeyboardArrowUp/> : <KeyboardArrowDown /> }
        </ExpandArrowContainer>
      </Paper>
      {isOpenRsvpStatusModal && <RsvpStatusModal guest={guest} isOpen={true} isEdit={isEditRSVP} handleClose={() => setIsOpenRsvpStatusModal(false)} />}
      <ConfirmationModal loading={removeLoading} isOpen={deleteModal} handleClose={() => setDeleteModal(false)} confimMessage={t('confirmDeleteMessage')} handleConfirm={deleteGuest} />
      {groupModalOpen &&
      <CreateGuestModal guest={guest} handleClose={() => setGroupModalOpen(false)} isOpen={groupModalOpen} />
      }
    </>
  );
};