import { Box, Button, Input, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const DraggableContainer = styled(Box)(
  ({ theme }) => ({
    height: '181px',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '100%',
    }
  }),
);

export const NotDisplayed = styled(Input)(
  () => ({
    display: 'none',
  }),
);

export const DragAndDropTitle = styled(Typography)(
  () => ({
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  }),
);

export const UploadButton = styled(Button)(
  () => ({
    border: 'none',
    color: themeDefault.palette.mainColor,
    cursor: 'pointer',
    fontSize: 20,
    padding: '0.25rem',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
);

export const UploadedFilesContainer = styled(Box)(
  () => ({
    ...themeDefault.overflowText,
    bottom: '0px',
    display: 'flex',
    justifyContent: 'end',
    marginRight: '15px',
    maxWidth: '95%',
    position: 'absolute',
    right: '0px',
    width: 'auto',
    '& p': {
      ...themeDefault.overflowText,
    },
  }),
);

export const DragFileElement = styled(Box)(
  () => ({
    borderRadius: '1rem',
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
  }),
);

export const LabelFileUpload = styled(Box)(
  () => ({
    ...themePublic.dragAndDropLabelFileUpload as CSSProperties,
  }),
);

export const ActiveLabelFileUpload = styled(Box)(
  () => ({
    ...themePublic.dragAndDropLabelFileUpload as CSSProperties,
    backgroundColor: themeDefault.palette.whiteColor,
  }),
);
