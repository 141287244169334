import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { usePromptModal } from 'components';

export function useBlocker( blocker, when = true ) {
  const { navigator: nav } = useContext( NavigationContext );

  useEffect( () => {
    if (!when) {
      return;
    }

    const unblock = nav.block( ( tx ) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker( autoUnblockingTx );
    } );

    return unblock;
  }, [ navigator, blocker, when ] );
}

export function usePrompt(options, when = true) {
  const blocker = useCallback((tx) => {
    usePromptModal({...options, tx: tx});
  }, []);

  useBlocker( blocker, when );
}