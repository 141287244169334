import React, { useEffect, useRef, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Checkbox,
  PaperProps,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, VirtualKeyboard } from 'components';
import { VALIDATION_RESTRICTION } from 'enums';
import { filterEmptyStringTags } from 'helpers';
import { themeDefault } from 'themeDefault';

import { AddNewContainer, AutocompleteContainer, AutocompleteStyled, ErrorMessage, Label, OptionContainer } from './TagSelector.styled';

type TagSelectorProps = {
  showText?: boolean
  multiple?: boolean
  freeSolo?: boolean
  setSelectedTags: (input: string[]) => void
  updateSelectedTags?: (input: string[], action: any, detail: any) => void
  selectedTags: string[]
  defaultShowTags?: boolean
  showKeyboardParent? : boolean
  showKeyboardForEvent? : boolean
  setShowKeyboardParent?: (show: boolean) => void
  setShowKeyboardForEvent?: (show: boolean) => void
  tagData: {
    tags: {
      id: number,
      name: string
    }[]
  }
}

export const TagSelector = ({
  selectedTags,
  setSelectedTags,
  tagData,
  showText=true,
  multiple=true,
  freeSolo=true,
  defaultShowTags=true,
  setShowKeyboardParent=null,
  setShowKeyboardForEvent=null,
  updateSelectedTags=null,
  showKeyboardParent = false,
  showKeyboardForEvent = false
}: TagSelectorProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);

  const [ showTags, setShowTags ] = useState(defaultShowTags);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');
  const [ tagsHaveError, setTagsHaveError ] = useState<boolean>(false);

  useEffect((() => {
    showKeyboardParent && setShowKeyboard(false);
  }), [showKeyboardParent]);

  useEffect((() => {
    showKeyboardForEvent && setShowKeyboard(false);
  }), [showKeyboardForEvent]);

  const CustomPaper = (props: PaperProps) => {
    return <OptionContainer elevation={8} {...props} />;
  };

  return (
    <AddNewContainer>
      {showText && showTags && selectedTags.length === 0 ?
        <Label onClick={() => setShowTags(false)}>+ {t('addTags')}</Label>:
        <AutocompleteContainer>
          <AutocompleteStyled
            PaperComponent={CustomPaper}
            disableCloseOnSelect
            freeSolo={freeSolo}
            getOptionLabel={(option: string) => option}
            inputValue={inputValue}
            disableClearable={selectedTags.length === 0}
            multiple={multiple}
            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value?.length > VALIDATION_RESTRICTION.HUNDRED) {
                setTagsHaveError(true);
                return;
              } else {
                setTagsHaveError(false);
              }
              setInputValue(e.target.value);
            }}
            options={tagData && tagData.tags.map((item: any) => item.name)}
            value={multiple ? selectedTags : selectedTags[0]}
            onFocus={() => {
              setShowKeyboard(true);
              setShowKeyboardParent && setShowKeyboardParent(false);
              setShowKeyboardForEvent && setShowKeyboardForEvent(false);
            }}
            onChange={(e, val: string[], reason, detail) => {
              const trimedVal = filterEmptyStringTags(val);
              setSelectedTags(trimedVal);
              setInputValue('');
              updateSelectedTags && updateSelectedTags(trimedVal, reason, detail);
            }}
            renderOption={(props, option: string, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  style={{ marginRight: 8 }}
                  checked={selected}/>
                <CustomTooltip theme={themeDefault.overflowText} text={option} />
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('enterTag')} />
            )}/>
          {tagsHaveError &&
          <ErrorMessage>
            {t('stringPropertyMaxValidation', { propertyName: t('tag'), length: VALIDATION_RESTRICTION.HUNDRED })}
          </ErrorMessage>}
        </AutocompleteContainer>
      }
      { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          initialValues={{
            'tags': selectedTags
          }}
          setTagsValue={setInputValue}
          addTag={setSelectedTags}
          keyboardRef={keyboardRef}
          tagInputValue={inputValue}
          inputName={'newTags'}/>
      }
    </AddNewContainer>
  );
};