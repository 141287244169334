import { Box } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import Slider from 'react-slick';

import { themeDefault } from 'themeDefault';

const themeLocal = createTheme();

const BaseArrowStyle = {
  cursor: 'pointer',
  fontSize: '2rem',
  position: 'absolute',
  top: '50%',
  transform: 'translate(0%, -50%)',
  zIndex: 100,
};

const generateSlideStyle = (scale: number, opacity: number, mobileOpacity: number) => {
  return {
    transform: `scale(${scale})`,
    transition: 'transform 300ms',
    opacity: opacity,
    cursor: 'pointer',
    display: 'inline-block !important',
    width: 'inherit',
    [themeLocal.breakpoints.down('sm')]: {
      width: 'auto',
      transform: 'scale(0.7)',
      opacity: mobileOpacity,
      transition: 'opacity 0.2s ease-in'
    },
  };
};

export const ImageSize = styled('img')(
  () => ({
    maxHeight: '400px',
    maxWidth: '400px',
  }),
);

export const SingleImage = {
  transform: 'scale(1)',
  transition: 'transform 300ms',
  maxHeight: '280px',
  width: 'auto !important',
  maxWidth: '60%',
};

export const ActiveSlide = {
  ...generateSlideStyle(1, 1, 1),
};

export const FirstSideSlide = {
  ...generateSlideStyle(0.7, 0.7, 0),
};

export const SecondSideSlide = {
  ...generateSlideStyle(0.5, 0.5, 0),
};

export const NextArrowContainer = styled(Box)(
  () => ({
    ...BaseArrowStyle as CSSProperties,
    right: '10px',
  }),
);

export const PrevArrowContainer = styled(Box)(
  () => ({
    ...BaseArrowStyle as CSSProperties,
    left: '10px',
  }),
);

export const CenteredSlider = styled(Slider)(
  () => ({
    '& .slick-track': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export const ImageContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex !important',
    justifyContent: 'center',
    width: '100%',
    '& img': {
      boxShadow: themeDefault.canvasBoxShadow,
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'inherit',
    },
  }),
);

export const SquareImageContainer = styled(ImageContainer)(
  () => ({
    aspectRatio: '1/1',
  }),
);
