import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const PromptBody = styled(Box)(
  () => ({
    background: themeDefault.palette.whiteColor,
    borderRadius: '10px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)',
    color: themeDefault.palette.blackColor,
    maxWidth: '400px',
    opacity: 1,
    padding: '30px',
    textAlign: 'left',
  }),
);

export const ButtonsContainer = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    width: '100%',
  }),
);
