import { styled } from '@mui/material/styles';

export const PageLoader = styled('div')(
  () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    zIndex: 1000,
  }),
);

export const ComponentLoader = styled('div')(
  () => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }),
);
