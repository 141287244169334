import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavigateBefore as BackIcon, NavigateNext as NextIcon } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { fabric } from 'fabric';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { ANIMATION_ELEMENTS_IDS } from 'enums';
import { addShadowToCanvas, calculateCanvasScale, delay, getElementsDimension, setCanvasDimensions } from 'helpers';
import { themeDefault } from 'themeDefault';
import { ImageType } from 'types';

import {
  AnimationContent, AnimationContentContainer, AnimationContentContainerrOverflowVisible, AnimationContentContainerrRemoveButton,
  AnimationContentContainerrShowAll, AnimationContentContainerrVisibilityHidden, AnimationContentEnvelopeAnimation,
  BackIconContainer, CardRotation, DefaultCursorStyle, EnvelopeBack, EnvelopeBackFixBackSideStyle, EnvelopeBackImage,
  EnvelopeBackRotationStyle, EnvelopeBody, EnvelopeContainer, EnvelopeContainerMoveDown, EnvelopeFront, EnvelopeFrontContainer,
  EnvelopeFrontRotationStyle, EnvelopeInside, EnvelopeShadowContainer, EnvelopeShadowContainerHide, EnvelopeTop, EnvelopeTopContainer,
  EnvelopeTopContainerBehind, EnvelopeTopContainerOpen, EnvelopeTopInside, EnvelopeTopShadow, HideEvite, HideSideEvite,
  HorizontalSingleCardRotation, InvitationContainer, InvitationContainerAnimation, InvitationContainerRotate, InvitationContent,
  InvitationCoreContainerHorizontal, InvitationCoreContainerStyle, KeepSingleOnFrontBaseStyle, KeepSingleOnFrontBaseStyleEmail, KeepSingleOnFrontBaseStylePreview,
  KeepSingleOnFrontHorizontal, MoveLeftEvite, MoveRightEvite, NextIconContainer, NotDisplayedSideEvite, PlayButton, PlayButtonShowStyle,
  PlayIcon, PointerCursorStyle, ReplayContainer, ReplayIconStyled, SetToBack, SetToFront
} from './AnimationContainer.styled';

type AnimationContainerProps = {
  envelopeImage: string,
  images: ImageType[],
  isEnabledBinding?: boolean,
  isHiddenSideEvite: boolean,
  isPreview?: boolean,
  setPlayAudio?: () => void,
  setShowModals?: () => void,
  hideModals?: () => void,
  disableModals?: () => void,
};

export const AnimationContainer = ({
  envelopeImage,
  hideModals,
  images,
  isEnabledBinding,
  isHiddenSideEvite,
  isPreview=false,
  setPlayAudio,
  disableModals,
  setShowModals
}: AnimationContainerProps) => {
  const [t] = useTranslation();
  const [ activeSlideId, setActiveSlideId ] = useState(0);
  const [ finalAnimation, setFinalAnimation ] = useState(false);
  const [ isAnimReady, setIsAnimReady ] = useState(false);
  const [ isChangedImage, setIsChangedImage ] = useState(false);
  const [ isEnvelopeReady, setIsEnvelopeReady ] = useState(false);
  const [ isPlayButton, setIsPlayButton ] = useState(false);
  const [ movingUpEvites, setMovingUpEvites ] = useState(false);
  const [ nextSlideId, setNextSlideId ] = useState(0);
  const [ openingEnvelope, setOpeningEnvelope ] = useState(false);
  const [ previousSlideId, setPrevoiusSlideId ] = useState(0);
  const [ rotateEnvelope, setRotateEnvelope ] = useState(false);
  const [ rotateEvites, setRotateEvites ] = useState(false);
  const [ startAnimation, setStartAnimation ] = useState(false);
  const [ touchPosition, setTouchPosition ] = useState(null);

  const imagesLength = images && images.length;

  const initCanvas = (id: string, cursor = 'default') => new fabric.Canvas(id, {defaultCursor: cursor});
  const horizontalImages = useRef([]);
  const frontScaleArray = useRef([]);

  useLayoutEffect(() => {
    const envelopeInside = document.getElementById(ANIMATION_ELEMENTS_IDS.ENVELOPE_INSIDE);
    const animationContent = document.getElementById(ANIMATION_ELEMENTS_IDS.ANIMATION_CONTENT);
    const animationContentContainer = document.getElementById(ANIMATION_ELEMENTS_IDS.ANIMATION_CONTENT_CONTAINER);
    const envelopeContainer = document.getElementById(ANIMATION_ELEMENTS_IDS.ENVELOPE_CONTAINER);

    const { width: envelopeWidth, height: envelopeHeight } = getElementsDimension(envelopeInside);
    const { width: animationBoxWidth, height: animationBoxHeight } = getElementsDimension(animationContent);

    const isVertical = window.innerHeight > window.innerWidth;

    animationContentContainer.style.aspectRatio = isVertical ? '2 / 3' :'3 / 2';
    envelopeContainer.style.width = isVertical ? '100%' :'70%';

    let counter = 0;
    Promise.all(images.map(async (image, index) => {
      const canvas = initCanvas(image.id.toString());
      const canvasJson = JSON.parse(image.contentJson);

      canvas.loadFromJSON(canvasJson, () => {
        let sizeRatio;
        const jsonWidth = canvasJson.width / canvasJson.viewportTransform[0];
        const jsonHeight = canvasJson.height / canvasJson.viewportTransform[3];
        const canvasRatio = Number((jsonWidth / jsonHeight).toFixed(4));

        if (canvasRatio > 1) {
          horizontalImages.current = [ ...horizontalImages.current, index ];
          sizeRatio = Math.min(envelopeHeight / jsonHeight, envelopeWidth / jsonWidth);
        } else {
          sizeRatio = Math.min(envelopeHeight / jsonWidth, envelopeWidth / jsonHeight);
        }
        const finalHeight = jsonHeight * sizeRatio;
        const finalWidth = jsonWidth * sizeRatio;

        if (finalWidth / finalHeight < animationBoxWidth / animationBoxHeight) {
          frontScaleArray.current.push({
            id: image.id,
            value: (animationBoxHeight / finalHeight).toFixed(2)
          });
        } else {
          frontScaleArray.current.push({
            id: image.id,
            value: (animationBoxWidth / finalWidth).toFixed(2)
          });
        }

        canvas.setWidth(finalWidth);
        canvas.setHeight(finalHeight);
        canvas.zoomToPoint(new fabric.Point(0, 0), Number(sizeRatio));
        canvas.forEachObject((obj) => {
          obj.selectable = false;
          obj.evented = false;
        });
        canvas.selection = false;
        canvas.renderAll();

        counter++;
        counter === imagesLength && setIsAnimReady(true);
      });
    }));
    if (isPreview) {
      if (!isPreview) {
        return;
      }
      const canvasEnvelope = initCanvas('envelopeBackJson', 'pointer');
      const canvasEnvelopeJson = JSON.parse(envelopeImage ? envelopeImage : null);
      canvasEnvelope.loadFromJSON(envelopeImage, () => {
        setCanvasDimensions(canvasEnvelope, envelopeWidth, envelopeHeight, calculateCanvasScale(envelopeInside, canvasEnvelopeJson));
        canvasEnvelope.forEachObject((obj) => {
          obj.selectable = false;
          obj.evented = false;
        });
        addShadowToCanvas(canvasEnvelope, canvasEnvelope.width / calculateCanvasScale(envelopeInside, canvasEnvelopeJson), canvasEnvelope.height / calculateCanvasScale(envelopeInside, canvasEnvelopeJson));
        canvasEnvelope.selection = false;
        canvasEnvelope.renderAll();
        setIsEnvelopeReady(true);
      });
    }
    handleSetActiveId(0);
  }, []);

  const handleSetActiveId = (id: number) => {
    setActiveSlideId((id % imagesLength + imagesLength) % imagesLength);
    setNextSlideId(((id + 1) % imagesLength + imagesLength) % imagesLength);
    setPrevoiusSlideId(((id - 1) % imagesLength + imagesLength) % imagesLength);
    hideModals && hideModals();
  };

  const continueAnimation = async () => {
    Promise.resolve()
      .then(() => {
        setIsPlayButton(false);
        setPlayAudio && setPlayAudio();
        setRotateEnvelope(true);
      })
      .then(() => delay(900))
      .then(() => {
        setOpeningEnvelope(true);
        delay(350).then(() => setIsChangedImage(true));
      })
      .then(() => delay(1000))
      .then(() => setMovingUpEvites(true))
      .then(() => delay(1000))
      .then(() => setRotateEvites(true))
      .then(() => delay(1000))
      .then(() => {
        setFinalAnimation(true);
        setShowModals && setShowModals();
      });
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (isEnabledBinding) {
      switch (e.key) {
        case 'ArrowRight':
          handleSetActiveId(activeSlideId - 1);
          break;
        case 'ArrowLeft':
          handleSetActiveId(activeSlideId + 1);
          break;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [ handleKeyPress, document ]);

  const isHorizontal = (index : number) => horizontalImages.current.includes(index);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0].clientY;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientY;
    const diff = touchDown - currentTouch;

    if (diff > 3) {
      handleSetActiveId(activeSlideId + 1);
    }
    if (diff < -3) {
      handleSetActiveId(activeSlideId - 1);
    }
    setTouchPosition(null);
  };

  useEffect(() => {
    if (isEnvelopeReady) {
      Promise.resolve()
        .then(() => setStartAnimation(true))
        .then(() => delay(2500))
        .then(() => setIsPlayButton(true));
    }
  }, [isEnvelopeReady]);

  const findValueFromId = (itemId: number) => frontScaleArray.current.find(i => i.id === itemId).value;

  const getStyleForOneOrTwoEvites = (index: number, itemId: number) => {
    let retStyle = {zIndex: images.length - index};
    if (isHorizontal(index)) {
      retStyle = {...retStyle, ...InvitationCoreContainerHorizontal};
      if (rotateEvites && !finalAnimation) {
        retStyle = {...retStyle, ...HorizontalSingleCardRotation};
      }
      if (finalAnimation && index === activeSlideId) {
        retStyle = {...retStyle, ...KeepSingleOnFrontHorizontal(findValueFromId(itemId))};
      }
    } else {
      retStyle = {...retStyle, ...InvitationCoreContainerStyle};
      if (rotateEvites) {
        retStyle = {...retStyle, ...CardRotation};
      }
      if (finalAnimation && index === activeSlideId) {
        retStyle = {
          ...retStyle,
          ...KeepSingleOnFrontBaseStyle,
          ... isPreview ?
            KeepSingleOnFrontBaseStylePreview(findValueFromId(itemId)) :
            KeepSingleOnFrontBaseStyleEmail(findValueFromId(itemId))};
      }
    }

    return retStyle;
  };

  const replayAnimation = async () => {
    setIsEnvelopeReady(false);
    setStartAnimation(!startAnimation);
    setRotateEnvelope(!rotateEnvelope);
    setOpeningEnvelope(!openingEnvelope);
    setIsChangedImage(!isChangedImage);
    setMovingUpEvites(!movingUpEvites);
    setRotateEvites(!rotateEvites);
    setFinalAnimation(!finalAnimation);
    hideModals && hideModals();
    disableModals && disableModals();
    await delay(500).then(() => {
      setIsEnvelopeReady(true);
    });
  };

  useEffect(() => {
    if (finalAnimation) {
      setShowModals && setShowModals();
    } else {
      hideModals && hideModals();
    }
  }, [finalAnimation]);

  return (
    <>
      <AnimationContentContainer
        id={ANIMATION_ELEMENTS_IDS.ANIMATION_CONTENT_CONTAINER}
        sx={{
          ... movingUpEvites && AnimationContentContainerrOverflowVisible,
          ... isPreview && AnimationContentContainerrRemoveButton,
          ... startAnimation && isEnvelopeReady && AnimationContentContainerrShowAll,
          ... !(startAnimation && isEnvelopeReady) && AnimationContentContainerrVisibilityHidden
        }}>
        <Box
          id={ANIMATION_ELEMENTS_IDS.ANIMATION_CONTENT}
          sx={{
            ...AnimationContent,
            ... startAnimation && {transform: isPreview ? 'translateY(0%)' : 'translateY(0%)'},
            ... startAnimation && AnimationContentEnvelopeAnimation }}>
          <EnvelopeContainer
            id={ANIMATION_ELEMENTS_IDS.ENVELOPE_CONTAINER}
            sx={movingUpEvites && EnvelopeContainerMoveDown}>
            {
              isPlayButton &&
              isEnvelopeReady &&
              <PlayButton
                sx={{
                  ... isPlayButton ? PlayButtonShowStyle : DefaultCursorStyle,
                  ... isAnimReady && PointerCursorStyle
                }}
                onClick={() => {
                  isAnimReady && continueAnimation();
                }}>
                <span>{t('clickToOpen')}</span>
                {isAnimReady ?
                  <span>
                    <PlayIcon />
                  </span> :
                  <CircularProgress sx={{color: themeDefault.palette.whiteColor}} size='1.5rem' />}
              </PlayButton>
            }
            <EnvelopeFrontContainer
              id={ANIMATION_ELEMENTS_IDS.ENVELOPE_FRONT_CONTAINER}
              sx={{ ...rotateEnvelope && EnvelopeFrontRotationStyle, overflow: openingEnvelope ? 'visible' : 'hidden' }}>
              <EnvelopeInside id={ANIMATION_ELEMENTS_IDS.ENVELOPE_INSIDE} />
              <EnvelopeFront id={ANIMATION_ELEMENTS_IDS.ENVELOPE_FRONT}>
                <InvitationContent
                  id={ANIMATION_ELEMENTS_IDS.INVITATION_CONTENT}
                  sx={{zIndex: rotateEvites ? 15 : 1, ... movingUpEvites && AnimationContentContainerrOverflowVisible}}>
                  <InvitationContainer
                    style={{display: startAnimation ? 'block' : 'none'}}
                    id={ANIMATION_ELEMENTS_IDS.INVITATION_CONTAINER}
                    sx={{
                      ... movingUpEvites && InvitationContainerAnimation,
                      ... rotateEvites && images.length > 1 && {
                        transform: isMobile ? 'rotate(0deg) translate(0%, 25%)' : 'rotate(0deg) translate(0%, 60%)'},
                      ...rotateEvites && images.length > 1 && InvitationContainerRotate,
                      ... (isMobile ? movingUpEvites : startAnimation) && AnimationContentContainerrOverflowVisible,
                    }}>
                    {
                      isAnimReady &&
                      images.length > 2 ?
                        images.map((item: ImageType, index: number) => {
                          return (
                            <Box key={item.id}
                              onTouchMove={(e) => finalAnimation && index === activeSlideId && handleTouchMove(e)}
                              onTouchStart={(e) => finalAnimation && index === activeSlideId && handleTouchStart(e)}
                              id={`${ANIMATION_ELEMENTS_IDS.INVITATION_CORE}_${item.id}`}
                              sx={{
                                zIndex: images.length - index,
                                ... isHorizontal(index) ? InvitationCoreContainerHorizontal : InvitationCoreContainerStyle,
                                ... !isHorizontal(index) && rotateEvites && CardRotation,
                                ... !finalAnimation && index !== activeSlideId && HideEvite,
                                ... finalAnimation && index === activeSlideId && SetToFront(findValueFromId(item.id)),
                                ... finalAnimation && index === nextSlideId && MoveRightEvite,
                                ... finalAnimation && index === previousSlideId && MoveLeftEvite,
                                ... isHiddenSideEvite && rotateEvites && index !== activeSlideId && HideSideEvite,
                                ... !finalAnimation && index !== activeSlideId && index !== previousSlideId && index !== nextSlideId && NotDisplayedSideEvite,
                                ... finalAnimation && index !== activeSlideId && index !== previousSlideId && index !== nextSlideId && SetToBack,
                              }}>
                              <canvas id={item.id.toString()} />
                            </Box>
                          );
                        }):
                        images.map((item: ImageType, index: number) => (
                          <Box key={item.id}
                            id={`${ANIMATION_ELEMENTS_IDS.INVITATION_CORE}_${item.id}`}
                            sx={getStyleForOneOrTwoEvites(index, item.id)}>
                            <canvas id={item.id.toString()} />
                          </Box>
                        ))
                    }
                  </InvitationContainer>
                </InvitationContent>
                <EnvelopeTopContainer
                  id={ANIMATION_ELEMENTS_IDS.ENVELOPE_TOP_CONTAINER}
                  sx={{
                    ... openingEnvelope && EnvelopeTopContainerOpen,
                    ... isChangedImage && EnvelopeTopContainerBehind,
                  }}>
                  <EnvelopeTop id={ANIMATION_ELEMENTS_IDS.ENVELOPE_TOP} sx={isChangedImage ? {opacity: '0 !important'} : {opacity: '1 !important'}} />
                  <EnvelopeTopInside id={ANIMATION_ELEMENTS_IDS.ENVELOPE_TOP_INSIDE} sx={isChangedImage ? {opacity: '1 !important'} : {opacity: '0 !important'}} />
                </EnvelopeTopContainer>
                <EnvelopeShadowContainer sx={{
                  ... openingEnvelope && EnvelopeShadowContainerHide,
                }}>
                  <EnvelopeTopShadow />
                </EnvelopeShadowContainer>
                <EnvelopeBody />
              </EnvelopeFront>
            </EnvelopeFrontContainer>
            {
              isPreview ?
                <EnvelopeBack
                  id={ANIMATION_ELEMENTS_IDS.ENVELOPE_BACK}
                  onClick={() => isPlayButton && continueAnimation()}
                  sx={{
                    ... rotateEnvelope && EnvelopeBackRotationStyle,
                    ... rotateEnvelope && EnvelopeBackFixBackSideStyle,
                    ... isPlayButton && PointerCursorStyle,
                  }}>
                  <canvas id={ANIMATION_ELEMENTS_IDS.ENVELOPE_BACK_JSON} />
                </EnvelopeBack>
                :
                <EnvelopeBackImage
                  id={ANIMATION_ELEMENTS_IDS.ENVELOPE_BACK}
                  src={envelopeImage}
                  onClick={() => {
                    isPlayButton && continueAnimation();
                  }}
                  onLoad={() => setIsEnvelopeReady(true)}
                  sx={{
                    ... rotateEnvelope && EnvelopeBackRotationStyle,
                    ... isPlayButton && PointerCursorStyle,
                  }}>
                </EnvelopeBackImage>
            }
          </EnvelopeContainer>
        </Box>
      </AnimationContentContainer>
      {
        finalAnimation &&
        <Tooltip title={t('replay')} >
          <ReplayContainer onClick={replayAnimation}>
            <ReplayIconStyled/>
          </ReplayContainer>
        </Tooltip>
      }
      {finalAnimation && images.length >= 2 &&
        <>
          <BackIconContainer onClick={() => handleSetActiveId(activeSlideId + 1)}>
            <BackIcon />
          </BackIconContainer>
          <NextIconContainer onClick={() => handleSetActiveId(activeSlideId - 1)}>
            <NextIcon />
          </NextIconContainer>
        </>}
      <Loader inProgress={!isEnvelopeReady}/>
    </>
  );
};