import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { format, isBefore, setMinutes } from 'date-fns';
import { FastField, Form, Formik, FormikErrors } from 'formik';
import { debounce } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types.js';

import { AutocompleteCustom, CustomTooltip, GiftUrl, InputField, SenderForm, TagSelector, VirtualKeyboard } from 'components';
import { useAuth, useInvitation } from 'contexts';
import { FORM_TYPE, ORDER_PROPERTY, ORDER_TYPE, USER_PERMISSIONS, VALIDATION_RESTRICTION } from 'enums';
import {
  DATE_FORMAT_INPUT,
  DEBOUNCE_SEARCH_WAIT_TIME,
  DEFAULT_EVENT_DURATION_HOURS,
  LOCAL_DATE_ARGUMENT, MAX_NUMBER_OF_GIFTS, MAX_NUMBER_OF_ITEMS_TO_GET, MAX_NUMBER_OF_SENDERS, MAX_YEAR, Prompt,
  getLocalStringOptions, getRouteForNavigation, getUTCDate, isEmptyStringOrNull,
  tagsAreChanged,
  TIME_FORMAT_INPUT,
  checkPermission,
} from 'helpers';
import {
  GET_CURRENT_USER, GET_INVITATIONS, GET_TAGS, GET_VENUES,
  UPDATE_INVITATION,
  graphLazyQueryMiddleware, graphMutationMiddleware
} from 'services';
import { themeDefault } from 'themeDefault';
import {
  EventLinkType, FormikMethodsTypes,
  InvitationContextType, InvitationDetailsFormValuesType, InvitationProps, SendersProps, TagType, UseAuthProps, VenueProps
} from 'types';

import {
  AddNewTextButton, AddNewTextButtonFixedBottom, AutocompleteInputContainer, BoxSpacingContainer,
  DateContainer, DateInputContainer, DateTimeContainer, DetailsContainer, EndTimeLabel, ErrorMessage, InformationContainer, InputMessage,
  MarginBottomContainer, MarginBottomRemoveLabel, MobileTitle, PageButtonsContainer, PageContent, PageNextButton, PagePrevButton,
  RelativePositionContainer, Title
} from './InvitationDetailsPage.styled';

type InvitationDetailsPageProps = {
  focusField?: boolean
  setFocusField?: (focusValue: boolean) => void
};

export const InvitationDetailsPage = ( {focusField = false, setFocusField = null} : InvitationDetailsPageProps) => {
  const [t] = useTranslation();
  const navLocation = useLocation();
  const routeParams = useParams();
  const keyboardRef = useRef(null);
  const { dbUser }: UseAuthProps = useAuth();
  const { invitationDetails }: InvitationContextType = useInvitation();

  const [ getTags, { data, refetch: refetchTags }] = graphLazyQueryMiddleware(GET_TAGS);
  const [ getInvitations, { data: invitationData, loading: loadingInvitationData }] = graphLazyQueryMiddleware(GET_INVITATIONS);
  const [updateInvitation] = graphMutationMiddleware(UPDATE_INVITATION);
  const [ getUser, {data: currentUserData}] = graphLazyQueryMiddleware(GET_CURRENT_USER);
  const [getVenues] = graphLazyQueryMiddleware(GET_VENUES);

  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ showKeyboardForEvent, setShowKeyboardForEvent ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');
  const [ dirtyGlobal, setDirtyGlobal ] = useState(false);

  const eventNameFocusField = focusField;
  const tomorrowStartDate = setMinutes((new Date()).setDate(new Date().getDate() + 1), 60);
  const tomorrowEndDate = new Date(invitationDetails?.locationInfo?.startDate ? getUTCDate(invitationDetails?.locationInfo?.startDate) : tomorrowStartDate);
  tomorrowEndDate.setHours(tomorrowStartDate.getHours() + DEFAULT_EVENT_DURATION_HOURS);

  const initialInvitationStartDate = getUTCDate(invitationDetails?.locationInfo?.startDate);
  const initialInvitationEndDate = getUTCDate(invitationDetails?.locationInfo?.endDate);
  const [ startDateKey, setStartDateKey ] = useState('');
  const [ endDateKey, setEndDateKey ] = useState('');
  const [ sendersNo, setSendersNo ] = useState( invitationDetails?.senders?.length > 1 ? invitationDetails.senders.length : 1);
  const [ giftNo, setGiftNo ] = useState(invitationDetails?.gifts?.length > 0 ? invitationDetails.gifts.length : 0);
  const [ showGift, setShowGift ] = useState(true);
  const [ showSender, setShowSender ] = useState(true);
  const [ showEndDate, setShowEndDate ] = useState(false);
  const [ showEventLink, setShowEventLink ] = useState(true);
  const [ navigationPath, setNavigationPath ] = useState(null);
  const [ initialValues, setInitialValues ] = useState({
    id: localStorage.getItem('activeId'),
    firstName: invitationDetails?.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.firstName) : [''],
    lastName: invitationDetails?.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.lastName) : [''],
    title: invitationDetails?.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.title || '') : [''],
    eventName: invitationDetails?.name ? invitationDetails.name : '',
    venueName: invitationDetails?.locationInfo?.venue?.name ? invitationDetails.locationInfo.venue.name : '',
    location: invitationDetails?.locationInfo?.venue?.displayedAddress ? invitationDetails.locationInfo.venue.displayedAddress : '',
    startDate: format(invitationDetails?.locationInfo?.startDate ? initialInvitationStartDate : tomorrowStartDate, DATE_FORMAT_INPUT),
    startTime: format(invitationDetails?.locationInfo?.startDate ? initialInvitationStartDate : tomorrowStartDate, TIME_FORMAT_INPUT),
    endDate: format(invitationDetails?.locationInfo?.endDate ? initialInvitationEndDate : tomorrowEndDate, DATE_FORMAT_INPUT),
    endTime: format(invitationDetails?.locationInfo?.endDate ? initialInvitationEndDate : tomorrowEndDate, TIME_FORMAT_INPUT),
    aditionalInfo: invitationDetails?.additionalInfo ? invitationDetails.additionalInfo : '',
    giftUrl: invitationDetails?.gifts ? invitationDetails.gifts.map((g) => g.url) : [''],
    giftRegistryNote: invitationDetails?.giftRegistryNote ? invitationDetails.giftRegistryNote : '',
  });
  const [ defaultSender, setDefaultSender ] = useState(invitationDetails?.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.isDefault && item.isDefault) : [true]);
  const [ selectedTags, setSelectedTags ] = useState(['']);
  const [ selectedEvent, setSelectedEvent ] = useState<EventLinkType>({id: invitationDetails?.eventLinkId ?? null, name: invitationDetails?.eventLinkName ?? ''});
  const [ filteredVenues, setFilteredVenues ] = useState([]);
  const [ selectedVenue, setSelectedVenue ] = useState(invitationDetails?.locationInfo?.venue ?? null);
  const [ haveDirtyFields, setHaveDirtyFields ] = useState(false);
  const [ openEventLink, setOpenEventLink ] = useState(false);
  const [ isOpenVenueModal, setIsOpenVenueModal ] = useState(false);
  const [ isHebrew, setIsHebrew ] = useState(false);
  const [ venueModalType, setVenueModalType ] = useState<FORM_TYPE>(null);
  const closeEventLinkDropdown = () => setOpenEventLink(false);
  const openEventLinkDropdown = () => setOpenEventLink(true);

  useEffect(() => {
    getTags({
      variables: {
        filter: {
          type: 'INVITATION'
        }
      }
    });
    fetchInvitations();
    getUser();
    if (focusField && setFocusField) {
      setFocusField(false);
    }
  }, []);

  useEffect(() => {
    if (!invitationData?.invitations?.items || !invitationDetails) {
      return;
    }
    const newShowEventLink = inputValue.length === 0 ?
      invitationData.invitations.items.filter((invitation: InvitationProps) => invitation.id !== invitationDetails.id && invitation.eventLinkId !== invitationDetails.id).length > 0 : true;
    setShowEventLink(newShowEventLink);
  }, [invitationData]);

  const debounceFetch = useCallback(
    debounce((newFilterValue) => fetchInvitations(newFilterValue), DEBOUNCE_SEARCH_WAIT_TIME),
    []
  );

  const fetchInvitations = (filterValue = '') => {
    getInvitations({
      fetchPolicy: 'cache-and-network',
      variables: {
        order: {[ORDER_PROPERTY.NAME]: ORDER_TYPE.ASC.toLocaleUpperCase()},
        filter: {
          textFilter: filterValue,
        },
        take: MAX_NUMBER_OF_ITEMS_TO_GET,
      }
    });
  };

  const invitationSchema = Yup.object().shape({
    firstName: Yup.array().of(Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    lastName: Yup.array().of(Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    title: Yup.array(),
    eventName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('eventName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    venueName: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('venueName'), length: VALIDATION_RESTRICTION.HUNDRED })),
    location: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.THREE_HUNDRED_AND_FIFTY, t('stringPropertyMaxValidation', { propertyName: t('location'), length: VALIDATION_RESTRICTION.THREE_HUNDRED_AND_FIFTY })),
    startDate: Yup.date()
      .required(t('required'))
      .test('minStartDate', null, (startDateValue, schema) => {
        const valueDate = new Date(startDateValue).setHours(0, 0, 0, 0);
        const invitationStartDate = new Date(invitationDetails?.locationInfo?.startDate).setHours(0, 0, 0, 0);
        const currentDate = new Date();
        if (new Date(startDateValue).getFullYear() > MAX_YEAR) {
          return new Yup.ValidationError(t('invalidDateFormat'), null, 'startDate');
        } else if (valueDate !== invitationStartDate && (valueDate < currentDate.setHours(0, 0, 0, 0) ||
        valueDate === currentDate.setHours(0, 0, 0, 0) && format(currentDate, TIME_FORMAT_INPUT) > schema.parent.startTime)) {
          return new Yup.ValidationError(t('dateMustBeInTheFuture'), null, 'startDate');
        }
        return true;
      }),
    startTime: Yup.string()
      .required(t('required'))
      .when('startDate', {
        is: (startDateValue: Date) => new Date(startDateValue) < new Date(),
        then: Yup.string()
          .test('minStartTime', null, (startTimeValue, schema) => {
            const startDateValue = new Date(schema.parent.startDate).setHours(0, 0, 0, 0);
            const invitationStartDate = new Date(invitationDetails?.locationInfo?.startDate).setHours(0, 0, 0, 0);
            const invitationStartTimeString = invitationDetails?.locationInfo?.startDate ? format(new Date(invitationStartDate), TIME_FORMAT_INPUT) : '';
            if ((startDateValue !== invitationStartDate || startTimeValue !== invitationStartTimeString) &&
              startDateValue === new Date().setHours(0, 0, 0, 0) && startTimeValue < format(new Date(), TIME_FORMAT_INPUT)) {
              return new Yup.ValidationError(t('timeMustBeInTheFuture'), null, 'startTime');
            }
            return true;
          }),
      }),
    endDate: showEndDate && Yup.date()
      .nullable()
      .when('startDate', () => {
        return (
          Yup.date()
            .required(t('required'))
            .nullable()
            .test('minEndDate', null, (endDateValue, schema) => {
              return validateEndDate(endDateValue, schema);
            })
        );
      })
      .test('minEndDate', null, (endDateValue, schema) => {
        return validateEndDate(endDateValue, schema);
      }),
    endTime: showEndDate && Yup.string()
      .test('minEndTime', null, (endTimeValue, schema) => {
        if (!showEndDate) {
          return;
        }

        const startDateValue = new Date(schema.parent.startDate).setHours(0, 0, 0, 0);
        const endDateValue = new Date(schema.parent.endDate).setHours(0, 0, 0, 0);
        const invitationEndDate = new Date(invitationDetails?.locationInfo?.endDate).setHours(0, 0, 0, 0);
        const invitationEndTimeString = invitationDetails?.locationInfo?.endDate ? format(new Date(invitationEndDate), TIME_FORMAT_INPUT) : '';
        if (!endTimeValue) {
          return new Yup.ValidationError(t('required'), null, 'endTime');
        } else if ((endDateValue !== invitationEndDate || endTimeValue !== invitationEndTimeString) &&
          endDateValue < new Date().setHours(0, 0, 0, 0) && endTimeValue < format(new Date(), TIME_FORMAT_INPUT)) {
          return new Yup.ValidationError(t('timeMustBeInTheFuture'), null, 'endTime');
        } else if ((endDateValue !== invitationEndDate || endTimeValue !== invitationEndTimeString) && endDateValue >= new Date().setHours(0, 0, 0, 0) &&
          (endDateValue === startDateValue && schema.parent.endTime <= schema.parent.startTime)) {
          return new Yup.ValidationError(t('endTimeBeforeStartTimeError'), null, 'endTime');
        }
        return true;
      }),
    aditionalInfo: Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.THOUSAND, t('stringPropertyMaxValidation', { propertyName: t('additionalInfo'), length: VALIDATION_RESTRICTION.THOUSAND })),
    giftUrl: Yup.array().of(Yup.string()
      .url(t('urlErrorMessage')))
      .max(VALIDATION_RESTRICTION.THOUSAND, t('stringPropertyMaxValidation', { propertyName: t('giftUrl'), length: VALIDATION_RESTRICTION.THOUSAND })),
    giftRegistryNote: Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.THOUSAND, t('stringPropertyMaxValidation', { propertyName: t('giftRegistryNote'), length: VALIDATION_RESTRICTION.THOUSAND })),
  });

  const validateEndDate = (endDateValue: Date, schema: Yup.TestContext<AnyObject>) => {
    if (!showEndDate) {
      return;
    }
    if (!endDateValue) {
      return new Yup.ValidationError(t('required'), null, 'endDate');
    }

    const endDate = endDateValue.setHours(0, 0, 0, 0);
    const startDate = schema.parent.startDate.setHours(0, 0, 0, 0);
    const endTimeValue = format(new Date(schema.parent.endDate), TIME_FORMAT_INPUT);
    const startTimeValue = format(new Date(schema.parent.startDate), TIME_FORMAT_INPUT);
    const startDateValue = new Date(schema.parent.startDate);
    const currentDate = new Date();
    const invitationEndDate = new Date(invitationDetails?.locationInfo?.endDate).setHours(0, 0, 0, 0);
    const invitationStartDate = new Date(invitationDetails?.locationInfo?.startDate).setHours(0, 0, 0, 0);
    const invitationEndTimeString = invitationDetails?.locationInfo?.endDate ? format(new Date(invitationEndDate), TIME_FORMAT_INPUT) : '';
    const invitationStartTimeString = invitationDetails?.locationInfo?.startDate ? format(new Date(invitationStartDate), TIME_FORMAT_INPUT) : '';

    if (endDateValue.getFullYear() > MAX_YEAR) {
      return new Yup.ValidationError(t('invalidDateFormat'), null, 'endDate');
    } else if (endDate !== invitationEndDate &&
      (Number.isNaN(startDateValue.getDate()) || (endDate < currentDate.setHours(0, 0, 0, 0) ||
      endDate === currentDate.setHours(0, 0, 0, 0) && format(currentDate, TIME_FORMAT_INPUT) > schema.parent.endTime))) {
      return new Yup.ValidationError(t('dateMustBeInTheFuture'), null, 'endDate');
    } else if ((endDate !== invitationEndDate || endTimeValue !== invitationEndTimeString) &&
      (endDateValue < startDateValue || endDateValue === startDateValue && schema.parent.endTime <= schema.parent.startTime)) {
      return new Yup.ValidationError(t('endDateBeforeStartDateError'), null, 'endDate');
    } else if (endDate === invitationEndDate && endTimeValue === invitationEndTimeString &&
      (startDate === invitationStartDate || startTimeValue === invitationStartTimeString) &&
      (endDateValue < startDateValue || endDateValue === startDateValue && schema.parent.endTime <= schema.parent.startTime)) {
      return new Yup.ValidationError(t('endDateBeforeStartDateError'), null, 'endDate');
    }
    return true;
  };

  useEffect(() => {
    if (invitationDetails && currentUserData) {

      const firstName = currentUserData.currentUser?.firstName ? currentUserData.currentUser.firstName : [''];
      const lastName = currentUserData.currentUser?.lastName ? currentUserData.currentUser.lastName : [''];
      setInitialValues({
        id: invitationDetails.id ? invitationDetails.id.toString() : localStorage.getItem('activeId'),
        firstName: invitationDetails.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.firstName) : [firstName],
        lastName: invitationDetails.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.lastName) : [lastName],
        title: invitationDetails.senders?.length > 0 ? invitationDetails.senders.map((item: SendersProps) => item.title || '') : [''],
        eventName: invitationDetails.name ? invitationDetails.name : '',
        venueName: invitationDetails.locationInfo?.venue?.name ? invitationDetails.locationInfo.venue.name : '',
        location: invitationDetails.locationInfo?.venue?.displayedAddress ? invitationDetails.locationInfo.venue.displayedAddress : '',
        startDate: format(invitationDetails.locationInfo?.startDate ? initialInvitationStartDate : tomorrowStartDate, DATE_FORMAT_INPUT),
        startTime: format(invitationDetails.locationInfo?.startDate ? initialInvitationStartDate : tomorrowStartDate, TIME_FORMAT_INPUT),
        endDate: format(invitationDetails.locationInfo?.endDate ? initialInvitationEndDate : tomorrowEndDate, DATE_FORMAT_INPUT),
        endTime: format(invitationDetails.locationInfo?.endDate ? initialInvitationEndDate : tomorrowEndDate, TIME_FORMAT_INPUT),
        aditionalInfo: invitationDetails.additionalInfo ? invitationDetails.additionalInfo : '',
        giftUrl: invitationDetails.gifts ? invitationDetails.gifts.map((g) => g.url) : [''],
        giftRegistryNote: invitationDetails.giftRegistryNote ? invitationDetails.giftRegistryNote : '',
      });

      if (invitationDetails.locationInfo && invitationDetails.locationInfo.endDate) {
        setShowEndDate(true);
      }

      setSelectedTags(invitationDetails.tags.length > 0 ? invitationDetails.tags.map((tag: TagType) => tag.name) : []);
      setSelectedEvent(invitationDetails.eventLinkId && {id: invitationDetails.eventLinkId, name: invitationDetails.eventLinkName});
      setDefaultSender(invitationDetails.senders ? invitationDetails.senders.map((item: SendersProps) => item.isDefault && item.isDefault) : [true]);
      setSelectedVenue(invitationDetails.locationInfo?.venue);
    }
  }, [ invitationDetails, currentUserData ]);

  useEffect(() => {
    setStartDateKey(startDateKey === 'startDate' ? '' : 'startDate');
    setEndDateKey(endDateKey === 'endDate' ? '' : 'endDate');
  }, [showEndDate]);

  const submitRef = useRef();
  const navigate = useNavigate();

  const submitForm = (tabName: string) => {
    setNavigationPath(getRouteForNavigation(navLocation.pathname, tabName, routeParams['*']));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    submitRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleSubmit = (values: InvitationDetailsFormValuesType, { setSubmitting }: FormikMethodsTypes) => {
    if (!dirtyGlobal && !haveDirtyFields && !giftUrlsAreChanged(values)) {
      navigate(navigationPath);
      setSubmitting(false);
      return;
    }
    const giftUrls = values.giftUrl.filter((url) => url !== '');
    setGiftNo(giftUrls.length);
    const senders = values.firstName.map((item, index) => {
      return {
        id: invitationDetails.senders.length > 0 ? invitationDetails.senders[index]?.id : null,
        firstName: item,
        lastName: values.lastName[index],
        titleValue: values.title[index],
        isDefault: defaultSender[index]
      };
    });

    updateInvitation({variables: {
      input: {
        id: Number(values.id),
        name: values.eventName,
        tags: selectedTags,
        senders: senders,
        locationInfo: {
          startDate: new Date(`${values.startDate}T${values.startTime}`).toLocaleString(LOCAL_DATE_ARGUMENT, getLocalStringOptions()),
          endDate: values.endDate && values.endTime && showEndDate ? new Date(`${values.endDate}T${values.endTime}`).toLocaleString(LOCAL_DATE_ARGUMENT, getLocalStringOptions()) : null,
          venueId: selectedVenue.id,
        },
        additionalInfo: values.aditionalInfo,
        eventLinkId: selectedEvent?.id,
        gifts: giftUrls,
        giftRegistryNote: values.giftRegistryNote
      }
    }
    }).then(() => {
      setSubmitting(false);
      setHaveDirtyFields(false);
      refetchTags({
        filter: {
          type: 'INVITATION'
        }
      });
      navigate(navigationPath);
    }).catch(() => {
      setSubmitting(false);
      setDirtyGlobal(false);
    });
  };

  useEffect((() => {
    if (!invitationDetails) {
      return;
    }
    setSendersNo(invitationDetails?.senders?.length || 1);
    setGiftNo(invitationDetails.gifts.length > 0 ? invitationDetails.gifts.length : 0);
  }), [invitationDetails]);

  const arraySender: number[] = [];
  const arrayGift: number[] = [];
  for (let i = 0; i < sendersNo; i++) {
    arraySender.push(i);
  }
  for (let i = 0; i < giftNo; i++) {
    arrayGift.push(i);
  }

  useEffect((() => {
    setShowGift(giftNo !== MAX_NUMBER_OF_GIFTS);
  }), [giftNo]);

  useEffect((() => {
    if (sendersNo === MAX_NUMBER_OF_SENDERS) {
      setShowSender(false);
    }
  }), [sendersNo]);

  useEffect((() => {
    if (showKeyboardForEvent) {
      localStorage.getItem('isHebrew') === 'true' ? setIsHebrew(true) : setIsHebrew(false);
      setShowKeyboard(false);
    } else {
      closeEventLinkDropdown();
    }
  }), [showKeyboardForEvent]);

  useEffect((() => {
    showKeyboard && setShowKeyboardForEvent(false);
  }), [showKeyboard]);

  const handleGiftUrlChange = (values: InvitationDetailsFormValuesType) => {
    if (values?.giftUrl.some((gift: string) => gift === '' && values.giftUrl.length > 1)) {
      setHaveDirtyFields(true);
    }
  };

  const renderNewSender = (values: InvitationDetailsFormValuesType) => {
    setSendersNo(sendersNo + 1);
    setDefaultSender([ ...defaultSender, false ]);
    setInitialValues({
      ...values,
      firstName: [ ...values.firstName, '' ],
      lastName: [ ...values.lastName, '' ],
      title: [ ...values.title, '' ],
    });
  };

  const renderNewGift = (values: InvitationDetailsFormValuesType) => {
    setGiftNo(giftNo + 1);
    setDefaultSender([ ...defaultSender, false ]);
    setInitialValues({
      ...values,
      giftUrl: [ ...values.giftUrl, '' ]
    });
  };

  const handleChange = (value: string) => {
    getVenues({
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          textFilter: value,
        },
        order: { name: ORDER_TYPE.ASC.toUpperCase() },
        take: MAX_NUMBER_OF_ITEMS_TO_GET,
      }
    }).then((allVenuesData) => {
      setFilteredVenues(allVenuesData.data.venues.items.map((venue: VenueProps) => {
        return {
          id: venue.id,
          name: venue.name,
          address1: venue.address1,
          address2: venue.address2,
          city: venue.city,
          state: venue.state,
          zipCode: venue.zipCode,
          displayedAddress: venue.displayedAddress,
          status: venue.status,
          createdById: venue.createdById,
        };
      }));
    }
    );
  };

  const selectDefaultSender = (element: number) => {
    setDefaultSender(
      defaultSender.map((item, index: number) => element === index)
    );
  };

  const removeSender = (senderIndex: number, values: InvitationDetailsFormValuesType, errors: FormikErrors<InvitationDetailsFormValuesType>) => {
    const newValues = {...values};
    newValues.firstName.splice(senderIndex, 1);
    newValues.lastName.splice(senderIndex, 1);
    newValues.title.splice(senderIndex, 1);
    clearSenderErrors(senderIndex, errors);

    defaultSender[senderIndex] && selectDefaultSender(0);
    setSendersNo(sendersNo - 1);
  };

  const clearSenderErrors = (senderIndex: number, errors: FormikErrors<InvitationDetailsFormValuesType>) => {
    if (Array.isArray(errors.firstName) && Array.isArray(errors.lastName)) {
      errors.firstName.splice(senderIndex, 1);
      errors.lastName.splice(senderIndex, 1);

      if (errors.firstName.every(element => !element)) {
        clearSenderErrorsFields(errors);
      }
    } else {
      clearSenderErrorsFields(errors);
    }
  };

  const clearSenderErrorsFields = (errors: FormikErrors<InvitationDetailsFormValuesType>) => {
    delete errors['firstName'];
    delete errors['lastName'];
  };

  const removeGiftUrl = (giftIndex: number, values: InvitationDetailsFormValuesType) => {
    const newValues = {...values};
    newValues.giftUrl.splice(giftIndex, 1);
    setGiftNo(giftNo - 1);
    setInitialValues(newValues);
    if (newValues.giftUrl.length === 0) {
      values.giftRegistryNote = '';
    }
  };

  const setValueAndDebounce = (newValue : string) => {
    if (newValue !== inputValue) {
      setInputValue(newValue);
      debounceFetch(newValue);
    }
  };

  const selectedEventIsChanged = () => {
    if (invitationDetails?.eventLinkId === null && selectedEvent === null) {
      return false;
    }
    return invitationDetails && selectedEvent && invitationDetails.eventLinkId !== selectedEvent.id;
  };

  const giftUrlsAreChanged = (values: InvitationDetailsFormValuesType) => {
    if (initialValues?.giftUrl?.length !== values['giftUrl'].length) {
      return true;
    }

    return values['giftUrl'].some((formGiftUrl: string) => {
      return !initialValues?.giftUrl.find((invitationGiftUrl) => invitationGiftUrl === formGiftUrl);
    });
  };

  const selectedSendersChanged = () => {
    if (initialValues.firstName.length === sendersNo || initialValues.lastName.length === sendersNo) {
      return false;
    }

    return invitationDetails?.senders?.length !== sendersNo || JSON.stringify(invitationDetails.senders.map((sender: SendersProps) => sender.isDefault)) !== JSON.stringify(defaultSender);
  };

  const giftsNoChanged = () => {
    if (invitationDetails?.gifts?.length === 0 && giftNo === 1) {
      return false;
    }

    return invitationDetails?.gifts?.length !== giftNo;
  };

  const hasEmptySenderData = (values: InvitationDetailsFormValuesType) => {
    return values.firstName.includes('') || values.lastName.includes('');
  };

  useEffect(() => {
    if (!invitationDetails) {
      return;
    }

    const isDirty = tagsAreChanged(invitationDetails.tags, selectedTags) || selectedEventIsChanged() || selectedSendersChanged() || giftsNoChanged();
    setHaveDirtyFields(isDirty);
  }, [ selectedTags, selectedEvent, initialValues, defaultSender, sendersNo ]);

  useEffect(() => {
    selectedEvent && setInputValue(selectedEvent.name);
  }, [selectedEvent]);

  const removeEndDate = (values: InvitationDetailsFormValuesType, errors: FormikErrors<InvitationDetailsFormValuesType>) => {
    const startDate = new Date(`${values.startDate}T${values.startTime}`);
    const endDate = values.startDate && values.startTime ? startDate.setHours(startDate.getHours() + DEFAULT_EVENT_DURATION_HOURS) : tomorrowEndDate;
    values.endDate = format(invitationDetails?.locationInfo?.endDate && showEndDate ? initialInvitationEndDate : endDate, DATE_FORMAT_INPUT);
    values.endTime = format(invitationDetails?.locationInfo?.endDate && showEndDate ? initialInvitationEndDate : endDate, TIME_FORMAT_INPUT);
    delete errors['endDate'];
    delete errors['endTime'];
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={invitationSchema}
        onSubmit={handleSubmit}>
        {({ dirty, errors, isSubmitting, setErrors, setFieldValue, touched, values }) => {
          return (
            <>
              <PageContent>
                {isMobile ?
                  <MobileTitle sx={{marginTop: '0.5rem !important'}}> {t('selectSenders')}</MobileTitle>:
                  <Title>{t('senders')}</Title>
                }
                <DetailsContainer>
                  <Form ref={submitRef}>
                    <RelativePositionContainer>
                      {arraySender.map((item, index) =>
                        <SenderForm
                          key={item}
                          removeSender={removeSender}
                          index={index}
                          defaultSender={defaultSender}
                          selectDefaultSender={selectDefaultSender}
                          setInputName={setInputName}
                          setShowKeyboard={setShowKeyboard}
                          values={values}
                          errors={errors} />
                      )}
                      {
                        showSender &&
                          <AddNewTextButton
                            onClick={() => {
                              setShowSender(true);
                              renderNewSender(values);
                            }}>+ {t('addNewSender')}
                          </AddNewTextButton>
                      }
                    </RelativePositionContainer>
                    {isMobile ?
                      <MobileTitle> {t('eventInformation')}</MobileTitle>:
                      <Title> {t('information')}</Title>
                    }
                    <InformationContainer>
                      <DateTimeContainer>
                        <InputField
                          boxStyles={{marginBottom: '0.5rem'}}
                          key='eventName'
                          autoFocus={eventNameFocusField}
                          setShowKeyboard={setShowKeyboard}
                          setInputName={setInputName}
                          inputId='eventName'
                          inputName='eventName'
                          label={t('eventName')}
                          type='text' />
                        <InputMessage>{t('eventNameMessage')}</InputMessage>
                      </DateTimeContainer>
                      <BoxSpacingContainer>
                        <AutocompleteCustom
                          key='venueName'
                          handleChange={handleChange}
                          values={values}
                          errors={errors}
                          setErrors={setErrors}
                          list={filteredVenues}
                          selectedItem={selectedVenue}
                          setSelectedItem={setSelectedVenue}
                          setFieldValue={setFieldValue}
                          isOpenVenueModal={isOpenVenueModal}
                          setIsOpenVenueModal={setIsOpenVenueModal}
                          setShowKeyboard={setShowKeyboard}
                          venueModalType={venueModalType}
                          setVenueModalType={setVenueModalType}
                          setInputName={setInputName}
                          inputId='venueName'
                          inputName='venueName'
                          label={t('venueName')}
                          type='text'
                          placeholder={t('defaultVenueNamePlaceholderExample')} />
                      </BoxSpacingContainer>
                      <BoxSpacingContainer>
                        <InputField
                          key='location'
                          disabled={true}
                          inputId='location'
                          inputName='location'
                          label={t('location')}
                          type='text'
                          placeholder={t('defaultVenueLocationPlaceholderExample')} >
                          {checkPermission(dbUser, USER_PERMISSIONS.EDIT_VENUES)&&
                            <AddNewTextButton
                              onClick={() => {
                                setIsOpenVenueModal(true);
                                selectedVenue ? setVenueModalType(FORM_TYPE.EDIT_PRIVATE) : setVenueModalType(FORM_TYPE.CREATE_PRIVATE);
                              }}>
                              {t(selectedVenue ? 'editVenueLabelButton' : 'addVenueLabelButton')}
                            </AddNewTextButton>}
                        </InputField>
                      </BoxSpacingContainer>
                      <DateTimeContainer as={isMobile && errors['endTime'] ? MarginBottomContainer : DateTimeContainer}>
                        <DateContainer>
                          <DateInputContainer sx={{ width: '50%' }}>
                            <FastField as={TextField}
                              key={startDateKey}
                              id='startDate'
                              name='startDate'
                              label={t('startDate')}
                              type='date'
                              inputProps={{
                                min: format(new Date(), DATE_FORMAT_INPUT),
                                max: showEndDate && values.endDate && isBefore(new Date(), new Date(values.endDate)) ? values.endDate : '',
                              }}
                              sx={{ width: '100%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('startDate', e.target.value);
                                setEndDateKey(endDateKey === 'endDate' ? '' : 'endDate');
                              }}/>
                            {errors['startDate'] && <ErrorMessage>{errors['startDate']}</ErrorMessage>}
                          </DateInputContainer>
                          <DateInputContainer sx={{ width: '50%' }}>
                            <FastField as={TextField}
                              id='startTime'
                              name='startTime'
                              label={t('startTime')}
                              type='time'
                              sx={{ width: '100%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}/>
                            {errors['startTime'] && <ErrorMessage>{errors['startTime']}</ErrorMessage>}
                          </DateInputContainer>
                        </DateContainer>
                        {showEndDate &&
                        <DateContainer>
                          <DateInputContainer sx={{ width: '50%' }}>
                            <FastField as={TextField}
                              key={endDateKey}
                              id='endDate'
                              name='endDate'
                              label={t('endDate')}
                              inputProps={{
                                min: new Date(values.startDate) > new Date() && values.startDate ? values.startDate : format(new Date(), DATE_FORMAT_INPUT)
                              }}
                              type='date'
                              sx={{ marginTop: '16px', width: '100%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('endDate', e.target.value);
                                setStartDateKey(startDateKey === 'startDate' ? '' : 'startDate');
                              }}/>
                            {errors['endDate'] && <ErrorMessage>{errors['endDate']}</ErrorMessage>}
                          </DateInputContainer>
                          <DateInputContainer sx={{ width: '50%' }}>
                            <FastField as={TextField}
                              id='endTime'
                              name='endTime'
                              label={t('endTime')}
                              type='time'
                              sx={{ marginTop: '16px', width: '100%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}/>
                            {errors['endTime'] && <ErrorMessage>{errors['endTime']}</ErrorMessage>}
                          </DateInputContainer>
                        </DateContainer>}
                        <AddNewTextButton
                          onClick={() => {
                            removeEndDate(values, errors);
                            setShowEndDate(!showEndDate);
                            setDirtyGlobal(true);
                          }}
                          style={{... !showEndDate && EndTimeLabel, ... isMobile && errors['endTime'] && MarginBottomRemoveLabel}}>
                          {t(showEndDate ? 'removeEndDate' : 'addEndDate')}
                        </AddNewTextButton>
                      </DateTimeContainer>
                      <BoxSpacingContainer>
                        <InputField
                          boxStyles={{marginTop: '0 !important'}}
                          setShowKeyboard={setShowKeyboard}
                          setInputName={setInputName}
                          inputId='aditionalInfo'
                          inputName='aditionalInfo'
                          rows={4}
                          label={t('additionalInfo')}
                          type='text' />
                      </BoxSpacingContainer>
                      {showEventLink &&
                        <AutocompleteInputContainer>
                          <Autocomplete
                            sx={{margin: 0, width: '100%'}}
                            disablePortal
                            inputValue={inputValue}
                            loading={loadingInvitationData}
                            loadingText={t('loading')}
                            open={openEventLink}
                            onOpen={openEventLinkDropdown}
                            onClose={closeEventLinkDropdown}
                            onInputChange={(e : any) => {
                              e?.target && setValueAndDebounce(isEmptyStringOrNull(e.target.textContent) ? e.target.value : e.target.textContent );
                              isHebrew && showKeyboardForEvent && openEventLinkDropdown();
                            }}
                            value={selectedEvent === null ? null : selectedEvent}
                            onFocus={() => {
                              setShowKeyboardForEvent(true);
                            }}
                            onChange={(e, val: EventLinkType) => {
                              setSelectedEvent(val ?? {id: null, name: ''});
                              closeEventLinkDropdown();
                              setShowKeyboardForEvent(false);
                            }}
                            options={ invitationData?.invitations?.items && invitationDetails ?
                              invitationData.invitations.items.filter((i: InvitationProps) => i.id !== invitationDetails.id && i.eventLinkId !== invitationDetails.id).map((item: InvitationProps) => {
                                return {name: item.name, id: item.id, eventLinkName: item.eventLinkName};
                              }) : []}
                            getOptionLabel={(option: EventLinkType) => option.name ? `${option.name} ${option.eventLinkName ? ` > ${option.eventLinkName}` : ''}` : ''}
                            renderOption={(props, option: EventLinkType) => (
                              <li {...props} key={option.id}>
                                <CustomTooltip theme={themeDefault.overflowText} text={option.name ? `${option.name} ${option.eventLinkName ? ` > ${option.eventLinkName}` : ''}` : ''} />
                              </li>
                            )}
                            renderInput={(params) => <TextField {...params} placeholder={t('eventLink')} />}/>
                        </AutocompleteInputContainer>}
                      <GiftUrl
                        removeGiftUrl={removeGiftUrl}
                        arrayGift={arrayGift}
                        setInputName={setInputName}
                        setShowKeyboard={setShowKeyboard}
                        showKeyboard={showKeyboard}
                        values={values}
                        handleGiftUrlChange={handleGiftUrlChange}/>
                      <RelativePositionContainer>
                        <AddNewTextButtonFixedBottom
                          onClick={() => {
                            setShowSender(true);
                            renderNewGift(values);
                            setHaveDirtyFields(true);
                          }}
                          sx={{margin: '7px'}}>
                          {showGift && (giftNo === 0 ? t('addGifyRegistry') : t('addAnotherGifyRegistry'))}
                        </AddNewTextButtonFixedBottom>
                      </RelativePositionContainer>
                      {giftNo > 0 &&
                      <InputField
                        boxStyles={{marginTop: '0 !important', marginBottom: '24px'}}
                        setShowKeyboard={setShowKeyboard}
                        errorAbsolutePosition={true}
                        setInputName={setInputName}
                        inputId='giftRegistryNote'
                        inputName='giftRegistryNote'
                        rows={4}
                        label={t('giftRegistryNote')}
                        type='text' />}
                      <AutocompleteInputContainer>
                        <TagSelector
                          showKeyboardParent={showKeyboard}
                          showKeyboardForEvent={showKeyboardForEvent}
                          setShowKeyboardParent={setShowKeyboard}
                          setShowKeyboardForEvent={setShowKeyboardForEvent}
                          showText={false}
                          tagData={data ?? {tags: [{id: 0, name: '0'}]}}
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags} />
                      </AutocompleteInputContainer>
                    </InformationContainer>
                    { showKeyboard &&
                    <VirtualKeyboard
                      setShowKeyboard={setShowKeyboard}
                      initialValues={values}
                      setFieldValue={setFieldValue}
                      keyboardRef={keyboardRef}
                      inputName={inputName}/>
                    }
                    { showKeyboardForEvent &&
                    <VirtualKeyboard
                      setShowKeyboard={setShowKeyboardForEvent}
                      initialValues={{
                        'eventLink': inputValue
                      }}
                      setStandaloneItem={setValueAndDebounce}
                      keyboardRef={keyboardRef}
                      inputName={'eventLink'}/>
                    }
                  </Form>
                </DetailsContainer>
              </PageContent>
              <PageButtonsContainer>
                <PagePrevButton
                  disabled={(Object.keys(touched).length !== 0 && Object.keys(errors).length > 0) || isSubmitting || hasEmptySenderData(values)}
                  onClick={() => {
                    setDirtyGlobal(dirty || dirtyGlobal || selectedEventIsChanged());
                    submitForm('invitation');
                  }}
                  variant='contained'>
                  {t(isMobile ? 'back' : 'prevInvitation')}
                </PagePrevButton>
                <PageNextButton
                  disabled={(Object.keys(touched).length !== 0 && Object.keys(errors).length > 0) || isSubmitting || hasEmptySenderData(values)}
                  onClick={() => {
                    setDirtyGlobal(dirty || dirtyGlobal || selectedEventIsChanged());
                    submitForm('design');
                  }}
                  variant='outlined'>
                  {t(isMobile ? 'next' : 'nextDesign')}
                </PageNextButton>
              </PageButtonsContainer>
              <Prompt when={(dirty || haveDirtyFields) && !dirtyGlobal} options={{
                title: t('leavePage'),
                message: t('unsavedChanges'),
                buttons: [
                  {
                    label: 'Confirm',
                    continue: true
                  },
                  {
                    label: 'Cancel',
                  }
                ],
              }} />
            </>
          );
        }}
      </Formik>
    </>
  );
};