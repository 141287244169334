import { CSSProperties } from 'react';
import { Box, Button, Typography, Dialog, DialogContent } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { Form } from 'formik';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  maxWidth: '100%',
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const DialogStyled = styled(Dialog)(
  () => ({
    textAlign: 'center',
    '& .MuiPaper-root': {
      minWidth: '40vw',
      padding: '1rem',
    }
  }),
);

export const DialogContentStyled = styled(DialogContent)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      ...themeDefault.overflowText,
      maxWidth: '100%',
    }
  }),
);

export const TitleLabel = styled(Typography)(
  () => ({
    ...themeDefault.overflowText,
    fontWeight: 'bold',
    marginBottom: '1rem',
    maxWidth: '100%',
  }),
);

export const FormStyled = styled(Form)(
  () => ({
    textAlign: 'start',
    width: '100%',
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme, style }) => ({
    ...themePublic.submitButton as CSSProperties,
    marginTop: '1rem',
    padding: '8px 48px',
    width: style.width,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '100% !important',
    },
  }),
);

export const TitleName = styled(Typography)(
  () => ({
    ...themeDefault.overflowText,
    maxWidth: '100%',
    width: '60%',
  }),
);

export const ColoredTypography = styled('span')(
  (props) => ({
    ...themeDefault.overflowText,
    maxWidth: '100%',
    color: props.color as string,
    [props.theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
  }),
);

export const DetailsTypography = styled(Typography)(
  () => ({
    ...themeDefault.overflowText,
    marginTop: '1rem',
    maxWidth: '100%',
    width: '60%',
  }),
);

export const InfoContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  }),
);

export const InfoTypography = styled(Typography)(
  () => ({
    color: themeDefault.palette.darkGreyColor,
    marginTop: '1rem',
    width: '100%',
  }),
);
