import { gql } from '@apollo/client';

export const ADD_EVITE_TEMPLATE = gql`
mutation AddEviteTemplate ($input: EviteTemplateInput) {
  addEviteTemplate (input: $input) {
    id
    name
    contentJson
    isDefault
    position
    image {
      id,
      name
      url
      thumbnailUrl
      key
    }
  }
}
`;

export const ADD_INVITATION_TEMPLATE = gql`
mutation AddInvitationTemplate ($input: InvitationTemplateInput) {
  addInvitationTemplate (input: $input) {
    id
    name
    createdAt
    description
  }
}
`;

export const DELETE_INVITATION_TEMPLATE = gql`
mutation RemoveInvitationTemplate ($templateId: Long!) {
  removeInvitationTemplate (templateId: $templateId)
}
`;

export const DUPLICATE_INVITATION_TEMPLATE = gql`
mutation DuplicateInvitationTemplate ($templateId: Long!) {
  duplicateInvitationTemplate (templateId: $templateId) {
    id
    name
    createdAt
    description
  }
}
`;

export const REMOVE_EVITE_TEMPLATE = gql`
mutation RemoveEviteTemplate ($id: Long!) {
  removeEviteTemplate (id: $id)
}
`;

export const UPDATE_EVITE_TEMPLATE = gql`
mutation UpdateEviteTemplate ($input: UpdateEviteTemplateInput){
  updateEviteTemplate (input: $input){
    id
    name
    contentJson
    isDefault
    position
    image {
      id
      name
      url
      thumbnailUrl
      key
    }
  }
}
`;

export const UPDATE_INVITATION_TEMPLATE = gql`
mutation UpdateInvitationTemplate ($input: UpdateInvitationTemplateInput) {
  updateInvitationTemplate (input: $input) {
    id
    name
    description
    typeId
    status
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    colors
    tags {
      name
    }
    evites {
      id
      name
      isDefault
      position
      contentJson
      image {
        id,
        name
        url
        thumbnailUrl
        key
      }
    }
  }
}
`;

export const UPDATE_INVITATION_TEMPLATE_NAME = gql`
mutation UpdateInvitationTemplateName($input: UpdateInvitationTemplateNameInput) {
  updateInvitationTemplateName(input: $input) {
    id
    name
  }
}
`;

export const UPDATE_INVITATION_TEMPLATE_ENVELOPE = gql`
mutation UpdateInvitationTemplateEnvelope ($input : EnvelopeInput) {
  updateInvitationTemplateEnvelope (input: $input ) {
   id
   contentJson
  }
}
`;

export const UPDATE_SELECTED_EVITE_TEMPLATES = gql`
mutation updateSelectedEviteTemplates ($input: UpdateSelectedEviteTemplatesInput) {
  updateSelectedEviteTemplates (input: $input) {
    id
    isDefault
    position
  }
}
`;
