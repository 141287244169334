import React from 'react';
import {
  Box,
  TableRow,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomTooltip } from 'components';
import { MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP, filterRsvpStatus, responseColorDetect } from 'helpers';
import { ContactType, EviteResponseType, GuestRSVPType, GuestType, InvitationResponseRsvpType, RsvpContactResponseType } from 'types';

import {
  ContactNameText, TableStyled, GuestNameText,
  PersonalMessage, RowContainerRound,
  RowContainerWithoutBorder, StatusIcon, TableCellStyled,
  TableCellWithoutBorderStyle, TableContainer, ThemeOverlappedText,
} from './ViewResponseModal.styled';

type ViewResponseModalProps = {
  guest: GuestType,
  guestRsvp: GuestRSVPType,
  isResponseValid: boolean,
};

export const ViewResponseModal = ({guest, guestRsvp, isResponseValid}: ViewResponseModalProps) => {
  const [t] = useTranslation();

  const getTooltipText = (text: string) => {
    return text.length > MAX_NUMBER_OF_CHARACTERS_FOR_TOOLTIP ? text : '';
  };

  const getContactText = (contact: ContactType) => {
    return (contact.firstName || '') + ' ' + contact.lastName;
  };

  return (

    <>
      {isResponseValid ?
        <>
          <TableContainer>
            <Tooltip enterTouchDelay={0}
              title={getTooltipText(getContactText(guestRsvp?.contact))}>
              <GuestNameText>- {getContactText(guestRsvp?.contact)} -</GuestNameText>
            </Tooltip>
            {filterRsvpStatus(guestRsvp?.contactResponses).map((response: RsvpContactResponseType, contactIndex: number) =>
              <Box key={contactIndex} component={guestRsvp?.contactResponses.length === 1 ? RowContainerWithoutBorder : RowContainerRound}>
                <TableStyled>
                  {guestRsvp?.contactResponses.length > 1 &&
                    <Tooltip enterTouchDelay={0} title={getTooltipText(getContactText(response.contact))}>
                      <ContactNameText>{getContactText(response.contact)}</ContactNameText>
                    </Tooltip>}
                  {response?.invitationResponses.map((invitationResponse: InvitationResponseRsvpType) =>
                    invitationResponse.responses.map((eviteResponse: EviteResponseType, index: number) => {
                      return (
                        <>
                          <TableRow key={index} selected={false}>
                            <TableCellStyled style={{... index !== 0 && TableCellWithoutBorderStyle}}>
                              {index === 0 && <CustomTooltip theme={ThemeOverlappedText} text={invitationResponse.invitation.name} />}
                            </TableCellStyled>
                            <TableCellStyled>
                              <CustomTooltip theme={ThemeOverlappedText} text={eviteResponse.evite.name} />
                            </TableCellStyled>
                            <TableCellStyled>
                              <StatusIcon style={{backgroundColor: responseColorDetect(eviteResponse.status)}} />
                              <CustomTooltip theme={ThemeOverlappedText} text={t(eviteResponse.status)} />
                            </TableCellStyled>
                          </TableRow>
                        </>);
                    })
                  )}
                </TableStyled>
              </Box>
            )}
          </TableContainer>
          {guest.personalMessage &&
            <PersonalMessage
              disabled
              value={guest.personalMessage}
              multiline
              rows={3}
              inputProps={{ maxLength: 500 }}
              maxRows={3} />
          }
        </> :
        <GuestNameText>{t('invitationNotSent')}</GuestNameText>}
    </>
  );
};
