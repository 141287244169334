import { gql } from '@apollo/client';

export const UPDATE_VENUE = gql`
mutation updateVenue ($input: UpdateVenueInput!) {
    updateVenue(input: $input) {
        id
        name
        address1
        address2
        city
        state
        zipCode
        displayedAddress
        status
        createdById
    }
  }
`;

export const ADD_VENUE = gql`
mutation addVenue ($input: VenueInput) {
    addVenue (input: $input) {
      id
      name
      address1
      address2
      city
      state
      zipCode
      displayedAddress
      status
      createdById
  }
}
`;

export const DELETE_VENUE = gql`
mutation deleteVenue ($venueId: Long!) {
    deleteVenue (venueId: $venueId) 
}
`;

export const BULK_DELETE_VENUE = gql`
mutation bulkDeleteVenue ($venueIds: [Long!]) {
  bulkDeleteVenue (venueIds: $venueIds) 
}
`;
