import React, { useState } from 'react';
import {
  FormControl, MenuItem, Select, Typography
} from '@mui/material';
import { FastField } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components';
import { FORM_TYPE } from 'enums';
import { isEmptyStringOrNull } from 'helpers';
import { GET_TITLES, graphQueryMiddleware } from 'services';
import { ContactType, TitleType } from 'types';

import {
  AddNewContainer, AddNewLabel, CityInputContainer, CloseIconStyled, DividerFromBottom,
  InputContainer, InputContainerSecond, InputGroup, InputRow, StreetInputContainer, TitleContainer
} from './ContactsForm.styled';

type ContactsFormProps = {
  contact?: ContactType
  handleAddNew?: () => void
  setShowKeyboard?: (show: boolean) => void,
  setInputName?: (namee: string) => void,
  index: number
  type: FORM_TYPE.CREATE | FORM_TYPE.EDIT,
  removeMember?: () => void
  showAddNewText?: boolean
}
export const ContactsForm = ({ handleAddNew, showAddNewText, removeMember, index, type, setShowKeyboard = null, setInputName = null, contact = null }: ContactsFormProps) => {
  const [t] = useTranslation();

  const [ showAddres, setShowAddress ] = useState(type === FORM_TYPE.EDIT && !(isEmptyStringOrNull(contact?.state) && isEmptyStringOrNull(contact?.street) && isEmptyStringOrNull(contact?.zipCode) && isEmptyStringOrNull(contact?.city)));
  const { data: titlesData } = graphQueryMiddleware(GET_TITLES);

  return (
    <>
      <InputRow>
        {index > 0 && <CloseIconStyled onClick={removeMember}/>}
        <TitleContainer>
          <Typography>{t('title')}</Typography>
          {titlesData &&
          <FormControl>
            <FastField as={Select} defaultValue={''} name={`title[${index}]`} sx={{ minWidth: '70px'}}>
              <MenuItem value=''>
                <em>{t('none')}</em>
              </MenuItem>
              {titlesData?.titles.map((item: TitleType) =>
                <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
              )}
            </FastField>
          </FormControl>
          }
        </TitleContainer>
        <InputGroup>
          <InputContainer>
            <Typography>{t('firstName')}</Typography>
            <InputField
              setShowKeyboard={setShowKeyboard}
              setInputName={setInputName}
              inputId={`firstName[${index}]`}
              inputName={`firstName[${index}]`}
              isError={null}
              label=''
              type='text' />
          </InputContainer>
          <InputContainer>
            <Typography>{t('lastName')}</Typography>
            <InputField
              setShowKeyboard={setShowKeyboard}
              setInputName={setInputName}
              inputId={`lastName[${index}]`}
              inputName={`lastName[${index}]`}
              isError={null}
              label=''
              type='text' />
          </InputContainer>
        </InputGroup>
      </InputRow>
      <InputContainerSecond>
        <InputGroup>
          <InputContainer>
            <Typography>{t('email')}</Typography>
            <InputField
              setShowKeyboard={setShowKeyboard}
              setInputName={setInputName}
              inputId={`email[${index}]`}
              inputName={`email[${index}]`}
              isError={null}
              label=''
              type='text' />
          </InputContainer>
          <InputContainer>
            <Typography>{t('phone')}</Typography>
            <InputField
              setShowKeyboard={setShowKeyboard}
              setInputName={setInputName}
              inputId={`phoneNumber[${index}]`}
              inputName={`phoneNumber[${index}]`}
              isError={null}
              label=''
              type='text' />
          </InputContainer>
        </InputGroup>
      </InputContainerSecond>
      {showAddres &&
        <>
          <InputContainerSecond>
            <InputGroup>
              <StreetInputContainer>
                <Typography>{t('street')}</Typography>
                <InputField
                  setShowKeyboard={setShowKeyboard}
                  setInputName={setInputName}
                  inputId={`street[${index}]`}
                  inputName={`street[${index}]`}
                  isError={null}
                  label=''
                  type='text' />
              </StreetInputContainer>
            </InputGroup>
          </InputContainerSecond>
          <InputContainerSecond>
            <InputGroup>
              <CityInputContainer>
                <Typography>{t('city')}</Typography>
                <InputField
                  setShowKeyboard={setShowKeyboard}
                  setInputName={setInputName}
                  inputId={`city[${index}]`}
                  inputName={`city[${index}]`}
                  isError={null}
                  label=''
                  type='text' />
              </CityInputContainer>
              <CityInputContainer>
                <Typography>{t('state')}</Typography>
                <InputField
                  setShowKeyboard={setShowKeyboard}
                  setInputName={setInputName}
                  inputId={`state[${index}]`}
                  inputName={`state[${index}]`}
                  isError={null}
                  label=''
                  type='text' />
              </CityInputContainer>
              <CityInputContainer>
                <Typography>{t('zipCode')}</Typography>
                <InputField
                  setShowKeyboard={setShowKeyboard}
                  setInputName={setInputName}
                  inputId={`zipCode[${index}]`}
                  inputName={`zipCode[${index}]`}
                  isError={null}
                  label=''
                  type='text' />
              </CityInputContainer>
            </InputGroup>
          </InputContainerSecond>
        </>
      }
      <AddNewContainer>
        {showAddres ? <Typography /> : <AddNewLabel onClick={() => setShowAddress(true)}>+ {t('addAddress')}</AddNewLabel>}
        {showAddNewText && <AddNewLabel onClick={handleAddNew}>+ {t('addNewMember')}</AddNewLabel>}
      </AddNewContainer>
      <DividerFromBottom/>
    </>

  );
};
