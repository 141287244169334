import React, { ReactNode } from 'react';

import { IconButton } from '@mui/material';

type CanvasDeleteButtonProps = {
  showButton: boolean,
    handleAction: () => void,
    elementId: string,
    children: ReactNode
};

export const CanvasActionButton = ({ showButton, handleAction, elementId, children }: CanvasDeleteButtonProps) => {
  return (
    showButton &&
        <IconButton
          id={elementId}
          onClick={handleAction}
          color='inherit'>
          {children}
        </IconButton>
  );
};
