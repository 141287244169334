import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const HeaderContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '158px',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: '100%',
      }
    }
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.pageWithPaperDivider as CSSProperties,
    width: '240px',
  }),
);

export const HeaderLable = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'uppercase',
  }),
);
