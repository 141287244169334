/* eslint-disable no-shadow */

export enum ACTION_MENU_INVIATTION_TEMPLATES {
  EDIT = 'Edit',
  DUPLICATE = 'Duplicate',
  PUBLISH = 'Publish',
  UNPUBLISH = 'Unpublish',
  DELETE = 'Delete',
}

export enum ACTION_MENU_PLACEMENT {
  TOP = 'top',
  TOP_END = 'top-end',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
}

export enum ACTION_MENU_VENUES {
  EDIT = 'Edit',
  MAKE_PRIVATE = 'makePrivate',
  MAKE_PUBLIC = 'makePublic',
  DELETE = 'Delete',
}

export enum ACTIVITY_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum ADD_TO_CALENDAR_IDS {
  GIFT_REGISTRY_CALENDAR_BUTTON = 'giftRegistryCalendarButton',
  RSVP_CALENDAR_BUTTON = 'RSVPCalendarButton',
}

export enum ANIMATION_ELEMENTS_IDS {
  ANIMATION_CONTENT = 'animationContent',
  ANIMATION_CONTENT_CONTAINER = 'animationContentContainer',
  ENVELOPE_BACK = 'envelopeBack',
  ENVELOPE_BACK_JSON = 'envelopeBackJson',
  ENVELOPE_CONTAINER = 'EnvelopeContainer',
  ENVELOPE_FRONT = 'envelopeFront',
  ENVELOPE_FRONT_CONTAINER = 'envelopeFrontContainer',
  ENVELOPE_INSIDE = 'envelopeInside',
  ENVELOPE_TOP = 'envelopeTop',
  ENVELOPE_TOP_CONTAINER = 'envelopeTopContainer',
  ENVELOPE_TOP_INSIDE = 'envelopeTopInside',
  INVITATION_CONTAINER = 'invitationContainer',
  INVITATION_CONTENT = 'invitationContent',
  INVITATION_CORE = 'invitationCore',
}

export enum BOUNDARY_WINDOW_WIDTH {
  SMALL = 1280,
  MEDIUM = 1531
}

export enum CANVAS_ELEMENTS_IDS {
  PLACEHOLDER_MENU = 'placeholder-menu',
  VIRTUAL_KEYBOARD = 'keyboardComponentId',
  DELETE_BUTTON = 'deleteButton',
  EDIT_TEXT_BUTTON = 'editTextButton'
}

export enum DEFAULT_EVITE_SIZE {
  WIDTH = 1536,
  HEIGHT = 2048
}

export enum EDITOR_CONTAINER_ELEMENTS_IDS {
  COLOR_PICKER_BUTTON = 'colorPickerButton',
  FONT_COLOR_PICKER = 'fontColorPicker',
  BOLD_BUTTON = 'boldButton',
  ITALIC_BUTTON = 'italicButton',
  UNDERLINE_BUTTON = 'underlineButton',
  LEFT_BUTTON = 'leftButton',
  RIGHT_BUTTON = 'rightButton',
  CENTER_BUTTON = 'centerButton',
  JUSTIFY_BUTTON = 'justifyButton'
}

export enum ENVELOPE_SIZE {
  WIDTH = 1280,
  HEIGHT = 720
}

export enum ENVELOPE_STAMPS_PADDING {
  LEFT = 50,
  TOP = 40,
}

export enum FONT_PROPERTY {
  FONT_FAMILY = 'fontFamily',
  FILL = 'fill',
  FONT_SIZE = 'fontSize',
  FONT_WEIGHT = 'fontWeight',
  FONT_STYLE = 'fontStyle',
  UNDERLINE = 'underline',
  TEXT_ALIGN = 'textAlign',
  LINE_HEIGHT = 'lineHeight',
  CHAR_SPACING = 'charSpacing',
}

export enum FORM_TYPE {
  CREATE = 'create',
  CREATE_PRIVATE = 'createPrivate',
  EDIT = 'edit',
  EDIT_PRIVATE = 'editPrivate',
}

export enum INVITATION_STATUS {
  UNSENT = 'Unsent',
  SENT = 'Sent',
}

export enum INVITATION_TEMPLATE_PARAMS {
  SELECTED_TAGS = 'selectedTags',
  SELECTED_TYPES = 'selectedTypes',
  SELECTED_STATUS = 'selectedStatus',
  SELECTED_COLORS = 'selectedColors',
  SORT_BY = 'sortBy',
  ROWS_PER_PAGE = 'rowsPerPage',
  PAGE = 'page',
  ORDER_BY = 'orderBy'
}

export enum INVITATION_TEMPLATE_STATUS {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

export enum KEYBOARD_BUTTONS {
  TAB = 'Tab',
  ESCAPE = 'Escape'
}

export enum NUMBER_OF_ITEMS_PER_PAGE {
  TEN = 10,
  TWENTY_FIVE = 25,
  FIFTY = 50,
  HUNDRED = 100,
}

export enum NUMBER_OF_INVITATION_TEMPLATES_PER_ROW {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export enum NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES {
  ONE = 1,
  TWO = 2,
  FIVE = 5,
  TEN = 10
}

export enum ORDER_PROPERTY {
  CONTACT_LAST_NAME = 'Contact.LastName',
  CREATED_AT = 'createdAt',
  LAST_NAME = 'lastName',
  NAME = 'name',
  TIMES_USED = 'timesUsed',
}

export enum ORDER_TYPE {
  ASC = 'asc',
  DESC = 'desc',
}

export enum PASSWORD_PAGE_TYPE {
  CHANGE_PASSWORD = 'changePassword',
  SET_PASSWORD = 'setPassword',
}

export enum RESPONSE_STATUS {
  NOT_SENT = 'Unsent',
  PENDING = 'Sent-NotOpened',
  NOT_ANSWERED = 'Opened-NoResponse',
  ATTENDING = 'Attending',
  NOT_ATTENDING = 'NotAttending',
  MAZEL_TOW_ONLY = 'MazalTovOnly',
}

export enum RESPONSE_STATUS_COLORS {
  NOT_SENT = '#CED1D8',
  PENDING = '#DF76A3',
  NOT_ANSWERED = '#7182AB',
  ATTENDING = '#A5416F',
  NOT_ATTENDING = '#33415E',
  MAZEL_TOW_ONLY = '#E380D4',
}

export enum SLIDER_ELEMENTS_IDS {
  TEXT_SPACING_SLIDER = 'textSpacingSlider',
  LINE_SPACING_SLIDER = 'lineSpacingSlider',
  TEXT_SIZE_SLIDER = 'textSizeSlider',

  TEXT_SPACING_SLIDER_UP_ARROW = 'textSpacingSliderUpArrow',
  TEXT_SPACING_SLIDER_DOWN_ARROW = 'textSpacingSliderDownArrow',
  LINE_SPACING_SLIDER_UP_ARROW = 'lineSpacingSliderUpArrow',
  LINE_SPACING_SLIDER_DOWN_ARROW = 'lineSpacingSliderDownArrow',
  TEXT_SIZE_SLIDER_UP_ARROW = 'textSizeSliderUpArrow',
  TEXT_SIZE_SLIDER_DOWN_ARROW = 'textSizeSliderDownArrow',

  UP_ARROW = 'UpArrow',
  DOWN_ARROW = 'DownArrow',

  OUTLINED_BASIC = 'outlined-basic',
}

export enum SUPPORTED_CALENDARS {
  APPLE = 'Apple',
  GOOGLE = 'Google',
  ICAL = 'iCal',
  MICROSOFT_365 = 'Microsoft365',
  MICROSOFT_TEAMS = 'MicrosoftTeams',
  OUTLOOK_COM = 'Outlook.com',
  YAHOO = 'Yahoo',
}

export enum TABLE_TYPE {
  USERS_TABLE = 'UsersTable',
  CONTACTS_TABLE = 'ContactsTable',
  VENUES_TABLE = 'VenuesTable',
  INVITATION_TEMPLATES_TABLE = 'InvitationTemplatesTable',
  INVITATIONS_TABLE = 'InvitationsTable',
  GUEST_TABLE = 'GuestsTable',
}

export const TRACK_CHART_LABELS_CONFIG = [
  RESPONSE_STATUS.NOT_SENT,
  RESPONSE_STATUS.PENDING,
  RESPONSE_STATUS.NOT_ANSWERED,
  RESPONSE_STATUS.ATTENDING,
  RESPONSE_STATUS.NOT_ATTENDING,
  RESPONSE_STATUS.MAZEL_TOW_ONLY,
];

export enum USER_PERMISSIONS {
  VIEW_USERS = 'ViewUsers',
  EDIT_USERS = 'EditUsers',
  VIEW_CONTACT = 'ViewContact',
  EDIT_CONTACT = 'EditContact',
  VIEW_INVITATION_TEMPLATE = 'ViewInvitationTemplate',
  EDIT_INVITATION_TEMPLATE = 'EditInvitationTemplate',
  CAN_DO_IMPERSONIFICATION = 'CanDoImpersonification',
  VIEW_INVITATION = 'ViewInvitation',
  EDIT_INVITATION = 'EditInvitation',
  VIEW_ALL_INVITATIONS = 'ViewAllInvitations',
  VIEW_VENUES = 'ViewVenues',
  EDIT_VENUES = 'EditVenues'
}

export enum USER_ROLES {
  USER = 'User',
  ADMIN = 'Admin',
}

export enum USER_SETTINGS_CONFIG {
  USERS_TABLE = 'UsersTable',
  CONTACTS_TABLE = 'ContactsTable',
  VENUES_TABLE = 'VenuesTable',
  INVITATION_TEMPLATES_TABLE = 'InvitationTemplatesTable',
  INVITATIONS_TABLE = 'InvitationsTable',
  EDIT_GUEST_PREVIEW_PAGE = 'EditGuestPreviewPage',
  GUEST_TABLE_SEND_PAGE = 'GuestTableSendPage',
  IMPORT_EXISTING_CONTACTS_SEND_PAGE = 'ImprtExistingContactsSendPage',
  GUEST_TABLE_TRACK_PAGE = 'GuestTableTrackPage',
}

export enum USER_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum VALIDATION_RESTRICTION {
  ONE = 1,
  EIGHT = 8,
  FIFTY = 50,
  HUNDRED = 100,
  HUNDRED_AND_FIFTY = 150,
  THREE_HUNDRED_AND_FIFTY = 350,
  FOUR_HUNDRED = 400,
  THOUSAND = 1000,
  FOUR_THOUSAND = 4000,
}

export enum VENUE_STATUS {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}
