import React, { useEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { ActionMenu, ConfirmationModal, UserModal } from 'components';
import { FORM_TYPE, USER_PERMISSIONS, USER_STATUS } from 'enums';
import { calculateMenuPlacement, checkPermission, isUserActive } from 'helpers';
import { DEACTIVATE_USER, ACTIVATE_USER, graphMutationMiddleware } from 'services';
import { useVenuesStore } from 'store';
import { UserProps } from 'types';
import { themePublic } from 'themeDefault';

import {
  Edit as EditIcon,
  PersonRemove as PersonRemoveIcon,
  PersonAdd as PersonAddIcon,
  PersonOff as ImpersonateIcon,
} from '@mui/icons-material';

type OptionCellProps = {
  user: UserProps
}

export const UserOptionCell: FC<OptionCellProps> = ({user}) => {
  const [t] = useTranslation();
  const { changeExecuteRefetch } = useVenuesStore();

  const [ impersonateModal, setImpersonateModal ] = useState(false);
  const [ handleActivateModal, setHandleActivateModal ] = useState(false);
  const [ editUserModal, setEditUserModal ] = useState(false);
  const [ actionMenuProps, setActionMenuProps ] = useState([]);
  const [ changeUserStatus, { loading: userStatusLoading }] = graphMutationMiddleware(user.status === USER_STATUS.ACTIVE.toUpperCase() ? DEACTIVATE_USER : ACTIVATE_USER);

  useEffect(() => {
    const initialState = [
      {
        id: 0,
        title: t('edit'),
        icon: EditIcon,
        functionHandler: () => openEditUserModal()
      },
      {
        id: 1,
        title: t(user.status === USER_STATUS.ACTIVE.toUpperCase() ? 'deactivate' : 'activate'),
        icon: user.status === USER_STATUS.ACTIVE.toUpperCase() ? PersonRemoveIcon : PersonAddIcon,
        functionHandler: () => openActivateUserModal()
      }
    ];
    if (!(checkPermission(user, USER_PERMISSIONS.CAN_DO_IMPERSONIFICATION)) &&
      user.status === USER_STATUS.ACTIVE.toUpperCase()) {
      setActionMenuProps([
        ...initialState,
        {
          id: 2,
          title: t('impersonate'),
          icon: ImpersonateIcon,
          functionHandler: () => setImpersonateModal(true)
        }]);
    } else {
      setActionMenuProps(initialState);
    }
  }, [user]);

  const handleUserActivation = () => {
    changeUserStatus({variables: { email: user.email}}).then(() => changeExecuteRefetch());
    setHandleActivateModal(false);
  };

  const handleUserImpersonate = () => {
    localStorage.setItem('userId', user.id.toString());
    setImpersonateModal(false);
    window.location.reload();
  };

  const openEditUserModal = () => {
    setEditUserModal(true);
  };

  const openActivateUserModal = () => {
    setHandleActivateModal(true);
  };

  return (
    <>
      <ActionMenu propsData={actionMenuProps} disablePortal={isMobile} iconTheme={isMobile && themePublic.actionMenuIconStyle} menuPlacement={calculateMenuPlacement(isMobile)}/>
      <ConfirmationModal
        loading={false}
        isOpen={impersonateModal}
        handleClose={() => setImpersonateModal(false)}
        handleConfirm={handleUserImpersonate}
        confimMessage={`${t('impersonateConfirmMessage')} ${user.firstName.toString()} ${user.lastName.toString()}`}/>
      <ConfirmationModal
        loading={userStatusLoading}
        isOpen={handleActivateModal}
        handleClose={() => setHandleActivateModal(false)}
        handleConfirm={handleUserActivation}
        confimMessage={`${t(isUserActive(user) ? 'deactivateConfirmMessage' : 'activateConfirmMessage')} ${user.firstName.toString()} ${user.lastName.toString()}`}/>
      <UserModal
        isOpen={editUserModal}
        type={FORM_TYPE.EDIT}
        user={user}
        handleClose={() => setEditUserModal(false)}/>
    </>
  );
};