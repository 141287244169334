import { Box, Button, Divider, MenuItem, TableCell, TableHead, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const ImageTableCellStyle = {
  fontSize: '0.875rem !important',
  minWidth: '5vw',
  paddingLeft: 0,
  paddingRight: 0,
  zIndex: '0 !important',
};

export const LastTableCellStyle = {
  ...themePublic.lastTableCell as CSSProperties,
  width: '3vw',
};

export const TitleHeaderTableCellStyle = {
  ...themeDefault.overflowText,
  minWidth: '6vw',
  maxWidth: '6vw',
  zIndex: '0 !important',
  fontSize: '0.875rem !important',
  fontWeight: 'bold',
  '& p': {
    minWidth: 'initial'
  }
};

export const TableBulkOptionsHeader = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    '& svg': {
      fill: themeDefault.palette.mainBackground,
    },
  }),
);

export const TableBulkOptionsText = styled(Typography)(
  () => ({
    ...themePublic.tableBulkOptionsText as CSSProperties,
  }),
);

export const TableBulkOptionsMenu = styled(Button)(
  () => ({
    ...themePublic.tableBulkOptionsMenu as CSSProperties,
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    margin: '0 !important',
  }),
);

export const TableBulkOptionsMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.tableBulkOptionsMenuItem as CSSProperties,
  }),
);

export const BulkTableHead = styled(TableHead)(
  () => ({
    ...themePublic.bulkTableHeader as CSSProperties,
  }),
);

export const FirstTableCellHeader = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const ImageTableCell = styled(TableCell)(
  () => ({
    ...ImageTableCellStyle as CSSProperties,
  }),
);

export const BulkTitleHeaderTableCell = styled(TableCell)(
  () => ({
    fontSize: '14px !important',
    minWidth: '6vw',
    maxWidth: '6vw',
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...LastTableCellStyle as CSSProperties,
  }),
);

export const RemovedBorderTableHead = styled(TableHead)(
  () => ({
    ...themePublic.removedBorderTableHead as CSSProperties,
    width: '100%',
  }),
);

export const CheckBoxHeaderTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxHeaderTableCell as CSSProperties,
  }),
);

export const CheckBoxHeaderTableCellSelected = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxHeaderTableCell as CSSProperties,
    ...themePublic.checkBoxHeaderTableCellSelected as CSSProperties,
  }),
);

export const HeaderTableCellContent = styled('span')(
  () => ({
    alignItems: 'center',
    display: 'flex !important',
    flexDirection: 'row',
    fontSize: '14px !important',
    height: '2rem',
    justifyContent: 'start',
    zIndex: '0 !important',
  }),
);

export const TitleHeaderTableCell = styled(TableCell)(
  () => ({
    ...TitleHeaderTableCellStyle as CSSProperties,
  }),
);
