import { fabric } from 'fabric';
import { MouseEvent as MouseEventType, SyntheticEvent } from 'react';

import {
  ACTION_MENU_PLACEMENT,
  BOUNDARY_WINDOW_WIDTH, ENVELOPE_SIZE, NUMBER_OF_INVITATION_TEMPLATES_PER_ROW, NUMBER_OF_ITEMS_PER_PAGE,
  NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES,
  ORDER_TYPE,
  TABLE_TYPE,
  USER_PERMISSIONS,
  USER_SETTINGS_CONFIG,
  USER_STATUS
} from 'enums';
import { DEFAULT_FONT_SIZE, DefaulContactParams, DefaultGuestParams, DefaultInvitationParams, DefaultTemplateParams, DefaultUserParams, DefaultVenueParams, FIRST_PAGE, showToast } from 'helpers';
import {
  ActionMenuPropsDataType,
  ContactFormValuesType,
  ContactType,
  EviteType,
  ExtendedParams,
  FontType,
  GuestType,
  InvitationProps,
  InvitationResponseRsvpType,
  PageInfoType,
  RsvpContactResponseType,
  StoreType,
  TagType,
  UserProps,
  UserSettingsCofing
} from 'types';

export const addFontsToDocument = async (fontsData: FontType[]) => {
  if (fontsData.length > 0) {
    for (let i = 0; i < fontsData.length; i++) {
      if (fontsData[i].ttfUrl) {
        const fontFace = new FontFace(fontsData[i].name, `url(${fontsData[i].ttfUrl})`);
        (document as any).fonts.add(fontFace);
        await fontFace.load();
      }
    }
  }
};

export const addShadowToCanvas = (canvas: fabric.Canvas, width = ENVELOPE_SIZE.WIDTH, height = ENVELOPE_SIZE.HEIGHT ) => {
  const rect = new fabric.Rect({
    width: width,
    height: height,
    fill: new fabric.Gradient({
      type: 'linear',
      coords: {
        x1: 0,
        y1: 0,
        x2: width,
        y2: 0
      },
      colorStops: [
        { offset: 0, color: 'rgba(0,0,0,0.15)' },
        { offset: 0.25, color: 'rgba(0,0,0,0)' },
        { offset: 0.75, color: 'rgba(0,0,0,0)' },
        { offset: 1, color: 'rgba(0,0,0,0.15)' }
      ]
    }),
    left: 0,
    top: 0,
    selectable: false,
    evented: false,
  });
  canvas.add(rect);
};

export const calculateCanvasScale = (element: HTMLElement, canvasJson: fabric.Canvas) => {
  const widthScale = element.offsetWidth / (canvasJson.width / canvasJson.viewportTransform[0]);
  const heightScale = element.offsetHeight / (canvasJson.height / canvasJson.viewportTransform[0]);
  return Math.min(widthScale, heightScale);
};

export const calculateMenuPlacement = (isMobile: boolean) => {
  return isMobile ? ACTION_MENU_PLACEMENT.BOTTOM_END : ACTION_MENU_PLACEMENT.BOTTOM;
};

export const checkPermission = (user: UserProps, perrmission: USER_PERMISSIONS) => {
  return user && user.permissions.includes(perrmission);
};

export const checkIfElementContainsElement = (id: string, target: HTMLElement) : boolean => {
  return document.getElementById(id)?.contains(target) || id === target.id;
};

export const checkIfElementsContainsElement = (ids: string[], target: HTMLElement) : boolean => {
  return ids.some(id => checkIfElementContainsElement(id, target));
};

export const checkForLoadedFonts = (setStateAction: (trigger: boolean)=> void) => {
  document.fonts.addEventListener('loadingdone', () => {
    setStateAction(true);
  });
  if (document.fonts.status === 'loaded') {
    setStateAction(true);
  }
};

export const checkIsAnyEviteSelected = (guest: GuestType, sendToOneContact: (id: number) => void, errorMessage: string) => {
  guest.evites.some(evite => evite.isSelected) ? sendToOneContact(guest.id) : showToast('error', errorMessage);
};

export const delay = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const disableInvitationTab = (invitationDetails: InvitationProps) => {
  return !invitationDetails?.locationInfo?.venue?.name || !invitationDetails?.locationInfo?.venue?.address1;
};

export const filterActionMenuByTitle = (actionMenu: ActionMenuPropsDataType[], title: string) => {
  return actionMenu.filter((option) => option.title !== title);
};

export const findDefaultFontValue = (fontSizes: number[], scale: number, defaultSize = DEFAULT_FONT_SIZE) => {
  const firstBigger = fontSizes.find(el => el > defaultSize / scale);
  const firstLower = fontSizes[fontSizes.indexOf(firstBigger) - 1];
  return firstBigger - defaultSize / scale > (firstLower - defaultSize / scale) * -1 ? firstLower : firstBigger;
};

export const formatDate = (date: string) => {
  return new Date(date);
};

export const filterRsvpStatus = (contactResponses: RsvpContactResponseType[]) => {
  return contactResponses.filter((contactResponse: RsvpContactResponseType) => contactResponse?.invitationResponses.length > 0 &&
  contactResponse.invitationResponses.some((invitationResponse: InvitationResponseRsvpType) => invitationResponse.responses.length > 0));
};

export const filterEmptyStringTags = (val: string[]) => {
  const trimedVal = val.map((tag: string) => tag.trim());
  const index = trimedVal.indexOf('');
  if (index > -1) {
    trimedVal.splice(index, 1);
  }
  return trimedVal;
};

export const getDefaultTableParams = (tableType: TABLE_TYPE) => {
  switch (tableType) {
    case TABLE_TYPE.CONTACTS_TABLE:
      return DefaulContactParams;
    case TABLE_TYPE.GUEST_TABLE:
      return DefaultGuestParams;
    case TABLE_TYPE.INVITATIONS_TABLE:
      return DefaultInvitationParams;
    case TABLE_TYPE.INVITATION_TEMPLATES_TABLE:
      return DefaultTemplateParams;
    case TABLE_TYPE.USERS_TABLE:
      return DefaultUserParams;
    case TABLE_TYPE.VENUES_TABLE:
      return DefaultVenueParams;
  }
};

export const getElementsDimension = (element: HTMLElement) => {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
  };
};

export const getIntialTableParams = (params: ExtendedParams, tableType: TABLE_TYPE, userSettings: UserSettingsCofing) => {
  return {
    ...params,
    rowsPerPage: userSettings?.pageSize ?? getDefaultTableParams(tableType).rowsPerPage,
    page: FIRST_PAGE,
    order: userSettings?.order ?? ORDER_TYPE.ASC,
    orderBy: userSettings?.orderBy ?? getDefaultTableParams(tableType).orderBy,
  };
};

export const generateStoreObject = (set: any, get: any, filterParams: ExtendedParams): StoreType => {
  return {
    searchText: '',
    setSearchText: (text: string ) => set({ searchText: text }),
    params: filterParams,
    setParams: (params) => set({ params: params }),
    setParamsPage: (paramsNew) => {
      get().params.page = paramsNew.page;
      set(get());
    },
    pageInfo: {
      totalCount: 0,
      hasPreviousPage: false,
      hasNextPage: false,
    },
    setPageInfo: (info: PageInfoType) => set({ pageInfo: info }),
    selectedItems: [],
    setSelectedItems: (itemIds: number[]) => set({ selectedItems: itemIds }),
    isExecutedRefetch: false,
    changeExecuteRefetch: () => set({isExecutedRefetch: !get().isExecutedRefetch}),
    isLoading: false,
    setIsLoading: (loadingState: boolean) => set({isLoading: loadingState})
  };
};

export const getEviteData = (invitation: InvitationProps) => {
  return { evites: invitation?.evites.length > 0 ? invitation.evites : [{ id: 0, name: '' } as EviteType] };
};

export const getIdFromRoute = (params: any) => {
  return params.split('/')[1];
};

export const getLocalStringOptions = (): Intl.DateTimeFormatOptions => {
  // Date Format: "31/01/2000, 9:00:00 AM"
  return { year: 'numeric', month: 'numeric', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' };
};

export const getRouteForNavigation = (pathname: string, tabName: string, params: string) => {
  if (pathname.startsWith('/invitations/create/')) {
    return `/invitations/create/${tabName}/${localStorage.getItem('activeId')}`;
  } else if (pathname.startsWith('/invitations/edit/')) {
    return `/invitations/edit/${tabName}/${localStorage.getItem('activeId')}`;
  } else if (pathname.startsWith('/invitationTemplates/create/')) {
    return `/invitationTemplates/create/${tabName}/${getIdFromRoute(params)}`;
  } else if (pathname.startsWith('/invitationTemplates/edit/')) {
    return `/invitationTemplates/edit/${tabName}/${getIdFromRoute(params)}`;
  }
};

export const getRouteName = (pathname: string) => {
  switch (pathname) {
    case '/settings':
      return 'myProfile';

    case '/contacts':
      return 'contacts';

    case '/userlist':
      return 'Users';

    case '/invitationTemplates':
      return 'invitationTemplates';

    case '/invitationlist':
      return 'invitationList';

    case '/invitationTemplates/create/details':
    case '/invitations/create/details':
      return 'details';

    case '/invitationTemplates/create/design':
    case '/invitations/create/design':
      return 'design';

    case '/invitations/invitationlist':
      return 'invitationList';

    case '/invitations/create/invitation':
      return 'invitation';

    case '/invitations/create/envelope':
      return 'envelope';

    case '/invitations/create/preview':
      return 'preview';

    case '/invitations/create/send/':
      return 'send';

    case '/venues':
      return 'venues';

    default:
      break;
  }

  if (pathname.startsWith('/invitations/edit/invitation/') || pathname.startsWith('/invitations/create/invitation/')) {
    return 'invitation';
  } else if (pathname.startsWith('/invitationTemplates/create/details/') || pathname.startsWith('/invitations/create/details/') ||
             pathname.startsWith('/invitationTemplates/edit/details/') || pathname.startsWith('/invitations/edit/details/')) {
    return 'details';
  } else if (pathname.startsWith('/invitationTemplates/create/design/')|| pathname.startsWith('/invitations/create/design/')||
             pathname.startsWith('/invitationTemplates/edit/design/') || pathname.startsWith('/invitations/edit/design') ) {
    return 'design';
  } else if (pathname.startsWith('/invitationTemplates/create/envelope/') || pathname.startsWith('/invitations/create/envelope/') ||
             pathname.startsWith('/invitationTemplates/edit/envelope/') || pathname.startsWith('/invitations/edit/envelope')) {
    return 'envelope';
  } else if (pathname.startsWith('/invitationTemplates/create/preview/') || pathname.startsWith('/invitations/create/preview/') ||
    pathname.startsWith('/invitationTemplates/edit/preview/') || pathname.startsWith('/invitations/edit/preview')) {
    return 'preview';
  } else if (pathname.startsWith('/invitationTemplates/create/send/') || pathname.startsWith('/invitations/create/send/') ||
    pathname.startsWith('/invitationTemplates/edit/send/') || pathname.startsWith('/invitations/edit/send')) {
    return 'send';
  } else if (pathname.startsWith('/invitations/create/track') || pathname.startsWith('/invitations/edit/track')) {
    return 'track';
  }
};

export const getSelectedEvitesPropsByPropName = (selectedEvites: EviteType[], prop: string) => {
  return prop ? selectedEvites.map((evite: EviteType) => evite[prop as keyof EviteType]) : selectedEvites.map((evite: EviteType) => evite);
};

export const getUserSettings = (tableType: USER_SETTINGS_CONFIG = null, userId: number = null) => {
  if (!localStorage.getItem('userSettings') || JSON.parse(localStorage.getItem('userSettings')).userId !== userId) {
    const jsonAttributes = Object.values(USER_SETTINGS_CONFIG).map(item => {
      switch (item) {
        case USER_SETTINGS_CONFIG.USERS_TABLE:
        case USER_SETTINGS_CONFIG.CONTACTS_TABLE:
        case USER_SETTINGS_CONFIG.IMPORT_EXISTING_CONTACTS_SEND_PAGE:
          return `"${item}": {"pageSize": ${NUMBER_OF_ITEMS_PER_PAGE.TEN}, "orderBy": "lastName", "order": "ASC"}`;
        case USER_SETTINGS_CONFIG.VENUES_TABLE:
          return `"${item}": {"pageSize": ${NUMBER_OF_ITEMS_PER_PAGE.TEN}, "orderBy": "name", "order": "ASC"}`;
        case USER_SETTINGS_CONFIG.INVITATIONS_TABLE:
          return `"${item}": {"pageSize": ${NUMBER_OF_ITEMS_PER_PAGE.TEN}, "orderBy": "createdAt", "order": "DESC"}`;
        case USER_SETTINGS_CONFIG.EDIT_GUEST_PREVIEW_PAGE:
        case USER_SETTINGS_CONFIG.GUEST_TABLE_SEND_PAGE:
        case USER_SETTINGS_CONFIG.GUEST_TABLE_TRACK_PAGE:
          return `"${item}": {"pageSize": ${NUMBER_OF_ITEMS_PER_PAGE.TEN}, "orderBy": "Contact.LastName", "order": "ASC"}`;
        case USER_SETTINGS_CONFIG.INVITATION_TEMPLATES_TABLE:
          return `"${item}": {"pageSize": ${NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES.TWO}, "orderBy": "timesUsed", "order": "DESC"}`;
        default:
          return `"${item}": {}`;
      }
    }).join(', ');
    localStorage.setItem('userSettings', `{"userId": ${userId}, "value": {${jsonAttributes}}}`);
  }
  return tableType ? JSON.parse(localStorage.getItem('userSettings')).value[tableType] : JSON.parse(localStorage.getItem('userSettings')).value;
};

export const getUTCDate = (stringDate: string) : Date => {
  return new Date(
    new Date(stringDate).getUTCFullYear(),
    new Date(stringDate).getUTCMonth(),
    new Date(stringDate).getUTCDate(),
    new Date(stringDate).getUTCHours(),
    new Date(stringDate).getUTCMinutes());
};

export const handleCustomSortFilter = (params: ExtendedParams) => {
  if (typeof(params.orderBy) === 'number') {
    return { eviteId: params.orderBy, order: params.order.toUpperCase(), orderBy: 'Status' };
  } else {
    return { order: params.order.toUpperCase(), orderBy: params.orderBy };
  }
};

export const handleOrder = (order: ORDER_TYPE) => {
  return order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC;
};

export const isCurrentUsersInvitation = (currentUserId: number, invitationUserId: number) => {
  return currentUserId === invitationUserId;
};

export const isRsvpResponseNotEmpty = (guestRsvp: any) => {
  let isNotEmpty = false;
  guestRsvp?.contactResponses.map((contactResponse: any) => {
    contactResponse.invitationResponses.map((invitationResponse: any) => {
      if (invitationResponse.responses.length > 0) {
        isNotEmpty = true;
      }
    });
  });
  return isNotEmpty;
};

export const isEmptyStringOrNull = (string: string) => {
  return string === null || string === '';
};

export const isLocationValid = (invitationDetails: InvitationProps) => {
  return invitationDetails.locationInfo?.venue?.name && invitationDetails.locationInfo?.startDate;
};

export const isPageForSettingPassword = (pageType: string) => {
  return 'setPassword' === pageType;
};

export const isUserActive = (user: UserProps) => user.status === USER_STATUS.ACTIVE.toUpperCase();

export const memberOfContactsAreChanged = (contact: ContactType, newValues: ContactFormValuesType) => {
  if (!contact && newValues?.firstName.length === 0) {
    return false;
  }

  if (contact.contacts?.length > 0 && contact.contacts?.length !== newValues?.firstName.length) {
    return true;
  }

  const initialValues = contact.contacts?.length === 0 ? {
    groupName: '',
    firstName: [contact.firstName],
    lastName: [contact.lastName],
    email: [contact.email],
    phoneNumber: [contact.phoneNumber],
    street: [contact.street],
    city: [contact.city],
    zipCode: [contact.zipCode],
    state: [contact.state],
    title: [contact.title ?? ''],
    id: [contact.id]
  } : {
    groupName: contact.lastName,
    firstName: contact.contacts.map(item => item.firstName),
    lastName: contact.contacts.map(item => item.lastName),
    email: contact.contacts.map(item => item.email),
    phoneNumber: contact.contacts.map(item => item.phoneNumber),
    street: contact.contacts.map(item => item.street),
    city: contact.contacts.map(item => item.city),
    zipCode: contact.contacts.map(item => item.zipCode),
    state: contact.contacts.map(item => item.state),
    title: contact.contacts.map(item => item.title),
    id: contact.contacts.map(item => item.id)
  };

  return JSON.stringify(newValues) !== JSON.stringify(initialValues);
};

export const onImageErrorLoading = async (e: SyntheticEvent<HTMLImageElement>) => {
  const {currentTarget} = e;
  await sleep(1000);

  const src = currentTarget.src;
  currentTarget.onerror = null;
  currentTarget.src = src;
};

export const removeFocusFromTextboxMobile = (e: MouseEventType<HTMLDivElement>, elements: HTMLDivElement[], canvasObject: fabric.Canvas, isMobile: boolean) => {
  if (isMobile && (elements.some(element => element === e.target))) {
    canvasObject.discardActiveObject().renderAll();
  }
};

export const resetTextSelection = (canvasObject: fabric.Canvas) => {
  setTimeout(() => {
    const textBox = canvasObject.getActiveObject();
    canvasObject.discardActiveObject();
    canvasObject.setActiveObject(textBox);
  }, 1);
};

export const requestHeader = () => {
  return localStorage.getItem('userId') && {
    'ImpersonateUserId': localStorage.getItem('userId')
  };
};

export const responseColorDetect = (eviteStatus: string) => {
  switch (eviteStatus) {
    case 'ATTENDING':
      return '#A5416F';
    case 'NOT_ATTENDING':
      return '#33415E';
    case 'MAZEL_TOW_ONLY':
      return '#DF519C';

    default:
      return '#D6D9DF';
  }
};

export const requiredDataForInvitation = (invitationDetails: InvitationProps) => {
  const senders = invitationDetails.senders.map((sender: any) => {
    return {
      id: sender.id,
      firstName: sender.firstName,
      lastName: sender.lastName,
      titleValue: sender.titleValue,
      isDefault: sender.isDefault
    };
  });
  const locationInfo = {
    address1: invitationDetails.locationInfo.venue.address1,
    endDate: invitationDetails.locationInfo?.endDate,
    name: invitationDetails.locationInfo.venue.name,
    startDate: invitationDetails.locationInfo.startDate
  };
  return { senders: senders, locationInfo: locationInfo };
};

export const selectAndRemoveFocus = (canvas: fabric.Canvas) => {
  const textBox = canvas.getActiveObject();
  canvas.discardActiveObject();
  canvas.setActiveObject(textBox);
};

export const setCanvasDimensions = (canvas: fabric.Canvas, canvasWidth: number, canvasHeight: number, scale: number) => {
  canvas.setWidth(canvasWidth);
  canvas.setHeight(canvasHeight);
  canvas.zoomToPoint(new fabric.Point(0, 0), scale);
};

export const sleep = (ms: number) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export const switchToEditMode = (canvasObject: any, elementId: string) => {
  const activeObject = canvasObject.getActiveObject();

  activeObject.enterEditing();
  activeObject.setSelectionStart(activeObject.text.length);
  activeObject.setSelectionEnd(activeObject.text.length);

  const element = document.getElementById(elementId);
  element.scrollIntoView();
};

export const tagsAreChanged = (initialValues: TagType[], newValues: string[]) => {
  if (initialValues?.length !== newValues?.length || !initialValues.every((tag: TagType) => newValues.includes(tag.name))) {
    return true;
  }
  return false;
};

export const templatesPerRowCalc = () => {
  if (window.innerWidth < BOUNDARY_WINDOW_WIDTH.SMALL) {
    return NUMBER_OF_INVITATION_TEMPLATES_PER_ROW.TWO;
  } else if (window.innerWidth > BOUNDARY_WINDOW_WIDTH.SMALL && window.innerWidth < BOUNDARY_WINDOW_WIDTH.MEDIUM) {
    return NUMBER_OF_INVITATION_TEMPLATES_PER_ROW.THREE;
  } else {
    return NUMBER_OF_INVITATION_TEMPLATES_PER_ROW.FOUR;
  }
};

export const templateRowsPerPageCalc = (index: number = null) => {
  return index ? Number(Object.values(NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES)[index]) : NUMBER_OF_ROWS_PER_PAGE_FOR_INVITATION_TEMPLATES.TWO;
};
