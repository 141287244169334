import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const RemovedBorderTableHead = styled(TableHead)(
  () => ({
    ...themePublic.removedBorderTableHead as CSSProperties,
  }),
);

export const BulkTableHeadRow = styled(TableRow)(
  () => ({
    ...themePublic.tableBulkHeadRow as CSSProperties,
  }),
);

export const LabelNameTableCell = styled(TableCell)(
  () => ({
    ...themePublic.boldText as CSSProperties,
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCell as CSSProperties,
  }),
);

export const TableStyled = styled(TableBody)(
  () => ({
    ...themePublic.table as CSSProperties,
    minHeight: '80vh',
    '& td': {
      padding: '5px 8px 5px 16px',
    },
  }),
);

export const ButtonColumnIndent = styled(Box)(
  () => ({
    paddingLeft: '12px',
  }),
);
