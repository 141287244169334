import { People } from '@mui/icons-material';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  maxWidth: '30vh',
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const ContactGroupMobilePaperContainer = styled(Paper)(
  () => ({
    ...themePublic.tableGroupMobilePaperConatiner as CSSProperties,
    border: '2px solid transparent',
  }),
);

export const ContactGroupMobilePaperContainerSelected = styled(Paper)(
  () => ({
    ...themePublic.tableGroupMobilePaperConatiner as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
  }),
);

export const GroupMobilePaperContent = styled(Paper)(
  () => ({
    ...themePublic.groupMobilePaperContent as CSSProperties,
    boxShadow: 'none',
  }),
);

export const PeopleIconStyled = styled(People)(
  () => ({
    ...themePublic.personIcon as CSSProperties,
  }),
);

export const DataContainerMobile = styled(Box)(
  () => ({
    ...themePublic.dataContainer as CSSProperties,
  }),
);

export const BoldInfoData = styled(Typography)(
  () => ({
    ...themePublic.tableMobileDataInfoBold as CSSProperties,
    ... themeDefault.overflowText as CSSProperties,
    width: '-webkit-fill-available',
  }),
);

export const TypographyCustomTooltip = styled(Typography)(
  () => ({
    ... themeDefault.overflowText as CSSProperties,
    width: '-webkit-fill-available',
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactRowDividerMobile as CSSProperties,
  }),
);

export const DataInfoContainer = styled(Box)(
  () => ({
    ...themePublic.singleContactDataInfoContainerMobile as CSSProperties,
  }),
);

export const DataContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  }),
);

export const LabelName = styled('span')(
  ({ theme }) => ({
    ...themePublic.contactLabelName as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
  }),
);

export const Data = styled('span')(
  () => ({
    ...themeDefault.overflowText,
    maxWidth: '30vh',
  }),
);

export const EvitesContainer = styled(Box)(
  () => ({
    ...themePublic.contactEvitesContainer as CSSProperties,
  }),
);

export const DataEviteName = styled('span')(
  () => ({
    ...themePublic.contactDataEvitesName as CSSProperties,
  }),
);

export const ExpandArrowContainer = styled('span')(
  () => ({
    display: 'flex',
    justifyContent: 'center',
  }),
);

export const ExpandedDataContainer = styled(Box)(
  () => ({
    border: '2px solid transparent',
    borderRadius: '8px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem',
    padding: '0.5rem',
  }),
);
