import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const UploaderContainer = styled(Box)(
  ({ theme }) => ({
    maxWidth: '40vw',
    paddingBottom: '20px',
    width: '400px',
    '& button': {
      backgroundColor: themeDefault.palette.mainColor,
      padding: '10px 36px',
      '&:hover': {
        backgroundColor: themeDefault.palette.mainColor,
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
    }
  }),
);

export const CommentTextModal = styled(Typography)(
  () => ({
    ...themePublic.commentTextModal as CSSProperties,
  }),
);
