import React, { ChangeEvent, ElementType, memo, useEffect, useState } from 'react';
import {
  Box,
  Checkbox, Menu,
  Table,
  TableCell,
  TableCellBaseProps,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal, TableWrapper, VenueModal, VenueRow } from 'components';
import { useAuth } from 'contexts';
import { FORM_TYPE, TABLE_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { MAX_NUMBER_OF_ITEMS_TO_GET, getUserSettings, handleOrder, useFetching, venueColumns } from 'helpers';
import { BULK_DELETE_VENUE, GET_VENUES, GET_VENUES_IDS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { useVenuesStore } from 'store';
import { GuestType, TableColumnProps, UseAuthProps, VenueProps } from 'types';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import {
  AddNewVenueButtonMobile,
  BulkTableHead, CheckBoxHeaderTableCell, CheckBoxHeaderTableCellSelected, EmptyListTextStyle, ImageTableCell,
  LastTableCell, RemovedBorderTableHead, TableBodyStyled, TableBulkOptionsCheckbox,
  TableBulkOptionsCheckboxSelected, TableBulkOptionsHeader, TableBulkOptionsMenu,
  TableBulkOptionsMenuItem, TableBulkOptionsText, TableContainerStyled, TitleHeaderTableCell
} from './VenuesTable.style';

const VenuesTableComponent = () => {
  const [t] = useTranslation();
  const { dbUser, saveUserSettings }: UseAuthProps = useAuth();
  const [ getVenues, { loading: loadingVenues, data, refetch }] = graphLazyQueryMiddleware(GET_VENUES);
  const [getAllVenues] = graphLazyQueryMiddleware(GET_VENUES_IDS);
  const [ bulkDeleteVenue, { loading: deleteInProgress }] = graphMutationMiddleware(BULK_DELETE_VENUE);

  const [ storedData, setStoredData ] = useState(null);
  const [ isSelectedAll, setIsSelectedAll ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ isOpenBulkDeleteModal, setIsOpenBulkDeleteModal ] = useState(false);
  const [ isOpenAddModal, setIsOpenAddModal ] = useState(false);
  const [ startFetching, setStartFetching ] = useState(false);
  const openMenu = Boolean(anchorEl);
  const userSettings = getUserSettings(USER_SETTINGS_CONFIG.VENUES_TABLE, dbUser.id);

  const {
    searchText,
    params,
    setParams,
    pageInfo,
    selectedItems: selectedVenues,
    setSelectedItems: setSelectedVenues,
    setPageInfo,
    setIsLoading: setLoadingVenues,
  } = useVenuesStore();

  useEffect(() => {
    if (!data) {
      return;
    }
    setStoredData(data.venues.items);
    setPageInfo({
      totalCount: data.venues.totalCount,
      hasNextPage: data.venues.pageInfo.hasNextPage,
      hasPreviousPage: data.venues.pageInfo.hasPreviousPage,
    });
  }, [data]);

  const fetchVenues = (newSearchText = '') => {
    if (!startFetching) {
      return;
    }
    setLoadingVenues(true);
    getVenues({
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          textFilter: newSearchText,
        },
        order: {
          [params.orderBy]: params.order.toUpperCase(),
          'id': params.order.toUpperCase()
        },
        skip: params.rowsPerPage * (params.page - 1),
        take: params.rowsPerPage
      }}).then(() => setLoadingVenues(false));
  };

  useFetching( fetchVenues, refetch, loadingVenues, setStartFetching, startFetching, userSettings, useVenuesStore, TABLE_TYPE.VENUES_TABLE );

  const isVenueSelected = (id: number) => {
    return selectedVenues.some(c => c === id);
  };

  const handleSelectAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allRows: number[] = [];
      let hasNextPage = false;
      let iterator = 0;
      do {
        await getAllVenues({
          fetchPolicy: 'cache-and-network',
          variables: {
            filter: {
              textFilter: searchText,
            },
            skip: MAX_NUMBER_OF_ITEMS_TO_GET * iterator,
            take: MAX_NUMBER_OF_ITEMS_TO_GET,
          }
        }).then((resAllVenuesData) => {
          const venuesFromPage = resAllVenuesData.data.venues.items;
          hasNextPage = resAllVenuesData.data.venues.pageInfo.hasNextPage;
          iterator++;
          venuesFromPage.forEach((guest: GuestType) => allRows.push(guest.id));
        });
      } while (hasNextPage);
      setSelectedVenues(allRows);
      setIsSelectedAll(true);
      return;
    }
    setSelectedVenues([]);
    setIsSelectedAll(false);
  };

  const handleCheckboxSelect = (id: number) => {
    const newSelectedVenues = [...selectedVenues];
    if (isVenueSelected(id)) {
      const customer = newSelectedVenues.find(c => c === id);
      const index = newSelectedVenues.indexOf(customer);
      newSelectedVenues.splice(index, 1);
      setIsSelectedAll(false);
    } else {
      (data.venues.totalCount - 1) === selectedVenues.length && setIsSelectedAll(true);
      newSelectedVenues.push(id);
    }
    setSelectedVenues(newSelectedVenues);
  };

  const bulkDelete = () => {
    bulkDeleteVenue({variables: {
      venueIds: selectedVenues
    }}).then(() => {
      setSelectedVenues([]);
      refetch();
    });
  };

  const venues: VenueProps[] = data?.venues?.items || storedData || [];

  return (
    <TableWrapper isLoading={loadingVenues} totalCount={pageInfo.totalCount} theme={EmptyListTextStyle}>
      <>
        {isMobile ?
          <Box>
            {!loadingVenues &&
            <>
              <TableBulkOptionsHeader>
                {venues.length > 0 && isSelectedAll ?
                  <TableBulkOptionsCheckboxSelected
                    checked={venues.length > 0 && isSelectedAll}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelectAll(e)}/> :
                  <TableBulkOptionsCheckbox
                    checked={venues.length > 0 && isSelectedAll}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelectAll(e)}/>
                }
                {selectedVenues.length > 0 ?
                  <>
                    <TableBulkOptionsText> {`${selectedVenues.length} ${t('selected')}`} </TableBulkOptionsText>
                    <TableBulkOptionsMenu onClick={(e) => setAnchorEl(e.currentTarget)}>
                      <MoreHorizIcon />
                    </TableBulkOptionsMenu>
                    <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
                      <TableBulkOptionsMenuItem onClick={() => {
                        setAnchorEl(null);
                        setIsOpenBulkDeleteModal(true);
                      }}>
                        <DeleteIcon/> {t('delete')}
                      </TableBulkOptionsMenuItem>
                    </Menu>
                  </> :
                  <Typography>{t('selectAll')}</Typography>
                }
              </TableBulkOptionsHeader>
              {selectedVenues.length === 0 &&
                <AddNewVenueButtonMobile elevation={3} onClick={() => setIsOpenAddModal(true)}>
                  <AddIcon fontSize='large' />
                </AddNewVenueButtonMobile>}
              {venues.map((venue) => (
                <VenueRow key={venue.id} venue={venue} handleCheckboxSelect={handleCheckboxSelect} selectedVenues={selectedVenues}/>
              ))}
            </>
            }
            <VenueModal type={FORM_TYPE.CREATE} isOpen={isOpenAddModal} handleClose={() => setIsOpenAddModal(false)}/>
          </Box> :
          <TableContainerStyled>
            {!loadingVenues &&
              <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                {
                  selectedVenues.length > 0 ?
                    <BulkTableHead>
                      <TableRow>
                        <TableCell component={(isSelectedAll ? CheckBoxHeaderTableCellSelected : CheckBoxHeaderTableCell) as ElementType<TableCellBaseProps>}>
                          <Checkbox onChange={(e) => handleSelectAll(e)} checked={isSelectedAll} />
                        </TableCell>
                        <ImageTableCell>
                          <TableBulkOptionsText> {`${selectedVenues.length} ${t('selected')}`} </TableBulkOptionsText>
                        </ImageTableCell>
                        <TableCell>
                          <TableBulkOptionsText onClick={() => setIsOpenBulkDeleteModal(true)}><DeleteIcon/>{t('delete')}</TableBulkOptionsText>
                        </TableCell>
                        <TableCell />
                        <LastTableCell />
                      </TableRow>
                    </BulkTableHead> :
                    <RemovedBorderTableHead>
                      <TableRow>
                        <TableCell component={(venues.length === selectedVenues.length && selectedVenues.length > 0 ? CheckBoxHeaderTableCellSelected : CheckBoxHeaderTableCell) as ElementType<TableCellBaseProps>}>
                          <Checkbox onChange={(e) => handleSelectAll(e)} checked={isSelectedAll} />
                        </TableCell>
                        {venueColumns.map((column: TableColumnProps) => (
                          <TableCell key={column.id} component={(column.id === 0 ? ImageTableCell : TitleHeaderTableCell) as ElementType<TableCellBaseProps>} >
                            {t(column.title)}
                            {column.enableSorting &&
                              <TableSortLabel
                                active={params.orderBy === column.sortableName}
                                direction={params.order}
                                onClick={() => {
                                  setParams({...params, order: handleOrder(params.order), orderBy: column.sortableName });
                                  saveUserSettings(USER_SETTINGS_CONFIG.VENUES_TABLE, column.sortableName, handleOrder(params.order));
                                }}/>}
                          </TableCell>
                        ))}
                      </TableRow>
                    </RemovedBorderTableHead>
                }
                {venues?.length > 0 &&
              <TableBodyStyled>
                {venues.length > 0 && venues.map((venue) => (
                  <VenueRow key={venue.id} venue={venue} handleCheckboxSelect={handleCheckboxSelect} selectedVenues={selectedVenues}/>
                ))}
              </TableBodyStyled>
                }
              </Table>}
          </TableContainerStyled>}
        <ConfirmationModal
          loading={deleteInProgress}
          isOpen={isOpenBulkDeleteModal}
          handleClose={() => setIsOpenBulkDeleteModal(false)}
          handleConfirm={() => bulkDelete()}
          confimMessage={t('venuesDeactivatedConfirmMessage')}/>
      </>
    </TableWrapper>
  );
};

export const VenuesTable = memo(VenuesTableComponent);

VenuesTable.displayName = 'VenuesTable';
