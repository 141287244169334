import { createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const EmptyListTextStyle = {
  ...themePublic.emptyListText as CSSProperties,
  fontSize: '1rem !important',
  [themeLocal.breakpoints.down('sm')]: {
    margin: 0,
    marginTop: '5rem',
    textAlign: 'center'
  },
};
