import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const pageBody = {
  ...themePublic.pageBody as CSSProperties,
};

export const PageBody = styled(Box)(
  () => ({
    ...pageBody,
    minHeight: 'auto',
  }),
);

export const ImportingOnlyContactsPageBody = styled(PageBody)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
  }),
);

export const PageContent = styled(Box)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    marginTop: '3rem',
    padding: '4rem 8rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 3rem',
      borderRadius: themeDefault.defaultBorderRadius,
      marginTop: '130px',
    },
  }),
);

export const ImportingOnlyContactsPageContent = styled(PageContent)(
  ({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  }),
);

export const UploadFilersContainer = styled(Box)(
  ({ theme }) => ({
    ...themeDefault.overflowText,
    justifyContent: 'end',
    maxWidth: '95%',
    display: 'flex',
    '& p': {
      ...themeDefault.overflowText,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '0px',
      display: 'flex',
      justifyContent: 'center',
      marginRight: '15px',
      right: '0px',
      textAlign: 'right',
      width: 'auto',
    }
  }),
);

export const ButtonsContainer = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    height: '75vh',
    justifyContent: 'flex-start',
  }),
);

export const BrowseButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.mainColor,
    borderRadius: '0.6rem',
    '&:hover': {
      backgroundColor: themeDefault.palette.mainColor
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }),
);

export const DownloadButton = styled(Button)(
  ({ theme }) => ({
    border: `1px solid ${themeDefault.palette.mainColor}`,
    borderRadius: '0.6rem',
    color: themeDefault.palette.mainColor,
    marginLeft: '0.6rem',
    '&:hover': {
      border: `1px solid ${themeDefault.palette.mainColor}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '0.6rem',
      marginLeft: 0,
    },
  }),
);

export const DownloadIcon = styled('img')(
  () => ({
    marginRight: '8px',
  }),
);

export const ResponseContainer = styled(Box)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    overflow: 'auto',
    padding: '1rem',
    whiteSpace: 'pre-wrap',
    width: '90%',
    wordWrap: 'break-word',
  }),
);
