import { CSSProperties } from 'react';
import { Box, Button, Checkbox, MenuItem, TableCell, TableHead, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themePublic } from 'themeDefault';

export const TableBulkOptionsHeader = styled(Box)(
  () => ({
    ...themePublic.tableBulkOptionsHeader as CSSProperties,
  }),
);

export const TableBulkOptionsCheckboxSelected = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
    ...themePublic.tableBulkOptionsCheckboxSelected as CSSProperties,
  }),
);

export const TableBulkOptionsCheckbox = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
  }),
);

export const TableBulkOptionsText = styled(Typography)(
  () => ({
    ...themePublic.tableBulkOptionsText as CSSProperties,
  }),
);

export const TableBulkOptionsMenu = styled(Button)(
  () => ({
    ...themePublic.tableBulkOptionsMenu as CSSProperties,
  }),
);

export const TableBulkOptionsMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.tableBulkOptionsMenuItem as CSSProperties,
  }),
);

export const BulkTableHead = styled(TableHead)(
  () => ({
    ...themePublic.bulkTableHeader as CSSProperties,
  }),
);

export const FirstTableCellHeaderSelected = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const FirstTableCellHeader = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
  }),
);

export const ImageTableCell = styled(TableCell)(
  ({ theme }) => ({
    minWidth: '6vw',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.between('sm', 860)]: {
      minWidth: '12vw',
    },
    [theme.breakpoints.between(860, 1050)]: {
      minWidth: '11vw',
    },
    [theme.breakpoints.between(1050, 1350)]: {
      minWidth: '9vw',
    },
    [theme.breakpoints.between(1350, 1455)]: {
      minWidth: '7.125vw',
    },
    [theme.breakpoints.between(1455, 1700)]: {
      minWidth: '5vw',
    },
  }),
);

export const HeaderBulkOptionTableCell = styled(TableCell)(
  ({ theme }) => ({
    minWidth: '6vw',
    [theme.breakpoints.between('sm', 860)]: {
      minWidth: '12vw',
    },
    [theme.breakpoints.between(860, 1050)]: {
      minWidth: '11vw',
    },
    [theme.breakpoints.between(1050, 1350)]: {
      minWidth: '9vw',
    },
    [theme.breakpoints.between(1350, 1455)]: {
      minWidth: '7.125vw',
    },
    [theme.breakpoints.between(1455, 1700)]: {
      minWidth: '6vw',
    },
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCell as CSSProperties,
  }),
);

export const RemovedBorderTableHead = styled(TableHead)(
  () => ({
    ...themePublic.removedBorderTableHead as CSSProperties,
  }),
);

export const CheckBoxHeaderTableCellSelected = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxHeaderTableCell as CSSProperties,
    ...themePublic.checkBoxHeaderTableCellSelected as CSSProperties,
  }),
);

export const CheckBoxHeaderTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxHeaderTableCell as CSSProperties,
  }),
);

export const TitleHeaderTableCell = styled(TableCell)(
  ({ theme }) => ({
    minWidth: '6vw',
    fontWeight: 'bold !important',
    [theme.breakpoints.down('lg')]: {
      minWidth: '15vh',
    },
    [theme.breakpoints.between('sm', 860)]: {
      minWidth: '12vw',
    },
    [theme.breakpoints.between(860, 1050)]: {
      minWidth: '11vw',
    },
    [theme.breakpoints.between(1050, 1350)]: {
      minWidth: '9vw',
    },
    [theme.breakpoints.between(1350, 1455)]: {
      minWidth: '7.125vw',
    },
    [theme.breakpoints.between(1455, 1700)]: {
      minWidth: '6vw',
    },
  }),
);