import React, { SetStateAction, useRef, useState } from 'react';
import {
  Box,
  Link,
  Typography
} from '@mui/material';
import { fabric } from 'fabric';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { VALIDATION_RESTRICTION } from 'enums';
import { showToast } from 'helpers';

import {
  ActiveLabelFileUpload, DragAndDropTitle, DragFileElement, DraggableContainer, LabelFileUpload,
  NotDisplayed, UploadButton, UploadedFilesContainer
} from './DragDropFile.stled';

type DragDropProps = {
  files: File[],
  setFiles: React.Dispatch<SetStateAction<File[]>>
  buttonText: string,
  title: string,
  accept?: string,
  multiple?: boolean
}

export const DragDropFile = ({files, setFiles, buttonText, title, accept, multiple} : DragDropProps) => {
  const [t] = useTranslation();
  const inputRef = useRef(null);

  const [ dragActive, setDragActive ] = useState(false);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      showNotification(e.dataTransfer.files[0]);
      setFiles(e.dataTransfer.files);
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      showNotification(e.target.files[0]);
      setFiles(e.target.files);
    }
  };

  const showNotification = (uploadedFile: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = (e) => {
      const { result } = e.target;
      if (result) {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = result as string;
        image.onload = function() {
          const fabricImage = new fabric.Image(image);
          if (fabricImage.width > VALIDATION_RESTRICTION.FOUR_THOUSAND || fabricImage.height > VALIDATION_RESTRICTION.FOUR_THOUSAND) {
            showToast('info', t('imageDimensionWillBeResizedToTheMaxAllowed', { maxValue: VALIDATION_RESTRICTION.FOUR_THOUSAND }));
          }
        };
      }
    };
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <DraggableContainer onDragEnter={handleDrag}>
      <NotDisplayed
        inputRef={inputRef}
        type='file'
        inputProps={{ accept: accept || '', multiple: multiple || false}}
        onChange={handleChange}/>
      <Box component={dragActive ? ActiveLabelFileUpload : LabelFileUpload}>
        <Box>
          {!isMobile && <DragAndDropTitle>{title}</DragAndDropTitle>}
          <UploadButton as={Link} onClick={onButtonClick}>{buttonText}</UploadButton>
        </Box>
      </Box>
      {
        files &&
          <UploadedFilesContainer> {
            Array.from(files).map((file: File, index: number) =>
              <Typography key={index}>{file.name}</Typography>
            )}
          </UploadedFilesContainer>
      }
      { dragActive && <DragFileElement onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></DragFileElement> }
    </DraggableContainer>
  );
};