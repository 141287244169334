import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const PointerText = {
  ...themePublic.cursorPointer as CSSProperties,
  paddingTop: 0,
};

export const InvitationPaper = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    padding: '0.5rem 0.5rem 0.5rem 0.1rem',
  }),
);

export const InvitationPaperSelected = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    ...themePublic.tableMobilePaperConatinerSelected as CSSProperties,
    border: '2px solid transparent',
    padding: '0.5rem 0.5rem 0.5rem 0.1rem',
  }),
);

export const SelectInvitationAutocompleteContainer = styled(Box)(
  () => ({
    marginTop: '16px',
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    marginTop: '10px',
    padding: '8px 30px',
    width: '20%',
  }),
);
