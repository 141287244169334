import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  MenuItem,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ContactsModal, ImportContactsCsvModal, PublicContactEntryLinksModal, SelectFilter, VirtualKeyboard } from 'components';
import { FORM_TYPE } from 'enums';
import { defaultPositionForMenu } from 'helpers';
import { menuClosed, menuOpen, searchFilter } from 'resources';
import { useContactStore } from 'store';
import { themeDefault } from 'themeDefault';
import { TagType } from 'types';

import { Search as SearchIcon } from '@mui/icons-material';
import {
  ChipContainer, ChipStyled, ContactPageHeader,
  DividerStyled, FilterHeading, FilterImage, FilterItemContainer, FilterMenu, FilterSearchField,
  ImportingButton, ImportingContactPageHeader, MenuContent,
  SearchInputHeader, SubmenuButton, SubmenuContainer
} from './ContactsHeader.styled';

type ContactsHeaderProps = {
  importingProcess: boolean
  tagData: {
    tags: TagType[]
  }
  importContacts: (ids: number[]) => void
  handleClose?: () => void,
}

export const ContactsHeader = ({
  importingProcess,
  importContacts,
  tagData,
  handleClose,
}: ContactsHeaderProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const [ filterEl, setFilterEl ] = useState<null | HTMLElement>(null);
  const [ submenuEl, setSubmenuEl ] = useState<null | HTMLElement>(null);
  const [ openContactModal, setOpenContactModal ] = useState<boolean>(false);
  const [ importCsvModal, setOpenImportCsvModal ] = useState<boolean>(false);
  const [ sharedLinksModal, setSharedLinksModal ] = useState<boolean>(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const filterRef = useRef();
  const keyboardRef = useRef(null);
  const isOpenFilter = Boolean(filterEl);

  const { searchText, setSearchText, params, setParams, selectedItems: selectedContacts } = useContactStore();

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const openFilters = () => {
    setFilterEl(filterRef.current);
  };
  const closeFilters = () => {
    setFilterEl(null);
  };
  const isOpenMenu = Boolean(submenuEl);

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setSubmenuEl(e.currentTarget);
  };
  const closeMenu = () => {
    setSubmenuEl(null);
  };
  const openContactsModal = () => {
    setOpenContactModal(true);
    closeMenu();
  };
  const closeContactsModal = () => {
    setOpenContactModal(false);
  };
  const openImportCsvModal = () => {
    setOpenImportCsvModal(true);
    closeMenu();
  };
  const openSharedLinksModal = () => {
    setSharedLinksModal(true);
    closeMenu();
  };

  return (
    <>
      <Box component={importingProcess ? ImportingContactPageHeader : ContactPageHeader}>
        {!importingProcess && <Typography>{t('contacts')}</Typography>}
        <div ref={filterRef}>
          <SearchInputHeader
            onFocus={() => setShowKeyboard(true)}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={t('searchPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start' onClick={() => openFilters()}>
                  <FilterImage src={searchFilter} />
                </InputAdornment>
              ),
            }}
            variant='outlined'/>
        </div>
        <FilterMenu
          anchorEl={filterEl}
          open={isOpenFilter}
          onClose={closeFilters}
          anchorOrigin={{
            vertical: 0,
            horizontal: 0,
          }}
          transformOrigin={defaultPositionForMenu}>
          <FilterHeading onClick={closeFilters}>
            <FilterImage src={searchFilter} />
          </FilterHeading>
          <FilterItemContainer>
            <FilterSearchField
              onFocus={() => setShowKeyboard(true)}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder={t('searchPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant='outlined'/>
            <SelectFilter
              isDisabledTranslation={true}
              placeholder={t('selectTags')}
              selectedItems={params.selectedTags}
              handleSelectItem={(e) => setParams({...params, selectedTags: e as string[]})}
              items={tagData ? tagData.tags.map((item: TagType) => {
                return {id: item.id, title: item.name};
              }) : []}/>
          </FilterItemContainer>
        </FilterMenu>
        <Button
          component={importingProcess ? ImportingButton : Button}
          disabled={importingProcess && selectedContacts.length === 0}
          variant='contained'
          onClick={() => {
            if (importingProcess) {
              importContacts(selectedContacts);
            } else {
              localStorage.removeItem('activeId');
              handleNavigate('/invitations/create/invitation');
            }
            handleClose && handleClose();
          }}>
          {t(importingProcess ? 'importSelected' : 'createNewInvitationBtn')}
        </Button>
      </Box>
      {!importingProcess &&
      <SubmenuContainer>
        <Box>
          {params.selectedTags.map((tagName: string) => (
            <ChipStyled label={tagName} key={tagName} onDelete={() => setParams({...params, selectedTags: params.selectedTags.filter(item => item !== tagName)})} />
          ))}
        </Box>
        <div>
          <SubmenuButton
            aria-controls={isOpenMenu ? 'demo-positioned-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={isOpenMenu ? 'true' : undefined}
            onClick={openMenu}>
            {t('contactManagement')} <img src={menuClosed} />
          </SubmenuButton>
          <MenuContent
            anchorEl={submenuEl}
            open={isOpenMenu}
            onClose={closeMenu}
            anchorOrigin={defaultPositionForMenu}
            transformOrigin={defaultPositionForMenu}>
            <MenuItem onClick={closeMenu}>{t('contactManagement')} <img src={menuOpen} /></MenuItem>
            <DividerStyled color={themeDefault.palette.mainBackground} />
            <MenuItem onClick={openContactsModal}>{t('newContact')}</MenuItem>
            <DividerStyled color={themeDefault.palette.mainBackground} />
            <MenuItem onClick={openImportCsvModal}>{t('importFromCSV')}</MenuItem>
            <DividerStyled color={themeDefault.palette.mainBackground} />
            <MenuItem onClick={openSharedLinksModal}>{t('sharedLinks')}</MenuItem>
          </MenuContent>
        </div>
      </SubmenuContainer>}
      {importingProcess &&
      <ChipContainer>
        {params.selectedTags.map((tagName: string) => (
          <ChipStyled label={tagName} key={tagName} onDelete={() => setParams({...params, selectedTags: params.selectedTags.filter(item => item !== tagName)})} />
        ))}
      </ChipContainer>}
      { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          closeWhenOutOfFocus={showKeyboard}
          initialValues={{
            'searchText': searchText
          }}
          setFieldValue={null}
          setStandaloneItem={setSearchText}
          keyboardRef={keyboardRef}
          inputName={'searchText'}/>
      }
      {openContactModal &&
      <Dialog maxWidth='xl' open={openContactModal} onClose={closeContactsModal}>
        <DialogContent>
          <ContactsModal tagData={tagData} handleClose={closeContactsModal} type={FORM_TYPE.CREATE} />
        </DialogContent>
      </Dialog>
      }
      <Dialog maxWidth='xl' open={importCsvModal} onClose={() => setOpenImportCsvModal(false)}>
        <DialogContent>
          <ImportContactsCsvModal handleClose={() => setOpenImportCsvModal(false)} importingOnlyContacts={true} />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth='xl' open={sharedLinksModal} onClose={() => setSharedLinksModal(false)}>
        <DialogContent>
          <PublicContactEntryLinksModal handleClose={() => setSharedLinksModal(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};