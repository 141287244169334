import { CSSProperties } from 'react';
import { Close as CloseIcon, Edit as EditIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themePublic } from 'themeDefault';

export const TableCellWithoutBorderStyle = {
  border: 'none',
};

export const DialogStyled = styled(Dialog)(
  ( { theme }) => ({
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
    }
  }),
);

export const DialogContentStyled = styled(DialogContent)(
  ( { theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: '55vh',
    minHeight: '100px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      justifyContent: 'center',
      maxHeight: '100vh',
      padding: '1rem',
      width: '100%',
    }
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  ({theme}) => ({
    cursor: 'pointer',
    position: 'absolute',
    right: '0px',
    top: '20px',
    zIndex: 5000,
    [theme.breakpoints.down('sm')]: {
      top: '16px',
    }
  }),
);

export const EditIconStyled = styled(EditIcon)(
  () => ({
    ...themePublic.rsvpModalIcon as CSSProperties,
  }),
);
export const ViewIconStyled = styled(VisibilityIcon)(
  () => ({
    ...themePublic.rsvpModalIcon as CSSProperties,
  }),
);

export const FlexBoxStyled = styled(Box)(
  ({theme}) => ({
    ...themePublic.basicFlex as CSSProperties,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  }),
);

export const ContentContainer = styled(Box)(
  () => ({
    minWidth: '300px',
    width: '100%',
  }),
);

export const ModalHeaderTitle = styled(Typography)(
  () => ({
    fontWeight: 'bold',
  }),
);