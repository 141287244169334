import { Checkbox, TableCell, TableRow } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const TableRowStyledPreview = styled(TableRow)(
  () => ({
    ...themePublic.tableRowPreview as CSSProperties,
  }),
);

export const CheckboxTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxTableCellInRow as CSSProperties,
  }),
);

export const FirstTableCellHeaderSelected = styled(Checkbox)(
  () => ({
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const FirstTableCellClosed = styled(TableCell)(
  ({ theme }) => ({
    ...themePublic.firstTableCell as CSSProperties,
    maxWidth: '5vw',
    [theme.breakpoints.down(1700)]: {
      maxWidth: '7vw',
      minWidth: '5vw',
    },
  }),
);

export const BasicTableCell = styled(TableCell)(
  ({ theme }) => ({
    ...themePublic.basicTableCell as CSSProperties,
    maxWidth: '6vw',
    [theme.breakpoints.between(1400, 1700)]: {
      maxWidth: '8vw',
      minWidth: '8vw',
    },
    [theme.breakpoints.between('sm', 1400)]: {
      maxWidth: '8.5vw',
      minWidth: '8.5vw',
    },
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCellGroup as CSSProperties,
    maxWidth: '3vw',
  }),
);
