import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Dialog } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ContactsHeader,
  ContactsModal,
  ContactsTable,
  DefaultButton,
  Loader,
  MobileContactsHeading,
  MobileFilters,
  MobileImportContactsCsvModal,
  MobilePagination,
  MobileSearch,
  PublicContactEntryLinksModal,
  TablePagination
} from 'components';
import { useAuth } from 'contexts';
import { FORM_TYPE, NUMBER_OF_ITEMS_PER_PAGE, ORDER_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { contactsColumns } from 'helpers';
import { GET_TAGS, graphLazyQueryMiddleware } from 'services';
import { useContactStore, useGuestStore } from 'store';
import { themePublic } from 'themeDefault';
import { ContactColumnType, FilterConfigProps, TagType, UseAuthProps } from 'types';

import {
  ContactsPageBody,
  ContactsPageContent,
  FullPageMobile,
  ImportingAndContactsPageContent,
  ImportingContactsPageBody,
  ImportingContactsPageContentMobile,
  ImportingTableContainerMobile,
  TableContainerMobile
} from './ContactsPage.styled';

type ContactsPageProps = {
  importingProcess: boolean,
  handleClose?: () => void,
}

export const ContactsPage = ({importingProcess = false, handleClose}: ContactsPageProps) => {
  const [t] = useTranslation();
  const urlParams = useParams();
  const { saveUserSettings }: UseAuthProps = useAuth();

  const [ getTags, { data: tagData, refetch: refetchTags }] = graphLazyQueryMiddleware(GET_TAGS);

  const {
    params,
    setParams,
    pageInfo,
    isExecutedTagsRefetch,
    changeExecuteTagsRefetch,
    setSearchText,
    searchText,
    contactForEditing,
    setContactForEditing,
    isLoading,
    selectedItems: selectedContacts,
    setSelectedItems: setSelectedContacts
  } = useContactStore();

  const { updateGuests } = useGuestStore();

  const [ openFilters, setOpenFilters ] = useState(false);
  const [ isOpenCreateModal, setIsOpenCreateModal ] = useState(false);
  const [ isOpenImportCsvModal, setIsOpenImportCsvModal ] = useState(false);
  const [ isEditModal, setIsEditModal ] = useState(false);
  const [ sharedLinksModal, setSharedLinksModal ] = useState(false);

  const getFilteredContacts = (orderBy: string, order: ORDER_TYPE) => {
    setParams({
      ...params,
      order: order,
      orderBy: orderBy
    });
    const tableType = importingProcess ? USER_SETTINGS_CONFIG.IMPORT_EXISTING_CONTACTS_SEND_PAGE : USER_SETTINGS_CONFIG.CONTACTS_TABLE;
    saveUserSettings(tableType, orderBy, order);
  };

  useEffect(() => {
    getTags({ variables: {
      filter: {
        type: 'CONTACT'
      }
    }});
  }, []);

  useEffect(() => {
    if (!isExecutedTagsRefetch) {
      refetchTags();
      changeExecuteTagsRefetch();
    }
  }, [isExecutedTagsRefetch]);

  const importContacts = () => {
    updateGuests(selectedContacts, Number(urlParams.id));
    setSelectedContacts([]);
  };

  const filterConfig: FilterConfigProps[] = [
    {
      id: 0,
      label: t('selectTags'),
      body: tagData && tagData.tags.map((item: TagType) => ({ id: item.id, title: item.name})),
      multiple: true,
      selectedItems: params.selectedTags,
      handleSelectItem: (tags) => setParams({...params, selectedTags: tags as string[]}),
      placeholder: t('selectTags'),
      isDisabledTranslation: true
    },
  ];

  if (isMobile && isOpenCreateModal) {
    return (
      <FullPageMobile>
        <ContactsModal tagData={tagData} type={FORM_TYPE.CREATE} handleClose={() => setIsOpenCreateModal(false)} />
      </FullPageMobile>
    );
  }
  if (isMobile && isEditModal) {
    return (
      <FullPageMobile>
        <ContactsModal type={FORM_TYPE.EDIT} tagData={tagData} handleClose={() => setIsEditModal(false)} contact={contactForEditing} />
      </FullPageMobile>
    );
  }
  if (isMobile && isOpenImportCsvModal) {
    return (
      <MobileImportContactsCsvModal handleClose={() => setIsOpenImportCsvModal(false)} importingOnlyContacts={true}/>
    );
  }
  if (isMobile && !!contactForEditing) {
    return (
      <FullPageMobile>
        <ContactsModal type={FORM_TYPE.EDIT} tagData={tagData} handleClose={() => setContactForEditing(null)} contact={contactForEditing} />
      </FullPageMobile>
    );
  }
  if (isMobile && sharedLinksModal) {
    return (
      <Dialog open fullScreen disablePortal>
        <PublicContactEntryLinksModal handleClose={() => setSharedLinksModal(false)} />
      </Dialog>
    );
  }

  return (
    isMobile ?
      <ContactsPageBody>
        <Box component={importingProcess ? ImportingContactsPageContentMobile : ContactsPageContent}>
          <MobileSearch
            theme={{...themePublic.mobileSearchContainer, ... importingProcess ? themePublic.importingProcessSearchInput : themePublic.fixedPosition} as CSSProperties}
            setSearchText={setSearchText}
            searchText={searchText}
            handleOpenFilters={setOpenFilters}
            showFilters={true}/>
          {!importingProcess &&
          <MobileContactsHeading
            openCreateModal={() => setIsOpenCreateModal(true)}
            openImportCsvContactsModal={() => setIsOpenImportCsvModal(true)}
            openSharedLinksModal={setSharedLinksModal} />}
          <Box component={importingProcess ? ImportingTableContainerMobile : TableContainerMobile}>
            <ContactsTable importingProcess={importingProcess} />
            {!isLoading &&
              <MobilePagination
                hasPreviousPage={pageInfo.hasPreviousPage}
                hasNextPage={pageInfo.hasNextPage}
                setPage={(page) => setParams({...params, page: page})}
                setRowsPerPage={(rowsPerPage) => setParams({...params, rowsPerPage: rowsPerPage})}
                page={params.page}
                rowsLength={pageInfo.totalCount}
                rowsPerPage={params.rowsPerPage}/>}
          </Box>
          {importingProcess &&
            <DefaultButton title={t('importSelected')} handleFunction={() => {
              importContacts();
              handleClose && handleClose();
            }} theme={themePublic.importSelectedContactsButton as CSSProperties} />
          }
        </Box>
        <MobileFilters
          isOpen={openFilters}
          filterConfig={filterConfig}
          searchText={searchText}
          setSearchText={setSearchText}
          handleSortBy={(sortBy) => setParams({...params, orderBy: sortBy})}
          selectedSort={params.orderBy}
          order={params.order}
          changeParams={getFilteredContacts}
          handleClose={setOpenFilters}
          sortValues={contactsColumns.filter((item: ContactColumnType) => item.enableSorting).map(item => ({title: t(item.title), sortableName: item.sortableName}))}/>
        <Loader inProgress={isLoading} />
      </ContactsPageBody> :
      <Box component={importingProcess ? ImportingContactsPageBody : ContactsPageBody}>
        <ContactsHeader
          importContacts={importContacts}
          importingProcess={importingProcess}
          tagData={tagData}
          handleClose={handleClose} />
        <Box component={importingProcess ? ImportingAndContactsPageContent : ContactsPageContent}>
          <ContactsTable importingProcess={importingProcess} />
          {!isLoading &&
            <TablePagination
              invitationToShow={NUMBER_OF_ITEMS_PER_PAGE}
              setPage={(page) => setParams({...params, page: page})}
              setRowsPerPage={(rowsPerPage) => setParams({...params, rowsPerPage: rowsPerPage})}
              page={params.page}
              rowsLength={pageInfo.totalCount}
              rowsPerPage={params.rowsPerPage}
              tableType={importingProcess ? USER_SETTINGS_CONFIG.IMPORT_EXISTING_CONTACTS_SEND_PAGE : USER_SETTINGS_CONFIG.CONTACTS_TABLE} />}
        </Box>
      </Box>
  );
};
