import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { LinkedInvitationResponse } from 'components';
import { RESPONSE_STATUS, RESPONSE_STATUS_COLORS } from 'enums';
import { TRACK_CHART_LABELS_CONFIG } from 'helpers';
import { useTrackStore } from 'store';
import { CumulativeResponses, EviteFilterType, InvitationProps } from 'types';

import { CardsContainer } from './LinkedInvitationsResponses.styled';

type LinkedInvitationsResponsesProps = {
  setSelectedEvitesResponses: (selectedEvitesResponses: any) => void,
  linkedInvitations: InvitationProps[],
  evitesConfigsDict: any,
  setEvitesConfigsDict: (evitesConfigsDict: any) => void,
}

export const LinkedInvitationsResponses = ({
  setSelectedEvitesResponses,
  linkedInvitations,
  evitesConfigsDict,
  setEvitesConfigsDict,
} : LinkedInvitationsResponsesProps) => {
  const [t] = useTranslation();
  const { cumulativeEvitesResponses } = useTrackStore();
  const selectedEvitesResponsesRef = useRef<EviteFilterType[]>();

  useEffect(() => {
    if (!cumulativeEvitesResponses) {
      return;
    }

    const evitesConfigsDictCopy: { [key: string]: any } = {};
    cumulativeEvitesResponses?.forEach((cr: CumulativeResponses) => {
      evitesConfigsDictCopy[cr.eviteId] = {
        series: [
          cr.numberOfNotSent,
          cr.numberOfPending,
          cr.numberOfNotAnswered,
          cr.numberOfAttending,
          cr.numberOfNotAttending,
          cr.numberOfMazelTowOnly,
        ],
        options: {
          labels: TRACK_CHART_LABELS_CONFIG.map(item => t(item)),
          colors: [
            RESPONSE_STATUS_COLORS.NOT_SENT,
            RESPONSE_STATUS_COLORS.PENDING,
            RESPONSE_STATUS_COLORS.NOT_ANSWERED,
            RESPONSE_STATUS_COLORS.ATTENDING,
            RESPONSE_STATUS_COLORS.NOT_ATTENDING,
            RESPONSE_STATUS_COLORS.MAZEL_TOW_ONLY,
          ],
          dataLabels: {
            enabled: false
          },
          chart: {
            events: {
              dataPointSelection: (e: any, chartContext: any, config: any) => {
                const selectedStatus = config.selectedDataPoints[0].map((selectedIndex: number) => (
                  Object.keys(RESPONSE_STATUS)[Object.values(RESPONSE_STATUS).indexOf(TRACK_CHART_LABELS_CONFIG[selectedIndex] as unknown as RESPONSE_STATUS)]
                ));

                let selectedEvitesResponsesCopy: EviteFilterType[] = selectedEvitesResponsesRef.current ? [...selectedEvitesResponsesRef.current] : [];
                selectedEvitesResponsesCopy = selectedEvitesResponsesCopy.filter((item: any) => item.eviteId !== cr.eviteId);
                setSelectedEvitesResponses([ ...selectedEvitesResponsesCopy ? [...selectedEvitesResponsesCopy] : [], {
                  eviteId: cr.eviteId,
                  responseStatuses: selectedStatus
                }]);
              }
            },
          },
          legend: {
            show: true,
            position: isMobile ? 'left' as const : 'right' as const,
            horizontalAlign: isMobile ? 'left' as const : 'right' as const,
            formatter: function(label: string, opts: any) {
              return label + ' ' + opts.w.globals.series[opts.seriesIndex];
            },
          },
          states: {
            active: {
              allowMultipleDataPointsSelection: true
            }
          },
          tooltip: {
            enabled: false,
            onDatasetHover: {
              highlightDataSeries: false
            }
          }
        },
      };
    });
    setEvitesConfigsDict(evitesConfigsDictCopy);
  }, [cumulativeEvitesResponses]);

  return (
    <CardsContainer>
      {linkedInvitations && linkedInvitations.length > 0 &&
      linkedInvitations.map((linkedInvitation: InvitationProps) => (
        <LinkedInvitationResponse
          key={linkedInvitation.id}
          linkedInvitation={linkedInvitation}
          evitesConfigsDict={evitesConfigsDict} />
      ))}
    </CardsContainer>
  );
};
