import { create } from 'zustand';

import { CumulativeResponses, EviteType, InvitationProps } from 'types';

type EvitesDictType = {
    [key: string]: EviteType[]
}

type TrackStore = {
    extendedInvitations: number[],
    setExtendedInvitations: (ids: number[]) => void,
    enabledInvitations: number[],
    setEnabledInvitations: (ids: number[]) => void,
    linkedInvitations: InvitationProps[],
    setLinkedInvitations: (invitations: InvitationProps[]) => void,
    enabledInvitationsEvitesDict: EvitesDictType,
    setEnabledInvitationsEvitesDict: (item: EvitesDictType) => void,
    cumulativeEvitesResponses: CumulativeResponses[],
    setCumulativeEvitesResponses: (responses: CumulativeResponses[]) => void,
    isExecutedRefetch: boolean,
    changeExecuteRefetch: () => void,
};

export const useTrackStore = create<TrackStore>((set, get) => (
  {
    extendedInvitations: [],
    setExtendedInvitations: (ids) => set({extendedInvitations: ids}),
    enabledInvitations: [],
    setEnabledInvitations: (ids) => set({enabledInvitations: ids}),
    linkedInvitations: [],
    setLinkedInvitations: (invitations) => set({linkedInvitations: invitations}),
    enabledInvitationsEvitesDict: {},
    setEnabledInvitationsEvitesDict: (item) => set({enabledInvitationsEvitesDict: item}),
    cumulativeEvitesResponses: [],
    setCumulativeEvitesResponses: (responses) => set({cumulativeEvitesResponses: responses}),
    isExecutedRefetch: false,
    changeExecuteRefetch: () => set({isExecutedRefetch: !get().isExecutedRefetch}),
  }
));