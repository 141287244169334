import React, { useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ActionMenu, ConfirmationModal, CustomTooltip } from 'components';
import { format, formatDistanceToNow, isPast, parseISO } from 'date-fns';
import { showToast } from 'helpers';
import { useTranslation } from 'react-i18next';
import { PublicContactEntryAuthorisationType } from 'types';

import {
  ActiveText, BasicTableCell, ChipStyled, CopyButton, CopyButtonIcon, HeaderFirstTableCellClosed,
  InactiveText, LastTableCell, ThemeOverlappedText, TooltipMessageStyled, TooltipStyle
} from './PublicContactEntryLinksTableRow.styled';

type Props = {
  item: PublicContactEntryAuthorisationType;
  onStartEdit?: () => void;
  onDelete?: () => Promise<void>;
};

const MAX_FULLVIEW_TAGS = 2;

export const PublicContactEntryLinksTableRow = ({
  item,
  onStartEdit,
  onDelete,
}: Props) => {
  const [t] = useTranslation();

  const [ deleteModal, setDeleteModal ] = useState(false);
  const [ deleting, setDeleting ] = useState(false);

  const onCopyLink = () => {
    const loc = window.location;
    const url = `${loc.protocol}//${loc.host}/gather-contacts?token=${item.token}`;
    navigator.clipboard.writeText(url)
      .then(
        () => showToast('success', t('sharedLinkCopySucceeded')),
        () => showToast('error', t('sharedLinkCopyFailed')),
      );
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await onDelete?.();
      setDeleteModal(false);
      showToast('success', t('sharedLinkDeleteSucceeded'));
    } catch (err) {
      showToast('error', t('sharedLinkDeleteFailed'));
    } finally {
      setDeleting(false);
    }
  };

  const parsedExpirationDate = item.expirationDate && parseISO(item.expirationDate);
  const derivedStatus = parsedExpirationDate && isPast(parsedExpirationDate) ? 'EXPIRED' : item.activityStatus;

  return (
    <>
      <TableRow>
        <HeaderFirstTableCellClosed>
          <CustomTooltip theme={ThemeOverlappedText} text={item.name || '--'} />
        </HeaderFirstTableCellClosed>
        <BasicTableCell>
          {item.expirationDate &&
            <TooltipMessageStyled
              title={format(parsedExpirationDate, 'MM/dd/yyyy HH:mm')}>
              <Typography>{formatDistanceToNow(parsedExpirationDate, { addSuffix: true })}</Typography>
            </TooltipMessageStyled>
          }
          {!item.expirationDate &&
            <Typography>--</Typography>
          }
        </BasicTableCell>
        <BasicTableCell>
          <Typography component={derivedStatus === 'ACTIVE' ? ActiveText : InactiveText}>
            &#9679; {t(derivedStatus.toLowerCase())}
          </Typography>
        </BasicTableCell>
        <BasicTableCell>
          <Box>
            {item.tags?.slice(0, MAX_FULLVIEW_TAGS).map((tag, i) => (
              <ChipStyled key={i} label={tag.name} />
            ))}
            {item.tags?.length > MAX_FULLVIEW_TAGS &&
              <Tooltip
                sx={TooltipStyle}
                title={item.tags.slice(MAX_FULLVIEW_TAGS).map((tag) => tag.name).join(', ')}>
                <ChipStyled label={`+ ${item.tags.length - MAX_FULLVIEW_TAGS}`} />
              </Tooltip>
            }
          </Box>
        </BasicTableCell>
        <BasicTableCell>
          <CopyButton onClick={onCopyLink}>
            <CopyButtonIcon />
            {t('copy')}
          </CopyButton>
        </BasicTableCell>
        <LastTableCell>
          <ActionMenu propsData={[
            {
              id: 0,
              title: t('edit'),
              icon: Edit,
              functionHandler: () => onStartEdit?.(),
            },
            {
              id: 1,
              title: t('delete'),
              icon: Delete,
              functionHandler: () => setDeleteModal(true),
            },
          ]}/>
        </LastTableCell>
      </TableRow>
      <TableRow>
        <TableCell />
      </TableRow>
      <ConfirmationModal
        loading={deleting}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleDelete}
        confimMessage={t('sharedLinkDeleteConfirmMessage')} />
    </>
  );
};
