import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Step,
  StepButton,
  StepIconProps,
  StepLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Loader, DragDropFile, ImportCsvContactsReport } from 'components';
import { useInvitation } from 'contexts';
import { showToast } from 'helpers';
import { downloadTemplateIcon } from 'resources';
import { graphMutationMiddleware, IMPORT_CSV_GUESTS, UPLOAD_CONTACTS_CSV } from 'services';
import { useContactStore, useGuestStore } from 'store';
import { InvitationContextType } from 'types';

import {
  ActiveStepContainerStyled, ButtonImageContainer, CommentText, DownloadIcon,
  NavigationButton, PaperCircle, ReportContainer, StepContainerStyled, StepText, StepperStyled, TemplateButton, UploaderContainer
} from './ImportContactsCsvModal.styled';

type ImportContactsCsv = {
  handleClose: () => void,
  importingOnlyContacts?: boolean,
}

export const ImportContactsCsvModal = ({ handleClose, importingOnlyContacts=false }: ImportContactsCsv) => {
  const [t] = useTranslation();
  const { changeExecuteRefetch: refetchContacts } = useContactStore();
  const { changeExecuteRefetch: refetchGuests } = useGuestStore();
  const { invitationDetails }: InvitationContextType = useInvitation();
  const [ uploadCsv, { loading: uploading }] = graphMutationMiddleware(importingOnlyContacts ? UPLOAD_CONTACTS_CSV : IMPORT_CSV_GUESTS);

  const [ activeStep, setActiveStep ] = useState(0);
  const steps = [ t('chooseAFile'), t('result') ];
  const [ files, setFiles ] = useState<File[]>([]);
  const [ hasSelectedFiles, setHasSelectedFiles ] = useState<boolean>(false);
  const [ uploadedData, setUploadedData ] = useState<any>({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    setHasSelectedFiles(files.length > 0);
  }, [files]);

  const uploadFile = () => {
    const variables = importingOnlyContacts ? { file: files[0] } : {
      file: files[0],
      invitationId: invitationDetails.id
    };

    uploadCsv({ variables: variables })
      .then((res) => {
        setUploadedData(importingOnlyContacts ? res.data.uploadCsv : res.data.importCsvGuests);
        refetchContacts();
        refetchGuests();
        showToast('info', t('importCsvFinished'));
      });
  };

  const downloadCsvTemplate = () => {
    const link = document.createElement('a');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    link.href = `${window.UREVITED_APP_CORE_URL}/api/v1/file/contactcsv`;
    link.click();
  };

  function colorlibStepIcon(props: StepIconProps) {
    const { active, completed } = props;
    return (
      <Container component={active || completed ? ActiveStepContainerStyled : StepContainerStyled}>
        <PaperCircle>
          <StepText>{props.icon}</StepText>
        </PaperCircle>
      </Container>
    );
  }

  return (
    <>
      <Loader loadingPage={false} inProgress={uploading} />
      <StepperStyled activeStep={activeStep} alternativeLabel connector={null} >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={index} {...stepProps}>
              <StepButton onClick={() => setActiveStep(index)}>
                <StepLabel StepIconComponent={colorlibStepIcon}>{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </StepperStyled>
      {activeStep === 0 ?
        <>
          <UploaderContainer>
            <Box sx={{paddingTop: '20px', paddingBottom: '20px'}}>
              <CommentText>{t('uploadCsvFile')}</CommentText>
              <DragDropFile
                files={files}
                setFiles={setFiles}
                buttonText={t('browseFile')}
                title={t('dragAndDropHere')}
                accept='.csv'/>
            </Box>
          </UploaderContainer>
          <Box>
            <TemplateButton
              onClick={downloadCsvTemplate}>
              <ButtonImageContainer>
                <DownloadIcon src={downloadTemplateIcon} />
                {t('downloadCsvTemplate')}
              </ButtonImageContainer>
            </TemplateButton>
            <NavigationButton
              disabled={!hasSelectedFiles}
              onClick={() => {
                handleNext();
                uploadFile();
              }}>{t('import')}
            </NavigationButton>
          </Box>
        </>
        :
        <>
          <ReportContainer>
            <ImportCsvContactsReport uploadedData={uploadedData} />
          </ReportContainer>
          <NavigationButton
            onClick={handleClose}>
            {t('close')}
          </NavigationButton>
        </>
      }
    </>
  );
};
