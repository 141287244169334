import React, { useEffect, useRef, useState } from 'react';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
  Box,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { InputField, Loader, TagSelector, VirtualKeyboard } from 'components';
import { FORM_TYPE, VALIDATION_RESTRICTION } from 'enums';
import { Prompt, getIdFromRoute, tagsAreChanged } from 'helpers';
import {
  ADD_INVITATION_TEMPLATE,
  GET_COLORS,
  GET_TAGS,
  GET_TYPES,
  UPDATE_INVITATION_TEMPLATE,
  graphLazyQueryMiddleware,
  graphMutationMiddleware,
  graphQueryMiddleware,
} from 'services';
import { ColorType, FormikMethodsTypes, InvitationTempalteDetailsForm, InvitationTemplateProps, TagType, TypesOfInvitationTemplate } from 'types';

import {
  ButtonContainer, ContentContainer, FormContainer, InputGroupContainer, ListItemTextColor, MenuItemSelected,
  PageBody, PageContent, SelectFormControl, SelectGroupContainer, SubmitButton, TitleContainer
} from './InvitationTemplateDetailsPage.styled';

type CreateInvitationTemplatePageProps = {
  type: FORM_TYPE.CREATE | FORM_TYPE.EDIT,
  refetch: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<InvitationTemplateProps>>,
  invitationTemplate: InvitationTemplateProps,
  invitationTemplateStatus: string,
  setInvitationTemplateStatus: (invitatioNTemplateStatus: string) => void,
  loadingInvitationTemplateData: boolean,
};

export const InvitationTemplateDetailsPage = ({ type, refetch, invitationTemplate, invitationTemplateStatus, setInvitationTemplateStatus, loadingInvitationTemplateData } : CreateInvitationTemplatePageProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const params = useParams();
  const submitRef = useRef();
  const keyboardRef = useRef(null);
  const formRef = useRef(null);

  const [ createInvitationTemplate, { loading: createLoading }] = graphMutationMiddleware(ADD_INVITATION_TEMPLATE);
  const [updateInvitationTemplate] = graphMutationMiddleware(UPDATE_INVITATION_TEMPLATE);
  const { data: colorsData } = graphQueryMiddleware(GET_COLORS);
  const [ getTags, { data: tagData }] = graphLazyQueryMiddleware(GET_TAGS);
  const { data: typesData } = graphQueryMiddleware(GET_TYPES);

  const [ invColors, setInvColors ] = useState<string[] | string>([]);
  const [ invitationTemplateType, setInvitationTemplateType ] = useState<number>(1);
  const [ selectedTags, setSelectedTags ] = useState([]);
  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ dirtyGlobal, setDirtyGlobal ] = useState(false);
  const [ haveDirtyFields, setHaveDirtyFields ] = useState(false);
  const [ initialValues, setInitialValues ] = useState({
    name: type === FORM_TYPE.EDIT && invitationTemplate ? invitationTemplate.name : '',
    description: type === FORM_TYPE.EDIT && invitationTemplate ? invitationTemplate.description : '',
    typeId: type === FORM_TYPE.EDIT && invitationTemplate?.typeId,
  });

  useEffect(() => {
    getTags({ variables: {
      filter: {
        type: 'INVITATION_TEMPLATE'
      }
    }});

  }, []);

  useEffect(() => {
    setHaveDirtyFields(initialValues.typeId !== invitationTemplateType || colorsAreChanged() || tagsAreChanged(invitationTemplate?.tags, selectedTags));
  }, [ selectedTags.length, invColors.length, invitationTemplateType, formRef?.current?.values?.typeId ]);

  useEffect(() => {
    if (!invitationTemplate) {
      return;
    }
    setInitialValues({
      name: invitationTemplate.name,
      description: invitationTemplate.description,
      typeId: invitationTemplate.typeId,
    });
    setInvColors(invitationTemplate ? invitationTemplate.colors.map((col: string) => col.toUpperCase()) : []);
    setInvitationTemplateType(invitationTemplate ? invitationTemplate.typeId : 1);
    setSelectedTags(type === FORM_TYPE.EDIT ? invitationTemplate?.tags.map((tag: TagType) => tag.name) : []);
  }, [invitationTemplate]);

  useEffect(() => {
    if (invitationTemplateStatus) {
      submitForm();
      setInvitationTemplateStatus(undefined);
    }
  }, [invitationTemplateStatus]);

  const invitationTemplateDetailsSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('name'), length: VALIDATION_RESTRICTION.HUNDRED })),
    description: Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.THOUSAND, t('stringPropertyMaxValidation', { propertyName: t('description'), length: VALIDATION_RESTRICTION.THOUSAND })),
  });

  const submitForm = () => {
    if (submitRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      submitRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const handleCreate = (values: InvitationTempalteDetailsForm, { setSubmitting }: FormikMethodsTypes) => {
    createInvitationTemplate({ variables: {
      input: {
        name: values.name,
        description: values.description,
        colors: invColors.length === 0 ? ['WHITE'] : typeof invColors === 'string' ? [invColors.toUpperCase()] : invColors.map(c => c.toUpperCase()),
        typeId: invitationTemplateType,
        tags: selectedTags
      }}
    }).then((res) => {
      setHaveDirtyFields(false);
      setSubmitting(false);
      handleNavigate(`/invitationTemplates/create/design/${res.data.addInvitationTemplate.id}`);
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const changedInvitationTemplateStatus = () => {
    return invitationTemplateStatus && invitationTemplateStatus !== invitationTemplate.status;
  };

  const handleEdit = (values: InvitationTempalteDetailsForm, { setSubmitting, resetForm }: FormikMethodsTypes) => {
    const editInvitationTemplate = values.typeId !== invitationTemplateType || colorsAreChanged() || tagsAreChanged(invitationTemplate?.tags, selectedTags);
    if (!dirtyGlobal && !editInvitationTemplate && !changedInvitationTemplateStatus()) {
      setSubmitting(false);
      handleNavigate(`/invitationTemplates/edit/design/${getIdFromRoute(params['*'])}`);
      return;
    }

    updateInvitationTemplate({variables: {
      input: {
        id: invitationTemplate.id,
        name: values.name,
        description: values.description,
        colors: invColors.length === 0 ? ['WHITE'] : typeof invColors === 'string' ? [invColors.toUpperCase()] : invColors.map(c => c.toUpperCase()),
        typeId: invitationTemplateType,
        tags: selectedTags,
        status: invitationTemplateStatus ?? invitationTemplate.status
      }}
    }).then(res => {
      setHaveDirtyFields(false);
      setSubmitting(false);
      resetForm();
      refetch({id: res.data.updateInvitationTemplate.id});
      if (!changedInvitationTemplateStatus()) {
        handleNavigate(`/invitationTemplates/edit/design/${getIdFromRoute(params['*'])}`);
      }
    }).catch(() => {
      setSubmitting(false);
    });
  };

  const handleChangeColor = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    setInvColors(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeInvitationTemplateType = (e: SelectChangeEvent) => {
    setInvitationTemplateType(parseInt(e.target.value));
  };

  const formatedColors = colorsData?.colors && colorsData.colors.map((color: ColorType) => ({id: color.id, title: color.value, hexValue: color.hexValue}));
  const formatedTypes = typesData?.types && typesData.types.map((invType: TypesOfInvitationTemplate) => ({id: invType.id, name: invType.name}));

  if (loadingInvitationTemplateData || createLoading) {
    return <Loader inProgress={loadingInvitationTemplateData || createLoading} />;
  }

  const showForm = () => {
    return !loadingInvitationTemplateData && (invitationTemplate || type === FORM_TYPE.CREATE);
  };

  const colorsAreChanged = () => {
    if (invitationTemplate?.colors.length !== invColors.length) {
      return true;
    }

    const retValue = typeof(invColors) === 'string' ?
      !invitationTemplate?.colors.includes(invColors) :
      invColors.some(newValue => {
        return !invitationTemplate?.colors.includes(newValue);
      });

    return retValue;
  };

  return (
    showForm() &&
    <PageBody>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={invitationTemplateDetailsSchema}
        onSubmit={type === FORM_TYPE.CREATE ? handleCreate : handleEdit}>
        {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }) => {
          return (
            <>
              <PageContent>
                <ContentContainer>
                  <TitleContainer>
                    <Typography variant='h5'>{type === FORM_TYPE.EDIT ? t(FORM_TYPE.EDIT) : t(FORM_TYPE.CREATE)}</Typography>
                  </TitleContainer>
                  <FormContainer>
                    <Box>
                      <Form autoComplete='off' ref={submitRef}>
                        <InputGroupContainer>
                          <Box>
                            <InputField
                              setShowKeyboard={setShowKeyboard}
                              setInputName={() => setInputName('name')}
                              inputId='name'
                              inputName='name'
                              label={t('name')}
                              type='text' />
                          </Box>
                          <Box>
                            <InputField
                              setShowKeyboard={setShowKeyboard}
                              setInputName={() => setInputName('description')}
                              inputId='description'
                              inputName='description'
                              label={t('description')}
                              type='text' />
                          </Box>
                        </InputGroupContainer>
                        <SelectGroupContainer>
                          <SelectFormControl size='small'>
                            <InputLabel>{t('color')}</InputLabel>
                            <Select
                              multiple
                              value={invColors}
                              renderValue={(selected) => typeof selected === 'string' ? t(selected) : selected.map(s => t(s)).join(', ')}
                              label={t('color')}
                              onChange={handleChangeColor}>
                              {formatedColors?.map((item: ColorType) =>
                                <MenuItem key={item.id} value={item.title} component={invColors.indexOf(item.title) > -1 ? MenuItemSelected : MenuItem}>
                                  <div style={{ width: 13, height: 13, marginRight: 5, backgroundColor: item.hexValue }}></div>
                                  <ListItemTextColor primary={t(item.title)} />
                                  <Checkbox checked={invColors.includes(item.title)} />
                                </MenuItem>
                              )}
                            </Select>
                          </SelectFormControl>
                          <SelectFormControl size='small'>
                            <InputLabel>{t('invitationTemplateType')}</InputLabel>
                            <Select
                              value={invitationTemplateType && invitationTemplateType.toString()}
                              label={t('invitationTemplateType')}
                              onChange={handleChangeInvitationTemplateType}>
                              {formatedTypes?.map((invType: TypesOfInvitationTemplate) => <MenuItem component={invType.id === invitationTemplateType ? MenuItemSelected : MenuItem} key={invType.id} value={invType.id}>{t(invType.name)}</MenuItem>)}
                            </Select>
                          </SelectFormControl>
                        </SelectGroupContainer>
                        <TagSelector
                          setShowKeyboardParent={setShowKeyboard}
                          tagData={tagData && tagData}
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags} />
                        {showKeyboard &&
                          <VirtualKeyboard
                            setShowKeyboard={setShowKeyboard}
                            initialValues={values}
                            setFieldValue={setFieldValue}
                            keyboardRef={keyboardRef}
                            inputName={inputName}/>}
                        <Prompt when={(dirty || haveDirtyFields) && !dirtyGlobal} options={{
                          title: t('leavePage'),
                          message: t('unsavedChanges'),
                          buttons: [
                            {
                              label: 'Confirm',
                              continue: true
                            },
                            {
                              label: 'Cancel',
                            }
                          ],
                        }} />
                      </Form>
                    </Box>
                  </FormContainer>
                </ContentContainer>
              </PageContent>
              <ButtonContainer>
                <SubmitButton
                  type='submit'
                  disableElevation
                  disabled={Object.keys(errors).length > 0 || isSubmitting || !isValid || (type === FORM_TYPE.CREATE && !dirty)}
                  onClick={() => {
                    setDirtyGlobal(dirty);
                    submitForm();
                  }} >
                  {t('nextDesign')}
                </SubmitButton>
              </ButtonContainer>
            </>
          );
        }}
      </Formik>
    </PageBody>
  );
};