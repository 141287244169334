import { gql } from '@apollo/client';

export const GET_VENUES = gql`
query Venues($filter: VenuesFilterInput, $order: [VenuePayloadSortInput!], $skip: Int, $take: Int) {
  venues (
    filter: $filter
    order: $order
    skip: $skip
    take: $take
    )
  {
    items {
      id
      name
      address1
      address2
      city
      state
      zipCode
      displayedAddress
      status
      createdById
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`;

export const GET_VENUES_IDS = gql`
query Venues($filter: VenuesFilterInput, $skip: Int, $take: Int) {
  venues (
    filter: $filter
    skip: $skip
    take: $take
    )
  {
    items {
      id
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

