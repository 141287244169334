import React from 'react';
import { DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, Loader } from 'components';
import { themePublic } from 'themeDefault';

import {
  ButtonContainer, CancelButton, CloseIconStyled, ConfirmButton, DialogStyled,
  ModalHeaderContainer, ModalHeaderTitle
} from './ConfirmationModal.styled';

type ConfirmationModalProps = {
  isOpen: boolean
  handleClose: () => void
  confimMessage: string
  handleConfirm: () => void
  loading?: boolean
};

export const ConfirmationModal = ({isOpen, handleClose, confimMessage, handleConfirm, loading}: ConfirmationModalProps) => {
  const [t] = useTranslation();

  return (
    <DialogStyled maxWidth='md' open={isOpen} onClose={handleClose}>
      <Loader loadingPage={false} inProgress={loading} />
      <DialogContent>
        <ModalHeaderContainer>
          <ModalHeaderTitle variant='h6'>
            <CustomTooltip theme={themePublic.customTooltipText} text={confimMessage} />
          </ModalHeaderTitle>
          <CloseIconStyled onClick={handleClose}/>
        </ModalHeaderContainer>
        <ButtonContainer>
          <ConfirmButton
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
            variant='contained'>
            {t('confirm')}
          </ConfirmButton>
          <CancelButton
            onClick={handleClose}
            variant='outlined'>
            {t('cancel')}
          </CancelButton>
        </ButtonContainer>
      </DialogContent>
    </DialogStyled>
  );
};