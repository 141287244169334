import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const TextFieldStyled = styled(TextField)(
  () => ({
    '& input': {
      ...themePublic.mobileSearchInput as CSSProperties,
    }
  }),
);
