import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { showToast } from 'helpers';

// eslint-disable-next-line no-shadow
enum TOAST_LEVEL {
    ERROR = 0,
    WARNING = 1,
    INFO = 2
}

export const graphqlErrorHandler = (errorResponse: ErrorResponse) => {

  const handleError = (error: GraphQLError) => {
    if (!error.extensions) {
      return { level: TOAST_LEVEL.ERROR, message: error.message };
    }

    switch (error.extensions.code) {
      case 'AUTH_NOT_AUTHENTICATED':
        return { level: TOAST_LEVEL.ERROR, message: error.message };

      case 'ENTITY_NOT_FOUND':
        window.location.href=('/notFound?message=' + encodeURI(error.extensions.message.toString()));
        return;

      case 'ACCESS_VIOLATION':
        window.location.href=('/notFound?message=' + encodeURI('Entity was not found'));
        return;

      default:
        return { level: TOAST_LEVEL.ERROR, message: error.extensions.message ? error.extensions.message.toString() : error.message };
    }
  };

  if (errorResponse.graphQLErrors) {
    errorResponse.graphQLErrors.forEach(error => {
      const response = handleError(error);
      response && showToast(TOAST_LEVEL[response.level as number].toLowerCase(), response.message);
    });
  }

  if (errorResponse.networkError) {
    showToast('error', errorResponse.networkError.message);
  }
};