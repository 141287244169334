import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const RegisterContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    backgroundColor: themeDefault.palette.mainBackground,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100vw',
  }),
);

export const FormikContainer = styled(Box)(
  ({ theme }) => ({
    backgroundColor: themeDefault.palette.whiteColor,
    borderRadius: '20px',
    boxSizing: 'border-box',
    height: 'auto',
    padding: '48px',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
      width: '85%',
    },
  }),
);

export const FormContainer = styled(Box)(
  () => ({
    width: '100%',
  }),
);

export const InputGroup = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '40px',
  }),
);

export const InputContainer = styled(Box)(
  ({ theme }) => ({
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }),
);

export const ButtonsContainer = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  }),
);

export const SignUpButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
    height: 'auto',
    border: '2px solid',
    borderColor: themeDefault.palette.mainColor,
    marginBottom: '1rem',
    marginTop: '1.5rem',
    padding: '0.5rem',
    textTransform: 'capitalize',
    width: '100%',
  }),
);

export const LoginButton = styled(Button)(
  () => ({
    ...themePublic.secondarySubmitButton as CSSProperties,
  }),
);
