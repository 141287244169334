import React from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputField } from 'components';
import { VALIDATION_RESTRICTION } from 'enums';

import { CommentTextModal, InputGroup, SubmitButton } from './CanvasResizeModal.styled';

type SizeProps = {
    width: number
    height: number
}

type CanvasResizeProps = {
    initSize: SizeProps
    setSize: (size: SizeProps) => void
    handleClose: () => void
}

export const CanvasResizeModal = ({initSize, setSize, handleClose} : CanvasResizeProps) => {
  const [t] = useTranslation();

  const initialValues = {
    width: Number(initSize.width).toFixed(),
    height: Number(initSize.height).toFixed(),
  };

  const canvasSizeSchema = Yup.object().shape({
    width: Yup.number()
      .required(t('required'))
      .min(VALIDATION_RESTRICTION.HUNDRED, t('numberPropertyMinValidation', { propertyName: t('width'), minValue: VALIDATION_RESTRICTION.HUNDRED }))
      .max(VALIDATION_RESTRICTION.FOUR_THOUSAND, t('numberPropertyMaxValidation', { propertyName: t('width'), maxValue: VALIDATION_RESTRICTION.FOUR_THOUSAND })),
    height: Yup.number()
      .required(t('required'))
      .min(VALIDATION_RESTRICTION.HUNDRED, t('numberPropertyMinValidation', { propertyName: t('height'), minValue: VALIDATION_RESTRICTION.HUNDRED }))
      .max(VALIDATION_RESTRICTION.FOUR_THOUSAND, t('numberPropertyMaxValidation', { propertyName: t('height'), maxValue: VALIDATION_RESTRICTION.FOUR_THOUSAND }))
  });

  const handleSave = (values: any) => {
    setSize({width: values.width, height: values.height});
    handleClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={canvasSizeSchema}
      onSubmit={handleSave}>
      {({ errors, isSubmitting, isValid, dirty }) => {
        return (
          <Box>
            <Form autoComplete='off'>
              <CommentTextModal>{t('setBackgroundSize')}</CommentTextModal>
              <InputGroup>
                <Box>
                  <InputField
                    inputId='width'
                    inputName='width'
                    label={t('width')}
                    type='number' />
                </Box>
                <Box>
                  <InputField
                    inputId='height'
                    inputName='height'
                    label={t('height')}
                    type='number' />
                </Box>
              </InputGroup>
              <SubmitButton
                type='submit'
                disabled={Object.keys(errors).length > 0 || isSubmitting || !(isValid && dirty)} >
                {t('save')}
              </SubmitButton>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};