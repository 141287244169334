import React, { useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { defaultPositionForMenu } from 'helpers';
import { menuClosed, menuOpen } from 'resources';
import { themeDefault } from 'themeDefault';

import { DividerStyled, HiddenButton, MenuButton, MenuContent } from './MobileContactsHeader.styled';

type MobileContactsHederProps = {
  openCreateModal: (openModal: boolean) => void
  openImportCsvContactsModal: (openModal: boolean) => void
  openSharedLinksModal: (openModal: boolean) => void
};
export const MobileContactsHeading = ({openCreateModal, openImportCsvContactsModal, openSharedLinksModal}: MobileContactsHederProps) => {
  const [t] = useTranslation();
  const [ submenuEl, setSubmenuEl ] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(submenuEl);

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setSubmenuEl(e.currentTarget);
  };
  const closeMenu = () => {
    setSubmenuEl(null);
  };

  const openCreateContactsModal = () => {
    openCreateModal(true);
    closeMenu();
  };

  const openImportCsvModal = () => {
    openImportCsvContactsModal(true);
    closeMenu();
  };

  const handleOpenSharedLinksModal = () => {
    openSharedLinksModal(true);
    closeMenu();
  };

  return (
    <>
      <Box
        component={isOpenMenu ? HiddenButton : MenuButton}
        aria-controls={isOpenMenu ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={isOpenMenu ? 'true' : undefined}
        onClick={openMenu}>
        {t('contactManagement')} <img src={menuClosed} />
      </Box>
      <MenuContent
        anchorEl={submenuEl}
        open={isOpenMenu}
        onClose={closeMenu}
        anchorOrigin={defaultPositionForMenu}
        transformOrigin={defaultPositionForMenu}>
        <MenuItem onClick={closeMenu}>{t('contactManagement')} <img src={menuOpen} /></MenuItem>
        <DividerStyled color={themeDefault.palette.mainBackground} />
        <MenuItem onClick={openCreateContactsModal}>{t('newContact')}</MenuItem>
        <DividerStyled color={themeDefault.palette.mainBackground} />
        <MenuItem onClick={openImportCsvModal}>{t('importFromCSV')}</MenuItem>
        <DividerStyled color={themeDefault.palette.mainBackground} />
        <MenuItem onClick={handleOpenSharedLinksModal}>{t('sharedLinks')}</MenuItem>
      </MenuContent>
    </>
  );
};