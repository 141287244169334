import { gql } from '@apollo/client';

export const GET_INVITATIONS = gql`
query Invitations($order: [InvitationPayloadSortInput!], $customOrder: CustomOrderInput, $filter: InvitationFilterInput, $skip: Int, $take: Int) {
  invitations (
    order: $order
    customOrder: $customOrder
    filter: $filter
    skip: $skip
    take: $take
    )
  {
    items {
      id
      name
      description
      activityStatus
      envelope {
        id
        contentJson
        musicId
      }
      envelope {
        id
        contentJson
        musicId
      }
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      typeId
      colors
      status
      response
      link
      eventDate
      isOpened
      tags {
        name
      }
      senders {
        id
        title
        firstName
        lastName
        isDefault
      }
      locationInfo {
        id
        createdAt
        modifiedAt
        startDate
        endDate
        venue {
          id
          name
          address1
          address2
          city
          state
          zipCode
          displayedAddress
          status
          createdById
        }
      }
      additionalInfo
      eventLinkId
      eventLinkName
      gifts {
        url
        invitationId
        createdAt
        modifiedAt
        id
      }
      giftRegistryNote
      evites {
        id
        name
        contentJson
        isSelected
        requestRsvp
        isDefault
        position
        invitationId
        image {
          id
          name
          url
          thumbnailUrl
          key
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`;

export const GET_INVITATIONS_IDS = gql`
query Invitations($order: [InvitationPayloadSortInput!], $customOrder: CustomOrderInput, $filter: InvitationFilterInput, $skip: Int, $take: Int) {
  invitations (
    order: $order
    customOrder: $customOrder
    filter: $filter
    skip: $skip
    take: $take
    )
  {
    items {
      id
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

export const GET_INVITATION_BY_ID = gql`
query Invitation($id: Long!) {
  invitation(id: $id) {
    id
    name
    description
    activityStatus
    isOpened
    envelope {
      id
      contentJson
      image {
        id
        url
      }
      musicId
    }
    evites {
      id
      name
      contentJson
      isSelected
      requestRsvp
      isDefault
      position
      invitationId
      image {
        id,
        name
        url
        thumbnailUrl
        key
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
    typeId
    colors
    status
    response
    link
    eventDate
    tags {
      id
      name
    }
    senders {
      id
      title
      firstName
      lastName
      isDefault
    }
    locationInfo {
      id
      createdAt
      modifiedAt
      startDate
      endDate
      venue {
        id
        name
        address1
        address2
        city
        state
        zipCode
        displayedAddress
        status
        createdById
      }
    }
    additionalInfo
    eventLinkId
    eventLinkName
    gifts {
      url
      invitationId
      createdAt
      modifiedAt
      id
    }
    giftRegistryNote
    guests {
      id
      contact {
        id
        address
        city
        email
        firstName
        lastName
        phoneNumber
        state
        street
        tags {
          id
          name
          type
        }
        title
        zipCode
        contacts {
          id
          address
          city
          email
          firstName
          lastName
          phoneNumber
          state
          street
          tags {
            id
            name
            type
          }
          title
          zipCode
        }
      }
    }
  }
}
`;

export const GET_STAMPS = gql`
query Stamps {
  stamps {
    id
    name
    url
    thumbnailUrl
  }
}
`;

export const GET_MUSIC = gql`
query Music {
  music {
    id
    name
    url
  }
}
`;

export const GET_LINKED_INVITATIONS = gql`
query LinkedInvitations($id: Long!) {
  linkedInvitations(id: $id) {
    items{
      id
      name
      evites {
        id
        name
        isSelected
        requestRsvp
      }
    }
  }
}
`;
