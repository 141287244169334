import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const ToastHeading = styled('p')(
  () => ({
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: '0',
    textTransform: 'capitalize',
  }),
);

export const ToastParagraph = styled('p')(
  () => ({
    ...themeDefault.overflowText,
    fontSize: '14px',
    lineClamp: 10,
    margin: '0',
    wordBreak: 'break-word',
  }),
);

export const Link = styled('span')(
  () => ({
    color: themeDefault.palette.linkColor,
  }),
);
