import React from 'react';
import {
  FormControl,
  MenuItem,
  Select
} from '@mui/material';
import { FIRST_PAGE } from 'helpers';

import {
  PaginationFirstPageArrow,
  PaginationLastPageArrow,
  PaginationNextPageArrow,
  PaginationPreviousPageArrow,
  PaginationSection, SelectContainer
} from './MobilePagination.styled';

type TablePaginationProps = {
  hasPreviousPage: boolean
  hasNextPage: boolean
  rowsLength: number
  rowsPerPage: number
  page: number
  setRowsPerPage: (e: number) => void
  setPage: (e: number) => void
}

export const MobilePagination = ({ rowsLength, page, rowsPerPage, setRowsPerPage, hasPreviousPage, setPage, hasNextPage }: TablePaginationProps) => {
  const count = rowsLength / rowsPerPage;
  const allPages = [];

  for (let i = 0; i < count; i++) {
    allPages.push(i+1);
  }

  const handleChangePage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(parseInt(e.target.value, 10));
  };

  const getNavigationArrowOpacity = (hasNecessaryPage: boolean) => {
    return hasNecessaryPage ? 1 : 0.5;
  };

  return (
    rowsLength > 0 &&
    <PaginationSection>
      <PaginationFirstPageArrow opacity={getNavigationArrowOpacity(hasPreviousPage)} onClick={() => hasPreviousPage && setPage(FIRST_PAGE)}/>
      <PaginationNextPageArrow opacity={getNavigationArrowOpacity(hasPreviousPage)} onClick={() => hasPreviousPage && setPage(page - 1)}/>
      <SelectContainer>
        <FormControl sx={{ m: 1, display: 'flex' }}>
          <Select
            size='small'
            value={page}
            onChange={handleChangePage}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
            }}
            displayEmpty>
            {allPages.map(item => (
              <MenuItem key={item} disabled={item === page} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectContainer>
      <PaginationPreviousPageArrow opacity={getNavigationArrowOpacity(hasNextPage)} onClick={() => hasNextPage && setPage(page+1)}/>
      <PaginationLastPageArrow opacity={getNavigationArrowOpacity(hasNextPage)} onClick={() => hasNextPage && setPage(Math.ceil(count))}/>
    </PaginationSection>
  );
};