import React, { ElementType, useEffect, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Checkbox, Dialog, DialogContent, TableCell, TableCellBaseProps, TableRow } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ActionMenu, ConfirmationModal, ContactsModal, CustomTooltip } from 'components';
import { FORM_TYPE } from 'enums';
import { calculateMenuPlacement } from 'helpers';
import { DELETE_CONTACTS, GET_TAGS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { useContactStore } from 'store';
import { ContactType } from 'types';

import {
  BasicTableCell, CheckboxTableCell, FirstTableCell, ImportingBasicTableCell,
  OptionTableCell, ThemeMultipleLinesText, ThemeOverlappedText, TitleTableCell
} from './TableRowContact.styled';

interface TableRowProps {
  row: ContactType,
  handleCheckboxSelect: (id: number) => void,
  isContactSelected: (id: number) => boolean,
  importingProcess: boolean,

}

export const TableRowContact = ({row, handleCheckboxSelect, isContactSelected, importingProcess}: TableRowProps) => {
  const [t] = useTranslation();
  const { changeExecuteRefetch } = useContactStore();

  const [ deleteContact, { loading: deleteLoading }] = graphMutationMiddleware(DELETE_CONTACTS);
  const [ getTags, { data: tagData }] = graphLazyQueryMiddleware(GET_TAGS);

  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ editGroup, setEditGroup ] = useState<boolean>(false);

  useEffect(() => {
    getTags({ variables: {
      filter: {
        type: 'CONTACT'
      }
    }});
  }, []);

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: Edit,
      functionHandler: () => setEditGroup(true)
    },
    {
      id: 1,
      title: t('delete'),
      icon: Delete,
      functionHandler: () => setDeleteModal(true)
    },
  ];

  const handleDelete = () => {
    deleteContact({variables: {
      contactId: row.id
    }}).then(() => {
      changeExecuteRefetch();
      setDeleteModal(false);
    });
  };

  return (
    <>
      <TableRow selected={isContactSelected(row.id)}>
        <CheckboxTableCell>
          <Checkbox checked={isContactSelected(row.id)} onClick={() => handleCheckboxSelect(row.id)}/>
        </CheckboxTableCell>
        <FirstTableCell />
        <TitleTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={row.lastName} />
        </TitleTableCell>
        <TableCell component={(importingProcess ? ImportingBasicTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={row.firstName} />
        </TableCell>
        <TableCell component={(importingProcess ? ImportingBasicTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={row.email} />
        </TableCell>
        <TableCell component={(importingProcess ? OptionTableCell : BasicTableCell) as ElementType<TableCellBaseProps>}>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={row.phoneNumber} />
        </TableCell>
        {!importingProcess &&
        <OptionTableCell>
          <ActionMenu propsData={actionMenuProps} menuPlacement={calculateMenuPlacement(isMobile)}/>
        </OptionTableCell>}
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
      </TableRow>
      {editGroup &&
      <Dialog maxWidth='xl' open={editGroup} onClose={() => setEditGroup(false)}>
        <DialogContent>
          <ContactsModal contact={row} tagData={tagData} handleClose={() => setEditGroup(false)} type={FORM_TYPE.EDIT} />
        </DialogContent>
      </Dialog>
      }
      <ConfirmationModal
        loading={deleteLoading}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleDelete}
        confimMessage={`${t('deletionConfirmMessage')} ${row.firstName || ''} ${row.lastName || ''}`}/>
    </>
  );
};