import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, ConfirmationModal, CreateGuestModal, CustomTooltip, RsvpStatusModal } from 'components';
import { RESPONSE_STATUS } from 'enums';
import { calculateMenuPlacement, checkIsAnyEviteSelected } from 'helpers';
import { REMOVE_GUEST, graphMutationMiddleware } from 'services';
import { useGuestStore, useTrackStore } from 'store';
import { themePublic } from 'themeDefault';
import { EviteResponseType, EviteType, GuestType, InvitationProps } from 'types';

import {
  BoldInfoData, ContactMobilePaperContainer, ContactMobilePaperContainerSelected,
  DataContainer, DataContainerMobile, DataEviteName, DataInfoContainer, DataInfoContainerMobile, DividerStyled,
  EvitesContainer, InfoContainerMobile, LabelName, PersonIconStyled,
  ThemeMultipleLinesText, ThemeOverlappedText, TypographyCustomTooltip
} from './SingleMobileGuest.styled';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Mail as MailIcon,
  Send as SendIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';

type ContactsTableProps = {
  sendToOneContact: (guestId: number) => void
  guest: GuestType
  invitation: InvitationProps
  forPreview: boolean
  isTrackPage?: boolean
  enabledEvites?: EviteType[],
}
export const SingleMobileGuest = ({
  guest,
  invitation,
  sendToOneContact,
  forPreview = false,
  isTrackPage = false,
  enabledEvites = [],
}: ContactsTableProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { selectedGuests, setSelectedGuests } = useGuestStore();
  const { changeExecuteRefetch: refetchResponses } = useTrackStore();

  const [removeGuest] = graphMutationMiddleware(REMOVE_GUEST);

  const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
  const [ isOpenEditModal, setIsOpenEditModal ] = useState(false);
  const [ showMore, setShowMore ] = useState(false);
  const [ isOpenRsvpStatusModal, setIsOpenRsvpStatusModal ] = useState(false);
  const [ isEditRSVP, setIsEditRSVP ] = useState(false);

  const handleOpenRsvpModal = (isEdit: boolean) => {
    setIsOpenRsvpStatusModal(true);
    setIsEditRSVP(isEdit);
  };

  const deleteGuest = () => {
    removeGuest({variables: {
      guestId: Number(guest.id)
    }}).then(() => {
      refetchResponses();
    });
  };

  const actionMenuProps = [
    {
      id: 0,
      title: t('edit'),
      icon: EditIcon,
      functionHandler: () => setIsOpenEditModal(true)
    },
    {
      id: 1,
      title: t('send'),
      icon: SendIcon,
      functionHandler: () => checkIsAnyEviteSelected(guest, sendToOneContact, t('noEvitesSelectedFor', { entity: t('guest')}))
    },
    {
      id: 2,
      title: t('preview'),
      icon: VisibilityIcon,
      functionHandler: () => navigate(`/invitations/edit/preview/${invitation.id}`, { state: { guest: guest } })
    },
    {
      id: 3,
      title: t('viewRSVP'),
      icon: MailIcon,
      functionHandler: () => handleOpenRsvpModal(false)
    },
    {
      id: 4,
      title: t('editRSVP'),
      icon: EditIcon,
      functionHandler: () => handleOpenRsvpModal(true)
    },
    {
      id: 5,
      title: t('remove'),
      icon: DeleteIcon,
      functionHandler: () => setIsOpenDeleteModal(true)
    }
  ];

  return (
    <>
      <Paper elevation={2} component={selectedGuests.map(a => a.guestId).includes(guest.id) ? ContactMobilePaperContainerSelected : ContactMobilePaperContainer}>
        <DataContainerMobile>
          <DataInfoContainerMobile>
            <PersonIconStyled onClick={() => setSelectedGuests(guest)}/>
            <InfoContainerMobile onClick={() => setShowMore(!showMore)}>
              <BoldInfoData>
                <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={`${guest.contact.lastName} ${guest.contact.firstName ?? ''}`} />
              </BoldInfoData>
              <TypographyCustomTooltip>
                <CustomTooltip theme={ThemeOverlappedText} text={guest.contact.email} />
              </TypographyCustomTooltip>
              <TypographyCustomTooltip>
                <CustomTooltip theme={ThemeOverlappedText} text={guest.contact.phoneNumber} />
              </TypographyCustomTooltip>
            </InfoContainerMobile>
          </DataInfoContainerMobile>
          { actionMenuProps && !forPreview &&
          <ActionMenu propsData={actionMenuProps} iconTheme={themePublic.actionMenuIconStyle} disablePortal isTrackPage={isTrackPage} menuPlacement={calculateMenuPlacement(isMobile)} />
          }
        </DataContainerMobile>
        {showMore &&
        <>
          <DividerStyled />
          <DataContainer>
            {isTrackPage &&
            <DataInfoContainer>
              <LabelName>{t('sender')}: </LabelName>
              <span>
                <CustomTooltip theme={ThemeOverlappedText} text={`${guest?.sender?.firstName ?? ''} ${guest?.sender?.lastName}`} />
              </span>
            </DataInfoContainer>
            }
            <DataInfoContainer>
              <LabelName>{t(isTrackPage ? 'event' : 'lastSent')}: </LabelName>
              <span>
                <CustomTooltip
                  theme={ThemeOverlappedText}
                  text={isTrackPage ?
                    invitation.name :
                    guest.lastSent && format(parseISO(guest.lastSent), t('dateFormatUI'))} />
              </span>
            </DataInfoContainer>
            {isTrackPage ?
              <EvitesContainer>
                {guest?.evites.map((evite: EviteType) => {
                  return enabledEvites.find(eev => eev.id === evite.id) &&
                  <DataInfoContainer key={evite.id}>
                    <DataEviteName>
                      <CustomTooltip theme={ThemeOverlappedText} text={`${evite.name}: `} />
                    </DataEviteName>
                    <span>
                      <CustomTooltip theme={ThemeOverlappedText} text={guest?.responses.length > 0 && guest?.responses[0].eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id) ?
                        t(guest?.responses[0].eviteResponse.find((er: EviteResponseType) => er.evite.id === evite.id).status) :
                        t(RESPONSE_STATUS.NOT_SENT)} />
                    </span>
                  </DataInfoContainer>;
                })}
              </EvitesContainer> :
              <DataInfoContainer>
                <LabelName>{t('evites')}: </LabelName>
                <span>
                  <CustomTooltip
                    theme={ThemeOverlappedText}
                    text={guest?.evites.filter((evite: EviteType) => evite.isSelected).length > 0 && guest.evites.filter((evite: EviteType) => evite.isSelected).map((item: EviteType) => item.name).join(', ')} />
                </span>
              </DataInfoContainer>}
            {!isTrackPage &&
            <DataInfoContainer>
              <LabelName>{t('sender')}: </LabelName>
              <span>
                <CustomTooltip theme={ThemeOverlappedText} text={`${guest?.sender?.firstName ?? ''} ${guest?.sender?.lastName}`} />
              </span>
            </DataInfoContainer>
            }
          </DataContainer>
        </>}
      </Paper>
      {isOpenRsvpStatusModal && <RsvpStatusModal guest={guest} isOpen={true} handleClose={() => setIsOpenRsvpStatusModal(false)} isEdit={isEditRSVP} />}
      <ConfirmationModal isOpen={isOpenDeleteModal} handleClose={() => setIsOpenDeleteModal(false)} confimMessage={t('confirmDeleteMessage')} handleConfirm={deleteGuest} />
      {isOpenEditModal &&
      <CreateGuestModal guest={guest} handleClose={() => setIsOpenEditModal(false)} isOpen={isOpenEditModal} />
      }
    </>
  );
};