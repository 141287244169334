import React from 'react';
import i18next from 'i18next';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  split
} from '@apollo/client';

import { App } from 'components';
import { AuthProvider } from 'contexts';
import { graphqlErrorHandler, auth, SUBSCRIPTION, OPERATION_DEFINITION, MUTATION } from 'helpers';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { themeDefault, themePublic } from './themeDefault';

import './index.css';

// Setup sentry
Sentry.init({
  dsn: window.REACT_APP_SENTRY_DNS,
  environment: window.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    ),
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: window.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
});

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    const isMutation =
      operation &&
      operation.query &&
      operation.query.definitions &&
      Array.isArray(operation.query.definitions) &&
      operation.query.definitions.some(
        def =>
          def.kind === OPERATION_DEFINITION &&
          def.operation === MUTATION
      );

    if (isMutation) {
      return !!error && count < 25;
    }

    return !!error && count < 6;
  },
});

const errorLink = onError(errorResponse => {
  graphqlErrorHandler(errorResponse);
});

const httpLink = retryLink.concat(
  createUploadLink({
    uri: window.UREVITED_APP_CORE_URL + '/graphql',
  })
);

const wsLink = new WebSocketLink(
  new SubscriptionClient(window.UREVITED_WEBSOCKET_CORE_URL + '/graphql'), {
    reconnect: true
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === OPERATION_DEFINITION &&
      definition.operation === SUBSCRIPTION
    );
  },
  wsLink,
  httpLink,
);

const authMiddleware = new ApolloLink(async (operation, forward) => {
  const token = await auth.currentUser?.getIdToken();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}` || null,
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([ authMiddleware, errorLink, splitLink ]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={createTheme({...themeDefault, ...themePublic})}>
    <StyledEngineProvider injectFirst>
      <I18nextProvider i18n={i18next}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ApolloProvider client={client}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ApolloProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </I18nextProvider>
    </StyledEngineProvider>
  </ThemeProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

