import React, { CSSProperties, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  InvitationListTable,
  InvitationsHeader,
  MobileFilters,
  MobilePagination,
  MobileSearch,
  TablePagination
} from 'components';
import { useAuth } from 'contexts';
import { ACTIVITY_STATUS, INVITATION_STATUS, NUMBER_OF_ITEMS_PER_PAGE, ORDER_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { FIRST_PAGE, invitationColumns } from 'helpers';
import { GET_TAGS, GET_TYPES, graphQueryMiddleware } from 'services';
import { useInvitationStore } from 'store';
import { themePublic } from 'themeDefault';
import { FilterConfigProps, TagType, UseAuthProps } from 'types';

import { AddNewInvitationButtonMobile, InvitationListPageBody, InvitationListPageContent } from './InvitationListPage.styled';
import { Add as AddIcon } from '@mui/icons-material';

export const InvitationListPage = () => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const { saveUserSettings }: UseAuthProps = useAuth();
  const { params, setParams, searchText, setSearchText, pageInfo, isLoading, selectedItems: selectedInvitations } = useInvitationStore();

  const [ openFilters, setOpenFilters ] = useState(false);

  const { data: tagData } = graphQueryMiddleware(GET_TAGS);
  const { data: typesData } = graphQueryMiddleware(GET_TYPES);

  const getFilteredInvitations = (orderBy: string, order: ORDER_TYPE) => {
    setParams({
      ...params,
      order: order,
      orderBy: orderBy
    });
    saveUserSettings(USER_SETTINGS_CONFIG.INVITATIONS_TABLE, orderBy, order);
  };

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  const handleSetParams = (key: string, value: string | string[] | number | number[]) => {
    setParams({
      ...params,
      [key]: value
    });
  };

  const filterConfig: FilterConfigProps[] = [
    {
      id: 0,
      label: t('selectTags'),
      body: tagData && tagData.tags.map((item: TagType) => ({ id: item.id, title: item.name})),
      multiple: true,
      selectedItems: params.selectedTags,
      handleSelectItem: (selectedTags : string[]) => {
        setParams({
          ...params,
          selectedTags: selectedTags
        });
      },
      placeholder: t('selectTags'),
      isDisabledTranslation: true
    },
    {
      id: 1,
      label: t('selectTypes'),
      body: typesData && typesData.types.map((item: TagType) => ({ id: item.id, title: item.name})),
      multiple: true,
      selectedItems: params.selectedTypes,
      handleSelectItem: (item) => handleSetParams('selectedTypes', item),
      placeholder: t('selectTypes'),
      isDisabledTranslation: true
    },
    {
      id: 2,
      label: t('selectStatus'),
      body: (Object.keys(INVITATION_STATUS) as (keyof typeof INVITATION_STATUS)[]).map((item, index) => ({id: index, title: t(item)})),
      multiple: true,
      selectedItems: params.selectedStatus,
      handleSelectItem: (item) => handleSetParams('selectedStatus', item),
      placeholder: t('selectStatus'),
      isDisabledTranslation: false
    },
    {
      id: 3,
      label: t('selectActivityStatus'),
      body: (Object.keys(ACTIVITY_STATUS) as (keyof typeof ACTIVITY_STATUS)[]).map((item, index) => ({id: index, title: t(item)})),
      multiple: true,
      selectedItems: params.selectedActivityStatuses,
      handleSelectItem: (item) => handleSetParams('selectedActivityStatuses', item),
      placeholder: t('selectActivityStatus'),
      isDisabledTranslation: false
    },
  ];

  const header = isMobile ?
    <MobileSearch
      theme={themePublic.mobileSearchContainer as CSSProperties}
      setSearchText={setSearchText}
      searchText={searchText}
      handleOpenFilters={setOpenFilters}
      showFilters={true}/>
    :
    <InvitationsHeader tags={tagData && tagData.tags.map((item: TagType) => ({ id: item.id, title: item.name}))} />;

  const pagination = isMobile ?
    <MobilePagination
      hasPreviousPage={pageInfo.hasPreviousPage}
      hasNextPage={pageInfo.hasNextPage}
      setPage={(page: number) => handleSetParams('page', page)}
      setRowsPerPage={(rowsPerPage) => setParams({
        ...params,
        rowsPerPage: rowsPerPage,
        page: FIRST_PAGE
      })}
      page={params.page}
      rowsLength={pageInfo.totalCount}
      rowsPerPage={params.rowsPerPage}/>
    :
    <TablePagination
      invitationToShow={NUMBER_OF_ITEMS_PER_PAGE}
      rowsLength={pageInfo.totalCount}
      rowsPerPage={params.rowsPerPage}
      page={params.page}
      setRowsPerPage={(rowsPerPage) => setParams({
        ...params,
        rowsPerPage: rowsPerPage,
        page: FIRST_PAGE
      })}
      setPage={(page: number) => handleSetParams('page', page)}
      tableType={USER_SETTINGS_CONFIG.INVITATIONS_TABLE} />;

  return (
    <InvitationListPageBody>
      {header}
      <InvitationListPageContent>
        {
          selectedInvitations.length === 0 && isMobile && !isLoading &&
            <AddNewInvitationButtonMobile
              elevation={3}
              onClick={() => {
                handleNavigate('/invitations/create/invitation');
                localStorage.removeItem('activeId');
              }}>
              <AddIcon fontSize='large' />
            </AddNewInvitationButtonMobile>
        }
        <InvitationListTable allTypes={typesData && typesData.types} />
        {!isLoading && pagination}
      </InvitationListPageContent>
      <MobileFilters
        isOpen={openFilters}
        filterConfig={filterConfig}
        searchText={searchText}
        setSearchText={setSearchText}
        handleSortBy={(item) => handleSetParams('orderBy', item)}
        selectedSort={params.orderBy}
        order={params.order}
        changeParams={getFilteredInvitations}
        handleClose={setOpenFilters}
        sortValues={invitationColumns.filter((item) => item.enableSorting)
          .map(item => ({ title: t(item.title), sortableName: item.sortableName }))}/>
    </InvitationListPageBody>
  );
};