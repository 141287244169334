import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionMenu, ConfirmationModal, CreateInvitationModal, CustomTooltip, ImagePreview } from 'components';
import { useAuth, useInvitation } from 'contexts';
import { ACTION_MENU_INVIATTION_TEMPLATES, INVITATION_TEMPLATE_STATUS, USER_PERMISSIONS } from 'enums';
import { LIMIT_OF_POPULAR_INVITATION_TEMPLATE, calculateMenuPlacement, checkPermission, filterActionMenuByTitle } from 'helpers';
import { invitationTemplateBadge } from 'resources';
import { CHANGE_TEMPLATE, DELETE_INVITATION_TEMPLATE, DUPLICATE_INVITATION_TEMPLATE, UPDATE_INVITATION_TEMPLATE, graphMutationMiddleware } from 'services';
import { useTemplateStore } from 'store';
import { themePublic } from 'themeDefault';
import { ActionMenuPropsDataType, InvitationContextType, InvitationTemplateProps, UseAuthProps } from 'types';

import {
  Delete as DeleteIcon,
  RestorePage as DraftIcon,
  FileCopy as DuplicateIcon,
  Edit as EditIcon,
  CheckCircleOutline as PublishedIcon,
} from '@mui/icons-material';
import { ActionMenuConatiner, DateTypography, DraftLabel, InvitationItemContainer, ItemContainer, StarBadge } from './InvitationItem.styled';

type InvitationItemProps = {
  templateData: InvitationTemplateProps,
  isCreateInvitationProcess?: boolean,
  setShowCards?: (state: boolean) => void,
};

export const InvitationItem = ({templateData, isCreateInvitationProcess, setShowCards }: InvitationItemProps) => {
  const [t] = useTranslation();
  const handleNavigate = useNavigate();
  const { dbUser }: UseAuthProps = useAuth();
  const { setInvitationDetails }: InvitationContextType = useInvitation();
  const { changeExecuteRefetch } = useTemplateStore();

  const [changeTemplate] = graphMutationMiddleware(CHANGE_TEMPLATE);
  const [deleteInvitationTemplate] = graphMutationMiddleware(DELETE_INVITATION_TEMPLATE);
  const [duplicateInvitationTemplate] = graphMutationMiddleware(DUPLICATE_INVITATION_TEMPLATE);
  const [updateInvitationTemplate] = graphMutationMiddleware(UPDATE_INVITATION_TEMPLATE);

  const [ isCreateModalOpened, setIsCreateModalOpened ] = useState(false);
  const [ isDelete, setIsDelete ] = useState(false);
  const [ isConfirmModalOpened, setIsConfirmModalOpened ] = useState(false);

  let actionMenuProps: ActionMenuPropsDataType[] = [
    {
      id: 0,
      title: t(ACTION_MENU_INVIATTION_TEMPLATES.EDIT),
      icon: EditIcon,
      functionHandler: () => handleNavigate(`/invitationTemplates/edit/details/${templateData.id}`)
    },
    {
      id: 1,
      title: t(ACTION_MENU_INVIATTION_TEMPLATES.DUPLICATE),
      icon: DuplicateIcon,
      functionHandler: () => {
        setIsDelete(false);
        setIsConfirmModalOpened(true);
      }
    },
    {
      id: 2,
      title: t(ACTION_MENU_INVIATTION_TEMPLATES.PUBLISH),
      icon: PublishedIcon,
      functionHandler: () => {
        handleUpdateInvitationTemplateStatus(INVITATION_TEMPLATE_STATUS.PUBLISHED);
      }
    },
    {
      id: 3,
      title: t(ACTION_MENU_INVIATTION_TEMPLATES.UNPUBLISH),
      icon: DraftIcon,
      functionHandler: () => {
        handleUpdateInvitationTemplateStatus(INVITATION_TEMPLATE_STATUS.DRAFT);
      }
    },
    {
      id: 4,
      title: t(ACTION_MENU_INVIATTION_TEMPLATES.DELETE),
      icon: DeleteIcon,
      functionHandler: () => {
        setIsDelete(true);
        setIsConfirmModalOpened(true);
      }
    }
  ];

  if (templateData.status === INVITATION_TEMPLATE_STATUS.DRAFT.toUpperCase()) {
    actionMenuProps = filterActionMenuByTitle(actionMenuProps, ACTION_MENU_INVIATTION_TEMPLATES.UNPUBLISH);
  } else if (templateData.status === INVITATION_TEMPLATE_STATUS.PUBLISHED.toUpperCase()) {
    actionMenuProps = filterActionMenuByTitle(actionMenuProps, ACTION_MENU_INVIATTION_TEMPLATES.PUBLISH);
  }

  const handleUpdateInvitationTemplateStatus = (templateStatus: INVITATION_TEMPLATE_STATUS) => {
    updateInvitationTemplate({ variables: {
      input: {
        id: templateData.id,
        name: templateData.name,
        description: templateData.description,
        colors: templateData.colors,
        typeId: templateData.typeId,
        tags: templateData.tags.map(tag => tag.name),
        status: templateStatus.toUpperCase()
      }}
    }).then((res) => {
      templateData = res.data.updateInvitationTemplate;
    });
  };

  const handleDelete = () => {
    deleteInvitationTemplate({ variables: { templateId: templateData.id } }).then(() => {
      changeExecuteRefetch();
    });
    setIsConfirmModalOpened(false);
  };

  const handleDuplicate = () => {
    duplicateInvitationTemplate({ variables: { templateId: templateData.id } }).then(() => {
      changeExecuteRefetch();
    });
    setIsConfirmModalOpened(false);
  };

  const invitaitonUpdate = async () => {
    changeTemplate({
      variables: {
        id: Number(localStorage.getItem('activeId')),
        templateId: templateData.id
      }
    })
      .then(res => {
        setInvitationDetails(res.data.changeTemplate);
        setShowCards(true);
      });
  };

  return (
    <InvitationItemContainer>
      <ItemContainer
        onClick={() => {
          isCreateInvitationProcess && localStorage.getItem('activeId') ?
            invitaitonUpdate():
            setIsCreateModalOpened(true);
        }}
        sx={{ height: '100%' }}>
        {templateData.timesUsed > LIMIT_OF_POPULAR_INVITATION_TEMPLATE &&
          <StarBadge src={invitationTemplateBadge} alt={'Invitation Template Badge'}/>}
        {templateData.status === INVITATION_TEMPLATE_STATUS.DRAFT.toUpperCase() &&
          <DraftLabel>{t(INVITATION_TEMPLATE_STATUS.DRAFT)}</DraftLabel>}
        <ImagePreview invitation={templateData} forTablePreview={false} />
        <CustomTooltip theme={themePublic.invitationTemplateCardTitle} text={templateData.name} />
        <DateTypography>{t('created')}: {format(parseISO(templateData.createdAt), t('dateFormatUI'))}</DateTypography>
      </ItemContainer>
      {checkPermission(dbUser, USER_PERMISSIONS.EDIT_INVITATION_TEMPLATE) &&
          <ActionMenuConatiner>
            <ActionMenu
              propsData={actionMenuProps}
              iconTheme={themePublic.actionMenuIconStyle}
              disablePortal={true}
              menuPlacement={calculateMenuPlacement(true)}/>
          </ActionMenuConatiner>}
      <CreateInvitationModal
        templateData={templateData}
        isOpen={isCreateModalOpened}
        handleClose={() => setIsCreateModalOpened(false)}
        timesUsed={templateData.timesUsed}
        templateId={templateData.id}
        createdAt={format(parseISO(templateData.createdAt), t('dateFormatUI'))}
        createdBy={templateData.createdBy ? `${templateData.createdBy?.firstName} ${templateData.createdBy?.lastName}` : null}
        title={templateData.name}/>
      <ConfirmationModal
        loading={false}
        isOpen={isConfirmModalOpened}
        handleClose={() => setIsConfirmModalOpened(false)}
        handleConfirm={isDelete ? handleDelete : handleDuplicate}
        confimMessage={t(isDelete ? 'confirmDeleteMessage' : 'confirmDuplicateMessage')} />
    </InvitationItemContainer>
  );
};