import { CSSProperties } from 'react';
import { Table, TableContainer } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

import { themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const EmptyListTextStyle = {
  ...themePublic.emptyListText as CSSProperties,
  [themeLocal.breakpoints.down('sm')]: {
    margin: 0,
    marginTop: '5rem',
    textAlign: 'center'
  },
};

export const TableContainerStyled = styled(TableContainer)(
  ({ theme }) => ({
    [theme.breakpoints.between('sm', 900)]: {
      maxWidth: '72.125vw',
    },
    [theme.breakpoints.between(900, 1300)]: {
      maxWidth: '74.525vw',
    },
    [theme.breakpoints.between(1300, 1700)]: {
      maxWidth: '77.125vw',
    },
  }),
);

export const TableStyled = styled(Table)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);