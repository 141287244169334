import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { TooltipContainer, TypographyFont } from './CustomTooltip.styled';

type CustomTooltipProps = {
  theme?: CSSProperties,
  text: string,
  onClick?: (e: React.MouseEvent<Element>) => void
}

export const CustomTooltip = ({ theme = {}, text, onClick }: CustomTooltipProps) => {
  const tooltipTextElementRef = useRef<HTMLInputElement | null>(null);

  const [ hoverTooltipStatus, setHoverTooltipStatus ] = useState(false);

  useEffect(() => {
    compareTooltipTextSize();
    window.addEventListener('resize', compareTooltipTextSize);
  }, [text]);

  const compareTooltipTextSize = () => {
    const comparedValue = tooltipTextElementRef.current?.scrollHeight > tooltipTextElementRef.current?.clientHeight ||
      tooltipTextElementRef.current?.scrollWidth > tooltipTextElementRef.current?.clientWidth;
    setHoverTooltipStatus(comparedValue);
  };

  return (
    <TooltipContainer
      title={text ?? ''}
      disableHoverListener={!hoverTooltipStatus} >
      <TypographyFont
        ref={tooltipTextElementRef}
        sx={theme}
        onClick={onClick}>
        {text}
      </TypographyFont>
    </TooltipContainer>
  );
};