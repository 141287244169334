import { Box, Checkbox, TableCell, TableRow } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const CheckboxTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxTableCellInRow as CSSProperties,
  }),
);

export const FirstTableCellHeaderSelected = styled(Checkbox)(
  () => ({
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const FirstTableCell = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellGroup as CSSProperties,
    height: 'initial',
    minWidth: '5vw',
    maxWidth: '5vw',
    '& svg': {
      verticalAlign: 'middle',
    },
  }),
);

export const FirstTableCellClosed = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCell as CSSProperties,
    minWidth: '5vw',
    maxWidth: '5vw',
  }),
);

export const HeaderFirsTableCellContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around'
  }),
);

export const OpenedFirstTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openFirstTableCell as CSSProperties,
  }),
);

export const HeaderLastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.headerLastTableCellGroup as CSSProperties,
    maxWidth: '3vw',
    minWidth: '3vw',
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCellGroup as CSSProperties,
    width: '3vw',
    maxWidth: '3vw',
  }),
);

export const OpenLastRowStartTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openFirstTableCell as CSSProperties,
    borderRadius: '0 0 0 10px',
  }),
);

export const OpenLastRowEndTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openLastTableCell as CSSProperties,
    borderRadius: '0 0 10px 0',
  }),
);

export const OpenedLastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openLastTableCell as CSSProperties,
  }),
);

export const OpenedLastRowTableCellEnd = styled(TableCell)(
  () => ({
    ...themePublic.openLastTableCell as CSSProperties,
  }),
);

export const GroupBadge = styled(Box)(
  () => ({
    ...themePublic.groupBadge as CSSProperties,
  }),
);

export const TitleTableCell = styled(TableCell)(
  () => ({
    ...themePublic.titleTableCell as CSSProperties,
    ...themeDefault.overflowText,
    maxWidth: '6vw',
    minWidth: '6vw',
  }),
);

export const HeaderTitleTableCell = styled(TableCell)(
  () => ({
    ...themePublic.headerTitleTableCellGroup as CSSProperties,
    ...themeDefault.overflowText,
    maxWidth: '6vw',
    minWidth: '6vw',
  }),
);

export const OpenedBasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.openBasicTableCell as CSSProperties,
    maxWidth: '6vw',
    minWidth: '6vw',
  }),
);

export const TableRowStyled = styled(TableRow)(
  () => ({
    ...themePublic.groupTableRow as CSSProperties,
  }),
);

export const TableRowStyledPreview = styled(TableRowStyled)(
  () => ({
    ...themePublic.tableRowPreview as CSSProperties,
  }),
);
