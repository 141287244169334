import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const TableCellTrucate = styled('span')(
  () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
);

export const ResponseTableContainer = styled(Box)(
  () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
);

export const ErrorResponse = styled(Typography)(
  () => ({
    color: themeDefault.palette.redColor,
  }),
);
