import {
  Close as CloseIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon
} from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, InputLabel, Radio, Select, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

const TitleLabelStyle = {
  padding: '0',
  paddingRight: '0.25vw',
};

const RadioButtonStyle = {
  alignItems: 'center',
  color: themeDefault.palette.fieldLabelColor,
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '11.5%',
  padding: '0 0.5%',
  '& label': {
    marginLeft: 0,
    marginRight: 0,
  },
  [themeLocal.breakpoints.down('sm')]: {
    '& .MuiTypography-root': {
      fontSize: '12px',
    },
    '& .MuiRadio-root': {
      padding: '0 4px 0 0',
    },
    justifyContent: 'flex-start',
    marginLeft: 0,
    paddingTop: '2px',
    width: '45%',
  },
};

export const ContentContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    borderRadius: '10px',
    border: `1px solid ${themeDefault.palette.borderColor}`,
    boxShadow: `1px 2px ${themeDefault.palette.borderColor}`,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    minHeight: '10vh',
    padding: '1rem 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '1rem',
      padding: '1.5rem 1rem 1rem 1rem',
    }
  }),
);

export const RowContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      gap: '2rem',
      width: '100%',
    },
  }),
);

export const RadioButton = styled(FormControlLabel)(
  () => ({
    ...RadioButtonStyle as CSSProperties,
  }),
);

export const RadioContainer = styled(Box)(
  () => ({
    ...RadioButtonStyle as CSSProperties,
  }),
);

export const CheckedRadioButton = styled(RadioButtonCheckedIcon)(
  () => ({
    ...themePublic.radioButtonSenderDetailsPage as CSSProperties,
  }),
);

export const UncheckedRadioButton = styled(RadioButtonUncheckedIcon)(
  () => ({
    ...themePublic.radioButtonSenderDetailsPage as CSSProperties,
    color: themeDefault.palette.mainColor,
  }),
);

export const SenderTitle = styled('span')(
  () => ({
    ...TitleLabelStyle as CSSProperties,
  }),
);

export const FormControlStyled = styled(FormControl)(
  () => ({
    ...themePublic.halfWidth as CSSProperties,
  }),
);

export const InputContainer = styled(Box)(
  ({ theme }) => ({
    maxWidth: '40%',
    width: '100%',
    '& label': {
      color: `${themeDefault.palette.fieldLabelColor} !important`,
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiBox-root': {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%'
    },
  }),
);

export const InputContainerHalfWidth = styled(InputContainer)(
  () => ({
    ...themePublic.halfWidth as CSSProperties,
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  ({ theme }) => ({
    cursor: 'pointer',
    height: '20px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    width: '20px',
    [theme.breakpoints.down('sm')]: {
      right: '2px',
      top: '2px',
    },
  }),
);

export const SubtitleContainer = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    color: themeDefault.palette.mainColor,
    display: 'flex',
    justifyContent: 'center',
    minWidth: '12.5%',
    textAlign: 'left',
    '& p': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      width: '45%',
      display: 'flex',
      alignItems: 'center',
      color: themeDefault.palette.fieldLabelColor,
      fontSize: '12px'
    },
  }),
);

export const Label = styled(Typography)(
  ({ theme }) => ({
    [theme.breakpoints.down(925)]: {
      fontSize: '13px'
    },
  }),
);

export const InputGroup = styled(Box)(
  ({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '75%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

export const TitleContainer = styled(Box)(
  () => ({
    maxWidth: '15%',
    marginTop: 0,
    marginRight: '0.5vh',
    width: '100%',
    '& input': {
      width: '120px',
    },
    '& div': {
      margin: 0
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  }),
);

export const RadioStyled = styled(Radio)(
  () => ({
    ...TitleLabelStyle as CSSProperties,
  }),
);

export const TitleSelect = styled(Select)(
  () => ({
    minWidth: '3.65vw',
    maxHeight: '48px',
    '& fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23) !important',
      borderRadius: '6px',
    }
  }),
);

export const InputLabelActive = styled(InputLabel)(
  () => ({
    backgroundColor: themeDefault.palette.whiteColor,
    color: 'rgba(0,0,0,0.6) !important',
    padding: '0 4px',
    transform: 'translate(8px, -9px) scale(0.7)',
  }),
);

export const InputLabelStyled = styled(InputLabel)(
  () => ({
    color: 'rgba(0,0,0,0.6) !important',
    transform: 'translate(14px, 14px) scale(1)',
  }),
);
