import {
  Box, Button, Checkbox, MenuItem,
  Paper,
  TableBody, TableCell, TableContainer, TableHead, Typography
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const EmptyListTextStyle = {
  ...themePublic.emptyListText as CSSProperties,
  [themeLocal.breakpoints.down('sm')]: {
    margin: 0,
    marginTop: '2rem',
    textAlign: 'center'
  },
};

export const TableBulkOptionsHeader = styled(Box)(
  () => ({
    ...themePublic.tableBulkOptionsHeader as CSSProperties,
  }),
);

export const TableBulkOptionsCheckbox = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
  }),
);

export const TableBulkOptionsCheckboxSelected = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
    ...themePublic.tableBulkOptionsCheckboxSelected as CSSProperties,
  }),
);

export const TableBulkOptionsText = styled(Typography)(
  () => ({
    ...themePublic.tableBulkOptionsText as CSSProperties,
  }),
);

export const TableBulkOptionsMenu = styled(Button)(
  () => ({
    ...themePublic.tableBulkOptionsMenu as CSSProperties,
  }),
);

export const TableBulkOptionsMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.tableBulkOptionsMenuItem as CSSProperties,
  }),
);

export const TableContainerStyled = styled(TableContainer)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const TableBodyStyled = styled(TableBody)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const BulkTableHead = styled(TableHead)(
  () => ({
    ...themePublic.bulkTableHeader as CSSProperties,
  }),
);

export const RemovedBorderTableHead = styled(TableHead)(
  () => ({
    ...themePublic.removedBorderTableHead as CSSProperties,
  }),
);

export const CheckBoxHeaderTableCell = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxHeaderTableCell as CSSProperties,
  }),
);

export const CheckBoxHeaderTableCellSelected = styled(TableCell)(
  () => ({
    ...themePublic.checkBoxHeaderTableCell as CSSProperties,
    ...themePublic.checkBoxHeaderTableCellSelected as CSSProperties,
  }),
);

export const ImageTableCell = styled(TableCell)(
  () => ({
    paddingLeft: 0,
    paddingRight: 0,
    width: '8vw',
  }),
);

export const TitleHeaderTableCell = styled(TableCell)(
  () => ({
    fontWeight: 'bold !important',
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCell as CSSProperties,
  }),
);

export const AddNewVenueButtonMobile = styled(Paper)(
  () => ({
    ...themePublic.addNewEntityButtonMobile as CSSProperties,
    top: '15px',
  }),
);
