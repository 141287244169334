import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  Typography,
  Tooltip,
} from '@mui/material';

import { ErrorResponse, ResponseTableContainer, TableCellTrucate } from './ImportContactsCsvReport.styled';

type ImportCsvContacts ={
  uploadedData: {
    successfullyAdded?: any[],
    skipped: any[],
    failed: any[],
  }
}

export const ImportCsvContactsReport = ({uploadedData}: ImportCsvContacts) => {
  const [t] = useTranslation();

  const responseColumns: GridColDef[] = [
    {
      field: 'row',
      headerName: t('row'),
      width: 70,
    },
    {
      field: 'error',
      headerName: t('error'),
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value} >
          <TableCellTrucate>{params.value}</TableCellTrucate>
        </Tooltip>
      ),
    }
  ];

  return (
    uploadedData ?
      <>
        <Box>
          <Typography>{`${t('successfullyImported')} ${uploadedData.successfullyAdded?.length || 0}`}</Typography>
          <Typography>{`${t('skippedAlreadyExist')} ${uploadedData.skipped?.length || 0}`}</Typography>
          <Typography component={uploadedData.failed?.length > 0 ? ErrorResponse : Typography}>{`${t('invalidRows')} ${uploadedData.failed?.length || 0}`}</Typography>
          {
            uploadedData.failed && uploadedData.failed.length > 0 &&
                <ResponseTableContainer>
                  <DataGrid
                    rows={uploadedData.failed.map((f: any, index: number) => ({id: index, row: f.row, error: f.error}))}
                    columns={responseColumns}
                    hideFooter={true}
                    disableSelectionOnClick/>
                </ResponseTableContainer>
          }
        </Box>
      </>
      :
      <ErrorResponse>{t('errorOccured')}</ErrorResponse>
  );
};