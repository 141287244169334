import React, { ElementType } from 'react';
import {
  Checkbox,
  Paper,
  TableCell,
  TableCellBaseProps,
  TableRow,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, UserOptionCell } from 'components';
import { USER_STATUS } from 'enums';
import { contactsOptionIconDisabled } from 'resources';
import { UserProps } from 'types';

import {
  ActionMenuContainer, ActiveText, BadgeNumber, BasicTableCell,
  BoldInfoData, CheckBoxTableCell, DataContainerMobile,
  DataInfoContainerMobile, FirstTableCell, InactiveText,
  OptionTableCell, StatusTableCell, ThemeMultipleLinesText,
  ThemeOverlappedText, UserPaperConatinerMobile, UserPaperConatinerMobileSelected
} from './UserTableRow.styled';

type UserRowProps = {
    user: UserProps
    isUserSelected: boolean,
    handleUserSelect: (id: number) => void,
    selectedUsersLength?: number
}

export const UserTableRow = ({ user, isUserSelected, handleUserSelect, selectedUsersLength = 0 }: UserRowProps) => {
  const [t] = useTranslation();
  return (
    isMobile ?
      <Paper elevation={2} key={user.id} component={isUserSelected ? UserPaperConatinerMobile : UserPaperConatinerMobileSelected}>
        <DataInfoContainerMobile onClick={() => handleUserSelect(user.id)}>
          <DataContainerMobile>
            <BoldInfoData>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={`${user.lastName} ${user.firstName}`} />
            </BoldInfoData>
            <Typography>
              <CustomTooltip theme={{...ThemeOverlappedText}} text={user.email} />
            </Typography>
            <Typography>{user.role}</Typography>
            <Typography component={user.status === USER_STATUS.ACTIVE.toUpperCase() ? ActiveText : InactiveText}>
              &#9679; {t(user.status.toLowerCase())}
              <BadgeNumber>{user.invitationsNo}</BadgeNumber>
            </Typography>
          </DataContainerMobile>
        </DataInfoContainerMobile>
        <ActionMenuContainer>
          {selectedUsersLength === 0 ?
            <UserOptionCell user={user} /> :
            <img src={contactsOptionIconDisabled} alt={t('optionImage')} />}
        </ActionMenuContainer>
      </Paper>:
      <TableRow key={user.id}>
        <TableCell component={(isUserSelected ? CheckBoxTableCell : TableCell) as ElementType<TableCellBaseProps>}>
          <Checkbox checked={isUserSelected} onClick={() => handleUserSelect(user.id)}/>
        </TableCell>
        <FirstTableCell />
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={user.lastName} />
        </BasicTableCell>
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={user.firstName} />
        </BasicTableCell>
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={user.email} />
        </BasicTableCell>
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={user.role} />
        </BasicTableCell>
        <BasicTableCell>
          <CustomTooltip theme={{...ThemeOverlappedText}} text={user.invitationsNo.toString()} />
        </BasicTableCell>
        <StatusTableCell>
          <Typography component={user.status === USER_STATUS.ACTIVE.toUpperCase() ? ActiveText : InactiveText}>
            &#9679; {t(user.status.toLowerCase())}
          </Typography>
        </StatusTableCell>
        <OptionTableCell>
          <UserOptionCell user={user} />
        </OptionTableCell>
      </TableRow>
  );
};

