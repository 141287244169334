import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EventDetailsForm, GuideIcon, Loader } from 'components';
import { getEviteData, getSelectedEvitesPropsByPropName, showToast } from 'helpers';
import { UPDATE_GUESTS, graphMutationMiddleware } from 'services';
import { useGuestStore } from 'store';
import { EviteType, InvitationProps, SelectedGuests, SendersProps } from 'types';

import { CloseIconStyled, EditEventDetailsContainer, EventInfoLabel, SubmitButton } from './EditDetailsModal.styled';
import { themePublic } from 'themeDefault';

type EditDetailsModalProps = {
  isOpen: boolean,
  handleClose: () => void
};

export const EditDetailsModal = ({isOpen, handleClose}: EditDetailsModalProps) => {
  const [t] = useTranslation();
  const { invitation, selectedGuests, setSelectedGuests, changeExecuteRefetch} = useGuestStore();
  const [ updateGuests, { loading: updateGuestsLoading }] = graphMutationMiddleware(UPDATE_GUESTS);
  const [ selectedSender, setSelectedSender ] = useState(invitation && invitation.senders?.find((item: SendersProps) => item.isDefault === true)?.id);
  const [ selectedEvites, setSelectedEvites ] = useState(invitation ? invitation.evites.map((evite: EviteType) => evite) : []);
  const [ personalGreetingMessage, setPersonalGreetingMessage ] = useState('');
  const [ personalGreetingMessageError, setPersonalGreetingMessageError ] = useState(false);
  const [ activeItems, setActiveItems ] = useState({
    sender: false,
    personalGreetingMessage: false,
    evites: false
  });
  const urlParams = useParams();

  useEffect(() => {
    personalGreetingMessage !== '' && personalGreetingMessage.length > 1000 ? setPersonalGreetingMessageError(true) : setPersonalGreetingMessageError(false);
  }, [personalGreetingMessage]);

  const bulkUpdateGuests = () => {
    if (activeItems.evites && selectedEvites.length < 1 ) {
      showToast('error', t('selectOneEvite'));
      return;
    }
    updateGuests({variables: {
      input: {
        evites: activeItems.evites ? getSelectedEvitesPropsByPropName(selectedEvites, 'id') : null,
        ids: selectedGuests.map(a => a.guestId),
        invitationId: Number(urlParams.id),
        personalGreetingMessage: activeItems.personalGreetingMessage ? personalGreetingMessage : null,
        senderId: activeItems.sender ? selectedSender : null
      }
    }}).then(() => {
      changeExecuteRefetch();
      setSelectedGuests();
      handleClose();
      setPersonalGreetingMessage('');
    });
  };

  return (
    <>
      <Loader loadingPage={false} inProgress={updateGuestsLoading} />
      <Dialog open={isOpen} onClose={handleClose} fullScreen={isMobile} maxWidth='xl'>
        <EditEventDetailsContainer>
          <GuideIcon themes={{containerStyle: themePublic.guideIconContainerGuestBulkEditEventDetails as CSSProperties, textBoxStyle: themePublic.guideTextContainerGuestBulkEditEventDetails}} text={t('eventInfoGuideMessage')} />
          <CloseIconStyled onClick={handleClose}/>
          <EventInfoLabel>{t('editEventDetails')}</EventInfoLabel>
          <EventDetailsForm
            activeItems={activeItems}
            setActiveItems={setActiveItems}
            personalGreetingMessage={personalGreetingMessage}
            personalGreetingMessageError={personalGreetingMessageError}
            setPersonalGreetingMessage={setPersonalGreetingMessage}
            isCreating={false}
            selectedSenders={selectedSender}
            setSelectedSenders={setSelectedSender}
            eviteData={getEviteData(invitation)}
            setSelectedEvites={setSelectedEvites}
            selectedEvites={selectedEvites} />
          <SubmitButton
            variant='contained'
            disabled={Object.values(activeItems).every(value => value === false) || personalGreetingMessageError}
            onClick={bulkUpdateGuests}>
            {t('save')}
          </SubmitButton>
        </EditEventDetailsContainer>
      </Dialog>
    </>
  );
};