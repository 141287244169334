import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ContactsForm, EventDetailsForm, InputField, Loader, TabPanel, TagSelector, VirtualKeyboard } from 'components';
import { FORM_TYPE, VALIDATION_RESTRICTION } from 'enums';
import { contactGroupInitialValues, contactInitialValues, getEviteData, getSelectedEvitesPropsByPropName, memberOfContactsAreChanged, phoneNumberRegExp, tagsAreChanged } from 'helpers';
import {
  ADD_GUESTS, ADD_GUESTS_GROUP, GET_INVITATION_BY_ID,
  GET_TAGS,
  UPDATE_GUEST, UPDATE_GUESTS_GROUP,
  graphLazyQueryMiddleware,
  graphMutationMiddleware,
  graphQueryMiddleware
} from 'services';
import { useGuestStore } from 'store';
import { ContactFormValuesType, ContactType, EviteType, GuestType, SendersProps, TagType } from 'types';

import {
  ButtonContainer, CloseIconStyled, DialogContentStyled, GroupNameDividerContainer,
  SubmitButton, TabStyled, TabsContainer, TabsStyled
} from './CreateGuestModal.styled';

type CreateGuestModalProps = {
  isOpen: boolean,
  handleClose: () => void
  guest?: GuestType
};

export const CreateGuestModal = ({ isOpen, handleClose, guest }: CreateGuestModalProps) => {
  const [t] = useTranslation();
  const params = useParams();
  const keyboardRef = useRef(null);
  const formRef = useRef(null);
  const { invitation, changeExecuteRefetch } = useGuestStore();

  const [ addGuest, { loading: isAddingGuest}] = graphMutationMiddleware(ADD_GUESTS);
  const [ addGuestGroup, { loading: isAddingGuestGroup}] = graphMutationMiddleware(ADD_GUESTS_GROUP);
  const [ updateGuestGroup, {loading: isUpdatingGuestGroup}] = graphMutationMiddleware(UPDATE_GUESTS_GROUP);
  const [ updateGuest, {loading: isUpdatingGuest}] = graphMutationMiddleware(UPDATE_GUEST);
  const [getInvitation] = graphLazyQueryMiddleware(GET_INVITATION_BY_ID);
  const {data: tagData} = graphQueryMiddleware(GET_TAGS, { filter: { type: 'CONTACT' } });

  const [ tabValue, setTabValue ] = useState(0);
  const [ personalGreetingMessageError, setPersonalGreetingMessageError ] = useState(false);
  const [ selectedTags, setSelectedTags ] = useState(guest ? guest.contact.tags.map((tag: TagType) => tag.name) : []);
  const [ selectedSenders, setSelectedSenders ] = useState<number>();
  const [ selectedEvites, setSelectedEvites ] = useState(invitation ? invitation.evites.map((evite) => evite) : []);
  const [ inputName, setInputName ] = useState('');
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ contactsCopy, setContactsCopy ] = useState(guest?.contact ? guest.contact.contacts.length > 0 ? [...guest.contact.contacts] : [guest.contact] : [contactInitialValues]);
  const [ membersForDelete, setMembersForDelete ] = useState<number[]>([]);
  const [ initialValues, setInitialValues ] = useState(
    guest ?
      {
        groupName: guest.contact?.contacts.length > 0 ? guest.contact.lastName : '',
        firstName: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.firstName) : [guest.contact.firstName || ''],
        lastName: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.lastName) : [guest.contact.lastName || ''],
        email: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.email) : [guest.contact.email || ''],
        phoneNumber: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.phoneNumber) : [guest.contact.phoneNumber || ''],
        street: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.street) : [guest.contact.street || ''],
        city: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.city) : [guest.contact.city || ''],
        zipCode: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.zipCode) : [guest.contact.zipCode || ''],
        state: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.state) : [guest.contact.state || ''],
        title: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.title) : [guest.contact.title || ''],
        id: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.id) : [guest.contact.id || null]
      } :
      contactGroupInitialValues);
  const [ personalGreetingMessage, setPersonalGreetingMessage ] = useState(guest && guest.personalGreetingMessage);

  const loadingProcess = isAddingGuest || isAddingGuestGroup || isUpdatingGuest || isUpdatingGuestGroup;

  const shouldEnableButton = (tags: TagType[], isValid: boolean, errors: any, isSubmitting: boolean, dirty: boolean, values: ContactFormValuesType) => {
    const notFormikFieldsChanged = eventInfoAreChanged() || tagsAreChanged(tags, selectedTags) || (guest && memberOfContactsAreChanged(guest.contact, values));
    const changed = guest ? notFormikFieldsChanged || dirty : dirty;
    return !(changed && Object.keys(errors).length === 0 && !isSubmitting && !personalGreetingMessageError && isValid);
  };

  const renderNewMember = (values: ContactFormValuesType) => {
    const newContactsCopy = [...contactsCopy];
    newContactsCopy.push(contactInitialValues);
    setContactsCopy(newContactsCopy);

    setInitialValues({
      ...initialValues,
      groupName: formRef?.current ? formRef.current.values.groupName : '',
      firstName: [ ...values.firstName, '' ],
      lastName: [ ...values.lastName, '' ],
      email: [ ...values.email, '' ],
      phoneNumber: [ ...values.phoneNumber, '' ],
      street: [ ...values.street, '' ],
      city: [ ...values.city, '' ],
      zipCode: [ ...values.zipCode, '' ],
      state: [ ...values.state, '' ],
      title: [ ...values.title, '' ],
    });
  };

  const removeMember = (index: number, values: ContactFormValuesType) => {
    const newContactsCopy = [...contactsCopy];
    newContactsCopy[index].id && setMembersForDelete([ ...membersForDelete, newContactsCopy[index].id ]);
    newContactsCopy.splice(index, 1);
    setContactsCopy(newContactsCopy);

    setInitialValues({
      ...initialValues,
      groupName: formRef?.current ? formRef.current.values.groupName : '',
      firstName: values.firstName.filter((value, i) => i !== index),
      lastName: values.lastName.filter((value, i) => i !== index),
      email: values.email.filter((value, i) => i !== index),
      phoneNumber: values.phoneNumber.filter((value, i) => i !== index),
      street: values.street.filter((value, i) => i !== index),
      city: values.city.filter((value, i) => i !== index),
      zipCode: values.zipCode.filter((value, i) => i !== index),
      state: values.state.filter((value, i) => i !== index),
      title: values.title.filter((value, i) => i !== index),
      id: values.id.filter((value, i) => i !== index)
    });
  };

  const guestSchema = Yup.object().shape({
    groupName: contactsCopy.length > 1 ? Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('groupName'), length: VALIDATION_RESTRICTION.HUNDRED })) :
      Yup.string()
        .nullable(),
    firstName: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('firstName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    lastName: Yup.array().of(Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('lastName'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    email: Yup.array().of(Yup.string()
      .email(t('invalidEmailFormat'))
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('email'), length: VALIDATION_RESTRICTION.FIFTY }))),
    phoneNumber: Yup.array().of(Yup.string()
      .matches(phoneNumberRegExp, t('invalidPhoneNumberFormat'))
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('phoneNumber'), length: VALIDATION_RESTRICTION.FIFTY }))),
    street: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('street'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    city: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('city'), length: VALIDATION_RESTRICTION.HUNDRED }))),
    zipCode: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.FIFTY, t('stringPropertyMaxValidation', { propertyName: t('zipCode'), length: VALIDATION_RESTRICTION.FIFTY }))),
    state: Yup.array().of(Yup.string()
      .nullable()
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('state'), length: VALIDATION_RESTRICTION.HUNDRED }))),
  });

  useEffect(() => {
    getInvitation({
      variables: {
        id: Number(params.id)
      }
    }).then(result => {
      !guest && setSelectedSenders(result.data.invitation.senders.find((item: SendersProps) => item.isDefault === true)?.id);
      !guest && setSelectedEvites(result.data.invitation.evites.filter((evite: EviteType) => evite.isSelected).map((evite: EviteType) => evite));
    });
  }, []);

  useEffect(() => {
    if (!guest) {
      return;
    }
    setInitialValues({
      groupName: guest.contact?.contacts.length > 0 ? guest.contact.lastName : '',
      firstName: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.firstName) : [guest.contact.firstName || ''],
      lastName: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.lastName) : [guest.contact.lastName || ''],
      email: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.email) : [guest.contact.email || ''],
      phoneNumber: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.phoneNumber) : [guest.contact.phoneNumber || ''],
      street: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.street) : [guest.contact.street || ''],
      city: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.city) : [guest.contact.city || ''],
      zipCode: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.zipCode) : [guest.contact.zipCode || ''],
      state: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.state) : [guest.contact.state || ''],
      title: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.title) : [guest.contact.title || ''],
      id: guest.contact?.contacts.length > 0 ? guest.contact.contacts.map((item: ContactType) => item.id) : [guest.contact.id || null]
    });
  }, [guest]);

  const createSingle = (values: ContactFormValuesType) => {
    if (guest) {
      updateGuest({
        variables: {
          input: {
            contact: {
              id: guest.contact.id,
              firstName: values.firstName[0],
              lastName: values.lastName[0],
              email: values.email[0],
              phoneNumber: values.phoneNumber[0],
              city: values.city[0],
              street: values.street[0],
              state: values.state[0],
              zipCode: values.zipCode[0],
              titleValue: values.title[0],
              tags: selectedTags
            },
            id: guest.id,
            invitationId: Number(invitation.id),
            senderId: selectedSenders,
            personalMessage: guest.personalMessage,
            personalGreetingMessage: personalGreetingMessage,
            evites: getSelectedEvitesPropsByPropName(selectedEvites, 'id')
          }
        }
      }).then(() => {
        changeExecuteRefetch();
        setTabValue(0);
        handleClose();
      });
      return;
    }
    addGuest({
      variables: {
        input: {
          contact: {
            firstName: values.firstName[0],
            lastName: values.lastName[0],
            email: values.email[0],
            phoneNumber: values.phoneNumber[0],
            city: values.city[0],
            street: values.street[0],
            state: values.state[0],
            zipCode: values.zipCode[0],
            titleValue: values.title[0],
            tags: selectedTags
          },
          invitationId: Number(invitation.id),
          senderId: selectedSenders,
          personalGreetingMessage: personalGreetingMessage,
          evites: getSelectedEvitesPropsByPropName(selectedEvites, 'id')
        }
      }
    }).then(() => {
      handleClose();
      setTabValue(0);
      changeExecuteRefetch();
    });
  };

  const createGroup = (values: ContactFormValuesType) => {
    const items = values.firstName.map((firstName: string, index: number) => {
      return {
        firstName: firstName,
        lastName: values.lastName[index] ?? '',
        email: values.email[index] ?? '',
        phoneNumber: values.phoneNumber[index] ?? '',
        state: values.state[index] ?? '',
        street: values.street[index] ?? '',
        zipCode: values.zipCode[index] ?? '',
        city: values.city[index] ?? '',
        titleValue: values.title[index] ?? '',
        id: values.id[index] ?? null,
      };
    });
    if (guest) {
      updateGuestGroup({
        variables: {
          input: {
            contacts: {
              id: guest.contact.id,
              groupName: values.groupName,
              contacts: items.filter((value) => value.id),
              membersForDelete: membersForDelete,
              newContactMembers: items.filter((value) => !value.id).map((value) => {
                return {
                  firstName: value.firstName,
                  lastName: value.lastName,
                  email: value.email,
                  phoneNumber: value.phoneNumber,
                  state: value.state,
                  street: value.street,
                  zipCode: value.zipCode,
                  city: value.city,
                  titleValue: value.titleValue
                };
              }),
              tags: selectedTags
            },
            id: guest.id,
            invitationId: Number(invitation.id),
            senderId: selectedSenders,
            personalMessage: guest.personalMessage,
            personalGreetingMessage: personalGreetingMessage,
            evites: getSelectedEvitesPropsByPropName(selectedEvites, 'id')
          }
        }
      }).then(() => {
        changeExecuteRefetch();
        setTabValue(0);
        handleClose();
      });
      return;
    }
    addGuestGroup({
      variables: {
        input: {
          contacts: {
            groupName: values.groupName,
            contacts: items.filter((value) => !value.id).map((value) => {
              return {
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                phoneNumber: value.phoneNumber,
                state: value.state,
                street: value.street,
                zipCode: value.zipCode,
                city: value.city,
                titleValue: value.titleValue
              };
            }),
            tags: selectedTags
          },
          invitationId: Number(invitation.id),
          senderId: selectedSenders,
          personalGreetingMessage: personalGreetingMessage,
          evites: getSelectedEvitesPropsByPropName(selectedEvites, 'id')
        }
      }
    }).then(() => {
      handleClose();
      setTabValue(0);
      changeExecuteRefetch();
    });
  };

  useEffect(() => {
    setSelectedEvites(guest && guest?.evites.filter((evite: EviteType) => evite.isSelected).map((item: EviteType) => item));
    setSelectedSenders(guest && guest.sender?.id);
    setPersonalGreetingMessage(guest && guest.personalGreetingMessage);
  }, [guest]);

  const eventInfoAreChanged = () => {
    const evitesIds: number[] = [];
    if ((guest?.personalGreetingMessage ? guest.personalGreetingMessage !== personalGreetingMessage : personalGreetingMessage && personalGreetingMessage !== '')) {
      if (personalGreetingMessage.length > VALIDATION_RESTRICTION.THOUSAND) {
        setPersonalGreetingMessageError(true);
        return false;
      }
      setPersonalGreetingMessageError(false);
      return true;
    }
    if (guest) {
      if (guest?.sender?.id !== selectedSenders) {
        return true;
      }
      guest?.evites && guest.evites.forEach((evite: EviteType) => {
        evitesIds.push(evite.id);
      });
      if (!(guest?.evites.length === selectedEvites.length && evitesIds.every(id => selectedEvites.some((evite: EviteType) => evite.id === id)))) {
        return true;
      }
    }
    return false;
  };

  return (
    <Dialog open={isOpen} onClose={() => {
      handleClose();
    }} maxWidth='lg' fullScreen={isMobile} sx={{ overflowY: 'visible !important' }}>
      <Loader loadingPage={false} inProgress={loadingProcess} />
      <DialogContentStyled sx={{ overflowY: 'visible' }}>
        <CloseIconStyled onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }} />
        <TabsContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowY: 'visible' }}>
            <TabsStyled
              value={tabValue}
              onChange={(e: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}>
              <TabStyled label={t('contactInfo')} />
              <TabStyled label={t('eventInfo')} />
            </TabsStyled>
          </Box>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={guestSchema}
            enableReinitialize
            onSubmit={(values: ContactFormValuesType) => contactsCopy.length > 1 ? createGroup(values) : createSingle(values)}>
            {({ errors, isSubmitting, isValid, dirty, setFieldValue, values }) => {
              return (
                <Form>
                  <TabPanel value={tabValue} index={0}>
                    {contactsCopy.length > 1 &&
                      <GroupNameDividerContainer>
                        <Typography>{t('groupName')}</Typography>
                        <InputField
                          setShowKeyboard={setShowKeyboard}
                          setInputName={setInputName}
                          inputId='groupName'
                          inputName='groupName'
                          isError={null}
                          label=''
                          type='text' />
                      </GroupNameDividerContainer>}
                    {contactsCopy.map((item, index) =>
                      <ContactsForm
                        contact={item}
                        handleAddNew={() => renderNewMember(values)}
                        removeMember={() => removeMember(index, values)}
                        showAddNewText={contactsCopy.length - 1 === index}
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        key={index}
                        type={guest ? FORM_TYPE.EDIT : FORM_TYPE.CREATE}
                        index={index} />
                    )}
                    <TagSelector
                      showKeyboardParent={showKeyboard}
                      setShowKeyboardParent={setShowKeyboard}
                      defaultShowTags={false}
                      tagData={tagData ? tagData : { tags: [{ id: 0, name: '0' }] }}
                      setSelectedTags={setSelectedTags}
                      selectedTags={selectedTags} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <EventDetailsForm
                      showKeyboardParent={showKeyboard}
                      setShowKeyboardParent={setShowKeyboard}
                      personalGreetingMessageError={personalGreetingMessageError}
                      personalGreetingMessage={personalGreetingMessage}
                      setPersonalGreetingMessage={setPersonalGreetingMessage}
                      isCreating={true}
                      selectedSenders={selectedSenders}
                      setSelectedSenders={setSelectedSenders}
                      eviteData={getEviteData(invitation)}
                      setSelectedEvites={setSelectedEvites}
                      selectedEvites={selectedEvites} />
                  </TabPanel>
                  <ButtonContainer>
                    <SubmitButton type='submit' disabled={shouldEnableButton(guest?.contact?.tags, isValid, errors, isSubmitting, dirty, values)}>
                      {guest ? t('save') : t('add')}
                    </SubmitButton>
                  </ButtonContainer>
                  {showKeyboard &&
                    <VirtualKeyboard
                      setShowKeyboard={setShowKeyboard}
                      initialValues={values}
                      setFieldValue={setFieldValue}
                      keyboardRef={keyboardRef}
                      inputName={inputName} />
                  }
                </Form>
              );
            }}
          </Formik>
        </TabsContainer>
      </DialogContentStyled>
    </Dialog>
  );
};
