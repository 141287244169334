import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const DialogStyledAllowedEvents = styled(Dialog)(
  () => ({
    ...themePublic.loadingStateModalAllowEvents as CSSProperties,
  }),
);

export const DialogStyledPreventEvents = styled(Dialog)(
  () => ({
    ...themePublic.loadingStateModalPreventEvents as CSSProperties,
  }),
);

export const ModalHeaderContainer = styled(Box)(
  ({ theme }) => ({
    ...themePublic.modalHeaderContainer as CSSProperties,
    width: '20vw',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }),
);

export const CloseIconStyled = styled(CloseIcon)(
  () => ({
    cursor: 'pointer'
  }),
);

export const InputGroupContainer = styled(Box)(
  () => ({
    ...themePublic.inputGroup as CSSProperties,
    flexDirection: 'column',
    marginBottom: '1rem',
  }),
);

export const ButtonContainer = styled(Box)(
  () => ({
    ...themePublic.submitButtonContainer as CSSProperties,
  }),
);

export const SubmitButton = styled(Button)(
  () => ({
    ...themePublic.submitButton as CSSProperties,
  }),
);
