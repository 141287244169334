import { Box, Button, Checkbox, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const EmptyListTextStyle = {
  ...themePublic.emptyListText as CSSProperties,
  [themeLocal.breakpoints.down('sm')]: {
    margin: 0,
    marginTop: '2rem',
    textAlign: 'center',
  },
};

export const TableContainerStyled = styled(TableContainer)(
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      maxWidth: '72.125vw',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '74.525vw',
    },
  }),
);

export const TableStyled = styled(Table)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const TableBodyStyled = styled(TableBody)(
  () => ({
    ...themePublic.table as CSSProperties,
  }),
);

export const BulkTableHead = styled(TableHead)(
  () => ({
    ...themePublic.bulkTableHeader as CSSProperties,
  }),
);

export const BulkTableHeadRow = styled(TableRow)(
  () => ({
    ...themePublic.tableBulkHeadRow as CSSProperties,
  }),
);

export const FirstTableCellHeader = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
  }),
);

export const FirstTableCellHeaderSelected = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeader as CSSProperties,
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const ImageTableCell = styled(TableCell)(
  () => ({
    minWidth: '5vw',
  }),
);

export const TitleHeaderTableCell = styled(TableCell)(
  ({ theme }) => ({
    fontWeight: 'bold !important',
    minWidth: '9vw',
    [theme.breakpoints.down('lg')]: {
      minWidth: '11vw',
    },
  }),
);

export const TableBulkOptionsText = styled(Typography)(
  () => ({
    ...themePublic.tableBulkOptionsText as CSSProperties,
    '& svg': {
      marginRight: '8px',
      fill: themeDefault.palette.mainBackground,
    }
  }),
);

export const TableBulkOptionsColoredText = styled(TableBulkOptionsText)(
  (props) => ({
    color: props.color as string,
  }),
);

export const HeaderBulkOptionTableCell = styled(TableCell)(
  () => ({
    minWidth: '9vw',
  }),
);

export const LastTableCell = styled(TableCell)(
  () => ({
    ...themePublic.lastTableCell as CSSProperties,
  }),
);

export const RemovedBorderTableHead = styled(TableHead)(
  () => ({
    ...themePublic.removedBorderTableHead as CSSProperties,
  }),
);

export const TableBulkOptionsHeader = styled(Box)(
  () => ({
    ...themePublic.tableBulkOptionsHeader as CSSProperties,
  }),
);

export const TableContentContainerMobile = styled(Box)(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  }),
);

export const TableBulkOptionsCheckbox = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
  }),
);

export const TableBulkOptionsCheckboxSelected = styled(Checkbox)(
  () => ({
    ...themePublic.tableBulkOptionsCheckbox as CSSProperties,
    ...themePublic.tableBulkOptionsCheckboxSelected as CSSProperties,
  }),
);

export const TableBulkOptionsMenu = styled(Button)(
  () => ({
    ...themePublic.tableBulkOptionsMenu as CSSProperties,
  }),
);

export const TableBulkOptionsMenuItem = styled(MenuItem)(
  () => ({
    ...themePublic.tableBulkOptionsMenuItem as CSSProperties,
  }),
);

export const ListContainer = styled(Box)(
  () => ({
    padding: '1rem',
  }),
);
