import { gql } from '@apollo/client';

export const GET_PUBLIC_CONTACT_ENTRY_AUTHORISATIONS = gql`
query PublicContactEntryAuthorisation(
  $filter: PublicContactEntryAuthorisationFilterInput!
) {
  publicContactEntryAuthorisations(filter: $filter) {
    id
    activityStatus
    name
    token
    expirationDate
    scope
    tags {
      id
      name
    }
  }
}
`;
