import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isBefore, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AnimationContainer, GiftRegistry, Loader, RsvpModal } from 'components';
import { ACTIVITY_STATUS, RESPONSE_STATUS } from 'enums';
import { addFontsToDocument, checkForLoadedFonts } from 'helpers';
import { whiteLogo } from 'resources';
import {
  GET_GUEST_BY_TOKEN,
  SEND_RSVP_RESPONSE,
  SET_TO_NOT_ANSWERED,
  graphLazyQueryMiddleware,
  graphMutationMiddleware
} from 'services';
import { EviteResponseType, EviteType } from 'types';

import { GiftText, Header, InvitationInactiveMessage, LogoContainer, PaperStyled } from './InvitationContactPreviewPage.styled';

export const InvitationContactPreviewPage = () => {
  const [t] = useTranslation();
  const [searchParams] = useSearchParams();

  const [ getGuest, { data: guestData, loading: guestLoading }] = graphLazyQueryMiddleware(GET_GUEST_BY_TOKEN);
  const [ sendRsvpResponse, { loading: sending }] = graphMutationMiddleware(SEND_RSVP_RESPONSE);
  const [setToNotAnswred] = graphMutationMiddleware(SET_TO_NOT_ANSWERED);

  const [ showGiftRegistry, setShowGiftRegistry ] = useState(false);
  const [ showRSVPmodal, setShowRSVPmodal ] = useState(false);
  const [ guestFormatedData, setGuestFormatedData ] = useState([]);
  const [ personalMessage, setPersonalMessage ] = useState('');
  const [ audio, setAudio ] = useState(null);
  const [ playAudio, setPlayAudio ] = useState(false);
  const [ showModals, setShowModals ] = useState(false);
  const [ evitesForAnimation, setEvitesForAnimation ] = useState([]);
  const [ isFontStyleReady, setIsFontStyleReady ] = useState(false);
  const [ isHiddenSideEvite, setIsHiddenSideEvite ] = useState(false);

  const isValidToken = isBefore(new Date(), parseISO(guestData?.guestByToken?.tokenExpirationDate));
  const activeInvitation = guestData?.guestByToken?.invitation.activityStatus === ACTIVITY_STATUS.ACTIVE.toUpperCase();

  useEffect(() => {
    getGuest({ variables: {
      token: searchParams.get('t')
    }}).then(res => {
      if (res.data.guestByToken.usedCustomFonts.length > 0) {
        addFontsToDocument(res.data.guestByToken.usedCustomFonts).then(() => checkForLoadedFonts(setIsFontStyleReady));
      } else {
        setIsFontStyleReady(true);
      }
      const animEvites = res.data.guestByToken.evites;
      if (animEvites.length === 2 ) {
        setEvitesForAnimation(animEvites.concat(animEvites).map((item: EviteType, index: number) => ({
          id: index,
          name: item.name.toString(),
          url: item.image?.url,
          thumbnailUrl: item.image?.thumbnailUrl,
          contentJson: item.contentJson
        })));
        setIsHiddenSideEvite(true);
      } else {
        setEvitesForAnimation(res.data.guestByToken.evites.map((item: EviteType) => ({
          id: item.id,
          name: item.name.toString(),
          url: item.image?.url,
          thumbnailUrl: item.image?.thumbnailUrl,
          contentJson: item.contentJson
        })));
      }
    });
    isValidToken && setToNotAnswred({ variables: {
      token: searchParams.get('t')
    }});
  }, []);

  useEffect(() => {
    if (!guestData || !guestData.guestByToken || !isValidToken) {
      return;
    }
    setAudio(new Audio(guestData.guestByToken.envelope?.music?.url));
    const newArray = (guestData.guestByToken.evites.filter((evite: any) => evite.requestRsvp).length > 1 || guestData.guestByToken.responses.length > 1) ?
      [{id: -1, name: '', responseId: '-1', evites: [{id: -1, name: '', status: initSelectedAll() }]}] :
      [];
    guestData.guestByToken.responses.length > 1 ?
      guestData.guestByToken.responses.map((response: any) => {
        if (response.contact.contacts.length === 0) {
          const newCustomEvites = response.eviteResponse.map((eviteResponse: EviteResponseType) => (
            {
              name: eviteResponse.evite.name,
              id: eviteResponse.evite.id,
              customId: `${response.contact.id}_${eviteResponse.evite.id}`,
              status: eviteResponse.status
            }
          ));
          newArray.push({
            name: `${response.contact.firstName} ${response.contact.lastName}`,
            id: response.contact.id,
            responseId: response.id,
            evites: newCustomEvites
          });
        }
      })
      :
      newArray.push({
        name: `${guestData.guestByToken.responses[0]?.contact.firstName} ${guestData.guestByToken.responses[0]?.contact.lastName}`,
        id: guestData.guestByToken.responses[0]?.contact.id,
        responseId: guestData.guestByToken.responses[0]?.id,
        evites: guestData.guestByToken.responses[0]?.eviteResponse.map((eviteResponse: EviteResponseType) => (
          {
            name: eviteResponse.evite.name,
            id: eviteResponse.evite.id,
            customId: `${guestData.guestByToken?.contact.id}_${eviteResponse.evite.id}`,
            status: eviteResponse.status
          }
        ))
      });

    newArray?.forEach((guestResData: any) => (
      guestResData.evites?.forEach((evite: any) => evite.status = evite.status === Object.keys(RESPONSE_STATUS)[Object.values(RESPONSE_STATUS).indexOf(RESPONSE_STATUS.PENDING as unknown as RESPONSE_STATUS)] ?
        Object.keys(RESPONSE_STATUS)[Object.values(RESPONSE_STATUS).indexOf(RESPONSE_STATUS.NOT_ANSWERED as unknown as RESPONSE_STATUS)]
        : evite.status)
    ));
    const filteredGuestsData = [...newArray];
    filteredGuestsData.find(guest => guest.id === -1) && filteredGuestsData.shift();
    sendRsvp(filteredGuestsData, guestData.guestByToken.personalMessage);
    guestData.guestByToken.personalMessage && setPersonalMessage(guestData.guestByToken.personalMessage);

    setGuestFormatedData(newArray);

    guestData.guestByToken.invitation.envelope?.music?.url && setAudio(new Audio(guestData.guestByToken.invitation.envelope?.music?.url));
  }, [guestData]);

  useEffect(() => {
    if (!audio) {
      return;
    }
    if (playAudio) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [playAudio]);

  const initSelectedAll = () => {
    let selectAllStatus = guestData?.guestByToken.responses[0]?.eviteResponse[0]?.status;
    guestData?.guestByToken.responses.map((respose: any) => {
      respose.eviteResponse.map((eviteResponse: EviteResponseType) => {
        if (eviteResponse.status !== selectAllStatus) {
          selectAllStatus = '';
        }
      });
    });
    return selectAllStatus;
  };

  const changeValue = (id: number, value: string, guestId: number) => {
    if (id === -1) {
      const newArray = [...guestFormatedData];
      newArray.map(guest => guest.evites.map((evite: EviteType) => evite.status = value));
      setGuestFormatedData(newArray);
    } else {
      const newArray = [...guestFormatedData];
      newArray.map(guest => {
        guest.id === guestId && guest.evites.map((evite: any) => {
          if (evite.customId === `${guestId}_${id}`) {
            evite.status = value;
          }
        });
      });
      if (newArray.find(el => el.id === -1)) {
        newArray.find(el => el.id === -1).evites[0].status = '';
      }
      setGuestFormatedData(newArray);
    }
  };

  const submitRsvp = () => {
    const filteredGuestsData = [...guestFormatedData];
    filteredGuestsData.find(guest => guest.id === -1) && filteredGuestsData.shift();
    sendRsvp(filteredGuestsData);
  };

  const sendRsvp = (filteredGuestsData: any, initialPersonalMessage?: string) => {
    sendRsvpResponse({variables: {
      token: searchParams.get('t'),
      personalMessage: initialPersonalMessage ?? personalMessage,
      input: filteredGuestsData.map((data: any) => {
        return {
          responseId: data.responseId,
          eviteResponse: data?.evites?.map((evite: any) => ({ eviteId: evite.id, status: evite.status }))
        };
      })
    }}).then(() => {
      setShowRSVPmodal(false);
    });
  };

  const onChangePersonalMessage = (e: any) => {
    setPersonalMessage(e.target.value);
  };

  const handleOpenModal = () => {
    setShowRSVPmodal(!showRSVPmodal);
    setShowGiftRegistry(false);
  };

  const hideModals = () => {
    setShowRSVPmodal(false);
    setShowGiftRegistry(false);
  };

  return (
    <PaperStyled>
      <Loader inProgress={guestLoading} />
      {activeInvitation && !guestData?.guestByToken?.invitation.isDeleted ?
        <Box sx={{display: guestLoading ? 'none' : 'block', position: 'absolute', width: '100vw', height: '100vh', top: 0, left: 0}}>
          <Box sx={{position: 'relative', overflow: 'hidden', width: '100%', height: '100%'}}>
            <Header>
              {
                guestData &&
                evitesForAnimation.length > 0 &&
                isFontStyleReady &&
                  <AnimationContainer
                    hideModals={hideModals}
                    isEnabledBinding={!showGiftRegistry && !showRSVPmodal}
                    setShowModals={() => setShowModals(true)}
                    disableModals={() => setShowModals(false)}
                    envelopeImage={guestData?.guestByToken.envelope.image.url}
                    setPlayAudio={() => setPlayAudio(!playAudio)}
                    isHiddenSideEvite={isHiddenSideEvite}
                    images={evitesForAnimation} />
              }
              <LogoContainer>
                <img src={whiteLogo} />
              </LogoContainer>
              {guestData &&
              showModals &&
              <GiftText
                onClick={() => {
                  setShowGiftRegistry(true);
                  setShowRSVPmodal(false);
                }}>
                {t(guestData?.guestByToken?.invitation?.gifts?.length ? 'giftRegistry' : 'eventInfo')}
              </GiftText>}
            </Header>
            {guestData &&
            showModals &&
            <GiftRegistry
              personalGreetingMessage={guestData.guestByToken.personalGreetingMessage}
              showDrawer={showGiftRegistry}
              invitation={guestData.guestByToken.invitation}
              setClose={() => setShowGiftRegistry(false)} />}
            {guestData &&
            showModals &&
            isValidToken &&
            <RsvpModal
              personalMessage={personalMessage}
              setShowModal={handleOpenModal}
              showModal={showRSVPmodal}
              onChangePersonalMessage={onChangePersonalMessage}
              submitRsvp={submitRsvp}
              isSending={sending}
              changeValue={changeValue}
              invitation={guestData.guestByToken.invitation}
              guests={guestFormatedData} />}
          </Box>
        </Box> :
        !guestLoading &&
          <InvitationInactiveMessage>
            {t('invitationInactive')} !
          </InvitationInactiveMessage>
      }
    </PaperStyled>
  );
};