import { Box, Button, FormControl, ListItemText, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PageBody = styled(Box)(
  () => ({
    ...themePublic.pageBody as CSSProperties,
    flex: 1,
    minHeight: 'auto',
    padding: 0,
  }),
);

export const PageContent = styled(Box)(
  ({ theme }) => ({
    ...themePublic.pageContent as CSSProperties,
    padding: '1rem',
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: themeDefault.defaultBorderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: 0,
      paddingTop: '1rem',
    },
  }),
);

export const ContentContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const TitleContainer = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  }),
);

export const FormContainer = styled(Box)(
  () => ({
    width: '80%',
  }),
);

export const InputGroupContainer = styled(Box)(
  () => ({
    justifyContent: 'space-between',
    width: '100%',
  }),
);

export const SelectGroupContainer = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
  }),
);

export const SelectFormControl = styled(FormControl)(
  () => ({
    margin: '1rem 0',
    width: '46%',
  }),
);

export const ButtonContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      ...themeDefault.stickyBottom,
      left: 0,
      marginTop: 0,
    },
  }),
);

export const SubmitButton = styled(Button)(
  ({ theme }) => ({
    ...themePublic.pageNextButton as CSSProperties,
    marginTop: '1rem',
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: '0',
      padding: 0,
      width: '100% !important',
    },
  }),
);

export const MenuItemSelected = styled(MenuItem)(
  () => ({
    backgroundColor: `${themeDefault.palette.lightMainColor} !important`,
    color: themeDefault.palette.mainColor,
    width: '100%',
    '& svg': {
      fill: themeDefault.palette.mainColor,
    },
  }),
);

export const ListItemTextColor = styled(ListItemText)(
  () => ({
    display: 'flex',
    justifyContent: 'left',
  }),
);
