import { PromptModalProps } from 'components';
import React from 'react';
import { usePrompt } from './blocker';

type PromptProps = {
    when?: boolean
    options: PromptModalProps
  };

export const Prompt = ({options, when} : PromptProps) => {
  usePrompt(options, when);
  return <></>;
};

