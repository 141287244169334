import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { invitationPageBackground as ImageBackground } from 'resources';

import { themeDefault } from 'themeDefault';

export const PaperStyled = styled(Paper)(
  () => ({
    backgroundImage: `url(${ImageBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    overflow: 'hidden !important',
    width: '100%',
  }),
);

export const Header = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: '100%',
  }),
);

export const LogoContainer = styled(Box)(
  ({ theme }) => ({
    marginLeft: '2%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      width: '100%',
      '& img': {
        float: 'none',
        margin: '0 15%',
        width: '70%',
      },
    },
  }),
);

export const GiftText = styled(Typography)(
  ({ theme }) => ({
    color: themeDefault.palette.whiteColor,
    cursor: 'pointer',
    float: 'right',
    margin: '2% 2% 0 0',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      marginTop: '100px',
      position: 'absolute',
      right: '5px',
      top: '-90px',
    },
  }),
);

export const InvitationInactiveMessage = styled(Typography)(
  () => ({
    color: themeDefault.palette.whiteColor,
    fontWeight: 'bold',
    fontSize: '30px',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  }),
);
