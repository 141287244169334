import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

export const PaperStyled = styled(Paper)(
  () => ({
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem',
  }),
);

export const PaperContent = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '0.75rem',
  }),
);

export const InfoContainer = styled(Box)(
  () => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }),
);

export const InfoText = styled(Typography)(
  () => ({
    fontSize: '75%',
  }),
);

export const InfoDateText = styled(Typography)(
  () => ({
    fontSize: '75%',
    color: 'rgba(0, 0, 0, .4)',
  }),
);

export const TagsContainer = styled(Box)(
  () => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  }),
);

export const ChipStyled = styled(Chip)(
  () => ({
    ...themePublic.chip as CSSProperties,
  }),
);

export const ActiveText = styled(Typography)(
  () => ({
    color: themeDefault.palette.mainColor,
  }),
);

export const InactiveText = styled(Typography)(
  () => ({
    color: themeDefault.palette.greyColor,
  }),
);

export const CopyButton = styled(Button)(
  () => ({
    ...themePublic.publicContactEntryLinksTableRow_CopyButton as CSSProperties,
  }),
);

export const CopyButtonIcon = styled(ContentCopy)(
  () => ({
    ...themePublic.publicContactEntryLinksTableRow_CopyButtonIcon as CSSProperties,
  }),
);
