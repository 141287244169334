import { useMutation, useQuery, useLazyQuery, WatchQueryFetchPolicy, DocumentNode } from '@apollo/client';
import { requestHeader } from 'helpers';

export const graphQueryMiddleware = (graphqlQuery: DocumentNode, variables?: any, skip?: boolean, fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network') => {
  return useQuery(graphqlQuery, {
    context: {
      headers: requestHeader()
    },
    variables: variables,
    skip: skip,
    fetchPolicy: fetchPolicy
  });
};

export const graphMutationMiddleware = (graphqlMutation: DocumentNode) => {
  return useMutation(graphqlMutation, {
    context: {
      headers: requestHeader()
    }
  });
};

export const graphLazyQueryMiddleware = (graphqlQuery: DocumentNode, pollInterval?: number) => {
  return useLazyQuery(graphqlQuery, {
    context: {
      headers: requestHeader()
    },
    pollInterval: pollInterval
  });
};