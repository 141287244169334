import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { searchFilter } from 'resources';

import { TextFieldStyled } from './MobileSearch.styled';

type MobileSearchProps = {
  setSearchText: (text: string) => void
  searchText: string
  handleOpenFilters?: (state: boolean) => void
  showFilters: boolean
  theme?: CSSProperties,
};

export const MobileSearch = ({setSearchText, searchText, handleOpenFilters, showFilters, theme}: MobileSearchProps) => {
  const [t] = useTranslation();

  const openFilters = () => {
    handleOpenFilters(true);
  };

  return (
    <TextFieldStyled
      style={theme}
      onChange={e => setSearchText(e.target.value)}
      value={searchText}
      placeholder={t('searchPlaceholder')}
      autoComplete='off'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          showFilters &&
          <InputAdornment position='start' onClick={() => openFilters()}>
            <img src={searchFilter} onClick={() => handleOpenFilters(true)}/>
          </InputAdornment>
        ),
      }}
      variant='outlined'/>
  );
};