import React from 'react';
import { useTranslation } from 'react-i18next';

import { DragDropFile, Loader } from 'components';
import { UPLOAD_FILE, UPLOAD_GLOBAL_FILE, graphMutationMiddleware } from 'services';

import { CommentTextModal, UploaderContainer } from './AddFontModal.styled';

type AddFontModalProps = {
  handleClose: () => void
  handleUpload: (fileUrl: string) => void
  fontLoading: boolean
  isTemplate?: boolean
}

export const AddFontModal = ({handleClose, handleUpload, fontLoading, isTemplate = false}: AddFontModalProps) => {
  const [t] = useTranslation();
  const [ uploadFile, {loading: uploadLoading}] = graphMutationMiddleware(isTemplate ? UPLOAD_GLOBAL_FILE : UPLOAD_FILE);

  const uploadFont = (uploadedFiles: File[]) => {
    uploadFile({variables: {
      file: uploadedFiles[0],
      type: 'FONT'
    }}).then(res => {
      handleClose();
      handleUpload(res.data[isTemplate ? 'uploadGlobalFile': 'uploadFile'].url);
    });
  };

  return (
    <UploaderContainer>
      <Loader loadingPage={false} inProgress={fontLoading || uploadLoading} />
      <CommentTextModal>{t('uploadFont')}</CommentTextModal>
      <DragDropFile
        files={null}
        setFiles={uploadFont}
        buttonText={t('browseFile')}
        title={t('dragAndDropHere')}
        multiple={false}
        accept='.ttf, .otf, .woff, .woff2'/>
    </UploaderContainer>
  );
};
