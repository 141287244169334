import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const KeyboardContainer = styled('div')(
  () => ({
    backgroundColor: themeDefault.palette.lightGreyColor,
    boxSizing: 'border-box',
    bottom: '0vh',
    cursor: 'move',
    left: '50vh',
    paddingTop: '48px',
    position: 'fixed',
    right: '50vh',
    top: '75.5vh',
    zIndex: 8000,
    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      outline: 0,
      position: 'absolute',
      right: 10,
      top: 10,
      transform: 'scale(1.5)',
    }
  }),
);
