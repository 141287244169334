import React, { CSSProperties, ElementType, useState } from 'react';
import {
  Checkbox,
  Menu,
  TableCell,
  TableCellBaseProps,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ConfirmationModal, CustomTooltip, EditDetailsModal, EditTagsModal } from 'components';
import { useAuth } from 'contexts';
import { USER_SETTINGS_CONFIG } from 'enums';
import { handleOrder } from 'helpers';
import { BULK_REMOVE_GUEST, GET_GUESTS_ID_BY_INVITATIONS_IDS, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { useGuestStore } from 'store';
import { ContactColumnType, EviteType, GuestType, SelectedGuests, TableColumnProps, UseAuthProps } from 'types';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocalOffer as LocalOfferIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';
import {
  BulkTableHead, BulkTitleHeaderTableCell, CheckBoxHeaderTableCell, CheckBoxHeaderTableCellSelected, DividerStyled,
  FirstTableCellHeader, HeaderTableCellContent, ImageTableCell, ImageTableCellStyle, LastTableCell, LastTableCellStyle,
  RemovedBorderTableHead, TableBulkOptionsHeader, TableBulkOptionsMenu, TableBulkOptionsMenuItem, TableBulkOptionsText,
  TitleHeaderTableCell, TitleHeaderTableCellStyle
} from './GuestTableHead.styled';

type GuestTableProps = {
  enabledEvites?: EviteType[],
  forPreview: boolean,
  guestColumns: ContactColumnType[],
  guestsLength: number,
  isTrackPage?: boolean,
};

export const GuestsTableHead = ({ enabledEvites = [], forPreview = false, guestColumns, guestsLength, isTrackPage = false, }: GuestTableProps) => {
  const urlParams = useParams();
  const [t] = useTranslation();
  const { saveUserSettings }: UseAuthProps = useAuth();
  const [getAllGuests] = graphLazyQueryMiddleware(GET_GUESTS_ID_BY_INVITATIONS_IDS);
  const [removeGuest] = graphMutationMiddleware(BULK_REMOVE_GUEST);

  const [ isOpenDeleteModal, setIsOpenDeleteModal ] = useState(false);
  const [ isOpenEventDetailsModal, setIsOpenEventDetailsModal ] = useState(false);

  const [ editTags, setEditTags ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);

  const {
    params,
    setParams,
    selectedGuests,
    setSelectedGuests,
    searchText,
    setMultipleGuests,
    changeExecuteRefetch
  } = useGuestStore();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openEditTags = () => {
    setEditTags(true);
    handleClose();
  };

  const closeOnClick = (bulkAction: () => void) => {
    bulkAction();
    handleClose();
  };

  const handleSelectAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allRows: SelectedGuests[] = [];
      let hasNextPage = false;
      let iterator = 0;
      do {
        await getAllGuests({
          fetchPolicy: 'cache-and-network',
          variables: {
            invitationsIds: [Number(urlParams.id)],
            skip: 50 * iterator,
            take: 50,
            filters: {
              textFilter: searchText,
              senderIds: params.selectedSenders,
              tags: params.selectedTags
            }
          }
        }).then((allGuestsData) => {
          const resGuests = allGuestsData.data.guests.items;
          hasNextPage = allGuestsData.data.guests.pageInfo.hasNextPage;
          iterator++;
          resGuests.forEach((guest: GuestType) => {
            allRows.push({
              guestId: guest.id,
              contactId: guest.contact.id
            });
          });
        });
      } while (hasNextPage);
      setMultipleGuests(allRows);
      return;
    }
    setMultipleGuests([]);
  };

  const bulkRemoveGuests = () => {
    removeGuest({variables: {
      guestsIds: selectedGuests.map(a => a.guestId)
    }}).then(() => {
      changeExecuteRefetch();
      setSelectedGuests();
    });
  };
  const isSelectedAll = guestsLength > 0 && guestsLength === selectedGuests.length;
  return (
    <>
      {isMobile ?
        (!forPreview &&
            <TableBulkOptionsHeader>
              <Checkbox checked={isSelectedAll} onChange={(e) => handleSelectAll(e)}/>
              {selectedGuests.length > 0 ?
                <>
                  <TableBulkOptionsText sx={{display: 'inline-block', marginRight: 2}} > {selectedGuests.length} {t('selected')}</TableBulkOptionsText>
                  <TableBulkOptionsMenu
                    onClick={handleClick}>
                    <MoreHorizIcon />
                  </TableBulkOptionsMenu>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={isOpenMenu}
                    onClose={handleClose}>
                    <TableBulkOptionsMenuItem onClick={openEditTags}>
                      <LocalOfferIcon/> {t('editTags')}
                    </TableBulkOptionsMenuItem>
                    <DividerStyled/>
                    <TableBulkOptionsMenuItem onClick={() => closeOnClick(() => setIsOpenEventDetailsModal(true))}><EditIcon/> {t('editEventDetails')}</TableBulkOptionsMenuItem>
                    <DividerStyled/>
                    <TableBulkOptionsMenuItem onClick={() => closeOnClick(() => setIsOpenDeleteModal(true))}><DeleteIcon/> {t('remove')}</TableBulkOptionsMenuItem>
                  </Menu>
                  {
                    editTags && <EditTagsModal isOpen={editTags} closeEditTags={() => closeOnClick(() => setEditTags(false))} selectedIds={[]} forInvitations={false}/>
                  }
                </> :
                <Typography>{t('selectAll')}</Typography>
              }
            </TableBulkOptionsHeader>
        ):
        selectedGuests.length > 0 && !forPreview ?
          <BulkTableHead>
            <TableRow>
              <FirstTableCellHeader>
                {
                  !forPreview &&
                    <Checkbox onChange={(e) => handleSelectAll(e)} checked={isSelectedAll} />
                }
              </FirstTableCellHeader>
              <ImageTableCell>
                {
                  !forPreview &&
                    <TableBulkOptionsText> {selectedGuests.length} {t('selected')} </TableBulkOptionsText>
                }
              </ImageTableCell>
              <BulkTitleHeaderTableCell>
                <TableBulkOptionsText onClick={() => setEditTags(true)}><LocalOfferIcon/>{t('editTags')}</TableBulkOptionsText>
                {
                  editTags &&
                    <EditTagsModal forInvitations={false} isOpen={editTags} closeEditTags={() => setEditTags(false)} selectedIds={[]} />
                }
              </BulkTitleHeaderTableCell>
              <BulkTitleHeaderTableCell>
                <TableBulkOptionsText onClick={() => setIsOpenEventDetailsModal(true)}><EditIcon/>{t('editEventDetails')}</TableBulkOptionsText>
              </BulkTitleHeaderTableCell>
              <BulkTitleHeaderTableCell>
                <TableBulkOptionsText onClick={() => setIsOpenDeleteModal(true)}><DeleteIcon/>{t('remove')}</TableBulkOptionsText>
              </BulkTitleHeaderTableCell>
              <BulkTitleHeaderTableCell />
              <BulkTitleHeaderTableCell />
              <BulkTitleHeaderTableCell />
              <BulkTitleHeaderTableCell />
              {isTrackPage && Array(enabledEvites.length - 1).fill(<BulkTitleHeaderTableCell />) }
              <LastTableCell />
            </TableRow>
          </BulkTableHead> :
          <RemovedBorderTableHead>
            <TableRow>
              <TableCell component={(selectedGuests.length > 0 ? CheckBoxHeaderTableCellSelected : CheckBoxHeaderTableCell) as ElementType<TableCellBaseProps>}>
                { !forPreview && <Checkbox onChange={(e) => handleSelectAll(e)} checked={isSelectedAll} /> }
              </TableCell>
              {(guestColumns as TableColumnProps[]).map((column: TableColumnProps, index: number) => (
                (!forPreview || !column.hideOnPreview ) &&
                <TableCell key={column.id} component={(index === 0 ? ImageTableCell : index === (guestColumns.length - 1) ? LastTableCell : TitleHeaderTableCell) as ElementType<TableCellBaseProps>}>
                  <HeaderTableCellContent>
                    <CustomTooltip theme={((index === 0 ? ImageTableCellStyle : index === (guestColumns.length - 1) ? LastTableCellStyle : TitleHeaderTableCellStyle) as CSSProperties)} text={t(column.title)} />
                    {column.enableSorting &&
                          <TableSortLabel
                            active={column.sortableName === 'Status' ? params.orderBy === column.sortableEviteId.toString() : params.orderBy === column.sortableName}
                            direction={params.order}
                            onClick={() => {
                              const orderByProp = column.sortableName === 'Status' ? column.sortableEviteId.toString() : column.sortableName;
                              setParams({
                                ...params,
                                order: handleOrder(params.order),
                                orderBy: orderByProp
                              });
                              saveUserSettings(isTrackPage ?
                                USER_SETTINGS_CONFIG.GUEST_TABLE_TRACK_PAGE :
                                forPreview ? USER_SETTINGS_CONFIG.EDIT_GUEST_PREVIEW_PAGE : USER_SETTINGS_CONFIG.GUEST_TABLE_SEND_PAGE, orderByProp, params.order);
                            }}>
                          </TableSortLabel>
                    }
                  </HeaderTableCellContent>
                </TableCell>
              ))}
            </TableRow>
          </RemovedBorderTableHead>
      }
      <ConfirmationModal
        isOpen={isOpenDeleteModal}
        confimMessage={t('confirmBulkDeleteMessage')}
        handleClose={() => setIsOpenDeleteModal(false)}
        handleConfirm={bulkRemoveGuests} />
      {
        isOpenEventDetailsModal && <EditDetailsModal handleClose={() => setIsOpenEventDetailsModal(false)} isOpen={isOpenEventDetailsModal}/>
      }
    </>
  );
};
