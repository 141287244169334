import React from 'react';
import { Box } from '@mui/material';
import { atcb_action } from 'add-to-calendar-button';
import { format } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { AddToCalendar, Guest, Loader } from 'components';
import { ADD_TO_CALENDAR_IDS, SUPPORTED_CALENDARS } from 'enums';
import { DATE_FORMAT_INPUT, DEFAULT_EVENT_DURATION_HOURS, TIME_FORMAT_INPUT, getUTCDate } from 'helpers';
import { GuestRSVPModalType, InvitationProps } from 'types';

import {
  ButtonSubcontainer, ButtonSubcontainerMultipleGuests, ButtonsContainer,
  CloseButton, ContentContainer, GuestContainer, HideModalContentContainer, PersonalMessageContainer,
  PersonalMessageContainerMultipleGuests, PersonalMessageInput, PersonalMessageText, ShowModalContentContainerStyle,
  SubmitButton
} from './RsvpModal.styled';

type RsvpModalProps = {
  guests: GuestRSVPModalType[];
  changeValue: (id: number, value: string, guestId: number) => void
  invitation: InvitationProps
  submitRsvp: () => void
  isSending: boolean
  onChangePersonalMessage: (e: React.ChangeEvent) => void
  showModal?: boolean
  setShowModal?: () => void
  personalMessage?: string
}

export const RsvpModal = ({ guests, changeValue, invitation, submitRsvp, isSending, onChangePersonalMessage, showModal, setShowModal, personalMessage = null }: RsvpModalProps) => {
  const [t] = useTranslation();
  const { startDate, endDate } = invitation.locationInfo;
  const { name: venueName, displayedAddress } = invitation.locationInfo.venue;
  const isMultipleGuests = guests.filter(el => el.id !== -1).length > 1;

  const invitationStartDate = getUTCDate(startDate);
  const startDateTime = new Date(invitationStartDate);
  const invitationEndDate = endDate ? getUTCDate(endDate) : startDateTime.setHours(startDateTime.getHours() + DEFAULT_EVENT_DURATION_HOURS);

  const addToCalendarButtonConfig = {
    name: invitation.name,
    label: t('addToCalendar'),
    options: Object.values(SUPPORTED_CALENDARS),
    location: `${venueName}, ${displayedAddress}`,
    startDate: format(invitationStartDate, DATE_FORMAT_INPUT),
    endDate: format(invitationEndDate, DATE_FORMAT_INPUT),
    startTime: format(invitationStartDate, TIME_FORMAT_INPUT),
    endTime: format(invitationEndDate, TIME_FORMAT_INPUT),
    timeZone: 'currentBrowser',
    hideBackground: true,
    listStyle: 'overlay' as const,
  };

  const addToCalendarButton = document.getElementById('add-to-calendar-button');
  if (addToCalendarButton) {
    addToCalendarButton.addEventListener('click', () => atcb_action(addToCalendarButtonConfig, addToCalendarButton));
  }

  return (
    <>
      <ContentContainer
        sx={{
          ... isMultipleGuests && !isMobile && { width: '90%', margin: '0 5%' },
          ... showModal ? ShowModalContentContainerStyle : HideModalContentContainer}}>
        <Loader loadingPage={true} inProgress={isSending} />
        <CloseButton onClick={setShowModal && setShowModal}>{t('rsvp').toUpperCase()}</CloseButton>
        <GuestContainer>
          {guests.map(guest => <Guest multipleGuests={isMultipleGuests} changeValue={changeValue} key={guest.id} guest={guest} />)}
        </GuestContainer>
        <Box component={isMultipleGuests ? PersonalMessageContainerMultipleGuests : PersonalMessageContainer}>
          {!isMobile && <PersonalMessageText>{t('personalMessage')}</PersonalMessageText>}
          <PersonalMessageInput
            value={personalMessage ?? ''}
            onChange={onChangePersonalMessage}
            placeholder={isMobile ? t('personalMessage') : ''}
            multiline
            rows={3}
            inputProps={{ maxLength: 500 }}
            maxRows={3} />
        </Box>
        <ButtonsContainer>
          <Box component={isMultipleGuests ? ButtonSubcontainer : ButtonSubcontainerMultipleGuests}>
            <AddToCalendar buttonId={ADD_TO_CALENDAR_IDS.RSVP_CALENDAR_BUTTON} invitation={invitation} />
            <SubmitButton
              variant='contained'
              disabled={guests.find((guest: GuestRSVPModalType) => guest.id !== -1)?.evites?.length < 1}
              onClick={submitRsvp}>
              {t('submitRSVP')}
            </SubmitButton>
          </Box>
        </ButtonsContainer>
      </ContentContainer>
    </>
  );
};
