import React, { CSSProperties, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { CustomTooltip, ImageSlider, InputField, Loader, VirtualKeyboard } from 'components';
import { useInvitation } from 'contexts';
import { USER_PERMISSIONS, VALIDATION_RESTRICTION } from 'enums';
import { checkPermission } from 'helpers';
import { ADD_INVITATION, GET_CURRENT_USER, graphMutationMiddleware, graphQueryMiddleware } from 'services';
import { themeDefault } from 'themeDefault';
import { EviteType, InvitationContextType, InvitationTemplateProps } from 'types';

import {
  ButtonContainer, ColoredTypography,
  DetailsTypography, DialogContentStyled, DialogStyled, FormStyled, InfoContainer, InfoTypography,
  SubmitButton, ThemeOverlappedText, TitleLabel, TitleName
} from './CreateInvitationModal.styled';

type CreateInvitationProps = {
  templateData: InvitationTemplateProps
  isOpen: boolean
  handleClose: () => void
  title: string
  timesUsed: number
  createdAt: string
  createdBy: string
  templateId: number
}

export const CreateInvitationModal = ({ isOpen, handleClose, title, timesUsed, createdAt, templateId, createdBy, templateData }: CreateInvitationProps) => {
  const [ inputName, setInputName ] = useState('');
  const [t] = useTranslation();
  const navigate = useNavigate();
  const keyboardRef = useRef(null);
  const formikRef = useRef(null);

  const [ addInvitation, { loading: addInvitationloading }] = graphMutationMiddleware(ADD_INVITATION);
  const { data: userData } = graphQueryMiddleware(GET_CURRENT_USER);
  const [ createModal, setCreateModal ] = useState(false);
  const [ activeSliderId, setActiveSliderId ] = useState(0);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const { setInvitationDetails }: InvitationContextType = useInvitation();
  const isFormSubmitting = useRef(false);

  const createInvitationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('required'))
      .max(VALIDATION_RESTRICTION.HUNDRED, t('stringPropertyMaxValidation', { propertyName: t('name'), length: VALIDATION_RESTRICTION.HUNDRED })),
  });

  const handleCreate = (values: {name: string}) => {
    if (!isFormSubmitting.current) {
      isFormSubmitting.current=true;
      addInvitation({
        variables: {
          input: {
            name: values.name,
            templateId: templateId
          }
        }
      }).then(e => {
        handleClose();
        localStorage.setItem('activeId', e.data.addInvitation.id);
        setInvitationDetails && setInvitationDetails(e.data.addInvitation);
        navigate(`/invitations/create/invitation/${e.data.addInvitation.id}`);
      });
    }
  };

  const evites = templateData?.evites.map((item: EviteType) => {
    return {id: item?.image?.id, name: item?.image?.name.toString(), url: item?.image?.url, thumbnailUrl: item?.image?.thumbnailUrl};
  });

  const getImageId = (id: number) => {
    setActiveSliderId(id);
  };

  return (
    <DialogStyled maxWidth='lg' open={isOpen} onClose={() => {
      setCreateModal(false);
      handleClose();
    }}>
      <DialogContentStyled>
        <Loader inProgress={addInvitationloading}/>
        {
          createModal ?
            <>
              <TitleLabel>{t('eventName')}</TitleLabel>
              <Formik
                innerRef={formikRef}
                initialValues={{name: ''}}
                validationSchema={createInvitationSchema}
                onSubmit={handleCreate}>
                {({ errors, isValid, dirty, setFieldValue, values}) => {
                  return (
                    <FormStyled autoComplete='off'>
                      <InputField
                        setShowKeyboard={setShowKeyboard}
                        setInputName={setInputName}
                        inputId='name'
                        inputName='name'
                        isError={errors.name}
                        label={t('name')}
                        type='text' />
                      <ButtonContainer>
                        <SubmitButton
                          type='submit'
                          variant='contained'
                          disabled={Object.keys(errors).length > 0 || isFormSubmitting.current || !(isValid && dirty)}
                          style={{width: '20%'} as CSSProperties}>
                          {t('save')}
                        </SubmitButton>
                      </ButtonContainer>
                      { showKeyboard &&
                            <VirtualKeyboard
                              setShowKeyboard={setShowKeyboard}
                              closeWhenOutOfFocus={showKeyboard}
                              initialValues={values}
                              setFieldValue={setFieldValue}
                              keyboardRef={keyboardRef}
                              inputName={inputName}/>
                      }
                    </FormStyled>
                  );
                }}
              </Formik>
            </> :
            <>
              <TitleName variant='h5' sx={{mb: 2}}>
                <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={title} />
              </TitleName>
              <ImageSlider getImageId={getImageId} slidesToShow={3} images={evites} loading={false} />
              <Typography>
                <ColoredTypography color={themeDefault.palette.mainColor}>
                  <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={templateData?.evites[activeSliderId]?.name} />
                </ColoredTypography>
              </Typography>
              <DetailsTypography>
                <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={templateData?.description} />
              </DetailsTypography>
              <InfoContainer>
                <InfoTypography>
                  <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={`${t('createdAt')}: ${createdAt}`} />
                </InfoTypography>
                {createdBy &&
                <InfoTypography>
                  <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={`${t('creator')}: ${createdBy}`} />
                </InfoTypography>}
                <InfoTypography>
                  <CustomTooltip theme={ThemeOverlappedText as CSSProperties} text={`${t('timesUsedTitle')}: ${timesUsed}`} />
                </InfoTypography>
              </InfoContainer>
              {checkPermission(userData.currentUser, USER_PERMISSIONS.EDIT_INVITATION) &&
                <SubmitButton
                  variant='contained'
                  onClick={() => setCreateModal(true)}
                  style={{width: 'auto'} as CSSProperties}>
                  {t('createYourInvitation')}
                </SubmitButton>
              }
            </>
        }
      </DialogContentStyled>
    </DialogStyled>
  );
};