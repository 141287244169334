import { Box, Paper, TableCell } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themeDefault, themePublic } from 'themeDefault';

const themeLocal = createTheme();

export const ThemeOverlappedText = {
  ...themeDefault.overflowText,
  [themeLocal.breakpoints.down('sm')]: {
    maxWidth: '25vh',
  },
};

export const ThemeMultipleLinesText = {
  ...themeDefault.multipleLinesTextDisplay as CSSProperties,
};

export const VenuePaperConatinerMobile = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    border: `2px solid ${themeDefault.palette.mainBackground}`,
  }),
);

export const VenuePaperConatinerMobileSelected = styled(Paper)(
  () => ({
    ...themePublic.tableMobilePaperConatiner as CSSProperties,
    ...themePublic.tableMobilePaperConatinerSelected as CSSProperties,
    border: '2px solid transparent',
  }),
);

export const DataInfoContainerMobile = styled(Box)(
  () => ({
    ...themePublic.tableMobileDataInfoConatiner as CSSProperties,
  }),
);

export const DataContainerMobile = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    marginLeft: '1rem',
    '& p': {
      ...themeDefault.overflowText,
      maxWidth: '30vh',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '95%',
      },
    }
  }),
);

export const ActionMenuContainer = styled(Box)(
  () => ({
    ...themePublic.tableActionMenuContainer as CSSProperties,
  }),
);

export const FirstTableCellHeaderSelected = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCellHeaderSelected as CSSProperties,
  }),
);

export const FirstTableCell = styled(TableCell)(
  () => ({
    ...themePublic.firstTableCell as CSSProperties,
  }),
);

export const BasicTableCell = styled(TableCell)(
  () => ({
    ...themePublic.basicTableCell as CSSProperties,
    maxWidth: '30vh',
  }),
);

export const OptionTableCell = styled(TableCell)(
  () => ({
    ...themePublic.optionTableCell as CSSProperties,
    width: '4rem',
  }),
);

export const StatusContainer = styled(Box)(
  () => ({
    display: 'flex',
    justifyContent: 'start',
    '& svg': {
      color: themeDefault.palette.darkGreyColor,
      marginRight: '0.5rem',
    }
  }),
);