import { gql } from '@apollo/client';

export const GET_GUESTS_BY_INVITATIONS_IDS = gql`
query guests ($skip: Int, $take: Int, $customOrder: CustomOrderInput, $invitationsIds: [Long!], $filters: GuestFilterInput) {
    guests (skip: $skip, take: $take, customOrder: $customOrder, invitationsIds: $invitationsIds, filters: $filters) {
        items {
            id
            lastSent
            contact {
                id
                firstName
                lastName
                title
                email
                phoneNumber
                address
                city
                street
                state
                zipCode
                contacts {
                    id
                    firstName
                    lastName
                    title
                    email
                    phoneNumber
                    address
                    city
                    street
                    state
                    zipCode
                }
                tags {
                    id
                    name
                }
            }
            sender {
                id
                title
                firstName
                lastName
            }
            invitation {
                id
                name
            }
            evites {
                id
                name
                isSelected
            }
            responses {
                id
                contact {
                  firstName
                  lastName
                  contacts {
                      id
                  }
                  id
                }
                eviteResponse {
                  status
                  isGroup
                  evite {
                    name
                    id
                  }
                }
            }
            personalMessage
            personalGreetingMessage
            isInvitationSent
        }
        pageInfo {
            hasNextPage
            hasPreviousPage
        }
        totalCount
    }
}
`;

export const GET_GUEST_BY_TOKEN = gql`
query GuestByToken($token: String) {
  guestByToken(token: $token) {
    contact {
      id
      firstName
      lastName
      contacts {
        id
        firstName
        lastName
      }
    }
    evites {
      id
      name
      requestRsvp
      contentJson
      image {
        thumbnailUrl
        url
      }
    }
    envelope {
      id
      contentJson
      image {
        id
        url
      }
      music {
        id
        url
      }
    }
    invitation {
      id
      name
      activityStatus
      isDeleted
      additionalInfo
      locationInfo {
        endDate
        startDate
        venue {
          name
          displayedAddress
        }
      }
      evites {
        id
        name
      }
      envelope {
        id
        contentJson
        musicId
        music {
          id
          url
        }
      }
      gifts {
        url
      }
      tags {
        id
        name
        type
      }
      giftRegistryNote
    }
    responses {
        id
        contact {
          firstName
          lastName
          id
          contacts {
            id
          }
        }
        eviteResponse {
          status
          evite {
            name
            id
          }
        }
    }
    tokenExpirationDate
    personalMessage
    personalGreetingMessage
    usedCustomFonts {
      name
      ttfUrl
    }
  }
}
`;

export const GET_GUESTS_ID_BY_INVITATIONS_IDS = gql`
query GuestsIds ($skip: Int, $take: Int, $invitationsIds: [Long!], $filters: GuestFilterInput) {
    guests(skip: $skip, take: $take, invitationsIds: $invitationsIds, filters: $filters) {
        items {
            id
            contact {
                id
            }
        }
        pageInfo {
            hasNextPage
        }
    }
}
`;

export const GET_GUESTS_RSVP_STATUS = gql`
query guestsRsvpStatus ($guestId: Long!, $invitationId: Long!) {
    guestsRsvpStatus (guestId: $guestId, invitationId: $invitationId) {
        contact {
            id
            firstName
            lastName
          }
          contactResponses {
            contact {
              id
              firstName
              lastName
            }
            invitationResponses {
              invitation {
                name
              }
              responses {
                responseId
                evite {
                  id
                  name
                }
                status
              }
            }
          }
    }
}
`;

export const GET_CUMULATIVE_EVITES_RESPONSES = gql`
query cumulativeEvitesResponses ($evitesIds: [Long!]) {
    cumulativeEvitesResponses (evitesIds: $evitesIds) {
        eviteId
        numberOfNotSent
        numberOfAttending
        numberOfNotAttending
        numberOfPending
        numberOfNotAnswered
        numberOfMazelTowOnly
    }
}
`;

export const GET_GUEST_RESPONSES = gql`
query GuestResponse ($input: GuestResponseFileInput) {
    guestsResponses(input: $input) {
      name
      data
      type
    }
}
`;
