import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Box, TableBody } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal, TableWrapper, UserTableHead, UserTableRow } from 'components';
import { useAuth } from 'contexts';
import { TABLE_TYPE, USER_SETTINGS_CONFIG } from 'enums';
import { MAX_NUMBER_OF_ITEMS_TO_GET, getUserSettings, useFetching } from 'helpers';
import { BULK_CHANGE_STATUS_USERS, GET_USERS, GET_USERS_IDS, SEND_USER_UPDATED_SUBSCRIPTION, graphLazyQueryMiddleware, graphMutationMiddleware } from 'services';
import { useUsersStore } from 'store';
import { UseAuthProps, UserProps } from 'types';

import { EmptyListTextStyle, TableContainerStyled, TableStyled } from './UserlistTable.styled';

const UserlistTableComponent = () => {
  const [t] = useTranslation();
  const { dbUser }: UseAuthProps = useAuth();
  const {
    params,
    searchText,
    pageInfo,
    setPageInfo,
    selectedItems: selectedUsers,
    setSelectedItems: setSelectedUsers,
    changeExecuteRefetch,
  } = useUsersStore();

  const [ getUsers, { loading: loadingUsers, data, refetch, subscribeToMore: subscribeToGetUsers }] = graphLazyQueryMiddleware(GET_USERS);
  const [ bulkChangeStatusUsers, { loading: loadingAction }] = graphMutationMiddleware(BULK_CHANGE_STATUS_USERS);
  const userSettings = getUserSettings(USER_SETTINGS_CONFIG.USERS_TABLE, dbUser.id);
  const [getAllUsersIds] = graphLazyQueryMiddleware(GET_USERS_IDS);

  const [ startFetching, setStartFetching ] = useState(false);
  const [ storedData, setStoredData ] = useState(null);
  const [ isSelectedAll, setIsSelectedAll ] = useState(false);
  const [ isActionModalOpened, setIsActionModalOpened ] = useState(false);
  const [ isBulkActivate, setIsBulkActivate ] = useState(false);

  const users: UserProps[] = data?.users?.items || storedData || [];

  useEffect(() => {
    subscribeToGetUsers({
      document: SEND_USER_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data.userUpdated) {
          return prev;
        }
        const newPrev = {...prev};
        const userIndex = prev.users.items.findIndex((user: UserProps) => user.id === subscriptionData.data.userUpdated.id);
        Object.assign(newPrev, { [userIndex]: subscriptionData.data.userUpdated });
        return newPrev;
      }
    });
  }, []);

  useEffect(() => {
    if (!data?.users?.items) {
      return;
    }
    setStoredData(data.users.items);
    setPageInfo({
      totalCount: data.users.totalCount,
      hasNextPage: data.users.pageInfo.hasNextPage,
      hasPreviousPage: data.users.pageInfo.hasPreviousPage
    });
  }, [data]);

  const fetchUsers = (newSearchText = '') => {
    if (!startFetching) {
      return;
    }
    getUsers({
      fetchPolicy: 'cache-and-network',
      variables: {
        order: {
          [params.orderBy]: params.order.toUpperCase(),
          'id': params.order.toUpperCase()
        },
        filter: {
          textFilter: newSearchText,
          roles: params.selectedRoles,
          statusList: params.selectedStatus.map((s: string) => s.toUpperCase())
        },
        skip: params.rowsPerPage * (params.page - 1),
        take: params.rowsPerPage
      }});
  };

  const refetchUsers = () => {
    refetch();
    setIsSelectedAll(false);
    setSelectedUsers([]);
  };

  useFetching( fetchUsers, refetchUsers, loadingUsers, setStartFetching, startFetching, userSettings, useUsersStore, TABLE_TYPE.USERS_TABLE );

  const handleBulkAction = () => {
    bulkChangeStatusUsers({variables: {
      userIds: selectedUsers,
      isActive: isBulkActivate
    }}).then(() => {
      changeExecuteRefetch();
    });
    setIsActionModalOpened(false);
  };

  const handleSelectAll = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allRows: number[] = [];
      let hasNextPage = false;
      let iterator = 0;
      do {
        await getAllUsersIds(
          { fetchPolicy: 'cache-and-network',
            variables: {
              order: {
                [params.orderBy]: params.order.toUpperCase(),
                'id': params.order.toUpperCase()
              },
              filter: {
                textFilter: searchText,
                roles: params.selectedRoles,
                statusList: params.selectedStatus.map((s: string) => s.toUpperCase())
              },
              skip: MAX_NUMBER_OF_ITEMS_TO_GET * iterator,
              take: MAX_NUMBER_OF_ITEMS_TO_GET,
            }}).then((allUsersData) => {
          const allUsers: UserProps[] = allUsersData.data.users.items;
          hasNextPage = allUsersData.data.users.pageInfo.hasNextPage;
          iterator++;
          allUsers.forEach((user) => {
            allRows.push(user.id);
          });
        });
      } while (hasNextPage);
      setSelectedUsers(allRows);
      setIsSelectedAll(true);
      return;
    }
    setSelectedUsers([]);
    setIsSelectedAll(false);
  };

  const handleUserSelect = (id: number) => {
    const newSelectedContacts = [...selectedUsers];
    if (isUserSelected(id)) {
      const customer = newSelectedContacts.find(c => c === id);
      const index = newSelectedContacts.indexOf(customer);
      newSelectedContacts.splice(index, 1);
      setIsSelectedAll(false);
    } else {
      newSelectedContacts.push(id);
      data.users.totalCount === selectedUsers.length + 1 && setIsSelectedAll(true);
    }
    setSelectedUsers(newSelectedContacts);
  };

  const isUserSelected = (id: number) => {
    return selectedUsers.some(c => c === id);
  };

  const handleBulkStatus = (isActivate: boolean) => {
    setIsBulkActivate(isActivate);
    setIsActionModalOpened(true);
  };

  return (
    <TableWrapper totalCount={pageInfo.totalCount} isLoading={loadingUsers} theme={EmptyListTextStyle}>
      <>
        {isMobile ?
          <Box>
            {!loadingUsers &&
            <>
              <UserTableHead handleBulkStatus={handleBulkStatus} handleSelectAll={handleSelectAll} isSelectedAll={isSelectedAll} selectedUsersLength={selectedUsers.length} />
              {users.map((user) => (
                <UserTableRow key={user.id} handleUserSelect={handleUserSelect} user={user} selectedUsersLength={selectedUsers.length} isUserSelected={isUserSelected(user.id)} />
              ))}
            </>}
          </Box> :
          <TableContainerStyled>
            <TableStyled sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0 10px' }}>
              {!loadingUsers &&
                <>
                  <UserTableHead handleBulkStatus={handleBulkStatus} handleSelectAll={handleSelectAll} isSelectedAll={isSelectedAll} selectedUsersLength={selectedUsers.length} />
                  <TableStyled as={TableBody}>
                    {users.length > 0 && users.map((user) => (
                      <UserTableRow key={user.id} user={user} isUserSelected={isUserSelected(user.id)} handleUserSelect={handleUserSelect} />
                    ))}
                  </TableStyled>
                </>}
            </TableStyled>
          </TableContainerStyled>}
        <ConfirmationModal
          loading={loadingAction}
          isOpen={isActionModalOpened}
          handleClose={() => setIsActionModalOpened(false)}
          handleConfirm={handleBulkAction}
          confimMessage={t('bulkChangeStatusConfirmMessage')}/>
      </>
    </TableWrapper>
  );
};

export const UserlistTable = memo(UserlistTableComponent);

UserlistTable.displayName = 'UserlistTable';
