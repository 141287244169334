import { Box, Button, Chip, Divider, Menu, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { themePublic } from 'themeDefault';

export const ContactPageHeader = styled(Box)(
  () => ({
    ...themePublic.pageHeader as CSSProperties,
  }),
);

export const ImportingContactPageHeader = styled(ContactPageHeader)(
  () => ({
    paddingRight: '20px',
  }),
);

export const SearchInputHeader = styled(TextField)(
  () => ({
    ...themePublic.searchInputHeader as CSSProperties,
  }),
);

export const FilterImage = styled('img')(
  () => ({
    ...themePublic.cursorPointer as CSSProperties,
  }),
);

export const FilterMenu = styled(Menu)(
  () => ({
    ...themePublic.filterContent as CSSProperties,
  }),
);

export const FilterHeading = styled(Box)(
  () => ({
    ...themePublic.filterHeading as CSSProperties,
  }),
);

export const FilterItemContainer = styled(Box)(
  () => ({
    ...themePublic.filterItemContainer as CSSProperties,
  }),
);

export const FilterSearchField = styled(TextField)(
  () => ({
    ...themePublic.filterSearch as CSSProperties,
  }),
);

export const ImportingButton = styled(Button)(
  () => ({
    marginLeft: '20px',
  }),
);

export const SubmenuContainer = styled(Box)(
  () => ({
    ...themePublic.submenuContainer as CSSProperties,
  }),
);

export const ChipContainer = styled(Box)(
  () => ({
    padding: '0 2rem',
  }),
);

export const ChipStyled = styled(Chip)(
  () => ({
    ...themePublic.chip as CSSProperties,
  }),
);

export const SubmenuButton = styled(Button)(
  () => ({
    ...themePublic.submenuButton as CSSProperties,
    padding: '6px 16px',
  }),
);

export const MenuContent = styled(Menu)(
  () => ({
    ...themePublic.submenuContent as CSSProperties,
  }),
);

export const DividerStyled = styled(Divider)(
  () => ({
    ...themePublic.contactMenagementDividerMenu as CSSProperties,
  }),
);
