import { gql } from '@apollo/client';

export const GET_TAGS = gql`
query Tags($filter: TagFilterInput) {
  tags(filter: $filter) {
      id
      name
      type
  }
}
`;