import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Guest } from 'components';
import { DEFAULT_ID } from 'helpers';
import { CHANGE_GUESTS_RESPONSE, graphMutationMiddleware } from 'services';
import { EviteRSVPType, GuestType, GuestRSVPType, GuestResponseType } from 'types';

import { GuestNameText, SubmitButton } from './EditResponseModal.styled';

type EditResponseModalProps = {
    guest: GuestType,
    guestRsvp: GuestRSVPType,
    handleClose: () => void,
    isResponseValid: boolean,
};

export const EditResponseModal = ({guest, guestRsvp, handleClose, isResponseValid}: EditResponseModalProps) => {
  const [t] = useTranslation();
  const [changeRsvpResponse] = graphMutationMiddleware(CHANGE_GUESTS_RESPONSE);

  const [ responses, setResponses ] = useState<GuestResponseType[]>([]);

  useEffect(() => {
    const evites: Record<number, EviteRSVPType[]> = {};

    guestRsvp?.contactResponses.forEach((contactResponse) => {
      let evitesRSVP: EviteRSVPType[] = [];
      contactResponse.invitationResponses.forEach((invitationResponse) => {
        evitesRSVP = [];
        invitationResponse.responses.forEach((response) => {
          evitesRSVP.push({
            id: response.evite.id,
            name: response.evite.name,
            status: response.status,
            responseId: response.responseId
          });
        });
        evites[contactResponse.contact.id] = evitesRSVP;
      });
    });

    const guestForRSVP = guestRsvp?.contactResponses.map((inv) => (
      {
        id: inv.contact.id,
        name: `${inv.contact.title ?? ''} ${inv.contact.lastName} ${inv.contact.firstName ?? ''}`,
        evites: evites
      }
    ));

    const checkForSameStatus = (guestResponses: GuestResponseType[]) => {
      let commonStatus = null;
      for (const guestResponse of guestResponses) {
        const evitesNew = Object.values(guestResponse.evites);
        for (const evite of evitesNew) {
          const statuses = evite.map((item: { status: string }) => item.status);
          const uniqueStatuses = statuses.filter((value, index) => statuses.indexOf(value) === index);
          if (uniqueStatuses.length === 1) {
            if (commonStatus === null) {
              commonStatus = uniqueStatuses[0];
            } else if (commonStatus !== uniqueStatuses[0]) {
              return null;
            }
          } else {
            return null;
          }
        }
      }
      return commonStatus;
    };

    const commonStatuses = checkForSameStatus(guestForRSVP);
    const emptyGuest = {
      id: DEFAULT_ID,
      name: '',
      evites: {
        [DEFAULT_ID.toString()]: [{
          id: DEFAULT_ID,
          name: '',
          responseId: DEFAULT_ID,
          status: commonStatuses ?? '',
        }]
      }

    };
    guestForRSVP.unshift(emptyGuest);
    setResponses(guestForRSVP);

  }, []);

  const setAllToSameStatus = (guestResponses: GuestResponseType[], newStatus: string) => {
    return guestResponses.map((guestResponse) => ({
      ...guestResponse,
      evites: Object.entries(guestResponse.evites).reduce((prevEvite, [ key, value ]) => {
        prevEvite[Number(key)] = value.map((evite) => ({
          ...evite,
          status: newStatus
        }));
        return prevEvite;
      }, {} as Record<number, EviteRSVPType[]>)
    }));
  };

  const setStatusToEmptyString = (guestResponses: GuestResponseType[]) => {
    return guestResponses.map(guestResponse => {
      const evites = guestResponse.evites[DEFAULT_ID];

      if (evites) {
        const updatedEvites = evites.map((evite) => {
          if (evite.id === DEFAULT_ID) {
            return {
              ...evite,
              status: ''
            };
          }
          return evite;
        });
        return {
          ...guestResponse,
          evites: {
            [DEFAULT_ID.toString()]: updatedEvites
          }
        };
      }
      return guestResponse;
    });
  };

  const changeValue = (eviteId: number, statusValue: string, guestId: number) => {
    if (eviteId ===DEFAULT_ID) {
      setResponses(setAllToSameStatus(responses, statusValue));
      return;
    }

    const responsesCopy = setStatusToEmptyString(responses);
    const guestForEdit = responses.find((g) => g.id === guestId);

    const eviteForEdit = guestForEdit.evites[guestId].find((e) => e.id === eviteId);
    eviteForEdit.status = statusValue;

    const newEvites = guestForEdit.evites[guestId].map((e) => e.id === eviteId ? eviteForEdit : e);

    const newResponses = responsesCopy.map(response => response.id === guestId ? {...response, evites: {[guestId]: newEvites}} : response);
    setResponses(newResponses);
  };

  const handleSubmit = () => {
    const formatArrayToRequestInput = () => {
      const inputArray = [];
      for (const guestResponse of responses) {
        if (guestResponse.id === DEFAULT_ID) {
          continue;
        }
        const eviteResponses = [];
        for (const eviteId in guestResponse.evites) {
          for (const response of guestResponse.evites[eviteId]) {
            eviteResponses.push({
              eviteId: response.id,
              status: response.status
            });
          }
        }
        inputArray.push({
          responseId: guestResponse.evites[guestResponse.id][0].responseId,
          eviteResponse: eviteResponses
        });
      }
      return inputArray;
    };

    const formattedInput = formatArrayToRequestInput();

    changeRsvpResponse({
      variables: {
        guestId: guest.id,
        input: formattedInput
      }
    }).then(() => handleClose());
  };

  return (
    isResponseValid ?
      <>
        { responses.map((guestResponse, index) =>
          <Guest key={index} changeValue={changeValue} guestRsvp={guestResponse} multipleGuests={false} isEdit />
        )}
        <SubmitButton sx={{float: 'right'}} variant='contained' onClick={handleSubmit}>{t('edit')}</SubmitButton>
      </> :
      <GuestNameText>{t('invitationNotSent')}</GuestNameText>

  );
};