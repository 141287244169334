import React, { ElementType, useState } from 'react';
import { Delete, Edit, Public, PublicOff } from '@mui/icons-material';
import { Checkbox, Paper, TableCell, TableCellBaseProps, TableRow } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ActionMenu, ConfirmationModal, CustomTooltip, VenueModal } from 'components';
import { ACTION_MENU_VENUES, FORM_TYPE, VENUE_STATUS } from 'enums';
import { calculateMenuPlacement, filterActionMenuByTitle } from 'helpers';
import { DELETE_VENUE, UPDATE_VENUE, graphMutationMiddleware } from 'services';
import { useVenuesStore } from 'store';
import { themePublic } from 'themeDefault';
import { ActionMenuPropsDataType, VenueProps } from 'types';

import {
  ActionMenuContainer, BasicTableCell, DataContainerMobile,
  DataInfoContainerMobile, FirstTableCell, FirstTableCellHeaderSelected,
  OptionTableCell, StatusContainer, ThemeMultipleLinesText, ThemeOverlappedText,
  VenuePaperConatinerMobile, VenuePaperConatinerMobileSelected
} from './VenueRow.styled';

type VenuesRowProps = {
    venue: VenueProps,
    selectedVenues: number[],
    handleCheckboxSelect: (id: number) => void
  }

export const VenueRow = ({ venue, selectedVenues, handleCheckboxSelect }: VenuesRowProps) => {
  const [t] = useTranslation();
  const { changeExecuteRefetch } = useVenuesStore();

  const [ deleteVenue, { loading: deleteLoading }] = graphMutationMiddleware(DELETE_VENUE);
  const [updateVenue] = graphMutationMiddleware(UPDATE_VENUE);

  const [ editVenueModal, setEditVenueModal ] = useState(false);
  const [ handleConfirmationModal, setHandleConfirmationModal ] = useState(null);

  let actionMenuProps: ActionMenuPropsDataType[] = [
    {
      id: 0,
      title: t(ACTION_MENU_VENUES.EDIT),
      icon: Edit,
      functionHandler: () => setEditVenueModal(true)
    },
    {
      id: 1,
      title: t(ACTION_MENU_VENUES.MAKE_PRIVATE),
      icon: PublicOff,
      functionHandler: () => setHandleConfirmationModal(ACTION_MENU_VENUES.MAKE_PRIVATE)
    },
    {
      id: 2,
      title: t(ACTION_MENU_VENUES.MAKE_PUBLIC),
      icon: Public,
      functionHandler: () => setHandleConfirmationModal(ACTION_MENU_VENUES.MAKE_PUBLIC)
    },
    {
      id: 3,
      title: t(ACTION_MENU_VENUES.DELETE),
      icon: Delete,
      functionHandler: () => setHandleConfirmationModal(ACTION_MENU_VENUES.DELETE)
    }
  ];

  if (venue.status === VENUE_STATUS.PRIVATE.toUpperCase()) {
    actionMenuProps = filterActionMenuByTitle(actionMenuProps, t(ACTION_MENU_VENUES.MAKE_PRIVATE));
  } else if (venue.status === VENUE_STATUS.PUBLIC.toUpperCase()) {
    actionMenuProps = filterActionMenuByTitle(actionMenuProps, t(ACTION_MENU_VENUES.MAKE_PUBLIC));
  }

  const handleVenueDelete = () => {
    deleteVenue({variables: { venueId: venue.id}}).then(() => {
      changeExecuteRefetch();
    });
    setHandleConfirmationModal(null);
  };

  const handleVenueUpdate = () => {
    const newVenueStatus = handleConfirmationModal === ACTION_MENU_VENUES.MAKE_PRIVATE ?
      VENUE_STATUS.PRIVATE.toUpperCase() :
      VENUE_STATUS.PUBLIC.toUpperCase();

    updateVenue({ variables: {
      input: {
        id: venue.id,
        name: venue.name,
        address1: venue.address1,
        address2: venue.address2,
        city: venue.city,
        state: venue.state,
        zipCode: venue.zipCode,
        status: newVenueStatus,
      }}}).then(() => {
      changeExecuteRefetch();
    }).catch(() => {
      setHandleConfirmationModal(null);
    });
  };

  const handleConfirmAction = () => {
    switch (handleConfirmationModal) {
      case ACTION_MENU_VENUES.MAKE_PRIVATE:
      case ACTION_MENU_VENUES.MAKE_PUBLIC:
        return handleVenueUpdate();
      case ACTION_MENU_VENUES.DELETE:
        return handleVenueDelete();
      default:
        return t('defaultConfirmationMessage');
    }
  };

  const isVenueSelected = (id: number) => {
    return selectedVenues.some((c: number) => c === id);
  };

  const getConfirmationModalMessage = () => {
    const venueForTranslation = {
      venueName: venue.name.toString(),
    };

    switch (handleConfirmationModal) {
      case ACTION_MENU_VENUES.MAKE_PRIVATE:
        return t('venueMakePrivateConfirmationMessage', venueForTranslation);
      case ACTION_MENU_VENUES.MAKE_PUBLIC:
        return t('venueMakePublicConfirmationMessage', venueForTranslation);
      case ACTION_MENU_VENUES.DELETE:
        return t('venueDeactivatedConfirmMessage', venueForTranslation);
      default:
        return t('defaultConfirmationMessage');
    }
  };

  return (
    <>
      {isMobile ?
        <Paper elevation={2} component={(selectedVenues.includes(venue.id) ? VenuePaperConatinerMobile : VenuePaperConatinerMobileSelected as ElementType<TableCellBaseProps>)}>
          <DataInfoContainerMobile onClick={() => handleCheckboxSelect(venue.id)}>
            <DataContainerMobile>
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.name} />
              <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.displayedAddress} />
              <StatusContainer>
                {venue.status === VENUE_STATUS.PRIVATE.toUpperCase() ? <PublicOff /> : <Public />}
                <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={t(venue.status)} />
              </StatusContainer>
            </DataContainerMobile>
          </DataInfoContainerMobile>
          <ActionMenuContainer>
            <ActionMenu
              propsData={actionMenuProps}
              disablePortal={isMobile}
              iconTheme={themePublic.actionMenuIconStyle}
              menuPlacement={calculateMenuPlacement(isMobile)}/>
          </ActionMenuContainer>
        </Paper> :
        <TableRow>
          <TableCell component={(isVenueSelected(venue.id) ? FirstTableCellHeaderSelected : TableCell) as ElementType<TableCellBaseProps>}>
            <Checkbox checked={isVenueSelected(venue.id)} onClick={() => handleCheckboxSelect(venue.id)}/>
          </TableCell>
          <FirstTableCell />
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.name} />
          </BasicTableCell>
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.address1} />
          </BasicTableCell>
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.address2} />
          </BasicTableCell>
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.city} />
          </BasicTableCell>
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.state} />
          </BasicTableCell>
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={venue.zipCode} />
          </BasicTableCell>
          <BasicTableCell>
            <CustomTooltip theme={{...ThemeOverlappedText, ...ThemeMultipleLinesText}} text={t(venue.status)} />
          </BasicTableCell>
          <OptionTableCell>
            <ActionMenu propsData={actionMenuProps} menuPlacement={calculateMenuPlacement(false)}/>
          </OptionTableCell>
        </TableRow>}
      <ConfirmationModal
        loading={deleteLoading}
        isOpen={handleConfirmationModal}
        handleClose={() => setHandleConfirmationModal(null)}
        handleConfirm={handleConfirmAction}
        confimMessage={getConfirmationModalMessage()} />
      <VenueModal
        isOpen={editVenueModal}
        type={FORM_TYPE.EDIT}
        venue={venue}
        handleClose={() => setEditVenueModal(false)}/>
    </>
  );
};