import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, CircularProgress, FormControl, Grid, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { SelectFilter, SelectInvitation, VirtualKeyboard } from 'components';
import { RESPONSE_STATUS } from 'enums';
import { defaultPositionForMenu } from 'helpers';
import { menuClosed, menuOpen, searchFilter, sendIcon } from 'resources';
import { useGuestStore } from 'store';
import { COPY_GUESTS_FROM_INVITATION, graphMutationMiddleware } from 'services';
import { themeDefault } from 'themeDefault';
import { EviteType, InvitationProps, SendFilterParamsProps } from 'types';

import {
  ButtonSend, ChipFilterContainer, ChipStyled, DividerStyled,
  FilterHeading, FilterImage, FilterItemContainer, FilterMenu, FilterSearchField,
  MenuContent, MenuItemContainer, MenuItemSelected, MenuItemTitle,
  SearchInputHeader, SelectLabel, SelectrSearchField, SubheaderConatinerPreviewPage,
  SubheaderContainer, SubheaderContainerTrackPage, SubmenuButton,
} from './Subheader.styled';
import { Search as SearchIcon } from '@mui/icons-material';

type SubheaderProps = {
  filterParams: SendFilterParamsProps
  openCreateModal: () => void
  openImportModal: () => void
  openCSVModal: () => void
  sendToSelected: () => void
  sendingProcess: boolean
  tags: {
    id: number | string,
    title: string,
    hexValue?: string
  }[],
  forPreview: boolean,
  isTrackPage?: boolean,
  linkedInvitations: InvitationProps[],
};

export const Subheader = ({
  filterParams,
  openCreateModal,
  openImportModal,
  openCSVModal,
  sendToSelected,
  tags,
  sendingProcess,
  isTrackPage = false,
  forPreview = false,
  linkedInvitations,
}: SubheaderProps) => {
  const [t] = useTranslation();
  const [ cloneGuestFromInvitation, { loading: cloneLoading }] = graphMutationMiddleware(COPY_GUESTS_FROM_INVITATION);
  const { searchText, setSearchText, setParams, params, invitation, selectedGuests, changeExecuteRefetch: refetchGuests } = useGuestStore();
  const filterRef = useRef();
  const keyboardRef = useRef(null);
  const [ submenuEl, setSubmenuEl ] = useState<null | HTMLElement>(null);
  const [ filterEl, setFilterEl ] = useState<null | HTMLElement>(null);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ showMenuButton, setShowMenuButton ] = useState(true);
  const [ isOpenImportFromInvModal, setIsOpenImportFromInvModal ] = useState(false);
  const urlParams = useParams();
  const handleParams = (property: string, value: string | string[]) => {
    setParams({...params, [property]: value});
  };

  const getSenderName = (selectedId: any) => {
    const selectedSender = invitation?.senders.find((sender: any) => sender.id === Number.parseInt(selectedId));
    return `${selectedSender.title ?? ''} ${selectedSender.lastName} ${selectedSender.firstName}`;
  };

  const closeOnAction = (handleAction: () => void) => {
    setSubmenuEl(null);
    setShowMenuButton(true);
    handleAction();
  };

  const cloneSubmit = (targetId: number) => {
    cloneGuestFromInvitation({
      variables: {
        targetInvitationId: Number(urlParams.id),
        cloneInvitationId: targetId,
      }
    }).then(() => refetchGuests());
    setIsOpenImportFromInvModal(false);
  };

  return (
    <Grid container component={forPreview ? SubheaderConatinerPreviewPage : isTrackPage ? SubheaderContainerTrackPage : SubheaderContainer}>
      {!isMobile &&
        <>
          <Grid justifyContent='center' display='flex' ref={filterRef} item sm={isTrackPage ? 12 : 6} md={isTrackPage ? 12 : 6} lg={isTrackPage ? 12 : 7.5} xl={isTrackPage ? 12 : 8.5}>
            <SearchInputHeader
              onFocus={() => setShowKeyboard(true)}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder={t('searchPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start' onClick={() => setFilterEl(filterRef.current)}>
                    <FilterImage src={searchFilter} />
                  </InputAdornment>
                ),
              }}
              variant='outlined' />
            <FilterMenu
              anchorEl={filterEl}
              open={Boolean(filterEl)}
              onClose={() => setFilterEl(null)}
              anchorOrigin={{
                vertical: 0,
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <FilterHeading onClick={() => setFilterEl(null)}>
                <FilterImage src={searchFilter} />
              </FilterHeading>
              <FilterItemContainer>
                <FilterSearchField
                  onFocus={() => setShowKeyboard(true)}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  placeholder={t('searchPlaceholder')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined' />
                <SelectFilter
                  isDisabledTranslation={true}
                  placeholder={t('selectTags')}
                  selectedItems={filterParams.selectedTags}
                  handleSelectItem={(e) => handleParams('selectedTags', e)}
                  items={tags.map((tag: any) => (
                    { id: tag.id, title: tag.name }
                  ))} />
                <FormControl sx={{ m: '0 auto', marginBottom: '5%', width: isMobile ? '90%' : '70%' }}>
                  <SelectrSearchField
                    multiple
                    size='small'
                    displayEmpty
                    value={filterParams.selectedSenders}
                    onChange={(e: SelectChangeEvent<string | string[]>) => handleParams('selectedSenders', e.target.value)}
                    renderValue={
                      filterParams.selectedSenders.length === 0 ?
                        () => <SelectLabel>- {t('selectSenders')} -</SelectLabel> :
                        (selected: string | string[]) => typeof selected === 'string' ? t(getSenderName(selected)) :
                          selected.map((s: string) => t(getSenderName(s))).join(', ')}>
                    {invitation?.senders.map((sender: any) => (
                      { id: sender.id, title: `${sender.title ?? ''} ${sender.firstName} ${sender.lastName}` }
                    )).map((item: any) => (
                      <MenuItem key={item.id} value={item.id} component={filterParams.selectedSenders.indexOf(item.id) > -1 ? MenuItemSelected : MenuItem}>
                        <MenuItemContainer>
                          <MenuItemTitle primary={t(item.title)} />
                          <Checkbox checked={filterParams.selectedSenders.includes(item.id)} />
                        </MenuItemContainer>
                      </MenuItem>
                    ))}
                  </SelectrSearchField>
                </FormControl>
                {isTrackPage &&
                  <>
                    <SelectFilter
                      isDisabledTranslation={true}
                      placeholder={t('selectEvents')}
                      selectedItems={filterParams.selectedEvents}
                      handleSelectItem={(e) => handleParams('selectedEvents', e)}
                      items={linkedInvitations.map((linkedInvitation: InvitationProps) => (
                        { id: linkedInvitation.id, title: linkedInvitation.name }
                      ))} />
                    <SelectFilter
                      placeholder={t('selectResponses')}
                      selectedItems={filterParams.selectedStatus}
                      handleSelectItem={(e) => handleParams('selectedStatus', e)}
                      items={(Object.keys(RESPONSE_STATUS) as (keyof typeof RESPONSE_STATUS)[]).map((item, index) => ({ id: index, title: t(item) }))} />
                    <SelectFilter
                      placeholder={t('selectEvites')}
                      selectedItems={filterParams.selectedEvites}
                      handleSelectItem={(e) => handleParams('selectedEvites', e)}
                      items={Array.prototype.concat.apply([], linkedInvitations.map((inv: InvitationProps) => (
                        inv.evites.map((evite: EviteType) => ({ id: evite.id, title: evite.name }))
                      )))} />
                  </>}
              </FilterItemContainer>
            </FilterMenu>
          </Grid>
        </>}
      {!isTrackPage && !forPreview &&
        <>
          <Grid item sm='auto' md='auto' lg='auto' xl='auto' justifyContent='right' display='flex'>

            <SubmenuButton
              sx={!showMenuButton && {opacity: 0}}
              aria-controls={submenuEl ? 'demo-positioned-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={submenuEl ? 'true' : undefined}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setSubmenuEl(e.currentTarget);
                setShowMenuButton(false);
              }}>
              {t('contactManagement')} <img src={menuClosed} />
            </SubmenuButton>
            <MenuContent
              anchorEl={submenuEl}
              open={Boolean(submenuEl)}
              onClose={() => {
                setSubmenuEl(null);
                setShowMenuButton(true);
              }}
              anchorOrigin={defaultPositionForMenu}
              transformOrigin={defaultPositionForMenu}>
              <MenuItem>{t('contactManagement')} <img src={menuOpen} /></MenuItem>
              <DividerStyled color={themeDefault.palette.mainBackground} />
              <MenuItem onClick={() => closeOnAction(openCreateModal)}>{t('newContact')}</MenuItem>
              <DividerStyled color={themeDefault.palette.mainBackground} />
              <MenuItem onClick={() => closeOnAction(openImportModal)}>{t('importExistingContacts')}</MenuItem>
              <DividerStyled color={themeDefault.palette.mainBackground} />
              <MenuItem onClick={() => closeOnAction(openCSVModal)}>{t('importFromCSV')}</MenuItem>
              <DividerStyled color={themeDefault.palette.mainBackground} />
              <MenuItem onClick={() => closeOnAction(() => setIsOpenImportFromInvModal(true))}>{t('copyFromOtherInvitation')}</MenuItem>
            </MenuContent>
          </Grid>
          <Grid item sm='auto' md='auto' lg='auto' xl='auto'>
            <ButtonSend disabled={selectedGuests.length < 1 || sendingProcess} variant='contained' onClick={() => sendToSelected()}>
              {
                sendingProcess ?
                  <CircularProgress size={30} /> :
                  <>
                    <img src={sendIcon} />
                    {t('send')}
                  </>
              }
            </ButtonSend>
          </Grid>
        </>
      }
      <ChipFilterContainer item xs={12} sm={12} md={12} lg={12}>
        {Object.entries(filterParams)?.map(([ filterKey, filterValue ]) =>
          Array.isArray(filterValue) && filterValue.map((item: any) =>
            (typeof item === 'string' || typeof item === 'number') && <ChipStyled key={item} label={typeof item === 'string' ? item : getSenderName(item)} onDelete={(e) => handleParams(filterKey, filterValue.filter(elem => elem !== item))} />
          )
        )}
      </ChipFilterContainer>
      {showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          closeWhenOutOfFocus={showKeyboard}
          initialValues={{
            'searchText': searchText
          }}
          setFieldValue={null}
          setStandaloneItem={setSearchText}
          keyboardRef={keyboardRef}
          inputName={'searchText'} />
      }
      {isOpenImportFromInvModal &&
      <SelectInvitation
        handleClose={() => setIsOpenImportFromInvModal(false)}
        handleUpdate={cloneSubmit}
        isOpen={isOpenImportFromInvModal}
        title={t('selectInvitation')}
        placeholder={t('invitationName')}
        submitBtnText={t('import')}/>}
    </Grid>
  );
};