import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { format, formatDistanceToNow, isPast, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ActionMenu, ConfirmationModal } from 'components';
import { ACTION_MENU_PLACEMENT } from 'enums';
import { showToast } from 'helpers';
import { PublicContactEntryAuthorisationType } from 'types';

import {
  ActiveText, ChipStyled, CopyButton, CopyButtonIcon,
  InactiveText, InfoContainer, InfoDateText, InfoText,
  PaperContent, PaperStyled, TagsContainer
} from './MobilePublicContactEntryLinksTableRow.styled';
import { Delete, Edit } from '@mui/icons-material';

type Props = {
  item: PublicContactEntryAuthorisationType;
  onStartEdit?: () => void;
  onDelete?: () => Promise<void>;
};

export const MobilePublicContactEntryLinksTableRow = ({
  item,
  onStartEdit,
  onDelete,
}: Props) => {
  const [t] = useTranslation();

  const [ deleteModal, setDeleteModal ] = useState(false);
  const [ deleting, setDeleting ] = useState(false);

  const onCopyLink = () => {
    const loc = window.location;
    const url = `${loc.protocol}//${loc.host}/gather-contacts?token=${item.token}`;
    navigator.clipboard.writeText(url)
      .then(
        () => showToast('success', t('sharedLinkCopySucceeded')),
        () => showToast('error', t('sharedLinkCopyFailed')),
      );
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await onDelete?.();
      setDeleteModal(false);
      showToast('success', t('sharedLinkDeleteSucceeded'));
    } catch (err) {
      showToast('error', t('sharedLinkDeleteFailed'));
    } finally {
      setDeleting(false);
    }
  };

  const parsedExpirationDate = item.expirationDate && parseISO(item.expirationDate);
  const derivedStatus = parsedExpirationDate && isPast(parsedExpirationDate) ? 'EXPIRED' : item.activityStatus;

  return (
    <>
      <PaperStyled elevation={2}>
        <PaperContent>
          {item.name &&
            <InfoContainer>
              <Typography>{item.name}</Typography>
            </InfoContainer>
          }
          {item.expirationDate &&
            <InfoContainer>
              <InfoText>
                <strong>Expiry: </strong>
                {format(parsedExpirationDate, 'MM/dd/yyyy')}
              </InfoText>
              <InfoDateText>
                {formatDistanceToNow(parsedExpirationDate, { addSuffix: true })}
              </InfoDateText>
            </InfoContainer>
          }
          <Typography component={derivedStatus === 'ACTIVE' ? ActiveText : InactiveText}>
            &#9679; {t(derivedStatus.toLowerCase())}
          </Typography>
          <TagsContainer>
            {item.tags?.map((tag, i) => (
              <ChipStyled key={i} label={tag.name} />
            ))}
          </TagsContainer>
          <CopyButton onClick={onCopyLink}>
            <CopyButtonIcon />
            {t('copy')}
          </CopyButton>
        </PaperContent>
        <ActionMenu
          menuPlacement={ACTION_MENU_PLACEMENT.BOTTOM_END}
          propsData={[
            {
              id: 0,
              title: t('edit'),
              icon: Edit,
              functionHandler: () => onStartEdit?.(),
            },
            {
              id: 1,
              title: t('delete'),
              icon: Delete,
              functionHandler: () => setDeleteModal(true),
            },
          ]}/>
      </PaperStyled>
      <ConfirmationModal
        loading={deleting}
        isOpen={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={handleDelete}
        confimMessage={t('sharedLinkDeleteConfirmMessage')} />
    </>
  );
};
