import React, { CSSProperties } from 'react';

import { ButonStyled } from './DefaultButton.styled';

type DefaultButtonProps = {
  title: string,
  handleFunction?: () => void,
  theme?: CSSProperties,
  disabled?: boolean,
};

export const DefaultButton = ({title, handleFunction, theme = {}, disabled=false}: DefaultButtonProps) => {
  return (
    <ButonStyled variant='contained' type='submit' disabled={disabled} sx={theme} style={theme} onClick={handleFunction}>
      {title}
    </ButonStyled>
  );
};