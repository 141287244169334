import React, { useEffect, useRef, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, PaperProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomTooltip, VirtualKeyboard } from 'components';
import { getSelectedEvitesPropsByPropName, showToast } from 'helpers';
import { themeDefault } from 'themeDefault';
import { ActiveItemsType, EviteType } from 'types';

import { EvitesContainer, OptionPaper, SelectorContainer } from './EviteSelector.styled';

type EviteSelectorProps = {
    activeItems: ActiveItemsType,
    defaultShowEvites: boolean
    eviteData: {
      evites: EviteType[]
    }
    isCreating: boolean
    freeSolo?: boolean
    multiple?: boolean
    selectedEvites: EviteType[]
    showKeyboardForEvent?: boolean
    showKeyboardParent: boolean
    setSelectedEvites: (input: EviteType[]) => void
    setShowKeyboardForEvent?: (show: boolean) => void
    setShowKeyboardParent: (show: boolean) => void
}

export const EviteSelector = ({
  activeItems,
  eviteData,
  freeSolo = false,
  isCreating=true,
  multiple = true,
  selectedEvites,
  setSelectedEvites,
  setShowKeyboardParent = null,
  setShowKeyboardForEvent = null,
  showKeyboardForEvent = false,
  showKeyboardParent = false,
}: EviteSelectorProps) => {
  const [t] = useTranslation();
  const keyboardRef = useRef(null);

  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');

  useEffect((() => {
    showKeyboardParent && setShowKeyboard(false);
  }), [showKeyboardParent]);

  useEffect((() => {
    showKeyboardForEvent && setShowKeyboard(false);
  }), [showKeyboardForEvent]);

  const CustomPaper = (props: PaperProps) => {
    return <OptionPaper elevation={8} {...props} />;
  };

  return (
    <SelectorContainer>
      <EvitesContainer>
        <Autocomplete
          PaperComponent={CustomPaper}
          disableCloseOnSelect
          disabled={!isCreating && !activeItems.evites}
          freeSolo={freeSolo}
          inputValue={inputValue}
          getOptionLabel={(option: EviteType) => option.name}
          isOptionEqualToValue={(option: EviteType, value: EviteType) => option.id === value.id}
          disableClearable
          multiple={multiple}
          onInputChange={(e: React.ChangeEvent<HTMLInputElement>, value: string, reason: string) => {
            reason === 'input' && setInputValue(value);
          }}
          options={eviteData && eviteData.evites.filter((item: EviteType) => item.isSelected)}
          value={multiple ? getSelectedEvitesPropsByPropName(selectedEvites, null) : selectedEvites[0]}
          onFocus={() => {
            setShowKeyboard(true);
            setShowKeyboardParent && setShowKeyboardParent(false);
            setShowKeyboardForEvent && setShowKeyboardForEvent(false);
          }}
          onChange={(e, val: EviteType[]) => {
            val.length < 1 ? showToast('error', t('oneEviteMustBeSelected')) : (setSelectedEvites(val), setInputValue(''));
          }}
          renderOption={(props, option: EviteType, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected} />
              <CustomTooltip key={option.id} theme={themeDefault.overflowText} text={option.name} />
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('weddingAfterparty')} />
          )} />
      </EvitesContainer>
      {showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          initialValues={{
            'evites': getSelectedEvitesPropsByPropName(selectedEvites, 'name')
          }}
          setEvitesValue={setInputValue}
          keyboardRef={keyboardRef}
          inputName={'searchEvites'} />
      }
    </SelectorContainer>
  );
};