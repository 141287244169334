import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  InputAdornment,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { VenueModal, VirtualKeyboard } from 'components';
import { FORM_TYPE } from 'enums';
import { useVenuesStore } from 'store';

import { ChipContainer, SearchInputHeader, VenuesPageHeader } from './VenuesHeader.styled';

export const VenuesHeader = () => {
  const [t] = useTranslation();
  const { searchText, setSearchText } = useVenuesStore();

  const [ openModal, setOpenModal ] = useState(false);
  const [ showKeyboard, setShowKeyboard ] = useState(false);

  const filterRef = useRef();
  const keyboardRef = useRef(null);

  const closeCreateVenueModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <VenuesPageHeader>
        <Typography>{t('venuesHeader')}</Typography>
        <div ref={filterRef}>
          <SearchInputHeader
            autoComplete='off'
            onFocus={() => setShowKeyboard(true)}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={t('searchPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            variant='outlined'/>
        </div>
        <Button variant='contained' onClick={() => setOpenModal(true)}>{t('createNewVenue')}</Button>
      </VenuesPageHeader>
      <ChipContainer/>
      { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          closeWhenOutOfFocus={showKeyboard}
          initialValues={{
            'searchText': searchText
          }}
          setFieldValue={null}
          setStandaloneItem={setSearchText}
          keyboardRef={keyboardRef}
          inputName={'searchText'}/>
      }
      <VenueModal isOpen={openModal} handleClose={closeCreateVenueModal} type={FORM_TYPE.CREATE}/>
    </>
  );
};