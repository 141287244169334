import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SearchFilterInvitationsContainer, VirtualKeyboard } from 'components';
import { defaultPositionForMenu } from 'helpers';
import { searchFilter } from 'resources';
import { GET_TYPES, graphQueryMiddleware } from 'services';
import { useInvitationStore } from 'store';
import { InvitationTags } from 'types';

import {
  ChipContainer, ChipStyled, FilterHeading, FilterImage, FilterItemContainer, FilterMenu,
  InvitationPageHeader, SearchInputHeader
} from './InvitationsHeader.styled';
import SearchIcon from '@mui/icons-material/Search';

type ContactsHeadingProps = {
  tags: InvitationTags[]
}

export const InvitationsHeader = ({ tags }: ContactsHeadingProps) => {
  const [t] = useTranslation();
  const navHistory = useNavigate();
  const [ filterEl, setFilterEl ] = useState<null | HTMLElement>(null);
  const [ showKeyboard, setShowKeyboard ] = useState(false);
  const { params, setParams, searchText, setSearchText} = useInvitationStore();

  const filterRef = useRef();
  const keyboardRef = useRef(null);
  const isOpenFilter = Boolean(filterEl);
  const { data: typesData } = graphQueryMiddleware(GET_TYPES);

  const openFilters = () => {
    setFilterEl(filterRef.current);
  };
  const closeFilters = () => {
    setFilterEl(null);
  };

  const handleNavigate = (route: string) => {
    navHistory(route);
  };

  return (
    <>
      <InvitationPageHeader>
        <Typography>{t('invitations')}</Typography>
        <div ref={filterRef}>
          <SearchInputHeader
            onFocus={() => setShowKeyboard(true)}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={t('searchPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start' onClick={() => openFilters()}>
                  <FilterImage src={searchFilter} />
                </InputAdornment>
              ),
            }}
            variant='outlined'/>
        </div>
        <FilterMenu
          anchorEl={filterEl}
          open={isOpenFilter}
          onClose={closeFilters}
          anchorOrigin={{
            vertical: 0,
            horizontal: 0,
          }}
          transformOrigin={defaultPositionForMenu}>
          <FilterHeading onClick={closeFilters}>
            <FilterImage src={searchFilter} />
          </FilterHeading>
          <FilterItemContainer>
            <SearchFilterInvitationsContainer tags={tags} types={typesData?.types}/>
          </FilterItemContainer>
        </FilterMenu>
        <Button variant='contained' onClick={() => {
          handleNavigate('/invitations/create/invitation');
          localStorage.removeItem('activeId');
        }}>
          {t('createNewInvitationBtn')}
        </Button>
      </InvitationPageHeader>
      { showKeyboard &&
        <VirtualKeyboard
          setShowKeyboard={setShowKeyboard}
          initialValues={{
            'searchText': searchText
          }}
          setFieldValue={null}
          setStandaloneItem={setSearchText}
          keyboardRef={keyboardRef}
          inputName={'searchText'}/>
      }
      <ChipContainer>
        <Box>
          {params.selectedTags.map((tagName) => (
            <ChipStyled label={tagName} key={tagName} onDelete={() => setParams({...params, selectedTags: params.selectedTags.filter(item => item !== tagName)})} />
          ))}
          {params.selectedTypes.map((typeName) => (
            <ChipStyled label={t(typeName)} key={typeName} onDelete={() => setParams({...params, selectedTypes: params.selectedTypes.filter(item => item !== typeName)})} />
          ))}
          {params.selectedStatus.map((selStatus) => (
            <ChipStyled label={t(selStatus)} key={selStatus} onDelete={() => setParams({ ...params, selectedStatus: params.selectedStatus.filter(item => item !== selStatus)})} />
          ))}
          {params.selectedActivityStatuses.map((selectedActivityStatus) => (
            <ChipStyled label={t(selectedActivityStatus)} key={selectedActivityStatus} onDelete={() => setParams({ ...params, selectedActivityStatuses: params.selectedActivityStatuses.filter(item => item !== selectedActivityStatus)})} />
          ))}
        </Box>
        <Box />
      </ChipContainer>
    </>
  );
};