import React from 'react';
import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FONT_PROPERTY, SLIDER_ELEMENTS_IDS } from 'enums';
import { FONT_MIN_VALUE, FONT_STEP_INCREASE, FONT_VALUE_INCREASE, decimalNumberRegExp, numberRegExp } from 'helpers';
import { downArrowIcon, upArrowIcon } from 'resources';

import {
  ArrowButtonsContainer, DownArrowButton, EditorNameLabel, InputContainer,
  SliderInput, TextFieldStyled, UpArrowButton
} from './ValueAndSliderInput.styled';

type ValueAndSliderInputProps = {
  font: any;
  callback: (styleType: string, value: string | number | number[] | boolean) => void;
  title: string;
  valueType: string;
  maxValue: number,
  isMobile: boolean,
  sliderId: string
  returnFocus?: (toRef: boolean) => void
  canvas: fabric.Canvas,
  selectAndRemoveFocus: (canvas: fabric.Canvas) => void
}

export const ValueAndSliderInput = ({font, callback, title, valueType, maxValue, isMobile, sliderId, returnFocus = null, canvas, selectAndRemoveFocus} : ValueAndSliderInputProps) => {
  const [t] = useTranslation();
  const inputNumberRegExp = valueType === FONT_PROPERTY.LINE_HEIGHT ? decimalNumberRegExp : numberRegExp;

  const calculateNewValue = (oldValue: number, step: number, isDecimalNumber: boolean) => {
    const result = Number(oldValue) + step;
    return isDecimalNumber ? result.toFixed(1) : result;
  };

  return (
    <>
      <EditorNameLabel>{title}</EditorNameLabel>
      <InputContainer>
        <TextFieldStyled
          id={SLIDER_ELEMENTS_IDS.OUTLINED_BASIC}
          variant='outlined'
          value={font[valueType]}
          InputProps={{
            endAdornment: valueType === FONT_PROPERTY.FONT_SIZE && <InputAdornment position='end'>{t('px')}</InputAdornment>,
          }}
          onChange={(e) => {
            if (inputNumberRegExp.test(e.target.value) && Number(e.target.value) <= maxValue) {
              callback(valueType, e.target.value);
              selectAndRemoveFocus(canvas);
            }
          }} />
        <ArrowButtonsContainer>
          <UpArrowButton
            id={sliderId + SLIDER_ELEMENTS_IDS.UP_ARROW}
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => {
              const step = valueType === FONT_PROPERTY.LINE_HEIGHT ? FONT_STEP_INCREASE : FONT_VALUE_INCREASE;
              font[valueType] < maxValue && callback(valueType, calculateNewValue(font[valueType], step, valueType === FONT_PROPERTY.LINE_HEIGHT));
              returnFocus && returnFocus(false);
            }} >
            <img
              src={upArrowIcon} />
          </UpArrowButton>
          <DownArrowButton
            id={sliderId + SLIDER_ELEMENTS_IDS.DOWN_ARROW}
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => {
              const step = valueType === FONT_PROPERTY.LINE_HEIGHT ? -FONT_STEP_INCREASE : -FONT_VALUE_INCREASE;
              font[valueType] > FONT_MIN_VALUE && callback(valueType, calculateNewValue(font[valueType], step, valueType === FONT_PROPERTY.LINE_HEIGHT));
              returnFocus && returnFocus(false);
            }} >
            <img
              src={downArrowIcon} />
          </DownArrowButton>
        </ArrowButtonsContainer>
        {!isMobile && <SliderInput
          id={sliderId}
          size='small'
          defaultValue={font[valueType]}
          value={font[valueType]}
          step={valueType === FONT_PROPERTY.LINE_HEIGHT ? FONT_STEP_INCREASE : FONT_VALUE_INCREASE}
          onChange={(e, val) => {
            callback(valueType, val);
            returnFocus && returnFocus(false);
          }}
          draggable={true}
          min={FONT_MIN_VALUE}
          max={maxValue}
          aria-label='Default'
          valueLabelDisplay='auto' />}
      </InputContainer>
    </>
  );
};