import React, {
  CSSProperties,
  KeyboardEvent as KeyboardEventType,
  MouseEvent as MouseEventType,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { Box, ClickAwayListener, Grow, MenuItem, PopperPlacementType } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { ACTION_MENU_PLACEMENT, KEYBOARD_BUTTONS } from 'enums';
import { contactsOptionIcon, contactsOptionIconActive } from 'resources';
import { ActionMenuPropsDataType } from 'types';

import { MenuListStyled, OptionsActiveIcon, OptionsIcon, OptionsMenuPaper, OptionsMenuPopper } from './ActionMenu.styled';

type ActionMenuProps = {
  propsData: ActionMenuPropsDataType[],
  isTrackPage?: boolean,
  disablePortal?: boolean,
  menuPlacement?: PopperPlacementType,
  iconTheme?: CSSProperties,
};

export const ActionMenu = ({propsData, iconTheme = {}, isTrackPage = false, disablePortal = false, menuPlacement = ACTION_MENU_PLACEMENT.BOTTOM}: ActionMenuProps) => {
  const [t] = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(isOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = (e: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }
    prevOpen.current = false;
    setIsOpen(false);
  };

  const handleElementFunction = (item: ActionMenuPropsDataType) => {
    item.functionHandler();
    setIsOpen(false);
  };

  const handleListKeyDown = (e: KeyboardEventType) => {
    if (e.key === KEYBOARD_BUTTONS.TAB) {
      e.preventDefault();
      setIsOpen(false);
    } else if (e.key === KEYBOARD_BUTTONS.ESCAPE) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current && !isOpen) {
      anchorRef?.current!.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  return (
    <>
      <Box sx={{width: '28px', zIndex: 5, opacity: Number(!isOpen)}}>
        <OptionsIcon src={contactsOptionIcon}
          alt={t('optionImage')}
          ref={anchorRef}
          onClick={handleToggle}
          sx={iconTheme}/>
      </Box>
      <OptionsMenuPopper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement={menuPlacement}
        disablePortal={disablePortal}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          }
        ]}
        transition>
        {({ TransitionProps }) => {
          return (
            <Grow
              {...TransitionProps}>
              <OptionsMenuPaper>
                <OptionsActiveIcon src={contactsOptionIconActive}
                  alt={t('optionImage')}
                  onClick={handleToggle}
                  sx={iconTheme}/>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuListStyled
                    id='composition-menu'
                    aria-labelledby='composition-button'
                    onKeyDown={handleListKeyDown}>
                    {propsData.map(item =>
                      isTrackPage && item.title !== t('preview') &&
                      <MenuItem
                        key={item.id}
                        onClick={(e: MouseEventType) => {
                          e.stopPropagation();
                          handleElementFunction(item);
                        }}>
                        <item.icon />
                        {item.title}
                      </MenuItem> ||
                      !isTrackPage &&
                      <MenuItem key={item.id} onClick={() => handleElementFunction(item)}><item.icon /> {item.title}</MenuItem>
                    )}
                  </MenuListStyled>
                </ClickAwayListener>
              </OptionsMenuPaper>
            </Grow>
          );
        }}
      </OptionsMenuPopper>
    </>
  );
};