import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeDefault } from 'themeDefault';

export const InputErrorStyle = {
  borderRadius: '10px',
  outline: 'none',
  width: '100%',
  '& fieldset': {
    borderColor: themeDefault.palette.errorColor,
  },
  '& input': {
    color: themeDefault.palette.mainColor,
    padding: '11px',
  },
  '& div': {
    backgroundColor: themeDefault.palette.lightMainColor,
    height: '3rem',
    outline: 0,
    '& :hover': {
      border: 0,
    }
  },
};

export const RegisterFieldStyle = {
  width: '100%',
  '& div': {
    borderRadius: '10px',
    height: 'auto',
  },
  '& input': {
    height: '2rem',
  }
};

export const VisibilityOffIconStyled = styled(VisibilityOffIcon)(
  () => ({
    fill: themeDefault.palette.greyColor,
  }),
);

export const ErrorSymbol = styled(Box)(
  () => ({
    '& span': {
      backgroundColor: themeDefault.palette.errorColor,
      borderRadius: '50%',
      color: themeDefault.palette.whiteColor,
      height: '22px',
      position: 'absolute',
      textAlign: 'center',
      right: '-24px',
      top: '50%',
      transform: 'translate(50%, -50%)',
      width: '22px',
    },
  }),
);

export const InputFieldContainer = styled(Box)(
  ({ style }) => ({
    marginTop: '16px',
    position: 'relative',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -8px) scale(0.7) !important',
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& fieldset': {
      borderRadius: '6px',
    },
    '& p': {
      color: themeDefault.palette.errorColor,
      fontSize: '12px',
      margin: 0,
    },
    '& .MuiOutlinedInput-root .MuiInputBase-inputAdornedEnd': {
      color: style?.color,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: themeDefault.palette.blackColor,
      borderWidth: '1px',
    },
    '& .MuiInputAdornment-root': {
      color: `${themeDefault.palette.disabledColor} !important`,
    },
  }),
);

export const AbsoluteError = styled(InputFieldContainer)(
  () => ({
    '& p': {
      position: 'absolute',
      top: '95%',
    },
  }),
);
